import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, MenuItem } from "@material-ui/core";
import { textAlign } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import * as fiActions from "../../../store/fi/fiSlice";

export default function FreeTextInput(values) {
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState("");
  const tradeToEdit = useSelector(
    (state) => state.fiSlice?.selectedTradeToEdit
  );
  const useStyles = makeStyles((theme) => ({
    textAreaPicker: {
      marginTop: "25px",

      "& .MuiOutlinedInput-input": {
        color: "#FFFFFF",
        borderRadius: "4px",
        height: "85px",
        width: "474px",
        padding: 0,
        textAlign: "left",
        paddingLeft: "10px",
      },
      "& .MuiInputLabel-root": {
        color: "#868DA2",
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#686B76",
      },
      "&:hover .MuiOutlinedInput-input": {
        color: "#FFFFFF",
      },
      "&:hover .MuiInputLabel-root": {
        color: "#2692FF",
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#2692FF",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
        color: "#FFFFFF",
      },
      "& .MuiInputLabel-root.Mui-focused": {
        color: "#2692FF",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#2692FF",
      },
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    if (tradeToEdit !== undefined) {
      setSelectedValue(tradeToEdit[`${values.values.key}`]);
      let obj = {};
      obj = {
        value: tradeToEdit[`${values.values.key}`],
        key: values.values.key,
      };
      dispatch(fiActions.editNewTradeForm(obj));
    }
  }, []);

  const handleChange = (event) => {
    let obj = {};
    obj = {
      value: event.target.value,
      key: values.values.key,
    };
    setSelectedValue(event.target.value);
    dispatch(fiActions.editNewTradeForm(obj));
  };
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <TextField
          className={classes.textAreaPicker}
          id="outlined-select-currency-native"
          type="text"
          label={values.values.label}
          value={selectedValue}
          onChange={handleChange}
          SelectProps={{
            native: true,
          }}
          menuprops={{ classes: { paper: classes.dropdownStyle } }}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
        >
          {" "}
        </TextField>
      </div>
    </form>
  );
}
