// Import worker
import WebSocketPricesWorker from "workerize-loader!./pricerWebSocket.js"; // eslint-disable-line import/no-webpack-loader-syntax
// const WebSocketPricesWorker = () => require("./pricerWebSocket.js");
// Create an instance of WebSocketPrices
let WebSocketPricesInstance = WebSocketPricesWorker();
let WebSocketPricesInstance2 = WebSocketPricesWorker();
// const WebSocketPricesInstance = new SharedWorker('web-sockets-worker.js');

let exportingObject = {
  WebSocketPricesInstance,
  WebSocketPricesInstance2,
};

export default exportingObject;
