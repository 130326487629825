export const editableSelector = (params) => {
  if (
    params.data.columnHeader !== "Cleared By" &&
    params.data.columnHeader !== "Client" &&
    params.data.columnHeader !== "Trader" &&
    params.data.columnHeader !== "Clearance Account" &&
    params.data.columnHeader !== "Initiation Fee" &&
    params.data.columnHeader !== "Market Maker Fee" &&
    params.data.columnHeader !== "Floor Broker" &&
    params.data.columnHeader !== "Crossed Fee Rate" &&
    params.data.columnHeader !== "Not Crossed Fee Rate" &&
    params.data.columnHeader !== "Leg Fee Rate" &&
    params.data.columnHeader !== "Hedge Fee Rate" &&
    params.data.columnHeader !== "Floor Broker Fee" &&
    params.data.columnHeader !== "Execution Fee" &&
    params.data.columnHeader !== "Commission" &&
    params.data.columnHeader !== "Initiation" &&
    params.data.columnHeader !== "Market Maker"
  ) {
    return false;
  } else return true;
};
