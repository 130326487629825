import React, { useCallback, useEffect, useState } from "react";
import { Grid, Paper, Tabs, Tab, IconButton } from "@material-ui/core";
import { useStyles } from "../../../Styles/pricerStyle";
import CloseIcon from "@material-ui/icons/Close";

export const DialogContainer = ({
  isBooking = null,
  isConfo = null,
  isEConfo = null,
  setOpenBookingModal,
  setOpenConfirmationModal,
  setOpenEConfoModal,
  handleCancelBooking,
  handleCancelConfo,
  order,
  bookedTablesData,
}) => {
  const classes = useStyles();

  const [tabs, setTabs] = useState([
    { label: "Makor X Booking", value: "booking" },
    { label: "Email Confirmation", value: "confo" },
    { label: "E-Confirmation", value: "e_confo" },
  ]);
  const [tabValue, setTabValue] = useState(
    isBooking !== null && isBooking
      ? "booking"
      : isConfo !== null && isConfo
      ? "confo"
      : isEConfo !== null && isEConfo
      ? "e_confo"
      : null,
  );
  const handleChange = useCallback(
    (event, newValue) => {
      setTabValue(newValue);
      if (newValue === "booking") {
        setOpenBookingModal(true);
        setOpenConfirmationModal(false);
        setOpenEConfoModal(false);
        handleCancelConfo();
      } else if (newValue === "confo") {
        setOpenConfirmationModal(true);
        setOpenBookingModal(false);
        setOpenEConfoModal(false);
        handleCancelBooking();
      } else if (newValue === "e_confo") {
        setOpenEConfoModal(true);
        setOpenBookingModal(false);
        setOpenConfirmationModal(false);
        handleCancelBooking();
      }
    },
    [setOpenConfirmationModal, setOpenBookingModal],
  );


  return (
    <Grid container>
      <Paper className={classes.paperTabsContainerDialog} xs={11}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
          className={classes.tabsDialog}
          TabIndicatorProps={{ style: { display: "none" } }}
          style={{ marginTop: "15px" }}
        >
          {tabs.map((tab, i) => {
            return (
              <Tab
                label={tab.label}
                value={tab.value}
                className={
                  tabValue === tab.value
                    ? classes.selectedTab
                    : classes.notSelectedTab
                }
                disabled={
                  (tab.value === "confo" &&
                    !order.status.toLowerCase().includes("booked")) ||
                  (tab.value === "e_confo" &&
                    !(
                      order.status.toLowerCase().includes("booked") &&
                      order.symbol.toLowerCase().includes("vix") &&
                      bookedTablesData?.strategyTable[0]?.isOtc
                    ))
                }
              />
            );
          })}
        </Tabs>
      </Paper>
      <Grid
        xs={1}
        item
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <IconButton
          size="small"
          onClick={
            tabValue === "booking" ? handleCancelBooking : handleCancelConfo
          }
        >
          <CloseIcon className={classes.bookingCloseIcon} />
        </IconButton>
      </Grid>
    </Grid>
  );
};
