import axios from "axios";
import { END_POINT } from "../../../../utils";
import {
  getBbgData,
  subscribeOrdersToSocket,
} from "../../derivsPricerBlotter/utils/apiCalls";
import { testDateWithinRange } from "../../derivsPricerBlotter/utils/utilsFunctions";

export const getBookedOrders = async (
  setOrdersList,
  setIsOrderListLoading,
  pageNum,
  setBbgUpdate,
  setEntitlement,
  setTeam,
) => {
  try {
    const token = sessionStorage.getItem("token");
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}${END_POINT.GET_BOOKED_ORDERS_LIST}`,
      { headers: { Authorization: token } },
    );
    if (setEntitlement !== null) {
      setEntitlement(res.data.entitlement);
    }
    if (setTeam !== null) {
      res.data.team.length > 1
        ? setTeam("Admin")
        : res.data.team[0] === "US"
        ? setTeam("Americas")
        : res.data.team[0] === "US2"
        ? setTeam("Americas-2")
        : setTeam(res.data.team[0]);
    }
    setOrdersList(res.data.ordersListWithLegs);

    setIsOrderListLoading(false);
    subscribeOrdersToSocket(
      res.data.ordersListWithLegs.filter(
        (order, index) =>
          (pageNum === 1 && index < 10) ||
          (pageNum > 10 && (pageNum - 1) * 10 <= index && index < pageNum * 10),
      ),
    );
    const timestamp = new Date().getTime();
    if (res.data.ordersListWithLegs.length > 0) {
      const ordersList = await getBbgData(res.data.ordersListWithLegs, pageNum);
      setBbgUpdate({
        timestamp,
        payload: ordersList,
      });
      setBbgUpdate({
        timestamp,
        payload: ordersList,
      });
      // setOrdersList(ordersList);
    } else {
      setIsOrderListLoading(false);
    }
  } catch (error) {
    console.log(error);
  }
};
