import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { CreateUser } from "./tabs/CreateUser";
import { useStyles } from "../../Styles/usersManagement";
import { UsersList } from "./tabs/UsersList";
import { TabPanel } from "./components/TabPanel";

export default function UsersManagement() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.rootTabs}>
      <AppBar position="static" className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          className={classes.tabs}
          classes={{ indicator: classes.indicator }} // Override indicator style
        >
          <Tab
            label="create user"
            {...a11yProps(0)}
            className={value === 0 ? classes.selectedTab : ""}
          />
          <Tab
            label="manage users (coming soon)"
            {...a11yProps(1)}
            className={value === 1 ? classes.selectedTab : ""}
            disabled
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <CreateUser />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <UsersList />
      </TabPanel>
    </div>
  );
}
