import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "ag-grid-enterprise";
import { Grid } from "@material-ui/core";
import "./style.css";
import axios from "axios";
import { MilitaryTech } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import * as derivsPricerSlice from "../../../../../store/derivsPricer/derivsPricerSlice";

export const UnderlyingTable = ({
  parsedBookingText,
  order,
  gridApiSource,
  setIsUnderlyingLoading,
  isSubmittingForm,
}) => {
  // ag grid settings
  const dispatch = useDispatch();
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const gridStyle = useMemo(() => ({ height: "100%", width: "700px" }), []);
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "ticker",
      editable: false,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "securityType",
      editable: false,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "currency",
      editable: false,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "multiplier",
      editable: false,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
    };
  }, []);

  const getRowId = useCallback((params) => {
    return params.data.id;
  });

  // useEffect
  useEffect(() => {
    const rowDataBuild = async (order) => {
      setIsUnderlyingLoading(true);
      const tickerString = order.legs[0].optionTicker
        .replaceAll("/", "|")
        .replaceAll(" ", "%20");
      const res = await axios.get(
        `${
          process.env.REACT_APP_BLOOMBERG_DERIVS
        }equity/${tickerString.toUpperCase()}`,
      );
      setRowData([
        {
          id: 0,
          ticker: order.symbol,
          securityType: order.securityType,
          currency: "USD",
          multiplier: res.data.multiplier,
          isin: order.isin,
        },
      ]);
      setIsUnderlyingLoading(false);
    };
    rowDataBuild(order);
  }, []);

  useEffect(() => {
    dispatch(derivsPricerSlice.setUnderlyingTable([...rowData]));
  }, [rowData]);

  
   useEffect(() => {
     if (isSubmittingForm) {
       gridApi?.showLoadingOverlay();
     } else {
       gridApi?.hideOverlay();
     }
   }, [isSubmittingForm, gridApi]);

  return (
    <>
      {rowData.length > 0 ? (
        <Grid
          item
          className="ag-theme-balham-dark strategy-table"
          style={gridStyle}
          xs={12}
        >
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            domLayout={"autoHeight"}
            onGridReady={onGridReady}
            animateRows={true}
            readOnlyEdit={true}
            singleClickEdit={true}
            enableCellEditingOnBackspace={true}
          ></AgGridReact>
        </Grid>
      ) : null}
    </>
  );
};

export const otcFormatter = (params) => {
  return `${params.value === false ? "No" : "Yes"}`;
};
