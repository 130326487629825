import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  IconButton,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@material-ui/core";
import { useStyles } from "../style/derivsClientsStyle";
import {
  addNewClientTraderClearanceAcc,
  deleteClientTraderClearanceAcc,
  getClientTraderClearanceAccounts,
  updateClientTraderClearanceAcc,
} from "../utils/apiCalls";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDispatch } from "react-redux";
import * as actionSnackBar from "../../../../store/snackbar/action";

export const ClearingAccounts = ({
  pickedTraderObj,
  searchTraderValue,
  searchClientValue,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [accountsList, setAccountsList] = useState(null);
  const [hoveredId, setHoveredId] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [isEditing, setIsEditing] = useState(null);
  const [editedAccountValue, setEditedAccountValue] = useState("");
  const inputRef = useRef(null);

  const onAccountPick = (account) => {
    setSelectedId(account.id);
  };
  const setEditedAccount = (e, i) => {
    setEditedAccountValue(e.target.value);
  };
  const handleEditClick = (item) => {
    setIsEditing(item.id);
    setEditedAccountValue(item.clearanceAcc);
  };

  const updateAccountChange = async (id) => {
    try {
      const index = accountsList.findIndex((item) => item.id === id);
      if (index !== -1) {
        await updateClientTraderClearanceAcc({
          ...accountsList[index],
          clearanceAcc: editedAccountValue,
        });
        setAccountsList((prev) => {
          const updatedList = [...prev];
          updatedList[index].clearanceAcc = editedAccountValue;

          return updatedList;
        });
      }
    } catch (error) {
      dispatch(actionSnackBar.setSnackBar("error", error.message, 3000));
    }

    setIsEditing(null);
    setEditedAccountValue("");
  };
  const handleKeyPress = (e, id, type) => {
    if (e.key === "Enter") {
      updateAccountChange(id, type);
    }
  };

  const addNewAccount = async () => {
    try {
      console.log(pickedTraderObj);
      const res = await addNewClientTraderClearanceAcc({
        clientId: pickedTraderObj.client_id,
        traderId: pickedTraderObj.id,
        clearanceAcc: "Fill in new account ...",
      });

      setAccountsList((prev) => {
        const updatedList = [...prev];
        updatedList.unshift(res.accountObj);
        setSelectedId(updatedList[0].id);
        setIsEditing(updatedList[0].id);
        setEditedAccountValue(updatedList[0].clearanceAcc);
        return updatedList;
      });
    } catch (error) {
      dispatch(actionSnackBar.setSnackBar("error", error.message, 3000));
    }
  };
  const handleDelete = async (e, id, type) => {
    try {
      const index = accountsList.findIndex((item) => item.id === id);
      if (index !== -1) {
        await deleteClientTraderClearanceAcc(accountsList[index].id);
        accountsList.splice(index, 1);
        setAccountsList((prev) => {
          const updatedList = [...prev];

          return updatedList;
        });
        setIsEditing(null);
        setSelectedId(null);
        setEditedAccountValue("");
      }
    } catch (error) {
      dispatch(actionSnackBar.setSnackBar("error", error.message, 3000));
    }
  };
  useEffect(() => {
    if (pickedTraderObj !== null) {
      const { client_id, id } = pickedTraderObj;
      getClientTraderClearanceAccounts(client_id, id, setAccountsList);
    }
  }, [pickedTraderObj]);

  useEffect(() => {
    // Focus the input when isEditing matches selectedId
    if (isEditing === selectedId && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isEditing, selectedId]);
  useEffect(() => {
    setAccountsList([]);
  }, [searchTraderValue, searchClientValue]);
  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      className={classes.staticColumn2}
      xs={5}
    >
      <Grid item className={classes.grayHeaderContainer}>
        <Typography className={classes.grayHeader}>
          Clearing Accounts
        </Typography>
      </Grid>

      <Grid item className={classes.itemListContainer} xs={12}>
        <Collapse in={true} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Grid className={classes.itemsListEmails}>
              {accountsList !== null &&
                accountsList.map((item, i) => {
                  return (
                    <Grid className={classes.itemListContainer} key={i}>
                      <ListItem
                        button
                        onClick={() => onAccountPick(item)}
                        selected={selectedId === item.id}
                        className={classes.listItemSelected}
                        onMouseEnter={() => setHoveredId(item.id)}
                        onMouseLeave={() => setHoveredId(null)}
                      >
                        {isEditing === item.id && selectedId === item.id ? (
                          <input
                            type="text"
                            value={editedAccountValue}
                            onChange={(e) => setEditedAccount(e, i)}
                            onBlur={() => updateAccountChange(item.id, "to")}
                            onKeyPress={(e) => handleKeyPress(e, item.id, "to")}
                            className={classes.emailInput}
                            ref={inputRef}
                          />
                        ) : (
                          <ListItemText
                            className={classes.listItemText}
                            primary={item.clearanceAcc}
                          />
                        )}
                        {selectedId === item.id || hoveredId === item.id ? (
                          <>
                            <CreateIcon
                              className={classes.createIcon}
                              fontSize={"large"}
                              onClick={() => handleEditClick(item)}
                            />
                            <DeleteIcon
                              className={classes.deleteIcon}
                              fontSize={"large"}
                              onClick={(e) => handleDelete(e, item.id, "to")}
                            />
                          </>
                        ) : null}
                      </ListItem>
                    </Grid>
                  );
                })}
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={11}>
                <Button
                  disableElevation
                  variant="contained"
                  color="primary"
                  className={classes.addContactBtn}
                  onClick={() => addNewAccount()}
                >
                  + Add ACCOUNT
                </Button>
              </Grid>
            </Grid>
          </List>
        </Collapse>{" "}
      </Grid>

      {/* <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={11}>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            className={classes.addContactBtn}
          >
            + Add Contact
          </Button>
        </Grid>
      </Grid> */}
    </Grid>
  );
};
