import axios from "axios";
import { END_POINT } from "../utils/index";

const fetchMiFids = async (trade) => {
  let bloombergApi = process.env.REACT_APP_BLOOMBERG_FI;
  const newTrade = await axios.get(bloombergApi + `${trade.isin}`);
  return newTrade.data;
};

async function createAndUpdateMifids(missingMifidDataTrades) {

  
  let allNewTrades;
  let allTradesWithMiFids = [];

  allTradesWithMiFids = missingMifidDataTrades.map((trade) => {
    return fetchMiFids(trade).then((newTrade) => {
      return newTrade;
    });
  });

  try {
    allNewTrades = await Promise.allSettled(allTradesWithMiFids)
      .then((res) => {
  
        return res;
      })
      .catch((err) => {
        throw err;
      });
    allNewTrades = allNewTrades.map((trade) => {
      return trade.value;
    });
  } catch (error) {
    throw error;
  }

  // * after all trades got their MiFid Data send an Update req to the server
  try {
    const token = sessionStorage.getItem("token");
    let updateAllTradesWithMifid = await axios.post(
      `${process.env.REACT_APP_BASE_URL}${END_POINT.FI_TRADES}/update_mifids`,
        allNewTrades,
      { headers: { Authorization: token } }
    );  
    return updateAllTradesWithMifid.data
  } catch (error) {
    return (error)
  }
}



export { createAndUpdateMifids };
