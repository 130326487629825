import axios from "axios";
import { END_POINT } from "../../../../../utils";

export const getClearanceData = async (clientId, traderId) => {
  try {
    const token = sessionStorage.getItem("token");
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}${END_POINT.DERIVS_GET_CLIENT_TRADER_STATIC}/${clientId}/${traderId}`,
      { headers: { Authorization: token } },
    );
    if (res.data.status) {
      const { clearanceAcc } = res.data.payload;
      return clearanceAcc.map((i) => i.clearanceAcc);
    } else return [];
  } catch (error) {
    console.log(error);
  }
};

export const updateBookedOrderData = async ({ field, value, orderId }) => {
  try {
    const token = sessionStorage.getItem("token");

    if (!token) {
      throw new Error("No authorization token found.");
    }

    if (!field || value === null || value === undefined || !orderId) {
      throw new Error("Invalid input data.");
    }

    const res = await axios.put(
      `${process.env.REACT_APP_BASE_URL}${END_POINT.DERIVS_STORE_BOOKED_TRADE}`,
      { field, value, orderId },
      { headers: { Authorization: token } },
    );

    return res.data;
  } catch (error) {
    console.error(
      "Error updating booked order data:",
      error.response || error.message,
    );
    throw error; // Rethrow the error so that the caller function can handle it if needed.
  }
};
