import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  InputBase,
  makeStyles,
  Typography,
} from "@material-ui/core";
import OtpInput from "react-otp-input";
import { useStyles } from "./ResetPassword.styles";
import CloseIcon from "@material-ui/icons/Close";

function ResetPasswordView({
  open,
  onClose,
  userEmail,
  sixDigits,
  resetCodeSent,
  sendResetCode,
  changePassword,
  handleChangeNumeric,
  handleUserEmailChange
}) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={resetCodeSent === false ? onClose : null}
      style={{ marginLeft: 39 }}
      classes={{ paper: classes.emailDialogPaper }}
    >
      {resetCodeSent === false ? (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          style={{ padding: 10 }}
        >
          <Grid item>
            <IconButton
              size="small"
              style={{ background: "#3A3C3F" }}
              onClick={onClose}
            >
              <CloseIcon style={{ fontSize: "1.3125rem", color: "#ffffff" }} />
            </IconButton>
          </Grid>
        </Grid>
      ) : null}
      <DialogContent gutterBottom>
        <Grid container justifyContent="center" alignItems="center">
          {resetCodeSent === false ? (
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              style={{ paddingTop: "10%" }}
            >
              <Grid item>
                <Typography
                  style={{
                    color: "#ffffff",
                    fontSize: "1.3125rem",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Password Reset
                </Typography>
              </Grid>
              <Grid item style={{ paddingTop: 20 }}>
                <Typography style={{ color: "#ffffff", fontSize: "1.125rem" }}>
                  If you've lost your password please enter you email address.
                </Typography>
              </Grid>
              <Grid item style={{ paddingTop: 5 }}>
                <Typography style={{ color: "#ffffff", fontSize: "1.125rem" }}>
                  A six digits code will be send to your email.
                </Typography>
              </Grid>
              <Grid item style={{ height: "fit-content", paddingTop: 30 }}>
                <InputBase
                  className={classes.input}
                  placeholder="Your Email"
                  name="text"
                  onChange={(e) => handleUserEmailChange(e)}
                />
              </Grid>
              <Grid
                item
                container
                justifyContent="center"
                xs={12}
                style={{ paddingTop: 10 }}
              >
                <Button
                  className={classes.changeButton}
                  onClick={sendResetCode}
                >
                  Send me the code
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid item container alignItems="center" justifyContent="center">
              <Grid item>
                <Typography
                  style={{
                    textAlign: "center",
                    color: "#ffffff",
                    fontSize: "2rem",
                  }}
                >
                  Enter Reset code
                </Typography>
              </Grid>
              <Grid item xs={10} container style={{ paddingTop: "43px" }}>
                <Grid item>
                  <Typography
                    style={{ color: "#ffffff", fontSize: "1.125rem" }}
                  >
                    To reset your password, we emailed you a six-digit code to{" "}
                    {userEmail}
                  </Typography>
                </Grid>
                <Grid item style={{ paddingTop: 12 }}>
                  <Typography
                    style={{ color: "#868DA2", fontSize: "1.125rem" }}
                  >
                    Enter the code below to continue
                  </Typography>
                </Grid>
              </Grid>
              <Grid item style={{ paddingBlock: "30px" }}>
                <OtpInput
                  value={sixDigits}
                  onChange={(e) => handleChangeNumeric(e)}
                  autoFocus
                  otpType="number"
                  numInputs={6}
                  separator={<span></span>}
                  secure
                  inputStyle={{
                    fontFamily: "monospace",
                    borderRadius: "3px",
                    marginInline: "10px",
                    width: 56,
                    height: 72,
                    fontSize: 30,
                    boxSizing: "border-box",
                    marginLeft: 0,
                    color: "#ffffff",
                    border: `1px solid #686B76`,
                    backgroundColor: "#3A3C3F",
                    marginTop: "10px",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                container
                justifyContent="space-around"
                alignItems="center"
                style={{ paddingTop: "25px" }}
              >
                <Grid item>
                  <Typography
                    className={classes.resend}
                    onClick={sendResetCode}
                  >
                    Resend Code
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    className={classes.changeButton}
                    onClick={changePassword}
                  >
                    Change Password
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default ResetPasswordView;
