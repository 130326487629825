import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
const QuoteBookParamsSwap = ({ rfqParamaters,is_forwarding_starting,is_notionals_same,toggle_pricing_notional }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.rfqPctNotional} style={{ marginTop: "10px" }}>
      <Grid item xs={12}>
        <Typography style={{ color: "white", fontSize: "1.25" }}>
          QUOTE TYPE: {rfqParamaters?.quote_type_str}
        </Typography>
      </Grid>
      {is_notionals_same() ? (
        <Grid item xs={12}>
          <Typography style={{ color: "white", fontSize: "1.25" }}>
            NOTIONAL: {rfqParamaters?.pricing_notional}
          </Typography>
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <Typography style={{ color: "white", fontSize: "1.25" }}>
              NOTIONALS:{" "}
              {`${rfqParamaters.near_notional} x ${rfqParamaters.far_notional}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button onClick={ toggle_pricing_notional}><CompareArrowsIcon  style={{ fill: '#0072ea' , width: '50px',height: '40px',}} /></Button>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ color: "white", fontSize: "1.25" }}>
              PRICING LEG NOTIONAL:{" "}
              {`${rfqParamaters.near_notional} (${rfqParamaters.pricing_leg})`}
            </Typography>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Typography style={{ color: "white", fontSize: "1.25" }}>
          NEAR DATE: {rfqParamaters?.near_date_text}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ color: "white", fontSize: "1.25" }}>
          FAR DATE: {rfqParamaters?.far_date_text}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ color: "white", fontSize: "1.25" }}>
          SPOT REF: {rfqParamaters?.spot_ref}
        </Typography>
      </Grid>
      {is_forwarding_starting() ? (
        <>
          <Grid item xs={12}>
            <Typography style={{ color: "white", fontSize: "1.25" }}>
              NEAR SWAP:{" "}
              {`${rfqParamaters.near_swap} -> ${rfqParamaters.near_outright}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ color: "white", fontSize: "1.25" }}>
              FAR SWAP:{" "}
              {`${rfqParamaters.far_swap} -> ${rfqParamaters.far_outright}`}
            </Typography>{" "}
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ color: "white", fontSize: "1.25" }}>
              FWD START SWAP:{" "}
              {rfqParamaters.fwd_starting_swap}
            </Typography>{" "}
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <Typography style={{ color: "white", fontSize: "1.25" }}>
            MID SWAP: {`${rfqParamaters.far_swap} -> ${rfqParamaters.far_outright}`}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
export default QuoteBookParamsSwap;

export const useStyles = makeStyles((theme) => ({
  "& .MuiInputBase-input MuiInput-input": {
    fontSize: "400px",
    color: "white",
  },
  rfqPctNotional: {
    border: "1px solid #686B76",
    padding: "20px",
    marginTop: "5px",
    borderRadius: "8px",
  },
  rfqParameters: {
    fontSize: "1.0625rem",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-around",
    border: "1px solid #686B76",
    padding: "20px",
    marginTop: "5px",
    height: "fit-content",
  },
  quoteParamatersHeader: {
    color: " var(--unnamed-color-989ea8)",
    font: "normal normal normal 12px/10px Inter",
    letterSpacing: "0px",
    color: " #989EA8",
  },
  quoteParamatersText: {
    marginTop: "5px",
    color: " var(--unnamed-color-989ea8)",
    font: "normal normal normal 10px/2px Inter",
    letterSpacing: "0px",
    color: "#FFFFFF",
  },
}));
