import React, { useRef, useEffect, useState, useCallback } from "react";
import jspreadsheet from "jspreadsheet-ce";
import cssHighLightRowHelper from "../rfqWorkspace/helpers/cssHighLiteRow";
import {
  tableHeaders_vol_spread,
  tableHeaders_single_price,
  user_editable_columns,
  user_clickable_columns,
} from "../rfqWorkspace/helpers/staticDataTable";
import alphabetJson from "../rfqWorkspace/helpers/Alphabet.json";
import * as rfqSlice from "../../../../store/rfq/rfqSlice";
import * as rfqAggregatorSlice from "../../../../store/rfq/rfqAggregatorSlice";
import * as rfqPostTradeSlice from "../../../../store/rfq/rfqPostTradeSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import "../../../../../node_modules/jspreadsheet-ce/dist/jspreadsheet.css";
import "./RfqQuoteBook.css";
import { makeStyles } from "@material-ui/core/styles";
import { RfqInfoModal } from "../../modals/rfqInfoModal/RfqInfoModal";
import { Grid } from "@material-ui/core";
import { END_POINT } from "../../../../utils";
import * as actionSnackBar from "../../../../store/snackbar/action";
import workerInstances from "../../../../services/index";
import QuoteBookParamsPayout from "./quote_params/QuoteBookParamsPayout";
import QuoteBookParamsPctNotional from "./quote_params/QuoteBookParamsPctNotional";
import QuoteBookParamsSingleVol from "./quote_params/QuoteBookParamsSingleVol";
import QuoteBookParamsStraddleStrangle from "./quote_params/QuoteBookParamsStraddleStrangle";
import QuoteBookParamsDoubleVol from "./quote_params/QuoteBookParamsDoubleVol";
import QuoteBookParamsSwap from "./quote_params/QuoteBookParamsSwap";
import { buildInitialDataTable } from "../../../../services/rfqQuoteBook/initialDataTable";
import {
  apiRefreshQuoteBook,
  buildOrderBook,
  apiGetDealStats,
  apiBuildQuoteBook,
} from "../../../../services/apiCalls/quotsBookApi";
import {
  apiGetQuoatParameters,
  apiRestDealStats,
  updateQuoteStatus,
} from "../../../../services/apiCalls/restCalls/restQuoteBookApi";
import {
  ws,
  connectWS,
  sendEvent,
  closeWS,
} from "../../../../services/websocket";
import { findAllByDisplayValue } from "@testing-library/react";
import { createQuoteBookDict } from "../../../../services/rfqWorkspace/createQuoteBookDict";
import { buildInitialDataInBlotterTable } from "../../../../services/rfqQuoteBook/initialDataTable";
import { debounce } from "lodash";
import {
  getTableStyle,
  handleQuotePriceUpdate,
  handleQuoteUpdate,
  initializeTableWithDataAndStyle,
  setLocalJTable,
} from "./utils/tools";

const RfqQuoteBook = ({ rfq_object, setrfq_object }) => {
  const rfq_id = sessionStorage.getItem("rfqId");
  const dispatch = useDispatch();
  const [alphabet, setalphabet] = useState(alphabetJson.alphabet);
  const [onProgressHighlight, setOnProgressHighlight] = useState(false);
  const classes = useStyles();
  let jRef = useRef();

  let col = [];
  let row = [];
  let col2 = [];
  const userMarketMakers = useSelector(
    (state) => state.rfqAggregatorSlice.userMarketMakers.items,
  );
  let tableData = useSelector((state) => state.rfqSlice.tableData);
  let dealStatsData = useSelector((state) => state.rfqSlice.dealStatsData);
  let rfqParamaters = useSelector(
    (state) => state.rfqAggregatorSlice.RfqQuoteParameters,
  );
  const userSpread = useSelector(
    (state) => state.rfqAggregatorSlice.userSpread,
  );
  const status = useSelector((state) => state.rfqSlice.rfqButtonStatus);
  const rfqMode = useSelector((state) => state.rfqSlice.rfqMode);
  const rfqDefaultQuoteParameters = useSelector(
    (state) => state.rfqAggregatorSlice.rfqDefaultQuoteParams,
  );
  const rfqid = useSelector((state) => state.rfqSlice.rfqId.items);
  let rfqObject = useSelector((state) => state.rfqPostTradeSlice.rfqObject);
  let rfqParamatersCopy = { ...rfqParamaters };
  let copyDealStatsData = JSON.parse(JSON.stringify(dealStatsData));
  let initial_data = userMarketMakers.map((element) => [element, ""]);

  const set_read_only_columns = (table_headers) => {
    let columns = [];
    for (let c = 0; c < table_headers.length; c++) {
      columns.push({
        readOnly: true,
        align: "center",
      });
    }
    // addReadOnlyClass()
    return columns;
  };
  const selectionActive = useCallback(
    debounce(async (instance, x1, y1, x2, y2) => {
      row = y1;
      col = x1;
      col2 = x2;
      let cssUser = new cssHighLightRowHelper(
        jRef.current.jspreadsheet,
        true,
        x1,
        y1,
      );
      cssUser.activateUserEditableClassesSingleCell();
      if (
        user_editable_columns.includes(
          jRef.current.jspreadsheet.headers[col].title,
        )
      ) {
        let cell = getCell(col, row);
        cell.classList.remove("readonly");
      } else if (
        user_clickable_columns.includes(
          jRef.current.jspreadsheet.headers[col].title,
        ) &&
        !onProgressHighlight &&
        jRef.current.jspreadsheet.getRowData(y1)[1] === ""
      ) {
        await updateQuoteStatus(y1, rfq_id, setOnProgressHighlight);
      }
    }, 250),
    [onProgressHighlight, rfq_id],
  );

  const update_controller = async (instance, cell, x, y, value) => {
    let currentMarketMaket = [
      { marketMaker: getCell(x - 1, y).innerText, value: value },
    ];
    let active_row = 0;
    if (active_table_headers()[col] === "QUOTE TEXT") {
      active_row = row;
    }
    if (active_table_headers()[col] === "MARKET MAKER") {
      active_row = row - 1;
    }

    if (
      RegExp(/^\p{L}/, "u").test(currentMarketMaket[0].value) &&
      currentMarketMaket[0].value.toUpperCase().charAt(0) !== "P" &&
      currentMarketMaket[0].value.toUpperCase().charAt(0) !== "D"
    ) {
      alert(
        `QUOTE PARSER ERROR:  --> ${currentMarketMaket[0].value}. Ensure there is a bid/offer operator '/'`,
      );
      return;
    } else {
      let quote_book = await api_build_quote_book(currentMarketMaket);

      set_jtable(quote_book);
      parser_error();
    }
  };
  const set_jtable = (quote_book) => {
    setLocalJTable(jRef, quote_book, jspreadsheet);
  };
  const parser_error = () => {
    let quote_text_col =
      jRef.current.jspreadsheet.options.colHeaders.indexOf("QUOTE TEXT");
    let bid_col = jRef.current.jspreadsheet.options.colHeaders.indexOf("BID");
    let offer_col =
      jRef.current.jspreadsheet.options.colHeaders.indexOf("OFFER");
    let spread_col =
      jRef.current.jspreadsheet.options.colHeaders.indexOf("SPREAD");
    let mm_col =
      jRef.current.jspreadsheet.options.colHeaders.indexOf("MARKET MAKER");

    for (
      let index = 0;
      index < jRef.current.jspreadsheet.rows.length;
      index++
    ) {
      let quote_text = jRef.current.jspreadsheet.getValueFromCoords(
        quote_text_col,
        index,
      );

      let bid = jRef.current.jspreadsheet.getValueFromCoords(bid_col, index);
      let offer = jRef.current.jspreadsheet.getValueFromCoords(
        offer_col,
        index,
      );
      let spread = jRef.current.jspreadsheet.getValueFromCoords(
        spread_col,
        index,
      );
      let mm = jRef.current.jspreadsheet.getValueFromCoords(mm_col, index);

      if (
        quote_text !== "" &&
        quote_text.toUpperCase() !== "DO NOT ASK" &&
        quote_text.toUpperCase() !== "PASSED"
      ) {
        if (bid === "" && offer === "" && spread === "") {
          dispatch(
            actionSnackBar.setSnackBar(
              "error",
              `QUOTE PARSER ERROR: ${mm} --> ${quote_text}. Ensure there is a bid/offer operator '/'`,
              3000,
            ),
          );
          return;
        }
      }
    }
  };
  const rawVolSpread = (rfqParamaters, tableData) => {
    if (Number(rfqParamaters.choice_leg) === 1) {
      return `RAW: ${rfqParamaters.bbg_vol1} CH vs ${tableData[0].bid}/${tableData[0].offer} (${tableData[0].lp_bid}/${tableData[0].lp_offer})`;
    } else if (Number(rfqParamaters.choice_leg) === 2) {
      return `RAW: ${tableData[0].bid}/${tableData[0].offer} vs ${rfqParamaters.bbg_vol2} CH  (${tableData[0].lp_bid}/${tableData[0].lp_offer})`;
    }
  };
  const rfqParametersQuoteType = () => {
    if (rfqParamaters.quote_type === undefined) {
      return rfqid[0]?.quote_type;
    } else {
      return jspreadsheet.rfqParamaters.quote_type;
    }
  };
  const api_build_quote_book = async (currentMarketMaket) => {
    try {
      let jtableDict = await createQuoteBookDict();
      setrfq_object(jtableDict);
      const QuoteBook = await apiBuildQuoteBook(
        rfqParametersQuoteType(),
        create_quote_book_dict(),
        currentMarketMaket[0].marketMaker,
        jtableDict,
        jspreadsheet.rfqParamaters,
        dispatch,
      );
      let userQuoteBook = await apiRefreshQuoteBook(
        rfqParametersQuoteType(),
        QuoteBook,
        jtableDict,
        jspreadsheet.rfqParamaters,
        dispatch,
      );
      let defaultQuoteBook = await apiRefreshQuoteBook(
        rfqParametersQuoteType(),
        QuoteBook,
        jtableDict,
        jspreadsheet.rfqDefaultQuoteParameters,
        dispatch,
      );

      let defaultOrderBook = await buildOrderBook(defaultQuoteBook);

      let userOrderBook = await buildOrderBook(userQuoteBook);
      let defaultDealStats = await apiGetDealStats(
        rfqParametersQuoteType(),
        jtableDict,
        jspreadsheet.rfqDefaultQuoteParameters,
        jspreadsheet.dealStats,
        defaultOrderBook,
        false,
      );
      if (defaultDealStats !== undefined) {
        UpdateDealStatsData(defaultDealStats);
        updateRfqOrderBook(defaultOrderBook);
        let currentMarketMakerValue = defaultQuoteBook.filter((key) => {
          return key.market_maker === currentMarketMaket[0].marketMaker;
        });

        updateRfqQuote(currentMarketMakerValue);
      }

      if (
        jspreadsheet.userSpread.length > 0 &&
        jspreadsheet.userSpread[0].userSpreadBid !== "NaN" &&
        jspreadsheet.userSpread[0].userSpreadOffer !== "NaN" &&
        userOrderBook.length > 0
      ) {
        let copyDealStataData = { ...defaultDealStats };
        let copyUserSpredDetails = [...jspreadsheet.userSpread];
        if (jspreadsheet.rfqParamaters.quote_type === "DOUBLE_LEG_VOL") {
          let defaultRawPrice = rawVolSpread(
            jspreadsheet.rfqParamaters,
            jspreadsheet.tableData,
          );
          let userRawPrice = rawVolSpread(
            jspreadsheet.rfqParamaters,
            userOrderBook,
          );
          if (defaultRawPrice === userRawPrice) {
            copyUserSpredDetails[0] = {
              ...copyUserSpredDetails[0],
              isUserChangeBid: copyUserSpredDetails[0].isUserChangeBid,
              isUserChangeOffer: copyUserSpredDetails[0].isUserChangeOffer,
              userSpreadBid: copyUserSpredDetails[0].userSpreadBid,
              userSpreadOffer: copyUserSpredDetails[0].userSpreadOffer,
            };
          } else {
            copyUserSpredDetails[0] = {
              ...copyUserSpredDetails[0],
              isUserChangeBid: false,
              isUserChangeOffer: false,
              userSpreadBid: copyUserSpredDetails[0].userSpreadBid,
              userSpreadOffer: copyUserSpredDetails[0].userSpreadOffer,
            };
            dispatch(
              rfqAggregatorSlice.setUsersSpreadhandler(copyUserSpredDetails),
            );
          }
        } else {
          let defaultRawPrice = `${jspreadsheet.tableData[0].bid}/${jspreadsheet.tableData[0].offer}`;
          let userRawPrice = `${userOrderBook[0].bid}/${userOrderBook[0].offer}`;
          if (defaultRawPrice === userRawPrice) {
            copyUserSpredDetails[0] = {
              ...copyUserSpredDetails[0],
              isUserChangeBid: copyUserSpredDetails[0].isUserChangeBid,
              isUserChangeOffer: copyUserSpredDetails[0].isUserChangeOffer,
              userSpreadBid: copyUserSpredDetails[0].userSpreadBid,
              userSpreadOffer: copyUserSpredDetails[0].userSpreadOffer,
            };
          } else {
            copyUserSpredDetails[0] = {
              ...copyUserSpredDetails[0],
              isUserChangeBid: false,
              isUserChangeOffer: false,
              userSpreadBid: copyUserSpredDetails[0].userSpreadBid,
              userSpreadOffer: copyUserSpredDetails[0].userSpreadOffer,
            };
            dispatch(
              rfqAggregatorSlice.setUsersSpreadhandler(copyUserSpredDetails),
            );
          }
        }

        if (jspreadsheet.rfqDefaultQuoteParameters.quote_type === "SWAP") {
          let regex = /[,\s]/g;
          let marketBid = copyDealStatsData.market_bid.replace(regex, "");
          let marketOffer = copyDealStatsData.market_offer.replace(regex, "");
          copyDealStatsData.makor_bid = (
            parse_float_custom(copyDealStatsData.market_bid) -
            parse_float_custom(copyUserSpredDetails[0].userSpreadBid)
          ).toFixed(3);
          copyDealStatsData.makor_offer = (
            parse_float_custom(copyDealStatsData.market_offer) +
            parse_float_custom(copyUserSpredDetails[0].userSpreadOffer)
          ).toFixed(3);

          // copyDealStatsData.makor_bid =
          //   +marketBid - +copyUserSpredDetails[0].userSpreadBid;
          // copyDealStatsData.makor_offer =
          //   +marketOffer +
          //   +copyUserSpredDetails[0].userSpreadOffer;
        } else {
          copyDealStatsData.makor_bid =
            Number(copyDealStatsData.market_bid) -
            Number(copyUserSpredDetails[0].userSpreadBid).toFixed(3);
          copyDealStatsData.makor_offer = (
            Number(copyDealStatsData.market_offer) +
            Number(copyUserSpredDetails[0].userSpreadOffer)
          ).toFixed(3);
        }

        let userDealStats = await apiGetDealStats(
          rfqParametersQuoteType(),
          jtableDict,
          jspreadsheet.rfqParamaters,
          copyDealStataData,
          userOrderBook,
          true,
        );
        dispatch(rfqSlice.updateDealStatsData(userDealStats));
        dispatch(rfqSlice.updateTableData(userOrderBook));
        if (userQuoteBook.length > 0) {
          return userQuoteBook.map((x) => {
            if (x.quote_text !== "") {
              x.trader = sessionStorage.getItem("userName");
            }
            return Object.values(x);
          });
        }
      } else {
        let defaultDealStats = await apiGetDealStats(
          rfqParametersQuoteType(),
          jtableDict,
          jspreadsheet.rfqParamaters,
          copyDealStatsData,
          userOrderBook,
          false,
        );
        if (defaultDealStats !== undefined) {
        }
        if (jspreadsheet.rfqParamaters.quote_type === "SWAP") {
          let regex = /[,\s]/g;
          let marketBid = defaultDealStats.market_bid.replace(regex, "");
          let makorBid = defaultDealStats.makor_bid.replace(regex, "");
          let marketOffer = defaultDealStats.market_offer.replace(regex, "");
          let makorOffer = defaultDealStats.makor_offer.replace(regex, "");

          let userSpredDetails = [
            {
              userSpreadBid: (+marketBid - +makorBid).toFixed(3),
              isBidUser: false,
              isUserChangeBid: "0",
              isUserChangeOffer: "0",
              isUserChangeSpreadOffer: "0",
              isUserChangeSpreadBid: "0",
              userSpreadOffer: (+makorOffer - +marketOffer).toFixed(3),
            },
          ];

          dispatch(rfqAggregatorSlice.setUsersSpreadhandler(userSpredDetails));
          dispatch(rfqSlice.updateTableData(userOrderBook));
          dispatch(rfqSlice.updateDealStatsData(defaultDealStats));
        } else {
          let userSpredDetails = [
            {
              userSpreadBid: (
                Number(defaultDealStats.market_bid) -
                Number(defaultDealStats.makor_bid)
              ).toFixed(3),
              isBidUser: false,
              isUserChangeBid: "0",
              isUserChangeOffer: "0",
              isUserChangeSpreadOffer: "0",
              isUserChangeSpreadBid: "0",
              userSpreadOffer: (
                Number(defaultDealStats.makor_offer) -
                Number(defaultDealStats.market_offer)
              ).toFixed(3),
            },
          ];
          dispatch(rfqAggregatorSlice.setUsersSpreadhandler(userSpredDetails));
          dispatch(rfqSlice.updateTableData(userOrderBook));
          dispatch(rfqSlice.updateDealStatsData(defaultDealStats));
        }
        if (userQuoteBook.length > 0) {
          return userQuoteBook.map((x) => {
            if (x.quote_text !== "") {
              x.trader = sessionStorage.getItem("userName");
            }
            return Object.values(x);
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateRfqQuote = async (currentMarketMakerValue) => {
    let table = jRef.current.jspreadsheet;
    const tableData = table.getData();
    const rowIndex = tableData.findIndex(
      (row) => row[0] === currentMarketMakerValue[0].market_maker,
    );
    let rfqQuote = {
      quoteText: currentMarketMakerValue,
      rfqId: sessionStorage.getItem("rfqId"),
      quoteType: jspreadsheet.rfqParamaters.quote_type,
      rowIndex,
    };

    const token = sessionStorage.getItem("token");
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}` + END_POINT.RFQ_QUOTE,
        { rfqQuote },
        { headers: { Authorization: token } },
      );

      if (response.data.length === 0) {
        let newInitialData = userMarketMakers.map((element) => [element, ""]);
        jRef.current.jspreadsheet.setData(newInitialData);
        api_initialize_deal_stats();
        // dispatch(rfqSlice.clearDealStatsData());
        dispatch(rfqSlice.updateTableData([]));
      }
      formatTable();
    } catch (error) {
      console.log(error);
    }
  };
  const api_initialize_deal_stats = async () => {
    let jtableDict = await createQuoteBookDict();
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_PYTON}` + `initialize_deal_stats`,
        {
          quote_type: jspreadsheet.rfqParamaters.quote_type,
          rfq_table_data: jtableDict,
          quote_parameters: jspreadsheet.rfqDefaultQuoteParameters,
        },
      );
      UpdateDealStatsData(response.data);
      dispatch(rfqSlice.updateDealStatsData(response.data));
    } catch (error) {
      console.log(error);
    }
  };
  const updateRfqOrderBook = async (rfqOrderBook) => {
    let rfqId = sessionStorage.getItem("rfqId");
    rfqOrderBook = rfqOrderBook.map((obj) => {
      return { ...obj, rfq_id: rfqId };
    });
    rfqOrderBook =
      rfqOrderBook.length === 0 ? [{ rfq_id: rfqId }] : rfqOrderBook;

    const token = sessionStorage.getItem("token");
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}` + END_POINT.ORDER_BOOK,
        { rfqOrderBook },
        { headers: { Authorization: token } },
      );
    } catch (error) {
      console.log(error);
    }
  };
  const UpdateDealStatsData = async (dealstateData) => {
    const token = sessionStorage.getItem("token");
    dealstateData = {
      ...dealstateData,
      rfq_id: sessionStorage.getItem("rfqId"),
    };

    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}` + END_POINT.DEAL_STATS,
        { dealstateData },
        { headers: { Authorization: token } },
      );
    } catch (error) {
      console.log(error);
    }
  };
  const create_quote_book_dict = () => {
    const quote_grid = jRef.current.jspreadsheet.getJson();

    const clone = [...quote_grid];
    return clone.map((x) => {
      return map_single_quote(x);
    });
  };
  const map_single_quote = (single_quote_object) => {
    let keys = Object.keys(single_quote_object);
    let key_map = {};
    keys.forEach(
      (key, i) =>
        (key_map[key] = active_table_headers()
          [i].replace(" ", "_")
          .toLowerCase()),
    );
    let mappedData = Object.keys(key_map).reduce(
      (obj, k) => Object.assign(obj, { [key_map[k]]: single_quote_object[k] }),
      {},
    );
    return mappedData;
  };
  const getCell = (col, row) => {
    var id = jspreadsheet.getColumnNameFromId([col, row]);
    var cell = jRef.current.jspreadsheet.getCell([id]);
    return cell;
  };
  const options = () => {
    let quote_type = jspreadsheet.rfqDefaultQuoteParameters.quote_type;

    let table_headers = [];
    let column_widths = [];

    if (quote_type === "DOUBLE_LEG_VOL") {
      table_headers = tableHeaders_vol_spread;
      column_widths = [90, 190, 100, 130, 80, 80, 90];
    } else {
      table_headers = tableHeaders_single_price;
      column_widths = [90, 150, 80, 80, 90];
    }
    let read_only_columns = set_read_only_columns(table_headers);
    return {
      data: initial_data,
      colHeaders: table_headers,
      colWidths: column_widths,
      tableHeight: "68vh",
      tableWidth: `1350px`,
      tableOverflow: true,
      columnSorting: false,
      colWidths: column_widths,
      allowInsertRow: false,
      allowInsertColumn: false,
      freezeColumns: 1,
      columns: read_only_columns,
      onchange: update_controller,
      onselection: selectionActive,
    };
  };
  const active_table_headers = () => {
    if (rfqParamaters.quote_type === undefined) {
      if (rfqid[0]?.quote_type === "DOUBLE_LEG_VOL") {
        return tableHeaders_vol_spread;
      } else {
        return tableHeaders_single_price;
      }
    } else {
      if (rfqParamaters.quote_type === "DOUBLE_LEG_VOL") {
        return tableHeaders_vol_spread;
      } else {
        return tableHeaders_single_price;
      }
    }
  };
  const cellId = (col, row) => {
    return `${alphabet[col].toUpperCase()}${row}`;
  };
  const formatTable = () => {
    let table = jRef.current.jspreadsheet;
    table.hideIndex();
    const market_maker = active_table_headers().indexOf("MARKET MAKER");
    const quote_text = active_table_headers().indexOf("QUOTE TEXT");
    const bid = active_table_headers().indexOf("BID");
    const offer = active_table_headers().indexOf("OFFER");
    const spread = active_table_headers().indexOf("SPREAD");
    const trader = active_table_headers().indexOf("TRADER");

    if (rfqParamatersCopy.quote_type === "DOUBLE_LEG_VOL") {
      for (let r = 1; r <= table.rows.length; r++) {
        const qv1 = active_table_headers().indexOf("QUOTED VOL1");
        const qv2 = active_table_headers().indexOf("QUOTED VOL2");
        table.setStyle(
          cellId(market_maker, r),
          "color",
          "#F0F8FF",
          "important",
        );
        table.setStyle(cellId(quote_text, r), "color", "#FFFFFF", "important");
        table.setStyle(cellId(bid, r), "color", " #00D865", "important");
        table.setStyle(cellId(offer, r), "color", "#FF3400", "important");
        table.setStyle(cellId(spread, r), "color", "#FFFFFF", "important");
        table.setStyle(cellId(trader, r), "color", "#FFFFFF", "important");
      }
    }
    if (
      rfqParamatersCopy.quote_type === "SINGLE_LEG_VOL" ||
      rfqParamatersCopy.quote_type === "PCT_PAYOUT" ||
      rfqParamatersCopy.quote_type === "PCT_NOTIONAL" ||
      rfqParamatersCopy.quote_type === "STRANGLE_STRADDLE" ||
      rfqParamatersCopy.quote_type === "SWAP"
    ) {
      for (let row = 1; row <= table.rows.length; row++) {
        table.setStyle(
          cellId(market_maker, row),
          "color",
          "#FFFFFF",
          "important",
        );
        table.setStyle(
          cellId(quote_text, row),
          "color",
          "#FFFFFF",
          "important",
        );
        table.setStyle(cellId(bid, row), "color", " #86FFF4", "important");
        table.setStyle(cellId(offer, row), "color", "#FFC5CD", "important");
        table.setStyle(cellId(spread, row), "color", "#FFFFFF", "important");
        table.setStyle(cellId(trader, row), "color", "#FFFFFF", "important");
      }
    }
  };
  const handleRefreshPage = async () => {
    let token = sessionStorage.getItem("token");
    let rfqId = sessionStorage.getItem("rfqId");
    if (rfqId !== null) {
      try {
        let rfqModalDetails = await axios.get(
          `${process.env.REACT_APP_BASE_URL}` +
            END_POINT.RFQ +
            "/" +
            END_POINT.GET_RFQ_MODALS_DETAILS +
            `/${rfqId}`,
          { headers: { Authorization: token } },
        );
        let {
          dealStats,
          orderBook,
          quoteParameters,
          rfqQuote,
          rfqDetails,
          quoteHighlight,
        } = rfqModalDetails.data;
        dealStats = [
          {
            ...dealStats[0],
            fwd_scale:
              dealStats[0].userSpreads.length > 0 &&
              dealStats[0].userSpreads[0].fwd_scale
                ? dealStats[0].userSpreads[0].fwd_scale
                : dealStats[0].fwd_scale,
          },
        ];
        sessionStorage.setItem("rfqStatus", rfqDetails[0]?.status);

        dispatch(
          rfqAggregatorSlice.setRfqDefaultQuoteParams(
            quoteParameters.defaultQuoteParameters[0],
          ),
        );
        let quote = rfqQuote.map((q) => {
          return { ...q, quoted_vol1: q.quotevol_1, quoted_vol2: q.quotevol_2 };
        });
        if (rfqQuote.length > 0) {
          if (quoteParameters.userQuoteParameters.length === 0) {
            if (
              dealStats[0].userSpreads.length > 0 &&
              dealStats[0].userSpreads[0].userSpreadBid !== "NaN" &&
              dealStats[0].userSpreads[0].userSpreadOffer !== "NaN"
            ) {
              dispatch(
                rfqAggregatorSlice.setUsersSpreadhandler(
                  dealStats[0].userSpreads,
                ),
              );
              let jtableDict = await createQuoteBookDict(rfqId);
              setrfq_object(jtableDict);
              let refreshQuoteBook = await apiRefreshQuoteBook(
                quoteParameters.defaultQuoteParameters[0].quote_type,
                quote,
                jtableDict,
                quoteParameters.defaultQuoteParameters[0],
              );

              let UpdatedorderBook = await buildOrderBook(refreshQuoteBook);
              let copyDealStatsData = { ...dealStats[0] };
              if (
                quoteParameters.defaultQuoteParameters[0].quote_type === "SWAP"
              ) {
                let regex = /[,\s]/g;
                let marketBid = copyDealStatsData.market_bid.replace(regex, "");
                let marketOffer = copyDealStatsData.market_offer.replace(
                  regex,
                  "",
                );
                copyDealStatsData.makor_bid = (
                  parse_float_custom(copyDealStatsData.market_bid) -
                  parse_float_custom(dealStats[0].userSpreads[0].userSpreadBid)
                ).toFixed(3);
                copyDealStatsData.makor_offer = (
                  parse_float_custom(copyDealStatsData.market_offer) +
                  parse_float_custom(
                    dealStats[0].userSpreads[0].userSpreadOffer,
                  )
                ).toFixed(3);
                // copyDealStatsData.makor_bid =
                //  ( +marketBid - +dealStats[0].userSpreads[0].userSpreadBid).toFixed(3);
                // copyDealStatsData.makor_offer =
                //  ( +marketOffer +
                //   +dealStats[0].userSpreads[0].userSpreadOffer).toFixed(3);
              } else {
                copyDealStatsData.makor_bid =
                  Number(copyDealStatsData.market_bid) -
                  Number(dealStats[0].userSpreads[0].userSpreadBid).toFixed(3);
                copyDealStatsData.makor_offer = (
                  Number(copyDealStatsData.market_offer) +
                  Number(dealStats[0].userSpreads[0].userSpreadOffer)
                ).toFixed(3);
              }
              let dealStatsData = await apiGetDealStats(
                quoteParameters.defaultQuoteParameters[0].quote_type,
                jtableDict,
                quoteParameters.defaultQuoteParameters[0],
                copyDealStatsData,
                UpdatedorderBook,
                true,
              );

              let initialDataTable = buildInitialDataTable(
                quote,
                refreshQuoteBook,
                initial_data,
                quoteParameters.defaultQuoteParameters[0],
              );
              dispatch(rfqSlice.updateTableData(UpdatedorderBook));
              dispatch(
                rfqAggregatorSlice.updateQuoteParameterts(
                  quoteParameters.defaultQuoteParameters[0],
                ),
              );
              dispatch(rfqSlice.updateDealStatsData(dealStatsData));
              if (
                quoteParameters.defaultQuoteParameters[0].quote_type ===
                "DOUBLE_LEG_VOL"
              ) {
                jRef.current.jspreadsheet.setHeader(tableHeaders_vol_spread);
              } else if (
                quoteParameters.defaultQuoteParameters[0].quote_type ===
                "SINGLE_LEG_VOL"
              ) {
                jRef.current.jspreadsheet.setHeader(tableHeaders_single_price);
              }
              jRef.current.jspreadsheet.setData(initialDataTable);
              initializeTableWithDataAndStyle(
                quoteHighlight,
                jRef,
                jspreadsheet,
              );
              formatTable();
            } else {
              if (
                quoteParameters.defaultQuoteParameters[0].quote_type === "SWAP"
              ) {
                let regex = /[,\s]/g;
                let marketBid = dealStats[0].market_bid.replace(regex, "");
                let makorBid = dealStats[0].makor_bid.replace(regex, "");
                let marketOffer = dealStats[0].market_offer.replace(regex, "");
                let makorOffer = dealStats[0].makor_offer.replace(regex, "");

                let userSpredDetails = [
                  {
                    userSpreadBid: (+marketBid - +makorBid).toFixed(3),
                    isBidUser: false,
                    isUserChangeBid: "0",
                    isUserChangeOffer: "0",
                    isUserChangeSpreadOffer: "0",
                    isUserChangeSpreadBid: "0",
                    userSpreadOffer: (+makorOffer - +marketOffer).toFixed(3),
                  },
                ];
                dispatch(
                  rfqAggregatorSlice.setUsersSpreadhandler(userSpredDetails),
                );
              } else {
                let userSpredDetails = [
                  {
                    userSpreadBid: (
                      Number(dealStats[0].market_bid) -
                      Number(dealStats[0].makor_bid)
                    ).toFixed(3),
                    isBidUser: false,
                    isUserChangeBid: "0",
                    isUserChangeOffer: "0",
                    isUserChangeSpreadOffer: "0",
                    isUserChangeSpreadBid: "0",
                    userSpreadOffer: (
                      Number(dealStats[0].makor_offer) -
                      Number(dealStats[0].market_offer)
                    ).toFixed(3),
                  },
                ];
                dispatch(
                  rfqAggregatorSlice.setUsersSpreadhandler(userSpredDetails),
                );
              }
              let jtableDict = await createQuoteBookDict(rfqId);
              setrfq_object(jtableDict);
              let refreshQuoteBook = await apiRefreshQuoteBook(
                quoteParameters.defaultQuoteParameters[0].quote_type,
                quote,
                jtableDict,
                quoteParameters.defaultQuoteParameters[0],
              );

              let UpdatedorderBook = await buildOrderBook(refreshQuoteBook);

              let dealStatsData = await apiGetDealStats(
                quoteParameters.defaultQuoteParameters[0].quote_type,
                jtableDict,
                quoteParameters.defaultQuoteParameters[0],
                dealStats[0],
                UpdatedorderBook,
                true,
              );

              let initialDataTable = buildInitialDataTable(
                quote,
                refreshQuoteBook,
                initial_data,
                quoteParameters.defaultQuoteParameters[0],
              );
              dispatch(rfqSlice.updateTableData(UpdatedorderBook));
              dispatch(
                rfqAggregatorSlice.updateQuoteParameterts(
                  quoteParameters.defaultQuoteParameters[0],
                ),
              );
              dispatch(rfqSlice.updateDealStatsData(dealStatsData));
              if (
                quoteParameters.defaultQuoteParameters[0].quote_type ===
                "DOUBLE_LEG_VOL"
              ) {
                jRef.current.jspreadsheet.setHeader(tableHeaders_vol_spread);
              } else if (
                quoteParameters.defaultQuoteParameters[0].quote_type ===
                "SINGLE_LEG_VOL"
              ) {
                jRef.current.jspreadsheet.setHeader(tableHeaders_single_price);
              }
              jRef.current.jspreadsheet.setData(initialDataTable);
              formatTable();
              initializeTableWithDataAndStyle(
                quoteHighlight,
                jRef,
                jspreadsheet,
              );
            }
          } else if (quoteParameters.userQuoteParameters.length > 0) {
            let jtableDict = await createQuoteBookDict(rfqId);
            setrfq_object(jtableDict);
            let refreshQuoteBook = await apiRefreshQuoteBook(
              quoteParameters.userQuoteParameters[0].quote_type,
              quote,
              jtableDict,
              quoteParameters.userQuoteParameters[0],
            );
            let updatedOrderBook = await buildOrderBook(refreshQuoteBook);
            let defaultDealStats = await apiGetDealStats(
              quoteParameters.userQuoteParameters[0].quote_type,
              jtableDict,
              quoteParameters.userQuoteParameters[0],
              dealStats[0],
              updatedOrderBook,
              false,
            );
            if (
              dealStats[0].userSpreads.length > 0 &&
              dealStats[0].userSpreads[0].userSpreadBid !== "NaN" &&
              dealStats[0].userSpreads[0].userSpreadOffer !== "NaN"
            ) {
              dispatch(
                rfqAggregatorSlice.setUsersSpreadhandler(
                  dealStats[0].userSpreads,
                ),
              );
              let copyDealStatsData = { ...defaultDealStats };
              if (
                quoteParameters.defaultQuoteParameters[0].quote_type === "SWAP"
              ) {
                let regex = /[,\s]/g;
                let marketBid = copyDealStatsData.market_bid.replace(regex, "");
                let marketOffer = copyDealStatsData.market_offer.replace(
                  regex,
                  "",
                );
                copyDealStatsData.makor_bid = (
                  parse_float_custom(copyDealStatsData.market_bid) -
                  parse_float_custom(dealStats[0].userSpreads[0].userSpreadBid)
                ).toFixed(3);
                copyDealStatsData.makor_offer = (
                  parse_float_custom(copyDealStatsData.market_offer) +
                  parse_float_custom(
                    dealStats[0].userSpreads[0].userSpreadOffer,
                  )
                ).toFixed(3);
                //   copyDealStatsData.makor_bid =
                //  (   +marketBid - +dealStats[0].userSpreads[0].userSpreadBid).toFixed(3);
                //   copyDealStatsData.makor_offer =
                //     (+marketOffer +
                //     +dealStats[0].userSpreads[0].userSpreadOffer).toFixed(3);
              } else {
                copyDealStatsData.makor_bid =
                  Number(copyDealStatsData.market_bid) -
                  Number(dealStats[0].userSpreads[0].userSpreadBid).toFixed(3);
                copyDealStatsData.makor_offer = (
                  Number(copyDealStatsData.market_offer) +
                  Number(dealStats[0].userSpreads[0].userSpreadOffer)
                ).toFixed(3);
              }
              let userDealStats = await apiGetDealStats(
                quoteParameters.userQuoteParameters[0].quote_type,
                jtableDict,
                quoteParameters.userQuoteParameters[0],
                copyDealStatsData,
                updatedOrderBook,
                true,
              );
              let initialDataTable = buildInitialDataTable(
                quote,
                refreshQuoteBook,
                initial_data,
                quoteParameters.userQuoteParameters[0],
              );

              if (
                quoteParameters.userQuoteParameters[0].quote_type ===
                "DOUBLE_LEG_VOL"
              ) {
                jRef.current.jspreadsheet.setHeader(tableHeaders_vol_spread);
              } else if (
                quoteParameters.userQuoteParameters[0].quote_type ===
                "SINGLE_LEG_VOL"
              ) {
                jRef.current.jspreadsheet.setHeader(tableHeaders_single_price);
              }
              jRef.current.jspreadsheet.setData(initialDataTable);
              formatTable();
              initializeTableWithDataAndStyle(
                quoteHighlight,
                jRef,
                jspreadsheet,
              );
              dispatch(rfqSlice.updateDealStatsData(userDealStats));
              dispatch(
                rfqAggregatorSlice.updateQuoteParameterts(
                  quoteParameters.userQuoteParameters[0],
                ),
              );
              dispatch(rfqSlice.updateTableData(updatedOrderBook));
            } else {
              let userDealStats = await apiGetDealStats(
                quoteParameters.userQuoteParameters[0].quote_type,
                jtableDict,
                quoteParameters.userQuoteParameters[0],
                dealStats[0],
                updatedOrderBook,
                false,
              );
              let initialDataTable = buildInitialDataTable(
                quote,
                refreshQuoteBook,
                initial_data,
                quoteParameters.userQuoteParameters[0],
              );
              if (
                quoteParameters.defaultQuoteParameters[0].quote_type === "SWAP"
              ) {
                let regex = /[,\s]/g;
                let marketBid = userDealStats.market_bid.replace(regex, "");
                let makorBid = userDealStats.makor_bid.replace(regex, "");
                let marketOffer = userDealStats.market_offer.replace(regex, "");
                let makorOffer = userDealStats.makor_offer.replace(regex, "");

                let userSpredDetails = [
                  {
                    userSpreadBid: (+marketBid - +makorBid).toFixed(3),
                    isBidUser: false,
                    isUserChangeBid: "0",
                    isUserChangeOffer: "0",
                    isUserChangeSpreadOffer: "0",
                    isUserChangeSpreadBid: "0",
                    userSpreadOffer: (+makorOffer - +marketOffer).toFixed(3),
                  },
                ];
                dispatch(
                  rfqAggregatorSlice.setUsersSpreadhandler(userSpredDetails),
                );
              } else {
                let userSpredDetails = [
                  {
                    userSpreadBid: (
                      Number(userDealStats.market_bid) -
                      Number(userDealStats.makor_bid)
                    ).toFixed(3),
                    isBidUser: false,
                    isUserChangeBid: "0",
                    isUserChangeOffer: "0",
                    isUserChangeSpreadOffer: "0",
                    isUserChangeSpreadBid: "0",
                    userSpreadOffer: (
                      Number(userDealStats.makor_offer) -
                      Number(userDealStats.market_offer)
                    ).toFixed(3),
                  },
                ];
                dispatch(
                  rfqAggregatorSlice.setUsersSpreadhandler(userSpredDetails),
                );
              }
              if (
                quoteParameters.userQuoteParameters[0].quote_type ===
                "DOUBLE_LEG_VOL"
              ) {
                jRef.current.jspreadsheet.setHeader(tableHeaders_vol_spread);
              } else if (
                quoteParameters.userQuoteParameters[0].quote_type ===
                "SINGLE_LEG_VOL"
              ) {
                jRef.current.jspreadsheet.setHeader(tableHeaders_single_price);
              }
              jRef.current.jspreadsheet.setData(initialDataTable);
              formatTable();
              initializeTableWithDataAndStyle(
                quoteHighlight,
                jRef,
                jspreadsheet,
              );

              dispatch(rfqSlice.updateDealStatsData(userDealStats));
              dispatch(
                rfqAggregatorSlice.updateQuoteParameterts(
                  quoteParameters.userQuoteParameters[0],
                ),
              );
              dispatch(rfqSlice.updateTableData(updatedOrderBook));
            }
          }
        } else {
          jRef.current.jspreadsheet.setData(initial_data);
          initializeTableWithDataAndStyle(quoteHighlight, jRef, jspreadsheet);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const is_forwarding_starting = () => {
    return rfqParamaters.near_date_text === "SP" ? false : true;
  };
  const is_notionals_same = () => {
    return rfqParamaters.near_notional === rfqParamaters.far_notional
      ? true
      : false;
  };
  const toggle_pricing_notional = () => {
    let rfqParamatersCopy = { ...rfqParamaters };
    if (
      rfqParamatersCopy.pricing_notional === rfqParamatersCopy.near_notional
    ) {
      rfqParamatersCopy.pricing_notional = rfqParamatersCopy.far_notional;
      rfqParamatersCopy.pricing_leg = "FAR";
    } else {
      rfqParamatersCopy.pricing_notional = rfqParamatersCopy.near_notional;
      rfqParamatersCopy.pricing_leg = "NEAR";
    }
    dispatch(rfqAggregatorSlice.updateQuoteParameterts(rfqParamatersCopy));
  };
  const quoteBookParams = () => {
    if (rfqParamaters?.quote_type === "SINGLE_LEG_VOL") {
      return <QuoteBookParamsSingleVol rfqParamaters={rfqParamaters} />;
    } else if (rfqParamaters?.quote_type === "STRANGLE_STRADDLE") {
      return <QuoteBookParamsStraddleStrangle rfqParamaters={rfqParamaters} />;
    } else if (rfqParamaters?.quote_type === "PCT_PAYOUT") {
      return <QuoteBookParamsPayout rfqParamaters={rfqParamaters} />;
    } else if (rfqParamaters?.quote_type === "PCT_NOTIONAL") {
      return <QuoteBookParamsPctNotional rfqParamaters={rfqParamaters} />;
    } else if (rfqParamaters?.quote_type === "DOUBLE_LEG_VOL") {
      return (
        <QuoteBookParamsDoubleVol
          rfqParamaters={rfqParamaters}
          rfq_object={rfq_object}
          create_quote_book_dict={create_quote_book_dict}
          set_jtable={set_jtable}
        />
      );
    } else if (rfqParamaters?.quote_type === "SWAP") {
      return (
        <QuoteBookParamsSwap
          rfqParamaters={rfqParamaters}
          is_forwarding_starting={is_forwarding_starting}
          is_notionals_same={is_notionals_same}
          toggle_pricing_notional={toggle_pricing_notional}
        />
      );
    }
  };
  const parse_float_custom = (number_string) => {
    if (isString(number_string)) {
      return parseFloat(number_string.replace(/,/g, ""));
    } else {
      return number_string;
    }
  };
  const isString = (x) => {
    return Object.prototype.toString.call(x) === "[object String]";
  };
  useEffect(async () => {
    jspreadsheet.rfqParamaters = rfqParamaters;
    jspreadsheet.userSpread = userSpread;
    jspreadsheet.rfqDefaultQuoteParameters = rfqDefaultQuoteParameters;
    jspreadsheet.tableData = tableData;
    jspreadsheet.dealStats = dealStatsData;
    if (!jRef.current.jspreadsheet) {
      jspreadsheet(jRef.current, options());
    }
    formatTable();
  }, [col, row, col2, jRef, userMarketMakers, rfq_object, userSpread]);
  useEffect(() => {
    handleRefreshPage();
  }, [rfqMode, rfqid]);

  useEffect(() => {
    const getRfqTrade = async (message) => {
      let initial_data = userMarketMakers.map((element) => [element, ""]);
      switch (message.data.type) {
        case "get_rfq_quote":
          const quoteRfqId = JSON.parse(message.data.rfqId);
          let quote = JSON.parse(message.data.rfqQuote).map((q) => {
            return {
              ...q,
              quoted_vol1: q.quotevol_1,
              quoted_vol2: q.quotevol_2,
            };
          });
          const token = sessionStorage.getItem("token");
          const rfqId = sessionStorage.getItem("rfqId");
          if (Number(quoteRfqId) === Number(sessionStorage.getItem("rfqId"))) {
            try {
              let rfqModalDetails = await axios.get(
                `${process.env.REACT_APP_BASE_URL}` +
                  END_POINT.RFQ +
                  "/" +
                  END_POINT.GET_RFQ_MODALS_DETAILS +
                  `/${rfqId}`,
                { headers: { Authorization: token } },
              );
              let {
                dealStats,
                orderBook,
                quoteParameters,
                rfqQuote,
                rfqDetails,
              } = rfqModalDetails.data;
              dealStats = [
                {
                  ...dealStats[0],
                  fwd_scale:
                    dealStats[0].userSpreads.length > 0 &&
                    dealStats[0].userSpreads[0].fwd_scale
                      ? dealStats[0].userSpreads[0].fwd_scale
                      : dealStats[0].fwd_scale,
                },
              ];
              dispatch(
                rfqAggregatorSlice.setRfqDefaultQuoteParams(
                  quoteParameters.defaultQuoteParameters[0],
                ),
              );

              if (quoteParameters.userQuoteParameters.length === 0) {
                if (
                  dealStats[0].userSpreads.length > 0 &&
                  dealStats[0].userSpreads[0].userSpreads !== "NaN" &&
                  dealStats[0].userSpreads[0].userSpreadOffer !== "NaN"
                ) {
                  dispatch(
                    rfqAggregatorSlice.setUsersSpreadhandler(
                      dealStats[0].userSpreads,
                    ),
                  );
                  let copyDealStatsData = { ...dealStats[0] };
                  if (
                    quoteParameters.defaultQuoteParameters[0].quote_type ===
                    "SWAP"
                  ) {
                    let regex = /[,\s]/g;
                    let marketBid = copyDealStatsData.market_bid.replace(
                      regex,
                      "",
                    );
                    let marketOffer = copyDealStatsData.market_offer.replace(
                      regex,
                      "",
                    );
                    copyDealStatsData.makor_bid = (
                      parse_float_custom(copyDealStatsData.market_bid) -
                      parse_float_custom(
                        dealStats[0].userSpreads[0].userSpreadBid,
                      )
                    ).toFixed(3);
                    copyDealStatsData.makor_offer = (
                      parse_float_custom(copyDealStatsData.market_offer) +
                      parse_float_custom(
                        dealStats[0].userSpreads[0].userSpreadOffer,
                      )
                    ).toFixed(3);
                    // copyDealStatsData.makor_bid =
                    // (  +marketBid - +dealStats[0].userSpreads[0].userSpreadBid).toFixed(3);
                    // copyDealStatsData.makor_offer =
                    //  ( +marketOffer +
                    //   +dealStats[0].userSpreads[0].userSpreadOffer).toFixed(3);
                  } else {
                    copyDealStatsData.makor_bid =
                      Number(copyDealStatsData.market_bid) -
                      Number(dealStats[0].userSpreads[0].userSpreadBid).toFixed(
                        3,
                      );
                    copyDealStatsData.makor_offer = (
                      Number(copyDealStatsData.market_offer) +
                      Number(dealStats[0].userSpreads[0].userSpreadOffer)
                    ).toFixed(3);
                  }
                  let jtableDict = await createQuoteBookDict();
                  setrfq_object(jtableDict);
                  let quoteBook = await apiRefreshQuoteBook(
                    quoteParameters.defaultQuoteParameters[0].quote_type,
                    quote,
                    jtableDict,
                    quoteParameters.defaultQuoteParameters[0],
                  );
                  let initialQuote = buildInitialDataInBlotterTable(
                    quote,
                    quoteBook,
                  );
                  let initialDataTable = buildInitialDataTable(
                    quote,
                    quoteBook,
                    initial_data,
                    quoteParameters.defaultQuoteParameters[0],
                  );
                  let orderBook = await buildOrderBook(initialQuote);
                  let dealStatsData = await apiGetDealStats(
                    quoteParameters.defaultQuoteParameters[0].quote_type,
                    jtableDict,
                    quoteParameters.defaultQuoteParameters[0],
                    copyDealStatsData,
                    orderBook,
                    true,
                  );
                  dispatch(rfqSlice.updateDealStatsData(dealStatsData));
                  dispatch(rfqSlice.updateTableData(orderBook));
                  dispatch(
                    rfqAggregatorSlice.updateQuoteParameterts(
                      quoteParameters.defaultQuoteParameters[0],
                    ),
                  );
                  const uniqueRowsWithBackground = getTableStyle(
                    message,
                    jRef,
                    rfq_id,
                  );

                  jRef.current.jspreadsheet.setData(initialDataTable);
                  // formatTable();
                  handleQuotePriceUpdate(
                    message,
                    jRef,
                    rfq_id,
                    jspreadsheet,
                    uniqueRowsWithBackground,
                  );
                } else {
                  if (
                    quoteParameters.defaultQuoteParameters[0].quote_type ===
                    "SWAP"
                  ) {
                    let regex = /[,\s]/g;
                    let marketBid = dealStats[0].market_bid.replace(regex, "");
                    let makorBid = dealStats[0].makor_bid.replace(regex, "");
                    let marketOffer = dealStats[0].market_offer.replace(
                      regex,
                      "",
                    );
                    let makorOffer = dealStats[0].makor_offer.replace(
                      regex,
                      "",
                    );

                    let userSpredDetails = [
                      {
                        userSpreadBid: (+marketBid - +makorBid).toFixed(3),
                        isBidUser: false,
                        isUserChangeBid: "0",
                        isUserChangeOffer: "0",
                        isUserChangeSpreadOffer: "0",
                        isUserChangeSpreadBid: "0",
                        userSpreadOffer: (+makorOffer - +marketOffer).toFixed(
                          3,
                        ),
                      },
                    ];
                    dispatch(
                      rfqAggregatorSlice.setUsersSpreadhandler(
                        userSpredDetails,
                      ),
                    );
                  } else {
                    let userSpredDetails = [
                      {
                        userSpreadBid: (
                          Number(dealStats[0].market_bid) -
                          Number(dealStats[0].makor_bid)
                        ).toFixed(3),
                        isBidUser: false,
                        isUserChangeBid: "0",
                        isUserChangeOffer: "0",
                        isUserChangeSpreadOffer: "0",
                        isUserChangeSpreadBid: "0",
                        userSpreadOffer: (
                          Number(dealStats[0].makor_offer) -
                          Number(dealStats[0].market_offer)
                        ).toFixed(3),
                      },
                    ];
                    dispatch(
                      rfqAggregatorSlice.setUsersSpreadhandler(
                        userSpredDetails,
                      ),
                    );
                  }
                  let jtableDict = await createQuoteBookDict();
                  setrfq_object(jtableDict);
                  let quoteBook = await apiRefreshQuoteBook(
                    quoteParameters.defaultQuoteParameters[0].quote_type,
                    quote,
                    jtableDict,
                    quoteParameters.defaultQuoteParameters[0],
                  );
                  let initialDataTable = buildInitialDataTable(
                    quote,
                    quoteBook,
                    initial_data,
                    quoteParameters.defaultQuoteParameters[0],
                  );
                  const uniqueRowsWithBackground = getTableStyle(
                    message,
                    jRef,
                    rfq_id,
                  );
                  jRef.current.jspreadsheet.setData(initialDataTable);
                  // formatTable();
                  handleQuotePriceUpdate(
                    message,
                    jRef,
                    rfq_id,
                    jspreadsheet,
                    uniqueRowsWithBackground,
                  );

                  dispatch(rfqSlice.updateTableData(orderBook));
                  dispatch(rfqSlice.updateDealStatsData(dealStats[0]));
                  dispatch(
                    rfqAggregatorSlice.updateQuoteParameterts(
                      quoteParameters.defaultQuoteParameters[0],
                    ),
                  );
                }
              } else if (quoteParameters.userQuoteParameters.length > 0) {
                let quote = rfqQuote.map((q) => {
                  return {
                    ...q,
                    quoted_vol1: q.quotevol_1,
                    quoted_vol2: q.quotevol_2,
                  };
                });
                let jtableDict = await createQuoteBookDict();
                setrfq_object(jtableDict);
                let refreshQuoteBook = await apiRefreshQuoteBook(
                  quoteParameters.userQuoteParameters[0].quote_type,
                  quote,
                  jtableDict,
                  quoteParameters.userQuoteParameters[0],
                );
                let initialDataTable = buildInitialDataTable(
                  quote,
                  refreshQuoteBook,
                  initial_data,
                  quoteParameters.userQuoteParameters[0],
                );

                let updatedOrderBook = await buildOrderBook(refreshQuoteBook);
                let defaultDealStats = await apiGetDealStats(
                  quoteParameters.userQuoteParameters[0].quote_type,
                  jtableDict,
                  quoteParameters.userQuoteParameters[0],
                  dealStats[0],
                  updatedOrderBook,
                  false,
                );

                if (
                  dealStats[0].userSpreads.length > 0 &&
                  dealStats[0].userSpreads[0].userSpreads !== "NaN" &&
                  dealStats[0].userSpreads[0].userSpreadOffer !== "NaN"
                ) {
                  dispatch(
                    rfqAggregatorSlice.setUsersSpreadhandler(
                      dealStats[0].userSpreads,
                    ),
                  );
                  let copyDealStatsData = { ...defaultDealStats };
                  if (
                    quoteParameters.defaultQuoteParameters[0].quote_type ===
                    "SWAP"
                  ) {
                    let regex = /[,\s]/g;
                    let marketBid = copyDealStatsData.market_bid.replace(
                      regex,
                      "",
                    );
                    let marketOffer = copyDealStatsData.market_offer.replace(
                      regex,
                      "",
                    );
                    copyDealStatsData.makor_bid = (
                      parse_float_custom(copyDealStatsData.market_bid) -
                      parse_float_custom(
                        dealStats[0].userSpreads[0].userSpreadBid,
                      )
                    ).toFixed(3);
                    copyDealStatsData.makor_offer = (
                      parse_float_custom(copyDealStatsData.market_offer) +
                      parse_float_custom(
                        dealStats[0].userSpreads[0].userSpreadOffer,
                      )
                    ).toFixed(3);
                    // copyDealStatsData.makor_bid =
                    //  ( +marketBid - +dealStats[0].userSpreads[0].userSpreadBid).toFixed(3);
                    // copyDealStatsData.makor_offer =
                    //  ( +marketOffer +
                    //   +dealStats[0].userSpreads[0].userSpreadOffer).toFixed(3);
                  } else {
                    copyDealStatsData.makor_bid =
                      Number(copyDealStatsData.market_bid) -
                      Number(dealStats[0].userSpreads[0].userSpreadBid).toFixed(
                        3,
                      );
                    copyDealStatsData.makor_offer = (
                      Number(copyDealStatsData.market_offer) +
                      Number(dealStats[0].userSpreads[0].userSpreadOffer)
                    ).toFixed(3);
                  }
                  let userDealStats = await apiGetDealStats(
                    quoteParameters.userQuoteParameters[0].quote_type,
                    jtableDict,
                    quoteParameters.userQuoteParameters[0],
                    copyDealStatsData,
                    updatedOrderBook,
                    true,
                  );
                  dispatch(rfqSlice.updateDealStatsData(userDealStats));
                  dispatch(
                    rfqAggregatorSlice.updateQuoteParameterts(
                      quoteParameters.userQuoteParameters[0],
                    ),
                  );
                  dispatch(rfqSlice.updateTableData(updatedOrderBook));

                  const uniqueRowsWithBackground = getTableStyle(
                    message,
                    jRef,
                    rfq_id,
                  );
                  jRef.current.jspreadsheet.setData(initialDataTable);
                  // formatTable();
                  handleQuotePriceUpdate(
                    message,
                    jRef,
                    rfq_id,
                    jspreadsheet,
                    uniqueRowsWithBackground,
                  );
                } else {
                  let userDealStats = await apiGetDealStats(
                    quoteParameters.userQuoteParameters[0].quote_type,
                    await createQuoteBookDict(),
                    quoteParameters.userQuoteParameters[0],
                    dealStats[0],
                    updatedOrderBook,
                    false,
                  );
                  if (
                    quoteParameters.defaultQuoteParameters[0].quote_type ===
                    "SWAP"
                  ) {
                    let regex = /[,\s]/g;
                    let marketBid = userDealStats.market_bid.replace(regex, "");
                    let makorBid = userDealStats.makor_bid.replace(regex, "");
                    let marketOffer = userDealStats.market_offer.replace(
                      regex,
                      "",
                    );
                    let makorOffer = userDealStats.makor_offer.replace(
                      regex,
                      "",
                    );

                    let userSpredDetails = [
                      {
                        userSpreadBid: (+marketBid - +makorBid).toFixed(3),
                        isBidUser: false,
                        isUserChangeBid: "0",
                        isUserChangeOffer: "0",
                        isUserChangeSpreadOffer: "0",
                        isUserChangeSpreadBid: "0",
                        userSpreadOffer: (+makorOffer - +marketOffer).toFixed(
                          3,
                        ),
                      },
                    ];
                    dispatch(
                      rfqAggregatorSlice.setUsersSpreadhandler(
                        userSpredDetails,
                      ),
                    );
                  } else {
                    let userSpredDetails = [
                      {
                        userSpreadBid: (
                          Number(userDealStats.market_bid) -
                          Number(userDealStats.makor_bid)
                        ).toFixed(3),
                        isBidUser: false,
                        isUserChangeBid: "0",
                        isUserChangeOffer: "0",
                        isUserChangeSpreadOffer: "0",
                        isUserChangeSpreadBid: "0",
                        userSpreadOffer: (
                          Number(userDealStats.makor_offer) -
                          Number(userDealStats.market_offer)
                        ).toFixed(3),
                      },
                    ];
                    dispatch(
                      rfqAggregatorSlice.setUsersSpreadhandler(
                        userSpredDetails,
                      ),
                    );
                  }
                  let jtableDict = await createQuoteBookDict();
                  setrfq_object(jtableDict);
                  let quoteBook = await apiRefreshQuoteBook(
                    quoteParameters.defaultQuoteParameters[0].quote_type,
                    quote,
                    jtableDict,
                    jspreadsheet.rfqParamaters,
                  );
                  let initialDataTable = buildInitialDataTable(
                    quote,
                    quoteBook,
                    initial_data,
                    jspreadsheet.rfqParamaters,
                  );

                  const uniqueRowsWithBackground = getTableStyle(
                    message,
                    jRef,
                    rfq_id,
                  );
                  jRef.current.jspreadsheet.setData(initialDataTable);
                  // formatTable();
                  handleQuotePriceUpdate(
                    message,
                    jRef,
                    rfq_id,
                    jspreadsheet,
                    uniqueRowsWithBackground,
                  );

                  dispatch(rfqSlice.updateDealStatsData(userDealStats));
                  dispatch(rfqSlice.updateTableData(updatedOrderBook));
                  dispatch(
                    rfqAggregatorSlice.updateQuoteParameterts(
                      quoteParameters.userQuoteParameters[0],
                    ),
                  );
                }
              }
            } catch (error) {
              console.log(error);
            }
          }
          break;
        case "update_rfq_status":
          let rfqStatus = message.data.rfqTrade;
          if (rfqStatus !== undefined) {
            dispatch(rfqSlice.updateRfqStatus(rfqStatus));
          }
          break;
        case "update_quote_status":
          handleQuoteUpdate(message, jRef, rfq_id, jspreadsheet);

          break;
        default:
          break;
      }
    };
    window.addEventListener("message", getRfqTrade);

    return () => {
      window.removeEventListener("message", getRfqTrade);
    };
  }, []);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (ws !== null) {
      sendEvent({
        type: "subscribe_to_rfq_quote_id",
        data: { rfqId: `${rfq_object.rfq_id}` },
        metaData: {},
      });
    } else {
      connectWS(token.replace("Bearer ", ""), dispatch, rfqSlice);
      sendEvent({
        type: "subscribe_to_rfq_quote_id",
        data: { rfqId: `${rfq_object.rfq_id}` },
        metaData: {},
      });
    }
  }, []);

  useEffect(() => {
    const handleMouseDown = (event) => {
      // Check if middle mouse button (button index 1) is pressed
      if (event.button === 1) {
        event.preventDefault(); // Prevent the default action
        event.stopPropagation();
      }
    };

    // Attach event listeners to the whole document
    document.addEventListener("mousedown", handleMouseDown);

    // Cleanup event listeners
    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  return (
    <Grid container>
      <Grid item container>
        <Grid item xs={8}>
          {quoteBookParams()}
          <Grid
            container
            item
            xs={12}
            className="rfqAggregator"
            style={{ display: "flex" }}
          >
            <Grid item xs={12}>
              <div className="jTable-aggregator" ref={jRef} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} style={{ top: "-37px", position: "relative" }}>
          <RfqInfoModal
            direction="column"
            rfq_object={rfq_object}
            setrfq_object={setrfq_object}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default RfqQuoteBook;
export const useStyles = makeStyles((theme) => ({
  "& .MuiInputBase-input MuiInput-input": {
    fontSize: "400px",
    color: "white",
  },
  rfqPctNotional: {
    border: "1px solid #686B76",
    padding: "20px",
    marginTop: "5px",
    borderRadius: "8px",
  },
  rfqParameters: {
    fontSize: "1.0625rem",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-around",
    border: "1px solid #686B76",
    padding: "20px",
    marginTop: "5px",
    height: "fit-content",
  },
  rfqCoiceLeg: {
    marginTop: "1%",
    border: "1px solid #686B76",
    borderRadius: " 8px",
    height: "20vh",
    width: "100%",
  },
  quoteParamatersHeader: {
    color: " var(--unnamed-color-989ea8)",
    font: "normal normal normal 12px/10px Inter",
    letterSpacing: "0px",
    color: " #989EA8",
  },
  quoteParamatersText: {
    marginTop: "5px",
    color: " var(--unnamed-color-989ea8)",
    font: "normal normal normal 10px/2px Inter",
    letterSpacing: "0px",
    color: "#FFFFFF",
  },
  legChoiceText: {
    textAlign: "center",
    letterSpacing: "0px",
    color: "#FFFFFF",
    width: "90%",
  },

  quoteParamsLeg1Ch: {
    [theme.breakpoints.up("lg")]: {
      marginLeft: "-8em !important",
    },

    [theme.breakpoints.up("xl")]: {
      marginLeft: "-3em !important",
    },
    [theme.breakpoints.between("lg", "xl")]: {
      marginLeft: "-3em !important",
    },
  },
  menuItem: {
    color: "white",
  },
  input: {
    border: "1px solid var(--unnamed-color-686b76) !important",
  },
  inputLeg: {
    color: "white",
    border: "1px solid #686B76",
    borderRadius: "4px",
    opacity: 1,
    width: "100%",
    // height: "40px",
  },
  toggleButton: {
    backgroundColor: "#FF681C",
    border: "1px solid #FF681C",
    height: "25px",
    color: "#FFFFFF",
    borderRadius: "4px",
    width: "90%",
    font: "bold normal normal 14px/16px Inter",
  },
  checkbox: {
    color: "white",
    "&.MuiIconButton-root": {
      borderRadius: "3px",
      height: "5px",
      width: "5px",
    },
    "&.Mui-checked": {
      color: "#13c552",
    },
  },
}));
