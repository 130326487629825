export const agFormatter = (params) => {
  if (
    params.data?.columnHeader === "Side" ||
    params.data?.columnHeader === "Side Option" ||
    params.data?.columnHeader === "Side Hedge"
  ) {
    return sideFormatter(params);
  } else if (
    params.data?.columnHeader === "Received At" ||
    params.data?.columnHeader === "Completed At" ||
    params.data?.columnHeader === "Executed At"
  ) {
    return dateFormatter(params);
  } else if (
    params.data?.columnHeader === "Cleared By" ||
    params.data?.columnHeader === "Client" ||
    params.data?.columnHeader === "Trader"
  ) {
    if (params?.value !== null && params?.value !== undefined) {
      return params.value
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    } else return undefined;
  } else if (
    params.data?.columnHeader === "Send Conformation" ||
    params.data?.columnHeader === "Initiator" ||
    params.data?.columnHeader === "Is Market Maker"
  ) {
    return `${params.value ? "Yes" : "No"}`;
  } else if (
    params.data?.columnHeader === "Initiation Fee" ||
    params.data?.columnHeader === "Market Maker Fee"
  ) {
    if (params.value !== null && params.value !== "") {
      return `${params.value * 100}%`;
    } else return undefined;
  } else if (params.data?.columnHeader === "Call/Put") {
    return callPutFormatter(params);
  } else if (
    params.data?.columnHeader === "Initiation" ||
    params.data?.columnHeader === "Market Maker" ||
    params.data?.columnHeader === "Net"
  ) {
    return Number.isInteger(Number(params?.value))
      ? params?.value
      : Number(params?.value).toFixed(2);
  } else return undefined;
};

export const sideFormatter = (params) => {
  return `${
    params.value === "S" ? "Sell" : params.value === "B" ? "Buy" : null
  }`;
};

export const callPutFormatter = (params) => {
  return `${
    params.value === "C" ? "Call" : params.value === "P" ? "Put" : null
  }`;
};

export const dateFormatter = (params) => {
  const inputDate = params.value;
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dateObj = new Date(inputDate);
  const year = dateObj.getFullYear();
  const month = months[dateObj.getMonth()];
  const day = dateObj.getDate();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const seconds = dateObj.getSeconds();

  const paddedHours = String(hours).padStart(2, "0");
  const paddedMinutes = String(minutes).padStart(2, "0");
  const paddedSeconds = String(seconds).padStart(2, "0");

  const convertedDate = `${year}-${month}-${day} ${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
  return convertedDate;
};

export const colHeaderFormatter = (params) => {
  if (
    params.data?.columnHeader === "Side Option" ||
    params.data?.columnHeader === "Side Hedge"
  ) {
    return "Side";
  } else return undefined;
};
