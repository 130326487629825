import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  mainBody: {
    fontFamily: "Inter, sans-serif !important",
  },
  headerTypography: {
    color: "white",
    fontSize: "32px",
    marginTop: "21px",
    marginBottom: "21px",
    font: "normal normal normal 32px Inter",
  },
  clientsContainer: {
    height: "90vh",
    backgroundColor: "#222222",
    borderRadius: "12px",
  },

  TypographyContainer: {
    height: "46px",
    backgroundColor: "#171717",
    borderRadius: "12px 12px 0 0",
    border: "1px solid #686B76",
  },
  containerTypography: {
    color: "white",
    marginLeft: "15px",
    font: "normal normal normal 24px/26px Inter",
    height: "30px",
  },
  containerTypographyWithBorder: {
    color: "white",
    // marginLeft: "15px",
    font: "normal normal normal 24px/26px Inter",
    height: "30px",
    // borderLeft: "1px solid #686B76",
    paddingLeft: "15px",
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      left: 0,
      top: "15%", // Adjust the top value to position the border
      bottom: "15%", // Adjust the bottom value to control the height
      borderLeft: "1px solid #686B76",
      width: 0,
    },
  },
  columnsContainer: {
    height: "100%",
  },
  clientsColumn: {
    height: "90%",
    borderRight: "1px solid #686B76",
  },
  tradersColumn: {
    height: "90%",
    borderRight: "1px solid #686B76",
  },
  staticColumn: {
    height: "70%",
    borderRight: "1px solid #686B76",
  },
  searchInput: {
    font: "normal normal normal 16px/20px Inter",
    color: "white",
    border: "1px solid #686B76",
    borderRadius: "4px",
    height: "40px",
    width: "355px",
    paddingLeft: "10px",
    marginTop: "25px",
    "& .MuiSvgIcon-root": {
      color: "#2692ff",
      fontSize: "25px",
    },
  },
  circularProgress: {
    "& .MuiCircularProgress-svg": {
      color: "white",
    },
  },
  companiesContainer: {
    marginTop: "15px",
    color: "white",
  },
  companyTypography: {
    color: "white",
    font: "normal normal normal 16px/20px Inter",
  },
  companiesIsOpenIcon: {
    color: "#2692FF",
    fontSize: "20px",
  },
  itemsListClients: {
    maxHeight: "150px",
    overflow: "auto",
    backgroundColor: "#2D2D2D",
  },
  itemsListTraders: {
    marginLeft: "5px",
    overflow: "auto",
    maxHeight: "800px",
  },
  listItemText: {
    color: "white",
    paddingTop: "10px",
    marginLeft: "25px",
    "& .MuiListItemText-primary": {
      font: "normal normal normal 16px/20px Inter",
    },
  },
  listItemSelected: {
    "&.MuiListItem-root:hover": {
      backgroundColor: "#34495c",
    },
    "&.MuiListItem-root.Mui-selected": {
      backgroundColor: "#2B3C4C", // The color you want for the selected item
      "&:hover": {
        backgroundColor: "#405970", // Optional: if you want to maintain the color on hover
      },
    },
  },

  playIcon: {
    paddingTop: "10px",
    color: "#2692FF",
  },
  subHeaderContainer: {
    marginTop: "15px",
  },

  tradersListContainer: {
    marginTop: "25px",
    maxHeight: "500px",
    // overflow: "auto",
  },
  traderGrid: {
    height: "40px",
    borderBottom: "3px solid rgba(104, 107, 118, 0.3)",
  },
  traderTypography: {
    marginLeft: "25px",
    color: "white",
    font: "normal normal normal 16px/20px Inter",
  },
  itemListContainer: {
    borderBottom: "3px solid rgba(104, 107, 118, 0.3)",
  },
  grayHeader: {
    paddingTop: "15px",
    paddingLeft: "15px",
    color: "#686B76",
    font: "normal normal normal 20px/22px Inter",
  },
  grayHeaderContainer: {
    borderBottom: "3px solid rgba(104, 107, 118, 0.3)",
    width: "100%",
    height: "50px",
  },
  staticColumn1: {
    marginTop: "15px",
    border: "1px solid #3A3C3F",
    borderRadius: "8px 0px 0px 0px",
    height: "900px",
    borderRight: "none",
    alignContent: "flex-start",
  },
  staticColumn2: {
    marginTop: "15px",
    border: "1px solid #3A3C3F",
    borderRadius: "0px 8px 0px 0px",
    height: "900px",
    alignContent: "flex-start",
  },
  createIcon: {
    paddingTop: "10px",
    color: "#00CA80",
  },
  deleteIcon: {
    paddingTop: "10px",
    color: "#FF3939",
    marginLeft: "10px",
  },
  switchIcon: {
    paddingTop: "10px",
    color: "#2692FF",
    marginLeft: "10px",
  },
  itemsListEmails: {
    maxHeight: "220px",
    overflow: "auto",
    backgroundColor: "#2D2D2D",
  },
  addContactBtn: {
    marginTop: "10px",
    marginBottom: "10px",
    width: "100%",
    color: "#2692FF",
    backgroundColor: "#2B3C4C",
    borderRadius: "8px",
    font: "normal normal normal 16px/20px Inter",
    "&:hover": {
      backgroundColor: "#405970",
      color: "white",
    },
  },
  emailInput: {
    marginTop: "10px",
    width: "80%",
    marginRight: "60px",
    backgroundColor: "transparent",
    color: "white",
    font: "normal normal normal 16px/20px Inter",
  },
  clearanceAccountsItem: {
    color: "white",
    font: "normal normal normal 16px/20px Inter",
    paddingLeft: "15px",
    paddingTop: "20px",
    height: "40px",
    borderBottom: "3px solid rgba(104, 107, 118, 0.3)",
  },
  
}));
