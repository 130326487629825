import React, { useEffect, useState } from "react";
import { Grid, Paper, Tabs, Tab } from "@material-ui/core";
import { ReactComponent as Makor } from "../../../../assets/makorLogoSmallBlue.svg";
import { useStyles } from "../../../../Styles/pricerStyle";

export const Header = ({ tabs, tabValue, setTabValue }) => {
  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      item
      xs={12}
    >
      <Grid item>
        <Makor />
      </Grid>
      <Grid item xs>
        <Paper className={classes.paperTabsContainer}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
            className={classes.tabs}
            TabIndicatorProps={{ style: { display: "none" } }}
            style={{ marginTop: "15px" }}
          >
            {tabs.map((tab, i) => {
              return (
                <Tab
                  label={tab.label}
                  value={tab.value}
                  className={`${
                    tabValue === tab.value
                      ? classes.selectedTab
                      : classes.notSelectedTab
                  } ${classes.dataFeedTab}`}
                />
              );
            })}
          </Tabs>
        </Paper>
      </Grid>
    </Grid>
  );
};
