import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    emailDialogPaper: {
      width: "492px",
      height: "397px",
      background: "#222222",
    },
    input: {
      border: "1px solid #686B76",
      borderRadius: "4px",
      opacity: 1,
      maxWidth: "311px",
      width: "265px",
      height: "34px",
      maxHeight: "40px",
      marginBottom: "20px",
      "& .MuiInputBase-input": {
        color: "#ffffff",
        fontSize: "1.3125rem",
        padding: "10px 10px 11px",
      },
    },
    resend: {
      fontSize: "1.125rem",
      cursor:"pointer",
      textAlign: "right",
      textDecoration: "underline",
      letterSpacing: 0,
      color: "#2E81EC",
      opacity: 1,
    },
    changeButton: {
      fontSize: "1.125rem",
      backgroundColor: "#1565c0",
      color: "#ffffff",
      textTransform: "none",
      width: "197px",
      height: "32px",
    },
  }));
  