import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { END_POINT } from "../../utils/index";
import { clearLoginState } from "../users/usersSlice";

export const getUserMarketMaker = () => async (dispatch) => {
  const token = sessionStorage.getItem("token");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}` +
        END_POINT.MARKET_MAKER +
        "/user_market_maker",
      {
        headers: { Authorization: token },
      },
    );
    let marketMakers = response.data.map((key) => {
      return key.name;
    });
    dispatch(getUserMarketMakerSuccess(marketMakers));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(clearLoginState());
      }
    }
  }
};
export const getMarketMaker = () => async (dispatch) => {
  const token = sessionStorage.getItem("token");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}` + END_POINT.MARKET_MAKER,
      {
        headers: { Authorization: token },
      },
    );
    let marketMakers = response.data.map((key) => {
      return { name: key.name, id: key.id, checked: false };
    });
    dispatch(getMarketMakerSuccess(marketMakers));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(clearLoginState());
      }
    }
  }
};

export let rfqAggregatorSlice = createSlice({
  name: "rfqAggregatorSlice",
  initialState: {
    userMarketMakers: { items: [], isLoading: true },
    marketMakers: { items: [], isLoading: true },
    newArr: [],
    RfqQuoteParameters: {},
    volLegParams: [],
    rfqDefaultQuoteParams: {},
    isQuoteParamsChanged: false,
    rfqQuote:[],
    rfqDealStats:{},
    userSpread:[]
  },
  reducers: {
    setUserSpread: (state, action) => {
     
      state.userSpread = action.payload;
    },
    setRfqQuote: (state, action) => {
      state.rfqQuote = action.payload;
    },
    setIsQuoteParamsChanged: (state, action) => {
      state.isQuoteParamsChanged = action.payload;
    },
    setRfqDefaultQuoteParams: (state, action) => {
      state.rfqDefaultQuoteParams = action.payload;
    },
   
    updateQuoteParameterts: (state, action) => {
      state.RfqQuoteParameters = action.payload;
    },
    clearQuoteParameterts: (state, action) => {
      state.RfqQuoteParameters = {};
    },
    updateNewArr: (state, action) => {
      state.newArr = action.payload;
    },
    getUserMarketMakerSuccess: (state, action) => {
      state.userMarketMakers.items = action.payload;
      state.userMarketMakers.isLoading = false;
    },
    getMarketMakerSuccess: (state, action) => {
      state.marketMakers.items = action.payload;
      state.marketMakers.isLoading = false;
    },
    updateMarketMakers: (state, action) => {
      state.marketMakers.items = action.payload;
    },
    updateUserMarketMakers: (state, action) => {
      let marketMakers = action.payload.map((key) => {
        return key.name;
      });
      state.userMarketMakers.items = marketMakers;
    },
  },
});


export const preLoadQuoteParametertsThunk = (data) => (dispatch, getState) => {
  const RfqQuoteParameters = getState().rfqAggregatorSlice.RfqQuoteParameters;
  dispatch(updateQuoteParameterts({ ...data }));
};

export const setQuotesParamsThunk = (data) => (dispatch, getState) => {
  const { rfq_id, value, key } = data;
  const volLegParams = getState().rfqAggregatorSlice.volLegParams;
  const RfqQuoteParameters = getState().rfqAggregatorSlice.RfqQuoteParameters;
  const RfqQuoteParametersCopy = { ...RfqQuoteParameters };
  const volLegParamsCopy = [...volLegParams];
  const filterByRfqId = volLegParamsCopy.filter(
    (params) => Number(params.rfq_id) === Number(rfq_id),
  );
  if (filterByRfqId.length === 0) {
    let vol_leg1, vol_leg2;
    if (key === "choice_vol") {
      if (Number(RfqQuoteParameters.choice_leg) === 1) {
        vol_leg1 = value;
        vol_leg2 = RfqQuoteParametersCopy.bbg_vol2;
      } else if (Number(RfqQuoteParameters.choice_leg) === 2) {
        vol_leg1 = RfqQuoteParametersCopy.bbg_vol1;
        vol_leg2 = value;
      }
      volLegParamsCopy.push({
        ...RfqQuoteParameters,
        rfq_id,
        choice_vol: value,
        vol_leg1,
        vol_leg2,
      });
    } else if (key === "choice_leg") {
      vol_leg1 = RfqQuoteParametersCopy.bbg_vol1;
      vol_leg2 = RfqQuoteParametersCopy.bbg_vol2;
      volLegParamsCopy.push({
        ...RfqQuoteParameters,
        rfq_id,
        choice_leg: value,
        vol_leg1,
        vol_leg2,
      });
    }
    dispatch(setVolLegParams(volLegParamsCopy));
  } else {
    const index = volLegParamsCopy.findIndex(
      (params) => Number(params.rfq_id) === Number(rfq_id),
    );
    let vol_leg1, vol_leg2;
    if (key === "choice_vol") {
      if (Number(RfqQuoteParameters.choice_leg) === 1) {
        vol_leg1 = value;
        vol_leg2 = filterByRfqId[0].vol_leg2;
      } else if (Number(RfqQuoteParameters.choice_leg) === 2) {
        vol_leg1 = filterByRfqId[0].vol_leg1;
        vol_leg2 = value;
      }

      const obj = {
        ...filterByRfqId[0],
        rfq_id,
        choice_vol: value,
        vol_leg1,
        vol_leg2,
      };
      volLegParamsCopy.splice(index, 1, obj);
      dispatch(setVolLegParams(volLegParamsCopy));
    } else if (key === "choice_leg") {
      const obj = {
        ...filterByRfqId[0],
        rfq_id,
        choice_leg: value,
        choice_vol:
          Number(value) === 1
            ? filterByRfqId[0].vol_leg1
            : filterByRfqId[0].vol_leg2,
      };
      volLegParamsCopy.splice(index, 1, obj);
      dispatch(setVolLegParams(volLegParamsCopy));
    }
  }
};

export const setUsersSpreadhandler = (data) => (dispatch, getState) => {

  const userSpread = getState().rfqAggregatorSlice.userSpread
  const userSpreadCopy=[...userSpread][0]
  const newSpread = {...userSpreadCopy, ...data[0]}
  dispatch(setUserSpread([newSpread]))

}

export const {
  setIsQuoteParamsChanged,
  setRfqDefaultQuoteParams,
  getUserMarketMakerSuccess,
  getMarketMakerSuccess,
  updateMarketMakers,
  updateUserMarketMakers,
  updateNewArr,
  updateQuoteParameterts,
  clearQuoteParameterts,
  setVolLegParams,
  setRfqQuote,
  setUserSpread
} = rfqAggregatorSlice.actions;
export default rfqAggregatorSlice.reducer;
