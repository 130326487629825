import React, { useState, useEffect } from "react";
import WifiOffSharpIcon from "@material-ui/icons/WifiOffSharp";
import WifiSharpIcon from "@material-ui/icons/WifiSharp";
import { useStyles } from "../../../Styles/pricerStyle";
import { Grid } from "@material-ui/core";
import workerInstances from "../../../services/index";

export const SocketConnectivity = ({ platform }) => {
  const [wsPricerConnection, setWsPricerConnection] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    const handleWsConnection = async (message) => {
      switch (message.data.security) {
        case "connection_status":
          setWsPricerConnection(message.data.status);

          break;
        default:
          break;
      }
    };
    if (platform === "derivs_pricer")
      workerInstances?.WebSocketPricesInstance?.addEventListener(
        "message",
        handleWsConnection,
      );
    else if (platform === "derivs_pricer_mini_feed")
      workerInstances?.WebSocketPricesInstance2?.addEventListener(
        "message",
        handleWsConnection,
      );
    return () => {
      if (platform === "derivs_pricer")
        workerInstances?.WebSocketPricesInstance?.removeEventListener(
          "message",
          handleWsConnection,
        );
      else if (platform === "derivs_pricer_mini_feed")
        workerInstances?.WebSocketPricesInstance2?.removeEventListener(
          "message",
          handleWsConnection,
        );
    };
  }, []);

  return (
    <Grid
      className={
        platform === "derivs_pricer_mini_feed"
          ? classes.connectivityIconContainerMiniFeed
          : classes.connectivityIconContainer
      }
    >
      {wsPricerConnection ? (
        <WifiSharpIcon
          className={
            platform === "derivs_pricer_mini_feed"
              ? classes.connectivityIconOnMiniFeed
              : classes.connectivityIconOn
          }
        />
      ) : (
        <WifiOffSharpIcon
          className={
            platform === "derivs_pricer_mini_feed"
              ? classes.connectivityIconOffMiniFeed
              : classes.connectivityIconOff
          }
        />
      )}
    </Grid>
  );
};
