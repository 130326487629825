import { Grid, IconButton, InputAdornment, InputBase } from "@material-ui/core";

import React, { useEffect, useState } from "react";
import { useStyles } from "../../../Styles/pricerStyle";
import SearchIcon from "@material-ui/icons/Search";
import { useDebounce } from "../derivsPricerBlotter/utils/utilsFunctions";

import { ws, connectWS, sendEvent } from "../../../services/websocket";
import * as rfqSlice from "../../../store/rfq/rfqSlice";
import { useDispatch } from "react-redux";
import { Header } from "./components/Header";
import { getHistoryByTicker, getMarketMakers } from "./utils/apiCalls";
import { TableByTab } from "./components/TableByTab";

export const DataFeed = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [searchValue, setSearchValue] = useState(null);
  const [tickerParticipates, setTickerParticipates] = useState([]);
  const [marketMakers, setMarketMakers] = useState(null);
  const [tabs, setTabs] = useState([
    { label: "Trade History", value: "history" },
    { label: "Market Maker Lookup", value: "marketMaker" },
  ]);
  const [tabValue, setTabValue] = useState("history");

  const handleSearchByValue = async (searchValue) => {
    setSearchValue(searchValue);
  };
  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const handleDebouncedSearch = async () => {
    // if (tabValue === "history") {
    getHistoryByTicker(searchValue, setTickerParticipates);
    // } else if (tabValue === "marketMaker") {
    getMarketMakers(searchValue, setMarketMakers);
    // }
  };

  useEffect(() => {
    searchValue !== null && handleDebouncedSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm]);
  //screen resize handler
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  //connect to server socket
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (ws !== null) {
      sendEvent({
        type: "derivs_pricer",
        data: {},
        metaData: {},
        token: token.replace("Bearer ", ""),
        isMiniFeed: true,
      });
    } else {
      connectWS(token.replace("Bearer ", ""), dispatch, rfqSlice);

      sendEvent({
        type: "derivs_pricer",
        data: {},
        metaData: {},
        token: token.replace("Bearer ", ""),
        isMiniFeed: true,
      });
    }
  }, []);

  useEffect(() => {
    const handleDerivs = async (message) => {
      switch (message.data.type) {
        case "new_derivs_order":
          const userId = sessionStorage.getItem("userId");
          if (Number(message.data.order.userId) === Number(userId)) {
            setSearchValue(message.data.order.symbol);
          }

          break;

        default:
          break;
      }
    };

    window.addEventListener("message", handleDerivs);

    return () => {
      window.removeEventListener("message", handleDerivs);
    };
  }, []);

  return (
    <div
      style={{
        height: windowHeight - 4,
        width: windowWidth - 4,
        overflowY: "auto",
      }}
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item container className={classes.miniFeedNav} xs={12}>
        {/* header + tab */}
        <Header tabs={tabs} tabValue={tabValue} setTabValue={setTabValue} />

        {/* search by value */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          item
          xs={12}
          sm={11}
        >
          <InputBase
            style={{
              color: "white",
              border: "1px solid #686B76",
              borderRadius: "4px",
              height: "32px",
              width: "180px",
            }}
            className={classes.searchInput}
            variant="outlined"
            onChange={(e) => handleSearchByValue(e.target.value.toUpperCase())}
            value={searchValue}
            placeholder="Search"
            endAdornment={
              <InputAdornment position="start">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </Grid>
      </Grid>

      <TableByTab
        tabValue={tabValue}
        tickerParticipates={tickerParticipates}
        marketMakers={marketMakers}
      />
    </div>
  );
};
