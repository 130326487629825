import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  searchInput: {
    paddingLeft: "10px",
    "& .MuiSvgIcon-root": {
      color: "#2692ff",
    },
  },
  linkBtn: {
    width: "70px",
    borderRadius: 4,
    border: "1px solid #FFB300",
    textTransform: "none",
    color: "#FFB300",
    fontSize: 14,
    "&:hover": {
      backgroundColor: "#FFB300",
      color: "#000000",
    },
  },
  checkbox: {
    color: "#686B76",
    "&.MuiIconButton-root": {
      borderRadius: "3px",
      height: "9px",
      width: "10px",
    },
    "&.Mui-checked": {
      color: "#13c552",
    },
  },
}));
