import React, { useState, useEffect, useCallback } from "react";
import { Grid, Typography, Box, CircularProgress } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import workerInstances from "../../../services/index";
import axios from "axios";
import { END_POINT } from "../../../utils";
import { testDateWithinRange } from "../derivsPricerBlotter/utils/utilsFunctions.js";
import {
  subscribeOrdersToSocket,
  unsubscribeFromSocket,
  unsubscribeAllFromSocket,
  unsubscribeOnSearch,
} from "../derivsPricerBlotter/utils/apiCalls";
import { DerivsTableByOrder } from "../priceTableByOrder/DerivsTableByOrder";
import { useSelector } from "react-redux";
import { useStyles } from "../../../Styles/pricerStyle";

export const FavouritesBlotter = () => {
  const classes = useStyles();
  const [ordersList, setOrdersList] = useState([]);
  const [isOrderListLoading, setIsOrderListLoading] = useState(true);
  const [pageNum, setPageNum] = useState(1);
  const [wsPricerConnection, setWsPricerConnection] = useState(false);
  const [isFirstConnection, setIsFirstConnection] = useState(true);

  const parsedString = useSelector(
    (state) => state.derivsPricerSlice.parsedString,
  );
  const updateOrderFutureTicker = useCallback(
    async ({ order, ordersList, strategiesList }) => {
      const { colId, orderId, value } = order.updateBody;

      const ordersListCopy = [...ordersList];
      const ordersListIndex = ordersList.findIndex(
        (order) => Number(order.id) === Number(orderId),
      );

      if (ordersListIndex !== -1) {
        unsubscribeFromSocket(value);
        ordersListCopy.splice(ordersListIndex, 1, value);
        setOrdersList(ordersListCopy);
        subscribeOrdersToSocket([value]);
      }
    },
    [ordersList, pageNum],
  );
  const handlePagination = async (e, value) => {
    setIsOrderListLoading(true);
    const updatedOrdersList = await Promise.all(
      ordersList.map(async (order, index) => {
        if (
          (value === 1 && index < 10) ||
          (value > 1 && (value - 1) * 10 <= index && index < value * 10)
        ) {
          subscribeOrdersToSocket([order]);
          const { symbol, equityIndex } = order;
          const tickerString = (
            symbol.replaceAll("/", "%252f") +
            " " +
            equityIndex
          )
            .replaceAll("/", "|")
            .replaceAll(" ", "%20");
          const res = await axios.get(
            `${
              process.env.REACT_APP_BLOOMBERG_DERIVS
            }equity/${tickerString.toUpperCase()}`,
          );
          const { div_date, name, price, report_date, sector, ticker } =
            res.data;
          const dividend = await testDateWithinRange(div_date);
          const earnings = await testDateWithinRange(report_date);
          return {
            ...order,
            dividend,
            name,
            lastPrice: price,
            earnings,
            sector,
            ticker,
          };
        } else {
          unsubscribeOnSearch([order]);
          return { ...order };
        }
      }),
    );
    setOrdersList(updatedOrdersList);
    setIsOrderListLoading(false);

    setPageNum(value);
  };

  useEffect(() => {
    getFavList(setOrdersList, setIsOrderListLoading, pageNum);
    return () => {
      unsubscribeAllFromSocket();
    };
  }, []);

  useEffect(() => {
    const handleUpdate = async (message) => {
      switch (message.data.type) {
        case "derivs_pricer_update_fav":
          const updateFav = async () => {
            const { orderId, isFavorite, order } = message.data;

            const copyOrdersList = [...ordersList];

            const ordersListIndex = copyOrdersList.findIndex(
              (s) => Number(s.id) === Number(orderId),
            );
            if (!isFavorite && ordersListIndex !== -1) {
              unsubscribeFromSocket(copyOrdersList[ordersListIndex]);
              copyOrdersList.splice(ordersListIndex, 1);
              setOrdersList(copyOrdersList);
              const orderToSubscribe = ordersList.find(
                (order, index) => index === pageNum * 10,
              );
              if (orderToSubscribe !== undefined) {
                subscribeOrdersToSocket([orderToSubscribe]);
              }
            } else if (isFavorite) {
              const ordersListCopy = [...ordersList];
              const { symbol, equityIndex, futureTicker } = order;
              const tickerString = (
                futureTicker !== null
                  ? futureTicker + " " + equityIndex
                  : symbol.replaceAll("/", "%252f") + " " + equityIndex
              )
                .replaceAll("/", "|")
                .replaceAll(" ", "%20");
              const res = await axios.get(
                `${
                  process.env.REACT_APP_BLOOMBERG_DERIVS
                }equity/${tickerString.toUpperCase()}`,
              );
              const {
                div_date,
                name,
                price,
                report_date,
                sector,
                ticker,
                has_adr,
                country,
              } = res.data;
              const dividend = await testDateWithinRange(div_date);
              const earnings = await testDateWithinRange(report_date);

              ordersListCopy.unshift({
                ...order,
                dividend,
                name,
                lastPrice: price,
                earnings,
                sector,
                ticker,
                has_adr,
                country,
                isFavorite,
              });

              setOrdersList(ordersListCopy);
              subscribeOrdersToSocket([order]);
              const orderToUnsubscribe = ordersListCopy.find(
                (order, index) => index === pageNum * 10,
              );
              if (orderToUnsubscribe !== undefined) {
                unsubscribeFromSocket(orderToUnsubscribe);
              }
            }
          };
          updateFav();
          break;
        case "update_future_ticker":
          updateOrderFutureTicker({
            order: message.data,
            ordersList,
          });

          break;
        default:
          break;
      }
    };

    const handleWsConnection = async (message) => {
      switch (message.data.security) {
        case "connection_status":
          if (isFirstConnection && !message.data.status) {
            setIsFirstConnection(false);
          }
          setWsPricerConnection(message.data.status);
          break;
        default:
          break;
      }
    };
    window.addEventListener("message", handleUpdate);
    workerInstances?.WebSocketPricesInstance?.addEventListener(
      "message",
      handleWsConnection,
    );
    return () => {
      window.removeEventListener("message", handleUpdate);
      workerInstances?.WebSocketPricesInstance?.removeEventListener(
        "message",
        handleWsConnection,
      );
    };
  }, [ordersList]);
  useEffect(() => {
    if (wsPricerConnection && !isFirstConnection) {
      getFavList(setOrdersList, setIsOrderListLoading, pageNum);
    }
  }, [wsPricerConnection]);

  return (
    <Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{
          height: parsedString.length > 0 ? "65vh" : "75vh",
          minWidth: "96vw",
          // maxHeight: "80vh",
          marginTop: "15px",
        }}
      >
        <Grid item xs={12} sm={6} md={4}>
          <Typography
            style={{
              fontSize: 22,
              marginBottom: "20px",
              color: "#828282",
              opacity: "0.8",
              height: "40px",
            }}
          >
            Favourites Blotter
          </Typography>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          alignContent="flex-start"
          wrap="wrap"
          style={{
            height: parsedString.length > 0 ? "65vh" : "75vh",
            overflow: "scroll",
            overflowX: "hidden",
          }}
        >
          {isOrderListLoading ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          ) : ordersList.length > 0 ? (
            ordersList.map((order, index) => {
              return (
                <DerivsTableByOrder
                  order={order}
                  key={index}
                  setOrdersList={setOrdersList}
                  ordersList={ordersList}
                  tab={"favourites"}
                />
              );
            })
          ) : (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                style={{
                  color: "#828282",
                  opacity: "0.8",
                  fontSize: "15px",
                }}
              >
                No Active Orders
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid className={classes.pagination}>
        <Pagination
          count={
            ordersList.length % 10 > 0
              ? Math.floor(ordersList.length / 10) + 1
              : ordersList.length / 10
          }
          page={pageNum}
          onChange={handlePagination}
          size="large"
          // disabled={ordersList.length < 6}
        />
      </Grid>
    </Grid>
  );
};

export const getFavList = async (
  setOrdersList,
  setIsOrderListLoading,
  pageNum,
) => {
  const token = sessionStorage.getItem("token");
  const res = await axios.get(
    `${process.env.REACT_APP_BASE_URL}${END_POINT.GET_FAV_LIST}/`,
    { headers: { Authorization: token } },
  );
  setOrdersList(res.data.ordersListWithLegs);
  setIsOrderListLoading(false);
  if (res.data.ordersListWithLegs.length > 0) {
    const ordersList = await Promise.all(
      res.data.ordersListWithLegs.map(async (order, index) => {
        if (
          (pageNum === 1 && index < 10) ||
          (pageNum > 10 && (pageNum - 1) * 10 <= index && index < pageNum * 10)
        ) {
          const { symbol, equityIndex, futureTicker } = order;
          const tickerString = (
            futureTicker !== null
              ? futureTicker + " " + equityIndex
              : symbol.replaceAll("/", "%252f") + " " + equityIndex
          )
            .replaceAll("/", "|")
            .replaceAll(" ", "%20");
          const res = await axios.get(
            `${
              process.env.REACT_APP_BLOOMBERG_DERIVS
            }equity/${tickerString.toUpperCase()}`,
          );
          const {
            div_date,
            name,
            price,
            report_date,
            sector,
            ticker,
            has_adr,
            country,
          } = res.data;
          const dividend = await testDateWithinRange(div_date);
          const earnings = await testDateWithinRange(report_date);
          subscribeOrdersToSocket([order]);
          return {
            ...order,
            dividend,
            name,
            lastPrice: price,
            earnings,
            sector,
            ticker,
            has_adr,
            country,
          };
        } else {
          return {
            ...order,
          };
        }
      }),
    );
    setOrdersList(ordersList);
  } else {
    setIsOrderListLoading(false);
  }
};
