import {
  Button,
  ButtonGroup,
  Dialog,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useStyles } from "../../../Styles/fiStyles";
import FreeTextInput from "../freeTextInput/FreeTextInput";
import SelectOption from "../Select/Select";
import * as clientActions from "../../../store/fi/fiClientsSlice";
import clsx from "clsx";
import GppCodeDialog from "../gppCodeDialog/GppCodeDialog";

const ClientsDialog = (props) => {
  const dispatch = useDispatch();
  const { open, type, handleClose } = props;
  const classes = useStyles();
  const [clientType, setClientType] = useState("client");
  const { tradersList, newClientValues, selectedClient } = useSelector(
    (state) => state.fiClientsSlice,
  );
  const [openGppCodeDialog, setOpenGppCodeDialog] = useState(false);

  const openGppCodeDialogAction = (type) => {
    setOpenGppCodeDialog(!openGppCodeDialog);
  };
  const handleGppCodeDialogClose = () => {
    setOpenGppCodeDialog(!openGppCodeDialog);
  };
  useEffect(() => {
    if (open && type === "edit") {
      const obj = {
        key: "client_id",
        value: selectedClient.id,
      };
      dispatch(clientActions.editNewClientValues(obj));
      setClientType(selectedClient.clientType);
      const obj1 = {
        value: selectedClient.clientType,
        key: "client_type",
      };
      dispatch(clientActions.editNewClientValues(obj1));
    } else if (open && type === "new") {
      setClientType("client");
    } else if (open && type === "copy") {
      const obj = { value: selectedClient.gppCode, key: "gppCode" };
      dispatch(clientActions.editNewClientValues(obj));
    }
  }, [open]);

  const ssiObj = {
    label: "SSI",
    values: [
      { id: "ECLR", value: "ECLR" },
      { id: "CEDE", value: "CEDE" },
      { id: "FDNY", value: "FDNY" },
    ],
    key: "ssi",
    type,
  };
  const companyObj = {
    label: "Company",
    values: [
      { id: "Makor", value: "Makor" },
      { id: "Oscar Gruss", value: "Oscar Gruss" },
    ],
    key: "company",
    type,
  };
  const handleSubmit = () => {
    if (type === "copy" || type === "new") {
      dispatch(clientActions.createNewClientThunk(handleClose));
    } else if (type === "edit") {
      dispatch(clientActions.UpdateClientDetailsThunk(handleClose));
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
          dispatch(clientActions.clearNewClientValues());
        }}
        classes={{ paper: classes.dialogPaper }}
        PaperProps={{
          style: {
            backgroundColor: "#2D2D2D",
          },
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ padding: "30px 84px 32px 75px", position: "absolute" }}
        >
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={6}>
                <Typography style={{ fontSize: "1.25rem", color: "#ffffff" }}>
                  {type === "new"
                    ? "New Client"
                    : type === "edit"
                    ? "Edit Client"
                    : type === "copy"
                    ? "Copy client"
                    : null}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <ButtonGroup className={classes.btnGroup}>
                  <Button
                    className={clsx(classes.btnInside, {
                      [classes.chosenBtnInside]: clientType === "client",
                    })}
                    onClick={() => {
                      setClientType("client");
                      dispatch(
                        clientActions.editNewClientValues({
                          key: "clientType",
                          value: "client",
                        }),
                      );
                    }}
                  >
                    Client
                  </Button>
                  <Button
                    className={clsx(classes.btnInside, {
                      [classes.chosenBtnInside]: clientType === "market maker",
                    })}
                    onClick={() => {
                      setClientType("market maker");
                      dispatch(
                        clientActions.editNewClientValues({
                          key: "clientType",
                          value: "market maker",
                        }),
                      );
                    }}
                  >
                    Market Maker
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container justifyContent="space-between" xs={12}>
            <Grid item>
              <FreeTextInput
                values={{ label: "BBG/Makor Code", key: "bbgMakorCode", type }}
              />
            </Grid>
            {/* <Grid item>
              <FreeTextInput
                values={{ label: "Broker Ref", key: "brokerRef", type }}
              />
            </Grid> */}
            <Grid item>
              <FreeTextInput
                values={{
                  label: "Sub Accounts / Code ",
                  key: "subAccountsCode",
                  type,
                }}
              />
            </Grid>
          </Grid>
          <Grid item container justifyContent="space-between" xs={12}>
            <Grid item>
              <FreeTextInput
                values={{ label: "Firm Name", key: "firmName", type }}
              />
            </Grid>
            <Grid item>
              <FreeTextInput values={{ label: "Lei", key: "lei", type }} />
            </Grid>

            <Grid
              item
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-end"
              style={{ marginTop: "20px" }}
            >
              {type === "edit" ? (
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  xs={6}
                >
                  <Grid item xs={6} container>
                    <Button
                      onClick={() => openGppCodeDialogAction()}
                      className={clsx(classes.gppCodeBtn, {
                        [classes.disabledButton]: true,
                      })}
                    >
                      Gpp Codes
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <FreeTextInput
                      values={{
                        label: "Merrill Acc",
                        key: "merrillAcc",
                        type,
                      }}
                    />
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={11}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontSize: "1.25rem",
                    color: "#ffffff",
                    marginTop: "10px",
                  }}
                >
                  Settlement
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container justifyContent="space-between" xs={12}>
            <Grid item>
              <SelectOption values={ssiObj} />
            </Grid>
            <Grid item>
              <SelectOption values={companyObj} />
            </Grid>

            {newClientValues.ssi !== "FDNY" ? (
              <Grid item>
                <FreeTextInput
                  values={{
                    label: "Account",
                    key: "account",
                    type,
                  }}
                />
              </Grid>
            ) : null}
          </Grid>
          {newClientValues.ssi === "FDNY" ? (
            <>
              <Grid item container justifyContent="space-between" xs={12}>
                <Grid item>
                  <FreeTextInput
                    values={{ label: "Agent Name", key: "agentName", type }}
                  />
                </Grid>
                <Grid item>
                  <FreeTextInput
                    values={{
                      label: "Agent BIC",
                      key: "agentBic",
                      type,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item container justifyContent="space-between" xs={12}>
                <Grid item>
                  <FreeTextInput
                    values={{ label: "Local Code", key: "localCode", type }}
                  />
                </Grid>
                <Grid item>
                  <FreeTextInput
                    values={{
                      label: "Clearance A/C",
                      key: "clearanceAc",
                      type,
                    }}
                  />
                </Grid>
              </Grid>
            </>
          ) : null}

          <Grid item xs={11}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontSize: "1.25rem",
                    color: "#ffffff",
                    marginTop: "10px",
                  }}
                >
                  Confirmation
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container justifyContent="space-between" xs={12}>
            <Grid item>
              <FreeTextInput
                values={{
                  label: "Confirmation Email 1",
                  key: "confirmationEmail1",
                  type,
                }}
              />
            </Grid>
            <Grid item>
              <FreeTextInput
                values={{
                  label: "Confirmation Email 2",
                  key: "confirmationEmail2",
                  type,
                }}
              />
            </Grid>
          </Grid>
          <Grid item container justifyContent="space-between" xs={12}>
            <Grid item>
              <FreeTextInput
                values={{
                  label: "Confirmation Email 3",
                  key: "confirmationEmail3",
                  type,
                }}
              />
            </Grid>
            <Grid item>
              <FreeTextInput
                values={{
                  label: "Confirmation Email 4",
                  key: "confirmationEmail4",
                  type,
                }}
              />
            </Grid>
          </Grid>
          <IconButton
            size="small"
            onClick={() => {
              handleClose();
              dispatch(clientActions.clearNewClientValues());
            }}
            style={{
              marginRight: 5,
              position: "relative",
              backgroundColor: "#3a3c3f",
              bottom: "500px",
              left: "520px",
            }}
          >
            <CloseIcon style={{ color: "#bababa", fontSize: "1.3125rem" }} />
          </IconButton>
        </Grid>
        <Grid
          justifyContent="space-around"
          container
          xs={12}
          style={{ position: "absolute", bottom: "25px" }}
        >
          <Button
            item
            variant="contained"
            className={classes.btn}
            onClick={() => {
              handleClose();
              dispatch(clientActions.clearNewClientValues());
            }}
          >
            Cancel
          </Button>
          <Button
            item
            variant="contained"
            className={classes.btn}
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </Button>
        </Grid>
        <GppCodeDialog
          open={openGppCodeDialog}
          handleClose={handleGppCodeDialogClose}
        />
      </Dialog>
    </>
  );
};

export default ClientsDialog;
