import React, { useEffect, useState, useRef } from "react";
import { Grid, Paper, Tab, Tabs } from "@material-ui/core";
import { ClientRequest } from "../clientRequest/ClientRequest";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import { ws, connectWS, sendEvent } from "../../../services/websocket";
import * as rfqSlice from "../../../store/rfq/rfqSlice";

import { DerivsPriceBlotter } from "../derivsPricerBlotter/DerivsPriceBlotter";
import workerInstances from "../../../services/index";
import { useStyles } from "../../../Styles/pricerStyle";
import { FavouritesBlotter } from "../favouritesBlotter/FavouritesBlotter";
import { SocketConnectivity } from "../socketConnectivity/SocketConnectivity";
import { BookingBlotter } from "../bookingBlotter/BookingBlotter";

export const DerivsPricerHome = () => {
  const [tab, setTab] = useState("blotter");
  const [tabsList, setTabsList] = useState([
    { label: "blotter", value: "blotter" },
    { label: "favourites", value: "favourites" },
    { label: "booking", value: "booking" },
  ]);
  const miniFeedWindowRef = useRef(null);
  const historyWindowRef = useRef(null);

  const [isWsConnected, setIsWsConnected] = useState(false);

  const classes = useStyles();

  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");
  const parsedString = useSelector(
    (state) => state.derivsPricerSlice.parsedString,
  );
  const handleTabChange = (e, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    if (ws !== null) {
      sendEvent({
        type: "derivs_pricer",
        data: {},
        metaData: {},
        token: token.replace("Bearer ", ""),
      });
    } else {
      connectWS(token.replace("Bearer ", ""), dispatch, rfqSlice);

      sendEvent({
        type: "derivs_pricer",
        data: {},
        metaData: {},
        token: token.replace("Bearer ", ""),
      });
    }

    workerInstances?.WebSocketPricesInstance?.connectPricerWS();

    return () => {
      workerInstances.WebSocketPricesInstance.closeWS();
    };
  }, []);
  useEffect(() => {
    // openMiniFeed(); // Call the function when the component mounts

    // return () => {
    //   if (miniFeedWindowRef.current) {
    //     miniFeedWindowRef.current.close();
    //   }
    // };
    const closeMiniFeed = () => {
      if (miniFeedWindowRef.current && !miniFeedWindowRef.current.closed) {
        miniFeedWindowRef.current.close();
        miniFeedWindowRef.current = null;
      }
    };
    const closeHistory = () => {
      if (historyWindowRef.current && !historyWindowRef.current.closed) {
        historyWindowRef.current.close();
        historyWindowRef.current = null;
      }
    };

    // Attach the event listener to the 'beforeunload' event
    window.addEventListener("beforeunload", closeMiniFeed);
    window.addEventListener("beforeunload", closeHistory);

    return () => {
      // Clean up the event listener when the component is unmounted or closed
      window.removeEventListener("beforeunload", closeMiniFeed);
      window.removeEventListener("beforeunload", closeHistory);
      closeMiniFeed();
      closeHistory();
    };
  }, []);

  return (
    <Grid container>
      <Grid
        item
        style={{
          // maxWidth: "1110px",
          minHeight: "180px",
        }}
      >
        <ClientRequest />
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.tabsPaper}>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            className={classes.tabs}
          >
            {tabsList.map((tab) => {
              return (
                <Tab
                  value={tab.value}
                  label={tab.label}
                  className={classes.tab}
                />
              );
            })}
          </Tabs>
        </Paper>
      </Grid>
      <Grid
        item
        style={{
          width: "100vw",
          overflow: "scroll",
          overflowY: "hidden",
          overflowX: "hidden",
        }}
      >
        {tab === "blotter" ? (
          <DerivsPriceBlotter
            miniFeedWindowRef={miniFeedWindowRef}
            historyWindowRef={historyWindowRef}
            currentTab={tab}
          />
        ) : tab === "favourites" ? (
          <FavouritesBlotter />
        ) : tab === "booking" ? (
          <BookingBlotter />
        ) : null}
      </Grid>

      <SocketConnectivity platform={"derivs_pricer"} />
    </Grid>
  );
};
