export const cellKeyDown = async (
  e,
  rowData,
  setRowData,
  order,
  parsedBookingText,
) => {
  if (order.status.toLowerCase() === "live") {
    var keyPressed = e.event.code;
    const rowIndex = e.node.rowIndex;
    const colId = e.column.colId;
    const newData = [...rowData];
    const sideIndex = newData.findIndex((r) => r.id === "side");
    const initiatorIndex = newData.findIndex((r) => r.id === "initiator");
    const initiationIndex = newData.findIndex((r) => r.id === "initiation");
    const commissionIndex = newData.findIndex((r) => r.id === "commission");
    const floorBrokerFeeIndex = newData.findIndex(
      (r) => r.id === "floor_broker_fee",
    );
    const executionFeeIndex = newData.findIndex(
      (r) => r.id === "execution_fee",
    );
    const initiationFeeIndex = newData.findIndex(
      (r) => r.id === "initiation_fee",
    );
    const marketMakerFeeIndex = newData.findIndex(
      (r) => r.id === "market_maker_fee",
    );
    const isMarketMakerIndex = newData.findIndex(
      (r) => r.id === "is_market_maker",
    );
    const crossedFeeRateIndex = newData.findIndex(
      (r) => r.id === "crossed_fee_rate",
    );
    const notCrossedFeeIndex = newData.findIndex(
      (r) => r.id === "not_crossed_fee_rate",
    );
    const marketMakerIndex = newData.findIndex((r) => r.id === "market_maker");

    if (
      (keyPressed === "Space" && e.data.columnHeader === "Side") ||
      (keyPressed === "Enter" && e.data.columnHeader === "Side")
    ) {
      const newValue = e.value === "S" ? "B" : "S";
      if (
        rowIndex >= 0 &&
        rowIndex < rowData.length &&
        colId in rowData[rowIndex]
      ) {
        // Create a copy of the original array
        newData[rowIndex] = { ...newData[rowIndex], [colId]: newValue }; // Update the specific value
        setRowData(newData); // Update the state with the new array
      }
    } else if (
      (keyPressed === "Space" && e.data.columnHeader === "Send Conformation") ||
      (keyPressed === "Enter" && e.data.columnHeader === "Send Conformation")
    ) {
      const newValue = e.value ? false : true;
      if (
        rowIndex >= 0 &&
        rowIndex < rowData.length &&
        colId in rowData[rowIndex]
      ) {
        // Create a copy of the original array
        newData[rowIndex] = { ...newData[rowIndex], [colId]: newValue }; // Update the specific value
        setRowData(newData); // Update the state with the new array
      }
    } else if (
      (keyPressed === "Space" && e.data.columnHeader === "Initiator") ||
      (keyPressed === "Enter" && e.data.columnHeader === "Initiator")
    ) {
      const initiator = e.value ? false : true;

      if (
        rowIndex >= 0 &&
        rowIndex < rowData.length &&
        colId in rowData[rowIndex]
      ) {
        const initiationFee = newData[initiationFeeIndex][colId];
        const marketMakerFee = newData[marketMakerFeeIndex][colId];
        const commission = newData[commissionIndex][colId];
        const isMarketMaker = newData[isMarketMakerIndex][colId];
        const floorBrokerFee = newData[floorBrokerFeeIndex][colId];
        const executionFee = newData[executionFeeIndex][colId];
        const crossedFeeRate = newData[crossedFeeRateIndex][colId];
        const notCrossedFeeRate = newData[notCrossedFeeIndex][colId];
        let floorBrokerFeeObj = newData[floorBrokerFeeIndex];
        let initiation = newData[initiationIndex][colId];
        let marketMaker = newData[marketMakerIndex][colId];
        let marketMakerRow = newData[marketMakerIndex];
        let oldInitiatorExec = null;
        const updatedData = newData.map((row) => {
          if (row.id === "initiator") {
            if (initiator) {
              const execKeys = Object.keys(row).filter(
                (key) => key.startsWith("exec") && key !== colId,
              );

              for (const key of execKeys) {
                if (row.hasOwnProperty(key)) {
                  if (row[key]) {
                    oldInitiatorExec = key;
                  }
                  row = { ...row, [key]: false };
                }
              }

              return { ...row, [colId]: initiator };
            } else {
              return { ...row, [colId]: initiator };
            }
          } else if (row.id === "floor_broker_fee") {
            let totalQuantityBuy = 0;
            let totalQuantitySell = 0;
            // Aggregate total quantities for options and hedges across all clients
            let execTotalQuantityById = new Map();
            parsedBookingText.executions.forEach((exec, index) => {
              let totalQuantity = 0;
              exec.option.forEach((option) => {
                if (exec.side.toLowerCase() === "b") {
                  totalQuantityBuy += option.quantity;
                  totalQuantity += option.quantity;
                } else if (exec.side.toLowerCase() === "s") {
                  totalQuantitySell += option.quantity;
                  totalQuantity += option.quantity;
                }
              });
              exec.hedge.forEach((hedge) => {
                if (exec.side.toLowerCase() === "b") {
                  totalQuantityBuy += hedge.quantity;
                  totalQuantity += hedge.quantity;
                } else if (exec.side.toLowerCase() === "s") {
                  totalQuantitySell += hedge.quantity;
                  totalQuantity += hedge.quantity;
                }
              });

              execTotalQuantityById.set(index, totalQuantity);
            });

            const totalCrossed = Math.min(totalQuantityBuy, totalQuantitySell);
            let availableCrossed = totalCrossed;
            const totalNotCrossed = Math.abs(
              totalQuantityBuy - totalQuantitySell,
            );

            let sideNotCrossed = null,
              sideCrossed = null;

            if (totalQuantityBuy >= totalQuantitySell) {
              sideNotCrossed = "b";
              sideCrossed = "s";
            } else {
              sideNotCrossed = "s";
              sideCrossed = "b";
            }

            const initiatorIndex = initiator
              ? Number(colId.replace("exec", "")) - 1
              : null;
            if (initiatorIndex !== null) {
              const initiatorSide = rowData[sideIndex][colId];

              if (sideNotCrossed === initiatorSide.toLowerCase()) {
                console.log("sideNotCrossed === initiatorSide");
                const initiatorTotalQuantity =
                  execTotalQuantityById.get(initiatorIndex);
                if (availableCrossed >= initiatorTotalQuantity) {
                  console.log("availableCrossed >= initiatorTotalQuantity");
                  const crossedQuantity = Math.min(
                    availableCrossed,
                    initiatorTotalQuantity,
                  );
                  availableCrossed -= crossedQuantity;
                  const initiatorFloorBrokerFee =
                    crossedFeeRate * crossedQuantity;
                  row = {
                    ...row,
                    [colId]: Number(initiatorFloorBrokerFee.toFixed(2)),
                    floorBrokerFeeRate: {
                      ...row.floorBrokerFeeRate,
                      [initiatorIndex]: (
                        Number(initiatorFloorBrokerFee) / initiatorTotalQuantity
                      ).toFixed(2),
                    },
                  };
                 

                  parsedBookingText.executions.forEach((exec, index) => {
                    if (index !== initiatorIndex) {
                      if (
                        exec.side.toLowerCase() === initiatorSide.toLowerCase()
                      ) {
                        const execQuantity = execTotalQuantityById.get(index);
                        const crossedQuantity = Math.min(
                          availableCrossed,
                          execQuantity,
                        );
                        const notCrossedQuantity = Math.abs(
                          execQuantity - crossedQuantity,
                        );
                        availableCrossed -= crossedQuantity;
                        const floorBrokerFee =
                          crossedFeeRate * crossedQuantity +
                          notCrossedFeeRate * notCrossedQuantity;
                        row = {
                          ...row,
                          [`exec${index + 1}`]: Number(
                            floorBrokerFee.toFixed(2),
                          ),
                          floorBrokerFeeRate: {
                            ...row.floorBrokerFeeRate,
                            [index]: (
                              Number(floorBrokerFee) / execQuantity
                            ).toFixed(2),
                          },
                        };
                       
                      } else if (exec.side !== initiatorSide) {
                        const execQuantity = execTotalQuantityById.get(index);
                        const floorBrokerFee = crossedFeeRate * execQuantity;
                        row = {
                          ...row,
                          [`exec${index + 1}`]: Number(
                            floorBrokerFee.toFixed(2),
                          ),
                          floorBrokerFeeRate: {
                            ...row.floorBrokerFeeRate,
                            [index]: (
                              Number(floorBrokerFee) / execQuantity
                            ).toFixed(2),
                          },
                        };
                       
                      }
                    }
                  });

                  console.log(row);
                } else if (availableCrossed < initiatorTotalQuantity) {
                  console.log("availableCrossed < initiatorTotalQuantity");
                  const crossedQuantity = Math.min(
                    availableCrossed,
                    initiatorTotalQuantity,
                  );
                  const notCrossedQuantity = Math.abs(
                    initiatorTotalQuantity - crossedQuantity,
                  );
                  availableCrossed -= crossedQuantity;
                  const initiatorFloorBrokerFee =
                    crossedFeeRate * crossedQuantity +
                    notCrossedFeeRate * notCrossedQuantity;
                  //initiator floor broker fee
                  row = {
                    ...row,
                    [colId]: Number(initiatorFloorBrokerFee.toFixed(2)),
                    floorBrokerFeeRate: {
                      ...row.floorBrokerFeeRate,
                      [initiatorIndex]: (
                        Number(initiatorFloorBrokerFee) / initiatorTotalQuantity
                      ).toFixed(2),
                    },
                  };
                  

                  //rest of the clients on that side
                  parsedBookingText.executions.forEach((exec, index) => {
                    if (index !== initiatorIndex) {
                      if (
                        exec.side.toLowerCase() === initiatorSide.toLowerCase()
                      ) {
                        const execQuantity = execTotalQuantityById.get(index);
                        const floorBrokerFee = notCrossedFeeRate * execQuantity;
                        row = {
                          ...row,
                          [`exec${index + 1}`]: Number(
                            floorBrokerFee.toFixed(2),
                          ),
                          floorBrokerFeeRate: {
                            ...row.floorBrokerFeeRate,
                            [index]: (
                              Number(floorBrokerFee) / execQuantity
                            ).toFixed(2),
                          },
                        };

                       
                      } else if (exec.side !== initiatorSide) {
                        const execQuantity = execTotalQuantityById.get(index);
                        const floorBrokerFee = crossedFeeRate * execQuantity;
                        row = {
                          ...row,
                          [`exec${index + 1}`]: Number(
                            floorBrokerFee.toFixed(2),
                          ),
                          floorBrokerFeeRate: {
                            ...row.floorBrokerFeeRate,
                            [index]: (
                              Number(floorBrokerFee) / execQuantity
                            ).toFixed(2),
                          },
                        };
                        
                      }
                    }
                  });
                }
              } else if (sideNotCrossed !== initiatorSide.toLowerCase()) {
                console.log("sideNotCrossed !== initiatorSide");

                parsedBookingText.executions.forEach((exec, index) => {
                  if (
                    exec.side.toLowerCase() === sideNotCrossed.toLowerCase()
                  ) {
                    const execQuantity = execTotalQuantityById.get(index);
                    const crossedQuantity = Math.min(
                      availableCrossed,
                      execQuantity,
                    );
                    const notCrossedQuantity = Math.abs(
                      execQuantity - crossedQuantity,
                    );
                    availableCrossed -= crossedQuantity;
                    const floorBrokerFee =
                      crossedFeeRate * crossedQuantity +
                      notCrossedFeeRate * notCrossedQuantity;
                    row = {
                      ...row,
                      [`exec${index + 1}`]: Number(floorBrokerFee.toFixed(2)),
                      floorBrokerFeeRate: {
                        ...row.floorBrokerFeeRate,
                        [index]: (
                          Number(floorBrokerFee) / execQuantity
                        ).toFixed(2),
                      },
                    };
                    
                    
                  } else if (exec.side !== sideNotCrossed) {
                    const execQuantity = execTotalQuantityById.get(index);
                    const floorBrokerFee = crossedFeeRate * execQuantity;
                    row = {
                      ...row,
                      [`exec${index + 1}`]: Number(floorBrokerFee.toFixed(2)),
                      floorBrokerFeeRate: {
                        ...row.floorBrokerFeeRate,
                        [index]: (
                          Number(floorBrokerFee) / execQuantity
                        ).toFixed(2),
                      },
                    };
                   
                  }
                });
              }
            } else if (initiatorIndex === null) {
              console.log("their is no initiator");
              parsedBookingText.executions.forEach((exec, index) => {
                if (exec.side.toLowerCase() === sideNotCrossed.toLowerCase()) {
                  const execQuantity = execTotalQuantityById.get(index);
                  const crossedQuantity = Math.min(
                    availableCrossed,
                    execQuantity,
                  );
                  const notCrossedQuantity = Math.abs(
                    execQuantity - crossedQuantity,
                  );
                  availableCrossed -= crossedQuantity;
                  const floorBrokerFee =
                    crossedFeeRate * crossedQuantity +
                    notCrossedFeeRate * notCrossedQuantity;
                  row = {
                    ...row,
                    [`exec${index + 1}`]: Number(floorBrokerFee.toFixed(2)),
                    floorBrokerFeeRate: {
                      ...row.floorBrokerFeeRate,
                      [index]: (Number(floorBrokerFee) / execQuantity).toFixed(
                        2,
                      ),
                    },
                  };
                 
                } else if (exec.side !== sideNotCrossed) {
                  const execQuantity = execTotalQuantityById.get(index);
                  const floorBrokerFee = crossedFeeRate * execQuantity;
                  row = {
                    ...row,
                    [`exec${index + 1}`]: Number(floorBrokerFee.toFixed(2)),
                    floorBrokerFeeRate: {
                      ...row.floorBrokerFeeRate,
                      [index]: (Number(floorBrokerFee) / execQuantity).toFixed(
                        2,
                      ),
                    },
                  };
                
                }
              });
            }
            floorBrokerFeeObj = { ...row };
            return row;
          } else if (row.id === "initiation") {
            const execKeys = Object.keys(row).filter(
              (key) => key.startsWith("exec") && key !== colId,
            );
            let initiationSum = 0;
            //other column calculation
            execKeys.forEach((exec) => {
              const execCommission = newData[commissionIndex][exec];
              const execInitiationFee = newData[initiationFeeIndex][exec];
              const execFloorBrokerFee = floorBrokerFeeObj[exec];
              row = {
                ...row,
                [exec]:
                  (execCommission - execFloorBrokerFee) * execInitiationFee,
              };
              initiationSum +=
                (execCommission - execFloorBrokerFee) * execInitiationFee;
            });

            if (initiator) {
              initiation = initiationSum;
              return { ...row, [colId]: initiationSum };
            } else {
              initiation = (commission - floorBrokerFee) * initiationFee;

              return { ...row, [colId]: initiation };
            }
          } else if (row.id === "market_maker") {
            const execKeys = Object.keys(row).filter(
              (key) => key.startsWith("exec") && key !== colId,
            );
            execKeys.forEach((exec) => {
              const execIsMarketMaker = newData[isMarketMakerIndex][exec];
              const execCommission = newData[commissionIndex][exec];
              const execFloorBrokerFee = floorBrokerFeeObj[exec];
              const execMarketMakerFee = newData[marketMakerFeeIndex][exec];
              if (execIsMarketMaker) {
                marketMakerRow = {
                  ...row,
                  [exec]:
                    (execCommission - execFloorBrokerFee) * execMarketMakerFee,
                };
                row = {
                  ...row,
                  [exec]:
                    (execCommission - execFloorBrokerFee) * execMarketMakerFee,
                };
              } else {
                marketMakerRow = {
                  ...row,
                  [exec]: 0,
                };
                row = { ...row, [exec]: 0 };
              }
            });

            if (!initiator && isMarketMaker) {
              marketMaker =
                (commission - floorBrokerFeeObj[colId]) * marketMakerFee;
              return {
                ...row,
                [colId]:
                  (commission - floorBrokerFeeObj[colId]) * marketMakerFee,
              };
            } else {
              marketMaker = 0;
              return { ...row, [colId]: 0 };
            }
          } else if (row.id === "net") {
            const netCalculation =
              commission -
              floorBrokerFeeObj[colId] -
              executionFee +
              (initiator ? initiation : -initiation) -
              marketMaker;
            if (oldInitiatorExec === null) {
              return { ...row, [colId]: netCalculation };
            } else {
              const oldInitiatorNetValue =
                newData[commissionIndex][oldInitiatorExec] -
                floorBrokerFeeObj[oldInitiatorExec] -
                newData[executionFeeIndex][oldInitiatorExec] -
                (newData[commissionIndex][oldInitiatorExec] -
                  newData[floorBrokerFeeIndex][oldInitiatorExec]) *
                  newData[initiationFeeIndex][oldInitiatorExec] -
                marketMakerRow[oldInitiatorExec];

              return {
                ...row,
                [colId]: netCalculation,
                [oldInitiatorExec]: oldInitiatorNetValue,
              };
            }
          } else {
            return row;
          }
        });

        setRowData(updatedData); // Update the state with the new array
      }
    } else if (
      (keyPressed === "Enter" && e.data.columnHeader === "Is Market Maker") ||
      (keyPressed === "Space" && e.data.columnHeader === "Is Market Maker")
    ) {
      const isMarketMaker = e.value ? false : true;
      if (
        rowIndex >= 0 &&
        rowIndex < rowData.length &&
        colId in rowData[rowIndex]
      ) {
        let marketMaker = newData[marketMakerIndex][colId];
        const initiator = newData[initiatorIndex][colId];
        const marketMakerFee = newData[marketMakerFeeIndex][colId];
        const commission = newData[commissionIndex][colId];
        const floorBrokerFee = newData[floorBrokerFeeIndex][colId];
        const executionFee = newData[executionFeeIndex][colId];
        const initiation = newData[initiationIndex][colId];

        const updatedData = newData.map((row) => {
          if (row.id === "is_market_maker") {
            return { ...row, [colId]: isMarketMaker };
          } else if (row.id === "market_maker") {
            if (!initiator && isMarketMaker) {
              marketMaker = (commission - floorBrokerFee) * marketMakerFee;
              return {
                ...row,
                [colId]: (commission - floorBrokerFee) * marketMakerFee,
              };
            } else {
              marketMaker = 0;
              return { ...row, [colId]: 0 };
            }
          } else if (row.id === "net") {
            const netCalculation =
              commission -
              floorBrokerFee -
              executionFee +
              (initiator ? initiation : -initiation) -
              marketMaker;
            return { ...row, [colId]: netCalculation };
          } else {
            return row;
          }
        });

        setRowData(updatedData);
      }
    }
  }
};
