import axios from "axios";
import { END_POINT } from "../../../utils";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as userSlice from "../../../store/users/usersSlice";
import * as actionSnackBar from "../../../store/snackbar/action";
import * as rfqSlice from "../../../store/rfq/rfqSlice";
import workerInstances from "../../../services/index";
import ChangePasswordView from "./ChangePassword.view";
import { passwordValidation, setConfig } from "enigma-x-utilities";
import { ws, connectWS, sendEvent, closeWS } from "../../../services/websocket";


function ChangePassword() {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [formState, setFormState] = useState({
      userName: "",
      password: "",
      passwordCopy: "",
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmedPassword: false,
    });
    const [equalPasswords, setEqualPasswords] = useState(null);
    const [validState, setValidState] = useState({
      validation: [{}, {}, {}, {}, {}],
    });
  
    const logout = (e) => {
      const token = sessionStorage.getItem("token");
      if (token) {
        dispatch(userSlice.clearLoginState());
        dispatch(rfqSlice.clearRfqId());
        dispatch(rfqSlice.clearrfqInstanceList());
        dispatch(rfqSlice.clearCreatedRfqDetails());
        dispatch(rfqSlice.clearrfqButtonStatus());
        dispatch(rfqSlice.clearRfq());
        dispatch(rfqSlice.restartNotificationAcount());
        closeWS(dispatch,rfqSlice);
  
        navigate("/login");
      } else {
        navigate("/login");
      }
    };
    const handleClickShowPassword = (kind) => {
      if (kind === "current") {
        setFormState({
          ...formState,
          showCurrentPassword: !formState.showCurrentPassword,
        });
      }
      if (kind === "new") {
        setFormState({
          ...formState,
          showNewPassword: !formState.showNewPassword,
        });
      }
      if (kind === "confirmed") {
        setFormState({
          ...formState,
          showConfirmedPassword: !formState.showConfirmedPassword,
        });
      }
    };
  
    const valid = (e) => {
      if (e.target.value === "") {
        setEqualPasswords(null);
      } else {
        if (e.target.value === formState.passwordCopy) {
          setEqualPasswords(true);
        } else {
          setEqualPasswords(false);
        }
      }
      setFormState({
        ...formState,
        password: e.target.value,
      });
      setConfig("password", {
        characterLen: 9,
        upperCase: 1,
        lowerCase: 1,
        num: 1,
        symbol: "!@#^",
      });
      setValidState(passwordValidation(e.target.value));
    };
  
    const handlePasswordCopy = (e) => {
      setFormState({ ...formState, passwordCopy: e.target.value });
      if (formState.passwordCopy === "") {
        setEqualPasswords(null);
      } else {
        if (e.target.value === formState.password) {
          setEqualPasswords(true);
        } else {
          setEqualPasswords(false);
        }
      }
    };
  
    const saveNewPassword = async () => {
      let token = sessionStorage.getItem("token");
      let userLoggedDetails = {
        traderId: sessionStorage.getItem("id"),
        userName: sessionStorage.getItem("userName"),
        newPassword: formState.password,
      };
      try {
        let updatePassword = await axios.put(
          `${process.env.REACT_APP_BASE_URL}` + END_POINT.LOGIN,
          userLoggedDetails,
          {
            headers: { Authorization: token },
          }
        );
        if (updatePassword?.data?.error) {
          dispatch(actionSnackBar.setSnackBar("warning" , updatePassword?.data?.error , 3000))
        } else {
          if (updatePassword?.data?.entrances === 1) {
            sessionStorage.setItem(
              "token",
              "Bearer " + updatePassword.data.token
            );
            dispatch(userSlice.saveLoginDetails(updatePassword.data));
            sessionStorage.setItem("userName", updatePassword.data.userName);
            sessionStorage.setItem("id", updatePassword.data.id);
            sessionStorage.setItem("entrances", updatePassword.data.entrances);
            const entitlement = updatePassword.data.entitlement.split(";");
            if (
              entitlement.filter((entl) => entl === "fx-rfq-blotter").length > 0
            ) {
              navigate("/rfqblotter");
            } else {
              navigate("/fiblotter");
            }
  
           connectWS(
              updatePassword.data.token
            );
          }
        }
      } catch (error) {}
    };

    return(
        <ChangePasswordView
            valid={valid}
            logout={logout}
            formState={formState}
            validState={validState}
            equalPasswords={equalPasswords}
            saveNewPassword={saveNewPassword}
            handlePasswordCopy={handlePasswordCopy}
            handleClickShowPassword={handleClickShowPassword}
        />
    )


}


export default ChangePassword;
