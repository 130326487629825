import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { Grid, InputBase } from "@mui/material";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import { ReactComponent as Makor } from "../../../assets/makor-big.svg";
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ResetPassword from "../ResetPassword/ResetPassword";

import {
    useStyles,
  } from "./Login.styles";
import { useState } from "react";

const LoginView = ({formState,handleClickShowPassword,handleChange,loginUser}) => {
const classes = useStyles();
const [resetPasswordDialog, setResetPasswordDialog] = useState(false)



const openResetPasswordDialog = () => {
  setResetPasswordDialog(!resetPasswordDialog)
}

return (
<Grid container className={classes.loginPage}>
      <Grid item className={classes.loginContainer}>
        <Grid item container justifyContent="center">
          <Grid item container xs={12} justifyContent="center" style={{paddingTop : "46px"}} >
          <Makor/>
          </Grid>
          <Grid item xs={12} style={{paddingTop : "9px"}}>
          <Typography className={classes.header} style={{ fontSize: "2.50rem" }}>
            Macro Le Tool
          </Typography>
          </Grid>
        </Grid>
        <Grid style={{ paddingTop: "47px" }}>
          <Typography className={classes.welcome} style={{ fontSize: "2.3125rem"}}>
            Welcome!
          </Typography>
        </Grid>
        <Grid
          container
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          className={classes.inputContainer}
        >
          <InputBase
            className={classes.input}
            autoComplete="off"
            required
            variant="outlined"
            placeholder="Username"
            name="userName"
            onChange={handleChange}
          />
          <FormControl>
            <InputBase
              className={classes.input}
              placeholder="Password"
              name="password"
              type={formState.showPassword ? "text" : "password"}
              onChange={handleChange}
              onKeyDown={(e) => (e.key === "Enter" ? loginUser(e) : null)}
            endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {formState.showPassword ? (
                      <VisibilityIcon 
                      style={{ opacity: "100%", color :"#ffffff" ,fontSize:"1.25rem"}}/>
                    ) : (
                      <VisibilityOffIcon
                        style={{ opacity: "100%", color :"#ffffff",fontSize:"1.25rem" }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Grid style={{ paddingTop: "8px" }}>
            <Typography className={classes.forgetpass} onClick={openResetPasswordDialog}>
              Forgot password?
            </Typography>
            <Button
              className={classes.login}
              variant="contained"
              style={{ marginTop: "3vh", textTransform: "none", fontSize : "1.125rem"}}
              onClick={(e) => loginUser(e)}
            >
              Log In
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <ResetPassword open={resetPasswordDialog} onClose={openResetPasswordDialog}/>
    </Grid>
 )
}
export default LoginView
