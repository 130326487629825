import React, { useEffect, useRef, useState } from "react";
import Markdown from "markdown-to-jsx";
import CustomTable from "./CustomTable";

export const MarkdownToHtml = ({
  setMarkdown,
  markdown,
  tabChosen,
  setMarkdownContentTabs,
  isTabConfirmed,
  markdownContentTabs,
}) => {
  const [editMode, setEditMode] = useState(false);

  const textAreaRef = useRef();
  const handleDivClick = () => {
    !isTabConfirmed && setEditMode(true);
    setTimeout(() => {
      textAreaRef.current.focus();
    }, [100]);
  };

  const handleChange = (event) => {
    setMarkdown(event.target.value);
    setMarkdownContentTabs((pre) => {
      const index = pre.findIndex(
        (obj) =>
          Number(obj?.traderId) === Number(tabChosen?.traderId) &&
          Number(obj?.clientId) === Number(tabChosen?.clientId),
      );
      if (index !== -1) {
        return pre.map((item, idx) =>
          idx === index ? { ...item, email_body: event.target.value } : item,
        );
      } else {
        return pre;
      }
    });
  };

  const handleSave = () => {
    setEditMode(false);
  };

  return (
    
    <div style={{ margin: "15px", overflow: "auto" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={`${
            tabChosen?.companyId === 2
              ? "/og-green.png"
              : tabChosen?.companyId === 1
              ? "/makor_logo_purple.png"
              : null
          }`}
          alt="Logo is missing"
          width="100"
          height="100"
        />
        {tabChosen?.companyId === 2 ? (
          <div style={{ marginLeft: "5px" }}>
            <b>Oscar Gruss & Sons International .</b>
            <p>10 East 53rd Street, 17th Floor</p>
            <p> New York</p>
            <p>10022 NY</p>
          </div>
        ) : tabChosen?.companyId === 1 ? (
          <div style={{ marginLeft: "5px" }}>
            <b>Makor Securities London Ltd.</b>
            <p>6th Floor, 30 Panton Street</p>
            <p>London</p>
            <p>SW1Y 4AJ</p>
          </div>
        ) : null}
      </div>

      {editMode && !isTabConfirmed ? (
        <textarea
          ref={textAreaRef}
          value={markdown}
          onChange={(e) => handleChange(e, markdownContentTabs)}
          style={{ width: "90%", height: "300px", overflow: "auto" }}
          onBlur={handleSave}
        />
      ) : (
        <div onClick={handleDivClick}>
          {markdown !== null && markdown !== undefined ? (
            <Markdown
              children={markdown}
              options={{
                overrides: {
                  table: {
                    component: CustomTable,
                    props: {
                      companyId: tabChosen?.companyId,
                    },
                  },
                },
              }}
            />
          ) : null}
        </div>
      )}
      <br />
      <p>Thank you,</p>
      <p>Best regards,</p>
      <p>
        <b>Derivatives Team</b>
      </p>
      <p style={{ marginTop: 0 }}>
        {tabChosen?.companyId === 2 ? "Oscar Gruss & Son Inc." : "Makor Group"}
      </p>
      <img
        src={
          tabChosen?.companyId === 2
            ? "/og-logomark-dark-grey.png"
            : "/makor_logo_with_text_black.png"
        }
        alt="ogDarkIcon"
        width="80"
        height="50"
      />
      <p style={{ color: "#A9A9A9", fontWeight: "bold" }}>Disclaimer</p>
      {tabChosen?.companyId === 2 ? (
        <p style={{ color: "#E5E4E2" }}>
          This information represents neither an offer to buy or sell any
          security nor, because it does not take into account the differing
          needs of individual clients, investment advice. Those seeking
          investment advice specific to their financial profiles and goals
          should contact their Oscar Gruss & Son Incorporated sales
          representative. Oscar Gruss & Son Incorporated believes this
          information to be reliable, but no representation is made as to
          accuracy or completeness. This information does not analyze every
          material fact concerning a company, industry, or security. Oscar Gruss
          & Son Incorporated assumes that this information will be read in
          conjunction with other publicly available data. Matters discussed here
          are subject to change without notice. There can be no assurance that
          reliance on the information contained here will produce profitable
          results. A security denominated in a foreign currency is subject to
          fluctuations in currency exchange rates, which may have an adverse
          effect on the value of the security upon the conversion into local
          currency of dividends, interest, or sales proceeds. The value of
          securities and depositary receipts of foreign issuers that are
          denominated in United States dollars are also influenced by
          fluctuations in currency exchange rates.
        </p>
      ) : (
        <p style={{ color: "#E5E4E2" }}>
          This message has been sent by Makor Securities, London, part of Makor
          Group, which is authorised and regulated by the FCA (625054). This
          message is for professional clients and eligible counterparties only,
          not intended for "retail clients". The information contained in this
          message is confidential and is for the exclusive use of the intended
          recipient. If you receive this message in error please inform us and
          delete all copies of it. The information is not intended as an offer
          or solicitation to buy or sell any financial instrument. All comments
          and statements are to be considered the opinions of the author not the
          Company and are not intended to be relied upon. We cannot guarantee
          that this message or any attachments are virus free and accept no
          liability for any viruses or the consequences thereof.
        </p>
      )}
      {tabChosen?.companyId === 2 ? (
        <p style={{ color: "#E5E4E2" }}>
          © 2024 Oscar Gruss & Son Incorporated. All rights reserved.
        </p>
      ) : (
        <p style={{ color: "#E5E4E2" }}>
          © 2024 Makor Securities London Limited. All rights reserved.{" "}
        </p>
      )}
    </div>
  );
};
