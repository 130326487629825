import { Box, Typography, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ReactComponent as Copy } from "../../../../assets/copy.svg";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const RfqOptionRequestModal = ({
  data,
  header,
  column
}) => {
  const classes = useStyles();
  const [disableButton, setDisableButton] = useState(false);
  const copy_to_clip = async (string_value) => {
    setDisableButton(true);
    // const elem = document.createElement("textarea");
    // elem.value = string_value;
    // document.body.appendChild(elem);
    // elem.select();
    // document.execCommand("copy")
    await navigator.clipboard.writeText(string_value);
  };
  return (
    <Grid container
    className={data === "" ? classes.rfqModal1 : classes.rfqModal}
    style={{height: "auto"}}
    >
    <Grid item container     
    className={classes.rfqodalHeader} >
      <Typography className={classes.header}>{header}</Typography>
      <Typography className={classes.icon}>
        <Copy
          onClick={() => copy_to_clip(data)}
          className={clsx({
            [classes.copybuttonDisable]: disableButton,
          })}
        />
      </Typography>
    </Grid>
    <Grid item style={{ backgroundColor: "#222222",flex: "5" ,minHeight : column ? "fit-content" : "370px"}}>
      <pre
        style={{
          paddingTop: "2px",
          font: "normal normal normal 14px/21px monospace",
          color: "#ffffff",
          marginLeft: "2%",
          fontSize: '1.16rem',
          whiteSpace: "pre-wrap",
          opacity : "87%"
        }}>
        {data}
      </pre>
    </Grid>
    </Grid> 
  );
};

export default RfqOptionRequestModal;
export const useStyles = makeStyles(() => ({
  rfqModal: {
    border: "1px solid #686B76",
    borderRadius: "8px 8px 0px 0px",
    display: "flex",
    flexDirection: "column",
    height: "33.5vh",
    overflowY: "auto",
  },
  rfqModal1: {
    border: "1px solid #686B76",
    borderRadius: "8px 8px 0px 0px",
    display: "flex",
    flexDirection: "column",
    height: "15vh",
    overflowY: "auto",
  },
  rfqodalHeader: {
    color: "#FFFFFF",
    borderBottom: "1px solid #686B76",
    height: "43px",
    font: "normal normal normal 20px/24px Inter",
    justifyContent: "space-between",
    overflowY: "hidden"
  },
  header: {
    marginTop: "15px",
    marginLeft: "15px",
  },
  icon: {
    marginTop: "15px",
    marginRight: "15px",
  },
  input: {
    marginLeft: "10px",
    marginRight: "10px",
    border: "1px solid #686B76",
    borderRadius: "8px",
    opacity: 1,
    width: "89px !important",
    height: "40px",
    color: "white",
  },
  bid: {
    marginTop: "2%",
    color: "#00D865",
  },
  offer: {
    marginTop: "2%",
    color: " #FF3400",
  },
  text: {
    marginTop: "revert",
    textAlign: "center",
    color: "#686B76",
  },
  copybuttonDisable: {
    filter: "invert(0.5) sepia(1) hue-rotate(200deg)",
  },
}));



