import { getLegalEntitiesByParticipant } from "./functions";

export const onCellEditReq = async (e, setMappedTableData) => {
  const key = e.column.colId;
  const newValue = e.value;
  const rowId = e.data.id;
  let legalEntitiesList = null;
  let favObj = null;
  let legalEntity = null;

  if (rowId === "buyer" || rowId === "seller") {
    legalEntitiesList = await getLegalEntitiesByParticipant(
      newValue,
      e.data.markitFavData,
    );
    favObj = e.data.markitFavData.filter((item) => item.label === newValue);
    legalEntity = legalEntitiesList.filter(
      (le) => le.LEBic === favObj[0]?.LEBic,
    );
  }

  setMappedTableData((pre) => {
    const arr = [...pre];
    const newArr = arr.map((row) => {
      if (row.id === rowId) {
        return { ...row, [key]: newValue };
      } else if (
        (rowId === "buyer" && row.id === "buyerLe") ||
        (rowId === "seller" && row.id === "sellerLe")
      ) {
        return {
          ...row,
          [key]: legalEntity[0]?.LELongName,
          leObj: {
            ...row.leObj,
            [key]: legalEntitiesList,
          },
        };
      } else return row;
    });
    return newArr;
  });
};
