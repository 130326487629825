import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import {Grid , Typography,} from "@material-ui/core";

const QuoteBookParamsStraddleStrangle = ({rfqParamaters}) => {

  const classes = useStyles();

    return(
        <Grid item container xs={12} className={classes.rfqParameters}>
        <div style={{ display: "flex" }}>
          <Typography className={classes.quoteParamatersText}>
            Quote Params
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography className={classes.quoteParamatersHeader}>
            QUOTE TYPE:
          </Typography>
          <Typography className={classes.quoteParamatersText}>
            {rfqParamaters?.quote_type_str}
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography className={classes.quoteParamatersHeader}>
            BBG MID VOL:
          </Typography>
          <Typography className={classes.quoteParamatersText}>
            {(rfqParamaters?.bbg_vol === null || rfqParamaters?.bbg_vol === undefined) ? rfqParamaters?.bbg_vols :rfqParamaters?.bbg_vol}
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography className={classes.quoteParamatersHeader}>
            VEGA:
          </Typography>
          <Typography className={classes.quoteParamatersText}>
            {rfqParamaters?.vega}
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography className={classes.quoteParamatersHeader}>
            MID PREMIUM:
          </Typography>
          <Typography className={classes.quoteParamatersText}>
          {rfqParamaters?.bbg_mid_premium}
          </Typography>
        </div>
      </Grid>
    )
}
export default QuoteBookParamsStraddleStrangle
export const useStyles = makeStyles((theme) => ({
    "& .MuiInputBase-input MuiInput-input": {
      fontSize: "400px",
      color: "white",
    },
    rfqPctNotional: {
      border: "1px solid #686B76",
      padding: "20px",
      marginTop: "5px",
      borderRadius: "8px",
    },
    rfqParameters: {
      fontSize: "1.0625rem",
      borderRadius: "8px",
      display: "flex",
      justifyContent: "space-around",
      border: "1px solid #686B76",
      padding: "20px",
      marginTop: "5px",
      height: "fit-content",
    },
    quoteParamatersHeader: {
      color: " var(--unnamed-color-989ea8)",
      font: "normal normal normal 12px/10px Inter",
      letterSpacing: "0px",
      color: " #989EA8",
    },
    quoteParamatersText: {
      marginTop: "5px",
      color: " var(--unnamed-color-989ea8)",
      font: "normal normal normal 10px/2px Inter",
      letterSpacing: "0px",
      color: "#FFFFFF",
    },
  }));
