export const initialForm = {
  entitlement: "",
  firstName: "",
  lastName: "",
  username: "",
  company: "",
  email: "",
  password: "",
  role: "",
  rsp: "",
  team: "US",
};

export const entitlementList = [
  { value: "fi-blotter", isChecked: false },
  { value: "fx-rfq-blotter", isChecked: false },
  { value: "fx-rfq-workspace", isChecked: false },
  { value: "fi-client-static", isChecked: false },
  { value: "mgmt", isChecked: false },
  { value: "rates", isChecked: false },
  { value: "pricer", isChecked: false },
  { value: "equities-spread", isChecked: false },
  { value: "derivs-history", isChecked: false },
];

export const teamList = [
  { value: "US", isChecked: true },
  { value: "EMEA", isChecked: false },
];
