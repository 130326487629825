import React, { useEffect } from "react";
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  Typography,
} from "@material-ui/core";
import {
  CustomTableContainer,
  HeaderFontTableCell1,
  HeaderFontTableCell2,
} from "../style/styledComponents";
import { useStyles } from "../../../../Styles/pricerStyle";
import { Row } from "./Row";

export const TableByTab = ({
  tickerParticipates,
  marketMakers,
  tabValue,
  isPopover = false,
}) => {
  const classes = useStyles();

  return (
    <Grid style={{ overflowY: "auto" }}>
      {(tabValue === "history" && tickerParticipates.length > 0) ||
      (tabValue === "marketMaker" && marketMakers !== null) ? (
        <Grid
          item
          xs={12}
          style={{ marginTop: !isPopover ? "150px" : null, padding: "10px" }}
        >
          <CustomTableContainer component={Paper}>
            <Table className={classes.tableRow}>
              <TableHead>
                <TableRow>
                  <HeaderFontTableCell1>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="h6" fontWeight="bold">
                        {tabValue === "history"
                          ? "Client"
                          : tabValue === "marketMaker"
                          ? "Market Maker"
                          : null}
                      </Typography>
                      <hr
                        style={{
                          border: "none" /* Remove default horizontal border */,
                          borderLeft:
                            " 2px solid #828282" /* Set a vertical border on the left side */,
                          height:
                            "20px" /* Set the height of the vertical line */,
                          margin: 0 /* Remove default margins */,
                          padding: 0,
                          marginLeft: isPopover ? "15px" : null,
                        }}
                      />
                    </Grid>
                  </HeaderFontTableCell1>
                  <HeaderFontTableCell1>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="h6" fontWeight="bold">
                        {tabValue === "history"
                          ? "Contact"
                          : tabValue === "marketMaker"
                          ? "Pod"
                          : null}
                      </Typography>
                      <hr
                        style={{
                          border: "none" /* Remove default horizontal border */,
                          borderLeft:
                            " 2px solid #828282" /* Set a vertical border on the left side */,
                          height:
                            "20px" /* Set the height of the vertical line */,
                          margin: 0 /* Remove default margins */,
                          padding: 0,
                        }}
                      />
                    </Grid>
                  </HeaderFontTableCell1>
                  <HeaderFontTableCell2>
                    <Typography variant="h6" fontWeight="bold">
                      {tabValue === "history"
                        ? "Participation"
                        : tabValue === "marketMaker"
                        ? "Contacts"
                        : null}
                    </Typography>
                  </HeaderFontTableCell2>
                </TableRow>
              </TableHead>
              <TableBody>
                {tabValue === "history"
                  ? tickerParticipates.map((row, index) => (
                      <Row row={row} key={row.index} tabValue={tabValue} />
                    ))
                  : tabValue === "marketMaker"
                  ? marketMakers.market_makers !== undefined &&
                    marketMakers.market_makers.map((row, index) => (
                      <Row row={row} key={row.index} tabValue={tabValue} />
                    ))
                  : null}
              </TableBody>
            </Table>
          </CustomTableContainer>
        </Grid>
      ) : (
        <Grid
          container
          item
          direction="row"
          justifyContent="center"
          alignItems="center"
          xs={12}
        >
          <Typography
            style={{
              marginTop: "500px",
              color: "#828282",
              opacity: "0.8",
              fontSize: "15px",
            }}
          >
            No rows to show
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
