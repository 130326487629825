import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { END_POINT } from "../../../utils";
import {
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  Paper,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import workerInstances from "../../../services/index";
import soundEffect from "../../../assets/my-sounds/cash-register-purchase-87313.mp3";
import { EquityTableByOrder } from "../equityTableByOrder/EquityTableByOrder";
import { useStyles } from "../../../Styles/pricerStyle";
import { Autocomplete, Pagination } from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import SearchIcon from "@material-ui/icons/Search";
import { useDebounce } from "../../derivs pricer/derivsPricerBlotter/utils/utilsFunctions";
import { unsubscribeOnSearch } from "../../derivs pricer/derivsPricerBlotter/utils/apiCalls";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0, // Extra small screens (up to 599px)
      sm: 600, // Small screens (600px to 899px)
      md: 1750, // Medium screens (900px to 1199px)
      lg: 1940, // Large screens (1200px to 1535px)
      xl: 2200, // Extra large screens (1536px and above)
    },
  },
});

export const EquitySpreadList = () => {
  const audioRef = useRef(null);
  const classes = useStyles();

  const [ordersList, setOrdersList] = useState([]);
  const [strategiesList, setStrategiesList] = useState([]);
  const [isOrderListLoading, setIsOrderListLoading] = useState(true);
  const [searchValue, setSearchValue] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  const [wsPricerConnection, setWsPricerConnection] = useState(false);
  const [isFirstConnection, setIsFirstConnection] = useState(true);

  const parsedString = useSelector(
    (state) => state.equitiesSpreadSlice.equityDetails,
  );
  const debouncedSearchTerm = useDebounce(searchValue, 500);

  const handleNewOrder = useCallback(
    async ({ order, ordersList, strategiesList }) => {
      const ordersListCopy = [...ordersList];
      const strategiesListCopy = [...strategiesList];
      ordersListCopy.unshift({
        ...order.order,
        isNew: true,
        isNewTimestamp: new Date().getTime(),
      });

      if (audioRef.current) {
        audioRef.current.play();
      }
      strategiesListCopy.unshift({ ...order.order });
      setOrdersList(ordersListCopy);
      setStrategiesList(strategiesListCopy);
      subscribeOrdersToSocket([order.order]);
      const orderToUnsubscribe = ordersListCopy.find(
        (order, index) => index === pageNum * 10,
      );
      if (orderToUnsubscribe !== undefined) {
        unsubscribeFromSocket(orderToUnsubscribe);
      }
    },
    [ordersList, strategiesList, audioRef, pageNum],
  );
  const handleDeleteOrder = useCallback(
    async ({ orderDetails, ordersList, strategiesList }) => {
      const ordersListCopy = [...ordersList];
      const strategiesListCopy = [...strategiesList];
      const index = ordersList.findIndex(
        (order) => Number(order.id) === Number(orderDetails.orderId),
      );
      const strategyIndex = strategiesList.findIndex(
        (order) => Number(order.id) === Number(orderDetails.orderId),
      );

      if (index !== -1) {
        unsubscribeFromSocket(ordersListCopy[index]);
        ordersListCopy.splice(index, 1);
        setOrdersList(ordersListCopy);
        const orderToSubscribe = ordersList.find(
          (order, index) => index === pageNum * 10,
        );
        if (orderToSubscribe !== undefined) {
          subscribeOrdersToSocket([orderToSubscribe]);
        }

        if (
          ordersListCopy.length % 10 === 0 &&
          ordersListCopy.length / 10 < pageNum
        ) {
          setPageNum(Number(pageNum) !== 1 ? Number(pageNum) - 1 : 1);
        }
      }
      if (strategyIndex !== -1) {
        strategiesListCopy.splice(index, 1);
        setStrategiesList(strategiesListCopy);
      }
    },
    [ordersList, strategiesList, pageNum],
  );
  const handleIgnoreOrder = async (order) => {
    const copyOrderList = [...ordersList];
    const index = ordersList.findIndex(
      (o) => Number(o.id) === Number(order.id),
    );
    if (index !== -1) {
      removeOrderFromCustomList(order.id);
      unsubscribeFromSocket(order);
      copyOrderList.splice(index, 1);
      setOrdersList(copyOrderList);

      if (
        copyOrderList.length % 10 === 0 &&
        copyOrderList.length / 10 <= pageNum
      ) {
        setPageNum(Number(pageNum) !== 1 ? Number(pageNum) - 1 : 1);
        subscribeOrdersToSocket(copyOrderList);
      }
    }
  };

  const handleSearchByValue = async (searchValue) => {
    setSearchValue(searchValue);
  };
  const handleDebouncedSearch = async () => {
    try {
      const token = sessionStorage.getItem("token");
      if (searchValue.length > 1) {
        unsubscribeOnSearch(ordersList);
        setTimeout(async () => {
          const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}${END_POINT.SEARCH_ORDER_BY_VALUE_EQUITIES_SPREAD}`,
            { searchValue },
            { headers: { Authorization: token } },
          );
          if (res.data.length > 0) {
            subscribeOrdersToSocket(res.data);
            setOrdersList(res.data);
            setPageNum(1);

            setIsOrderListLoading(false);
          } else {
            setOrdersList(res.data);
          }
        }, 500);
      } else if (searchValue.length < 2) {
        unsubscribeOnSearch(ordersList);
        getOrdersList(
          setStrategiesList,
          setOrdersList,
          setIsOrderListLoading,
          pageNum,
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handlePagination = async (e, value) => {
    setIsOrderListLoading(true);
    const updatedOrdersList = await Promise.all(
      ordersList.map(async (order, index) => {
        if (
          (value === 1 && index < 10) ||
          (value > 1 && (value - 1) * 10 <= index && index < value * 10)
        ) {
          subscribeOrdersToSocket([order]);

          return {
            ...order,
          };
        } else {
          unsubscribeOnSearch([order]);
          return { ...order };
        }
      }),
    );
    setOrdersList(updatedOrdersList);
    setIsOrderListLoading(false);

    setPageNum(value);
  };

  useEffect(() => {
    searchValue !== null && handleDebouncedSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    getOrdersList(
      setStrategiesList,
      setOrdersList,
      setIsOrderListLoading,
      pageNum,
    );
  }, []);

  useEffect(() => {
    const handleDerivs = async (message) => {
      switch (message.data.type) {
        case "new_derivs_order":
          handleNewOrder({ order: message.data, ordersList, strategiesList });
          // setTimeout(() => {
          //   dispatch(derivsPricerSlice.removeHighlight(message.data));
          // }, [10000]);
          break;
        case "remove_derivs_order":
          handleDeleteOrder({
            orderDetails: message.data,
            ordersList,
            strategiesList,
          });
          break;

        default:
          break;
      }
    };
    const handleWsConnection = async (message) => {
      switch (message.data.security) {
        case "connection_status":
          if (isFirstConnection && !message.data.status) {
            setIsFirstConnection(false);
          }
          setWsPricerConnection(message.data.status);

          break;
        default:
          break;
      }
    };
    window.addEventListener("message", handleDerivs);
    workerInstances?.WebSocketPricesInstance?.addEventListener(
      "message",
      handleWsConnection,
    );

    return () => {
      window.removeEventListener("message", handleDerivs);

      workerInstances?.WebSocketPricesInstance?.removeEventListener(
        "message",
        handleWsConnection,
      );
    };
  }, [ordersList, strategiesList, pageNum]);

  useEffect(() => {
    if (wsPricerConnection && !isFirstConnection) {
      getOrdersList(
        setStrategiesList,
        setOrdersList,
        setIsOrderListLoading,
        pageNum,
      );
    }
  }, [wsPricerConnection]);

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{
          height: parsedString.priceConvention === "" ? "79vh" : "65vh",
          // maxHeight: "80vh",
          marginTop: "15px",
        }}
      >
        <audio ref={audioRef} src={soundEffect} />

        <Grid item xs={12} sm={6} md={4}>
          <Typography
            style={{
              fontSize: 22,
              marginBottom: "20px",
              color: "#828282",
              opacity: "0.8",
              height: "40px",
            }}
          >
            Equites Spread Blotter
          </Typography>
        </Grid>
        {/* multi select & search input */}
        <Grid
          xs={12}
          sm={6}
          md={8}
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <StrategiesMultiSelect
              strategiesList={strategiesList}
              ordersList={ordersList}
              setOrdersList={setOrdersList}
            />
          </Grid>
          <Grid item>
            <InputBase
              style={{
                color: "white",
                border: "1px solid #686B76",
                borderRadius: "4px",
                height: "32px",
                width: "256px",
              }}
              className={classes.searchInput}
              variant="outlined"
              onChange={(e) =>
                handleSearchByValue(e.target.value.toLowerCase())
              }
              value={searchValue}
              placeholder="Search"
              endAdornment={
                <InputAdornment position="start">
                  <IconButton
                  // onClick={(e) =>
                  //   handleSearchByValue(e.target.value.toLowerCase())
                  // }
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>
        {/* blotter body */}
        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          alignContent="flex-start"
          wrap="wrap"
          style={{
            height: "90%",
            overflow: "scroll",
            overflowX: "hidden",
          }}
        >
          {isOrderListLoading ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          ) : ordersList.length > 0 ? (
            ordersList.map((order, index) => {
              if (
                (pageNum === 1 && index < 10) ||
                (pageNum > 1 &&
                  (pageNum - 1) * 10 <= index &&
                  index < pageNum * 10)
              ) {
                return (
                  <Grid container item>
                    <Grid
                      item
                      // xs={11}
                      // md={10}
                      // lg={9}
                      // xl={8}
                      style={{
                        overflow: "auto",
                        overflowY: "hidden",
                      }}
                    >
                      <EquityTableByOrder
                        order={order}
                        key={index}
                        handleIgnoreOrder={handleIgnoreOrder}
                      />
                    </Grid>
                  </Grid>
                );
              }
            })
          ) : (
            <Typography
              style={{ color: "#828282", opacity: "0.8", fontSize: "15px" }}
            >
              No Active Orders
            </Typography>
          )}

          <Grid className={classes.paginationEquity}>
            <Pagination
              count={
                ordersList.length % 10 > 0
                  ? Math.floor(ordersList.length / 10) + 1
                  : ordersList.length / 10
              }
              page={pageNum}
              onChange={handlePagination}
              size="large"
              // disabled={ordersList.length < 6}
            />
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export const getOrdersList = async (
  setStrategiesList,
  setOrdersList,
  setIsOrderListLoading,
  pageNum,
) => {
  const token = sessionStorage.getItem("token");
  const res = await axios.get(
    `${process.env.REACT_APP_BASE_URL}${END_POINT.GET_ORDERS_LIST_EQUITIES_SPREAD}/`,
    { headers: { Authorization: token } },
  );
  setStrategiesList(res.data.strategiesList);
  setOrdersList(res.data.ordersList);
  res.data.ordersList.forEach((order) => {
    subscribeOrdersToSocket([order]);
  });

  setIsOrderListLoading(false);
};

export const subscribeOrdersToSocket = async (ordersList) => {
  try {
    const messageArray = [];
    ordersList.forEach((order, orderIndex) => {
      messageArray.push({
        action: "new",
        order_id: order.id,
        side: "a",
        security: `${order.ticker1} Equity`,
        attributes: ["LAST_PRICE", "BID", "ASK"],
        ...order,
      });
      messageArray.push({
        action: "new",
        order_id: order.id,
        side: "b",
        security: `${order.ticker2} Equity`,
        attributes: ["LAST_PRICE", "BID", "ASK"],
        ...order,
      });
    });
    workerInstances.WebSocketPricesInstance.sendEvent(messageArray);
  } catch (err) {
    console.log(err);
  }
};

export const unsubscribeFromSocket = (order) => {
  const messageArray = [];

  messageArray.push({
    action: "remove",
    order_id: order.id,
  });

  workerInstances.WebSocketPricesInstance.sendEvent(messageArray);
  return;
};

export const StrategiesMultiSelect = ({
  strategiesList,
  ordersList,
  setOrdersList,
}) => {
  const classes = useStyles();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        backgroundColor: "#2D2D2D",
        color: "white",
      },
    },
  };
  const [ordersChecked, setOrdersChecked] = React.useState([]);
  const [trackingList, setTrackingList] = React.useState([]);

  //SETS CHECKED ORDERS IN THE MULTI SELECT
  useEffect(() => {
    const idList = ordersList.map((order) => {
      return order.id;
    });
    setOrdersChecked([...ordersList]);
    setTrackingList(idList);
  }, [ordersList]);

  const handleChange = async (event, values) => {
    const copyOrderList = [...ordersList];
    const value = values.map((v) => {
      return v.id;
    });
    const orderToRemove = trackingList.filter((id) => !value.includes(id));
    if (orderToRemove.length > 0) {
      const index = ordersList.findIndex(
        (order) => Number(order.id) === Number(orderToRemove[0]),
      );
      if (index !== -1) {
        removeOrderFromCustomList(orderToRemove[0]);
        unsubscribeFromSocket(copyOrderList[index]);
        copyOrderList.splice(index, 1);
        setOrdersList(copyOrderList);
      }
    } else {
      const orderToAdd = value.filter((v) => !trackingList.includes(v));
      if (orderToAdd.length > 0) {
        addOrderToCustomList(orderToAdd[0]);
        const order = strategiesList.filter(
          (order) => Number(order.id) === Number(orderToAdd),
        );
        if (order.length > 0) {
          subscribeOrdersToSocket([order[0]]);
          copyOrderList.unshift({
            ...order[0],
          });
          setOrdersList(
            copyOrderList.sort(
              (a, b) => new Date(b.createdOn) - new Date(a.createdOn),
            ),
          );
        }
      }
    }

    setTrackingList(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };
  const handleKeyDown = (event) => {
    if (event.key === "Backspace" && event.target.value === "") {
      setOrdersChecked(ordersChecked);
    }
  };

  return (
    <Autocomplete
      classes={{
        clearIndicator: classes.clearIndicator,
        popupIndicator: classes.popupIndicator,
        endAdornment: classes.endAdornment,
        root: classes.root,
        inputRoot: classes.inputRoot,
        input: classes.input,
        option: classes.option,
        noOptions: classes.noOptions,
      }}
      noOptionsText="No options found"
      // inputProps={{ className: classes.input }}
      PaperComponent={(props) => (
        <Paper {...props} className={classes.paper}>
          {props.children}
        </Paper>
      )}
      // sx={{ width: 200 }}
      multiple
      fullWidth
      disableClearable
      value={ordersChecked}
      options={strategiesList}
      getOptionLabel={(option) =>
        `${option.ticker1.replace(" ", "").toUpperCase()}_${option.ticker2
          .replace(" ", "")
          .toUpperCase()}_${option.priceConvention
          .replace(" ", "")
          .toUpperCase()}`
      }
      getOptionSelected={(option, value) => option.id === value.id}
      disableCloseOnSelect
      onChange={handleChange}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{
              marginRight: 8,
              color: selected ? "#2692FF" : "#989EA8",
            }}
            checked={selected}
          />
          {`${option.ticker1.replace(" ", "").toUpperCase()}_${option.ticker2
            .replace(" ", "")
            .toUpperCase()}_${option.priceConvention
            .replace(" ", "")
            .toUpperCase()}`}
        </React.Fragment>
      )}
      // style={{ width: 500 }}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.multiSelectTextfield}
          variant="outlined"
          label={
            ordersChecked.length > 0
              ? `Strategies [${ordersChecked.length}]`
              : "Strategies"
          }
          InputLabelProps={{
            className: classes.label,
            style: {
              color: "white",
            },
          }}
          onKeyDown={handleKeyDown}

          // placeholder={`Strategies ${ordersChecked.length}`}
        />
      )}
      renderTags={(values) =>
        // <span style={{ color: "white" }}>Strategies {values.length}</span>
        null
      }
    ></Autocomplete>
  );
};

export const removeOrderFromCustomList = async (id) => {
  try {
    const token = sessionStorage.getItem("token");
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}${END_POINT.REMOVE_ORDER_EQUITIES_SPREAD}`,
      { id },
      { headers: { Authorization: token } },
    );
  } catch (err) {
    console.log(err);
  }
};
export const addOrderToCustomList = async (id) => {
  try {
    const token = sessionStorage.getItem("token");
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}${END_POINT.ADD_ORDER_TO_CUSTOM_LIST_EQUITIES_SPREAD}`,
      { id },
      { headers: { Authorization: token } },
    );
  } catch (err) {
    console.log(err);
  }
};
