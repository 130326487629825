import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  dataFeedTab: {
    fontSize: "0.8rem !important",
  },

  notSelectedTab: {
    color: "#686B76 !important",
    backgroundColor: "transparent",
    border: "1px solid #2692FF",
    borderRadius: "0px",
    padding: "6px 16px 6px 16px", // Adjusted to remove bottom padding
    minHeight: "0", // Ensures the tab height is as small as content allows
    fontSize: "1.05rem",
    width: "230px",
    fontWeight: "bold",
  },
  selectedTab: {
    fontWeight: "bold",
    color: "white !important",
    backgroundColor: "#2692FF",
    border: "1px solid #2692FF",
    borderRadius: "0px",
    padding: "6px 16px 6px 16px", // Adjusted to remove bottom padding
    minHeight: "0", // Ensures the tab height is as small as content allows
    fontSize: "1.05rem",
    width: "250px",
  },

  tabsDialog: {
    backgroundColor: "transparent",
    color: "white",

    "& .MuiTab-root": {
      minWidth: 0, // Override default minWidth if necessary
      paddingTop: 0, // Reduce padding if it's causing extra space
      paddingBottom: 0, // Adjust paddingBottom to control space below text
    },
    "& .MuiTabs-flexContainer.MuiTabs-centered": {
      marginTop: "10px",
    },
  },
  paperTabsContainer: {
    flexGrow: 1,
    backgroundColor: "transparent",
    boxShadow:
      "-2px 2px 4px -1px rgba(0,0,0,0.2), -2px 4px 5px 0px rgba(0,0,0,0.14), -2px 1px 10px 0px rgba(0,0,0,0.12)",
  },
  paperTabsContainerDialog: {
    flexGrow: 1,
    backgroundColor: "transparent",
    boxShadow: "none",
  },

  tableContainer: {
    [theme.breakpoints.up("xs")]: {
      flexBasis: "auto",
      maxWidth: "auto",
    },
    [theme.breakpoints.up("lg")]: {
      flexBasis: "85%", // mimicking lg=10
      maxWidth: "85%",
    },
    "@media (min-width:2300px)": {
      flexBasis: "90%",
      maxWidth: "90%",
    },
  },
  tableContainerBookingTab: {
    [theme.breakpoints.up("xs")]: {
      flexBasis: "auto",
      maxWidth: "auto",
    },
    [theme.breakpoints.up("lg")]: {
      flexBasis: "92%", // mimicking lg=10
      maxWidth: "92%",
    },
    "@media (min-width:2300px)": {
      flexBasis: "94%",
      maxWidth: "94%",
    },
  },
  iconContainer: {
    [theme.breakpoints.up("xs")]: {
      flexBasis: "auto",
      maxWidth: "auto",
    },
    [theme.breakpoints.up("lg")]: {
      flexBasis: "15%", // mimicking lg=2
      maxWidth: "15%",
    },
    "@media (min-width:2300px)": {
      flexBasis: "10%",
      maxWidth: "10%",
    },
  },
  iconContainerBookingTab: {
    [theme.breakpoints.up("xs")]: {
      flexBasis: "auto",
      maxWidth: "auto",
    },
    [theme.breakpoints.up("lg")]: {
      flexBasis: "7%", // mimicking lg=2
      maxWidth: "10%",
    },
    "@media (min-width:2300px)": {
      flexBasis: "5%",
      maxWidth: "10%",
    },
  },
  bookingTextArea: {
    minWidth: "900px",
    background: "transparent",
    color: "white",
  },
  bookingTextTypography: {
    color: "#686b76",
    paddingLeft: "10px",
    paddingBottom: "10px",
  },
  btn: {
    width: "70px",
    borderRadius: 4,
    backgroundColor: "#2692FF",
    border: "1px solid #2692FF",
    textTransform: "none",
    color: "#ffffff",
    fontSize: 14,
    "&:hover": {
      backgroundColor: "#2692FF",
      border: "1px solid #ffffff",
    },
    "&:disabled": {
      backgroundColor: "rgba(92, 87, 90, 0.26)",
      border: "1px solid rgba(192, 179, 186, 0.26)",
      color: "#ffffff",
    },
  },
  btnMakorX: {
    maxWidth: "150px",
    borderRadius: 4,
    backgroundColor: "#2692FF",
    border: "1px solid #2692FF",
    textTransform: "none",
    color: "#ffffff",
    fontSize: 14,
    "&:hover": {
      backgroundColor: "#2692FF",
      border: "1px solid #ffffff",
    },
    "&:disabled": {
      backgroundColor: "rgba(92, 87, 90, 0.26)",
      border: "1px solid rgba(192, 179, 186, 0.26)",
      color: "#ffffff",
    },
  },
  bookingCloseIcon: {
    fontSize: "20px",
    color: "white",
    backgroundColor: "#686B76",
    borderRadius: "12px",
    opacity: "0.5",
  },

  bookingHeaderTypography: {
    fontSize: "1.5rem !important",
    color: "#ffffff",
    paddingLeft: "10px",
    paddingTop: "25px",
    paddingBottom: "10px",
  },
  bookingDialog: {
    backgroundColor: "#2D2D2D",
    minWidth: "1800px",
    height: "1100px",
    borderRadius: "12px",
    overflowY: "hidden",
  },
  teamText: {
    position: "fixed",
    top: "30px" /* Adjust the bottom value as needed */,
    right: "70px" /* Adjust the right value as needed */,
    color: "#686B76",
    font: "normal normal normal 14px/17px Inter",
  },
  historyTableRow: {
    height: "20px !important",
    padding: "5px !important",
  },
  connectivityIconOff: {
    fontSize: "30px" /* Adjust the font size as needed */,
    color: "red" /* Customize the color as needed */,
  },
  connectivityIconOn: {
    fontSize: "30px" /* Adjust the font size as needed */,
    color: "green" /* Customize the color as needed */,
  },
  connectivityIconOffMiniFeed: {
    fontSize: "20px" /* Adjust the font size as needed */,
    color: "red" /* Customize the color as needed */,
  },
  connectivityIconOnMiniFeed: {
    fontSize: "20px" /* Adjust the font size as needed */,
    color: "green" /* Customize the color as needed */,
  },
  connectivityIconContainer: {
    position: "fixed",
    top: "20px" /* Adjust the bottom value as needed */,
    right: "20px" /* Adjust the right value as needed */,
  },
  connectivityIconContainerMiniFeed: {
    position: "fixed",
    top: "8px" /* Adjust the bottom value as needed */,
    right: "15px" /* Adjust the right value as needed */,
    zIndex: 1000,
  },

  starIcon: {
    cursor: "pointer",
  },
  fullBooked: {
    "& path": {
      fill: "#007f00", // Full star color
    },
  },
  fullConfirmed: {
    "& path": {
      fill: "#318CE7", // Full star color
    },
  },
  emptyBooked: {
    opacity: 0.5,
    "& path": {
      fill: "#C0C0C0",
    },
  },
  fullStar: {
    opacity: 1, // Full star opacity
    color: "#FFD700", // Full star color
  },
  emptyStar: {
    opacity: 0.5, // Empty star opacity
    color: "#C0C0C0", // Empty star color
  },
  tabsPaper: {
    flexGrow: 1,
    backgroundColor: "transparent",
    maxWidth: "500px",
  },
  tab: {
    color: "gray",
    fontSize: "20px",
    "&.Mui-selected": {
      color: "white",
    },
  },
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "white",
    },
  },
  pagination: {
    position: "relative",
    // bottom: "20px",
    left: "95%",
    transform: "translateX(-50%)",
    "& .MuiPaginationItem-root": {
      color: "white",
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "rgba(85, 85, 85, 0.5) !important",
    },
    "& .MuiPaginationItem-page:hover": {
      backgroundColor: "rgba(85, 85, 85, 0.5) !important",
    },
    // "& .MuiPaginationItem-page .Mui-selected": {
    //   backgroundColor: "red !important",
    // },
    // "&  '& .Mui-selected, & .Mui-selected:hover, & .Mui-selected.Mui-focusVisible": {
    //   backgroundColor: "red !important",
    // },
  },
  paginationEquity: {
    position: "fixed",
    bottom: "20px",
    left: "50%",

    transform: "translateX(-50%)",
    "& .MuiPaginationItem-root": {
      color: "white",
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "rgba(85, 85, 85, 0.5) !important",
    },
    "& .MuiPaginationItem-page:hover": {
      backgroundColor: "rgba(85, 85, 85, 0.5) !important",
    },
    // "& .MuiPaginationItem-page .Mui-selected": {
    //   backgroundColor: "red !important",
    // },
    // "&  '& .Mui-selected, & .Mui-selected:hover, & .Mui-selected.Mui-focusVisible": {
    //   backgroundColor: "red !important",
    // },
  },

  noOptions: {
    color: "#989EA8",
    // fontWeight: "bold",
    fontSize: 16,
  },
  label: {
    // color: "white",
    transform: "translate(14px, 13px) scale(1)",
  },

  paper: {
    backgroundColor: "#2D2D2D",
  },

  option: {
    // backgroundColor: "#2D2D2D",

    color: "#989EA8",
    "&:hover": {
      // backgroundColor: "red",
    },
    '&[aria-selected="true"]': {
      color: "white",
    },
  },
  root: {
    width: "300 !important",
  },
  inputRoot: {
    color: "#fff",
    "&$inputFocused $input": {
      padding: "4.5px 4px",
    },
  },
  input: {
    "&::placeholder": {
      color: "#fff",
      opacity: 0.5,
    },
  },
  clearIndicator: {
    color: "#fff",
  },
  popupIndicator: {
    color: "#fff",
  },
  endAdornment: {
    "& .MuiSvgIcon-root": {
      color: "#fff",
    },
  },
  multiSelectTextfield: {
    height: "32px",
    width: "256px",

    "& .MuiAutocomplete-inputRoot": {
      color: "#fff",
      padding: "4.5px 4px",
      "& $input": {
        padding: "4.5px 4px",
      },
    },

    "& .MuiOutlinedInput-root": {
      height: "36px",

      "& fieldset": {
        borderColor: "#686B76",
        // height: "36px",
      },
      "&:hover fieldset": {
        borderColor: "#686B76",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#686B76",
        borderWidth: "2px",
      },
    },
  },
  miniFeedNav: {
    height: "100px",
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 999,
    backgroundColor: "#171717",
    paddingLeft: "11px",
  },
  miniFeedMultiSelect: {
    height: "32px",
    width: "180px",

    "& .MuiAutocomplete-inputRoot": {
      color: "#fff",
      padding: "4.5px 4px",
      "& $input": {
        padding: "4.5px 4px",
      },
    },

    "& .MuiOutlinedInput-root": {
      height: "36px",

      "& fieldset": {
        borderColor: "#686B76",
        // height: "36px",
      },
      "&:hover fieldset": {
        borderColor: "#686B76",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#686B76",
        borderWidth: "2px",
      },
    },
  },
  linearLoading: {
    width: "500px",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "#FFDB97 !important",
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#FFA800 !important",
    },
  },
  miniFeedMainContainer: {
    height: "180px",
    backgroundColor: "#2D2D2D",
    borderRadius: "4px",
    maxWidth: "600px",
    marginLeft: "11px",
    marginBottom: "10px",
  },
  miniFeedUnderlineHeader: {
    color: "#686B76",
    fontSize: "12px",
    paddingLeft: "10px",
  },
  miniFeedUnderline: {
    color: "white",
    fontSize: "14px",
    paddingLeft: "10px",
  },
  miniFeedUnderline2: {
    color: "#FF6E57",
    fontSize: "14px",
    paddingLeft: "10px",
  },
  searchInput: {
    paddingLeft: "10px",

    "& .MuiSvgIcon-root": {
      color: "#2692ff",
    },
    // "& .MuiInputBase-input::placeholder": {

    // },
  },
  selected: {
    backgroundColor: "transparent !important",
    "&:hover": {
      backgroundColor: "#6B6E7729 !important",
    },
  },
  listItem: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  multiSelect: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#686B76",
      },
      "&:hover fieldset": {
        borderColor: "#686B76",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#686B76",
      },
    },

    "& .MuiSelect-icon": {
      color: "white",
    },

    "& .MuiInputBase-input": {
      color: "white",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#686B76",
    },
    "& .MuiInputLabel-root": {
      color: "white",
    },

    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "white",
    },
  },

  stringInput: {
    "& .MuiOutlinedInput-input": {
      color: "#FFFFFF",
      minWidth: "400px",
      height: "0px",
      font: "normal normal normal 14px/17px Inter",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#686B76",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#2692FF",
    },
    // "& .MuiInputLabel-root.Mui-focused": {
    //   color: "#686B76",
    // },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#2692FF",
    },
  },

  historyBtn: {
    width: "88px",
    height: "35px",
    textTransform: "none",
    background: "#3A3C3F",
    backgroundColor: "#3A3C3F",
    borderRadius: "4px",
    opacity: 1,
    // fontWeight: "700",
    fontSize: "14px",

    color: "#2692FF",
    border: "1px solid #686B76",
    "&:hover": {
      background: "#3A3C3F",
      backgroundColor: "#3A3C3F",
      border: "1px solid #2692FF",
    },
  },
  createBtn: {
    width: "88px",
    height: "35px",
    textTransform: "none",
    background: "#3A3C3F",
    backgroundColor: "#3A3C3F",
    borderRadius: "4px",
    opacity: 1,
    // fontWeight: "700",
    fontSize: "14px",
    marginLeft: "25px",
    color: "#2692FF",
    border: "1px solid #686B76",
    "&:hover": {
      background: "#3A3C3F",
      backgroundColor: "#3A3C3F",
      border: "1px solid #2692FF",
    },
  },
  customBtn: {
    width: "88px",
    height: "35px",
    textTransform: "none",
    background: "#3A3C3F",
    backgroundColor: "#3A3C3F",
    borderRadius: "4px",
    opacity: 1,
    // fontWeight: "700",
    fontSize: "14px",

    color: "#FF681C",
    border: "1px solid #686B76",
    marginLeft: "25px",
    "&:hover": {
      background: "#3A3C3F",
      backgroundColor: "#3A3C3F",
      border: "1px solid #FF681C",
    },
  },
  resetBtn: {
    width: "88px",
    height: "35px",
    textTransform: "none",
    background: "#3A3C3F",
    backgroundColor: "#3A3C3F",
    borderRadius: "4px",
    opacity: 1,
    // fontWeight: "700",
    fontSize: "14px",

    color: "#FFC5CD",
    border: "1px solid #686B76",
    marginLeft: "25px",
    "&:hover": {
      background: "#3A3C3F",
      backgroundColor: "#3A3C3F",
      border: "1px solid #FFC5CD",
    },
  },
  reformatBtn: {
    width: "88px",
    height: "35px",
    textTransform: "none",
    background: "#3A3C3F",
    backgroundColor: "#3A3C3F",
    borderRadius: "4px",
    opacity: 1,
    // fontWeight: "700",
    fontSize: "14px",

    color: "#8FB289",
    border: "1px solid #686B76",
    marginLeft: "25px",
    "&:hover": {
      background: "#3A3C3F",
      backgroundColor: "#3A3C3F",
      border: "1px solid #8FB289",
    },
  },

  selectRoot: {
    minWidth: "100px",

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#686B76",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
      "& input": {
        color: "white",
        paddingTop: "8px", // Keep label aligned properly
        paddingBottom: "8px",
      },
      "& input:-webkit-autofill": {
        "-webkit-box-shadow": "0 0 0 100px white inset",
        "-webkit-text-fill-color": "white",
      },
      "& .MuiSelect-icon": {
        color: "white",
        top: "calc(50% - 10px)", // Adjust the marginTop to vertically center the icon
      },
    },
    "& .MuiFormLabel-root": {
      color: "white",
      transform: "translate(14px, 12px) scale(1)", // Adjust label positioning
    },
    "& .MuiInputLabel-shrink": {
      color: "white",
      transform: "translate(14px, -6px) scale(0.75)", // Adjust label positioning when shrunk
    },
    "& .MuiMenu-paper": {
      background: "transparent !important",
      margin: "0 !important", // Set the menu background to transparent
    },
    "& .MuiMenuItem-root": {
      color: "white !important", // Set the text color to white for menu items
    },
  },

  selectStrategy: {
    color: "white",

    "&:before": {
      borderBottomColor: "white",
    },
    "&:after": {
      borderBottomColor: "white",
    },
  },
  tickerInput: {
    minWidth: "100px",
    maxWidth: "100px",

    // "& .MuiTextField-root": {},

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#686B76",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
      "& input": {
        color: "white",
        paddingTop: "8px", // Keep label aligned properly
        paddingBottom: "8px",
      },
      "& input:-webkit-autofill": {
        "-webkit-box-shadow": "0 0 0 100px white inset",
        "-webkit-text-fill-color": "white",
      },
    },
    "& .MuiFormLabel-root": {
      color: "white",
      transform: "translate(14px, 12px) scale(1)", // Adjust label positioning
    },
    "& .MuiInputLabel-shrink": {
      color: "white",
      transform: "translate(14px, -6px) scale(0.75)", // Adjust label positioning when shrunk
    },
    "& .MuiMenu-paper": {
      background: "transparent !important",
      margin: "0 !important", // Set the menu background to transparent
    },
    "& .MuiMenuItem-root": {
      color: "white !important", // Set the text color to white for menu items
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    height: "180px",
    maxWidth: "280px !important",
    minWidth: "100px !important",
    backgroundColor: "#3A3C3F",
    border: "1px solid #686B76",
    color: "white",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "8px",
    "& input": {
      fontSize: "16px",
      backgroundColor: "#3A3C3F",
      color: "white",
      borderRadius: "4px",
      outlineColor: "transparent",
      margin: "0px",
    },
    "& input:hover": {
      border: "1px solid white",
    },
  },
  modalTypography: {
    fontSize: "16px",
  },
  modalBtn: {
    padding: "0px",
    margin: "0px",
    width: "40px",
    height: "30px",
    textTransform: "none",
    background: "#3A3C3F",
    backgroundColor: "#3A3C3F",
    borderRadius: "4px",
    opacity: 1,
    fontSize: "16px",
    color: "#2692FF",
    border: "1px solid #686B76",
    "&:hover": {
      background: "#3A3C3F",
      backgroundColor: "#3A3C3F",
      border: "1px solid #2692FF",
      color: "white",
    },
    "&:disabled": {
      color: "#686B76",
    },
  },

  conformationCloseIcon: {
    color: "white",
    backgroundColor: "#686B76",
    borderRadius: "14px",
    opacity: "0.5",
    "& .MuiSvgIcon-root": {
      fontSize: "1.6rem",
    },
  },

  confoDialog: {
    backgroundColor: "#2D2D2D",
    minWidth: "1800px",
    height: "1100px",
    borderRadius: "12px",
    overflowX: "hidden",
  },
  TextareaAutosize: {
    width: "94%",
    background: "transparent",
    color: "#E0E0E0",
    border: "1px solid #4D4D4D",
    borderRadius: 4,
    padding: 8,
    marginTop: 8,
    resize: "none",
    "&:disabled": {
      background: "#2E2E2E", // Dark gray background when disabled
      color: "#A0A0A0", // Light gray text color when disabled
    },
  },
}));
