import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useStyles } from "../style/style";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={classes.tabPanel}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const TabsNavigator = ({
  tabsDataByColumn,
  setTabChosen,
  markdownContentTabs,
  setMarkdown,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    if (markdownContentTabs !== null) {
      setValue(newValue);
      setTabChosen(tabsDataByColumn[newValue]);
      setMarkdown((pre) => {
        const tab = tabsDataByColumn[newValue];
        const markdownObj = markdownContentTabs?.filter(
          (obj) =>
            Number(obj.traderId) === Number(tab.traderId) &&
            Number(obj.clientId) === Number(tab.clientId),
        );
        if (markdownObj?.length > 0) {
          return markdownObj[0].email_body;
        } else return `Email body is missing`;
      });
    }
  };

  useEffect(() => {
    if (tabsDataByColumn !== null) {
      setTabChosen(tabsDataByColumn[0]);
    }
  }, [tabsDataByColumn]);

  return (
    <div className={classes.rootTabs}>
      <AppBar position="static" className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          className={classes.tabs}
          classes={{ indicator: classes.indicator }} // Override indicator style
        >
          {tabsDataByColumn !== null && markdownContentTabs !== null ? (
            tabsDataByColumn.map((tab, i) => {
              return (
                <Tab
                  label={tab.clientName}
                  {...a11yProps(i)}
                  className={
                    value === i ? classes.selectedTab : classes.notSelectedTab
                  }
                />
              );
            })
          ) : (
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <CircularProgress size={20} color="inherit" />
                  </Grid>
                  <Grid item>
                    <Typography>Loading</Typography>
                  </Grid>
                </Grid>
              }
              {...a11yProps(0)}
              className={value === 0 ? classes.selectedTab : ""}
            />
          )}
        </Tabs>
      </AppBar>
      {tabsDataByColumn !== null ? (
        tabsDataByColumn.map((tab, i) => {
          return <TabPanel value={value} index={i}></TabPanel>;
        })
      ) : (
        <TabPanel value={value} index={0}></TabPanel>
      )}
    </div>
  );
};
