import React from "react";
// Material-UI
import Alert from "@material-ui/lab/Alert";
import { Collapse, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useStyles } from "../../../Styles/uiGeneral";
// Redux
import { useDispatch, useSelector } from "react-redux";
import * as actionSnackBar from "../../../store/snackbar/action";

export default function CustomizedSnackbars() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const snackBarOBJ = useSelector((state) => state.snackBar);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(actionSnackBar.disableSnackBar());
  };

  return (
    <div className={classes.snackbarRoot}>
      <Collapse in={snackBarOBJ.visible} timeout={400} >
        <Alert
          elevation={4}
          variant="filled"
          severity={snackBarOBJ.type}
          style={{maxWidth : '400px'}}
          action={
            <IconButton
              className={classes.closeSnackbar}
              elevation={4}
              variant="filled"
              severity={snackBarOBJ.type}
              onClick={handleClose}
            >
              <CloseIcon style={{ color: "#FFFFFF" }} />
            </IconButton>
          }
        >
          <Typography style={{ color: "#FFFFFF"}}>
            {snackBarOBJ.message}
          </Typography>
        </Alert>
      </Collapse>
    </div>
  );
}
