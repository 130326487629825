import React, { useEffect, useState } from "react";
import { Dialog, Grid, IconButton } from "@material-ui/core";
import { useStyles } from "../../../Styles/pricerStyle";
import { TabsNavigator } from "./tabs/TabsNavigator";
import { ComposeWindow } from "./composeWindow/ComposeWindow";
import { getBookedOrderData } from "./utils/apiCalls";
import { Body } from "./body/Body";
import { tablesConvertor } from "./utils/functions";
import CloseIcon from "@material-ui/icons/Close";
import { Attachment } from "./attachment/Attachment";
import { useDispatch } from "react-redux";
import { DialogContainer } from "../dialogContainer/DialogContainer";

export const ConfirmationDialog = ({
  open,
  handleClose,
  order,
  setOpenBookingModal,
  setOpenConfirmationModal,
  setOpenEConfoModal,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tabsDataByColumn, setTabsDataByColumn] = useState(null);
  const [tabChosen, setTabChosen] = useState(null);
  const [markdown, setMarkdown] = useState(null);
  const [markdownContentTabs, setMarkdownContentTabs] = useState(null);
  const [isTabConfirmed, setIsTabConfirmed] = useState(false);
  const [attachmentPath, setAttachmentPath] = useState(null);
  const [bookedTablesData, setBookedTablesData] = useState(null);

  const handleCancel = () => {
    setTabChosen(null);
    setTabsDataByColumn(null);
    setMarkdown(null);
    setMarkdownContentTabs(null);
    setBookedTablesData(null);
    setIsTabConfirmed(false);
    handleClose();
  };

  useEffect(() => {
    if (open && order.status.toLowerCase().includes("booked")) {
      getBookedOrderData(
        order.id,
        setTabsDataByColumn,
        setMarkdown,
        setMarkdownContentTabs,
        dispatch,
        order,
        setBookedTablesData,
      );
    }
  }, [open]);

  useEffect(() => {
    if (
      open &&
      order.status.toLowerCase().includes("booked") &&
      tabsDataByColumn
    ) {
      tablesConvertor(tabsDataByColumn);
    }
  }, [tabsDataByColumn]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleCancel();
      }}
      classes={{ paper: classes.confoDialog }}
      style={{ overflowX: "hidden" }}
    >
      <DialogContainer
        isConfo={open}
        setOpenBookingModal={setOpenBookingModal}
        setOpenConfirmationModal={setOpenConfirmationModal}
        setOpenEConfoModal={setOpenEConfoModal}
        handleCancelConfo={handleCancel}
        order={order}
        bookedTablesData={bookedTablesData}
      />
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        {/* tabs navigator */}
        <Grid item style={{ width: "100%" }}>
          <TabsNavigator
            tabsDataByColumn={tabsDataByColumn}
            setTabChosen={setTabChosen}
            markdownContentTabs={markdownContentTabs}
            setMarkdown={setMarkdown}
          />
        </Grid>
        {/* close button */}
        {/* <Grid
          style={{ width: "4%", marginBottom: "35px" }}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <IconButton
            aria-label="close"
            className={classes.conformationCloseIcon}
            onClick={handleCancel}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </Grid> */}
        {/* compose fields */}
        <Grid item xs={12}>
          <ComposeWindow
            tabChosen={tabChosen}
            setMarkdown={setMarkdown}
            markdown={markdown}
            order={order}
            tabsDataByColumn={tabsDataByColumn}
            setTabsDataByColumn={setTabsDataByColumn}
            isTabConfirmed={isTabConfirmed}
            setIsTabConfirmed={setIsTabConfirmed}
            attachmentPath={attachmentPath}
            markdownContentTabs={markdownContentTabs}
          />
        </Grid>
        {/* attachment component */}
        <Attachment
          tabChosen={tabChosen}
          order={order}
          markdownContentTabs={markdownContentTabs}
          attachmentPath={attachmentPath}
          setAttachmentPath={setAttachmentPath}
        />
        {/* email body  */}
        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Body
            setMarkdown={setMarkdown}
            markdown={markdown}
            tabChosen={tabChosen}
            setMarkdownContentTabs={setMarkdownContentTabs}
            isTabConfirmed={isTabConfirmed}
            markdownContentTabs={markdownContentTabs}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};
