// MarkitStatusRenderer.jsx
import React from "react";

const MarkitStatusRenderer = (props) => {
  return (
    <span
      style={{
        color:
          props.value === "failure"
            ? "#FF6E57"
            : props.value === "success"
            ? "#007f00"
            : props.value === "pending"
            ? "#686b76"
                : null,
        
      }}
    >
      {props.value}
    </span>
  );
};

export default MarkitStatusRenderer;
