export const initializeTableWithDataAndStyle = (data, jRef, jspreadsheet) => {
  let table = jRef.current.jspreadsheet;
  const tableData = table.getData();

  data.forEach(({ trader, rowIndex }) => {
    // Update table data with trader name
    const row = table.getRowData(rowIndex);
    row.splice(row.length - 1, 1, trader); // Assuming trader name is in the last column
    tableData[rowIndex] = row;

    // Apply highlight styling
    jRef.current.jspreadsheet.setData(tableData);
    // highlightRow(rowIndex + 1, "background-color", "#2f445a"); // Adjust rowIndex if necessary
  });
  data.forEach(({ trader, rowIndex }) => {
    highlightRow(rowIndex + 1, "background-color", "#2f445a");
  });
  // Update the whole table data at once

  // Function to highlight a row
  function highlightRow(rowIndex, styleProperty, styleValue) {
    let totalColumns = table.getConfig().columns.length;
    for (let colIndex = 0; colIndex < totalColumns; colIndex++) {
      let cellId = jspreadsheet.getColumnName(colIndex) + rowIndex; // Adjust if your rowIndex is 0-based
      table.setStyle(cellId, styleProperty, styleValue, "important");
    }
  }
};

export const handleQuoteUpdate = (message, jRef, rfq_id, jspreadsheet) => {
  const { rfqId, trader, rowIndex } = message.data;
  if (Number(rfq_id) === Number(rfqId)) {
    let table = jRef.current.jspreadsheet;
    const tableData = table.getData();
    //extract previous style
    const styleObject = table.getStyle();
    const backgroundColor = "background-color: rgb(47, 68, 90)";
    const uniqueRows = new Set(); // Use a Set to store unique row numbers
    Object.entries(styleObject).forEach(([cellId, style]) => {
      // Check if the style contains the specified background color
      if (style.includes(backgroundColor)) {
        const rowNumber = cellId.replace(/[A-Za-z]/g, ""); // Extract the row number
        uniqueRows.add(parseInt(rowNumber, 10)); // Add the row number as an integer
      }
    });
    const uniqueRowsWithBackground = Array.from(uniqueRows).sort(
      (a, b) => a - b,
    );
    //add new row highlight
    if (trader !== null) {
      uniqueRowsWithBackground.push(rowIndex + 1);
    }

    //table data update
    const row = table.getRowData(rowIndex);
    row.splice(row.length - 1, 1, trader);
    tableData[rowIndex] = row;
    jRef.current.jspreadsheet.setData(tableData);

    const highlightRow = (rowIndex, styleProperty, styleValue) => {
      let totalColumns = table.getConfig().columns.length;
      for (let colIndex = 0; colIndex < totalColumns; colIndex++) {
        let cellId = jspreadsheet.getColumnName(colIndex) + rowIndex;
        table.setStyle(cellId, styleProperty, styleValue, "important");
      }
    };
    const deHighlightRow = (rowIndex, styleProperty, styleValue) => {
      let totalColumns = table.getConfig().columns.length;
      for (let colIndex = 0; colIndex < totalColumns; colIndex++) {
        let cellId = jspreadsheet.getColumnName(colIndex) + (rowIndex + 1); // Assuming rowIndex is 0-based.

        table.setStyle(cellId, styleProperty, styleValue, "important");
      }
    };
    uniqueRowsWithBackground.forEach((r, i) => {
      highlightRow(r, "background-color", "#2f445a");
    });
    if (trader === null) {
      deHighlightRow(rowIndex, "background-color", "#3a3b3e");
    }
  }
};

export const getTableStyle = (message, jRef, rfq_id) => {
  const { rfqId } = message.data;
  if (Number(rfq_id) === Number(rfqId)) {
    let table = jRef.current.jspreadsheet;

    const styleObject = table.getStyle();
    const backgroundColor = "background-color: rgb(47, 68, 90)";
    const uniqueRows = new Set(); // Use a Set to store unique row numbers
    Object.entries(styleObject).forEach(([cellId, style]) => {
      // Check if the style contains the specified background color
      if (style.includes(backgroundColor)) {
        const rowNumber = cellId.replace(/[A-Za-z]/g, ""); // Extract the row number

        uniqueRows.add(parseInt(rowNumber, 10)); // Add the row number as an integer
      }
    });
    const array = Array.from(uniqueRows).sort((a, b) => a - b);

    return array.map((r) => {
      const row = table.getRowData(r - 1);
      return {
        index: r,
        trader: row[row.length - 1],
      };
    });
  }
};

export const handleQuotePriceUpdate = (
  message,
  jRef,
  rfq_id,
  jspreadsheet,
  uniqueRowsWithBackground,
) => {
  const { rfqId } = message.data;
  if (Number(rfq_id) === Number(rfqId)) {
    let table = jRef.current.jspreadsheet;
    const tableData = table.getData();
    uniqueRowsWithBackground.forEach(({ trader, index }) => {
      const row = table.getRowData(index - 1);
      row.splice(row.length - 1, 1, trader); // Assuming trader name is in the last column
      tableData[index - 1] = row;
      jRef.current.jspreadsheet.setData(tableData);
    });

    // const highlightRow = (rowIndex, styleProperty, styleValue) => {
    //   let totalColumns = table.getConfig().columns.length;
    //   for (let colIndex = 0; colIndex < totalColumns; colIndex++) {
    //     let cellId = jspreadsheet.getColumnName(colIndex) + rowIndex;
    //     table.setStyle(cellId, styleProperty, styleValue, "important");
    //   }
    // };

    uniqueRowsWithBackground.forEach((r, i) => {
      highlightRow(r.index, "background-color", "#2f445a", table, jspreadsheet);
    });
  }
};

export const setLocalJTable = (jRef, quote_book, jspreadsheet) => {
  let table = jRef.current.jspreadsheet;
  const styleObject = table.getStyle();
  const backgroundColor = "background-color: rgb(47, 68, 90)";
  const uniqueRows = new Set(); // Use a Set to store unique row numbers
  Object.entries(styleObject).forEach(([cellId, style]) => {
    // Check if the style contains the specified background color
    if (style.includes(backgroundColor)) {
      const rowNumber = cellId.replace(/[A-Za-z]/g, ""); // Extract the row number

      uniqueRows.add(parseInt(rowNumber, 10)); // Add the row number as an integer
    }
  });
  const array = Array.from(uniqueRows).sort((a, b) => a - b);

  const uniqueRowsWithBackground = array.map((r) => {
    const row = table.getRowData(r - 1);
    return {
      index: r,
      trader: row[row.length - 1],
    };
  });
  jRef.current.jspreadsheet.setData(quote_book);
  const tableData = table.getData();
  uniqueRowsWithBackground.forEach(({ trader, index }) => {
    const row = table.getRowData(index - 1);
    if (row[1] === "") {
      row.splice(row.length - 1, 1, trader); // Assuming trader name is in the last column
      tableData[index - 1] = row;
      jRef.current.jspreadsheet.setData(tableData);
    }
  });

  // const highlightRow = (rowIndex, styleProperty, styleValue) => {
  //   let totalColumns = table.getConfig().columns.length;
  //   const row = table.getRowData(rowIndex - 1);
  //   if (row[1] === "") {
  //     for (let colIndex = 0; colIndex < totalColumns; colIndex++) {
  //       let cellId = jspreadsheet.getColumnName(colIndex) + rowIndex;
  //       table.setStyle(cellId, styleProperty, styleValue, "important");
  //     }
  //   }
  // };

  uniqueRowsWithBackground.forEach((r, i) => {
    highlightRow(r.index, "background-color", "#2f445a", table, jspreadsheet);
  });
};

export const highlightRow = (
  rowIndex,
  styleProperty,
  styleValue,
  table,
  jspreadsheet,
) => {
  let totalColumns = table.getConfig().columns.length;
  const row = table.getRowData(rowIndex - 1);
  if (row[1] === "") {
    for (let colIndex = 0; colIndex < totalColumns; colIndex++) {
      let cellId = jspreadsheet.getColumnName(colIndex) + rowIndex;
      table.setStyle(cellId, styleProperty, styleValue, "important");
    }
  }
};
