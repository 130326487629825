import {
  isDataValid,
  getAdjustedPrice,
  getAdjustedTie,
  calculateRef,
} from "./utilsFunctions";

export const orderBidRefValueGetter = (params) => {
  if (isDataValid(params.data)) {
    const isFuture = params.data.futureTickerByLeg !== null;
    const lastPrice = getAdjustedPrice(params.data, isFuture);
    const tie = getAdjustedTie(params.data, isFuture);
    const ask = params.data.ASK || 0;
    const bid = params.data.BID || 0;
    const deltaMidRt = params.data.DELTA_MID_RT;
    const quantity = Number(params.data.quantity);
    if (quantity > 0) {
      return calculateRef(tie, lastPrice, deltaMidRt, bid, quantity);
    } else if (quantity < 0) {
      return calculateRef(tie, lastPrice, deltaMidRt, ask, quantity);
    }
  }
};

export const orderAskRefValueGetter = (params) => {
  if (isDataValid(params.data)) {
    const isFuture = params.data.futureTickerByLeg !== null;
    const lastPrice = getAdjustedPrice(params.data, isFuture);
    const tie = getAdjustedTie(params.data, isFuture);
    const ask = params.data.ASK || 0;
    const bid = params.data.BID || 0;
    const deltaMidRt = params.data.DELTA_MID_RT;
    const quantity = Number(params.data.quantity);
    if (quantity > 0) {
      return calculateRef(tie, lastPrice, deltaMidRt, ask, quantity);
    } else if (quantity < 0) {
      return calculateRef(tie, lastPrice, deltaMidRt, bid, quantity);
    }
  }
};

export const orderMidRefValueGetter = (params) => {
  if (isDataValid(params.data)) {
    const isFuture = params.data.futureTickerByLeg !== null;
    const lastPrice = getAdjustedPrice(params.data, isFuture);
    const tie = getAdjustedTie(params.data, isFuture);
    const ask = params.data.ASK || 0;
    const bid = params.data.BID || 0;
    const deltaMidRt = params.data.DELTA_MID_RT;
    const quantity = Number(params.data.quantity);

    const orderBidRef =
      quantity > 0
        ? calculateRef(tie, lastPrice, deltaMidRt, bid, quantity)
        : calculateRef(tie, lastPrice, deltaMidRt, ask, quantity);
    const orderAskRef =
      quantity > 0
        ? calculateRef(tie, lastPrice, deltaMidRt, ask, quantity)
        : calculateRef(tie, lastPrice, deltaMidRt, bid, quantity);

    return Number(((orderBidRef + orderAskRef) / 2).toFixed(2));
  }
};

export const orderBidValueGetter = (params) => {
  if (
    params.data !== undefined &&
    ((params.data.BID !== undefined && params.data.BID !== "") ||
      (params.data.ASK !== undefined && params.data.ASK !== ""))
  ) {
    const ask = params.data.ASK || 0;
    const bid = params.data.BID || 0;
    const quantity = Number(params.data.quantity);
    if (quantity > 0) {
      return quantity * Number(bid);
    } else if (quantity < 0) {
      return quantity * Number(ask);
    }
  }
};

export const orderAskValueGetter = (params) => {
  if (
    params.data !== undefined &&
    ((params.data.BID !== undefined && params.data.BID !== "") ||
      (params.data.ASK !== undefined && params.data.ASK !== ""))
  ) {
    const ask = params.data.ASK || 0;
    const bid = params.data.BID || 0;
    const quantity = Number(params.data.quantity);
    if (quantity > 0) {
      return quantity * Number(ask);
    } else if (quantity < 0) {
      return quantity * Number(bid);
    }
  }
};
