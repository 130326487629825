import React, { useEffect, useState } from "react";
import { Button, Dialog, Grid } from "@material-ui/core";
import { DialogContainer } from "../dialogContainer/DialogContainer";
import {
  getBookedTradeData,
  getMarkitFavData,
  saveEConfoData,
  submitTradesToMarkit,
} from "./utils/apiCalls";
import { remapData } from "./utils/functions";
import { TradesTable } from "./tables/TradesTable";
import { useStyles } from "./style/style";
import { SWBMLGenerator } from "./utils/swbmlGenerator";
import { useDispatch } from "react-redux";
export const EConfoDialog = ({
  open,
  handleClose,
  order,
  setOpenBookingModal,
  setOpenConfirmationModal,
  setOpenEConfoModal,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [bookedTradeData, setBookedTradeData] = useState(null);
  const [markitFavData, setMarkitFavData] = useState(null);
  const [mappedTableData, setMappedTableData] = useState(null);
  const [isSubmitting, setIsSubmittingForm] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const handleCancel = () => {
    setBookedTradeData(null);
    setMappedTableData(null);
    setMarkitFavData(null);
    setIsSubmittingForm(false);
    setIsFormSubmitted(false);
    handleClose();
  };
  const handleSubmit = async () => {
    setIsSubmittingForm(true);
    const SWBMLArray = SWBMLGenerator(mappedTableData, dispatch);
    if (SWBMLArray !== null) {
      await submitTradesToMarkit(
        SWBMLArray,
        setIsSubmittingForm,
        setMappedTableData,
      );
      await saveEConfoData(order.id, mappedTableData);
    } else setIsSubmittingForm(false);
  };

  useEffect(() => {
    if (open) {
      getBookedTradeData(order.id, setBookedTradeData);
      getMarkitFavData(setMarkitFavData);
    }
  }, [open, order]);

  useEffect(() => {
    if (bookedTradeData !== null && markitFavData !== null) {
      setMappedTableData(remapData(bookedTradeData, markitFavData));
    }
  }, [bookedTradeData, markitFavData]);

  useEffect(() => {
    if (mappedTableData !== null) {
      const markitRow = mappedTableData.find(
        (row) => row.columnHeader.toLowerCase() === "markit status",
      );
      let allTradesSuccessful = false;

      // If the row exists, check the condition
      if (markitRow) {
        allTradesSuccessful = Object.keys(markitRow).every((key) => {
          if (key.toLowerCase().includes("trade")) {
            return markitRow[key] === "success";
          }
          return true; // Ignore non-trade keys
        });
      }
      setIsFormSubmitted(allTradesSuccessful);
    }
  }, [mappedTableData]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleCancel();
      }}
      classes={{ paper: classes.confoDialog }}
      style={{ overflowX: "hidden" }}
    >
      <DialogContainer
        isEConfo={open}
        setOpenBookingModal={setOpenBookingModal}
        setOpenConfirmationModal={setOpenConfirmationModal}
        setOpenEConfoModal={setOpenEConfoModal}
        handleCancelConfo={handleCancel}
        order={order}
      />
      <Grid
        style={{
          opacity: "0.5",
          height: "20px",
          marginBottom: "100px",
        }}
      >
        <hr />
      </Grid>
      <TradesTable
        mappedTableData={mappedTableData}
        setMarkitFavData={setMarkitFavData}
        setMappedTableData={setMappedTableData}
        isSubmitting={isSubmitting}
      />
      <Grid
        item
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        xs={12}
        className={classes.buttonsContainer}
      >
        <Grid item>
          <Button
            variant="contained"
            className={classes.btnMain}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            className={classes.btnMain}
            onClick={handleSubmit}
            disabled={isFormSubmitted}
          >
            {isFormSubmitted ? "Submitted" : "Submit"}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};
