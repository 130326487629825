import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import configureAppStore from "./store";
import { BrowserRouter } from "react-router-dom";
import { LicenseManager } from "ag-grid-enterprise";
import { ErrorBoundary } from "react-error-boundary"; // Import ErrorBoundary
import ErrorFallback from "./components/errorFallback/ErrorFallback"; // Import the fallback component

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE);

export const store = configureAppStore();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ErrorBoundary
        FallbackComponent={ErrorFallback} // Use ErrorFallback for handling errors
        onReset={() => {
          // Optional: handle a reset of the error boundary, like resetting state
        }}
      >
        <App />
      </ErrorBoundary>{" "}
    </BrowserRouter>
  </Provider>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
