import { TableCell, TableContainer, TableRow, styled } from "@material-ui/core";

export const HeaderFontTableCell1 = styled(TableCell)({
  color: "#828282",
  opacity: "0.8",
  // borderRight: "1px solid",
});
export const HeaderFontTableCell2 = styled(TableCell)({
  color: "#828282",
  opacity: "0.8",
});

export const CustomTableContainer = styled(TableContainer)({
  border: "2px solid white",
  borderRadius: "8px",
  maxWidth: "99%",
});
export const StripedTableRow = styled(TableRow)(({ theme, index }) => ({
  "&:nth-of-type(odd)": {
    background: "#222222",
    borderRadius: "8px",
    opacity: 1,
  },
  "&:nth-of-type(even)": {
    background: "#2D2D2D",
    opacity: 1,
  },
}));
export const WhiteFontTableCell = styled(TableCell)({
  color: "white",
});
