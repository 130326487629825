import {
  Button,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useStyles } from "../../../Styles/pricerStyle";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { BookingTables } from "./bookingTables/BookingTables";
import { useSelector, useDispatch } from "react-redux";
import { buildJsonAndBookMakorX } from "./jsonUtils/buildJson";
import { storeBookedTrade } from "./jsonUtils/bookingOrderUtils";
import { END_POINT } from "../../../utils";
import * as actionSnackBar from "../../../store/snackbar/action";
import * as derivsPricerSlice from "../../../store/derivsPricer/derivsPricerSlice";
import { DialogContainer } from "../dialogContainer/DialogContainer";

export const BookingDialog = ({
  open,
  handleClose,
  order,
  gridApi,
  setOpenConfirmationModal,
  setOpenBookingModal,
  setOpenEConfoModal,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { bookingTable, strategyTable, underlyingTable } = useSelector(
    (state) => state.derivsPricerSlice,
  );
  const [bookingText, setBookingText] = useState(null);
  const [parsedBookingText, setParsedBookingText] = useState(null);
  const [makorStaticData, setMakorStaticData] = useState(null);
  const [textAreaOpen, setTextAreaOpen] = useState(true);
  const [textAreaLoading, setTextAreaLoading] = useState(false);
  const [isUnderlyingLoading, setIsUnderlyingLoading] = useState(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [makorXRef, setMakorXRef] = useState(null);
  const [bookedTablesData, setBookedTablesData] = useState(null);
  const [bookedPayloadData, setBookedPayloadData] = useState(null);
  const [isSymphony, setIsSymphony] = useState(false);

  // internal functions
  const handleCancel = () => {
    dispatch(derivsPricerSlice.setParsedBookingText(null));
    setBookingText(null);
    setParsedBookingText(null);
    setMakorStaticData(null);
    setTextAreaOpen(true);
    setTextAreaLoading(false);
    setIsUnderlyingLoading(false);
    setIsSubmittingForm(false);
    setIsSymphony(false);
    setMakorXRef(null);
    setBookedTablesData(null);
    setBookedPayloadData(null);
    handleClose();
  };
  const handleTextAreaChange = (e) => {
    setBookingText(e.target.value.replace(/\r?\n/g, "\\n"));
  };
  const handleSubmitBookingText = async () => {
    try {
      setTextAreaLoading(true);
      const reqBody = {
        booking_request_text: bookingText,

        strategy: {
          symbol: order.symbol,
          equity_index: order.equityIndex,
          strategy: order._strategy,
          Legs: order.legs.map((leg) => {
            return {
              expiry_day: leg.expiryDay,
              expiry_month: leg.expiryMonth,
              expiry_year: leg.expiryYear,
              strike: leg.strike,
              call_put: leg.callPut,
              quantity: leg.quantity,
            };
          }),
          tie: order.tie,
          client_request_text: order.strategy,
        },
      };
      const res = await axios.post(
        process.env.REACT_APP_PYTON + "parse_derivs_booking",
        reqBody,
      );
      if (res.data.error === undefined) {
        setParsedBookingText(res.data);
        dispatch(derivsPricerSlice.setParsedBookingText(res.data));

        const staticData = await getStaticData();
        setMakorStaticData(staticData.data);
        setTextAreaOpen(false);
        setTextAreaLoading(false);
      } else {
        console.log("handleSubmitBookingText error:", res.data.error);
        dispatch(actionSnackBar.setSnackBar("error", res.data.error, 3000));
        console.log(res.data.error);
        setTextAreaLoading(false);
      }
    } catch (error) {
      console.log("handleSubmitBookingText error:", error);
      dispatch(
        actionSnackBar.setSnackBar("error", error.response.statusText, 3000),
      );
      console.log(error.response.statusText);
      setTextAreaLoading(false);
    }
  };

  const handleFormSubmit = async () => {
    if (parsedBookingText !== null && makorStaticData !== null) {
      setIsSubmittingForm(true);
      try {
        const res = await buildJsonAndBookMakorX(
          makorStaticData,
          bookingTable,
          strategyTable,
          underlyingTable,
          parsedBookingText,
          dispatch,
          order,
        );
        // Check booking status and handle accordingly
        if (res?.status && res?.status?.toLowerCase() === "booked") {
          setMakorXRef(res.trade_id);
          await storeBookedTrade({ ...res, order });
          setBookedTablesData({
            strategyTable: res.strategyTable,
            bookingTable: res.bookingTable,
            underlyingTable: res.underlyingTable,
          });
          await getBookedOrderData(
            order.id,
            setMakorXRef,
            setBookedTablesData,
            setParsedBookingText,
            setMakorStaticData,
            dispatch,
            setBookedPayloadData,
          );
          dispatch(
            actionSnackBar.setSnackBar("success", "Booking successful!", 3000),
          );
        } else {
          dispatch(
            actionSnackBar.setSnackBar(
              "error",
              "Booking failed: " + res.message,
              3000,
            ),
          );
        }
      } catch (error) {
        console.error("Error during form submission:", error);
        dispatch(
          actionSnackBar.setSnackBar(
            "error",
            "An error occurred: " + error.message,
            3000,
          ),
        );
      } finally {
        setIsSubmittingForm(false);
      }
    }
  };

  const refToMakorX = async () => {
    if (makorXRef !== null) {
      const urlToOpen = `https://www.makor-x.com/derivative/trade-form/?trade_id=${makorXRef}`;
      window.open(urlToOpen, "_blank");
    }
  };

  useEffect(() => {
    if (
      open &&
      order.status.toLowerCase().includes("booked") &&
      parsedBookingText === null
    ) {
      setTextAreaOpen(false);
      getBookedOrderData(
        order.id,
        setMakorXRef,
        setBookedTablesData,
        setParsedBookingText,
        setMakorStaticData,
        dispatch,
        setBookedPayloadData,
      );
    }
  }, [open, order, parsedBookingText]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          handleCancel();
        }}
        classes={{ paper: classes.bookingDialog }}
      >
        <DialogContainer
          isBooking={open}
          setOpenConfirmationModal={setOpenConfirmationModal}
          setOpenBookingModal={setOpenBookingModal}
          setOpenEConfoModal={setOpenEConfoModal}
          handleCancelBooking={handleCancel}
          order={order}
          bookedTablesData={bookedTablesData}
        />
        <Grid container>
          {/* Header Grid  */}
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid xs={12} style={{ opacity: "0.5" }}>
              <hr />
            </Grid>
          </Grid>
          {/* Dialog Body Grid */}
          <Grid
            item
            container
            xs={12}
            style={{
              // border: "1px solid yellow",
              minHeight: "950px",
            }}
          >
            {/* Booking Text Grid  */}
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              xs={12}
              item
              style={{
                maxHeight: textAreaOpen ? "450px" : "60px",
                marginTop: "20px",
              }}
            >
              <Grid item className={classes.bookingTextTypography} xs={2}>
                <Typography variant="h5">Booking Text</Typography>
              </Grid>
              <Grid
                item
                xs={10}
                style={{ display: textAreaOpen ? null : "none" }}
              >
                <TextareaAutosize
                  className={classes.bookingTextArea}
                  aria-label="minimum height"
                  minRows={20}
                  maxRows={20}
                  placeholder="Enter Booking Text"
                  onChange={handleTextAreaChange}
                  disabled={textAreaLoading}
                />
              </Grid>

              <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                style={{
                  marginRight: "78px",
                  display: textAreaOpen ? null : "none",
                }}
              >
                <Button
                  variant="contained"
                  className={classes.btn}
                  onClick={() => {
                    handleSubmitBookingText();
                  }}
                  style={{ marginTop: "20px" }}
                  disabled={bookingText === null}
                >
                  {textAreaLoading ? (
                    <CircularProgress size={24} color="white" />
                  ) : (
                    "Create"
                  )}
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setTextAreaOpen(!textAreaOpen)}
                  style={{ color: "white", marginRight: "30px" }}
                  disabled={order.status.toLowerCase().includes("booked")}
                >
                  {textAreaOpen ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              </Grid>
            </Grid>
            {/* Booking parsed form Grid  */}
            <Grid
              item
              xs={12}
              style={{
                border: "1px solid grey",
                minHeight: textAreaOpen ? "300px" : "650px",
                maxHeight: textAreaOpen ? "300px" : "650px",
                overflow: "auto",
                overflowX: "hidden",
              }}
            >
              {parsedBookingText !== null && makorStaticData !== null ? (
                <BookingTables
                  parsedBookingText={parsedBookingText}
                  makorStaticData={makorStaticData}
                  order={order}
                  gridApi={gridApi}
                  setIsUnderlyingLoading={setIsUnderlyingLoading}
                  isSubmittingForm={isSubmittingForm}
                  bookedTablesData={bookedTablesData}
                  bookedPayloadData={bookedPayloadData}
                  isSymphony={isSymphony}
                  setIsSymphony={setIsSymphony}
                />
              ) : !textAreaOpen ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh",
                  }}
                >
                  <CircularProgress style={{ color: "white" }} />
                </div>
              ) : null}
            </Grid>
          </Grid>
          {/* buttons Grid */}
          <Grid
            xs={12}
            item
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={10}
          >
            {makorXRef !== null ? (
              <Grid item>
                <Button
                  variant="contained"
                  className={classes.btnMakorX}
                  onClick={refToMakorX}
                >
                  View in Makor X
                </Button>
              </Grid>
            ) : null}
            <Grid item>
              <Button
                variant="contained"
                className={classes.btn}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                className={classes.btn}
                onClick={
                  !order.status.toLowerCase().includes("booked")
                    ? handleFormSubmit
                    : undefined
                }
                disabled={
                  order.status.toLowerCase().includes("booked") ||
                  isUnderlyingLoading
                }
              >
                {isSubmittingForm ? (
                  <CircularProgress size={24} color="white" />
                ) : (
                  ` ${
                    order.status.toLowerCase().includes("booked")
                      ? "Booked"
                      : "Submit"
                  }`
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export const getStaticData = async () => {
  return await axios.get(
    `https://api.makor-x.com/le_tool/drv_create?api_key=${process.env.REACT_APP_MAKOR_X_KEY}`,
  );
};

export const getBookedOrderData = async (
  id,
  setMakorXRef,
  setBookedTablesData,
  setParsedBookingText,
  setMakorStaticData,
  dispatch,
  setBookedPayloadData,
) => {
  try {
    const token = sessionStorage.getItem("token");
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}${END_POINT.DERIVS_GET_BOOKED_ORDER_DATA}/${id}`,
      { headers: { Authorization: token } },
    );
    if (res.data && res.data.payload) {
      setMakorXRef(res.data.payload.makorXRef);
      setBookedTablesData(res.data.payload.tablesData);
      setParsedBookingText(res.data.payload.parsedBookingText);
      setBookedPayloadData(res.data.payload);
      dispatch(
        derivsPricerSlice.setParsedBookingText(
          res.data.payload.parsedBookingText,
        ),
      );
      const staticData = await getStaticData();
      setMakorStaticData(staticData.data);
    }
  } catch (error) {
    console.error("Failed to fetch booked order data:", error);
    dispatch(
      actionSnackBar.setSnackBar(
        "error",
        "Failed to fetch booked order data: " + error.message,
        3000,
      ),
    );
  }
};
