import {
  useStyles,
  StyledVisabilityIcon,
  StyledNoneVisabilityIcon,
} from "./ChangePassword.styles";
import { IconButton, InputAdornment } from "@mui/material";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import { Grid, InputBase, Typography, Button } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

function ChangePasswordView({
  valid,
  logout,
  formState,
  validState,
  equalPasswords,
  saveNewPassword,
  handlePasswordCopy,
  handleClickShowPassword,
}) {
  const classes = useStyles();
  const headers = [
    "Minimum 9 characters",
    "1 Uppercase alpha character(A,B, ...Z)",
    "1 Lowercase alpha character(a,b, ...z)",
    "1 number (1,2,3,4,5,6,7,8,9,0)",
    "1 Non-alphanumeric ASCII characters (!@#^)",
  ];
  return (
    <Grid
      container
      className={classes.mainContainer}
      direction="row"
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        item
        container
        xs={11}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography style={{ color: "#ffffff", fontSize: "1.63rem" }}>
            Create New Password
          </Typography>
        </Grid>
        <Grid item>
          <IconButton style={{ background: "#3A3C3F" }} onClick={logout}>
            <CloseIcon style={{ fontSize: "1.3125rem", color: "#ffffff" }} />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item xs={11} container justifyContent="space-between">
        {/* //Left */}
        <Grid item style={{ height: "fit-content" }}>
          <Grid item style={{ height: "fit-content" }}>
            <InputBase
              onChange={(e) => valid(e)}
              className={classes.input}
              placeholder="New Password"
              name="password"
              type={formState.showNewPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("new")}
                  >
                    {formState.showNewPassword ? (
                      <StyledVisabilityIcon />
                    ) : (
                      <StyledNoneVisabilityIcon
                        fontSize="1.25rem"
                        style={{ opacity: "100%" }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item style={{ height: "34px" }}>
            <InputBase
              className={classes.input}
              placeholder="Confirm new Password"
              onChange={(e) => handlePasswordCopy(e)}
              name="password"
              type={formState.showConfirmedPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("confirmed")}
                  >
                    {formState.showConfirmedPassword ? (
                      <StyledVisabilityIcon />
                    ) : (
                      <StyledNoneVisabilityIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={10} style={{ minHeight: "17px" }}>
            {equalPasswords === false ? (
              <Typography style={{ fontSize: "1rem", color: "red" }}>
                Passwords does not match
              </Typography>
            ) : null}
          </Grid>
          <Grid style={{ paddingTop: "28px", width: "265px" }}>
            <div
              style={{
                backgroundColor: "#3A3C3F",
                width: "100%",
                borderRadius: "8px",
                height: "6px",
              }}
            >
              {validState &&
                validState.strength &&
                validState.validation.every((item) => item.valid === true) && (
                  <div
                    style={{
                      height: "6px",
                      width:
                        formState.password === ""
                          ? "0%"
                          : validState.strength === "Weak"
                          ? "20%"
                          : validState.strength === "Strong"
                          ? "50%"
                          : validState.strength === "Very strong"
                          ? "100%"
                          : null,
                      backgroundColor: "#00CA80",
                      borderRadius: 4,
                    }}
                  ></div>
                )}
            </div>
            <Grid item style={{ color: "#ffffff", paddingTop: "5px" }}>
              <Grid container>
                <Grid item xs={4}>
                  <Typography style={{ textAlign: "left" }}>Weak</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography style={{ textAlign: "center" }}>
                    Strong
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography style={{ textAlign: "right" }}>
                    Very Strong
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* //Right */}
        <Grid
          item
          xs={6}
          container
          style={{ position: "relative", bottom: "25px" }}
        >
          <Grid item xs={12} style={{ paddingBottom: "3px" }}>
            <Typography style={{ color: "#686b76", fontSize: "1.3125rem" }}>
              Contains at least:
            </Typography>
          </Grid>
          <Grid
            item
            container
            style={{
              width: "314px",
              height: "158px",
              backgroundColor: "#3A3C3F",
              borderRadius: "4px",
              paddingTop: "10px",
            }}
          >
            {validState &&
              validState.validation.map((item, idx) => {
                return (
                  <Grid item xs={12} key={idx}>
                    <Grid
                      container
                      alignItems="center"
                      jusitifyContent="space-between"
                    >
                      <Grid item container justifyContent="center" xs={1}>
                        <FiberManualRecordIcon
                          style={{ fontSize: "10px", color: "#2692FF" }}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography
                          style={{ fontSize: "1.125rem", color: "#ffffff" }}
                        >
                          {headers[idx]}
                        </Typography>
                      </Grid>
                      <Grid item xs={1} style={{ height: "17px" }}>
                        {item.valid ? (
                          <DoneIcon
                            style={{ color: "#00CA80", fontSize: "18px" }}
                          />
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        justifyContent="center"
        style={{ marginBottom: "10px" }}
      >
        <Button
          className={classes.button}
          onClick={saveNewPassword}
          disabled={
            validState.validation.some((v) => v.valid === false) ||
            equalPasswords === false
          }
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
}

export default ChangePasswordView;
