import { Grid, TextField, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useTheme, withStyles } from "@material-ui/core/styles";
import { ReactComponent as Copy } from "../../../../assets/copy.svg";
import { makeStyles } from "@material-ui/core/styles";
import "./RfqModalDealStats.css";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import * as rfqSlice from "../../../../store/rfq/rfqSlice";
import * as rfqAggregatorSlice from "../../../../store/rfq/rfqAggregatorSlice";
import { ReactComponent as Filter } from "../../../../assets/filter.svg";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import clsx from "clsx";
import { END_POINT } from "../../../../utils";
import DealStatsPrice from "./dealStatsPrice/DealStatsPrice";
import DealStatsVol from "./dealStatsPrice/DealStatsVol";
import DealStatsSwap from "./dealStatsPrice/DealStatsSwap";
import { CleanHands, CompressOutlined } from "@mui/icons-material";
import { createQuoteBookDict } from "../../../../services/rfqWorkspace/createQuoteBookDict";

const RfqModalDealStats = ({
  header,
  rfq_object,
  setrfq_object,
  data,
  column,
}) => {
  const rfq_id = sessionStorage.getItem("rfqId");
  const classes = useStyles();
  const theme = useTheme();
  const lgScreen = theme.breakpoints.between("md", "lg");
  const dispatch = useDispatch();
  let dealStatsData = useSelector((state) => state.rfqSlice.dealStatsData);
  let tableData = useSelector((state) => state.rfqSlice.tableData);
  let copyDealStatsData = JSON.parse(JSON.stringify(dealStatsData));
  const [disableButton, setDisableButton] = useState(false);
  const rfqMode = useSelector((state) => state.rfqSlice.rfqMode);
  const [anchorEl, setAnchorEl] = React.useState();
  const rfqQuoteParameters = useSelector(
    (state) => state.rfqAggregatorSlice.RfqQuoteParameters
  );
  const rfq = useSelector((state) => state.rfqSlice.rfqId.items);
  const userSpread = useSelector(
    (state) => state.rfqAggregatorSlice.userSpread
  );
  let { volLegParams, rfqDefaultQuoteParams } = useSelector(
    (state) => state.rfqAggregatorSlice
  );
  let filterVolLegParams = volLegParams.filter(
    (params) => Number(params.rfq_id) === Number(rfq_id)
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = async (event, val) => {
    setAnchorEl(null);
  };
  const calculate_pnl = async (e) => {
    if (e) {
      if (e.target) {
        if (e.target.name === "makor_bid") {
          copyDealStatsData.makor_bid = e.target.value;
          let bestBid = Number(tableData[0].bid);
          let userBid = Number(e.target.value);
          let bidResults = (bestBid - userBid).toFixed(3);
          let userSpredDetails = [{ userSpreadBid: bidResults }];
          dispatch(rfqAggregatorSlice.setUsersSpreadhandler(userSpredDetails));
        }
        if (e.target.name === "makor_offer") {
          copyDealStatsData.makor_offer = e.target.value;
          let bestOffer = Number(tableData[0].offer);
          let userOffer = Number(e.target.value);
          let offerResults = (userOffer - bestOffer).toFixed(3);

          let userSpredDetails = [{ userSpreadOffer: offerResults }];
          dispatch(rfqAggregatorSlice.setUsersSpreadhandler(userSpredDetails));
        }
        dispatch(rfqSlice.updateDealStatsData(copyDealStatsData));
      }
    }
    await api_get_deal_stats(e);
  };
  const preset_spreads = () => {
    const qt = rfqQuoteParameters?.quote_type;
    if (
      ["DOUBLE_LEG_VOL", "SINGLE_LEG_VOL", "STRANGLE_STRADDLE"].includes(qt)
    ) {
      return [0.025, 0.05, 0.075, 0.1, 0.25, 0.5, 1, 1.5, 2];
    }
    if (["PCT_PAYOUT"].includes(qt)) {
      return [0.25, 0.5, 1.0, 1.25, 1.5, 2, 2.25, 2.5, 3];
    }
    if (["PCT_NOTIONAL"].includes(qt)) {
      if (rfqQuoteParameters?.quote_format === "BPS") {
        return [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5];
      } else {
        return [0.01, 0.015, 0.02, 0.025, 0.03, 0.035, 0.04, 0.045, 0.05];
      }
    }
    if (["SWAP"].includes(qt)) {
      return [0.25, 0.5, 1.0, 1.5, 2, 3, 5, 10];
    }
    return [];
  };
  const apply_preset_spread = async (e, val) => {

    let copyUserSpredDetails = [...userSpread];
    let makorBid, makorOffer;
    setAnchorEl(null);
    if (rfqQuoteParameters.quote_type === "SWAP") {
      let copyDealStats = JSON.parse(JSON.stringify(dealStatsData));
      let swap_pts_per_bps = Number(copyDealStats.swap_pts_per_bps);
      val = swap_pts_per_bps * val;
      val = Math.round(val);
      let regex = /[,\s]/g;
      if (copyDealStatsData.makor_bid !== "---") {
        copyUserSpredDetails[0] = {
          ...copyUserSpredDetails[0],
          isUserChangeBid: false,
          isUserChangeOffer: false,
          userSpreadBid: val,
          userSpreadOffer: val.toFixed(3),
          isUserChangeSpreadBid: true,
          isUserChangeSpreadOffer: true,
        };
        dispatch(
          rfqAggregatorSlice.setUsersSpreadhandler(copyUserSpredDetails)
        );
        makorBid = copyDealStatsData.market_bid.replace(regex, '')
        makorBid = +makorBid - val
        // makorOffer = (parseFloat(makorBid) + val).toFixed(
        //   3
        // );
      }
      if (copyDealStatsData.makor_offer !== "---") {
        copyUserSpredDetails[0] = {
          ...copyUserSpredDetails[0],
          isUserChangeBid: false,
          isUserChangeOffer: false,
          userSpreadBid: val.toFixed(3),
          userSpreadOffer: val.toFixed(3),
          isUserChangeSpreadBid: true,
          isUserChangeSpreadOffer: true,
        };
        dispatch(
          rfqAggregatorSlice.setUsersSpreadhandler(copyUserSpredDetails)
        );
        makorOffer = copyDealStatsData.market_offer.replace(regex, '')
        makorOffer = +makorOffer + val
        // makorOffer = (parseFloat(makorOffer) + val).toFixed(
        //   3
        // );
      }
    } else {
      if (copyDealStatsData.makor_bid !== "---") {
        copyUserSpredDetails[0] = {
          ...copyUserSpredDetails[0],
          isUserChangeBid: false,
          isUserChangeOffer: false,
          userSpreadBid: val,
          userSpreadOffer: val,
          isUserChangeSpreadBid: true,
          isUserChangeSpreadOffer: true,
        };
        dispatch(
          rfqAggregatorSlice.setUsersSpreadhandler(copyUserSpredDetails)
        );
        makorBid = (parseFloat(copyDealStatsData.market_bid) - val).toFixed(3);
      }
      if (copyDealStatsData.makor_offer !== "---") {
        copyUserSpredDetails[0] = {
          ...copyUserSpredDetails[0],
          isUserChangeBid: false,
          isUserChangeOffer: false,
          userSpreadBid: val,
          userSpreadOffer: val,
          isUserChangeSpreadBid: true,
          isUserChangeSpreadOffer: true,
        };
        dispatch(
          rfqAggregatorSlice.setUsersSpreadhandler(copyUserSpredDetails)
        );
        makorOffer = (parseFloat(copyDealStatsData.market_offer) + val).toFixed(
          3
        );
      }
    }

    apiUpdateUserSpread(copyUserSpredDetails);

    await api_get_deal_stat(makorBid, makorOffer);
  };
  const applyUserSpreadOffer = async (e) => {
    e.preventDefault();
    console.log("123")
    let copyDealStats = JSON.parse(JSON.stringify(dealStatsData));
    let val = e.target.value

    if (rfqQuoteParameters.quote_type === "SWAP") {
    let regex = /[,\s]/g;
    let makorOffer = (parse_float_custom(copyDealStats.market_offer) + parse_float_custom(val)).toFixed(3)
    copyDealStats.makor_offer = makorOffer
    // let marketOffer = copyDealStats.market_offer.replace(regex, '')
   
    // let addValToMakorOffer = +marketOffer + val
    // copyDealStats.makor_offer = Number(addValToMakorOffer).toLocaleString().replace(",000,",",")

    let userSpredDetails = [
      { isBidUser: false, userSpreadOffer: val },
    ];
    dispatch(rfqAggregatorSlice.setUsersSpreadhandler(userSpredDetails));
  }else {

    copyDealStats.makor_offer = (parse_float_custom(copyDealStats.market_offer) + parse_float_custom(val)).toFixed(3)
    // copyDealStats.makor_offer = (
    //   Number(copyDealStats.market_offer) + Number(val)
    // ).toFixed(3);
    let userSpredDetails = [
      { isBidUser: false, userSpreadOffer: val},
    ];
    dispatch(rfqAggregatorSlice.setUsersSpreadhandler(userSpredDetails));
  }
    dispatch(rfqSlice.updateDealStatsData(copyDealStats))
  };
  const parse_float_custom = (number_string) => {
    if (isString(number_string)) {
      return parseFloat(number_string.replace(/,/g, ""));
    } else {
      return number_string;
    }
  }
  const isString = (x) => {
    return Object.prototype.toString.call(x) === "[object String]";
  }
  const applyUserSpreadBid = async (e) => {
    let val = e.target.value

    let copyDealStats = JSON.parse(JSON.stringify(dealStatsData));
    if (rfqQuoteParameters.quote_type === "SWAP") {
    let regex = /[,\s]/g;
    // let marketBid = copyDealStats.market_bid.replace(regex, '')
    let makorBid = (parse_float_custom(copyDealStats.market_bid) - parse_float_custom(val)).toFixed(3)
    // let marketBid = copyDealStats.market_bid.replace(regex, '')
    // let addValToMakorBid = +marketBid - val
    copyDealStats.makor_bid = makorBid
    // copyDealStats.makor_bid = Number(addValToMakorBid).toLocaleString().replace(",000,",",")
    // copyDealStats.makor_bid = +marketBid + val
    // dispatch(rfqSlice.updateDealStatsData(copyDealStats));
    // copyDealStats.makor_bid =
    //   Number(copyDealStats.market_bid) - Number(e.target.value).toFixed(3);
    dispatch(rfqSlice.updateDealStatsData(copyDealStats));

    let userSpredDetails = [
      { isBidUser: false, userSpreadBid: val },
    ];
    dispatch(rfqAggregatorSlice.setUsersSpreadhandler(userSpredDetails));
  }else {
    copyDealStats.makor_bid = (parse_float_custom(copyDealStats.market_bid) - parse_float_custom(val)).toFixed(3)
    // copyDealStats.makor_bid =
    //   Number(copyDealStats.market_bid) - Number(e.target.value).toFixed(3);
    dispatch(rfqSlice.updateDealStatsData(copyDealStats));
    let userSpredDetails = [
      { userSpreadBid: e.target.value, isBidUser: false },
    ];
    dispatch(rfqAggregatorSlice.setUsersSpreadhandler(userSpredDetails));
  }
    dispatch(rfqSlice.updateDealStatsData(copyDealStats))
  };
  const apiUpdateUserSpread = async (dealstateData) => {
    const token = sessionStorage.getItem("token");
    const rfqId = sessionStorage.getItem("rfqId");
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}` +
          END_POINT.DEAL_STATS +
          "/" +
          END_POINT.DEAL_STATS_BY_USER +
          "/" +
          rfqId,
        { dealstateData },
        { headers: { Authorization: token } }
      );
    } catch (error) {
      console.log(error);
    }
  };
  const apiDeleteUserSpread = async () => {
    const token = sessionStorage.getItem("token");
    const rfqId = sessionStorage.getItem("rfqId");

    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}` +
          END_POINT.DEAL_STATS +
          "/" +
          END_POINT.DELETE_DEAL_STATS_BY_USER,
        { rfqId },
        { headers: { Authorization: token } }
      );
    } catch (error) {
      console.log(error);
    }
  };
  const api_get_deal_stat = async (makorBid, makorOffer) => {
    let copyDealStatsDat = JSON.parse(JSON.stringify(dealStatsData));
    let jtableDict = await createQuoteBookDict();
    copyDealStatsDat.is_users_price = true;
    copyDealStatsDat.makor_bid = makorBid;
    copyDealStatsDat.makor_offer = makorOffer;
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_PYTON}` + `get_deal_stats`,
        {
          quote_type: rfqQuoteParameters.quote_type,
          rfq_table_data: jtableDict,
          quote_parameters: rfqQuoteParameters,
          deal_stats: copyDealStatsDat,
          order_book: tableData,
          type: "deal_stats modal",
        }
      );
      if(rfqQuoteParameters.quote_type === "SWAP"){
        let obj = {...response.data};
      obj = {
        ...obj,
        market_bid: response.data.market_bid
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"),
        market_offer: response.data.market_offer
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"),
        makor_bid: response.data.makor_bid
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"),
        makor_offer: response.data.makor_offer
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"),
      };

      response.data.rfq_id = rfq_object.rfq_id;
      dispatch(rfqSlice.updateDealStatsData(obj));
      }else {
        response.data.rfq_id = rfq_object.rfq_id;
        dispatch(rfqSlice.updateDealStatsData(response.data));
      }
      
    } catch (error) {
      console.log(error);
    }
  };
  const api_get_deal_stats = async (
    e,
    IsUserPrice,
    isUserChangeBidOrOffer,
    type
  ) => {
    let jtableDict = await createQuoteBookDict();
    try {
      if (e.key === "Enter") {
        let copyDealStatsDat = JSON.parse(JSON.stringify(dealStatsData));
        let swap_pts_per_bps = Number(copyDealStatsDat.swap_pts_per_bps);
        let val = swap_pts_per_bps * 2;
        val = Math.round(val);
        let regex = /[,\s]/g;
     
        if (
          e.target.value === "" &&
          (type === "bidPnl" || type === "offerPnl")
        ) {
          
          let makorBid;
          let makorOffer;
          if (rfqQuoteParameters.quote_type === "SWAP") {
       
            makorBid = copyDealStatsData.market_bid.replace(regex, '')
            makorBid = +makorBid - val
            makorOffer = copyDealStatsData.market_offer.replace(regex, '')
            makorOffer = +makorOffer + val
          } else {
          
            if (copyDealStatsDat.client_price_string.includes("BPS") || copyDealStatsDat.quote_format === "BPS") {
              makorBid = (parseFloat(copyDealStatsData.market_bid) - 1).toFixed(
                3
              );
              makorOffer = (
                parseFloat(copyDealStatsData.market_offer) + 1
              ).toFixed(3);
            } else {
              makorBid = (
                parseFloat(copyDealStatsData.market_bid) - 0.1
              ).toFixed(3);
              makorOffer = (
                parseFloat(copyDealStatsData.market_offer) + 0.1
              ).toFixed(3);
            }
          }

          copyDealStatsDat.makor_offer = makorOffer
          // .replace(
          //   /(\d)(?=(\d{3})+(?!\d))/g,
          //   "$1,"
          // );
  
          copyDealStatsDat.makor_bid = makorBid
          // .replace(
          //   /(\d)(?=(\d{3})+(?!\d))/g,
          //   "$1,"
          // );

          let copyUserSpredDetails = [...userSpread];
          if (type === "bidPnl") {
            copyUserSpredDetails[0] = {
              ...copyUserSpredDetails[0],
              isUserChangeBid: false,
              isUserChangeOffer: userSpread[0].isUserChangeOffer,
              userSpreadBid:
                rfqQuoteParameters.quote_type === "SWAP"
                  ? Number(val).toFixed(3)
                  : copyDealStatsDat.client_price_string.includes("BPS") || copyDealStatsDat.quote_format === "BPS"
                  ? Number(1).toFixed(3)
                  : Number(0.1).toFixed(3),
              isUserChangeSpreadBid: false,
              isUserChangeSpreadOffer: userSpread[0].isUserChangeSpreadOffer,
            };
          } else if (type === "offerPnl") {
            copyUserSpredDetails[0] = {
              ...copyUserSpredDetails[0],
              isUserChangeOffer: false,
              userSpreadOffer:
                rfqQuoteParameters.quote_type === "SWAP"
                  ? Number(val).toFixed(3)
                  : copyDealStatsDat.client_price_string.includes("BPS") || copyDealStatsDat.quote_format === "BPS"
                  ? Number(1).toFixed(3)
                  : Number(0.1).toFixed(3),
              isUserChangeBid: userSpread[0].isUserChangeBid,
              isUserChangeSpreadBid: userSpread[0].isUserChangeSpreadBid,
              isUserChangeSpreadOffer: false,
            };
          }
          apiUpdateUserSpread(copyUserSpredDetails);
          dispatch(
            rfqAggregatorSlice.setUsersSpreadhandler(copyUserSpredDetails)
          );
          let response = await axios.post(
            `${process.env.REACT_APP_PYTON}` + `get_deal_stats`,
            {
              quote_type: rfqQuoteParameters.quote_type,
              rfq_table_data: jtableDict,
              quote_parameters: rfqQuoteParameters,
              deal_stats: copyDealStatsDat,
              order_book: tableData,
              type: "deal_stats modal 2",
            }
          );
          setrfq_object(rfq_object);

          dispatch(rfqSlice.updateDealStatsData(response.data));
        } else if (e.target.value === "" && type === "bidSpread") {
          let copyDealStats = JSON.parse(JSON.stringify(dealStatsData));
          let makorBid;
      
          if (rfqQuoteParameters.quote_type === "SWAP") {
            
            let swap_pts_per_bps = Number(copyDealStats.swap_pts_per_bps);
            let val = swap_pts_per_bps * 2;
            val = Math.round(val);
            makorBid = copyDealStatsData.market_bid.replace(regex, '')
            makorBid = +makorBid - val
          } else {
            
         

            if (copyDealStatsDat.client_price_string.includes("BPS") || copyDealStatsDat.quote_format === "BPS") {
              makorBid = (parseFloat(copyDealStatsData.market_bid) - 1).toFixed(
                3
              );
            } else {
              makorBid = (
                parseFloat(copyDealStatsData.market_bid) - 0.1
              ).toFixed(3);
            }
          }
        
          if(rfqQuoteParameters.quote_type === "SWAP"){
            copyDealStats.makor_bid = makorBid.toString().replace(
              /(\d)(?=(\d{3})+(?!\d))/g,
              "$1,"
            );
          }else {
            copyDealStats.makor_bid = makorBid
          }
          
          let copyUserSpredDetails = [...userSpread];
          copyUserSpredDetails[0] = {
            ...copyUserSpredDetails[0],
            userSpreadBid:
              rfqQuoteParameters.quote_type === "SWAP"
                ? Number(val).toFixed(3)
                : copyDealStatsDat.client_price_string.includes("BPS") || copyDealStatsDat.quote_format === "BPS"
                ? Number(1).toFixed(3)
                : Number(0.1).toFixed(3),
            isUserChangeSpreadBid: false,
          };
          dispatch(
            rfqAggregatorSlice.setUsersSpreadhandler(copyUserSpredDetails)
          );
          dispatch(rfqSlice.updateDealStatsData(copyDealStats));
          apiUpdateUserSpread(copyUserSpredDetails);
        } else if (e.target.value === "" && type === "offerSpread") {
          
          let copyDealStats = JSON.parse(JSON.stringify(dealStatsData));
          let makorOffer;
          if (rfqQuoteParameters.quote_type === "SWAP") {
            let swap_pts_per_bps = Number(copyDealStats.swap_pts_per_bps);
            let val = swap_pts_per_bps * 2;
            val = Math.round(val);
            makorOffer = copyDealStatsData.market_offer.replace(regex, '')
            makorOffer = +makorOffer + val
          } else {
            if (copyDealStatsDat.client_price_string.includes("bps")) {
              makorOffer = (
                parseFloat(copyDealStatsData.market_offer) + 1
              ).toFixed(3);
            } else {
              makorOffer = (
                parseFloat(copyDealStatsData.market_offer) + 0.1
              ).toFixed(3);
            }
          }
          
          if(rfqQuoteParameters.quote_type === "SWAP"){
          copyDealStats.makor_offer = makorOffer.toString().replace(
            /(\d)(?=(\d{3})+(?!\d))/g,
            "$1,"
          );
          }else {
            copyDealStats.makor_offer = makorOffer
          }
          let copyUserSpredDetails = [...userSpread];
          copyUserSpredDetails[0] = {
            ...copyUserSpredDetails[0],
            userSpreadOffer:
              rfqQuoteParameters.quote_type === "SWAP"
                ? Number(val).toFixed(3)
                : copyDealStatsDat.client_price_string.includes("BPS") || copyDealStatsDat.quote_format === "BPS"
                ? Number(1).toFixed(3)
                : Number(0.1).toFixed(3),
            isUserChangeSpreadOffer: false,
          };
          dispatch(rfqSlice.updateDealStatsData(copyDealStats));
          dispatch(
            rfqAggregatorSlice.setUsersSpreadhandler(copyUserSpredDetails)
          );
          apiUpdateUserSpread(copyUserSpredDetails);
        } else {
          let copyUserSpredDetails = [...userSpread];
          if (type === "bidSpread") {
            copyUserSpredDetails[0] = {
              ...copyUserSpredDetails[0],
              isUserChangeBid: userSpread[0].isUserChangeBid,
              isUserChangeOffer: userSpread[0].isUserChangeOffer,
              isUserChangeSpreadBid: true,
              isUserChangeSpreadOffer: userSpread[0].isUserChangeSpreadOffer,
            };
          } else if (type === "offerSpread") {
          
            copyUserSpredDetails[0] = {
              ...copyUserSpredDetails[0],
              isUserChangeOffer: userSpread[0].isUserChangeOffer,
              isUserChangeBid: userSpread[0].isUserChangeBid,
              isUserChangeSpreadBid: userSpread[0].isUserChangeSpreadBid,
              isUserChangeSpreadOffer: true,
            };
          }
          if (type === "bidPnl") {
            copyUserSpredDetails[0] = {
              ...copyUserSpredDetails[0],
              isUserChangeBid: true,
              isUserChangeOffer: userSpread[0].isUserChangeOffer,
            };
          } else if (type === "offerPnl") {
            copyUserSpredDetails[0] = {
              ...copyUserSpredDetails[0],
              isUserChangeOffer: true,
              isUserChangeBid: userSpread[0].isUserChangeBid,
            };
          }

          apiUpdateUserSpread(copyUserSpredDetails);
          dispatch(
            rfqAggregatorSlice.setUsersSpreadhandler(copyUserSpredDetails)
          );
          if (IsUserPrice) {
            copyDealStatsDat.is_users_price = true;
          } else {
            copyDealStatsDat.is_users_price = false;
          }
          let response = await axios.post(
            `${process.env.REACT_APP_PYTON}` + `get_deal_stats`,
            {
              quote_type: rfqQuoteParameters.quote_type,
              rfq_table_data: jtableDict,
              quote_parameters: rfqQuoteParameters,
              deal_stats: copyDealStatsDat,
              order_book: tableData,
              type: "deal_stats modal 2",
            }
          );
         if(rfqQuoteParameters.quote_type === "SWAP"){
          let obj = {...response.data};
         
          obj = {
            ...obj,
            market_bid: response.data.market_bid
              .toString()
              .replace(",000,",","),
            market_offer: response.data.market_offer
              .toString()
              .replace(",000,",","),
            makor_bid: response.data.makor_bid
              .toString()
              .replace(",000,",","),
            makor_offer: response.data.makor_offer
              .toString()
              .replace(",000,",","),
          };

          
          setrfq_object(rfq_object);
          dispatch(rfqSlice.updateDealStatsData(obj));
         }else {
          setrfq_object(rfq_object);
          dispatch(rfqSlice.updateDealStatsData(response.data));
         }
          
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const spread = () => {
    if (
      dealStatsData.makor_bid !== "---" &&
      dealStatsData.makor_offer !== "---"
    ) {
      // if(rfqQuoteParameters.quote_type === "SWAP"){
        return (parse_float_custom( dealStatsData.makor_offer) - parse_float_custom(dealStatsData.makor_bid)).toFixed(2)
     
      // let regex = /[,\s]/g;
      // let makorOffer = dealStatsData.makor_offer.toString().replace(regex, '') 
      // let makorBid = dealStatsData.makor_bid.toString().replace(regex, '')
      // return +makorOffer - +makorBid
      // }else {
        // return (dealStatsData.makor_offer - dealStatsData.makor_bid).toFixed(2);
      // }
    } else {
      return "---";
    }
  };
  const copy_to_clip = async () => {
    setDisableButton(true);
    await navigator.clipboard.writeText(dealStatsData.client_price_string);
  };

  const handleQuoteParms = () => {
    if (
      rfqQuoteParameters?.quote_type === "DOUBLE_LEG_VOL" ||
      rfqQuoteParameters?.quote_type === "SINGLE_LEG_VOL" ||
      rfqQuoteParameters?.quote_type === "STRANGLE_STRADDLE"
    ) {
      return <DealStatsVol dealStatsData={dealStatsData} />;
    } else if (rfqQuoteParameters?.quote_type === "SWAP") {
      return <DealStatsSwap dealStatsData={dealStatsData} />;
    } else {
      return <DealStatsPrice dealStatsData={dealStatsData} />;
    }
  };
  return (
    <Grid
      container
      className={
        dealStatsData.fees_pct_notional === "---"
          ? classes.rfqEmptyModal
          : classes.rfqModal
      }
    >
      <Grid item container xs={12} className={classes.rfqodalHeader}>
        <Grid item xs={6}>
          <Typography className={classes.header}>{header}</Typography>
        </Grid>
        <Grid item style={{ display: "flex" }}>
          {window.location.pathname.includes("rfqworkspace") ? (
            <Grid>
              <Typography className={classes.filterIcon}>
                <Filter
                  style={{ marginRight: "2vh" }}
                  onClick={(e) => handleClick(e)}
                />
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {preset_spreads().length > 0 ? (
                    preset_spreads().map((key, i) => {
                      return (
                        <MenuItem
                          key={i}
                          style={{}}
                          className={classes.root}
                          onClick={(e) => apply_preset_spread(e, key)}
                        >
                          {key}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem
                      style={{ color: "white" }}
                      className={classes.root}
                      onClick={handleClose}
                    ></MenuItem>
                  )}
                </Menu>
              </Typography>
            </Grid>
          ) : (
            <Grid></Grid>
          )}
          <Grid>
            <Typography className={classes.copyIcon}>
              <Copy
                onClick={copy_to_clip}
                className={clsx({
                  [classes.copybuttonDisable]: disableButton,
                })}
              />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        style={{
          backgroundColor: "#222222",
          paddingTop: "5%",
          minHeight: column ? "100%" : "255px",
        }}
        justifyContent="center"
      >
        <Grid item container xl={9} lg={12}>
          <Grid
            item
            container
            direction="row"
            lg={3}
            xl={3}
            justifyContent="space-between"
          >
            <Grid item container direction="column" lg={12}>
              <Grid item>
                <Typography className={classes.bid}>BID PNL:</Typography>
              </Grid>
              <Grid item>
                <Typography
                  style={{
                    color: "white",
                    font: "normal normal normal 14px/20px monospace",
                    margin: "1px 2px",
                    textAlign: "center",
                    opacity: 0.87,
                  }}
                >
                  {dealStatsData.net_bid_pnl}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={6}>
            <Grid
              item
              container
              xs={6}
              justifyContent={column ? "center" : "flex-start"}
            >
              <StyleTextField
                className={
                  userSpread.length > 0 &&
                  (userSpread[0].isUserChangeBid === "1" ||
                    userSpread[0].isUserChangeBid === true)
                    ? classes.userInputChanges
                    : classes.input
                }
                value={
                  dealStatsData.makor_bid !== "---"
                    ? dealStatsData.makor_bid
                    : ""
                }
                onClick={(e) => e.target.select()}
                onChange={(e) => calculate_pnl(e)}
                name="makor_bid"
                disabled={
                  window.location.pathname.includes("rfqworkspace")
                    ? false
                    : true
                }
                onKeyPress={(e) => api_get_deal_stats(e, true, true, "bidPnl")}
                inputProps={{
                  style: {
                    font: "normal normal normal 14px/20px monospace",
                    margin: "1px 2px",
                    opacity: 0.87,
                    textAlign: "center",
                  },
                }}
              />
            </Grid>
            <Grid
              item
              container
              justifyContent={column ? "center" : "flex-end"}
              xs={6}
            >
              <StyleTextField
                className={
                  userSpread.length > 0 &&
                  (userSpread[0].isUserChangeOffer === "1" ||
                    userSpread[0].isUserChangeOffer === true)
                    ? classes.userInputChanges
                    : classes.input
                }
                value={
                  dealStatsData.makor_offer !== "---"
                    ? dealStatsData.makor_offer
                    : ""
                }
                disabled={
                  window.location.pathname.includes("rfqworkspace")
                    ? false
                    : true
                }
                onClick={(e) => e.target.select()}
                onChange={(e) => calculate_pnl(e)}
                name="makor_offer"
                onKeyPress={(e) =>
                  api_get_deal_stats(e, true, true, "offerPnl")
                }
                inputProps={{
                  style: {
                    font: "normal normal normal 14px/20px monospace",
                    textAlign: "center",
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={3}
            lg={3}
            style={{ jusitifyContent: "space-between" }}
          >
            <Grid item container direction="column" lg={12}>
              <Grid item>
                <Typography className={classes.offer}>OFFER PNL:</Typography>
              </Grid>
              <Grid item>
                <Typography
                  style={{
                    color: "white",
                    font: "normal normal normal 14px/20px monospace",
                    textAlign: "center",
                    opacity: 0.87,
                  }}
                >
                  {dealStatsData.net_offer_pnl}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xl={9} lg={12} style={{ marginTop: "10px" }}>
          <Grid
            item
            container
            direction="row"
            lg={3}
            xl={3}
            justifyContent="space-between"
          >
            <Grid item container direction="column" lg={12}>
              <Grid item>
                <Typography className={classes.bid}>BID SPREAD:</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={6}>
            <Grid
              item
              container
              xs={6}
              justifyContent={column ? "center" : "flex-start"}
            >
              <StyleTextField
                className={
                  userSpread.length > 0 &&
                  (userSpread[0].isUserChangeSpreadBid === "1" ||
                    userSpread[0].isUserChangeSpreadBid === true)
                    ? classes.userSpreadInputChanges
                    : classes.input
                }
                value={
                  userSpread.length > 0 && 
                  userSpread[0].userSpreadBid !== "NaN"
                    ? userSpread[0].userSpreadBid
                    : ""
                }
                disabled={
                  window.location.pathname.includes("rfqworkspace")
                    ? false
                    : true
                }
                onClick={(e) => e.target.select()}
                onChange={(e) => applyUserSpreadBid(e)}
                name="userSpreadBid"
                onKeyPress={(e) =>
                  api_get_deal_stats(e, true, false, "bidSpread")
                }
                inputProps={{
                  style: {
                    font: "normal normal normal 14px/20px monospace",
                    margin: "1px 2px",
                    opacity: 0.87,
                    textAlign: "center",
                  },
                }}
              />
            </Grid>
            <Grid
              item
              container
              justifyContent={column ? "center" : "flex-end"}
              xs={6}
            >
              <StyleTextField
                className={
                  userSpread.length > 0 &&
                  (userSpread[0].isUserChangeSpreadOffer === "1" ||
                    userSpread[0].isUserChangeSpreadOffer === true)
                    ? classes.userSpreadInputChanges
                    : classes.input
                }
                value={
                  userSpread.length > 0 &&
                  userSpread[0].userSpreadOffer !== "NaN"
                    ? userSpread[0].userSpreadOffer
                    : ""
                }
                disabled={
                  window.location.pathname.includes("rfqworkspace")
                    ? false
                    : true
                }
                onClick={(e) => e.target.select()}
                onChange={(e) => applyUserSpreadOffer(e)}
                name="userSpreadOffer"
                onKeyPress={(e) =>
                  api_get_deal_stats(e, true, false, "offerSpread")
                }
                inputProps={{
                  style: {
                    font: "normal normal normal 14px/20px monospace",
                    textAlign: "center",
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={3}
            lg={3}
            style={{ jusitifyContent: "space-between" }}
          >
            <Grid item container direction="column" lg={12}>
              <Grid item>
                <Typography className={classes.offer}>OFFER SPREAD:</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid
            item
            container
            justifyContent="center"
            align="center"
            style={{ paddingTop: "10px" }}
          >
            <Grid item xs={12}>
              <Typography className={classes.dealStateInfoData}>
                {spread()}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={classes.dealStateInfoData}
                style={{ color: "#ffff" }}
              >
                {dealStatsData.client_price_string}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          style={{ paddingBottom: "10px" }}
        >
          {dealStatsData.fees_pct_notional !== "---" ? (
            handleQuoteParms()
          ) : (
            <Grid item>
              <Typography
                style={{
                  color: "white",
                  font: "normal normal normal 14px/20px monospace",
                  opacity: 0.87,
                }}
              >
                NO ACTIVE DEAL
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RfqModalDealStats;
export const useStyles = makeStyles(() => ({
  rfqModal: {
    border: "1px solid #686B76",
    borderRadius: "8px 8px 0px 0px",
    // display: "flex",
    flexDirection: "column",
  },
  rfqEmptyModal: {
    border: "1px solid #686B76",
    borderRadius: "8px 8px 0px 0px",
    flexDirection: "column",
  },
  rfqodalHeader: {
    color: "#FFFFFF",
    borderBottom: "1px solid #686B76",
    borderRadius: "8px 8px 0px 0px",
    font: "normal normal normal 20px/24px Inter",
    height: "43px",
    justifyContent: "space-between",
  },
  header: {
    marginTop: "15px",
    marginLeft: "15px",
  },
  copyIcon: {
    marginTop: "15px",
    marginRight: "10px",
  },
  filterIcon: {
    marginTop: "15px",
  },
  input: {
    border: "1px solid #686B76",
    borderRadius: "8px",
    opacity: 1,
    width: "89px !important",
    height: "40px",
    color: "white",
    font: "normal normal normal 14px/20px monospace",
  },
  userInputChanges: {
    font: "normal normal normal 14px/20px monospace",
    border: "1px solid #686B76",
    borderBottom: 0,
    borderRadius: "8px",
    width: "89px !important",
    // height: "40px",
    backgroundColor: "#CEA1A7",
  },
  userSpreadInputChanges: {
    font: "normal normal normal 14px/20px monospace",
    border: "1px solid #686B76",
    borderRadius: "8px",
    width: "89px !important",
    height: "40px",
    backgroundColor: "#70CEC5",
  },
  bid: {
    textAlign: "center",
    fontSize: "1rem",
    marginTop: "2%",
    color: "#86FFF4",
    font: "normal normal normal 14px/20px monospace",
  },
  offer: {
    textAlign: "center",
    fontSize: "1rem",
    marginTop: "2%",
    color: " #FFC5CD",
    font: "normal normal normal 14px/20px monospace",
  },
  text: {
    marginTop: "revert",
    textAlign: "center",
    color: "#686B76",
  },
  dealStateInfo: {
    fontSize: "1.05rem",
    font: "normal normal normal 14px/20px monospace",
    color: "#989EA8",
  },
  dealStateInfoData: {
    fontSize: "1.05rem",
    font: "normal normal normal 14px/20px monospace",
    color: "#ffffff",
  },
  copybuttonDisable: {
    filter: "invert(0.5) sepia(1) hue-rotate(200deg)",
  },
}));

const StyleTextField = withStyles(() => ({
  root: {
    "& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl":
      {
        paddingLeft: "0px",
      },
  },
}))(TextField);
