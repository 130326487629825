import axios from "axios";
import { END_POINT } from "../../../../utils";
import workerInstances from "../../../../services/index";
import { testDateWithinRange } from "./utilsFunctions";
import { ws, connectWS, sendEvent } from "../../../../services/websocket";

export const getOrdersList = async (
  setStrategiesList,
  setOrdersList,
  setIsOrderListLoading,
  pageNum,
  setEntitlement = null,
  setTeam = null,
  setBbgUpdate,
) => {
  const token = sessionStorage.getItem("token");
  const res = await axios.get(
    `${process.env.REACT_APP_BASE_URL}${END_POINT.GET_ORDERS_LIST}/`,
    { headers: { Authorization: token } },
  );
  if (setEntitlement !== null) {
    setEntitlement(res.data.entitlement);
  }
  if (setTeam !== null) {
    res.data.team.length > 1
      ? setTeam("Admin")
      : res.data.team[0] === "US"
      ? setTeam("Americas")
      : res.data.team[0] === "US2"
      ? setTeam("Americas-2")
      : setTeam(res.data.team[0]);
  }
  setStrategiesList(res.data.strategiesListWithLegs);
  setOrdersList(res.data.ordersListWithLegs);
  setIsOrderListLoading(false);
  subscribeOrdersToSocket(
    res.data.ordersListWithLegs.filter(
      (order, index) =>
        (pageNum === 1 && index < 10) ||
        (pageNum > 10 && (pageNum - 1) * 10 <= index && index < pageNum * 10),
    ),
  );
  const timestamp = new Date().getTime();
  if (res.data.ordersListWithLegs.length > 0) {
    const ordersList = await getBbgData(res.data.ordersListWithLegs, pageNum);
    setBbgUpdate({
      timestamp,
      payload: ordersList,
    });
    // setOrdersList(ordersList);
  } else {
    setIsOrderListLoading(false);
  }
};

export const subscribeOrdersToSocket = async (ordersList) => {
  try {
    const messageArray = [];
    ordersList.forEach((order, orderIndex) => {
      if (order.legs[0].futureTickerByLeg === null) {
        messageArray.push({
          action: "new",
          order_id: order.id,
          security: `${
            order.futureTicker === null ? order.symbol : order.futureTicker
          } ${order.equityIndex}`,
          attributes: ["LAST_PRICE"],
        });
      }
      order.legs.forEach(async (leg, legIndex) => {
        const attributes = [
          "BID_SIZE",
          "ASK_SIZE",
          "VEGA_MID_RT",
          "GAMMA_MID_RT",
          "RT_OPEN_INTEREST",
          "VOLUME",
          "BID",
          "ASK",
        ];
        const { DELTA_MID_RT, ...rest } = leg;

        let obj = {
          action: "new",
          order_id: order.id,
          leg_id: legIndex,
          ...rest,
          security: `${leg.optionTicker}`,
          attributes,
        };
        if (leg.DELTA_MID_RT === null) {
          attributes.unshift("DELTA_MID_RT");
        } else {
          obj = { ...obj, DELTA_MID_RT: leg.DELTA_MID_RT };
        }

        messageArray.push(obj);
        if (leg.futureTickerByLeg !== null) {
          messageArray.push({
            action: "new",
            order_id: order.id,
            leg_id: legIndex,
            security: `${leg.futureTickerByLeg} ${order.equityIndex}`,
            attributes: ["LAST_PRICE"],
          });
        }
      });
    });
    workerInstances.WebSocketPricesInstance.sendEvent(messageArray);
  } catch (err) {
    console.log(err);
  }
};

export const unsubscribeFromSocket = (order) => {
  const messageArray = [];

  messageArray.push({
    action: "remove",
    order_id: order.id,
  });

  workerInstances.WebSocketPricesInstance.sendEvent(messageArray);
  return;
};
export const unsubscribeAllFromSocket = () => {
  const messageArray = [];

  messageArray.push({
    action: "remove_all",
  });

  workerInstances.WebSocketPricesInstance.sendEvent(messageArray);
  return;
};

export const unsubscribeOnSearch = (ordersList) => {
  ordersList.forEach((order) => {
    const messageArray = [];
    messageArray.push({
      action: "remove",
      order_id: order.id,
    });
    workerInstances.WebSocketPricesInstance.sendEvent(messageArray);
    return;
  });
};

export const removeOrderFromCustomList = async (id) => {
  try {
    const token = sessionStorage.getItem("token");
    sendEvent({
      type: "derivs_pricer_custom_list",
      data: { action: "ignore", id },
      metaData: {},
      token: token.replace("Bearer ", ""),
    });
  } catch (err) {
    console.log(err);
  }
};
export const addOrderToCustomList = async (id) => {
  try {
    const token = sessionStorage.getItem("token");
    sendEvent({
      type: "derivs_pricer_custom_list",
      data: { action: "add", id },
      metaData: {},
      token: token.replace("Bearer ", ""),
    });
  } catch (err) {
    console.log(err);
  }
};

export const getBbgData = async (ordersListWithLegs, pageNum) => {
  try {
    const ordersList = await Promise.all(
      ordersListWithLegs.map(async (order, index) => {
        if (
          (pageNum === 1 && index < 10) ||
          (pageNum > 10 && (pageNum - 1) * 10 <= index && index < pageNum * 10)
        ) {
          const { symbol, equityIndex, futureTicker } = order;
          const tickerString = (
            futureTicker !== null
              ? futureTicker + " " + equityIndex
              : symbol.replaceAll("/", "%252f") + " " + equityIndex
          )
            .replaceAll("/", "|")
            .replaceAll(" ", "%20");
          const res = await axios.get(
            `${
              process.env.REACT_APP_BLOOMBERG_DERIVS
            }equity/${tickerString.toUpperCase()}`,
          );
          const {
            div_date,
            name,
            price,
            report_date,
            sector,
            ticker,
            has_adr,
            country,
            multiplier,
            security_type,
            isin,
            primary_exchange,
          } = res.data;
          const dividend = await testDateWithinRange(div_date);
          const earnings = await testDateWithinRange(report_date);
          // subscribeOrdersToSocket([order]);
          return {
            ...order,
            dividend,
            name,
            lastPrice: price,
            earnings,
            sector,
            ticker,
            has_adr,
            country,
            multiplier,
            securityType: security_type,
            isin,
            primaryExchange: primary_exchange,
          };
        } else {
          return {
            ...order,
          };
        }
      }),
    );
    return ordersList;
  } catch (error) {
    console.log(error);
  }
};

export const startBookingProcess = async (id) => {
  try {
    const token = sessionStorage.getItem("token");
    sendEvent({
      type: "derivs_pricer_start_booking_process",
      data: { id },
      metaData: {},
      token: token.replace("Bearer ", ""),
    });
  } catch (err) {
    console.log(err);
  }
};
