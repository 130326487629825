import { TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useStyles } from "../../../Styles/fiStyles";
import { useDispatch, useSelector } from "react-redux";
import * as fiActions from "../../../store/fi/fiSlice";

export const NumberComponent = ({ label, index, arrValue }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [value, setValue] = useState(null);
  const [resetKey, setResetKey] = useState(0);
  const tradersSplitPnlArr = useSelector(
    (state) => state.fiSlice.tradersSplitPnlArr,
  );
  const handleChange = (e) => {
    setValue(e.target.value);
    dispatch(
      fiActions.handleSplitTradersPnl({
        index,
        newValue: e.target.value !== "" ? e.target.value : null,
        key: "percentageSplit",
      }),
    );
  };

  useEffect(() => {
    setValue("");
    setResetKey((prevKey) => prevKey + 1);
  }, [arrValue]);

  useEffect(() => {
    if (tradersSplitPnlArr.length > 0) {
      const splitIndex = tradersSplitPnlArr.findIndex(
        (split) => Number(split.id) === Number(index),
      );
      if (splitIndex !== -1) {
        setValue(tradersSplitPnlArr[splitIndex].percentageSplit);
      }
    }
  }, []);
  return (
    <TextField
      variant="outlined"
      className={classes.pnlAttPercentage}
      type="number"
      label={label}
      value={value}
      onChange={handleChange}
      key={resetKey}
    />
  );
};
