import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import { ReactComponent as Makor } from "../../../assets/makor.svg";
import { ReactComponent as Profile } from "../../../assets/profile.svg";
import { ReactComponent as Database } from "../../../assets/database.svg";
import { ReactComponent as Calculator } from "../../../assets/calculator.svg";
import { ReactComponent as Settings } from "../../../assets/settings.svg";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";

import { ReactComponent as FI_ACTIVE } from "../../../assets/FI_active.svg";
import { ReactComponent as FI_INACTIVE } from "../../../assets/FI_inActive.svg";
import { ReactComponent as FX_ACTIVE } from "../../../assets/FX_active.svg";
import { ReactComponent as FX_INACTIVE } from "../../../assets/FX_inActive.svg";
import { ReactComponent as Rate_INACTIVE } from "../../../assets/ratesUnActive.svg";
import { ReactComponent as Rate_ACTIVE } from "../../../assets/ratesActive.svg";
import { ReactComponent as DERIVS_PRICER } from "../../../assets/derivs_pricer.svg";
import { ReactComponent as DERIVS_PRICER_INACTIVE } from "../../../assets/derivs_pricer_inactive.svg";
import { ReactComponent as DERIVS_CLIENTS_ACTIVE } from "../../../assets/derivsClientsActive.svg";
import { ReactComponent as DERIVS_CLIENTS_INACTIVE } from "../../../assets/derivsClientsInactive.svg";
import { ReactComponent as BBG_Download } from "../../../assets/bbg_download.svg";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { ReactComponent as FI } from "../../../assets/FI_icon.svg";
import MenuItem from "@material-ui/core/MenuItem";
import { useSelector, useDispatch } from "react-redux";
import * as userSlice from "../../../store/users/usersSlice";
import * as rfqSlice from "../../../store/rfq/rfqSlice";
import * as fiActions from "../../../store/fi/fiSlice";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import "./SideBar.css";
import clsx from "clsx";
import Tooltip from "@material-ui/core/Tooltip";
import {
  AppBar,
  ClickAwayListener,
  IconButton,
  Button,
  MenuList,
  Paper,
  Popper,
} from "@material-ui/core";
import { END_POINT } from "../../../utils";
import axios from "axios";
import { ws, connectWS, sendEvent, closeWS } from "../../../services/websocket";
import * as rfqActions from "../../../store/rfq/rfqSlice";

export default function SideBar() {
  const token = sessionStorage.getItem("token");
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [entitlement, setEntitlement] = useState([]);

  useEffect(() => {
    const auth = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}` + END_POINT.AUTH,
        { headers: { Authorization: token } },
      );
      setEntitlement(res.data.split(";"));
    };
    auth();
  }, []);

  const handelPopperClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const dispatch = useDispatch();

  const notificationNumber = useSelector(
    (state) => state.rfqSlice.notification,
  );
  let navigate = useNavigate();
  const { pathname } = useLocation();

  const handleLoginClicked = (e) => {
    const token = sessionStorage.getItem("token");
    if (token) {
      dispatch(userSlice.clearLoginState());
      dispatch(rfqSlice.clearRfqId());
      dispatch(rfqSlice.clearrfqInstanceList());
      dispatch(rfqSlice.clearCreatedRfqDetails());
      dispatch(rfqSlice.clearrfqButtonStatus());
      dispatch(rfqSlice.clearRfq());
      dispatch(rfqSlice.restartNotificationAcount());
      dispatch(rfqSlice.updatRfqInstanceList([]));
      closeWS(dispatch, rfqSlice);

      navigate("/login");
    } else {
      navigate("/login");
    }
    dispatch(fiActions.setFiTrades([]));
  };

  const downloadBBGConnector = async () => {
    const res = await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}` + END_POINT.BBG_CONNECTOR_DOWNLOAD,
        { responseType: "blob", headers: { Authorization: token } },
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "LeToolBloombergConnectorInstaller.exe");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error("Error while downloading file:", error);
      });
  };
  useEffect(() => {}, [notificationNumber]);
  return (
    <AppBar className={classes.appBar}>
      <Toolbar className="tool-bar-container" style={{ height: "100vh" }}>
        <div>
          <Makor className={classes.makor} />
        </div>
        {/* {entitlement.map((ent) => {
          if(ent===)
        })} */}
        {entitlement.map((entl, index) => {
          if (entl === "fi-blotter") {
            return (
              <div key={index}>
                <Tooltip
                  title="FI Blotter"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Link to="fiblotter">
                    <FI
                      className={classes.database}
                      style={{ margin: "40px 0px 0px 3px" }}
                      fill={clsx({
                        ["#ffffff"]: pathname.includes("/fiblotter"),
                        ["#828282"]: !pathname.includes("/fiblotter"),
                      })}
                    />
                  </Link>
                </Tooltip>
              </div>
            );
          } else if (entl === "fx-rfq-blotter") {
            return (
              <div key={index}>
                <Tooltip
                  title="RFQ Blotter"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Link
                    to="rfqblotter"
                    onClick={() => {
                      dispatch(rfqActions.setIsNavigateToRfqBlotter(true));
                    }}
                  >
                    <Database
                      className={classes.database}
                      fill={clsx({
                        ["#fff"]: pathname.includes("/rfqblotter"),
                        ["#828282"]: !pathname.includes("/rfqblotter"),
                      })}
                    />{" "}
                  </Link>
                </Tooltip>
                {notificationNumber.length > 0 ? (
                  <div className="notification-badge">
                    {notificationNumber.length}
                  </div>
                ) : null}
              </div>
            );
          } else if (entl === "fx-rfq-workspace") {
            return (
              <div key={index}>
                <Tooltip
                  title="RFQ Workspace"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Link to="rfqworkspace">
                    <Calculator
                      className={classes.calculator}
                      fill={clsx({
                        ["#fff"]: pathname.includes("/rfqworkspace"),
                        ["#828282"]: !pathname.includes("/rfqworkspace"),
                      })}
                    />
                  </Link>
                </Tooltip>
              </div>
            );
          } else if (entl === "fi-client-static") {
            return (
              <div key={index}>
                <Tooltip
                  title="FI Client Static Blotter"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Link to="clientblotter">
                    {pathname.includes("/clientblotter") ? (
                      <FI_ACTIVE
                        style={{ marginTop: "40px", marginLeft: "10px" }}
                      />
                    ) : (
                      <FI_INACTIVE
                        style={{ marginTop: "40px", marginLeft: "10px" }}
                      />
                    )}
                  </Link>
                </Tooltip>
              </div>
            );
          } else if (entl === "mgmt") {
            return (
              <div key={index}>
                <Tooltip
                  title="FX Data Mgmt"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Link to="datamgmt">
                    {pathname.includes("/datamgmt") ? (
                      <FX_ACTIVE
                        style={{ marginTop: "40px", marginLeft: "10px" }}
                      />
                    ) : (
                      <FX_INACTIVE
                        style={{ marginTop: "40px", marginLeft: "10px" }}
                      />
                    )}
                  </Link>
                </Tooltip>
              </div>
            );
          } else if (entl === "rates") {
            return (
              <div key={index}>
                <Tooltip
                  title="Rates Blotter"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Link to="rates">
                    {pathname.includes("/rates") ? (
                      <Rate_ACTIVE
                        style={{
                          width: "24px",
                          height: "24px",
                          marginTop: "40px",
                          marginLeft: "5px",
                        }}
                      />
                    ) : (
                      <Rate_INACTIVE
                        style={{
                          width: "24px",
                          height: "24px",
                          marginTop: "40px",
                          marginLeft: "5px",
                          color: "#828282",
                        }}
                      />
                    )}
                  </Link>
                </Tooltip>
              </div>
            );
          } else if (entl === "pricer") {
            return (
              <div key={index}>
                <Tooltip
                  title="Derivs Pricer"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Link to="pricer">
                    {pathname.includes("/pricer") ? (
                      <DERIVS_PRICER
                        className={classes.database}
                        style={{ margin: "40px 0px 0px 3px" }}
                      />
                    ) : (
                      <DERIVS_PRICER_INACTIVE
                        className={classes.database}
                        style={{
                          margin: "40px 0px 0px 3px",
                        }}
                      />
                    )}
                  </Link>
                </Tooltip>
              </div>
            );
          } else if (entl === "pricer-client-static") {
            return (
              <div key={index}>
                <Tooltip
                  title="Derivs Client Static Main"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Link to="derivs_client_static">
                    {pathname.includes("/derivs_client_static") ? (
                      <DERIVS_CLIENTS_ACTIVE
                        className={classes.database}
                        style={{ margin: "40px 0px 0px 3px" }}
                      />
                    ) : (
                      <DERIVS_CLIENTS_INACTIVE
                        className={classes.database}
                        style={{
                          margin: "40px 0px 0px 3px",
                        }}
                      />
                    )}
                  </Link>
                </Tooltip>
              </div>
            );
          } else if (entl === "equities-spread") {
            return (
              <div key={index}>
                <Tooltip
                  title="Equities Spread Monitor"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Link to="equities_spread">
                    {pathname.includes("/equities_spread") ? (
                      <DERIVS_PRICER
                        className={classes.database}
                        style={{ margin: "40px 0px 0px 3px" }}
                      />
                    ) : (
                      <DERIVS_PRICER_INACTIVE
                        className={classes.database}
                        style={{
                          margin: "40px 0px 0px 3px",
                        }}
                      />
                    )}
                  </Link>
                </Tooltip>
              </div>
            );
          } else if (entl === "users-management") {
            return (
              <div key={index}>
                <Tooltip
                  title="Users Management"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Link to="users_management">
                    {pathname.includes("/users_management") ? (
                      <PersonAddIcon
                        className={classes.database}
                        style={{
                          margin: "40px 0px 0px 3px",
                          fontSize: "32px", // Adjust the size as needed
                          color: "white", // Change the color to your desired color
                        }}
                      />
                    ) : (
                      <PersonAddIcon
                        className={classes.database}
                        style={{
                          margin: "40px 0px 0px 3px",
                          fontSize: "32px", // Adjust the size as needed
                          color: "#828282", // Change the color to your desired color
                        }}
                      />
                    )}
                  </Link>
                </Tooltip>
              </div>
            );
          }
        })}

        <div className="login">
          <div style={{ marginBottom: "20px" }}>
            <IconButton size="large" onClick={downloadBBGConnector}>
              <BBG_Download />
            </IconButton>
          </div>
          <Tooltip
            title={
              sessionStorage.getItem("userName") !== null &&
              sessionStorage.getItem("userName") !== undefined
                ? sessionStorage.getItem("userName")
                : null
            }
            classes={{ tooltip: classes.tooltip }}
          >
            <IconButton onClick={(e) => handelPopperClick(e)} size="large">
              <Profile aria-controls="profile-menu" />
            </IconButton>
          </Tooltip>

          <Popper
            open={open}
            anchorEl={anchorEl}
            placement="bottom"
            transition
            disablePortal
            modifiers={{
              offset: {
                enabled: true,
                offset: "-150, 10",
              },
            }}
          >
            <Paper style={{ backgroundColor: "#bababa" }}>
              <ClickAwayListener onClickAway={(e) => handelPopperClick(e)}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                >
                  <MenuItem onClick={(e) => handleLoginClicked(e)}>
                    <LogoutIcon
                      sx={{ fontSize: 0, color: "#000000" }}
                      style={{ paddingRight: "5px" }}
                    />
                    <p className="profil">log out</p>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Popper>
        </div>
      </Toolbar>
    </AppBar>
  );
}

const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: 16,
  },
  makor: {
    marginTop: "15px",
  },
  calculator: {
    marginTop: "40px",
  },
  client: {
    marginTop: "40px",
  },
  database: {
    marginTop: "35px",
    // background: "#3A3C3F 0% 0% no-repeat padding-box",
    opacity: 1,
  },
  selected: {
    color: "white",
  },
  appBar: {
    "&.MuiAppBar-colorPrimary": {
      backgroundColor: "transparent",
      width: "fit-content",
      left: "0px",
    },
  },
}));
