import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  componentContainer: {
    borderTop: "1px solid rgba(160, 164, 176, 0.3)",
    borderBottom: "1px solid rgba(160, 164, 176, 0.3)",
    height: "80px",
    marginTop: "20px",
  },
  attachmentContainerCsv: {
    minWidth: "150px",
    width: "auto",
    paddingRight: "15px",
    height: "50px",
    border: "1px solid #2ca050",
    marginLeft: "15px",
    borderRadius: "8px",
    transition: "background-color 0.3s ease",

    "&:hover": {
      backgroundColor: "#cdf7e1",
      cursor: "pointer",
      "& $attachmentTypography": {
        color: "#A0A4B0",
      },
    },
  },
  attachmentContainerPdf: {
    minWidth: "150px",
    width: "auto",
    paddingRight: "15px",
    height: "50px",
    border: "1px solid #e13d58",
    marginLeft: "15px",
    borderRadius: "8px",
    transition: "background-color 0.3s ease",

    "&:hover": {
      backgroundColor: "#deb0b7",
      cursor: "pointer",
    },
  },
  csvIcon: {
    fill: "#2ca050",
    width: "25px",
    height: "25px",
  },
  pdfIcon: {
    fill: "#e13d58",
    width: "25px",
    height: "25px",
  },
  attachmentTypography: {
    fontSize: "1.5rem",
    color: "white",
  },

  circularProgress: {
    width: "30px !important", // Set the width of the spinner
    height: "30px !important",
    marginRight: "10px",
    "& .MuiCircularProgress-circle": {
      color: "#2ca050",
    },
  },
}));
