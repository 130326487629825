export const agFormatter = (params) => {
  // console.log({ params });
  if (params.data?.id === "tradeDate" || params.data?.id === "paymentDate") {
    return formatDate(params.value);
  } else return undefined;
};

export function formatDate(inputDate) {
  // Parse the input date string
  const date = new Date(inputDate);

  // Check for Invalid Date
  if (isNaN(date.getTime())) {
    throw new Error("Invalid date format");
  }

  // Array of month abbreviations
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Extract day, month, and year from the date
  const day = date.getDate().toString().padStart(2, "0");
  const month = months[date.getMonth()];
  const year = date.getFullYear().toString().substring(2);

  // Return the formatted date
  const convertedDate = `${day}-${month}-${year}`;
  return convertedDate;
}
