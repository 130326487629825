import { Button, Grid, Typography } from "@material-ui/core";

import { useStyles, StyledTextField } from "../../../Styles/rfqPostTradeStyles";
import PMSTable from "../PMS/PmsTable/PMSTable";

function PmManagmentView(props) {
  const {
    pmName,
    toEmailAddresses,
    handleEmailAddressAdd,
    handlePmNameChange,
    savePmToDb,
  } = props;
  const classes = useStyles();

  return (
    <Grid container>
      <PMSTable />
    </Grid>
  );
}

export default PmManagmentView;
