import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    sendButton: {
    height: 80,
    width: 68,
    backgroundColor: "#2692ff !important",
    color: "#ffffff !important",
    borderRadius: 4,
  },
 
  
}));
