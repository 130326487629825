import { makeStyles, withStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  snackbarRoot: {
    position: "absolute",
    top: "69px",
    right: "30px",
    width: "auto",
    zIndex: 1400,
    opacity: 0.8,
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      zIndex: 1400,
      width: "90%",
      right: "5%",
    },
  },
  closeSnackbar: {
    height: "20px",
    width: "20px",
  },
  tab: {
    textTransform: "none",
    // minWidth: 72,
    // maxWidth: 142,
    height: 48,
    fontSize: theme?.typography?.sizes?.size4,
    marginRight: theme.spacing(4),
    alignItems: "flex-end",
    paddingLeft: 10,
    borderBottom: "2px solid transparent",
  },
  tabLink: {
    textDecoration: "none",
    height: 35,
    display: "flex",
    alignItems: "center",
    paddingLeft: 5,
    width: 130,
  },
  tabLinkActive: {
    fontWeight: 700,
    borderBottom: "2px solid #0396e6",
  },
  tabLinkPassive: {},
  tabLabel: {
    fontSize: theme?.typography?.sizes?.size4,
    // minWidth: 150
  },
  labelActive: {
    fontWeight: 700,
  },
  tabButtons: {
    padding: 5,
  },
  closeIcon: {
    fontSize: 14,
    color: "#B6B6B6",
    cursor: "pointer",
  },
  draggable: {
    "&:focus": {
      outline: "none",
    },
  },
  addDashboardButton: {
    width: 49,
    height: 24,
    borderRadius: 2,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
  },
}));
