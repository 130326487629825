import { createSlice } from "@reduxjs/toolkit";

export const dataMgmtSlice = createSlice({
    name: "dataMgmtSlice",
    initialState:{
        editMode : "",
        editData: null
    },
    reducers:{
        setEditMode: (state, action)=>{
            state.editMode = action.payload
        },
        setEditData: (state, action)=>{
            state.editData = action.payload
        }
    }

})

export const { setEditData, setEditMode  } = dataMgmtSlice.actions;

export default dataMgmtSlice.reducer;