import {
  Button,
  ButtonGroup,
  DialogContent,
} from "@material-ui/core";
import {
  Dialog,
  Grid,
  Typography,
  IconButton,
  Divider,
  FormControl,
} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import { useStyles } from "../../../Styles/fiStyles";
import { useEffect, useState } from "react";
import clsx from "clsx";
import SelectOption from "../../fi/Select/Select";
import MaterialUIPickers from "../../fi/datePicker/DatePicker";
import FreeTextInput from "../../fi/freeTextInput/FreeTextInput";
import NumberInput from "../../fi/numberInput/NumberInput";
import TextArea from "../../fi/textArea/TextArea";
import TimePickerComponent from "../../fi/timePicker/TimePicker";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import * as fiActions from "../../../store/fi/fiSlice";
import { useNavigate } from "react-router-dom";
import * as actionSnackBar from "../../../store/snackbar/action";
import { END_POINT } from "../../../utils/index";
import { TradeCheckbox } from "../checkbox/TradeCheckbox";

function TradeDialog(props) {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const formData = useSelector((state) => state.fiSlice?.newTradeStats);
  const {
    open,
    type,
    handleClose,
    isTradeDialogLoading,
    setIsTradeDialogLoading,
  } = props;
  const classes = useStyles();
  const [tradeStatus, setTradeStatus] = useState("live");
  const [tradersListObj, setTraderListObj] = useState({});
  const [clientsListObj, setTClientsListObj] = useState({});
  const [currenciesListObj, setTCurrenciesListObj] = useState({});
  const [netCashCurrenciesListObj, setNetCashCurrenciesListObj] = useState({});

  const tradeToEdit = useSelector(
    (state) => state.fiSlice?.selectedTradeToEdit,
  );

  useEffect(() => {
    if (
      tradeToEdit !== undefined &&
      tradeToEdit.status &&
      tradeToEdit.status !== ""
    ) {
      setTradeStatus(
        tradeToEdit.status !== "unresolved" ? tradeToEdit.status : "live",
      );
      let obj = {};
      obj = {
        value:
          tradeToEdit.status !== "unresolved" ? tradeToEdit.status : "live",
        key: "status",
      };
      dispatch(fiActions.editNewTradeForm(obj));
      if (type === "edit") {
        dispatch(
          fiActions.editNewTradeForm({
            value: tradeToEdit.uuid,
            key: "uuid",
          }),
        );
        dispatch(
          fiActions.editNewTradeForm({
            value: sessionStorage.getItem("userId"),
            key: "lastUpdatedBy",
          }),
        );
        dispatch(
          fiActions.editNewTradeForm({
            value: tradeToEdit.unresolvedClient,
            key: "unresolvedClient",
          }),
        );
        dispatch(
          fiActions.editNewTradeForm({
            value: tradeToEdit.unresolvedTrader,
            key: "unresolvedTrader",
          }),
        );
      }
    }
  }, [tradeToEdit]);

  useEffect(() => {
    if (sessionStorage.getItem("userId")) {
      const createdByObj = {
        value: sessionStorage.getItem("userId"),
        key: "createdBy",
      };
      open && dispatch(fiActions.editNewTradeForm(createdByObj));
    } else {
      navigate("/login");
    }
  }, [open]);

  const sideObj = {
    label: "Side",
    values: [
      { id: "buy", value: "Buy" },
      { id: "sell", value: "Sell" },
    ],
    key: "ourSide",
    type,
  };
  const getTraders = async () => {
    const token = sessionStorage.getItem("token");

    const tradersList = [];
    // `${process.env.REACT_APP_BASE_URL}` +
    //     END_POINT.
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}` +
        END_POINT.FI_TRADES +
        "/get_traders",
      {
        headers: { Authorization: token },
      },
    );
    res.data.map((trader) => {
      if (trader.trader_id !== 9) {
        return tradersList.push({
          id: trader.trader_id,
          value: trader.trader_first_name,
        });
      }
    });
    const obj = {
      label: "Trader",
      values: tradersList,
      key: "trader",
      type,
    };
    setTraderListObj(obj);
  };
  const getClients = async () => {
    const token = sessionStorage.getItem("token");
    const clientsList = [];
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}` +
        END_POINT.FI_TRADES +
        "/get_clients",
      {
        headers: { Authorization: token },
      },
    );
    res.data.map((client) => {
      if (client.id !== 858) {
        return clientsList.push({
          id: client.id,
          value: client.value,
          firmName: client.firm_name,
          ggpCodeList: client.currency_gpp_counter_party_code,
        });
      }
    });
    const obj = {
      label: "Client",
      values: clientsList,
      key: "client",
      type,
    };
    setTClientsListObj(obj);
  };
  const getCurrencies = async () => {
    const token = sessionStorage.getItem("token");
    const currenciesList = [];
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}` +
        END_POINT.FI_TRADES +
        "/get_currencies",
      { headers: { Authorization: token } },
    );
    res.data.map((currency) => {
      return currenciesList.push({
        id: currency.id,
        value: currency.name,
      });
    });
    const obj = {
      label: "Currency",
      values: currenciesList,
      key: "currency",
      type,
    };
    const obj2 = {
      label: "Net Cash Currency",
      values: currenciesList,
      key: "netCashCurrency",
      type,
    };
    setTCurrenciesListObj(obj);
    setNetCashCurrenciesListObj(obj2);
  };

  useEffect(() => {
    const getResources = async () => {
      await getTraders();
      await getClients();
      await getCurrencies();
      setIsTradeDialogLoading(false);
    };
    if (open) {
      getResources();
    }
  }, [open]);

  const handleSubmit = async () => {
    const messageHandler = [];
    const formKeys = Object.keys(formData);
    formKeys.map((key) => {
      if (
        key !== "comment" &&
        key !== "lastUpdatedBy" &&
        key !== "lastUpdatedAt" &&
        key !== "uuid" &&
        key !== "unresolvedClient" &&
        key !== "unresolvedTrader" &&
        key !== "uuid" &&
        formData[`${key}`] === ""
      ) {
        if (key === "ourSide") {
          key = "our side";
        } else if (key === "securityName") {
          key = "security name";
        } else if (key === "accruedInterest") {
          key = "accrued interest";
        }
        messageHandler.push(`${key} field is mandatory`);
      }
    });
    if (messageHandler.length !== 0) {
      dispatch(actionSnackBar.setSnackBar("error", messageHandler[0], 3000));
    } else if (messageHandler.length === 0) {
      try {
        const final = { ...formData };
        const token = sessionStorage.getItem("token");
        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}` +
            END_POINT.FI_TRADES +
            "/create_trade",
          final,
          {
            headers: { Authorization: token },
          },
        );
        if (res.status === 200) {
          dispatch(fiActions.resetFormData());
          dispatch(fiActions.resetSelectedTradeToEdit());
          dispatch(fiActions.resetChosenTrades());
          dispatch(fiActions.resetCheckbox());

          setTradeStatus("live");
          handleClose();
        }
      } catch (err) {
        dispatch(
          actionSnackBar.setSnackBar("error", err.response.data.message, 3000),
        );
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        dispatch(fiActions.resetFormData());
        dispatch(fiActions.resetSelectedTradeToEdit());
        setTradeStatus("live");
        handleClose();
      }}
      classes={{ paper: classes.dialogPaper }}
      PaperProps={{
        style: {
          backgroundColor: "#2D2D2D",
        },
      }}
    >
      {!isTradeDialogLoading ? (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ padding: "30px 84px 32px 75px", position: "absolute" }}
        >
          <Grid item xs={11}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={6}>
                <Typography style={{ fontSize: "1.25rem", color: "#ffffff" }}>
                  {type === "new"
                    ? "New Trade"
                    : type === "edit"
                    ? "Edit Trade"
                    : null}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <ButtonGroup className={classes.btnGroup}>
                  <Button
                    className={clsx(classes.btnInside, {
                      [classes.chosenBtnInside]: tradeStatus === "live",
                    })}
                    onClick={() => {
                      setTradeStatus("live");
                      const obj = {
                        value: "live",
                        key: "status",
                      };

                      dispatch(fiActions.editNewTradeForm(obj));
                    }}
                  >
                    Live
                  </Button>
                  <Button
                    className={clsx(classes.btnInside, {
                      [classes.chosenBtnInside]: tradeStatus === "draft",
                    })}
                    onClick={() => {
                      setTradeStatus("draft");
                      const obj = {
                        value: "draft",
                        key: "status",
                      };

                      dispatch(fiActions.editNewTradeForm(obj));
                    }}
                  >
                    Draft
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container justifyContent="space-between" xs={12}>
            <Grid item>
              <SelectOption values={tradersListObj} />
            </Grid>
            <Grid item>
              <SelectOption values={clientsListObj} />
            </Grid>
          </Grid>
          <Grid item container justifyContent="space-between" xs={12}>
            <Grid item>
              <MaterialUIPickers
                values={{ label: "Trade Date", key: "tradeDate" }}
              />
            </Grid>
            <Grid item className={classes.datePickerColor}>
              <MaterialUIPickers
                values={{ label: "Settlement Date", key: "settlementDate" }}
              />
            </Grid>
          </Grid>
          <Grid item container justifyContent="space-between" xs={12}>
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              xs={6}
            >
              <Grid item xs={8}>
                <FreeTextInput values={{ label: "ISIN", key: "isin", type }} />
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={4}
              >
                <TradeCheckbox field={"isStructured"} type={type} />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <FreeTextInput
                values={{ label: "Security Name", key: "securityName", type }}
              />
            </Grid>
          </Grid>
          <Grid item container justifyContent="space-between" xs={12}>
            <Grid item>
              <NumberInput values={{ label: "Quantity", key: "quantity" }} />
            </Grid>
            <Grid item>
              <SelectOption values={sideObj} />
            </Grid>
          </Grid>
          <Grid item container justifyContent="space-between" xs={12}>
            <Grid item>
              <NumberInput values={{ label: "Price", key: "price" }} />
            </Grid>
            <Grid item>
              <NumberInput
                values={{ label: "Accrued Interest", key: "accruedInterest" }}
              />
            </Grid>
          </Grid>

          <Grid item container justifyContent="space-between" xs={12}>
            <Grid item>
              <SelectOption values={currenciesListObj} />
            </Grid>
            <Grid item>
              <TimePickerComponent values={{ label: "Time", key: "time" }} />
            </Grid>
          </Grid>
          <Grid item container justifyContent="space-between" xs={12}>
            <Grid item>
              <SelectOption values={netCashCurrenciesListObj} />
            </Grid>
            <Grid item>
              <NumberInput values={{ label: "Net Cash", key: "netCash" }} />{" "}
            </Grid>
          </Grid>
          <Grid item container justifyContent="space-between" xs={12}>
            <Grid item>
              <TextArea values={{ label: "Comment", key: "comment" }} />
            </Grid>
          </Grid>
          <IconButton
            size="small"
            onClick={() => {
              dispatch(fiActions.resetFormData());
              dispatch(fiActions.resetSelectedTradeToEdit());
              setTradeStatus("live");
              handleClose();
            }}
            style={{
              marginRight: 5,
              position: "relative",
              backgroundColor: "#3a3c3f",
              bottom: "536px",
              left: "530px",
            }}
          >
            <CloseIcon style={{ color: "#bababa", fontSize: "1.3125rem" }} />
          </IconButton>
          <Grid item container justifyContent="space-between" xs={12}>
            <Button
              variant="contained"
              className={classes.btn}
              onClick={() => {
                dispatch(fiActions.resetFormData());
                dispatch(fiActions.resetSelectedTradeToEdit());
                setTradeStatus("live");
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className={classes.btn}
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      ) : null}

      <DialogContent>
        <FormControl></FormControl>
      </DialogContent>
    </Dialog>
  );
}

export default TradeDialog;
