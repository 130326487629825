import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { END_POINT } from "../../utils/index";
const token = sessionStorage.getItem("token");

export let fiSlice = createSlice({
  name: "fiSlice",
  initialState: {
    fiTrades: [],
    chosenTrades: [],
    chosenDeals: [],
    newTradeStats: {
      status: "live",
      trader: "",
      client: "",
      tradeDate: "",
      settlementDate: "",
      isin: "",
      securityName: "",
      quantity: "",
      ourSide: "",
      price: "",
      accruedInterest: "",
      currency: "",
      time: "",
      createdBy: "",
      comment: "",
      uuid: "",
      lastUpdatedBy: "",
      lastUpdatedAt: "",
      netCash: "",
      netCashCurrency: "",
      isStructured: false,
    },
    selectedTradeToEdit: {},
    splitTradeForm: {
      splitNumber: 2,
      splitOption: "notional",
    },
    splitArrValues: [],
    tradersSplitPnlArr: [],
    amountLeftToSplit: "",
  },

  reducers: {
    resetTradersSplitPnlArr: (state, action) => {
      state.tradersSplitPnlArr = [];
    },
    setChosenTrades: (state, action) => {
      state.chosenTrades = action.payload;
    },
    resetChosenTrades: (state) => {
      state.chosenTrades = [];
    },
    resetDealsArr: (state) => {
      state.chosenDeals = [];
    },
    addToDealsArr: (state, action) => {
      const copyDealsArr = [...state.chosenDeals];
      copyDealsArr.push(action.payload);
      state.chosenDeals = copyDealsArr;
    },
    setChosenDeals: (state, action) => {
      state.chosenDeals = action.payload;
    },
    removeFromDealsArr: (state, action) => {
      const copyDealsArr = [...state.chosenDeals];
      copyDealsArr.splice(action.payload, 1);
      state.chosenDeals = copyDealsArr;
    },

    setCheckboxInsideDeal: (state, action) => {
      const { indexToUpdate, trade } = action.payload;
      const tradesCopy = [...state.fiTrades];
      tradesCopy.splice(indexToUpdate, 1, trade);
      state.fiTrades = tradesCopy;
    },

    addNewDeal: (state, action) => {
      state.fiTrades = action.payload;
    },
    setAmountLeftToSplit: (state, action) => {
      state.amountLeftToSplit = action.payload;
    },
    setSplitValues: (state, action) => {
      state.splitArrValues = action.payload;
    },
    resetSplitArrValues: (state) => {
      state.splitArrValues = [];
    },
    setSplitTradeForm: (state, action) => {
      const key = action.payload.key;
      const value = action.payload.value;
      state.splitTradeForm[`${key}`] = value;
      // state.splitTradeForm[`${key}`] = value;
      // if (key !== "clientSplitId" && key !== "splitOption") {
      //   state.splitTradeForm[`${key}`] = value;
      // }
    },

    setSelectedTradeToEdit: (state, action) => {
      state.selectedTradeToEdit = action.payload;
    },
    resetSelectedTradeToEdit: (state) => {
      state.selectedTradeToEdit = {};
    },
    resetSelectedTrades: (state) => {
      state.chosenTrades = [];
    },
    updateTradersSplitPnlArr: (state, action) => {
      state.tradersSplitPnlArr = action.payload;
    },
    resetFormData: (state) => {
      state.newTradeStats = {
        status: "live",
        trader: "",
        client: "",
        tradeDate: "",
        settlementDate: "",
        isin: "",
        securityName: "",
        quantity: "",
        ourSide: "",
        price: "",
        accruedInterest: "",
        currency: "",
        time: "",
        createdBy: "",
        comment: "",
        uuid: "",
        isStructured: false,
      };
    },
    editNewTradeForm: (state, action) => {
      const key = action.payload.key;
      const value = action.payload.value;
      state.newTradeStats[`${key}`] = value;
    },

    setFiTrades: (state, action) => {
      state.fiTrades = action.payload;
    },
  },
});

export const handleIgnoreTrade = () => async (dispatch, getState) => {
  const chosenTrades = getState().fiSlice.chosenTrades;
  let chosenTrade = chosenTrades.length === 1 ? chosenTrades[0] : null;
  try {
    if (chosenTrade !== null) {
      chosenTrade = {
        ...chosenTrade,
        status: "ignore",
        lastUpdatedBy: sessionStorage.getItem("userId"),
      };

      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}` +
          END_POINT.FI_TRADES +
          "/ignore_trade",
        { chosenTrade },
        {
          headers: { Authorization: token },
        },
      );

      dispatch(resetChosenTrades());
      dispatch(resetDealsArr());
    } else {
      console.log("There should be only one trade chosen");
    }
  } catch (error) {
    console.log("handleIgnoreTrade", error);
  }
};

export const handleDealRowSelect = (obj) => (dispatch, getState) => {
  const fiTrades = getState().fiSlice.fiTrades;
  const chosenDeals = getState().fiSlice.chosenDeals;
  const chosenTrades = getState().fiSlice.chosenTrades;
  let fiTradesCopy = [...fiTrades];
  const chosenDealsCopy = [...chosenDeals];
  const chosenTradesCopy = [...chosenTrades];
  const chosenDealIndex = chosenDealsCopy.findIndex(
    (dealId) => dealId === obj[0],
  );
  if (chosenDealIndex !== -1) {
    chosenDealsCopy.splice(chosenDealIndex, 1);
    dispatch(setChosenDeals(chosenDealsCopy));
    fiTradesCopy = fiTradesCopy.map((trade) => {
      const filter = obj[1].filter((t) => t.trade_id === trade.trade_id);
      if (filter.length > 0) {
        return { ...trade, checked: false };
      } else return trade;
    });
    dispatch(setFiTrades(fiTradesCopy));
    obj[1].map((trade) => {
      const index = chosenTradesCopy.findIndex(
        (t) => t.trade_id === trade.trade_id,
      );
      if (index !== -1) {
        chosenTradesCopy.splice(index, 1);
      }
    });
    dispatch(setChosenTrades(chosenTradesCopy));
  } else if (chosenDealIndex === -1) {
    chosenDealsCopy.push(obj[0]);
    dispatch(setChosenDeals(chosenDealsCopy));
    fiTradesCopy = fiTradesCopy.map((trade) => {
      const filter = obj[1].filter((t) => t.trade_id === trade.trade_id);
      if (filter.length > 0) {
        const index = chosenTradesCopy.findIndex(
          (t) => t.trade_id === trade.trade_id,
        );
        if (index === -1) {
          chosenTradesCopy.push({ ...trade, checked: true });
        }

        return { ...trade, checked: true };
      } else return trade;
    });
    dispatch(setFiTrades(fiTradesCopy));
    dispatch(setChosenTrades(chosenTradesCopy));
  }
};

export const resetCheckbox = () => (dispatch, getState) => {
  const fiTrades = getState().fiSlice.fiTrades;
  let copyFiTrades = [...fiTrades];
  copyFiTrades = copyFiTrades.map((trade) => {
    return { ...trade, checked: false };
  });
  dispatch(setFiTrades(copyFiTrades));
};
export const setSplitArrValues =
  (split_Values) => async (dispatch, getState) => {
    const splitArrValues = getState().fiSlice.splitArrValues;
    let copySplitArrValues = [...splitArrValues];
    if (
      copySplitArrValues.length === 0 ||
      copySplitArrValues.filter((arrObj) => arrObj.id === split_Values.id)
        .length === 0
    ) {
      const key = split_Values.key;
      const value = split_Values.value;
      const obj = {
        [`${key}`]: value,
        id: split_Values.id,
      };
      dispatch(setSplitValues([...copySplitArrValues, obj]));
    } else if (
      copySplitArrValues.filter((arrObj) => arrObj.id === split_Values.id)
        .length !== 0
    ) {
      const index = copySplitArrValues.findIndex(
        (arrObj) => arrObj.id === split_Values.id,
      );

      const value = split_Values.value;
      const keyOfState = split_Values.key;
      copySplitArrValues[index] = {
        ...copySplitArrValues[index],
        [`${keyOfState}`]: value,
      };
      dispatch(setSplitValues(copySplitArrValues));
    }
  };

export const removeHighlightHandler =
  (new_trade) => async (dispatch, getState) => {
    const fiTrades = getState().fiSlice.fiTrades;
    const copyFiTrades = [...fiTrades];

    const index = copyFiTrades.findIndex(
      (trade) => trade.uuid === new_trade[0].uuid,
    );

    if (copyFiTrades[index].version !== "1") {
      copyFiTrades[index] = { ...copyFiTrades[index], highlightUpdate: false };
    } else if (new_trade[0].version === "1") {
      copyFiTrades[index] = { ...copyFiTrades[index], highlight: false };
    }
    // const filteredList = copyFiTrades.filter(
    //   (trade) => trade.uuid !== new_trade[0].uuid,
    // );
    // let obj = [...new_trade];
    // obj[0] = { ...obj[0] };
    // filteredList.unshift(obj[0]);
    dispatch(setFiTrades(copyFiTrades));
  };

export const addNewFiTradeHandler =
  (new_trade) => async (dispatch, getState) => {
    const fiTrades = getState().fiSlice.fiTrades;
    const copyFiTrades = [...fiTrades];

    let obj = [...new_trade];
    if (new_trade[0].version !== "1") {
      const filteredList = copyFiTrades.filter(
        (trade) => trade.uuid !== new_trade[0].uuid,
      );
      obj[0] = { ...obj[0], highlightUpdate: true };
      filteredList.unshift(obj[0]);
      dispatch(setFiTrades(filteredList));
      // state.fiTrades = filteredList;
    } else if (new_trade[0].version === "1") {
      obj[0] = { ...obj[0], highlight: true };

      copyFiTrades.unshift(obj[0]);
      dispatch(setFiTrades(copyFiTrades));
    }
  };

export const setCheckedTrade = (trade_id) => async (dispatch, getState) => {
  const fiTrades = getState().fiSlice?.fiTrades;
  const chosenTrades = getState().fiSlice?.chosenTrades;
  const fiTradesCopy = [...fiTrades];
  let chosenTradesCopy = [...chosenTrades];
  let chosenTrade = fiTradesCopy.find((t) => t.trade_id === trade_id);
  let chosenTradeIdx = fiTradesCopy.findIndex((t) => t.trade_id === trade_id);
  chosenTrade = { ...chosenTrade, checked: !chosenTrade.checked };
  fiTradesCopy[chosenTradeIdx] = chosenTrade;
  if (chosenTrade.checked === true) {
    chosenTradesCopy = [...chosenTradesCopy, chosenTrade];
  } else {
    let newChosenTrades = chosenTradesCopy.filter(
      (t) => t.trade_id !== chosenTrade.trade_id,
    );
    chosenTradesCopy = newChosenTrades;
  }
  dispatch(setFiTrades(fiTradesCopy));
  dispatch(setChosenTrades(chosenTradesCopy));
};

export const getFiDataAsync = (trades, type) => async (dispatch, getState) => {
  let tempData = [];
  if (trades !== undefined && trades !== null && trades.length > 0) {
    tempData = trades;
    tempData.forEach((t) => {
      t.checked = false;
    });

    switch (type) {
      case "first_load":
        tempData.sort((a, b) => {
          let x = a.side.toLowerCase();
          let y = b.side.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });
        dispatch(setFiTrades(tempData));
        break;
      case "new_fi_trade":
        dispatch(addNewFiTradeHandler(tempData));
        setTimeout(() => {
          dispatch(removeHighlightHandler(tempData));
        }, [10000]);
        break;
      case "handle_deal":
        const TradeState = getState().fiSlice?.fiTrades;
        const indexToRemove = TradeState.findIndex(
          (t) => t.uuid === tempData[0].uuid,
        );
        const copyOfTradeState = [...TradeState];
        if (indexToRemove !== -1) {
          copyOfTradeState.splice(indexToRemove, 1, tempData[0]);
        } else if (indexToRemove === -1) {
          copyOfTradeState.push(tempData[0]);
        }
        dispatch(addNewDeal(copyOfTradeState));
        break;

      default:
        break;
    }
  } else {
    dispatch(setFiTrades([]));
  }
};

export const filterTrades =
  (searchValue, type) => async (dispatch, getState) => {
    const fiTrades = getState().fiSlice.fiTrades;
    let tempArr = [];
    let keys = Object.keys(fiTrades[0]);

    if (searchValue !== "") {
      fiTrades.map((t) => {
        let tradeValues = Object.values(t);
        let searchMatch = false;
        tradeValues.map((value) => {
          if (
            value !== null &&
            value
              .toString()
              .toLowerCase()
              .match(searchValue.toString().toLowerCase()) !== null
          ) {
            searchMatch = true;
          }
        });
        if (searchMatch) {
          tempArr.push(t);
        }
      });

      dispatch(setFiTrades(tempArr));
    }
  };
export const handleSplitTradersPnl = (obj) => async (dispatch, getState) => {
  const tradersSplitPnlArrCopy = [...getState().fiSlice.tradersSplitPnlArr];
  const { index, newValue, key, tradersList = [] } = obj;
  const idx = tradersSplitPnlArrCopy.findIndex(
    (split) => Number(split.id) === Number(index),
  );
  if (key === "traderId") {
    let traderId;
    if (newValue !== null) {
      traderId = tradersList.filter((t) => t.value === newValue)[0].id;
    }
    if (idx !== -1) {
      // index exist
      tradersSplitPnlArrCopy.splice(idx, 1, {
        ...tradersSplitPnlArrCopy[`${idx}`],
        [`${key}`]: newValue !== null ? traderId : null,
      });
    } else {
      // index doesn't exist
      tradersSplitPnlArrCopy.push({
        [`${key}`]: newValue !== null ? traderId : null,
        id: index,
      });
    }
  } else if (key === "percentageSplit") {
    if (idx !== -1) {
      // index exist
      tradersSplitPnlArrCopy.splice(idx, 1, {
        ...tradersSplitPnlArrCopy[`${idx}`],
        [`${key}`]: newValue === null ? null : Number(newValue),
      });
    } else {
      // index doesn't exist
      tradersSplitPnlArrCopy.push({
        [`${key}`]: newValue === null ? null : Number(newValue),
        id: index,
      });
    }
  }
  dispatch(updateTradersSplitPnlArr(tradersSplitPnlArrCopy));
};

export const updatePnlAtt =
  (pnlDealId, tradersSplitPnlArr) => async (dispatch, getState) => {
    const fiTradesCopy = [...getState().fiSlice.fiTrades];
    const filterByDeal = fiTradesCopy.filter((t) => t.deal_id === pnlDealId);
    if (filterByDeal.length > 0) {
      filterByDeal.forEach((t) => {
        const index = fiTradesCopy.findIndex(
          (trade) => Number(trade.trade_id) === Number(t.trade_id),
        );
        if (index !== -1) {
          const updatedTrade = {
            ...t,
            pnlAtt: JSON.stringify(tradersSplitPnlArr),
          };
          fiTradesCopy.splice(index, 1, updatedTrade);
        }
      });
      dispatch(setFiTrades(fiTradesCopy));
    }
  };

export const {
  emptyFiTradesArr,
  removeHighlight,
  addNewDeal,
  setFiTrades,
  setChosenTrades,
  editNewTradeForm,
  resetFormData,
  setSelectedTradeToEdit,
  resetSelectedTradeToEdit,
  setSplitTradeForm,
  setSplitValues,
  resetSplitArrValues,
  setAmountLeftToSplit,
  setCheckboxInsideDeal,
  addToDealsArr,
  removeFromDealsArr,
  resetDealsArr,
  resetSelectedTrades,
  resetChosenTrades,
  setChosenDeals,
  updateTradersSplitPnlArr,
  resetTradersSplitPnlArr,
} = fiSlice.actions;

export default fiSlice.reducer;
