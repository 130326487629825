export const editableSelector = (params, mappedTableData) => {
  const colId = params.column.colId;
  const markitStatus = mappedTableData.filter(
    (row) => row.columnHeader.toLowerCase() === "markit status",
  );
  if (
    params.data.columnHeader.toLowerCase() !== "buyer" &&
    params.data.columnHeader.toLowerCase() !== "seller" &&
    params.data.columnHeader.toLowerCase() !== "buyer le" &&
    params.data.columnHeader.toLowerCase() !== "seller le"
  ) {
    return false;
  } else if (markitStatus[0][colId] === "success") {
    return false;
  } else return true;
};
