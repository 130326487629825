import { camelCaseToTitle, convertDateFormat } from "./functions";
import * as actionSnackBar from "../../../../store/snackbar/action";

export const SWBMLGenerator = (data, dispatch) => {
  const tradesNumbersArray = Object.keys(data[0]).filter((key) =>
    key.startsWith("trade"),
  );
  let emptyFieldDetected = false;
  const emptyFields = [];

  const tradesObjectsArray = tradesNumbersArray.map((t) => {
    let recipientA = "";
    let recipientB = "";
    let buyerRecipients = "";
    let sellerRecipients = "";
    if (emptyFieldDetected) return null;

    const tradeObj = {};
    let markitStatus = "";
    data.some((row) => {
      if (row.id === "markitStatus") {
        markitStatus = row[t];
        if (markitStatus === "success") {
          return true;
        }
      } else if (row.id === "tradeId") {
        tradeObj.tradeId = row[t];
      } else if (row.id === "buyer") {
        if (row[t] === null) {
          emptyFieldDetected = true;
          emptyFields.push({ field: "buyer", trade: t });
          return true;
        }
        const tradeAddressees = row.markitFavData.filter(
          (fav) => fav.label === row[t],
        );
        if (tradeAddressees.length > 0) {
          tradeAddressees[0].chips.forEach((c) => {
            buyerRecipients += `<UserId>${c.addresseeName}</UserId>`;
          });
          recipientA = `<Recipient id="_1">
                    <ParticipantId>${tradeAddressees[0].participantId}</ParticipantId>
                    ${buyerRecipients}
                    </Recipient>`;
        }
      } else if (row.id === "buyerTrader") {
        tradeObj.buyerTrader = row[t];
      } else if (row.id === "seller") {
        if (row[t] === null) {
          emptyFieldDetected = true;
          emptyFields.push({ field: "seller", trade: t });
          return true;
        }
        const tradeAddressees = row.markitFavData.filter(
          (fav) => fav.label === row[t],
        );
        if (tradeAddressees.length > 0) {
          tradeAddressees[0].chips.forEach((c) => {
            sellerRecipients += `<UserId>${c.addresseeName}</UserId>`;
          });
          recipientB = `<Recipient id="_2">
                    <ParticipantId>${tradeAddressees[0].participantId}</ParticipantId>
                    ${sellerRecipients}
                    </Recipient>`;
        }
      } else if (row.id === "sellerTrader") {
        tradeObj.sellerTrader = row[t];
      } else if (row.id === "tradeDate") {
        tradeObj.tradeDate = row[t];
      } else if (row.id === "optionType") {
        tradeObj.optionType = row[t];
      } else if (row.id === "underlying") {
        tradeObj.underlying = row[t];
      } else if (row.id === "expiry") {
        tradeObj.expiry = row[t];
      } else if (row.id === "strike") {
        tradeObj.strike = row[t];
      } else if (row.id === "quantity") {
        tradeObj.quantity = row[t];
      } else if (row.id === "price") {
        tradeObj.price = row[t];
      } else if (row.id === "paymentDate") {
        tradeObj.paymentDate = row[t];
      } else if (row.id === "buyerLe") {
        const buyerLeObj = row?.leObj[t]?.filter(
          (le) => le.LELongName === row[t],
        );
        tradeObj.buyerLeObj = buyerLeObj;
      } else if (row.id === "sellerLe") {
        const sellerLeObj = row?.leObj[t].filter(
          (le) => le.LELongName === row[t],
        );

        tradeObj.sellerLeObj = sellerLeObj;
      } else if (row.id === "buyerPaymentAmount") {
        tradeObj.buyerPaymentAmount = row[t];
      } else if (row.id === "sellerPaymentAmount") {
        tradeObj.sellerPaymentAmount = row[t];
      }
      return false;
    });
    if (markitStatus === "success" || emptyFieldDetected) return null;

    const recipientsXml = `<Recipients>${recipientA}
    ${recipientB}
    </Recipients>`;
    const tradeXml = `<?xml version="1.0"?>
    <SWBML xmlns="http://www.fpml.org/2010/FpML-4-9"
	  xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" version="4-9" xsi:schemaLocation="http://www.fpml.org/2010/FpML-4-9 swbml-main-4-9.xsd">
    <swbHeader>
    <swbBrokerTradeId>${tradeObj.tradeId}</swbBrokerTradeId>
    <swbBrokerTradeVersionId>1</swbBrokerTradeVersionId>
    <swbTradeSource>Voice</swbTradeSource>
    <swbRecipient id="_1">
      <partyReference href="partyA"/>
    </swbRecipient>
    <swbRecipient id="_2">
      <partyReference href="partyB"/>
    </swbRecipient>
    </swbHeader>
    <swbStructuredTradeDetails>
    <swbProductType>Equity Index Option</swbProductType>
    <FpML version="4-9" xsi:type="DataDocument">
      <trade>
          <tradeHeader>
              <partyTradeIdentifier>
                  <partyReference href="partyA"/>
                  <tradeId tradeIdScheme="www.tfs.com">0</tradeId>
              </partyTradeIdentifier>
              <partyTradeIdentifier>
                  <partyReference href="partyB"/>
                  <tradeId tradeIdScheme="www.tfs.com">0</tradeId>
              </partyTradeIdentifier>
              <partyTradeInformation>
                  <partyReference href="partyA"/>
                  <trader>${camelCaseToTitle(tradeObj.buyerTrader)}</trader>
              </partyTradeInformation>
              <partyTradeInformation>
                  <partyReference href="partyB"/>
                  <trader>${camelCaseToTitle(tradeObj.sellerTrader)}</trader>
              </partyTradeInformation>
              <tradeDate>${tradeObj.tradeDate}</tradeDate>
          </tradeHeader>
        <equityOptionTransactionSupplement>
          <buyerPartyReference href="partyA"/>
          <sellerPartyReference href="partyB"/>
          <optionType>${
            tradeObj.optionType.toLowerCase() === "c"
              ? "Call"
              : tradeObj.optionType.toLowerCase() === "p"
              ? "Put"
              : null
          }</optionType>
          <underlyer>
            <singleUnderlyer>
              <index>
                <instrumentId instrumentIdScheme="http://www.fpml.org/spec/2003/instrument-id-Reuters-RIC-1-0">.${
                  tradeObj.underlying
                }</instrumentId>
              </index>
            </singleUnderlyer>
          </underlyer>
          <equityExercise>
            <equityEuropeanExercise>
              <expirationDate>
                <adjustableDate>
                  <unadjustedDate>${convertDateFormat(
                    tradeObj.expiry,
                  )}</unadjustedDate>
                  <dateAdjustments>
                    <businessDayConvention>NotApplicable</businessDayConvention>
                  </dateAdjustments>
                </adjustableDate>
              </expirationDate>
              <equityExpirationTimeType>OSP</equityExpirationTimeType>
            </equityEuropeanExercise>
            <automaticExercise>true</automaticExercise>
            <equityValuation id="valuationDate">
              <futuresPriceValuation>true</futuresPriceValuation>
            </equityValuation>
            <settlementDate>
              <relativeDate>
                <periodMultiplier>1</periodMultiplier>
                <period>D</period>
                <dayType>CurrencyBusiness</dayType>
                <businessDayConvention>NotApplicable</businessDayConvention>
                <dateRelativeTo href="valuationDate"/>
              </relativeDate>
            </settlementDate>
            <settlementCurrency>USD</settlementCurrency>
            <settlementType>Cash</settlementType>
          </equityExercise>
          <strike>
            <strikePrice>${tradeObj.strike}</strikePrice>
          </strike>
          <numberOfOptions>${tradeObj.quantity}</numberOfOptions>
          <equityPremium>
            <payerPartyReference href="partyA"/>
            <receiverPartyReference href="partyB"/>
            <paymentAmount>
              <currency>USD</currency>
              <amount>${(tradeObj.quantity * tradeObj.price).toFixed(
                2,
              )}</amount>
            </paymentAmount>
            <paymentDate>
              <unadjustedDate>${tradeObj.paymentDate}</unadjustedDate>
              <dateAdjustments>
                <businessDayConvention>NotApplicable</businessDayConvention>
              </dateAdjustments>
            </paymentDate>
            <pricePerOption>
              <currency>USD</currency>
              <amount>${tradeObj.price}</amount>
            </pricePerOption>
          </equityPremium>
        </equityOptionTransactionSupplement>
        <otherPartyPayment>
          <payerPartyReference href="partyA"/>
          <receiverPartyReference href="broker"/>
          <paymentAmount>
            <currency>USD</currency>
            <amount>${
              tradeObj.buyerPaymentAmount === null
                ? 0
                : tradeObj.buyerPaymentAmount
            }</amount>
          </paymentAmount>
        </otherPartyPayment>
        <otherPartyPayment>
          <payerPartyReference href="partyB"/>
          <receiverPartyReference href="broker"/>
          <paymentAmount>
            <currency>USD</currency>
            <amount>${
              tradeObj.sellerPaymentAmount === null
                ? 0
                : tradeObj.sellerPaymentAmount
            }</amount>
          </paymentAmount>
        </otherPartyPayment>
        <brokerPartyReference href="broker"/>
        <documentation>
          <brokerConfirmation>
            <brokerConfirmationType brokerConfirmationTypeScheme="http://www.swapswire.com/spec/2004/broker-confirmation-type-1-0">ISDA2004EquityAmericasInterdealer</brokerConfirmationType>
          </brokerConfirmation>
        </documentation>
      </trade>
      <party id="partyA">
        <partyId>${tradeObj.buyerLeObj[0]?.LEBic}</partyId>
        <partyName>${tradeObj.buyerLeObj[0]?.LELongName}</partyName>
      </party>
      <party id="partyB">
        <partyId>${tradeObj.sellerLeObj[0]?.LEBic}</partyId>
        <partyName>${tradeObj.sellerLeObj[0]?.LELongName}</partyName>
      </party>
      <party id="broker">
        <partyId>MSEC_LN</partyId>
        <partyName>Makor</partyName>
      </party>
    </FpML>
    <swbExtendedTradeDetails>
			<swbTradeHeader>
				<swbAutoProcessing>false</swbAutoProcessing>
        <swbAutoSendForClearing>false</swbAutoSendForClearing>
				<swbClientClearing>false</swbClientClearing>
				<swbPartyExecutionMethod>
					<partyReference href="partyB"/>
					<swbExecutionMethod>Voice</swbExecutionMethod>
				</swbPartyExecutionMethod>
				<swbPartyExecutionMethod>
					<partyReference href="partyA"/>
					<swbExecutionMethod>Voice</swbExecutionMethod>
				</swbPartyExecutionMethod>
			</swbTradeHeader>
			<swbMessageText>Text to counterparties</swbMessageText>
		</swbExtendedTradeDetails>
    </swbStructuredTradeDetails>
    </SWBML>`;
    return { recipientsXml, tradeXml, columnId: t };
  });
  // !disabled for testing purposes
  if (emptyFieldDetected) {
    dispatch(
      actionSnackBar.setSnackBar(
        "error",
        `${emptyFields[0].trade.toUpperCase()} ${
          emptyFields[0].field
        } cannot be empty`,
        3000,
      ),
    );
    return null;
  } else {
    return tradesObjectsArray;
  }
};
