import React, { useEffect, useState } from "react";

import { Grid } from "@material-ui/core";
import { useStyles } from "../style/style";
import { MarkdownToHtml } from "./tools/MarkdownToHtml";
import CircularProgress from "@material-ui/core/CircularProgress";

export const Body = ({
  setMarkdown,
  markdown,
  tabChosen,
  setMarkdownContentTabs,
  isTabConfirmed,
  markdownContentTabs,
}) => {
  const classes = useStyles();

  return (
    <Grid className={classes.bodyContainer}>
      {markdown === null ? (
        <Grid className={classes.progressContainer}>
          <CircularProgress />
        </Grid>
      ) : (
        <MarkdownToHtml
          setMarkdown={setMarkdown}
          markdown={markdown}
          tabChosen={tabChosen}
          setMarkdownContentTabs={setMarkdownContentTabs}
          isTabConfirmed={isTabConfirmed}
          markdownContentTabs={markdownContentTabs}
        />
      )}
    </Grid>
  );
};
