import {
  createExecArray,
  getCurrencyId,
  getExchangeId,
  getExecComponentType,
  getExecContractType,
  getExecPrice,
  getExpiry,
  getFloorBrokerId,
  getMakorXId,
  getOptionTypeId,
  getSide,
  getStrategyId,
  getStrike,
  getTradeExecHedgeTypeId,
  getTradeTypeId,
  getUnderlyingStockTypeId,
  getUnderlyingTypeId,
  getTraderSide,
  getCompanyClearedId,
  getTraderById,
  getTotalOptionQuantity,
  getFeeCalculation,
  getTraderEmployeesFee,
  getTradeAccountExecs,
  getStrategyName,
  convertEstStringToUtc,
  getSymbolName,
} from "./JsonUtils";
import { getUniqueCompanies } from "../bookingTables/utils/cellEditorSelector";

import { submitBookingToMakorX } from "./bookingOrderUtils";

export const buildJsonAndBookMakorX = async (
  makorStaticData,
  bookingTable,
  strategyTable,
  underlyingTable,
  parsedBookingText,
  dispatch,
  order,
) => {
  try {
    const receivedAtIndex = bookingTable.findIndex(
      (row) => row.id === "received_at",
    );
    const executedAtIndex = bookingTable.findIndex(
      (row) => row.id === "executed_at",
    );
    const completedAtIndex = bookingTable.findIndex(
      (row) => row.id === "completed_at",
    );
    const sideIndex = bookingTable.findIndex((row) => row.id === "side");
    const floorBrokerIndex = bookingTable.findIndex(
      (row) => row.id === "floor_broker",
    );
    const clearedByIndex = bookingTable.findIndex(
      (row) => row.id === "cleared_by",
    );
    const clientByIndex = bookingTable.findIndex((row) => row.id === "client");
    const traderByIndex = bookingTable.findIndex((row) => row.id === "trader");
    const sendConformationIndex = bookingTable.findIndex(
      (row) => row.id === "send_conformation",
    );
    const crossedFeeIndex = bookingTable.findIndex(
      (row) => row.id === "crossed_fee_rate",
    );
    const notCrossedFeeIndex = bookingTable.findIndex(
      (row) => row.id === "not_crossed_fee_rate",
    );
    const tradeTypeIndex = bookingTable.findIndex(
      (row) => row.id === "trade_type",
    );
    const initiationFeeIndex = bookingTable.findIndex(
      (row) => row.id === "initiation_fee",
    );
    const marketMakerFeeIndex = bookingTable.findIndex(
      (row) => row.id === "market_maker_fee",
    );
    const legFeeIndex = bookingTable.findIndex(
      (row) => row.id === "leg_fee_rate",
    );
    const hedgeFeeIndex = bookingTable.findIndex(
      (row) => row.id === "hedge_fee_rate",
    );
    const commissionIndex = bookingTable.findIndex(
      (row) => row.id === "commission",
    );
    const initiationIndex = bookingTable.findIndex(
      (row) => row.id === "initiation",
    );
    const marketMakerIndex = bookingTable.findIndex(
      (row) => row.id === "market_maker",
    );
    const isInitiatorIndex = bookingTable.findIndex(
      (row) => row.id === "initiator",
    );
    const floorBrokerFeeIndex = bookingTable.findIndex(
      (row) => row.id === "floor_broker_fee",
    );

    const execArray = await createExecArray(
      bookingTable.filter(
        (row) =>
          (row.id.includes("option") || row.id.includes("hedge")) &&
          row.id !== "hedge_fee_rate",
      ),
    );
    const hedgesArray = await createExecArray(
      bookingTable.filter(
        (row) => row.id.includes("hedge") && row.id !== "hedge_fee_rate",
      ),
    );

    const drv_trade = {
      // employee_submitted_id: 150,
      employee_submitted_id: await getMakorXId(makorStaticData.employees),
      received_at: await convertEstStringToUtc(
        bookingTable[receivedAtIndex][`exec1`],
      ),
      executed_at: await convertEstStringToUtc(
        bookingTable[executedAtIndex][`exec1`],
      ),
      completed_at: await convertEstStringToUtc(
        bookingTable[completedAtIndex][`exec1`],
      ),
      drv_trade_strategy_id: await getStrategyId(
        strategyTable[0]._strategy !== undefined
          ? strategyTable[0]._strategy
          : strategyTable[0].strategy,
        makorStaticData.drv_trade_strategies,
      ),
      price: strategyTable[0].price,
      quantity: strategyTable[0].quantity,
      drv_trade_floor_broker_id: await getFloorBrokerId(
        bookingTable[floorBrokerIndex]["exec1"],
        makorStaticData.drv_trade_floor_brokers,
      ),
      drv_trade_floor_broker_fee_currency_id: await getCurrencyId(
        "USD",
        makorStaticData.currencies,
      ),
      drv_trade_floor_broker_quantity_crossed_fee_rate:
        bookingTable[crossedFeeIndex][`exec1`],
      drv_trade_floor_broker_quantity_not_crossed_fee_rate:
        bookingTable[notCrossedFeeIndex][`exec1`],

      drv_trade_detail: {
        drv_trade_type_id: await getTradeTypeId(
          bookingTable[tradeTypeIndex]["exec1"],
          makorStaticData.drv_trade_types,
        ),
        initiation_fee_percentage:
          bookingTable[initiationFeeIndex]["exec1"] * 100,
        market_maker_fee_percentage:
          bookingTable[marketMakerFeeIndex]["exec1"] * 100,
        volatility_ratio:
          strategyTable[0].ratio !== null
            ? Number(strategyTable[0].ratio)
            : null,
        volatility_delta: Math.abs(Number(strategyTable[0].delta) * 100),

        drv_trade_execution_hedge_type_id: await getTradeExecHedgeTypeId(
          hedgesArray.length === 0
            ? null
            : order.equityIndex.toLowerCase().includes("index")
            ? "synthetic"
            : "cash",
          makorStaticData.drv_trade_execution_hedge_types,
        ),
        underlying_price: Number(strategyTable[0].tie),
        underlying_multiplier: underlyingTable[0].multiplier,
        underlying: {
          underlying_type_id: await getUnderlyingTypeId(
            order.equityIndex.toLowerCase().includes("index")
              ? "index"
              : "stock",
            makorStaticData.underlying_type,
          ),
          underlying_stock_type_id: await getUnderlyingStockTypeId(
            underlyingTable[0].securityType,
            makorStaticData.underlying_stock_type,
          ),
          name: underlyingTable[0].ticker,
          currency_id: await getCurrencyId("USD", makorStaticData.currencies),
          exchange_id: await getExchangeId(
            strategyTable[0].exchange,
            makorStaticData.exchange,
          ),
          isin_code: underlyingTable[0].isin,
          option_symbol: underlyingTable[0].ticker,
          multiplier_option: underlyingTable[0].multiplier,
          multiplier_future: null,
        },
      },

      drv_trade_executions: await Promise.all(
        execArray.map(async (e, i) => {
          return {
            form_drv_trade_execution_id: i + 1,
            drv_trade_execution_component_type_id: await getExecComponentType(
              e,
              makorStaticData.drv_trade_execution_component_types,
            ),
            drv_trade_execution_contract_type_id: await getExecContractType(
              e,
              makorStaticData.drv_trade_execution_contract_type,
              order,
            ),
            side_id: await getSide(
              e,
              bookingTable[sideIndex][`exec1`],
              makorStaticData.side,
            ),
            price: await getExecPrice(e),
            symbol: await getSymbolName(
              e,
              i,
              parsedBookingText.executions[0].option,
              parsedBookingText.executions[0].hedge,
            ),
            ticker: e[0].id.toLowerCase().includes("hedge")
              ? underlyingTable[0].ticker
              : null,
            settlement: order.equityIndex.toLowerCase().includes("index")
              ? null
              : e[0].id.toLowerCase().includes("hedge")
              ? 1
              : null,
            expiry: await getExpiry(e, order),
            drv_trade_execution_option_type_id: await getOptionTypeId(
              e,
              makorStaticData.drv_trade_execution_option_types,
              order,
            ),
            strike: await getStrike(e, order),
          };
        }),
      ),
      drv_trade_client_traders: await Promise.all(
        parsedBookingText.executions.map(async (exec, i) => {
          return {
            side_id: await getTraderSide(
              bookingTable[sideIndex][`exec${i + 1}`],
              makorStaticData.side,
            ),
            company_cleared_id: await getCompanyClearedId(
              bookingTable[clearedByIndex][`exec${i + 1}`],
              getUniqueCompanies(makorStaticData.clients),
            ),
            client_trader_id: await getTraderById(
              bookingTable[clearedByIndex][`exec${i + 1}`],
              bookingTable[clientByIndex][`exec${i + 1}`],
              bookingTable[traderByIndex][`exec${i + 1}`],
              makorStaticData.clients,
            ),

            need_confirmation_send:
              bookingTable[sendConformationIndex][`exec${i + 1}`],
            execution_quantity: await getTotalOptionQuantity(exec.option),
            brokerage_fee_leg_rate: bookingTable[legFeeIndex][`exec${i + 1}`],
            brokerage_fee_hedge_rate:
              bookingTable[hedgeFeeIndex][`exec${i + 1}`],
            brokerage_fee_calculation: await getFeeCalculation(
              exec,
              bookingTable[legFeeIndex][`exec${i + 1}`],
              bookingTable[hedgeFeeIndex][`exec${i + 1}`],
            ),
            brokerage_fee: bookingTable[commissionIndex][`exec${i + 1}`],
            brokerage_fee_currency_id: await getCurrencyId(
              "USD",
              makorStaticData.currencies,
            ),
            drv_trade_floor_broker_fee_rate: Number(
              bookingTable[floorBrokerFeeIndex]["floorBrokerFeeRate"][i],
            ),
            drv_trade_floor_broker_fee:
              bookingTable[floorBrokerFeeIndex][`exec${i + 1}`],
            initiation_fee: bookingTable[initiationIndex][`exec${i + 1}`],
            market_maker_fee: bookingTable[marketMakerIndex][`exec${i + 1}`],
            drv_trade_client_trader_employees_brokerage_fee:
              await getTraderEmployeesFee(
                bookingTable[clearedByIndex][`exec${i + 1}`],
                bookingTable[clientByIndex][`exec${i + 1}`],
                bookingTable[traderByIndex][`exec${i + 1}`],
                makorStaticData.clients,
              ),
            drv_trade_client_account_executions: await getTradeAccountExecs(
              i + 1,
              execArray,
            ),
            is_initiation_drv_trade_client_trader:
              bookingTable[isInitiatorIndex][`exec${i + 1}`],
          };
        }),
      ),
    };
    if (drv_trade.drv_trade_strategy_id === 1) {
      drv_trade.drv_trade_detail = {
        ...drv_trade.drv_trade_detail,
        drv_trade_strategy_custom_name:
          strategyTable[0]._strategy !== undefined
            ? strategyTable[0].strategy
            : await getStrategyName(strategyTable[0].strategy), //=> name of the strategy
        drv_trade_strategy_custom_category_id: 4, //=> hardcoded as 4 (INT)
        drv_trade_strategy_custom_execution_leg_contract_type_id: 1, // => hardcoded as 1 (INT)
      };
    }
    // console.log({ drv_trade });
    return submitBookingToMakorX(
      drv_trade,
      dispatch,
      bookingTable,
      strategyTable,
      underlyingTable,
      parsedBookingText,
    );
  } catch (error) {
    console.log("buildJsonForBooking", error);
  }
};
