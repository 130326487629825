import React from "react";
import { ReactComponent as Arrows } from "../../../../../assets/arrows.svg";
import { Grid } from "@material-ui/core";

export const ToggleRenderer = (params) => {
  let rotateDirection;
  if (params.data.isOtc !== undefined) {
    rotateDirection = params.data.isOtc;
  } else if (params.data.columnHeader === "Side") {
    rotateDirection = params.value === "S" ? true : false;
  } else if (
    params.data.columnHeader === "Send Conformation" ||
    params.data.columnHeader === "Initiator" ||
    params.data.columnHeader === "Is Market Maker"
  ) {
    rotateDirection = params.value ? true : false;
  }
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item xs={8}>
        {params.valueFormatted}
      </Grid>
      <Grid item xs={4}>
        <Arrows
          style={{
            transform: `rotate(${rotateDirection ? "180deg" : "0"})`,
          }}
        />
      </Grid>
    </Grid>
  );
};
