import React, { useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export const EntitlementsCheckbox = ({ entitlement }) => {
  const [isChecked, setIsChecked] = useState(false);
  const handleChange = () => {
    setIsChecked((pre) => {
      return !pre;
    });
  };

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked}
            onChange={handleChange}
            name={entitlement}
            style={{
              color: isChecked ? "#2692FF" : "grey",
            }}
          />
        }
        label={entitlement}
      />
    </>
  );
};
