import { combineReducers } from "@reduxjs/toolkit";

import rfqSlice from "./rfq/rfqSlice";
import userSlice from "./users/usersSlice";
import uiSlice from "./ui/uiSlice";
import rfqAggregatorSlice from "./rfq/rfqAggregatorSlice";
import rfqPostTradeSlice from "./rfq/rfqPostTradeSlice";
import emailSlice from "./email_confo/emailConfoSlice";
import snackbarReducer from "./snackbar/reducer";
import fiSlice from "./fi/fiSlice";
import dataMgmtSlice from "./data_managment/dataMgmtSlice";
import fiClientsSlice from "./fi/fiClientsSlice";
import ratesSlice from "./rates/ratesSlice";
import derivsPricerSlice from "./derivsPricer/derivsPricerSlice";
import equitiesSpreadSlice from "./equitiesSpread/equitiesSpreadSlice";

const createRootReducer = () =>
  combineReducers({
    rfqSlice: rfqSlice,
    userSlice: userSlice,
    uiSlice: uiSlice,
    rfqAggregatorSlice: rfqAggregatorSlice,
    rfqPostTradeSlice: rfqPostTradeSlice,
    emailSlice: emailSlice,
    snackBar: snackbarReducer,
    fiSlice: fiSlice,
    dataMgmtSlice: dataMgmtSlice,
    fiClientsSlice: fiClientsSlice,
    ratesSlice: ratesSlice,
    derivsPricerSlice: derivsPricerSlice,
    equitiesSpreadSlice,
  });

export default createRootReducer;
