import { getMarkitLegalEntitiesByParticipant } from "./apiCalls";
import { v1 as uuidv1 } from "uuid";

export const remapData = (bookedTradeData, markitFavData) => {
  const { tablesData, makorXRef, eConfoData } = bookedTradeData;
  if (eConfoData !== null) return eConfoData;
  const { bookingTable, underlyingTable } = tablesData;
  const traders = {};
  bookingTable
    .filter((row) => row.id === "trader")
    .forEach((row) => {
      Object.keys(row).forEach((key) => {
        if (key.startsWith("exec")) {
          traders[key] = row[key];
        }
      });
    });

  let tradeSets = [];
  const sideRows = [];
  const strikeRows = [];
  const priceRows = [];
  const expiryRows = [];
  const callPutRows = [];
  let commissionRow = null;

  bookingTable.forEach((row) => {
    if (row.id.includes("side")) {
      if (row.id !== "side") {
        sideRows.push(row);
      }
    }
    if (row.id.includes("strike")) {
      strikeRows.push(row);
    }
    if (row.id.includes("price")) {
      priceRows.push(row);
    }
    if (row.id.includes("expiry")) {
      expiryRows.push(row);
    }
    if (row.id.includes("call_put")) {
      callPutRows.push(row);
    }
    if (row.id.includes("commission")) {
      commissionRow = { ...row };
    }
  });

  bookingTable.forEach((row) => {
    if (row.columnHeader.includes("Quantity")) {
      const newTradeSet = [];

      Object.keys(row).forEach((key) => {
        if (key.startsWith("exec")) {
          newTradeSet.push({
            trader: traders[key],
            execKey: key,
            quantity: row[key],
            side: sideRows[tradeSets.length][key],
            strike: strikeRows[tradeSets.length][key],
            price: priceRows[tradeSets.length][key],
            expiry: expiryRows[tradeSets.length][key],
            callPut: callPutRows[tradeSets.length][key],
            commission: commissionRow[key],
          });
        }
      });
      tradeSets.push(newTradeSet);
    }
  });

  let matchedTrades = [];
  tradeSets.forEach((trades, tradeSetIdx) => {
    const sellers = trades.filter((trade) => trade.side === "S");
    const buyers = trades.filter((trade) => trade.side === "B");
    sellers.forEach((seller) => {
      while (seller.quantity > 0 && buyers.length > 0) {
        let buyer = buyers[0]; // Always work with the first buyer in the list
        if (seller.quantity >= buyer.quantity) {
          matchedTrades.push({
            buyer: null,
            buyerLe: null,
            buyerTrader: buyer.trader,
            seller: null,
            sellerLe: null,
            sellerTrader: seller.trader,
            buyerPaymentAmount: tradeSetIdx === 0 ? buyer.commission : null,
            sellerPaymentAmount:
              tradeSetIdx === 0 && matchedTrades.length === 0
                ? seller.commission
                : null,
            tradeId: `${getTodayDate("id")}-${makorXRef}-${indexToLetter(
              matchedTrades.length,
            )}${uuidv1()}`,
            tradeSource: "voice",
            ProductType: "Equity Index Option",
            tradeDate: getTodayDate("date"),
            optionType: buyer.callPut,
            underlying: underlyingTable[0].ticker,
            expiry: buyer.expiry,
            strike: buyer.strike,
            quantity: buyer.quantity,
            price: buyer.price,
            paymentDate: getTodayDate(addBusinessDays()),
            markitStatus: "pending",
            markitDealId: null,
            markitErrorMessage: null,
          });
          seller.quantity -= buyer.quantity;
          buyers.shift(); // Remove the buyer from the list once their need is fulfilled
        } else {
          matchedTrades.push({
            buyer: null,
            buyerLe: null,
            seller: null,
            sellerLe: null,
            buyerPaymentAmount:
              tradeSetIdx === 0 && matchedTrades.length === 0
                ? buyer.commission
                : null,
            sellerPaymentAmount: tradeSetIdx === 0 ? seller.commission : null,
            sellerTrader: seller.trader,
            buyerTrader: buyer.trader,
            tradeId: `${getTodayDate("id")}-${makorXRef}-${indexToLetter(
              matchedTrades.length,
            )}${uuidv1()}`,
            tradeSource: "voice",
            ProductType: "Equity Index Option",
            optionType: seller.callPut,
            tradeDate: getTodayDate("date"),
            underlying: underlyingTable[0].ticker,
            expiry: seller.expiry,
            strike: seller.strike,
            quantity: seller.quantity,
            price: seller.price,
            paymentDate: getTodayDate(addBusinessDays()),
            markitStatus: "pending",
            markitDealId: null,
            markitErrorMessage: null,
          });
          buyer.quantity -= seller.quantity;
          seller.quantity = 0;
        }
      }
    });
  });
  return remapDataForAgGrid(matchedTrades, markitFavData);
};

function getTodayDate(key) {
  const today = key === "id" || key === "date" ? new Date() : key;
  const month = (today.getMonth() + 1).toString().padStart(2, "0"); // months are zero-indexed in JS
  const day = today.getDate().toString().padStart(2, "0");
  const year = today.getFullYear();
  return key !== "id" ? `${year}-${month}-${day}` : `${year}${month}${day}`;
}

function indexToLetter(index) {
  if (index < 0 || index > 25) {
    throw new Error("Index must be between 0 and 25");
  }
  const charCodeOfA = "A".charCodeAt(0); // Unicode value for 'A'
  return String.fromCharCode(charCodeOfA + index);
}

function addBusinessDays() {
  const today = new Date();
  let daysAdded = 0;

  while (daysAdded < 2) {
    // Add a day
    today.setDate(today.getDate() + 1);

    // Check if it's a weekend
    if (today.getDay() !== 0 && today.getDay() !== 6) {
      daysAdded++;
    }
  }

  return today;
}
function remapDataForAgGrid(matchedTrades = [], markitFavData) {
  let indexArray = [];
  const mappedData = Object.keys(matchedTrades[0]).map((key, mapIdx) => {
    const rowObj = {};
    matchedTrades.forEach((trade, i) => {
      rowObj[`trade ${i + 1}`] = trade[key];
    });
    if (key === "buyer" || key === "seller") {
      indexArray.push(mapIdx);
    }
    return {
      columnHeader: camelCaseToTitle(key),
      ...rowObj,
      id: key,
    };
  });
  indexArray.forEach((i) => {
    mappedData[i] = { ...mappedData[i], markitFavData };
  });
  return mappedData;
}

export function camelCaseToTitle(camelCase) {
  // Split the string at each point a capital letter follows a lowercase letter
  const words = camelCase.replace(/([a-z])([A-Z])/g, "$1 $2");
  // Capitalize the first letter of each word
  const titleCase = words.replace(/\b\w/g, (char) => char.toUpperCase());
  return titleCase;
}

export function groupByParticipant(inputArray) {
  const groupedObject = {};

  inputArray.forEach((item) => {
    const {
      groupId,
      label,
      participantId,
      addressee,
      addresseeRowId,
      participantRowId,
      LEBic,
    } = item;

    if (!groupedObject[groupId]) {
      groupedObject[groupId] = {
        groupId: groupId,
        label: label,
        chips: [],
        participantId,
        LEBic,
      };
    }

    groupedObject[groupId].chips.push({
      label: `${participantId}\\${addressee}`,
      addresseeRowId: addresseeRowId,
      participantRowId: participantRowId,
      addresseeName: addressee,
    });
  });

  // Convert the grouped object to an array
  return Object.values(groupedObject);
}

export async function getLegalEntitiesByParticipant(newValue, markitFavData) {
  const favObj = markitFavData.filter((f) => f.label === newValue);
  if (favObj.length > 0) {
    const participantId = favObj[0].participantId;
    const res = await getMarkitLegalEntitiesByParticipant(participantId);
    if (res.status) {
      return res.legalEntitiesList;
    } else return [];
  } else {
    return [];
  }
}
export function convertDateFormat(dateStr) {
  // Parse the date in the format DD-MMM-YY
  const months = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };
  const [day, month, year] = dateStr.split("-");
  const fullYear = year.length === 2 ? "20" + year : year; // Assuming 20XX for 2-digit year

  return `${fullYear}-${months[month]}-${day}`;
}
