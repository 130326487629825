import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Collapse,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import AddBoxRoundedIcon from "@material-ui/icons/AddBoxRounded";
import IndeterminateCheckBoxRoundedIcon from "@material-ui/icons/IndeterminateCheckBoxRounded";
import { useStyles } from "../style/derivsClientsStyle";

export const CompaniesContainer = ({
  clientsList = [],
  companies = [],
  setPickedTraders,
  searchTraderValue,
  pickedClientByTrader,
  searchClientValue,
}) => {
  const classes = useStyles();
  const [openStatus, setOpenStatus] = useState({});
  const [selectedId, setSelectedId] = useState(null);
  const itemRefs = useRef(new Map());

  // Function to toggle open/close status
  const toggleOpen = (companyId) => {
    setOpenStatus((prev) => ({
      ...prev,
      [companyId]: !prev[companyId],
    }));
  };
  const onClientPick = (client) => {
    setSelectedId(client.id);
    setPickedTraders(client.client_traders);
  };
  useEffect(() => {
    if (searchTraderValue !== null) {
      setSelectedId(null);
      setOpenStatus({});
    }
  }, [searchTraderValue]);

  useEffect(() => {
    if (pickedClientByTrader !== null) {
      setOpenStatus({});
      setSelectedId(pickedClientByTrader);
      const client = clientsList.filter((c) => c.id === pickedClientByTrader);
      if (client.length > 0) {
        setOpenStatus({ [client[0].company_id]: true });
      }
    }
  }, [pickedClientByTrader]);

  useEffect(() => {
    // Scroll to the selected item after the Collapse transition
    const timer = setTimeout(() => {
      const selectedRef = itemRefs.current.get(selectedId);
      if (selectedRef) {
        selectedRef.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }, 500); // Adjust the delay as needed for the Collapse transition

    return () => clearTimeout(timer);
  }, [selectedId, openStatus]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.companiesContainer}
    >
      {companies.map((company, i) => {
        return (
          <Grid item xs={12} className={classes.itemListContainer} key={i}>
            <Typography
              className={classes.companyTypography}
              onClick={() => toggleOpen(company.id)}
            >
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  toggleOpen(company.id);
                }}
              >
                {openStatus[company.id] ? (
                  <IndeterminateCheckBoxRoundedIcon
                    className={classes.companiesIsOpenIcon}
                  />
                ) : (
                  <AddBoxRoundedIcon className={classes.companiesIsOpenIcon} />
                )}
              </IconButton>
              {company?.name
                .toLowerCase()
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
                .join(" ")}
              {` (${
                clientsList.filter(
                  (c) =>
                    c.company.name.toLowerCase() === company.name.toLowerCase(),
                ).length
              })`}
            </Typography>
            <Collapse
              in={
                openStatus[company.id] ||
                (clientsList.filter(
                  (c) =>
                    c.company.name.toLowerCase() === company.name.toLowerCase(),
                ).length > 0 &&
                  searchClientValue !== null &&
                  searchClientValue !== "")
              }
              timeout="auto"
              unmountOnExit
            >
              <List
                component="div"
                disablePadding
                className={classes.itemsListClients}
              >
                {clientsList
                  .filter(
                    (c) =>
                      c.company.name.toLowerCase() ===
                      company.name.toLowerCase(),
                  )
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((obj) => {
                    return (
                      <ListItem
                        button
                        onClick={() => onClientPick(obj)}
                        selected={selectedId === obj.id}
                        className={classes.listItemSelected}
                        ref={(el) => itemRefs.current.set(obj.id, el)}
                      >
                        <ListItemText
                          className={classes.listItemText}
                          primary={obj.name
                            .toLowerCase()
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() +
                                word.substring(1),
                            )
                            .join(" ")}
                        />
                        {selectedId === obj.id ? (
                          <PlayArrowIcon className={classes.playIcon} />
                        ) : null}
                      </ListItem>
                    );
                  })}
              </List>
            </Collapse>
          </Grid>
        );
      })}
    </Grid>
  );
};
