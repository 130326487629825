import { makeStyles, TableCell, withStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  dialogPaperBookTrade: {
    minWidth: "50vw",
    minHeight: "90vh",
    backgroundColor: "#2D2D2D", // Match parent component background
    padding: "10px", // Smaller padding for a compact look
    overflow: "auto", // Prevent overflow
  },
  closeBtn: {
    color: "#bababa",
    fontSize: "1.1875rem",
  },
  confoBtn: {
    width: "150px",
    borderRadius: 8,
    backgroundColor: "#2692FF",
    border: "1px solid #2692FF",
    textTransform: "none",
    color: "#ffffff",
    fontSize: 14,
    minHeight: "35px",
    maxHeight: "35px",
    "&:hover": {
      backgroundColor: "#2692FF",
      border: "1px solid #ffffff",
    },
    "&:disabled": {
      backgroundColor: "rgba(92, 87, 90, 0.26)",
      border: "1px solid rgba(192, 179, 186, 0.26)",
      color: "#ffffff",
    },
  },

  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "40vh", // Full screen height
  },

  container: {
    backgroundColor: "#2D2D2D", // Match the overall environment
    borderRadius: "8px", // Rounded corners for modern aesthetics
    overflowY: "auto",
    maxHeight: "60vh",
    border: "1px solid #696B76",
    maxWidth: "99%",
  },
  table: {
    minWidth: 500, // Reduced minimum width to shrink the table
    borderCollapse: "separate", // Ensure spacing between borders
    width: "100%", // Ensure the table takes full width of the container
  },
  transparentCell: {
    backgroundColor: "#393b3e", // Transparent background for regular columns
    color: "white", // Consistent font color
    padding: "11px", // Reduced padding for compactness
    borderBottom: "1px solid #4F4F4F", // Subtle border between cells
    borderLeft: "1px solid #4F4F4F", // Left border for cell separation
    minWidth: "150px", // Reduced min width for cells
    fontSize: "1.1rem",
  },
  refreshButton: {
    color: "#fff",
    fontSize: "1.8rem",
    "&:hover": {
      color: "#2ECC71",
    },
    display: "flex",
    alignItems: "center",
  },
}));

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#323335",
    color: "#FFFFFF",
    fontWeight: "bold",
    textAlign: "center",
    padding: theme.spacing(1), // Reduced padding for a smaller size
    fontSize: "1.3rem", // Smaller font size for the header
  },
  body: {
    backgroundColor: "#323335", // Softer light blue for row labels
    color: "#FFFFFF", // Font color matching the interface
    fontSize: "1.1rem", // Reduced font size for compact look
    fontWeight: "bold",
    padding: theme.spacing(1), // Reduced padding for compact look
    borderBottom: "1px solid #4F4F4F", // Subtle border between cells
  },
}))(TableCell);
