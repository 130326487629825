export const cellEditorParamsSelector = (params) => {
  if (params.data.columnHeader === "Cleared By") {
    const values = params.data.clearedByData.map((v) => v.name);
    return {
      values: values,
    };
  } else if (params.data.columnHeader === "Client") {
    const colId = params.column.getId();
    const previousRowNode = params.api.getRowNode("cleared_by");
    const previousCellValue = previousRowNode.data[colId];
    const clientsByCompany =
      previousCellValue !== null
        ? params.data.clientsData
            .filter((client) => client.company === previousCellValue)
            .sort((a, b) => a.name.localeCompare(b.name))
        : [];
    return {
      values:
        clientsByCompany.length > 0 ? clientsByCompany.map((c) => c.name) : [],
      searchDebounceDelay: 1000,
    };
  } else if (params.data.columnHeader === "Trader") {
    const colId = params.column.getId();
    const previousRowNodeCompany = params.api.getRowNode("cleared_by");
    const previousRowNodeClient = params.api.getRowNode("client");
    const previousCellValueCompany = previousRowNodeCompany.data[colId];
    const previousCellValueClient = previousRowNodeClient.data[colId];
    const tradersByClient =
      previousCellValueCompany !== null && previousCellValueClient !== null
        ? params.data.clientsData.clients
            .filter(
              (client) =>
                client.company.name === previousCellValueCompany &&
                client.name === previousCellValueClient,
            )
            .sort((a, b) => a.name.localeCompare(b.name))
        : [];
    return {
      values:
        tradersByClient.length > 0
          ? tradersByClient[0].client_traders.map((c) => c.name)
          : [],
    };
  } else if (params.data.columnHeader === "Clearance Account") {
    const colId = params.column.getId();
    const previousRowNodeCompany = params.api.getRowNode("cleared_by");
    const previousRowNodeClient = params.api.getRowNode("client");
    const previousRowNodeTrader = params.api.getRowNode("trader");
    const previousRowNodeClearanceAcc =
      params.api.getRowNode("clearance_account");

    const previousCellValueCompany = previousRowNodeCompany.data[colId];
    const previousCellValueClient = previousRowNodeClient.data[colId];
    const previousCellValueTrader = previousRowNodeTrader.data[colId];
    const clientTradersObj =
      previousCellValueCompany !== null && previousCellValueClient !== null
        ? previousRowNodeTrader.data.clientsData.clients.filter(
            (client) =>
              client.company.name === previousCellValueCompany &&
              client.name === previousCellValueClient,
          )
        : [];
    if (clientTradersObj.length > 0) {
      const traderObj = clientTradersObj[0]?.client_traders.filter(
        (c) => c.name === previousCellValueTrader,
      );
      if (traderObj.length > 0) {
        return {
          values: previousRowNodeClearanceAcc.data.clearanceDataObj[colId],
        };
      } else return { values: [] };
    } else {
      return {
        values: [],
      };
    }
  } else if (params.data.columnHeader === "Floor Broker") {
    return {
      values: ["Mando", "XFA", "TJM"],
    };
  } else return undefined;
};
