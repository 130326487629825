import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Collapse,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { useStyles } from "../style/derivsClientsStyle";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

export const TradersContainer = ({
  pickedTraders,
  setPickedClientByTrader,
  setPickedTraderObj,
}) => {
  const classes = useStyles();
  const [tradersList, setTradersList] = useState([]);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    if (pickedTraders !== null) {
      setSelectedId(null);
      setTradersList(pickedTraders);
    }
  }, [pickedTraders]);

  const onTraderPick = (trader) => {
    setSelectedId(trader.id);
    setPickedClientByTrader(trader.client_id);
    setPickedTraderObj(trader);
  };
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      className={classes.tradersListContainer}
    >
      <Grid item xs={11}>
        <Collapse in={true} timeout="auto" unmountOnExit>
          <List
            component="div"
            disablePadding
            className={classes.itemsListTraders}
          >
            {tradersList
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((tr,i) => {
                return (
                  <Grid className={classes.itemListContainer} key={i}>
                    <ListItem
                      button
                      onClick={() => onTraderPick(tr)}
                      selected={selectedId === tr.id}
                      className={classes.listItemSelected}
                    >
                      <ListItemText
                        className={classes.listItemText}
                        primary={tr.name
                          .toLowerCase()
                          .split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.substring(1),
                          )
                          .join(" ")}
                      />
                      {selectedId === tr.id ? (
                        <PlayArrowIcon className={classes.playIcon} />
                      ) : null}
                    </ListItem>
                  </Grid>
                );
              })}
          </List>
        </Collapse>
      </Grid>
    </Grid>
  );
};
