export const cellEditorSelector = (params) => {
  if (
    params.data.columnHeader.toLowerCase() === "buyer" ||
    params.data.columnHeader.toLowerCase() === "seller"||
    params.data.columnHeader.toLowerCase() === "buyer le" ||
    params.data.columnHeader.toLowerCase() === "seller le"
  ) {
    return { component: "agRichSelectCellEditor" };
  } else return undefined;
};
