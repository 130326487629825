import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  makeStyles,
  ThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import * as fiActions from "../../../store/fi/fiSlice";
import { convertDate } from "./convertDate";

const theme = createTheme({
  palette: {
    type: "dark",
  },
});

export default function MaterialUIPickers(values) {
  const dispatch = useDispatch();
  const tradeToEdit = useSelector(
    (state) => state.fiSlice?.selectedTradeToEdit
  );
  const [value, setValue] = useState(new Date());
  const [color, setColor] = useState(false);

  useEffect(() => {
    if (values.values.key === "settlementDate") {
      let daysToAdd = 0;
      const dayNum = value.getDay();
      switch (dayNum) {
        case 0:
          daysToAdd = 2;
          break;
        case 1:
          daysToAdd = 2;
          break;
        case 2:
          daysToAdd = 2;
          break;
        case 3:
          daysToAdd = 2;
          break;
        case 4:
          daysToAdd = 4;
          break;
        case 5:
          daysToAdd = 4;
          break;
        case 6:
          daysToAdd = 3;
          break;

        default:
          break;
      }
      setValue(new Date(value.setDate(value.getDate() + daysToAdd)));
    }
    const obj = convertDate(value, values.values.key);
    dispatch(fiActions.editNewTradeForm(obj));
  }, []);

  useEffect(() => {
    if (tradeToEdit !== undefined && values.values.key === "tradeDate") {
      setColor(true);
      setValue(new Date(tradeToEdit.trade_date));
      const obj = { key: "tradeDate", value: tradeToEdit.trade_date };
      dispatch(fiActions.editNewTradeForm(obj));
    } else if (
      tradeToEdit !== undefined &&
      values.values.key === "settlementDate"
    ) {
      setColor(true);
      setValue(new Date(tradeToEdit.settlement_date));
      const obj = { key: "settlementDate", value: tradeToEdit.settlement_date };
      dispatch(fiActions.editNewTradeForm(obj));
    }
  }, []);

  const useStyles = makeStyles((theme) => ({
    datePicker: {
      "& .MuiInputBase-root": {
        margin: 0,
        height: "32px",
        width: "221px",
        marginTop: "25px",
        color: `${color ? "#FFFFFF" : "#868DA2"}`,
        borderRadius: "4px",
        textAlign: "left",
      },

      "& .MuiInputLabel-root": {
        color: "#868DA2",
        position: "absolute",
        top: "24px",
      },

      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #2692FF",
        borderRadius: "4px",
      },

      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #686B76",
        borderRadius: "4px",
      },

      "&:hover .MuiInputLabel-root": {
        color: "#2692FF",
      },
      "& .MuiInputBase-root:focus .MuiInputBase-input:focus": {
        borderColor: "red",
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#2692FF",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input.Mui-focused":
        {
          color: "#FFFFFF",
          border: "4px solid 2692FF ",
        },
      "& .MuiSvgIcon-root": {
        color: "#2692FF",
        position: "relative",
        left: "160px",
      },
      "& .MuiIconButton-root:hover": {
        backgroundColor: "transparent",
      },
      "& .MuiInputBase-input:focus": {
        width: "120px",
        position: "absolute",
        left: "8px",
      },
      "& .MuiInputBase-input": {
        width: "120px",
        position: "absolute",
        left: "8px",
      },
    },
  }));
  const classes = useStyles();

  const handleChange = (newValue) => {
    if (!color) {
      setColor(!color);
    }
    setValue(newValue);
    const obj = convertDate(newValue, values.values.key);
    dispatch(fiActions.editNewTradeForm(obj));
  };

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack spacing={3}>
          <KeyboardDatePicker
            format="dd-MMM-yyyy"
            size="small"
            className={classes.datePicker}
            autoOk
            variant="inline"
            inputVariant="outlined"
            label={values.values.label}
            value={value}
            InputAdornmentProps={{ position: "start" }}
            onChange={(date) => handleChange(date)}
          />
        </Stack>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
