export let ws = null;
let messages = [];
const token = sessionStorage.getItem("token");

export const connectWS = (token, dispatch, rfqSlice) => {
  if ((ws === null || ws.readyState === 3) && token) {
    ws = new WebSocket(`${process.env.REACT_APP_WS_URL}?token=${token}`);
    console.log("Trying to connect to websocket");
    dispatch(rfqSlice.updateSocketStatus("connecting"));
    const interval = setInterval(() => {
      if (ws.readyState === 1) {
        console.log("Connection to websocket is open");
        dispatch(rfqSlice.updateSocketStatus("success"));
        clearInterval(interval);
      }
      if (ws.readyState === 3) {
        console.log("Connection is to websocket lost");
        ws = new WebSocket(`${process.env.REACT_APP_WS_URL}?token=${token}`);
        dispatch(rfqSlice.updateSocketStatus("error"));
      }
      if (ws.readyState === 0) {
        console.log("Trying to reconnect to socket");
        dispatch(rfqSlice.updateSocketStatus("connecting"));
      }
    }, 2000);

    messages = [];

    ws.onopen = () => {
      if (ws.readyState === 1) {
        messages.forEach((message) => {
          ws.send(JSON.stringify(message));
        });
      }
    };
    ws.onmessage = (event) => {
      let parsedEvent = JSON.parse(event.data);

      if (parsedEvent.code === 401) {
        console.log({ type: "user_expired_token" });
        token = null;
      }

      if (parsedEvent.size !== 0) {
        postMessage(parsedEvent);
      }
    };
    ws.onclose = (event) => {
      if (!event.wasClean) {
        console.log("connection_lost");
        dispatch(rfqSlice.updateSocketStatus("error"));
        ws = null;
        if (ws === null && token) {
          const intervalId = setInterval(() => {
            connectWS(token, dispatch, rfqSlice);
            if (ws !== null) {
              clearInterval(intervalId);
            }
          }, 3000);
        }
      }
    };
    ws.onerror = (err) => {
      if (ws.code !== 4000) {
        setTimeout(function () {
          dispatch(rfqSlice.updateSocketStatus("error"));
          connectWS(token, dispatch, rfqSlice);
        }, 2000);
      }
    };
    // ws.close();
  }
};

export const sendEvent = (data) => {
  messages.push(data);
  if (ws !== null) {
    if (ws.readyState !== 1) {
      messages.push(data);
    } else {
      ws.send(JSON.stringify(data));
    }
    ws.onopen = () => {
      messages.forEach((message) => {
        ws.send(JSON.stringify(message));
      });
    };

    ws.onmessage = (event) => {
      if (event.data.size !== 0) {
        const response = JSON.parse(event.data);
        postMessage(response);
      }
    };

    ws.onerror = (err) => {
      if (ws.code !== 4000) {
        setTimeout(function () {
          connectWS(token.replace("Bearer ", ""));
        }, 2000);
      }
    };
    ws.onclose = (err) => {
      if (ws.code !== 4000) {
        setTimeout(function () {
          connectWS(token.replace("Bearer ", ""));
        }, 2000);
      }
    };
  } else {
    connectWS(token.replace("Bearer ", ""));
  }
};

export const closeWS = (dispatch, rfqSlice) => {
  if (ws) {
    if (ws.readyState === 1) {
      ws.send(JSON.stringify({ type: "close-connection" }));
      ws.close();
      ws = null;
      dispatch(rfqSlice.updateSocketStatus("error"));
    }
  }
};


