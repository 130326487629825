import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import { END_POINT } from "../../../utils";
import { getUserList } from "../utils/apiCalls";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { EntitlementsCheckbox } from "../components/EntitlementsCheckbox";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    backgroundColor: "transparent", // Set the background to transparent
    border: "1px solid gray",
  },
  tableCell: {
    color: "white", // Set text color to white
    borderBottom: "1px solid gray", // Set all borders to gray
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

export const UsersList = () => {
  const classes = useStyles();
  const [usersList, setUsersList] = useState([]);

  const generateTableCells = (row) => {
    const cells = [];
    for (const val of Object.entries(row)) {
      if (val[0] !== "entitlement") {
        cells.push(
          <TableCell component="th" scope="row" className={classes.tableCell}>
            {val[1]}
          </TableCell>,
        );
      } else if (val[0] === "entitlement") {
        cells.push(
          <TableCell component="th" scope="row" className={classes.tableCell}>
            {val[1].map((entitlement) => {
              return (
                <EntitlementsCheckbox
                  entitlement={entitlement}
                  key={entitlement}
                />
              );
            })}
          </TableCell>,
        );
      }
    }
    return cells;
  };

  useEffect(() => {
    getUserList(setUsersList);
  }, []);

  useEffect(() => {
    console.log({ usersList });
  }, [usersList]);

  return (
    <>
      {usersList.length > 0 ? (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {Object.keys(usersList[0]).map((cell) => {
                  return (
                    <TableCell className={classes.tableCell}>
                      {cell
                        .replace(/([A-Z])/g, " $1")
                        .trim()
                        .toUpperCase()}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {usersList.map((row) => (
                <TableRow key={row.name}>{generateTableCells(row)}</TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </>
  );
};
