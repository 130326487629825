import React, { useEffect, useState } from "react";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { TableByTab } from "../../dataFeed/components/TableByTab";
import { getMarketMakers } from "../../dataFeed/utils/apiCalls";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
    backgroundColor: "transparent", // Set the background color to transparent
    boxShadow: "none",
  },
}));

export const MarketMakerPopover = ({ ticker }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [marketMakers, setMarketMakers] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  useEffect(() => {
    getMarketMakers(ticker, setMarketMakers);
  }, []);

  return (
    <div>
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {ticker}
      </Typography>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {/* <Typography>I use Popover.</Typography> */}
        <TableByTab
          isPopover={true}
          tabValue={"marketMaker"}
          tickerParticipates={[]}
          marketMakers={marketMakers}
        />
      </Popover>
    </div>
  );
};
