import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  stringInput: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#686B76",
        background: "transparent",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
      "& input": {
        color: "white",
        paddingTop: "8px", // Keep label aligned properly
        paddingBottom: "8px",
      },
      "& input:-webkit-autofill": {
        "-webkit-box-shadow": "0 0 0 100px black inset",
        "-webkit-text-fill-color": "white",
      },
    },
    "& .MuiFormLabel-root": {
      color: "white",
      transform: "translate(14px, 11px) scale(1)", // Adjust label positioning
    },
    "& .MuiInputLabel-shrink": {
      color: "white",
      transform: "translate(14px, -6px) scale(0.75)", // Adjust label positioning when shrunk
    },
    "& .MuiMenu-paper": {
      background: "transparent !important",
      margin: "0 !important", // Set the menu background to transparent
    },
    "& .MuiMenuItem-root": {
      color: "white !important", // Set the text color to white for menu items
    },
  },
  typography: {
    color: "#828282",
    fontSize: "26px",
    marginBottom: "20px",
  },

  selectRoot: {
    "& .MuiOutlinedInput-root": {
      color: "white",
      width: "165px",
      "& fieldset": {
        borderColor: "#686B76",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
      "& input": {
        color: "white",
        paddingTop: "8px", // Keep label aligned properly
        paddingBottom: "8px",
      },
      "& input:-webkit-autofill": {
        "-webkit-box-shadow": "0 0 0 100px black inset",
        "-webkit-text-fill-color": "white",
      },
      "& .MuiSelect-icon": {
        color: "white",
        top: "calc(50% - 10px)", // Adjust the marginTop to vertically center the icon
      },
    },
    "& .MuiFormLabel-root": {
      color: "white",
      transform: "translate(14px, 12px) scale(1)", // Adjust label positioning
    },
    "& .MuiInputLabel-shrink": {
      color: "white",
      transform: "translate(14px, -6px) scale(0.75)", // Adjust label positioning when shrunk
    },
    "& .MuiMenu-paper": {
      background: "transparent !important",
      margin: "0 !important", // Set the menu background to transparent
    },
    "& .MuiMenuItem-root": {
      color: "white !important", // Set the text color to white for menu items
    },
  },

  formControl: {
    "& label": {
      color: "#686B76", // Border color when not focused
    },
    "& label.Mui-focused": {
      color: "white", // Label color when focused
    },
  },
  formLabel: {
    "& .MuiFormLabel-root": {
      color: "#686B76 !important",
    },
  },
  rootTabs: {
    flexGrow: 1,
  },
  tabs: {
    backgroundColor: "transparent",
    color: "white",
    borderBottom: "1px solid white",
  },
  tabPanel: {
    backgroundColor: "transparent",
    color: "white",
  },
  appBar: {
    backgroundColor: "transparent",
  },
  notSelectedTab: {
    // borderRight: "1px solid #686B76",
    "&::before": {
      content: '""',
      position: "absolute",
      right: 0,
      top: "10%", // Adjust the top value to position the border
      bottom: "10%", // Adjust the bottom value to control the height
      borderLeft: "1px solid #686B76",
      width: 0,
    },
  },
  selectedTab: {
    backgroundColor: "#2692FF",
  },
  indicator: {
    backgroundColor: "white",
  },
}));
