import { getClearanceData } from "./apiCalls";
import {
  getFloorBrokerRate,
  pnlRowsGenerator,
  resetField,
  resetFields,
  updateFields,
} from "./calculationUtils";

export const onCellEditReq = async (
  e,
  gridApi,
  rowData,
  setRowData,
  makorStaticData,
  parsedBookingText,
  order,
) => {
  const rowIndex = e.node.rowIndex;
  const colId = e.column.colId;
  const value = e.value;
  const newData = [...rowData];
  const sideIndex = newData.findIndex((r) => r.id === "side");
  const legFeeRateIndex = newData.findIndex((r) => r.id === "leg_fee_rate");

  const hedgeFeeRateIndex = newData.findIndex((r) => r.id === "hedge_fee_rate");
  const commissionIndex = newData.findIndex((r) => r.id === "commission");
  const initiatorIndex = newData.findIndex((r) => r.id === "initiator");
  const initiationFeeIndex = newData.findIndex(
    (r) => r.id === "initiation_fee",
  );
  const marketMakerFeeIndex = newData.findIndex(
    (r) => r.id === "market_maker_fee",
  );
  const initiationIndex = newData.findIndex((r) => r.id === "initiation");
  const isMarketMakerIndex = newData.findIndex(
    (r) => r.id === "is_market_maker",
  );
  const marketMakerIndex = newData.findIndex((r) => r.id === "market_maker");
  const floorBrokerFeeIndex = newData.findIndex(
    (r) => r.id === "floor_broker_fee",
  );
  const executionFeeIndex = newData.findIndex((r) => r.id === "execution_fee");
  const crossedFeeRateIndex = newData.findIndex(
    (r) => r.id === "crossed_fee_rate",
  );
  const notCrossedFeeRateIndex = newData.findIndex(
    (r) => r.id === "not_crossed_fee_rate",
  );

  if (
    rowIndex >= 0 &&
    // rowIndex < rowData.length &&
    colId in rowData[rowIndex]
  ) {
    newData[rowIndex] = { ...newData[rowIndex], [colId]: value }; // Update the specific value
    if (e.data.columnHeader === "Cleared By") {
      resetFields({
        columnHeader: "cleared_by",
        fieldsToReset: [
          "trader",
          "client",
          "initiator",
          "leg_fee_rate",
          "commission",
          "execution_fee",
          "initiation",
          "marker_maker",
          "net",
          "pnl",
        ],
        setRowData,
        rowIndex,
        colId,
        value,
        newData,
        e,
        initiationIndex,
        initiatorIndex,
      });
    }
    if (e.data.columnHeader === "Client") {
      const column = e.colDef.field;
      const previousRowNodeClient = gridApi.getRowNode("cleared_by");
      const company = previousRowNodeClient.data[`${column}`];
      const client = e.value;
      const clientObj =
        company !== null && client !== null
          ? makorStaticData.clients
              .filter((c) => c.company.name === company && c.name === client)
              .sort((a, b) => a.name.localeCompare(b.name))
          : "";
      updateFields({
        rowToUpdate: "client",
        newData,
        setRowData,
        colId,
        clientObj,
        order,
        parsedBookingText,
        legFeeRateIndex,
        hedgeFeeRateIndex,
        initiationFeeIndex,
        initiatorIndex,
        isMarketMakerIndex,
        marketMakerFeeIndex,
        floorBrokerFeeIndex,
        executionFeeIndex,
        makorStaticData,
        commissionIndex,
        marketMakerIndex,
      });
    } else if (e.data.columnHeader === "Trader") {
      const column = e.colDef.field;
      const previousRowNodeCompany = gridApi.getRowNode("cleared_by");
      const previousRowNodeClient = gridApi.getRowNode("client");
      const company = previousRowNodeCompany.data[`${column}`];
      const client = previousRowNodeClient.data[`${column}`];
      const trader = e.value;

      const clientObj =
        company !== undefined && client !== undefined && trader !== undefined
          ? makorStaticData.clients.filter(
              (c) => c.company.name === company && c.name === client,
            )
          : [];
      const clientTraderObj = clientObj[0].client_traders.filter(
        (trd) => trd.name === trader,
      );

      const updatedArrWithPnl = await pnlRowsGenerator(
        clientObj,
        newData,
        parsedBookingText,
        colId,
        makorStaticData,
        clientTraderObj,
      );
      const res = await getClearanceData(
        clientTraderObj[0].client_id,
        clientTraderObj[0].id,
      );
      setRowData(
        cleanEmptyPnlRows(updatedArrWithPnl).map((row) => {
          if (row.id === "clearance_account") {
            const obj =
              row.clearanceDataObj !== undefined
                ? { ...row.clearanceDataObj, [colId]: res }
                : { [colId]: res };
            return {
              ...row,
              [colId]: res.length > 0 ? res[0] : null,
              clearanceDataObj: obj,
            };
          } else return { ...row };
        }),
      );
    } else if (e.data.columnHeader === "Clearance Account") {
      newData[rowIndex] = { ...newData[rowIndex], [colId]: value };
      setRowData(newData);
    } else if (
      e.data.columnHeader === "Crossed Fee Rate" ||
      e.data.columnHeader === "Not Crossed Fee Rate"
    ) {
      let floorBrokerFeeRow = {};
      let currentInitiatorExec = null;
      let initiationRow = newData[initiationIndex];
      const crossedFeeRate =
        e.data.columnHeader === "Crossed Fee Rate"
          ? value
          : newData[crossedFeeRateIndex][colId];
      const notCrossedFeeRate =
        e.data.columnHeader === "Not Crossed Fee Rate"
          ? value
          : newData[notCrossedFeeRateIndex][colId];
      if (!isNaN(value)) {
        const updatedData = newData.map((row) => {
          if (row.id === "initiator") {
            const execKeys = Object.keys(row).filter((key) =>
              key.startsWith("exec"),
            );
            execKeys.forEach((exec) => {
              if (row[exec]) {
                currentInitiatorExec = exec;
              }
            });

            return row;
          } else if (
            e.data.columnHeader === "Crossed Fee Rate" &&
            row.id === "crossed_fee_rate"
          ) {
            const execKeys = Object.keys(row).filter((key) =>
              key.startsWith("exec"),
            );
            for (const key of execKeys) {
              if (row.hasOwnProperty(key)) {
                row = { ...row, [`${key}`]: Number(value) };
              }
            }
            return { ...row };
          } else if (
            e.data.columnHeader === "Not Crossed Fee Rate" &&
            row.id === "not_crossed_fee_rate"
          ) {
            const execKeys = Object.keys(row).filter((key) =>
              key.startsWith("exec"),
            );
            for (const key of execKeys) {
              if (row.hasOwnProperty(key)) {
                row = { ...row, [`${key}`]: Number(value) };
              }
            }
            return { ...row };
          } else if (row.id === "floor_broker_fee") {
            let totalQuantityBuy = 0;
            let totalQuantitySell = 0;
            // Aggregate total quantities for options and hedges across all clients
            let execTotalQuantityById = new Map();
            parsedBookingText.executions.forEach((exec, index) => {
              let totalQuantity = 0;
              exec.option.forEach((option) => {
                if (exec.side.toLowerCase() === "b") {
                  totalQuantityBuy += option.quantity;
                  totalQuantity += option.quantity;
                } else if (exec.side.toLowerCase() === "s") {
                  totalQuantitySell += option.quantity;
                  totalQuantity += option.quantity;
                }
              });
              exec.hedge.forEach((hedge) => {
                if (exec.side.toLowerCase() === "b") {
                  totalQuantityBuy += hedge.quantity;
                  totalQuantity += hedge.quantity;
                } else if (exec.side.toLowerCase() === "s") {
                  totalQuantitySell += hedge.quantity;
                  totalQuantity += hedge.quantity;
                }
              });

              execTotalQuantityById.set(index, totalQuantity);
            });

            const totalCrossed = Math.min(totalQuantityBuy, totalQuantitySell);
            let availableCrossed = totalCrossed;
            const totalNotCrossed = Math.abs(
              totalQuantityBuy - totalQuantitySell,
            );

            let sideNotCrossed = null,
              sideCrossed = null;

            if (totalQuantityBuy >= totalQuantitySell) {
              sideNotCrossed = "b";
              sideCrossed = "s";
            } else {
              sideNotCrossed = "s";
              sideCrossed = "b";
            }

            const initiatorIndex =
              currentInitiatorExec !== null
                ? Number(currentInitiatorExec.replace("exec", "")) - 1
                : null;
            if (initiatorIndex !== null) {
              const initiatorSide = rowData[sideIndex][colId];

              if (sideNotCrossed === initiatorSide.toLowerCase()) {
                console.log("sideNotCrossed === initiatorSide");
                const initiatorTotalQuantity =
                  execTotalQuantityById.get(initiatorIndex);
                if (availableCrossed >= initiatorTotalQuantity) {
                  console.log("availableCrossed >= initiatorTotalQuantity");
                  const crossedQuantity = Math.min(
                    availableCrossed,
                    initiatorTotalQuantity,
                  );
                  availableCrossed -= crossedQuantity;
                  const initiatorFloorBrokerFee =
                    crossedFeeRate * crossedQuantity;
                  row = {
                    ...row,
                    [colId]: Number(initiatorFloorBrokerFee.toFixed(2)),
                    floorBrokerFeeRate: {
                      ...row.floorBrokerFeeRate,
                      [initiatorIndex]: (
                        Number(initiatorFloorBrokerFee) / initiatorTotalQuantity
                      ).toFixed(2),
                    },
                  };

                  parsedBookingText.executions.forEach((exec, index) => {
                    if (index !== initiatorIndex) {
                      if (
                        exec.side.toLowerCase() === initiatorSide.toLowerCase()
                      ) {
                        const execQuantity = execTotalQuantityById.get(index);
                        const crossedQuantity = Math.min(
                          availableCrossed,
                          execQuantity,
                        );
                        const notCrossedQuantity = Math.abs(
                          execQuantity - crossedQuantity,
                        );
                        availableCrossed -= crossedQuantity;
                        const floorBrokerFee =
                          crossedFeeRate * crossedQuantity +
                          notCrossedFeeRate * notCrossedQuantity;
                        row = {
                          ...row,
                          [`exec${index + 1}`]: Number(
                            floorBrokerFee.toFixed(2),
                          ),
                          floorBrokerFeeRate: {
                            ...row.floorBrokerFeeRate,
                            [index]: (
                              Number(floorBrokerFee) / execQuantity
                            ).toFixed(2),
                          },
                        };
                      } else if (exec.side !== initiatorSide) {
                        const execQuantity = execTotalQuantityById.get(index);
                        const floorBrokerFee = crossedFeeRate * execQuantity;
                        row = {
                          ...row,
                          [`exec${index + 1}`]: Number(
                            floorBrokerFee.toFixed(2),
                          ),
                          floorBrokerFeeRate: {
                            ...row.floorBrokerFeeRate,
                            [index]: (
                              Number(floorBrokerFee) / execQuantity
                            ).toFixed(2),
                          },
                        };
                      }
                    }
                  });
                } else if (availableCrossed < initiatorTotalQuantity) {
                  console.log("availableCrossed < initiatorTotalQuantity");
                  const crossedQuantity = Math.min(
                    availableCrossed,
                    initiatorTotalQuantity,
                  );
                  const notCrossedQuantity = Math.abs(
                    initiatorTotalQuantity - crossedQuantity,
                  );
                  availableCrossed -= crossedQuantity;
                  const initiatorFloorBrokerFee =
                    crossedFeeRate * crossedQuantity +
                    notCrossedFeeRate * notCrossedQuantity;
                  //initiator floor broker fee
                  row = {
                    ...row,
                    [colId]: Number(initiatorFloorBrokerFee.toFixed(2)),
                    floorBrokerFeeRate: {
                      ...row.floorBrokerFeeRate,
                      [initiatorIndex]: (
                        Number(initiatorFloorBrokerFee) / initiatorTotalQuantity
                      ).toFixed(2),
                    },
                  };

                  //rest of the clients on that side
                  parsedBookingText.executions.forEach((exec, index) => {
                    if (index !== initiatorIndex) {
                      if (
                        exec.side.toLowerCase() === initiatorSide.toLowerCase()
                      ) {
                        const execQuantity = execTotalQuantityById.get(index);
                        const floorBrokerFee = notCrossedFeeRate * execQuantity;
                        row = {
                          ...row,
                          [`exec${index + 1}`]: Number(
                            floorBrokerFee.toFixed(2),
                          ),
                          floorBrokerFeeRate: {
                            ...row.floorBrokerFeeRate,
                            [index]: (
                              Number(floorBrokerFee) / execQuantity
                            ).toFixed(2),
                          },
                        };
                      } else if (exec.side !== initiatorSide) {
                        const execQuantity = execTotalQuantityById.get(index);
                        const floorBrokerFee = crossedFeeRate * execQuantity;
                        row = {
                          ...row,
                          [`exec${index + 1}`]: Number(
                            floorBrokerFee.toFixed(2),
                          ),
                          floorBrokerFeeRate: {
                            ...row.floorBrokerFeeRate,
                            [index]: (
                              Number(floorBrokerFee) / execQuantity
                            ).toFixed(2),
                          },
                        };
                      }
                    }
                  });
                }
              } else if (sideNotCrossed !== initiatorSide.toLowerCase()) {
                console.log("sideNotCrossed !== initiatorSide");

                parsedBookingText.executions.forEach((exec, index) => {
                  if (
                    exec.side.toLowerCase() === sideNotCrossed.toLowerCase()
                  ) {
                    const execQuantity = execTotalQuantityById.get(index);
                    const crossedQuantity = Math.min(
                      availableCrossed,
                      execQuantity,
                    );
                    const notCrossedQuantity = Math.abs(
                      execQuantity - crossedQuantity,
                    );
                    availableCrossed -= crossedQuantity;
                    const floorBrokerFee =
                      crossedFeeRate * crossedQuantity +
                      notCrossedFeeRate * notCrossedQuantity;
                    row = {
                      ...row,
                      [`exec${index + 1}`]: Number(floorBrokerFee.toFixed(2)),
                      floorBrokerFeeRate: {
                        ...row.floorBrokerFeeRate,
                        [index]: (
                          Number(floorBrokerFee) / execQuantity
                        ).toFixed(2),
                      },
                    };
                  } else if (exec.side !== sideNotCrossed) {
                    const execQuantity = execTotalQuantityById.get(index);
                    const floorBrokerFee = crossedFeeRate * execQuantity;
                    row = {
                      ...row,
                      [`exec${index + 1}`]: Number(floorBrokerFee.toFixed(2)),
                      floorBrokerFeeRate: {
                        ...row.floorBrokerFeeRate,
                        [index]: (
                          Number(floorBrokerFee) / execQuantity
                        ).toFixed(2),
                      },
                    };
                  }
                });
              }
            } else if (initiatorIndex === null) {
              console.log("their is no initiator");
              parsedBookingText.executions.forEach((exec, index) => {
                if (exec.side.toLowerCase() === sideNotCrossed.toLowerCase()) {
                  const execQuantity = execTotalQuantityById.get(index);
                  const crossedQuantity = Math.min(
                    availableCrossed,
                    execQuantity,
                  );
                  const notCrossedQuantity = Math.abs(
                    execQuantity - crossedQuantity,
                  );
                  availableCrossed -= crossedQuantity;
                  const floorBrokerFee =
                    crossedFeeRate * crossedQuantity +
                    notCrossedFeeRate * notCrossedQuantity;
                  row = {
                    ...row,
                    [`exec${index + 1}`]: Number(floorBrokerFee.toFixed(2)),
                    floorBrokerFeeRate: {
                      ...row.floorBrokerFeeRate,
                      [index]: (Number(floorBrokerFee) / execQuantity).toFixed(
                        2,
                      ),
                    },
                  };
                } else if (exec.side !== sideNotCrossed) {
                  const execQuantity = execTotalQuantityById.get(index);
                  const floorBrokerFee = crossedFeeRate * execQuantity;
                  row = {
                    ...row,
                    [`exec${index + 1}`]: Number(floorBrokerFee.toFixed(2)),
                    floorBrokerFeeRate: {
                      ...row.floorBrokerFeeRate,
                      [index]: (Number(floorBrokerFee) / execQuantity).toFixed(
                        2,
                      ),
                    },
                  };
                }
              });
            }
            floorBrokerFeeRow = { ...row };
            return row;
          } else if (row.id === "initiation") {
            const execKeys = Object.keys(row).filter(
              (key) => key.startsWith("exec") && key !== currentInitiatorExec,
            );
            if (currentInitiatorExec !== null) {
              let initiationSum = 0;
              execKeys.forEach((exec) => {
                const execInitiation = Number(
                  (newData[commissionIndex][exec] - floorBrokerFeeRow[exec]) *
                    newData[initiationFeeIndex][exec],
                );

                row = {
                  ...row,
                  [`${exec}`]: Number(
                    (newData[commissionIndex][exec] - floorBrokerFeeRow[exec]) *
                      newData[initiationFeeIndex][exec],
                  ),
                };
                initiationRow = {
                  ...initiationRow,
                  [`${exec}`]: Number(
                    (newData[commissionIndex][exec] - floorBrokerFeeRow[exec]) *
                      newData[initiationFeeIndex][exec],
                  ),
                };
                initiationSum += Number(execInitiation.toFixed(2));
              });
              initiationRow = {
                ...initiationRow,
                [currentInitiatorExec]: initiationSum,
              };
              row = { ...row, [currentInitiatorExec]: initiationSum };

              return { ...row };
            } else {
              execKeys.forEach((exec) => {
                row = {
                  ...row,
                  [`${exec}`]: Number(
                    (newData[commissionIndex][exec] - floorBrokerFeeRow[exec]) *
                      newData[initiationFeeIndex][exec],
                  ),
                };
                initiationRow = {
                  ...initiationRow,
                  [`${exec}`]: Number(
                    (newData[commissionIndex][exec] - floorBrokerFeeRow[exec]) *
                      newData[initiationFeeIndex][exec],
                  ),
                };
              });
              return { ...row };
            }
          } else if (row.id === "market_maker") {
            const execKeys = Object.keys(row).filter((key) =>
              key.startsWith("exec"),
            );
            execKeys.forEach((exec) => {
              if (
                !newData[initiatorIndex][exec] &&
                newData[isMarketMakerIndex][exec]
              ) {
                row = {
                  ...row,
                  [`${exec}`]:
                    (newData[commissionIndex][exec] - floorBrokerFeeRow[exec]) *
                    newData[marketMakerFeeIndex][exec],
                };
              } else {
                row = { ...row, [`${exec}`]: 0 };
              }
            });
            return { ...row };
          } else if (row.id === "net") {
            const execKeys = Object.keys(row).filter((key) =>
              key.startsWith("exec"),
            );
            for (const key of execKeys) {
              if (row.hasOwnProperty(key)) {
                const netValue =
                  newData[commissionIndex][key] -
                  floorBrokerFeeRow[key] -
                  newData[executionFeeIndex][key] +
                  (key === currentInitiatorExec
                    ? initiationRow[key]
                    : -initiationRow[key]) -
                  newData[marketMakerIndex][key];
                row = { ...row, [`${key}`]: netValue };
              }
            }
            return { ...row };
          } else {
            return row;
          }
        });

        setRowData(updatedData);
      }
    } else if (e.data.columnHeader === "Floor Broker") {
      const { crossedFeeRate, notCrossedFeeRate } = getFloorBrokerRate(
        value,
        makorStaticData,
        order,
      );
      let floorBrokerFeeRow = {};
      let currentInitiatorExec = null;
      let initiationRow = newData[initiationIndex];

      const updatedData = newData.map((row) => {
        if (row.id === "initiator") {
          const execKeys = Object.keys(row).filter((key) =>
            key.startsWith("exec"),
          );
          execKeys.forEach((exec) => {
            if (row[exec]) {
              currentInitiatorExec = exec;
            }
          });

          return row;
        } else if (row.id === "floor_broker") {
          const execKeys = Object.keys(row).filter((key) =>
            key.startsWith("exec"),
          );
          for (const key of execKeys) {
            if (row.hasOwnProperty(key)) {
              row = { ...row, [`${key}`]: value };
            }
          }
          return { ...row };
        } else if (row.id === "crossed_fee_rate") {
          const execKeys = Object.keys(row).filter((key) =>
            key.startsWith("exec"),
          );
          for (const key of execKeys) {
            if (row.hasOwnProperty(key)) {
              row = { ...row, [`${key}`]: Number(crossedFeeRate) };
            }
          }
          return { ...row };
        } else if (row.id === "not_crossed_fee_rate") {
          const execKeys = Object.keys(row).filter((key) =>
            key.startsWith("exec"),
          );
          for (const key of execKeys) {
            if (row.hasOwnProperty(key)) {
              row = { ...row, [`${key}`]: Number(notCrossedFeeRate) };
            }
          }
          return { ...row };
        } else if (row.id === "floor_broker_fee") {
          let totalQuantityBuy = 0;
          let totalQuantitySell = 0;
          // Aggregate total quantities for options and hedges across all clients
          let execTotalQuantityById = new Map();
          parsedBookingText.executions.forEach((exec, index) => {
            let totalQuantity = 0;
            exec.option.forEach((option) => {
              if (exec.side.toLowerCase() === "b") {
                totalQuantityBuy += option.quantity;
                totalQuantity += option.quantity;
              } else if (exec.side.toLowerCase() === "s") {
                totalQuantitySell += option.quantity;
                totalQuantity += option.quantity;
              }
            });
            exec.hedge.forEach((hedge) => {
              if (exec.side.toLowerCase() === "b") {
                totalQuantityBuy += hedge.quantity;
                totalQuantity += hedge.quantity;
              } else if (exec.side.toLowerCase() === "s") {
                totalQuantitySell += hedge.quantity;
                totalQuantity += hedge.quantity;
              }
            });

            execTotalQuantityById.set(index, totalQuantity);
          });

          const totalCrossed = Math.min(totalQuantityBuy, totalQuantitySell);
          let availableCrossed = totalCrossed;
          const totalNotCrossed = Math.abs(
            totalQuantityBuy - totalQuantitySell,
          );

          let sideNotCrossed = null,
            sideCrossed = null;

          if (totalQuantityBuy >= totalQuantitySell) {
            sideNotCrossed = "b";
            sideCrossed = "s";
          } else {
            sideNotCrossed = "s";
            sideCrossed = "b";
          }

          const initiatorIndex =
            currentInitiatorExec !== null
              ? Number(currentInitiatorExec.replace("exec", "")) - 1
              : null;
          if (initiatorIndex !== null) {
            const initiatorSide = rowData[sideIndex][colId];

            if (sideNotCrossed === initiatorSide.toLowerCase()) {
              console.log("sideNotCrossed === initiatorSide");
              const initiatorTotalQuantity =
                execTotalQuantityById.get(initiatorIndex);
              if (availableCrossed >= initiatorTotalQuantity) {
                console.log("availableCrossed >= initiatorTotalQuantity");
                const crossedQuantity = Math.min(
                  availableCrossed,
                  initiatorTotalQuantity,
                );
                availableCrossed -= crossedQuantity;
                const initiatorFloorBrokerFee =
                  crossedFeeRate * crossedQuantity;
                row = {
                  ...row,
                  [colId]: Number(initiatorFloorBrokerFee.toFixed(2)),
                  floorBrokerFeeRate: {
                    ...row.floorBrokerFeeRate,
                    [initiatorIndex]: (
                      Number(initiatorFloorBrokerFee) / initiatorTotalQuantity
                    ).toFixed(2),
                  },
                };

                parsedBookingText.executions.forEach((exec, index) => {
                  if (index !== initiatorIndex) {
                    if (
                      exec.side.toLowerCase() === initiatorSide.toLowerCase()
                    ) {
                      const execQuantity = execTotalQuantityById.get(index);
                      const crossedQuantity = Math.min(
                        availableCrossed,
                        execQuantity,
                      );
                      const notCrossedQuantity = Math.abs(
                        execQuantity - crossedQuantity,
                      );
                      availableCrossed -= crossedQuantity;
                      const floorBrokerFee =
                        crossedFeeRate * crossedQuantity +
                        notCrossedFeeRate * notCrossedQuantity;
                      row = {
                        ...row,
                        [`exec${index + 1}`]: Number(floorBrokerFee.toFixed(2)),
                        floorBrokerFeeRate: {
                          ...row.floorBrokerFeeRate,
                          [index]: (
                            Number(floorBrokerFee) / execQuantity
                          ).toFixed(2),
                        },
                      };
                    } else if (exec.side !== initiatorSide) {
                      const execQuantity = execTotalQuantityById.get(index);
                      const floorBrokerFee = crossedFeeRate * execQuantity;
                      row = {
                        ...row,
                        [`exec${index + 1}`]: Number(floorBrokerFee.toFixed(2)),
                        floorBrokerFeeRate: {
                          ...row.floorBrokerFeeRate,
                          [index]: (
                            Number(floorBrokerFee) / execQuantity
                          ).toFixed(2),
                        },
                      };
                    }
                  }
                });
              } else if (availableCrossed < initiatorTotalQuantity) {
                console.log("availableCrossed < initiatorTotalQuantity");
                const crossedQuantity = Math.min(
                  availableCrossed,
                  initiatorTotalQuantity,
                );
                const notCrossedQuantity = Math.abs(
                  initiatorTotalQuantity - crossedQuantity,
                );
                availableCrossed -= crossedQuantity;
                const initiatorFloorBrokerFee =
                  crossedFeeRate * crossedQuantity +
                  notCrossedFeeRate * notCrossedQuantity;
                //initiator floor broker fee
                row = {
                  ...row,
                  [colId]: Number(initiatorFloorBrokerFee.toFixed(2)),
                  floorBrokerFeeRate: {
                    ...row.floorBrokerFeeRate,
                    [initiatorIndex]: (
                      Number(initiatorFloorBrokerFee) / initiatorTotalQuantity
                    ).toFixed(2),
                  },
                };

                //rest of the clients on that side
                parsedBookingText.executions.forEach((exec, index) => {
                  if (index !== initiatorIndex) {
                    if (
                      exec.side.toLowerCase() === initiatorSide.toLowerCase()
                    ) {
                      const execQuantity = execTotalQuantityById.get(index);
                      const floorBrokerFee = notCrossedFeeRate * execQuantity;
                      row = {
                        ...row,
                        [`exec${index + 1}`]: Number(floorBrokerFee.toFixed(2)),
                        floorBrokerFeeRate: {
                          ...row.floorBrokerFeeRate,
                          [index]: (
                            Number(floorBrokerFee) / execQuantity
                          ).toFixed(2),
                        },
                      };
                    } else if (exec.side !== initiatorSide) {
                      const execQuantity = execTotalQuantityById.get(index);
                      const floorBrokerFee = crossedFeeRate * execQuantity;
                      row = {
                        ...row,
                        [`exec${index + 1}`]: Number(floorBrokerFee.toFixed(2)),
                        floorBrokerFeeRate: {
                          ...row.floorBrokerFeeRate,
                          [index]: (
                            Number(floorBrokerFee) / execQuantity
                          ).toFixed(2),
                        },
                      };
                    }
                  }
                });
              }
            } else if (sideNotCrossed !== initiatorSide.toLowerCase()) {
              console.log("sideNotCrossed !== initiatorSide");

              parsedBookingText.executions.forEach((exec, index) => {
                if (exec.side.toLowerCase() === sideNotCrossed.toLowerCase()) {
                  const execQuantity = execTotalQuantityById.get(index);
                  const crossedQuantity = Math.min(
                    availableCrossed,
                    execQuantity,
                  );
                  const notCrossedQuantity = Math.abs(
                    execQuantity - crossedQuantity,
                  );
                  availableCrossed -= crossedQuantity;
                  const floorBrokerFee =
                    crossedFeeRate * crossedQuantity +
                    notCrossedFeeRate * notCrossedQuantity;
                  row = {
                    ...row,
                    [`exec${index + 1}`]: Number(floorBrokerFee.toFixed(2)),
                    floorBrokerFeeRate: {
                      ...row.floorBrokerFeeRate,
                      [index]: (Number(floorBrokerFee) / execQuantity).toFixed(
                        2,
                      ),
                    },
                  };
                } else if (exec.side !== sideNotCrossed) {
                  const execQuantity = execTotalQuantityById.get(index);
                  const floorBrokerFee = crossedFeeRate * execQuantity;
                  row = {
                    ...row,
                    [`exec${index + 1}`]: Number(floorBrokerFee.toFixed(2)),
                    floorBrokerFeeRate: {
                      ...row.floorBrokerFeeRate,
                      [index]: (Number(floorBrokerFee) / execQuantity).toFixed(
                        2,
                      ),
                    },
                  };
                }
              });
            }
          } else if (initiatorIndex === null) {
            console.log("their is no initiator");
            parsedBookingText.executions.forEach((exec, index) => {
              if (exec.side.toLowerCase() === sideNotCrossed.toLowerCase()) {
                const execQuantity = execTotalQuantityById.get(index);
                const crossedQuantity = Math.min(
                  availableCrossed,
                  execQuantity,
                );
                const notCrossedQuantity = Math.abs(
                  execQuantity - crossedQuantity,
                );
                availableCrossed -= crossedQuantity;
                const floorBrokerFee =
                  crossedFeeRate * crossedQuantity +
                  notCrossedFeeRate * notCrossedQuantity;
                row = {
                  ...row,
                  [`exec${index + 1}`]: Number(floorBrokerFee.toFixed(2)),
                  floorBrokerFeeRate: {
                    ...row.floorBrokerFeeRate,
                    [index]: (Number(floorBrokerFee) / execQuantity).toFixed(2),
                  },
                };
              } else if (exec.side !== sideNotCrossed) {
                const execQuantity = execTotalQuantityById.get(index);
                const floorBrokerFee = crossedFeeRate * execQuantity;
                row = {
                  ...row,
                  [`exec${index + 1}`]: Number(floorBrokerFee.toFixed(2)),
                  floorBrokerFeeRate: {
                    ...row.floorBrokerFeeRate,
                    [index]: (Number(floorBrokerFee) / execQuantity).toFixed(2),
                  },
                };
              }
            });
          }
          floorBrokerFeeRow = { ...row };
          return row;
        } else if (row.id === "initiation") {
          const execKeys = Object.keys(row).filter(
            (key) => key.startsWith("exec") && key !== currentInitiatorExec,
          );
          if (currentInitiatorExec !== null) {
            let initiationSum = 0;
            execKeys.forEach((exec) => {
              const execInitiation = Number(
                (newData[commissionIndex][exec] - floorBrokerFeeRow[exec]) *
                  newData[initiationFeeIndex][exec],
              );

              row = {
                ...row,
                [`${exec}`]: Number(
                  (newData[commissionIndex][exec] - floorBrokerFeeRow[exec]) *
                    newData[initiationFeeIndex][exec],
                ),
              };
              initiationRow = {
                ...initiationRow,
                [`${exec}`]: Number(
                  (newData[commissionIndex][exec] - floorBrokerFeeRow[exec]) *
                    newData[initiationFeeIndex][exec],
                ),
              };
              initiationSum += Number(execInitiation.toFixed(2));
            });
            initiationRow = {
              ...initiationRow,
              [currentInitiatorExec]: initiationSum,
            };
            row = { ...row, [currentInitiatorExec]: initiationSum };

            return { ...row };
          } else {
            execKeys.forEach((exec) => {
              row = {
                ...row,
                [`${exec}`]: Number(
                  (newData[commissionIndex][exec] - floorBrokerFeeRow[exec]) *
                    newData[initiationFeeIndex][exec],
                ),
              };
              initiationRow = {
                ...initiationRow,
                [`${exec}`]: Number(
                  (newData[commissionIndex][exec] - floorBrokerFeeRow[exec]) *
                    newData[initiationFeeIndex][exec],
                ),
              };
            });
            return { ...row };
          }
        } else if (row.id === "market_maker") {
          const execKeys = Object.keys(row).filter((key) =>
            key.startsWith("exec"),
          );
          execKeys.forEach((exec) => {
            if (
              !newData[initiatorIndex][exec] &&
              newData[isMarketMakerIndex][exec]
            ) {
              row = {
                ...row,
                [`${exec}`]:
                  (newData[commissionIndex][exec] - floorBrokerFeeRow[exec]) *
                  newData[marketMakerFeeIndex][exec],
              };
            } else {
              row = { ...row, [`${exec}`]: 0 };
            }
          });
          return { ...row };
        } else if (row.id === "net") {
          const execKeys = Object.keys(row).filter((key) =>
            key.startsWith("exec"),
          );
          for (const key of execKeys) {
            if (row.hasOwnProperty(key)) {
              const netValue =
                newData[commissionIndex][key] -
                floorBrokerFeeRow[key] -
                newData[executionFeeIndex][key] +
                (key === currentInitiatorExec
                  ? initiationRow[key]
                  : -initiationRow[key]) -
                newData[marketMakerIndex][key];
              row = { ...row, [`${key}`]: netValue };
            }
          }
          return { ...row };
        } else {
          return row;
        }
      });

      setRowData(updatedData);
    } else if (e.data.columnHeader === "Initiation Fee") {
      // if (!isNaN(value)) {

      const initiator = newData[initiatorIndex][colId];
      let initiationRow = newData[initiationIndex];
      let currentInitiatorExec = initiator ? initiator : null;
      const updatedData = newData.map((row) => {
        if (row.id === "initiator") {
          const execKeys = Object.keys(row).filter((key) =>
            key.startsWith("exec"),
          );
          execKeys.forEach((exec) => {
            if (row[exec]) {
              currentInitiatorExec = exec;
            }
          });

          return row;
        } else if (row.id === "initiation_fee") {
          if (!isNaN(value)) {
            const execKeys = Object.keys(row).filter((key) =>
              key.startsWith("exec"),
            );
            for (const key of execKeys) {
              if (row.hasOwnProperty(key)) {
                row[`${key}`] = Number(value);
              }
            }
            return { ...row };
          } else {
            return { ...row, [colId]: Number(e.oldValue) };
          }
        } else if (row.id === "initiation") {
          if (currentInitiatorExec !== null) {
            const execKeys = Object.keys(row).filter(
              (key) => key.startsWith("exec") && key !== currentInitiatorExec,
            );
            let initiationSum = 0;

            for (const key of execKeys) {
              if (row.hasOwnProperty(key)) {
                row = {
                  ...row,
                  [`${key}`]: Number(
                    (
                      (newData[commissionIndex][`${key}`] -
                        newData[floorBrokerFeeIndex][`${key}`]) *
                      Number(value)
                    ).toFixed(2),
                  ),
                };
                initiationRow = {
                  ...initiationRow,
                  [`${key}`]: Number(
                    (
                      (newData[commissionIndex][`${key}`] -
                        newData[floorBrokerFeeIndex][`${key}`]) *
                      Number(value)
                    ).toFixed(2),
                  ),
                };

                initiationSum += Number(
                  (
                    (newData[commissionIndex][`${key}`] -
                      newData[floorBrokerFeeIndex][`${key}`]) *
                    Number(value)
                  ).toFixed(2),
                );
              }
            }

            initiationRow = {
              ...initiationRow,
              [currentInitiatorExec]: initiationSum,
            };
            row = { ...row, [currentInitiatorExec]: initiationSum };
          } else {
            const execKeys = Object.keys(row).filter((key) =>
              key.startsWith("exec"),
            );

            for (const key of execKeys) {
              if (row.hasOwnProperty(key)) {
                row = {
                  ...row,
                  [`${key}`]: Number(
                    (
                      (newData[commissionIndex][`${key}`] -
                        newData[floorBrokerFeeIndex][`${key}`]) *
                      Number(value)
                    ).toFixed(2),
                  ),
                };
                initiationRow = {
                  ...initiationRow,
                  [`${key}`]: Number(
                    (
                      (newData[commissionIndex][`${key}`] -
                        newData[floorBrokerFeeIndex][`${key}`]) *
                      Number(value)
                    ).toFixed(2),
                  ),
                };
              }
            }
          }
          return { ...row };
        } else if (row.id === "net") {
          const execKeys = Object.keys(row).filter((key) =>
            key.startsWith("exec"),
          );
          for (const key of execKeys) {
            if (row.hasOwnProperty(key)) {
              const netValue =
                newData[commissionIndex][key] -
                newData[floorBrokerFeeIndex][key] -
                newData[executionFeeIndex][key] +
                (key === currentInitiatorExec
                  ? initiationRow[key]
                  : -initiationRow[key]) -
                newData[marketMakerIndex][key];

              row = { ...row, [`${key}`]: netValue };
            }
          }
          return { ...row };
        } else {
          return row;
        }
      });
      setRowData(updatedData);
    } else if (e.data.columnHeader === "Market Maker Fee") {
      let currentInitiatorExec = null;
      let marketMakerRow = newData[marketMakerIndex];
      let isMarketMakerRow = newData[isMarketMakerIndex];
      let commissionRow = newData[commissionIndex];
      let floorBrokerFeeRow = newData[floorBrokerFeeIndex];
      const updatedData = newData.map((row) => {
        if (row.id === "initiator") {
          const execKeys = Object.keys(row).filter((key) =>
            key.startsWith("exec"),
          );
          execKeys.forEach((exec) => {
            if (row[exec]) {
              currentInitiatorExec = exec;
            }
          });

          return row;
        } else if (row.id === "market_maker_fee") {
          if (!isNaN(value)) {
            const execKeys = Object.keys(row).filter((key) =>
              key.startsWith("exec"),
            );
            for (const key of execKeys) {
              if (row.hasOwnProperty(key)) {
                row[`${key}`] = Number(value);
              }
            }
            return { ...row };
          } else {
            return { ...row, [colId]: Number(e.oldValue) };
          }
        } else if (row.id === "market_maker") {
          const execKeys = Object.keys(row).filter((key) =>
            key.startsWith("exec"),
          );
          for (const key of execKeys) {
            if (row.hasOwnProperty(key)) {
              if (key === currentInitiatorExec) {
                row = {
                  ...row,
                  [`${key}`]: 0,
                };
                marketMakerRow = { ...marketMakerRow, [`${key}`]: 0 };
              } else if (isMarketMakerRow[`${key}`]) {
                row = {
                  ...row,
                  [`${key}`]:
                    (commissionRow[`${key}`] - floorBrokerFeeRow[`${key}`]) *
                    Number(value),
                };
                marketMakerRow = {
                  ...marketMakerRow,
                  [`${key}`]:
                    (commissionRow[`${key}`] - floorBrokerFeeRow[`${key}`]) *
                    Number(value),
                };
              }
            }
          }
          return { ...row };
        } else if (row.id === "net") {
          const execKeys = Object.keys(row).filter((key) =>
            key.startsWith("exec"),
          );
          for (const key of execKeys) {
            if (row.hasOwnProperty(key)) {
              const netValue =
                newData[commissionIndex][key] -
                newData[floorBrokerFeeIndex][key] -
                newData[executionFeeIndex][key] +
                (key === currentInitiatorExec
                  ? newData[initiationIndex][key]
                  : -newData[initiationIndex][key]) -
                marketMakerRow[key];

              row = { ...row, [`${key}`]: netValue };
            }
          }
          return { ...row };
        } else {
          return row;
        }
      });
      setRowData(updatedData);
    } else if (
      e.data.columnHeader === "Leg Fee Rate" ||
      e.data.columnHeader === "Hedge Fee Rate"
    ) {
      const initiator = newData[initiatorIndex][colId];
      const floorBrokerFee = newData[floorBrokerFeeIndex][colId];
      const executionFee = newData[executionFeeIndex][colId];
      const isMarketMaker = newData[isMarketMakerIndex][colId];
      const initiationFee = newData[initiationFeeIndex][colId];
      const marketMakerFee = newData[marketMakerFeeIndex][colId];
      let legFeeRate = newData[legFeeRateIndex][colId];
      let hedgeFeeRate = newData[hedgeFeeRateIndex][colId];
      let commission = newData[commissionIndex][colId];
      let initiation = newData[initiationIndex][colId];
      let marketMaker = newData[marketMakerIndex][colId];
      let currentInitiatorExec = initiator ? initiator : null;
      let initiationRow = {};

      const updatedData = newData.map((row) => {
        if (row.id === "initiator") {
          const execKeys = Object.keys(row).filter((key) =>
            key.startsWith("exec"),
          );
          execKeys.forEach((exec) => {
            if (row[exec]) {
              currentInitiatorExec = exec;
            }
          });
          return row;
        } else if (
          (row.id === "leg_fee_rate" &&
            e.data.columnHeader === "Leg Fee Rate") ||
          (row.id === "hedge_fee_rate" &&
            e.data.columnHeader === "Hedge Fee Rate")
        ) {
          if (!isNaN(value)) {
            if (e.data.columnHeader === "Leg Fee Rate") {
              legFeeRate = Number(value);
            } else if (e.data.columnHeader === "Hedge Fee Rate") {
              hedgeFeeRate = Number(value);
            }
            return { ...row, [colId]: Number(value) };
          } else {
            return { ...row, [colId]: Number(e.oldValue) };
          }
        } else if (row.id === "commission") {
          const columnIndex = Number(colId.replace("exec", "")) - 1;
          let totalOptionsQuantity = 0;
          let totalHedgesQuantity = 0;
          for (const exec of parsedBookingText.executions[columnIndex].option) {
            totalOptionsQuantity += exec.quantity;
          }
          for (const exec of parsedBookingText.executions[columnIndex].hedge) {
            totalHedgesQuantity += exec.quantity;
          }
          commission =
            totalOptionsQuantity * (legFeeRate ? legFeeRate : 0) +
            totalHedgesQuantity * (hedgeFeeRate ? hedgeFeeRate : 0);
          return { ...row, [colId]: commission };
        } else if (row.id === "initiation") {
          if (initiator) {
            return row;
          } else {
            if (currentInitiatorExec !== null) {
              const execKeys = Object.keys(row).filter(
                (key) => key.startsWith("exec") && key !== currentInitiatorExec,
              );
              let initiationSum = 0;
              execKeys.forEach((exec) => {
                if (exec !== colId) {
                  const execInitiation = Number(newData[initiationIndex][exec]);
                  initiationSum += Number(execInitiation.toFixed(2));
                } else {
                  initiation = Number(
                    ((commission - floorBrokerFee) * initiationFee).toFixed(2),
                  );
                  row = { ...row, [colId]: initiation };
                  initiationSum += initiation;
                }
              });

              row = { ...row, [currentInitiatorExec]: initiationSum };
              initiationRow = {
                ...initiationRow,
                [currentInitiatorExec]: initiationSum,
              };
              return { ...row };
            } else {
              initiation = Number(
                ((commission - floorBrokerFee) * initiationFee).toFixed(2),
              );
              return { ...row, [colId]: initiation };
            }
          }
        } else if (row.id === "market_maker") {
          if (!initiator && isMarketMaker) {
            marketMaker = (commission - floorBrokerFee) * marketMakerFee;
            return { ...row, [colId]: marketMaker };
          } else {
            marketMaker = 0;
            return { ...row, [colId]: 0 };
          }
        } else if (row.id === "net") {
          const netCalculation =
            commission -
            floorBrokerFee -
            executionFee +
            (initiator ? initiation : -initiation) -
            marketMaker;

          if (initiator) {
            return { ...row, [colId]: netCalculation };
          } else {
            if (currentInitiatorExec !== null) {
              const initiatorNetValue =
                newData[commissionIndex][currentInitiatorExec] -
                newData[floorBrokerFeeIndex][currentInitiatorExec] -
                newData[executionFeeIndex][currentInitiatorExec] +
                initiationRow[currentInitiatorExec] -
                marketMaker;

              return {
                ...row,
                [colId]: netCalculation,
                [currentInitiatorExec]: initiatorNetValue,
              };
            } else {
              return { ...row, [colId]: netCalculation };
            }
          }
        } else {
          return row;
        }
      });
      setRowData(updatedData);
    } else if (e.data.columnHeader === "Floor Broker Fee") {
      let currentInitiatorExec = null;
      let initiationRow = newData[initiationIndex];

      const updatedData = newData.map((row) => {
        if (row.id === "initiator") {
          const execKeys = Object.keys(row).filter((key) =>
            key.startsWith("exec"),
          );
          execKeys.forEach((exec) => {
            if (row[exec]) {
              currentInitiatorExec = exec;
            }
          });

          return row;
        } else if (row.id === "floor_broker_fee") {
          if (!isNaN(value)) {
            const execKeys = Object.keys(row).filter((key) =>
              key.startsWith("exec"),
            );
            for (const key of execKeys) {
              if (row.hasOwnProperty(key)) {
                row[`${key}`] = Number(value);
              }
            }
            return { ...row };
          } else {
            return { ...row, [colId]: Number(e.oldValue) };
          }
        } else if (row.id === "initiation") {
          const execKeys = Object.keys(row).filter(
            (key) => key.startsWith("exec") && key !== currentInitiatorExec,
          );
          if (currentInitiatorExec !== null) {
            let initiationSum = 0;
            execKeys.forEach((exec) => {
              const execInitiation = Number(
                (newData[commissionIndex][exec] - value) *
                  newData[initiationFeeIndex][exec],
              );

              row = {
                ...row,
                [`${exec}`]: Number(
                  (newData[commissionIndex][exec] - value) *
                    newData[initiationFeeIndex][exec],
                ),
              };
              initiationRow = {
                ...initiationRow,
                [`${exec}`]: Number(
                  (newData[commissionIndex][exec] - value) *
                    newData[initiationFeeIndex][exec],
                ),
              };
              initiationSum += Number(execInitiation.toFixed(2));
            });
            initiationRow = {
              ...initiationRow,
              [currentInitiatorExec]: initiationSum,
            };
            row = { ...row, [currentInitiatorExec]: initiationSum };

            return { ...row };
          } else {
            execKeys.forEach((exec) => {
              row = {
                ...row,
                [`${exec}`]: Number(
                  (newData[commissionIndex][exec] - value) *
                    newData[initiationFeeIndex][exec],
                ),
              };
              initiationRow = {
                ...initiationRow,
                [`${exec}`]: Number(
                  (newData[commissionIndex][exec] - value) *
                    newData[initiationFeeIndex][exec],
                ),
              };
            });
            return { ...row };
          }
        } else if (row.id === "market_maker") {
          const execKeys = Object.keys(row).filter((key) =>
            key.startsWith("exec"),
          );
          execKeys.forEach((exec) => {
            if (
              !newData[initiatorIndex][exec] &&
              newData[isMarketMakerIndex][exec]
            ) {
              row = {
                ...row,
                [`${exec}`]:
                  (newData[commissionIndex][exec] - value) *
                  newData[marketMakerFeeIndex][exec],
              };
            } else {
              row = { ...row, [`${exec}`]: 0 };
            }
          });
          return { ...row };
        } else if (row.id === "net") {
          const execKeys = Object.keys(row).filter((key) =>
            key.startsWith("exec"),
          );
          for (const key of execKeys) {
            if (row.hasOwnProperty(key)) {
              const netValue =
                newData[commissionIndex][key] -
                value -
                newData[executionFeeIndex][key] +
                (key === currentInitiatorExec
                  ? initiationRow[key]
                  : -initiationRow[key]) -
                newData[marketMakerIndex][key];
              row = { ...row, [`${key}`]: netValue };
            }
          }
          return { ...row };
        } else {
          return row;
        }
      });
      setRowData(updatedData);
    } else if (e.data.columnHeader === "Execution Fee") {
      const initiator = newData[initiatorIndex][colId];
      const commission = newData[commissionIndex][colId];
      const initiation = newData[initiationIndex][colId];
      const marketMaker = newData[marketMakerIndex][colId];
      let floorBrokerFee = newData[floorBrokerFeeIndex][colId];
      let executionFee = newData[executionFeeIndex][colId];

      const updatedData = newData.map((row) => {
        if (row.id === "execution_fee") {
          if (!isNaN(value)) {
            executionFee = value;

            return { ...row, [colId]: value };
          } else {
            return { ...row, [colId]: e.oldValue };
          }
        } else if (row.id === "net") {
          const netCalculation =
            commission -
            floorBrokerFee -
            executionFee +
            (initiator ? initiation : -initiation) -
            marketMaker;
          return { ...row, [colId]: netCalculation };
        } else {
          return row;
        }
      });
      setRowData(updatedData);
    } else if (e.data.columnHeader === "Commission") {
      const initiationFee = newData[initiationFeeIndex][colId];
      const marketMakerFee = newData[marketMakerFeeIndex][colId];
      let commission = newData[commissionIndex][colId];
      const isMarketMaker = newData[isMarketMakerIndex][colId];
      const floorBrokerFee = newData[floorBrokerFeeIndex][colId];
      const executionFee = newData[executionFeeIndex][colId];
      let initiation = newData[initiationIndex][colId];
      const initiator = newData[initiatorIndex][colId];
      let marketMaker = newData[marketMakerIndex][colId];
      let currentInitiatorExec = null;
      let initiationRow = newData[initiationIndex];

      const updatedData = newData.map((row) => {
        if (row.id === "initiator") {
          const execKeys = Object.keys(row).filter(
            (key) => key.startsWith("exec") && key !== colId,
          );
          execKeys.forEach((exec) => {
            if (row[exec]) {
              currentInitiatorExec = exec;
            }
          });
          return row;
        } else if (row.id === "commission") {
          if (!isNaN(value)) {
            commission = value;
            return { ...row, [colId]: Number(value) };
          } else {
            return { ...row, [colId]: e.oldValue };
          }
        } else if (row.id === "initiation") {
          if (initiator) {
            return row;
          } else {
            if (currentInitiatorExec !== null) {
              const execKeys = Object.keys(row).filter(
                (key) => key.startsWith("exec") && key !== currentInitiatorExec,
              );
              let initiationSum = 0;
              execKeys.forEach((exec) => {
                if (exec !== colId) {
                  const execInitiation = Number(newData[initiationIndex][exec]);
                  initiationSum += Number(execInitiation.toFixed(2));
                } else {
                  initiation = Number(
                    ((commission - floorBrokerFee) * initiationFee).toFixed(2),
                  );
                  initiationRow = { ...row, [colId]: initiation };
                  row = { ...row, [colId]: initiation };
                  initiationSum += initiation;
                }
              });
              initiationRow = { ...row, [currentInitiatorExec]: initiationSum };
              row = { ...row, [currentInitiatorExec]: initiationSum };

              return { ...row };
            } else {
              initiation = Number(
                ((commission - floorBrokerFee) * initiationFee).toFixed(2),
              );
              initiationRow = { ...row, [colId]: initiation };
              return { ...row, [colId]: initiation };
            }
          }
        } else if (row.id === "market_maker") {
          if (!initiator && isMarketMaker) {
            marketMaker = (commission - floorBrokerFee) * marketMakerFee;
            return {
              ...row,
              [colId]: (commission - floorBrokerFee) * marketMakerFee,
            };
          } else {
            marketMaker = 0;
            return { ...row, [colId]: 0 };
          }
        } else if (row.id === "net") {
          const execKeys = Object.keys(row).filter((key) =>
            key.startsWith("exec"),
          );
          for (const key of execKeys) {
            if (row.hasOwnProperty(key)) {
              const netValue =
                (key === colId ? value : newData[commissionIndex][key]) -
                newData[floorBrokerFeeIndex][key] -
                newData[executionFeeIndex][key] +
                (key === currentInitiatorExec
                  ? initiationRow[key]
                  : -initiationRow[key]) -
                (key === colId ? marketMaker : newData[marketMakerIndex][key]);
              row = { ...row, [`${key}`]: netValue };
            }
          }
          return { ...row };
        } else {
          return row;
        }
      });

      setRowData(updatedData);
    } else if (e.data.columnHeader === "Initiation") {
      const initiator = newData[initiatorIndex][colId];
      const floorBrokerFee = newData[floorBrokerFeeIndex][colId];
      const executionFee = newData[executionFeeIndex][colId];
      const commission = newData[commissionIndex][colId];
      let initiation = newData[initiationIndex][colId];
      let marketMaker = newData[marketMakerIndex][colId];
      let currentInitiatorExec = null;
      let initiationSum = 0;

      const updatedData = newData.map((row) => {
        if (row.id === "initiator") {
          const execKeys = Object.keys(row).filter((key) =>
            key.startsWith("exec"),
          );
          execKeys.forEach((exec) => {
            if (row[exec]) {
              currentInitiatorExec = exec;
            }
          });
          return row;
        } else if (row.id === "initiation") {
          if (!isNaN(value)) {
            if (initiator) {
              initiation = Number(value);
              return { ...row, [colId]: Number(value) };
            } else {
              if (currentInitiatorExec !== null) {
                const execKeys = Object.keys(row).filter(
                  (key) =>
                    key.startsWith("exec") && key !== currentInitiatorExec,
                );
                execKeys.forEach((exec) => {
                  if (exec !== colId) {
                    // const execCommission = newData[commissionIndex][exec];
                    // const execInitiationFee = newData[initiationFeeIndex][exec];
                    const execInitiation = Number(
                      newData[initiationIndex][exec],
                    );
                    // row[exec] = execCommission * execInitiationFee;
                    initiationSum += Number(execInitiation.toFixed(2));
                  } else {
                    initiation = Number(Number(value).toFixed(2));
                    row = { ...row, [colId]: initiation };
                    initiationSum += initiation;
                  }
                });
                return {
                  ...row,
                  [colId]: value,
                  [currentInitiatorExec]: initiationSum,
                };
              } else {
                initiation = Number(value);
                return { ...row, [colId]: initiation };
              }
            }
          } else {
            return { ...row, [colId]: e.oldValue };
          }
        } else if (row.id === "net") {
          // if (initiator) {
          //   return row;
          // } else {
          const netCalculation =
            commission -
            floorBrokerFee -
            executionFee +
            (initiator ? initiation : -initiation) -
            marketMaker;
          if (currentInitiatorExec !== null) {
            const initiatorNetValue =
              newData[commissionIndex][currentInitiatorExec] -
              newData[floorBrokerFeeIndex][currentInitiatorExec] -
              newData[executionFeeIndex][currentInitiatorExec] +
              (colId !== currentInitiatorExec ? initiationSum : initiation);

            return {
              ...row,
              [colId]: netCalculation,
              [currentInitiatorExec]: initiatorNetValue,
            };
          } else {
            return { ...row, [colId]: netCalculation };
          }
          // }
        } else {
          return row;
        }
      });
      setRowData(updatedData);
    } else if (e.data.columnHeader === "Market Maker") {
      const initiator = newData[initiatorIndex][colId];
      const floorBrokerFee = newData[floorBrokerFeeIndex][colId];
      const executionFee = newData[executionFeeIndex][colId];
      const commission = newData[commissionIndex][colId];
      let initiation = newData[initiationIndex][colId];
      let marketMaker = newData[marketMakerIndex][colId];

      const updatedData = newData.map((row) => {
        if (row.id === "market_maker") {
          if (!isNaN(value)) {
            marketMaker = value;

            return { ...row, [colId]: value };
          } else {
            return { ...row, [colId]: e.oldValue };
          }
        } else if (row.id === "net") {
          const netCalculation =
            commission -
            floorBrokerFee -
            executionFee +
            (initiator ? initiation : -initiation) -
            marketMaker;
          return { ...row, [colId]: netCalculation };
        } else {
          return row;
        }
      });
      setRowData(updatedData);
    }
  }
};

export const cleanEmptyPnlRows = (arr) => {
  const filteredArray = arr.filter((obj) => {
    if (obj.columnHeader.includes("pnl")) {
      const allFieldsNotNull = Object.keys(obj).every(
        (key) => key === "columnHeader" || key === "id" || obj[key] === null,
      );
      return !allFieldsNotNull;
    }
    return true;
  });
  return filteredArray;
};
export const getTraderName = (id, makorStaticData) => {
  const { employees } = makorStaticData;
  const employee = employees.filter((e) => e.id === id);
  if (employee.length > 0) {
    return (
      employee[0].first_name.charAt(0).toUpperCase() +
      employee[0].first_name.slice(1)
    );
  } else {
    return "N/A";
  }
};
