import React, { memo, useEffect, useRef } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Box,
  CircularProgress,
} from "@material-ui/core";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { VariableSizeList as VirtualizedList } from "react-window";

const GenericList = memo(
  ({
    itemList,
    selectedItem,
    onItemPick,
    onItemDoubleClick,
    classes,
    itemKeyProp,
    itemDisplayProp,
  }) => {
    const listRef = useRef(null);

    useEffect(() => {
      if (listRef.current && selectedItem) {
        const index = itemList.findIndex(
          (item) => item[itemKeyProp] === selectedItem,
        );
        if (index !== -1) {
          listRef.current.scrollToItem(index, "center"); // Scroll to the selected item on mount or update
        }
      }
    }, [itemList, selectedItem, itemKeyProp]);

    const getItemSize = (index) => 50; // Function to return item size; adjust as needed

    const renderRow = ({ index, style }) => {
      const item = itemList[index];
      return (
        <ListItem
          button
          onClick={() => onItemPick(item)}
          onDoubleClick={() => onItemDoubleClick(item)}
          selected={selectedItem === item[itemKeyProp]}
          className={classes.listItemSelected}
          style={style}
          key={item[itemKeyProp]}
        >
          <ListItemText
            className={classes.listItemText}
            primary={item[itemDisplayProp]
              .toLowerCase()
              .split(" ")
              .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
              .join(" ")}
          />
          {selectedItem === item[itemKeyProp] ? (
            <PlayArrowIcon className={classes.playIcon} />
          ) : null}
        </ListItem>
      );
    };

    return (
      <Collapse in={true} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          className={classes.itemsListClients}
        >
          {itemList !== null ? (
            <VirtualizedList
              ref={listRef}
              height={250} // Adjust height according to your layout
              itemCount={itemList.length}
              itemSize={getItemSize} // Provide function returning item size
              width="100%"
            >
              {renderRow}
            </VirtualizedList>
          ) : (
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              className={classes.columnsContainer}
            >
              <Box sx={{ display: "flex" }}>
                <CircularProgress className={classes.circularProgress} />
              </Box>
            </Grid>
          )}
        </List>
      </Collapse>
    );
  },
);

export default GenericList;
