import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "userSlice",
  initialState: {
    auth: "",
    userName: "",
    id: 0,
    entitlement: "",
    role: "",
  },
  reducers: {
    saveLoginDetails: (state, action) => {
      state.auth = action.payload.token;
      state.userName = action.payload.userName;
      state.id = action.payload.id;
      state.entitlement = action.payload.entitlement;
      state.role = action.payload.role;
      sessionStorage.setItem("traderId", action.payload.id);
    },
    clearLoginState: (state, action) => {
      state.auth = "";
      state.userName = "";
      state.entitlement = "";
      state.id = 0;
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("userName");
      sessionStorage.removeItem("traderId");
      sessionStorage.removeItem("entrances");
      sessionStorage.removeItem("rfqTraderId");
      sessionStorage.removeItem("rfqId");
      sessionStorage.removeItem("rfqStatus");
      sessionStorage.removeItem("id");
      sessionStorage.removeItem("role");
      sessionStorage.removeItem("userId");
      sessionStorage.removeItem("status");
      sessionStorage.removeItem("rfqMode");
      sessionStorage.removeItem("jexcel");
    },
  },
});

export const getUserId = () => async (dispatch, getState) => {
  const id = getState().userSlice.id;
  return id;
};

export const { saveLoginDetails, clearLoginState } = userSlice.actions;

export default userSlice.reducer;
