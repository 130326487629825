export const cellEditorSelector = (params) => {
  if (
    params.data.columnHeader === "Cleared By" ||
    params.data.columnHeader === "Client" ||
    params.data.columnHeader === "Trader" ||
    params.data.columnHeader === "Clearance Account" ||
    params.data.columnHeader === "Floor Broker"
  ) {
    return { component: "agRichSelectCellEditor" };
  } else return undefined;
};

export const getUniqueCompanies = (clientsArray) => {
  const uniqueCompanies = {};
  clientsArray.forEach((item) => {
    const companyName = item.company.name;
    const companyId = item.company.id;

    if (!uniqueCompanies[companyName]) {
      uniqueCompanies[companyName] = { name: companyName, id: companyId };
    }
  });

  const uniqueCompanyData = Object.values(uniqueCompanies);
  uniqueCompanyData.sort((a, b) => a.name.localeCompare(b.name));

  return uniqueCompanyData;
};
export const getClientsByCompany = (clientsArray) => {
  return clientsArray.map((i) => {
    return { name: i.name, company: i.company.name };
  });
};
export const cellEditorPopupSelector = (params) => {
  if (
    params.data.columnHeader === "Cleared By" ||
    params.data.columnHeader === "Client" ||
    params.data.columnHeader === "Trader" ||
    params.data.columnHeader === "Clearance Account" ||
    params.data.columnHeader === "Floor Broker"
  ) {
    return true;
  } else return false;
};
export const cellEditorPopupPositionSelector = (params) => {
  if (
    params.data.columnHeader === "Cleared By" ||
    params.data.columnHeader === "Client" ||
    params.data.columnHeader === "Trader" ||
    params.data.columnHeader === "Clearance Account" ||
    params.data.columnHeader === "Floor Broker"
  ) {
    return "under";
  } else return undefined;
};
