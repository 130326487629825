import { useStyles } from "./Rates.styles";
import RatesTable from "./RatesTable/RatesTable";
import SearchIcon from "@material-ui/icons/Search";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as ratesSlice from "../../../store/rates/ratesSlice";
import * as actionSnackBar from "../../../store/snackbar/action";
import { ReactComponent as DownloadCsv } from "../../../assets/csv-download.svg";
import PapaParse from "papaparse";
import { DebounceInput } from "react-debounce-input";
function RatesView(props) {
  const {
    dateSelected,
    handleDatePick,
    handleDateFormatPick,
    searchValue,
    handleSearchByValue,
    PnL,
    exportToCsv,
    linkRates,
    deLinkTrades,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { rates, chosenRates, chosenDeals } = useSelector(
    (state) => state.ratesSlice
  );

  return (
    <Grid container>
      <Grid
        item
        container
        xs={12}
        style={{ paddingTop: "10px" }}
        alignItems="center"
      >
        <Grid item xs={4}>
          <Typography style={{ fontSize: "28px", color: "#ffffff" }}>
            Rates Blotter
          </Typography>
        </Grid>
        <Grid
          item
          container
          // xs={3}
          style={{
            borderRadius: "4px",
            border: "1px solid #ffffff",
            paddingBlock: 4,
            width: "450px",
          }}
          justifyContent="center"
        >
          <Grid item container xs={11} style={{ paddingBottom: "4px" }}>
            <Grid item xs={6}>
              <Typography style={{ fontSize: "1.3125rem", color: "#ffffff" }}>
                Approx Realised PnL (USD):
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography
                style={{
                  fontSize: "1.3125rem",
                  color: PnL < 0 ? "red" : "green",
                }}
              >
                &nbsp;&nbsp;
                {PnL?.realizePnl || PnL?.realizePnl === 0
                  ? `${PnL.realizePnl.toLocaleString("en-US")} USD`
                  : "N/A"}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={11}>
            <Grid item xs={6}>
              <Typography style={{ fontSize: "1.3125rem", color: "#ffffff" }}>
                Approx Unrealised PnL(USD):
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography
                style={{
                  fontSize: "1.3125rem",
                  color: "#ff3b3b",
                }}
              >
                &nbsp;&nbsp;
                {PnL?.unrealizePnl
                  ? `${PnL.unrealizePnl.toLocaleString("en-US")} USD`
                  : "N/A"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container alignItems="center">
        <Grid item xs={6} container>
          {chosenDeals.length === 1 ? (
            <Grid item>
              <Button className={classes.linkBtn} onClick={deLinkTrades}>
                De-Link
              </Button>
            </Grid>
          ) : null}
          {chosenRates.length > 1 && chosenDeals.length === 0 ? (
            <Grid item>
              <Button className={classes.linkBtn} onClick={linkRates}>
                Link
              </Button>
            </Grid>
          ) : null}
        </Grid>
        <Grid
          item
          xs={6}
          container
          justifyContent="flex-end"
          alignItems="center"
          style={{ paddingBottom: "15px" }}
        >
          <Grid item style={{ paddingRight: 5 }}>
            <IconButton
              size="small"
              style={{
                fill: "#49cb9cf7",
              }}
              disabled={rates.length === 0}
              onClick={() => exportToCsv()}
            >
              <DownloadCsv style={{ width: "35px", height: "35px" }} />
            </IconButton>
          </Grid>
          <Grid item>
            <Grid container style={{ width: "250px", marginRight: "10px" }}>
              <Grid
                itefgdfgxm
                order={1}
                style={{
                  width: "250px",
                  zIndex: "3",
                  marginTop: "9px",
                }}
              >
                <InputBase
                  style={{
                    color: "#ffffff",
                    border: "1px solid #686B76",
                    borderRadius: "4px",
                    width: "250px",
                    height: "40px",
                  }}
                  onChange={(e) => handleDatePick(e)}
                  name="date"
                  variant="outlined"
                  type="date"
                  className={"select-date-picker"}
                  value={dateSelected}
                />
              </Grid>
              <Grid
                item
                order={2}
                style={{
                  width: "130px",
                  position: "absolute",
                  zIndex: "5",
                  marginTop: "20px",
                }}
              >
                <select
                  className="select-date-format"
                  onChange={(e) => {
                    handleDateFormatPick(e);
                  }}
                >
                  <option value={"TradeDate"}>Trade Date</option>
                  <option value={"StartDate"}>Start Date</option>
                  <option value={"EndDate"}>End Date</option>
                  <option value={"AdditionalPaymentDate"}>
                    Additional Payment Date
                  </option>
                </select>
              </Grid>
            </Grid>
          </Grid>

          <Grid item style={{ marginTop: "6px" }}>
            <DebounceInput
              id="searchBox"
              style={{
                color: "white",
                border: "1px solid #686B76",
                borderRadius: "4px",
                height: "35px",
                background: "none",
              }}
              minLength={2}
              debounceTimeout={500}
              className={classes.searchInput}
              variant="outlined"
              onChange={(e) =>
                handleSearchByValue(e.target.value.toLowerCase())
              }
              placeholder="Search"
              endAdornment={
                <InputAdornment position="start">
                  <IconButton
                  // onClick={searchFi}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <RatesTable />
      </Grid>
    </Grid>
  );
}

export default RatesView;
