import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import PmManagmentView from "./PmManagment.view";

function PmManagment() {
  const dispatch = useDispatch();
  const [toEmailAddresses, setToEmailAddresses] = useState([]);
  const [pmName, setPmName] = useState("");

  const handleEmailAddressAdd = (event) => {
    let newArr = event.target.value.split(/[\;\,\:]/);
    newArr = newArr.map((address) => {
      return address.replaceAll(" ", "");
    });
    setToEmailAddresses(newArr);
  };

  const handlePmNameChange = (event) => {
    setPmName(event.target.value);
  };

 

  return (
    <PmManagmentView
      pmName={pmName}
      toEmailAddresses={toEmailAddresses}
      handleEmailAddressAdd={handleEmailAddressAdd}
      handlePmNameChange={handlePmNameChange}
    />
  );
}

export default PmManagment;
