import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  StyledHeaderTableRow,
  StyledTableCell,
  StyledTableRow,
} from "./RatesTable.styles";
import RatesTableRow from "./RatesTableRow";
import "./rates.css";
import * as ratesActions from "../../../../store/rates/ratesSlice";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useStyles } from "../Rates.styles";
import "./rates.css";
import DealRatesTableRow from "./DealRatesTableRow";

function RatesTable(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { rates, dataLoading } = useSelector((state) => state.ratesSlice);
  const linkedRates = rates
    ?.filter((rate) => rate?.deal_id !== null && rate?.deal_id !== "")
    ?.reduce(function (rv, x) {
      (rv[x?.deal_id] = rv[x?.deal_id] || []).push(x);
      return rv;
    }, {});

  const linkedRatesArr = linkedRates ? Object.entries(linkedRates) : [];

  const ratesWithoutLink = rates?.filter(
    (r) => r?.deal_id === null || r?.deal_id === ""
  );

  const [orderDirection, setOrderDirection] = useState("desc");
  const [fieldToOrderBy, setfieldToOrderBy] = useState("");
  const rowsHeaders = [
    "TradeId",
    "Trader",
    "ClearingPrevCtpy",
    "Direction",
    "TradeDate",
    "StartDate",
    "EndDate",
    "ProductType",
    "CCPLEName",
    "Currency",
    "Notional",
    "Rate",
    "FRateIndex",
    "FixedDayBasis",
    "FloatingDayBasis",
    "FixedConvention",
    "FloatingConvention",
    "InternalTradeId",
    "FixedPaymentFrequency",
    "FloatingPaymentFrequency",
    "RollDay",
    "AdditionalPaymentAmount",
    "AdditionalPaymentCurrency",
    "AdditionalPaymentDate",
    "AdditionalPaymentDirection",
  ];
  const handleSorting = (field) => {
    const isAscending = fieldToOrderBy === field && orderDirection === "asc";
    setfieldToOrderBy(field);
    setOrderDirection(isAscending ? "desc" : "asc");
    let copy = [...rates];
    const order = isAscending ? "desc" : "asc";
    if (field === "StartDate" && field === "EndDate" && field === "TradeDate") {
      if (order === "asc") {
        let temp = copy.sort(
          (a, b) => new Date(a[`${field}`]) - new Date(b[`${field}`])
        );
        dispatch(ratesActions.setRates(temp));
      } else {
        let temp = copy.sort(
          (a, b) => new Date(b[`${field}`]) - new Date(a[`${field}`])
        );
        dispatch(ratesActions.setRates(temp));
      }
    } else if (field === "Notional") {
      if (order === "asc") {
        let temp = copy.sort((a, b) => a[`${field}`] - b[`${field}`]);
        dispatch(ratesActions.setRates(temp));
      } else {
        let temp = copy.sort((a, b) => b[`${field}`] - a[`${field}`]);
        dispatch(ratesActions.setRates(temp));
      }
    } else {
      if (order === "asc") {
        let temp = copy.sort((a, b) => {
          let x = a[`${field}`].toLowerCase();
          let y = b[`${field}`].toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });
        dispatch(ratesActions.setRates(temp));
      } else {
        let temp = copy.sort((a, b) => {
          let x = a[`${field}`].toLowerCase();
          let y = b[`${field}`].toLowerCase();
          if (y < x) {
            return -1;
          }
          if (y > x) {
            return 1;
          }
          return 0;
        });
        dispatch(ratesActions.setRates(temp));
      }
    }
  };

  const linkRates = async (TradeId) => {
    dispatch(ratesActions.handleRateCheck(TradeId));
  };

  return (
    <Grid container>
      {dataLoading === true ? (
        <Grid item style={{ margin: "0 auto", paddingTop: "20%" }}>
          <CircularProgress
            style={{ color: "#2692ff", width: "60px", height: "60px" }}
          />
        </Grid>
      ) : (
        <>
          <TableContainer style={{ maxHeight: "calc(100vh - 140px)" }}>
            <Table stickyHeader style={{ overflowX: "auto" }}>
              <TableHead>
                <StyledHeaderTableRow>
                  <StyledTableCell
                    component="td"
                    style={{
                      position: "sticky",
                      left: 0,
                      width: "3%",
                      borderBottom: "1px solid #686B76",
                    }}
                  ></StyledTableCell>

                  {rowsHeaders.map((key, index) => {
                    if (key !== "isNew" && key !== "deal_id") {
                      if (
                        key === "ClearingPrevCtpy" ||
                        key === "StartDate" ||
                        key === "EndDate" ||
                        key === "TradeDate" ||
                        key === "Notional" ||
                        key === "Direction"
                      ) {
                        return (
                          <StyledTableCell key={index}>
                            <TableSortLabel
                              className={classes.sortLabel}
                              direction={orderDirection}
                              onClick={() => handleSorting(key)}
                              id={"sort-header"}
                            >
                              {key.replace(/([A-Z])/g, " $1")}
                            </TableSortLabel>
                          </StyledTableCell>
                        );
                      } else if (key === "CCPLEName") {
                        return (
                          <StyledTableCell key={index}>CCP</StyledTableCell>
                        );
                      } else {
                        return (
                          <StyledTableCell key={index}>
                            {key.replace(/([A-Z])/g, " $1")}
                          </StyledTableCell>
                        );
                      }
                    }
                  })}
                </StyledHeaderTableRow>
              </TableHead>
              <TableBody>
                {linkedRatesArr.length > 0 &&
                  linkedRatesArr[0][0] !== undefined &&
                  linkedRatesArr[0] !== null &&
                  linkedRatesArr[0] !== undefined &&
                  linkedRatesArr?.map((linkedRate, index) => {
                    return (
                      <DealRatesTableRow
                        key={index}
                        linkedRate={linkedRate}
                        linkedRatesArr={linkedRatesArr[index]}
                      />
                    );
                  })}
                {ratesWithoutLink &&
                  ratesWithoutLink !== null &&
                  ratesWithoutLink.length !== 0 &&
                  ratesWithoutLink?.map((rate, index) => {
                    return (
                      <RatesTableRow
                        rate={rate}
                        key={index}
                        linkRates={linkRates}
                      />
                    );
                  })}
              </TableBody>
            </Table>
            {rates.length === 0 && (
              <Typography
                style={{
                  color: "#ffffff",
                  width: "100%",
                  textAlign: "center",
                  paddingBlock: "10px",
                }}
              >
                No Rows To Show
              </Typography>
            )}
          </TableContainer>
        </>
      )}
    </Grid>
  );
}
export default RatesTable;
