import axios from "axios";
import { END_POINT } from "../../../utils/index";

export const apiRestDealStats = async (
  volLegParams,
  status,
  rfqId,
  dispatch,
  updateQuoteParameterts,
  updateDealStatsData,
) => {
  let filterVolLegParams = [];
  filterVolLegParams = volLegParams.filter(
    (params) => Number(params.rfq_id) === Number(rfqId),
  );
  let token = sessionStorage.getItem("token");
  if (status !== "copy") {
    if (filterVolLegParams.length > 0) {
      // dispatch(updateTableData(filterVolLegParams[0].tableData));
      dispatch(updateDealStatsData(filterVolLegParams[0].dealStatsData));
      dispatch(updateQuoteParameterts(filterVolLegParams[0].quoteParams));
      return filterVolLegParams[0].dealStatsData;
    } else {
      try {
        let response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}` +
            END_POINT.DEAL_STATS +
            `/${rfqId}`,
          { headers: { Authorization: token } },
        );
        dispatch(updateDealStatsData(response.data[0]));
        return response.data[0];
      } catch (error) {
        console.log(error);
      }
    }
  }
};
export const apiGetQuoatParameters = async (
  rfqId,
  volLegParams,
  dispatch,
  setRfqDefaultQuoteParams,
  updateQuoteParameterts,
) => {
  const token = sessionStorage.getItem("token");
  try {
    let filterVolLegParams = [];
    if (rfqId !== undefined) {
      filterVolLegParams = volLegParams.filter(
        (params) => Number(params.rfq_id) === Number(rfqId),
      );
    }

    if (filterVolLegParams.length > 0) {
      let response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}` + `quote_parameters/${rfqId}`,
        {
          headers: { Authorization: token },
        },
      );

      dispatch(updateQuoteParameterts(filterVolLegParams[0].quoteParams));
      dispatch(setRfqDefaultQuoteParams(response.data[0]));
      return filterVolLegParams[0].quoteParams;
    } else {
      let response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}` + `quote_parameters/${rfqId}`,
        {
          headers: { Authorization: token },
        },
      );
      dispatch(updateQuoteParameterts(response.data[0]));
      dispatch(setRfqDefaultQuoteParams(response.data[0]));
      return response.data[0];
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateQuoteStatus = async (
  rowIndex,
  rfqId,
  setOnProgressHighlight,
) => {
  try {
    const token = sessionStorage.getItem("token");

    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}` +
        `${END_POINT.RFQ_QUOTE}/update_quote_status`,
      { rowIndex, rfqId },
      {
        headers: { Authorization: token },
      },
    );

    setOnProgressHighlight(false);
  } catch (error) {
    console.log(error);
    setOnProgressHighlight(false);
  }
};
