import { Checkbox, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "../Rates.styles";
import {
  GreenRadio,
  StyledTableCell,
  StyledTableRow,
} from "./RatesTable.styles";
import * as ratesSlice from "../../../../store/rates/ratesSlice";
import moment from "moment";

const DealRatesTableRow = (props) => {
  const { linkedRate, linkedRatesArr } = props;
  console.log("linkedRatesArr: ON DEALRATSTABL ", linkedRatesArr[1].length);
  const { chosenRates, chosenDeals } = useSelector((state) => state.ratesSlice);
  const classes = useStyles();
  const dispatch = useDispatch();
  const numberWithCommas = (number) => {
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <>
      <StyledTableRow
        colspan="100%"
        onClick={() => {
          dispatch(ratesSlice.handleDealRowSelect(linkedRate));
        }}
        style={{
          backgroundColor:
            chosenDeals.filter((deal) => deal === linkedRate[0]).length > 0 &&
            chosenDeals.length > 0
              ? "#363434"
              : "#171717",
        }}
      >
        <StyledTableCell style={{ borderBottom: "none" }}>
          <Checkbox
            name="checkedH"
            className={classes.checkbox}
            onChange={(e) => {
              dispatch(ratesSlice.handleDealRowSelect(linkedRate));
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            checked={
              chosenDeals.filter((deal) => deal === linkedRate[0]).length > 0 &&
              chosenDeals.length > 0
            }
          />
        </StyledTableCell>
        <StyledTableCell
          colSpan={"100%"}
          style={{
            borderBottom: "none",
            width: "150px",
          }}
        >
          <Typography
            variant="body1"
            style={{
              fontSize: "14px",
              color: "white",
              textAlign: "left",
            }}
          >
            Deal: {linkedRate[0]}
          </Typography>
        </StyledTableCell>
      </StyledTableRow>

      {linkedRate[1]?.map((linkedRate, idx) => {
        return (
          <StyledTableRow
            key={idx}
            style={{
              backgroundColor:
                chosenRates.filter(
                  (r) => Number(r.TradeId) === Number(linkedRate.TradeId)
                ).length > 0 && chosenRates.length > 0
                  ? "#2b2a2a"
                  : "#171717",
            }}
          > 
            <StyledTableCell
              rateIndex={idx}
              linkedArrLength={linkedRatesArr[1].length}
              style={{
                textAlign: "center",
                position: "sticky",
                width: "2%",
                left: 0,
                backgroundColor:
                  chosenRates?.filter(
                    (r) => Number(r.TradeId) === Number(linkedRate.TradeId)
                  ).length > 0 && chosenRates.length > 0
                    ? "#2b2a2a"
                    : "#171717",
              }}
            >
              <GreenRadio
                size="small"
                checked={linkedRate?.checked}
                className={classes.checkbox}
                name="checkedH"
              />
            </StyledTableCell>
            {Object.entries(linkedRate).map(([key, value], index) => {
              {
                if (key !== "deal_id" && key !== "checked") {
                  if (
                    key === "TradeDate" ||
                    key === "StartDate" ||
                    key === "EndDate" ||
                    key === "AdditionalPaymentDate"
                  ) {
                    return (
                      <StyledTableCell
                        key={index}
                        rateIndex={idx}
                        linkedArrLength={linkedRatesArr[1].length}
                      >
                        {value !== "" && value !== null && value !== undefined
                          ? moment(value, "DD/MM/YYYY").format("DD-MMM-YYYY")
                          : ""}
                      </StyledTableCell>
                    );
                  } else if (
                    key === "Notional" ||
                    key === "AdditionalPaymentAmount"
                  ) {
                    return (
                      <StyledTableCell
                        key={index}
                        rateIndex={idx}
                        linkedArrLength={linkedRatesArr[1].length}
                      >
                        {numberWithCommas(value)}
                      </StyledTableCell>
                    );
                  } else {
                    return (
                      <StyledTableCell
                        key={index}
                        rateIndex={idx}
                        linkedArrLength={linkedRatesArr[1].length}
                      >
                        {value}
                      </StyledTableCell>
                    );
                  }
                }
              }
            })}
          </StyledTableRow>
        );
      })}
    </>
  );
};

export default DealRatesTableRow;
