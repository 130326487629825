import axios from "axios";
import { useEffect, useState } from "react";
import { END_POINT } from "../../../utils";
import * as actionSnackBar from "../../../store/snackbar/action";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { Typography } from "@material-ui/core";
import MarketMakersView from "./MarketMakersView";

function MarketMakers() {
  const dispatch = useDispatch();
  const [marketMakersData, setMarketMakersData] = useState();
  const [sortedMarketMakersData, setSortedMarketMakersData] = useState();
  const [loadingData, setLoadingData] = useState(false);
  const [isASD, setIsASD] = useState(true);

  const getMarketMakersData = async () => {
    const token = sessionStorage.getItem("token");
    try {
      setLoadingData(true);
      let res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}${END_POINT.DATA_MGMT}/market_makers`,
        { headers: { Authorization: token } }
      );
      setMarketMakersData(res.data);
      setSortedMarketMakersData(res.data);
      setTimeout(() => {
        setLoadingData(false);
      }, [500]);
    } catch (error) {
      console.log(error);
    }
  };

  const insertEmptyMM = () => {
    let newClient = {
      id: null,
      name: "",
      short_name: "",
      company: "",
      agreement: "",
      default_email: null,
      pm_ids: null,
      pm_names: null,
      pmsList: marketMakersData[0].pmsList.map((pm) => {
        return { ...pm, checked: false };
      }),
    };
    setMarketMakersData([newClient, ...marketMakersData]);
    setSortedMarketMakersData([newClient, ...sortedMarketMakersData]);
  };

  const deleteMM = async (mmId, clientIndex) => {
    if (mmId === null || mmId === undefined) {
      let newMarketMakersData = marketMakersData.filter(
        (mm, index) => index !== clientIndex
      );
      setMarketMakersData(newMarketMakersData);
      setSortedMarketMakersData(newMarketMakersData);
    } else {
      const token = sessionStorage.getItem("token");
      try {
        let res = await axios.delete(
          `${process.env.REACT_APP_BASE_URL}${END_POINT.DATA_MGMT}/delete_market_maker`,
          {
            headers: {
              Authorization: token,
            },
            data: {
              mmId: mmId,
            },
          }
        );
        dispatch(actionSnackBar.setSnackBar("success", res.data, 3000));
        let newMarketMakersData = marketMakersData.filter(
          (mm) => mm.id !== mmId
        );
        setMarketMakersData(newMarketMakersData);
      } catch (error) {
        dispatch(actionSnackBar.setSnackBar("error", error, 3000));
      }
    }
    return "deleted";
  };

  const handleSorting = (field) => {
    if (field === "PB") {
      field = "company";
    } else if (field === "Short Name") {
      field = "short_name";
    } else if (field === "MM Name") {
      field = "name";
    } else if (field === "Agreement") {
      field = "agreement";
    }
    if (field) {
      const sorted = [...marketMakersData].sort((a, b) => {
        return (
          a[field]?.toString().localeCompare(b[field]?.toString(), "en", {
            numeric: true,
          }) * (isASD ? 1 : -1)
        );
      });
      setIsASD(!isASD);
      setSortedMarketMakersData(sorted);
    }
  };

  const search = (e) => {
    if (e.target.value === "") {
      setSortedMarketMakersData(marketMakersData);
    } else {
      let filterd = sortedMarketMakersData.filter((mm) =>
        mm.name.toLowerCase().includes(e.target.value) || mm.short_name.toLowerCase().includes(e.target.value)
      );
      setSortedMarketMakersData(filterd)
    }
  };

  useEffect(() => {
    getMarketMakersData();
  }, []);

  return (
    <MarketMakersView
      marketMakersData={marketMakersData}
      sortedMarketMakersData={sortedMarketMakersData}
      loadingData={loadingData}
      handleSorting={handleSorting}
      insertEmptyMM={insertEmptyMM}
      deleteMM={deleteMM}
      getMarketMakersData={getMarketMakersData}
      search={search}
    />
  );
}

export default MarketMakers;
