import { Box, Button, Typography, Grid } from "@material-ui/core";
import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Copy } from "../../../../assets/copy.svg";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { id } from "date-fns/locale";

const OrderBook = ({ header }) => {
  const textAreaRef = useRef(null);
  const classes = useStyles();
  const [disableButton, setDisableButton] = useState(false);
  let { tableData, rfq } = useSelector((state) => state.rfqSlice);
  let { RfqQuoteParameters, volLegParams } = useSelector(
    (state) => state.rfqAggregatorSlice,
  );
  const checkRfq = rfq.filter((rfq) => rfq.checked);
  const rfq_id = sessionStorage.getItem("rfqId")
    ? sessionStorage.getItem("rfqId")
    : checkRfq[0]?.rfq_id;

  const filterVolLegParams = volLegParams.filter(
    (params) => Number(params.rfq_id) === Number(rfq_id),
  );
  const raw_price = () => {
    if (tableData.length === 0) {
      return;
    }
    if (RfqQuoteParameters?.quote_type === "DOUBLE_LEG_VOL") {
      return raw_vol_spread();
    }
    return raw_single_price();
  };
  const raw_vol_spread = () => {
    let multyRawPrice;
    if (Number(RfqQuoteParameters.choice_leg) === 1) {
      // if (tableData.length === 1) {
        multyRawPrice = [
          `RAW: ${RfqQuoteParameters.bbg_vol1} CH vs ${tableData[0].bid}/${tableData[0].offer}(${tableData[0].lp_bid}/${tableData[0].lp_offer})`,
          // `COVER: ${RfqQuoteParameters.bbg_vol1} CH vs ---/---`,
        ];
        return (
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            style={{ marginLeft: "10px" }}
          >
            <Grid
              item
              container
              direction="column"
              justifyContent="space-evenly"
              alignItems="center"
              style={{
                width: "400px",
              }}
            >
              <Grid item>
                <Typography
                  ref={textAreaRef}
                  style={{
                    fontSize: "1.16rem",
                    font: "normal normal normal 14px/21px monospace",
                    opacity: "87%",
                    color: "#6FCCC3",
                    width: "400px",
                  }}
                >
                  {multyRawPrice[0]}
                </Typography>
              </Grid>
              {/* <Grid item style={{ marginTop: "6px" }}>
                <Typography
                  ref={textAreaRef}
                  style={{
                    fontSize: "1.16rem",
                    font: "normal normal normal 14px/21px monospace",
                    opacity: "87%",
                    color: "#FFC5CD",
                    width: "400px",
                  }}
                >
                  {multyRawPrice[1]}
                </Typography>
              </Grid> */}
            </Grid>
          </Grid>
        );
      // } else if (tableData.length > 1) {
      //   multyRawPrice = [
      //     `RAW:${RfqQuoteParameters.bbg_vol1} CH vs ${tableData[0].bid}/${tableData[0].offer}(${tableData[0].lp_bid}/${tableData[0].lp_offer})`,
      //     `COVER: ${RfqQuoteParameters.bbg_vol1} CH vs ${tableData[1].bid}/${tableData[1].offer}(${tableData[1].lp_bid}/${tableData[1].lp_offer})`,
      //   ];
      //   return (
      //     <Grid
      //       container
      //       direction="row"
      //       justifyContent="flex-start"
      //       alignItems="center"
      //       style={{ marginLeft: "10px" }}
      //     >
      //       <Grid
      //         item
      //         container
      //         direction="column"
      //         justifyContent="space-evenly"
      //         alignItems="center"
      //         style={{
      //           width: "400px",
      //         }}
      //       >
      //         <Grid item>
      //           <Typography
      //             ref={textAreaRef}
      //             style={{
      //               fontSize: "1.16rem",
      //               font: "normal normal normal 14px/21px monospace",
      //               opacity: "87%",
      //               color: "#6FCCC3",
      //               width: "400px",
      //             }}
      //           >
      //             {multyRawPrice[0]}
      //           </Typography>
      //         </Grid>
      //         <Grid item style={{ marginTop: "6px" }}>
      //           <Typography
      //             ref={textAreaRef}
      //             style={{
      //               fontSize: "1.16rem",
      //               font: "normal normal normal 14px/21px monospace",
      //               opacity: "87%",
      //               color: "#FFC5CD",
      //               width: "400px",
      //             }}
      //           >
      //             {multyRawPrice[1]}
      //           </Typography>
      //         </Grid>
      //       </Grid>
      //     </Grid>
      //   );
      // }
    } else if (Number(RfqQuoteParameters.choice_leg) === 2) {
      // if (tableData.length === 1) {
        multyRawPrice = [
          `RAW: ${tableData[0].bid}/${tableData[0].offer} vs ${RfqQuoteParameters.bbg_vol2} CH (${tableData[0].lp_bid}/${tableData[0].lp_offer})`,
          // `COVER: ---/--- vs ${RfqQuoteParameters.bbg_vol2} CH`,
        ];
        return (
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            style={{ marginLeft: "10px" }}
          >
            <Grid
              item
              container
              direction="column"
              justifyContent="space-evenly"
              alignItems="center"
              style={{
                width: "400px",
              }}
            >
              <Grid item>
                <Typography
                  ref={textAreaRef}
                  style={{
                    fontSize: "1.16rem",
                    font: "normal normal normal 14px/21px monospace",
                    opacity: "87%",
                    color: "#6FCCC3",
                    width: "400px",
                  }}
                >
                  {multyRawPrice[0]}
                </Typography>
              </Grid>
              {/* <Grid item style={{ marginTop: "6px" }}>
                <Typography
                  ref={textAreaRef}
                  style={{
                    fontSize: "1.16rem",
                    font: "normal normal normal 14px/21px monospace",
                    opacity: "87%",
                    color: "#FFC5CD",
                    width: "400px",
                  }}
                >
                  {multyRawPrice[1]}
                </Typography>
              </Grid> */}
            </Grid>
          </Grid>
        );
      // } else if (tableData.length > 1) {
      //   multyRawPrice = [
      //     `RAW: ${tableData[0].bid}/${tableData[0].offer} vs ${RfqQuoteParameters.bbg_vol2} CH (${tableData[0].lp_bid}/${tableData[0].lp_offer})`,
      //     `COVER: ${tableData[0].bid}/${tableData[0].offer} vs ${RfqQuoteParameters.bbg_vol2} CH (${tableData[0].lp_bid}/${tableData[0].lp_offer})`,
      //   ];
      //   return (
      //     <Grid
      //       container
      //       direction="row"
      //       justifyContent="flex-start"
      //       alignItems="center"
      //       style={{ marginLeft: "10px" }}
      //     >
      //       <Grid
      //         item
      //         container
      //         direction="column"
      //         justifyContent="space-evenly"
      //         alignItems="center"
      //         style={{
      //           width: "400px",
      //         }}
      //       >
      //         <Grid item>
      //           <Typography
      //             ref={textAreaRef}
      //             style={{
      //               fontSize: "1.16rem",
      //               font: "normal normal normal 14px/21px monospace",
      //               opacity: "87%",
      //               color: "#6FCCC3",
      //               width: "400px",
      //             }}
      //           >
      //             {multyRawPrice[0]}
      //           </Typography>
      //         </Grid>
      //         <Grid item style={{ marginTop: "6px" }}>
      //           <Typography
      //             ref={textAreaRef}
      //             style={{
      //               fontSize: "1.16rem",
      //               font: "normal normal normal 14px/21px monospace",
      //               opacity: "87%",
      //               color: "#FFC5CD",
      //               width: "400px",
      //             }}
      //           >
      //             {multyRawPrice[1]}
      //           </Typography>
      //         </Grid>
      //       </Grid>
      //     </Grid>
      //   );
      // }
    }
    return multyRawPrice;
  };
  const raw_single_price = () => {
    let multyRawPrice;
    // if (tableData.length === 1) {
      multyRawPrice = [
        `RAW: ${tableData[0].bid}/${tableData[0].offer} (${tableData[0].lp_bid}/${tableData[0].lp_offer})`,
        // `COVER:----/----`,
      ];
      return (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          style={{ marginLeft: "10px" }}
        >
          <Grid
            item
            container
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
            style={{
              width: "400px",
            }}
          >
            <Grid item>
              <Typography
                ref={textAreaRef}
                style={{
                  fontSize: "1.16rem",
                  font: "normal normal normal 14px/21px monospace",
                  opacity: "87%",
                  color: "#6FCCC3",
                  width: "400px",
                }}
              >
                {multyRawPrice[0]}
              </Typography>
            </Grid>
            {/* <Grid item style={{ marginTop: "6px" }}>
              <Typography
                ref={textAreaRef}
                style={{
                  fontSize: "1.16rem",
                  font: "normal normal normal 14px/21px monospace",
                  opacity: "87%",
                  color: "#FFC5CD",
                  width: "400px",
                }}
              >
                {multyRawPrice[1]}
              </Typography>
            </Grid> */}
          </Grid>
        </Grid>
      // );
    // } else if (tableData.length > 1) {
    //   multyRawPrice = [
    //     `RAW: ${tableData[0].bid}/${tableData[0].offer} (${tableData[0].lp_bid}/${tableData[0].lp_offer})`,
    //     `COVER: ${tableData[1].bid}/${tableData[1].offer} (${tableData[1].lp_bid}/${tableData[1].lp_offer})`,
    //   ];
    //   return (
    //     <Grid
    //       container
    //       direction="row"
    //       justifyContent="flex-start"
    //       alignItems="center"
    //       style={{ marginLeft: "10px" }}
    //     >
    //       <Grid
    //         item
    //         container
    //         direction="column"
    //         justifyContent="space-evenly"
    //         alignItems="center"
    //         style={{
    //           width: "400px",
    //         }}
    //       >
    //         <Grid item>
    //           <Typography
    //             ref={textAreaRef}
    //             style={{
    //               fontSize: "1.16rem",
    //               font: "normal normal normal 14px/21px monospace",
    //               opacity: "87%",
    //               color: "#6FCCC3",
    //               width: "400px",
    //             }}
    //           >
    //             {multyRawPrice[0]}
    //           </Typography>
    //         </Grid>
    //         <Grid item style={{ marginTop: "6px" }}>
    //           <Typography
    //             ref={textAreaRef}
    //             style={{
    //               fontSize: "1.16rem",
    //               font: "normal normal normal 14px/21px monospace",
    //               opacity: "87%",
    //               color: "#FFC5CD",
    //               width: "400px",
    //             }}
    //           >
    //             {multyRawPrice[1]}
    //           </Typography>
    //         </Grid>
        //   </Grid>
        // </Grid>
      );
    // }
  };
  const copy_to_clip = async () => {
    const getCopied = () => {
      if (RfqQuoteParameters?.quote_type === "DOUBLE_LEG_VOL") {
        if (Number(RfqQuoteParameters.choice_leg) === 1) {
          if (tableData.length === 1) {
            return `RAW: ${RfqQuoteParameters.bbg_vol1} CH vs ${tableData[0].bid}/${tableData[0].offer}(${tableData[0].lp_bid}/${tableData[0].lp_offer}) \nCOVER: ${ RfqQuoteParameters.bbg_vol1 } CH vs-- - /---`;
            // \nCOVER: ${ RfqQuoteParameters.bbg_vol1 } CH vs-- - /---`;
          } else if (tableData.length > 1) {
            return `RAW:${RfqQuoteParameters.bbg_vol1} CH vs ${tableData[0].bid}/${tableData[0].offer}(${tableData[0].lp_bid}/${tableData[0].lp_offer}) \nCOVER: ${ RfqQuoteParameters.bbg_vol1 } CH vs ${ tableData[1].bid } /${tableData[1].offer}(${tableData[1].lp_bid}/${ tableData[1].lp_offer })`;
            // \nCOVER: ${ RfqQuoteParameters.bbg_vol1 } CH vs ${ tableData[1].bid } /${tableData[1].offer}(${tableData[1].lp_bid}/${ tableData[1].lp_offer })`;
          }
        } else if (Number(RfqQuoteParameters.choice_leg) === 2) {
          if (tableData.length === 1) {
            return `RAW: ${tableData[0].bid}/${tableData[0].offer} vs ${RfqQuoteParameters.bbg_vol2} CH (${tableData[0].lp_bid}/${tableData[0].lp_offer}) \nCOVER: ${ RfqQuoteParameters.bbg_vol2 } CH vs-- - /---`;
            // \nCOVER: ${ RfqQuoteParameters.bbg_vol2 } CH vs-- - /---`;
          } else if (tableData.length > 1) {
            return `RAW: ${tableData[0].bid}/${tableData[0].offer} vs ${RfqQuoteParameters.bbg_vol2} CH (${tableData[0].lp_bid}/${tableData[0].lp_offer})\nCOVER: ${tableData[1].bid}/${tableData[1].offer} vs ${RfqQuoteParameters.bbg_vol2} CH (${tableData[1].lp_bid}/${tableData[1].lp_offer})`;
          }
        }
      } else {
        if (tableData.length === 1) {
          return `RAW: ${tableData[0].bid}/${tableData[0].offer} (${tableData[0].lp_bid}/${tableData[0].lp_offer}) \nCOVER: ----/----`;
        } else if (tableData.length > 1) {
          return `RAW: ${tableData[0].bid}/${tableData[0].offer} (${tableData[0].lp_bid}/${tableData[0].lp_offer})  \nCOVER: ${ tableData[1].bid } /${tableData[1].offer} (${tableData[1].lp_bid}/${ tableData[1].lp_offer })`;

        }
      }
    };
    
    setDisableButton(true);
    await navigator.clipboard.writeText(getCopied());
  };
  return (
    <Grid
      className={classes.rfqModal}
      style={{ display: "flex", flexDirection: "column", height: "10vh" }}
    >
      <Grid item container className={classes.rfqodalHeader}>
        <Typography className={classes.header}>{header}</Typography>
        <Typography className={classes.icon}>
          <Copy
            onClick={copy_to_clip}
            className={clsx({
              [classes.copybuttonDisable]: disableButton,
            })}
          />
        </Typography>
      </Grid>
      <Grid
        style={{
          backgroundColor: "#222222",
          display: "flex",
          height: "100%",
          flex: "5",
        }}
      >
        {raw_price()}
      </Grid>
    </Grid>
  );
};

export default OrderBook;

export const useStyles = makeStyles(() => ({
  rfqModal: {
    border: "1px solid #686B76",
    borderRadius: "8px 8px 0px 0px",
  },
  rfqodalHeader: {
    color: "#FFFFFF",
    borderBottom: "1px solid #686B76",
    borderRadius: "8px 8px 0px 0px",
    height: "43px",
    font: "normal normal normal 20px/24px Inter",
    justifyContent: "space-between",
  },
  header: {
    marginTop: "15px",
    marginLeft: "15px",
  },
  icon: {
    marginTop: "15px",
    marginRight: "15px",
  },
  input: {
    marginLeft: "10px",
    marginRight: "10px",
    border: "1px solid #686B76",
    borderRadius: "8px",
    opacity: 1,
    width: "89px !important",
    height: "40px",
    color: "white",
  },
  bid: {
    marginTop: "2%",
    color: "#00D865",
  },
  offer: {
    marginTop: "2%",
    color: " #FF3400",
  },
  text: {
    marginTop: "revert",
    textAlign: "center",
    color: "#686B76",
  },
  copybuttonDisable: {
    filter: "invert(0.5) sepia(1) hue-rotate(200deg)",
  },
}));
