import {
    makeStyles, withStyles,
  } from "@material-ui/core";
import { VisibilityOff } from "@material-ui/icons";
import VisibilityIcon from "@material-ui/icons/Visibility";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    position: "absolute",
    height: "343px",
    width: "674px",
    background: "#222222",
    borderRadius: "8px",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  input: {
    border: "1px solid #686B76",
    borderRadius: "4px",
    opacity: 1,
    maxWidth: "311px",
    width: "265px",
    height: "34px",
    maxHeight: "40px",
    marginBottom: "20px",
    "& .MuiInputBase-input": {
      color: "#ffffff",
      fontSize: "1.3125rem",
      padding: "10px 10px 11px",
    },
  },
  button: {
    background: "#2692FF",
    width: "196px",
    color: "#ffffff",
    textTransform: "none",
    fontSize: "1.125rem",
    "&:hover": {
      background: "#1976d2",
    },
  },
  logOut: {
    background: "#222222",
    color: "#ffffff",
    textTransform: "none",
    fontSize: "1.125rem",
    "&:hover": {
      background: "#1976d2",
    },
  },
}));

export const StyledVisabilityIcon = withStyles(() => ({
  root: {
    fill: "#ffffff",
  },
}))(VisibilityIcon);

export const StyledNoneVisabilityIcon = withStyles(() => ({
  root: {
    fill: "#ffffff",
  },
}))(VisibilityOff);
