import { createSlice } from "@reduxjs/toolkit";
const token = sessionStorage.getItem("token");

export const ratesSlice = createSlice({
  name: "ratesSlice",
  initialState: {
    dataLoading: false,
    rates: [],
    chosenRates: [],
    chosenDeals: [],
    exchangeRates: {},
  },
  reducers: {
    setDataLoading: (state, action) => {
      state.dataLoading = action.payload;
    },
    setRates: (state, action) => {
      state.rates = action.payload;
      state.dataLoading = false;
    },
    setExchangeRates: (state, action) => {
      state.exchangeRates = action.payload;
    },
    setChosenRates: (state, action) => {
      state.chosenRates = action.payload;
    },
    setChosenDeals: (state, action) => {
      state.chosenDeals = action.payload;
    },
  },
});

export const handleNewRates = (newRates) => (dispatch, getState) => {
  console.log('newRates: ', newRates)
  newRates = newRates.map((rate) => {
    rate = { ...rate, isNew: true, TradeId: +rate.TradeId ,deal_id : null };
    return rate;
  });
  let currentRates = getState()?.ratesSlice?.rates;
  let allRates = [...newRates, ...currentRates];
  dispatch(setRates(allRates));
  setTimeout(() => {
    allRates = allRates.map((rate) => {
      if (rate.isNew === true) {
        rate = { ...rate, isNew: false };
        return rate;
      } else {
        return rate;
      }
    });
    dispatch(setRates(allRates));
  }, 5000);
};

export const handleRateCheck = (TradeId) => async (dispatch, getState) => {
  const rates = getState().ratesSlice?.rates;
  const chosenRates = getState().ratesSlice?.chosenRates;
  let ratesCopy = [...rates];
  const chosenDeals = getState().ratesSlice?.chosenDeals;
  let chosenDealsCopy = [...chosenDeals];
  let chosenRatesCopy = [...chosenRates];
  let chosenRate = ratesCopy.find((rate) => rate.TradeId === TradeId);
  let chosenRateIdx = ratesCopy.findIndex((t) => t.TradeId === TradeId);
  if (chosenDealsCopy.length > 0) {
    dispatch(setChosenDeals([]));
    ratesCopy = ratesCopy.map((rate, index) => {
      if (rate.checked === true) {
        return { ...rate, checked: false };
      } else if (index === chosenRateIdx) {
        return { ...rate, checked: true };
      } else {
        return rate;
      }
    });
    dispatch(setRates(ratesCopy));
    let newChosenRates = ratesCopy.filter((rate) => rate.checked === true);
    console.log("newChosenRates: ", newChosenRates);
    dispatch(setChosenRates(newChosenRates));
    chosenRate = { ...chosenRate, checked: !chosenRate.checked };
  } else {
    chosenRate = { ...chosenRate, checked: !chosenRate.checked };
    console.log("ratesCopy: ", ratesCopy);
    ratesCopy[chosenRateIdx] = chosenRate;
    if (chosenRate.checked === true) {
      chosenRatesCopy = [...chosenRatesCopy, chosenRate];
    } else {
      let newChosenRates = chosenRatesCopy.filter(
        (rate) => rate.TradeId !== chosenRate.TradeId
      );
      chosenRatesCopy = newChosenRates;
    }
    dispatch(setRates(ratesCopy));
    dispatch(setChosenRates(chosenRatesCopy));
  }
};

export const getRatesDataAsync =
  (rates, type) => async (dispatch, getState) => {
    let tempData = [];
    if (rates !== undefined && rates !== null && rates.length > 0) {
      tempData = rates.map((r) => {
        return { ...r, checked: false };
      });
    }

    switch (type) {
      case "get_rates":
        dispatch(setRates(tempData));
        break;
      case "new_rates":
        dispatch(handleNewRates(tempData));
        break;
      case "handle_rates_deal":
        const ratesState = getState().ratesSlice?.rates;
        const indexToRemove = ratesState.findIndex(
          (rate) => rate.TradeId === tempData[0].TradeId
        );
        const copyOfRateState = [...ratesState];
        if (indexToRemove !== -1) {
          copyOfRateState.splice(indexToRemove, 1, tempData[0]);
        } else if (indexToRemove === -1) {
          copyOfRateState.push(tempData[0]);
        }
        dispatch(setRates(copyOfRateState));
        dispatch(setChosenRates([]));

        break;
      default:
        break;
    }
  };

export const handleDealRowSelect = (dealPassed) => (dispatch, getState) => {
  const rates = getState().ratesSlice?.rates;
  const chosenDeals = getState().ratesSlice?.chosenDeals;
  const chosenRates = getState().ratesSlice?.chosenRates;
  let ratesCopy = [...rates];
  let chosenDealsCopy = [...chosenDeals];
  let chosenRatesCopy = [...chosenRates];

  if (chosenDeals.length === 0) {
    chosenDealsCopy.push(dealPassed[0]);
  } else {
    chosenDealsCopy = [];
    chosenDealsCopy.push(dealPassed[0]);
  }
  if (chosenRatesCopy.length > 0) {
    chosenRatesCopy = [];
    chosenRatesCopy = dealPassed[1];
    ratesCopy = ratesCopy.map((rate, index) => {
      if (!dealPassed[1].some((dp) => dp.TradeId === rate.TradeId)) {
        return { ...rate, checked: false };
      } else {
        return { ...rate, checked: true };
      }
    });
  } else {
    ratesCopy = ratesCopy.map((rate) => {
      if (dealPassed[1].some((dp) => dp.TradeId === rate.TradeId)) {
        return { ...rate, checked: true };
      }
      return rate;
    });
    chosenRatesCopy = dealPassed[1];
  }
  dispatch(setRates(ratesCopy));
  dispatch(setChosenDeals(chosenDealsCopy));
  dispatch(setChosenRates(chosenRatesCopy));
};
export const {
  setDataLoading,
  setRates,
  setExchangeRates,
  setChosenRates,
  setChosenDeals,
} = ratesSlice.actions;

export default ratesSlice.reducer;
