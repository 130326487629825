import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: "transparent", // Transparent background
    color: "#FFFFFF", // White text color
    border: "1px solid #686B76", // Custom border color
    borderRadius: theme.shape.borderRadius, // Apply border radius
    padding: theme.spacing(0.2, 1), // Decrease padding for smaller height
    width: "100px", // Set smaller width
    height: "25px", // Explicitly set the height to make it smaller
    fontSize: "1.2rem !important", // Slightly reduce font size for balance
    "&::placeholder": {
      color: "#FFFFFF", // Placeholder color
    },
    "&:focus-within .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFFFFF", // Change border color when focused
    },
    "&:hover": {
      borderColor: "#FFFFFF", // Change border color to white on hover
    },
  },
  // Styling to hide arrows for number input
  inputField: {
    "& input[type=number]": {
      "-moz-appearance": "textfield", // Firefox
      "-webkit-appearance": "none", // Chrome, Safari, Edge, Opera
      margin: 0,
      fontSize: "1.2rem !important",
    },
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none", // Chrome, Safari, Edge, Opera
      margin: 0,
    },
  },
  inputWrapper: {
    marginTop: theme.spacing(0), // Remove spacing on top
    display: "flex",
    alignItems: "center", // Align label and input on the same row
  },
  label: {
    marginRight: theme.spacing(2), // Add space between the label and input
  },

  checkerContainer: {
    color: "white",
    // border: "1px solid #686B76",
    height: 140,
    width: 680,
    marginTop: "20px",
    paddingLeft: "20px",
    paddingTop: "20px",
    fontStyle: "italic",
  },
}));
