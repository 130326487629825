import axios from "axios";
import { END_POINT } from "../../../utils/index";

const tradeSplitter = async (obj) => {
  try {
    const { splitNumArr, splitArrValues, selectedTradeToEdit, splitOption } =
      obj;
    const uuid = selectedTradeToEdit.uuid;
    let quantityByType;
    const createdBy = sessionStorage.getItem("id");
    const token = sessionStorage.getItem("token");

    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}` +
        END_POINT.FI_TRADES +
        "/get_trade_by_uuid",
      { uuid },
      { headers: { Authorization: token } },
    );
    const tradeSource = res.data[0];
    
    const tradesList = splitNumArr.map((num, index) => {
      if (splitOption === "notional") {
        quantityByType = splitArrValues[index].splitOption;
      } else if (splitOption === "percentage") {
        quantityByType =
          (splitArrValues[index].splitOption * tradeSource.quantity) / 100;
      }
      const newAccruedInterest =
        (Number(tradeSource.accrued_interest) / Number(tradeSource.quantity)) *
        Number(quantityByType);
      const newNetCash =
        (Number(tradeSource.net_cash) / Number(tradeSource.quantity)) *
        Number(quantityByType);

      const obj = {
        status: tradeSource.trader_id === 9 ? tradeSource.status : "live",
        trader: tradeSource.trader_id,
        client: splitArrValues[index].clientSplitId,
        tradeDate: tradeSource.trade_date,
        settlementDate: tradeSource.settlement_date,
        isin: tradeSource.isin,
        securityName: tradeSource.security_name,
        quantity: quantityByType,
        ourSide: tradeSource.side,
        price: tradeSource.price,
        accruedInterest: newAccruedInterest,
        currency: tradeSource.currency_id,
        time: tradeSource.execution_time,
        createdBy: createdBy,
        comment: tradeSource.comment,
        uuid: "",
        lastUpdatedBy: "",
        lastUpdatedAt: "",
        netCash: newNetCash,
        netCashCurrency: tradeSource.net_cash_currency_id,
        unresolvedClient: tradeSource.unresolved_client,
        unresolvedTrader: tradeSource.unresolved_trader,
      };
      return obj;
    });
    return tradesList;
  } catch (error) {
    console.log(error);
  }
};

export default tradeSplitter;
