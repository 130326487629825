import React,{ useState,useEffect } from 'react';
import {Box,Modal, Grid,TextField} from "@material-ui/core";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import axios from "axios"
import { useDispatch, useSelector } from 'react-redux';
import * as rfqSlice from "../.././../../store/rfq/rfqSlice";
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Checkbox,
  TableBody,
  Button,
  TableCell
} from "@material-ui/core";

export const RfqAggreatorModal = ({rfq_object,setrfq_object,column}) => {
  const classes = useStyles();
  const [header,serHeader]= useState(["BID LP","BID","OFFER","OFFER LP","SPREAD"])
  const theme = useTheme();
  const lgScreen = theme.breakpoints.between("960px", "1280px")
  const rfqMode = useSelector((state) => state.rfqSlice.rfqMode)
  const dispatch = useDispatch()
  let tableData = useSelector((state) => state.rfqSlice.tableData)


  return (
   <Grid container className={tableData.length === 0 ? classes.rfqEmptyModal : classes.rfqModal} style={{height:"fit-content"}}>
      <Grid className={classes.rfqodalHeader}>
    <TableContainer>
        <Table size={lgScreen ? "medium" : "small"}>
          <TableHead>
            <TableRow classes={classes.test}>
            {header.map((key, index) => {
            return (
              <TableCell key={index}>
                <Typography className="headerText" style={{fontSize:"1rem",font:"Inter",textAlign:"center"}}> {key}</Typography>
              </TableCell>
            );
          })}
            </TableRow>
          </TableHead>
          <TableBody>
             {tableData.length > 0 ? tableData.map((row,index) => {
               return (
                 <TableRow style={{ background: "#222222" }} key={index}>
                 <StyleTableCell><Typography className={classes.lpBid}>{row.lp_bid}</Typography></StyleTableCell>
                 <StyleTableCell><Typography className={classes.bid}>{row.bid.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</Typography></StyleTableCell>
                 <StyleTableCell><Typography className={classes.offer}>{row.offer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography></StyleTableCell>
                 <StyleTableCell><Typography className={classes.lpOffer}>{row.lp_offer}</Typography></StyleTableCell>
                 <StyleTableCell><Typography className={classes.spread}>{row.spread}</Typography></StyleTableCell>
            </TableRow>

                )
             }): <TableRow></TableRow>}
             
          </TableBody>
        </Table>
      </TableContainer>
      </Grid>
    </Grid>
  );
};
export const useStyles = makeStyles((theme) => ({
  rfqModal: {
    border: "1px solid #686B76",
    borderRadius: "8px 8px 0px 0px",
    display: "flex",
    flexDirection: "column",
    height:"22.5vh",
    background: "#222222",
    overflow:"auto",
    
  },
  rfqEmptyModal: {
    border: "1px solid #686B76",
    borderRadius: "8px 8px 0px 0px",
    display: "flex",
    flexDirection: "column",
    height:"22.5vh",
    background: "#222222",

  },
      rfqodalHeader:{
        color:"#FFFFFF",
        borderRadius: "8px 8px 0px 0px",
        font:" normal normal normal 20px/24px Inter",
        display:'flex',
        justifyContent: "space-around",
      },
      lpBid:{
        textAlign: "center",
        font: "normal normal normal 12px/20px Inter",
        color: "#FFFFFF",
        fontSize:"1rem",
        opacity : "87%"
      },
      bid:{
        textAlign: "center",
        font: "normal normal normal 17px/20px Inter",
        color: "#86FFF4",
        opacity: 0.87,
        fontSize:"1rem"
      },
      offer:{
        textAlign: "center",
        font: "normal normal normal 17px/20px Inter",
        color: "#FFC5CD ",
        opacity: 0.87,
        fontSize:"1rem"
      },
      lpOffer:{
        textAlign: "center",
        font: "normal normal normal 17px/20px Inter",
        color: "#FFFFFF",
        opacity: 0.87,
        fontSize:"1rem"
      },
      spread:{
        textAlign: "center",
        font: "normal normal normal 17px/20px Inter",
        color: "#FFFFFF",
        opacity: 0.87,
        fontSize:"1rem"
      },
      message:{
        textAlign:"center"
      },
      
}));
export const StyleTableCell = withStyles((theme) => ({
	root: {
    padding : "5px"
    // padding : `${theme.us : theme.breakpoints.up("1280px") ? "10px" : "10px"}`

  }
}))(TableCell)
