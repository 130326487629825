import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useStyles } from "../../style/style";

const CustomTable = ({ children, companyId }) => {
  const classes = useStyles();

  // Extracting thead and tbody from children
  const thead = children[0].props.children.props.children;
  const tbody = children[1].props.children;
  // Calculate column widths
  const columnWidth = `${100 / thead.length}%`;
  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table>
        <TableHead>
          <TableRow>
            {thead.map((th, index) => (
              <TableCell
                key={index}
                // style={{ width: columnWidth }}
                className={
                  companyId === 2
                    ? classes.headerCellOg
                    : classes.headerCellMakor
                }
              >
                {th.props.children}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tbody.map((tr, rowIndex) => (
            <TableRow key={rowIndex}>
              {tr.props.children.map((td, cellIndex) => (
                <TableCell
                  key={cellIndex}
                  style={{ width: columnWidth }}
                  className={
                    rowIndex === 0
                      ? companyId === 2
                        ? classes.firstCellOg
                        : classes.firstCellMakor
                      : classes.cell
                  }
                >
                  {td.props.children}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
