import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useStyles } from "../../../Styles/usersManagement";
import { createUser } from "../utils/apiCalls";
import { entitlementList, initialForm, teamList } from "../utils/presets";
import { useDispatch } from "react-redux";

export const CreateUser = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [formDetails, setFormDetails] = useState(initialForm);
  const [selectedOptions, setSelectedOptions] = useState(entitlementList);
  const [selectedTeam, setSelectedTeam] = useState(teamList);

  const handleChangeEntitleMent = (event, change, item) => {
    const index = selectedOptions.findIndex((obj) => item.value === obj.value);
    setSelectedOptions((prev) => {
      const updatedOptions = [...prev]; // Create a shallow copy of the previous state
      updatedOptions[index].isChecked = change; // Update the element at the specified index
      const entitlement = updatedOptions.filter((e) => e.isChecked);
      if (entitlement.length > 0) {
        handleFieldsUpdate(
          entitlement.map((e) => e.value),
          "entitlement",
        );
      } else {
        handleFieldsUpdate(entitlement, "entitlement");
      }

      return updatedOptions; // Return the updated array
    });
  };
  const handleChangeTeam = (event, change, item) => {
    setSelectedTeam(
      selectedTeam.map((t) => {
        if (t.value === item.value) {
          if (change) {
            setFormDetails((pre) => ({ ...pre, team: item.value }));
          }
          return { ...t, isChecked: change };
        } else {
          if (!change) {
            setFormDetails((pre) => ({ ...pre, team: item.value }));
          }
          return { ...t, isChecked: !change };
        }
      }),
    );
  };
  const handleFieldsUpdate = (value, key) => {
    if (
      (key === "firstName" && formDetails.lastName !== "") ||
      (key === "lastName" && formDetails.firstName !== "")
    ) {
      const firstName = key === "firstName" ? value : formDetails.firstName;
      const lastName = key === "lastName" ? value : formDetails.lastName;
      const username = combineNames(firstName, lastName);
      setFormDetails((pre) => ({
        ...pre,
        [key]: value,
        username,
        firstName: firstName.charAt(0).toUpperCase() + firstName.slice(1),
        lastName: lastName.charAt(0).toUpperCase() + lastName.slice(1),
      }));
    } else {
      setFormDetails((pre) => ({ ...pre, [key]: value }));
    }
  };
  const onSubmit = async () => {
    const res = createUser(formDetails, dispatch);
    if (res) {
      setFormDetails(initialForm);
      setSelectedOptions(entitlementList);
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item>
        <Typography className={classes.typography}>Users Management</Typography>
      </Grid>
      <Grid
        item
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <TextField
              id="first_name"
              label="First Name"
              variant="outlined"
              className={classes.stringInput}
              autoComplete="new-password"
              onChange={(e) => handleFieldsUpdate(e.target.value, "firstName")}
              value={formDetails.firstName}
            />
          </Grid>
          <Grid item>
            <TextField
              id="last_name"
              label="Last Name"
              variant="outlined"
              className={classes.stringInput}
              autoComplete="new-password"
              onChange={(e) => handleFieldsUpdate(e.target.value, "lastName")}
              value={formDetails.lastName}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <TextField
              id="company"
              label="Company"
              variant="outlined"
              className={classes.stringInput}
              autoComplete="new-password"
              onChange={(e) => handleFieldsUpdate(e.target.value, "company")}
              value={formDetails.company}
            />
          </Grid>
          <Grid item>
            <TextField
              id="username"
              label="Username"
              variant="outlined"
              className={classes.stringInput}
              autoComplete="new-password"
              onChange={(e) => handleFieldsUpdate(e.target.value, "username")}
              value={formDetails.username}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <TextField
              id="email"
              label="Email"
              variant="outlined"
              className={classes.stringInput}
              autoComplete="new-password"
              onChange={(e) => handleFieldsUpdate(e.target.value, "email")}
              value={formDetails.email}
            />
          </Grid>
          <Grid item>
            <TextField
              id="password"
              label="Password"
              variant="outlined"
              className={classes.stringInput}
              autoComplete="new-password"
              onChange={(e) => handleFieldsUpdate(e.target.value, "password")}
              type="password"
              value={formDetails.password}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          {formDetails.entitlement.includes("fi-blotter") ? (
            <Grid item>
              <TextField
                id="rsp"
                label="RSP"
                variant="outlined"
                className={classes.stringInput}
                autoComplete="new-password"
                onChange={(e) => handleFieldsUpdate(e.target.value, "rsp")}
                value={formDetails.rsp}
              />
            </Grid>
          ) : null}
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <TextField
              className={classes.selectRoot}
              label="Role"
              variant="outlined"
              select
              // value={equityDetails.statusNote}
              // onChange={(e) =>
              //   dispatch(
              //     equitiesSpreadSlice.handleEquityDetailsInput({
              //       key: "comment",
              //       value: e.target.value,
              //     }),
              //   )
              // }
              SelectProps={{
                className: classes.selectStrategy,
                style: { height: "34px" },
                MenuProps: {
                  anchorOrigin: {
                    vertical: "bottom", // Set the menu position to appear below the TextField
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  // Prevents the menu from appearing below the input field
                },
              }}
              onChange={(e) => handleFieldsUpdate(e.target.value, "role")}
              value={formDetails.role}
            >
              <MenuItem value=""></MenuItem>
              <MenuItem value="trader">Trader</MenuItem>
              <MenuItem value="Operation">Operation</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="label" className={classes.formLabel}>
              Assign Entitlement
            </FormLabel>
            <FormGroup>
              <Grid
                item
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                {selectedOptions.map((item) => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={item.isChecked}
                          onChange={(e, change) =>
                            handleChangeEntitleMent(e, change, item)
                          }
                          name={item.value}
                          style={{
                            color: item.isChecked ? "white" : "#686B76",
                            "&.Mui-checked": {
                              color: "white",
                            },
                          }}
                        />
                      }
                      label={
                        <span
                          style={{
                            color: item.isChecked ? "white" : "#686B76",
                          }}
                        >
                          {item.value}
                        </span>
                      }
                    />
                  );
                })}
              </Grid>
            </FormGroup>
          </FormControl>
        </Grid>
        {formDetails.entitlement.includes("pricer") ? (
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="label" className={classes.formLabel}>
                Assign Team
              </FormLabel>
              <FormGroup>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  {selectedTeam.map((item) => {
                    return (
                      <FormControlLabel
                        key={item.value}
                        control={
                          <Checkbox
                            checked={item.isChecked}
                            onChange={(e, change) =>
                              handleChangeTeam(e, change, item)
                            }
                            name={item.value}
                            style={{
                              color: item.isChecked ? "white" : "#686B76",
                              "&.Mui-checked": {
                                color: "white",
                              },
                            }}
                          />
                        }
                        label={
                          <span
                            style={{
                              color: item.isChecked ? "white" : "#686B76",
                            }}
                          >
                            {item.value}
                          </span>
                        }
                      />
                    );
                  })}
                </Grid>
              </FormGroup>
            </FormControl>
          </Grid>
        ) : null}
      </Grid>
      <Grid item style={{ marginTop: "20px" }}>
        <Button variant="contained" onClick={onSubmit}>
          Create User
        </Button>
      </Grid>
    </Grid>
  );
};

export const combineNames = (firstName, lastName) => {
  const firstLetter = firstName.charAt(0).toLowerCase();
  const lowerLastName = lastName.toLowerCase();
  const result = firstLetter + lowerLastName;
  return result;
};
