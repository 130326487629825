import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
  useMemo,
} from "react";

const ARROW_UP = "\u2191";
const ARROW_DOWN = "\u2193";

function useCssClasses() {
  const [classesMap, setClassesMap] = useState({});
  const classesMapString = useMemo(
    () =>
      Object.keys(classesMap)
        .filter((key) => classesMap[key])
        .join(" "),
    [classesMap],
  );

  const classHandler = (className, on) => {
    // const nothingHasChanged = !!classesMap[className] == on;
    // if (!nothingHasChanged) {
    //   return this;
    // }

    setClassesMap((prev) => ({ ...prev, [className]: on }));
  };

  return [classesMapString, classHandler];
}

const CustomAnimationRenderer = forwardRef((props, ref) => {
  const [value, setValue] = useState();
  const [delta, setDelta] = useState();
  const [refreshCount, setRefreshCount] = useState();
  const [lastValue, setLastValue] = useState();

  const eValueRef = useRef();
  const eDeltaRef = useRef();

  const [valueCssClasses, setValueCssClasses] = useCssClasses();
  const [deltaCssClasses, setDeltaCssClasses] = useCssClasses();

  const deltaClassName = useMemo(
    () => `ag-value-change-delta ${deltaCssClasses}`,
    [deltaCssClasses],
  );

  const valueClassName = useMemo(
    () => `ag-value-change-value ${valueCssClasses}`,
    [valueCssClasses],
  );

  useEffect(() => {
    if (props.data === undefined) {
      setValue(props.valueFormatted ? props.valueFormatted : props.value);
      setRefreshCount(0);
      setLastValue(props.value);
    }
  }, []);

  useImperativeHandle(ref, () => {
    return {
      refresh: (params) => {
        if (params.value === lastValue) {
          return false;
        }
        setValue(props.value);
        if (props.value !== undefined && lastValue !== undefined) {
          let _delta = props.value - lastValue;

          _showDelta(params, _delta);
        }

        // highlight the current value, but only if it's not new, otherwise it
        // would get highlighted first time the value is shown

        if (lastValue) {
          setValueCssClasses("ag-value-change-value-highlight", true);
        }

        _setTimerToRemoveDelta();
        setLastValue(params.value);
        return true;
      },
    };
  });

  const _showDelta = (params, _delta) => {
    let absDelta = Math.abs(_delta);
    let valueFormatted = params.formatValue(absDelta);
    let valueToUse = valueFormatted ? valueFormatted : absDelta;
    let deltaUp = _delta >= 0;
    if (deltaUp) {
      setDelta(ARROW_UP);
    } else {
      setDelta(ARROW_DOWN);
    }

    _addOrRemoveClass(delta, "ag-value-change-delta-up", deltaUp);
    _addOrRemoveClass(delta, "ag-value-change-delta-down", !deltaUp);
  };

  const _addOrRemoveClass = (element, className, addOrRemove) => {
    setDeltaCssClasses(className, addOrRemove);
  };

  const _setTimerToRemoveDelta = () => {
    setRefreshCount(refreshCount + 1);
    const refreshCountCopy = refreshCount;
    window.setTimeout(() => {
      if (refreshCountCopy === refreshCount) {
        hideDeltaValue();
      }
    }, 2000);
  };

  const hideDeltaValue = () => {
    setValueCssClasses("ag-value-change-value-highlight", false);
    setDelta(undefined);
  };
  return (
    <span>
      {props.data === undefined &&
      delta !== undefined &&
      value !== undefined ? (
        <span className={deltaClassName} ref={eValueRef}>
          {`${delta} ${value}`}
        </span>
      ) : (
        <span ref={eValueRef}>{value}</span>
      )}
    </span>
  );
});

export default CustomAnimationRenderer;
