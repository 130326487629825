export const tieHeaderConvertor = (input) => {
  const monthMapping = {
    F: "Jan",
    G: "Feb",
    H: "Mar",
    J: "Apr",
    K: "May",
    M: "Jun",
    N: "Jul",
    Q: "Aug",
    U: "Sep",
    V: "Oct",
    X: "Nov",
    Z: "Dec",
  };

  const monthChar = input.charAt(2);
  const yearChar = input.charAt(3);
  const month = monthMapping[monthChar];
  if (!month) {
    return "Invalid month character"; // Month character not found in mapping
  }

  const currentYear = new Date().getFullYear(); // Get the current year
  const yearBase = Math.floor(currentYear / 10) * 10; // Get the decade part of the current year
  let yearLastDigit = parseInt(yearChar, 10);
  if (isNaN(yearLastDigit) || yearLastDigit < 0 || yearLastDigit > 9) {
    return "Invalid year character"; // Year character must be a digit
  }
  const year = yearBase + yearLastDigit;
  return `Tie (${month}${year})`;
};

export const tieListCreator = (legs) => {
  const groupByTicker = {};

  legs.forEach((leg) => {
    const ticker = leg.futureTickerByLeg;
    if (!groupByTicker[ticker]) {
      groupByTicker[ticker] = {
        legNumArr: [],
        tieHeader: tieHeaderConvertor(ticker),
        tieValue: leg.tieByLeg,
        futureTickerByLeg: ticker,
        isEditing: false,
      };
    }
    groupByTicker[ticker].legNumArr.push(leg.legNum);
  });
  return Object.values(groupByTicker);
};
