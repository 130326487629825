import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { END_POINT } from "../../utils/index";
import * as actionSnackBar from "../snackbar/action";

export let derivsPricerSlice = createSlice({
  name: "derivsPricerSlice",
  initialState: {
    parsedString: [],
    bookingTable: [],
    strategyTable: [],
    underlyingTable: [],
    parsedBookingText_: null,
  },
  reducers: {
    setParsedString: (state, action) => {
      state.parsedString = action.payload;
    },
    setBookingTable: (state, action) => {
      state.bookingTable = action.payload;
    },
    setStrategyTable: (state, action) => {
      state.strategyTable = action.payload;
    },
    setUnderlyingTable: (state, action) => {
      state.underlyingTable = action.payload;
    },
    setParsedBookingText: (state, action) => {
      state.parsedBookingText_ = action.payload;
    },
  },
});

export const parseClientRequest =
  (clientString) => async (dispatch, getState) => {
    let parsedStringByLegs;
    try {
      if (clientString.toLowerCase() === "custom") {
        parsedStringByLegs = [1, 2].map((obj, index) => {
          return {
            id: index,
            legs: index === 0 ? 2 : "",
            symbol: "",
            equityIndex: index === 0 ? "US Equity" : "",
            strategy: index === 0 ? "Custom" : "",
            tie: "",
            expiryDay: "",
            expiryMonth: "",
            expiryYear: "",
            strike: "",
            callPut: "Call",
            quantity: "",
            lastPrice: "",
            futureTicker: null,
            clientString: null,
            ratio: null,
            futureTickerByLeg: null,
          };
        });
      } else {
        const parsedString = await axios.post(
          `${process.env.REACT_APP_PYTON}${END_POINT.PARSE_CLIENT_REQUEST}`,
          { client_request_text: clientString },
        );
        if (parsedString.data.error !== undefined) {
          dispatch(
            actionSnackBar.setSnackBar("error", parsedString.data.error, 3000),
          );
          parsedStringByLegs = [];
        } else {
          const getLastPrice = async () => {
            try {
              const symbol =
                parsedString.data.future_ticker === undefined
                  ? parsedString.data.symbol
                  : parsedString.data.future_ticker;

              const equityIndex = parsedString.data.equity_index;
              const ticker = (
                symbol.replaceAll("/", "%252f") +
                " " +
                equityIndex
              )
                .replaceAll("/", "|")
                .replaceAll(" ", "%20");
              const res = await axios.get(
                `${
                  process.env.REACT_APP_BLOOMBERG_DERIVS
                }equity/${ticker.toUpperCase()}`,
                // { timeout: 1000 },
              );
              if (res.data.error !== undefined) {
                dispatch(
                  actionSnackBar.setSnackBar("error", res.data.error, 3000),
                );
                return "";
              } else {
                return {
                  price: res.data.price,
                  futureTicker:
                    parsedString.data.future_ticker === undefined
                      ? null
                      : parsedString.data.future_ticker,
                };
              }
            } catch (error) {
              console.log(error);
              return {
                price: 99,
                futureTicker: null,
              };
            }
          };
          const lastPrice = await getLastPrice();
          parsedStringByLegs = parsedString.data.Legs.map((leg, index) => {
            return {
              id: index,
              legs: index === 0 ? parsedString.data.Legs.length : "",
              symbol: index === 0 ? parsedString.data.symbol : "",
              equityIndex: index === 0 ? parsedString.data.equity_index : "",
              strategy: index === 0 ? parsedString.data.strategy : "",
              tie:
                leg.future_ticker !== undefined
                  ? leg.tie
                  : index === 0
                  ? parsedString.data.tie
                  : "",
              expiryDay: leg.expiry_day,
              expiryMonth: leg.expiry_month,
              expiryYear: leg.expiry_year,
              strike: leg.strike,
              callPut:
                leg.call_put === "C"
                  ? "Call"
                  : leg.call_put === "P"
                  ? "Put"
                  : "",
              quantity: leg.quantity,
              lastPrice: index === 0 ? lastPrice.price : "",
              futureTicker:
                parsedString.data.future_ticker === undefined
                  ? null
                  : parsedString.data.future_ticker,
              futureTickerByLeg:
                leg.future_ticker === undefined ? null : leg.future_ticker,
              clientString,
              ratio:
                parsedString.data.ratio !== undefined
                  ? parsedString.data.ratio
                  : null,
            };
          });
        }
      }
      dispatch(setParsedString(parsedStringByLegs));
    } catch (error) {}
  };

//currently canceled
export const handleStrategySelect =
  (strategySelected, strategyList) => async (dispatch, getState) => {
    try {
      const parsedString = [...getState().derivsPricerSlice.parsedString];
      const strObj = strategyList.find((s) => s.value === strategySelected);
      const newParsedString = strObj.legs.map((leg, index) => {
        return {
          id: index,
          legs: index === 0 ? strObj.legs.length : "",
          symbol: index === 0 ? parsedString[0].symbol : "",
          equityIndex: index === 0 ? parsedString[0].equityIndex : "",
          strategy: index === 0 ? parsedString[0].strategy : "",
          tie: index === 0 ? parsedString[0].tie : "",
          //the rest depends if exist or not
          expiryDay:
            parsedString.length === strObj.legs.length
              ? parsedString[index].expiryDay
              : parsedString[0].expiryDay,
          expiryMonth:
            parsedString.length === strObj.legs.length
              ? parsedString[index].expiryMonth
              : parsedString[0].expiryMonth,
          expiryYear:
            parsedString.length === strObj.legs.length
              ? parsedString[index].expiryYear
              : parsedString[0].expiryYear,
          strike:
            parsedString.length === strObj.legs.length
              ? parsedString[index].strike
              : "",
          callPut: leg === "c" ? "Call" : leg === "p" ? "Put" : "",
          quantity:
            parsedString.length === strObj.legs.length
              ? parsedString[index].quantity
              : "",
        };
      });
      dispatch(setParsedString(newParsedString));
    } catch (err) {}
  };

export const handleTableEdit =
  (rowIndex, colId, value) => async (dispatch, getState) => {
    const parsedString = [...getState().derivsPricerSlice.parsedString];
    if (colId !== "legs") {
      //validations if they fails break outside the function
      if (colId === "expiryDay") {
        const regex = /^(?:[1-9]|[12][0-9]|3[01])$/;
        if (!regex.test(value)) {
          return dispatch(
            actionSnackBar.setSnackBar(
              "error",
              "The expiry Day can only be a number between 1-31",
              3000,
            ),
          );
        }
      } else if (colId === "expiryMonth") {
        const regex = /^(?:[1-9]|1[0-2])$/;
        if (!regex.test(value)) {
          return dispatch(
            actionSnackBar.setSnackBar(
              "error",
              "The Expiry Month can only be a number between 1-12",
              3000,
            ),
          );
        }
      } else if (colId === "strike" || colId === "tie") {
        const numberRegex = /^-?\d+(\.\d+)?$/;
        if (!numberRegex.test(value) && value !== "") {
          return dispatch(
            actionSnackBar.setSnackBar(
              "error",
              `The ${colId.toUpperCase()} can only be a number`,
              3000,
            ),
          );
        }
      }
      const getLastPrice = async () => {
        let futureTicker = null;
        try {
          if (
            colId === "symbol" &&
            parsedString[0].strategy === "Custom" &&
            (value.toLowerCase() === "spx" || value.toLowerCase() === "spxw us")
          ) {
            futureTicker = await axios.post(
              `${process.env.REACT_APP_PYTON}${END_POINT.GET_FUTURE_TICKER}`,
              { ticker: value },
            );
          }

          const symbol =
            colId !== "symbol"
              ? parsedString[0].symbol
              : futureTicker === null
              ? value
              : futureTicker.data.future_ticker;

          const equityIndex =
            colId === "equityIndex"
              ? value
              : futureTicker !== null
              ? "Index"
              : value.toLowerCase() === "vix" || value.toLowerCase() === "cac"
              ? "Index"
              : parsedString[0].equityIndex;

          const ticker = (symbol.replaceAll("/", "%252f") + " " + equityIndex)
            .replaceAll("/", "|")
            .replaceAll(" ", "%20");
          const res = await axios.get(
            `${
              process.env.REACT_APP_BLOOMBERG_DERIVS
            }equity/${ticker.toUpperCase()}`,
            // { timeout: 1000 },
          );
          if (res.data.error !== undefined) {
            dispatch(actionSnackBar.setSnackBar("error", res.data.error, 1000));
            return "";
          } else {
            return {
              price: res.data.price,
              futureTicker:
                futureTicker === null
                  ? futureTicker
                  : futureTicker.data.future_ticker,
            };
          }
        } catch (error) {
          console.log(error);
          return {
            price: 99,
            futureTicker,
          };
        }
      };
      const updatedRowData = await Promise.all(
        parsedString.map(async (row, index) => {
          //behavior for date update effect all columns
          try {
            if (
              rowIndex === 0 &&
              (colId === "expiryDay" ||
                colId === "expiryMonth" ||
                colId === "expiryYear")
            ) {
              return { ...row, [colId]: value };
            } else if (
              index === 0 &&
              (colId === "symbol" || colId === "equityIndex")
            ) {
              //update lastPrice when symbol or equityIndex changes
              const res = await getLastPrice();
              if (
                colId === "symbol" &&
                (value.toLowerCase() === "spx" ||
                  value.toLowerCase() === "spxw us" ||
                  value.toLowerCase() === "vix" ||
                  value.toLowerCase() === "cac")
              ) {
                return {
                  ...row,
                  [colId]: value,
                  equityIndex: "Index",
                  lastPrice: res.price,
                  futureTicker: res.futureTicker,
                };
              } else {
                return {
                  ...row,
                  [colId]: value,
                  lastPrice: res.price,
                  futureTicker: res.futureTicker,
                };
              }
            } else if (
              colId === "callPut" &&
              index === 0 &&
              index === rowIndex
            ) {
              return { ...row, [colId]: value, strategy: "Custom" };
            } else {
              //rest of columns update
              if (index === rowIndex) {
                return { ...row, [colId]: value };
              }
              return row;
            }
          } catch (error) {}
        }),
      );
      dispatch(setParsedString(updatedRowData));
    } else if (colId === "legs") {
      const regex = /^[1-5]$/;
      if (regex.test(value)) {
        const arr = new Array(Number(value)).fill(0);
        const customLegs = arr.map((item, index) => {
          return {
            id: index,
            legs: index === 0 ? arr.length : "",
            symbol: index === 0 ? parsedString[0].symbol : "",
            equityIndex: index === 0 ? parsedString[0].equityIndex : "",
            strategy: index === 0 ? "Custom" : "",
            tie: index === 0 ? parsedString[0].tie : "",
            lastPrice: index === 0 ? parsedString[0].lastPrice : "",
            //the rest depends if exist or not
            expiryDay:
              parsedString[index] !== undefined
                ? parsedString[index].expiryDay
                : parsedString[0].expiryDay,
            expiryMonth:
              parsedString[index] !== undefined
                ? parsedString[index].expiryMonth
                : parsedString[0].expiryMonth,
            expiryYear:
              parsedString[index] !== undefined
                ? parsedString[index].expiryYear
                : parsedString[0].expiryYear,
            strike:
              parsedString[index] !== undefined
                ? parsedString[index].strike
                : parsedString[0].strike,
            callPut:
              parsedString[index] !== undefined
                ? parsedString[index].callPut
                : "Call",
            quantity:
              parsedString[index] !== undefined
                ? parsedString[index].quantity
                : "",
            futureTicker:
              parsedString[index] !== undefined
                ? parsedString[index].futureTicker
                : null,
            clientString: null,
            ratio: parsedString[0].ratio,
            futureTickerByLeg: null,
          };
        });
        dispatch(setParsedString(customLegs));
      } else {
        dispatch(
          actionSnackBar.setSnackBar(
            "error",
            "Legs value must be a number between 1-5",
            3000,
          ),
        );
      }
    }
  };

const formatDate = (day, month, year) => {
  const paddedDay = String(day).padStart(2, "0");
  const paddedMonth = String(month).padStart(2, "0");
  return `${year}-${paddedMonth}-${paddedDay}`;
};

const getFutureTickerByLeg = async (parsedString) => {
  const errorArr = [];
  const arr = await Promise.all(
    parsedString.map(async (leg) => {
      const { expiryDay, expiryMonth, expiryYear } = leg;
      const expiry = formatDate(expiryDay, expiryMonth, expiryYear);
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_PYTON}${END_POINT.GET_FUTURE_TICKER}`,
          { ticker: parsedString[0].symbol, expiry: expiry },
        );
        return { ...leg, futureTickerByLeg: res.data.future_ticker };
      } catch (error) {
        console.log(error);
        errorArr.push(error.message);
      }
    }),
  );
  return errorArr.length > 0
    ? { status: false, errorArr }
    : { status: true, arr };
};

export const handleSubmitOrder = () => async (dispatch, getState) => {
  let parsedString = [...getState().derivsPricerSlice.parsedString];
  try {
    const token = sessionStorage.getItem("token");
    if (
      // VIX/CAC adding future ticker on create order
      parsedString[0].symbol.toLowerCase() === "vix" ||
      parsedString[0].symbol.toLowerCase() === "cac"
    ) {
      const updatedParsedString = await getFutureTickerByLeg(parsedString);
      if (updatedParsedString.status) {
        parsedString = [...updatedParsedString.arr];
        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}${END_POINT.CREATE_DERIVS_ORDER}`,
          parsedString,
          { headers: { Authorization: token } },
        );
        if (res.data.status === true) {
          dispatch(setParsedString([]));
        }
      } else {
        return dispatch(
          actionSnackBar.setSnackBar(
            "error",
            updatedParsedString.errorArr[0],
            3000,
          ),
        );
      }
    } else {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${END_POINT.CREATE_DERIVS_ORDER}`,
        parsedString,
        { headers: { Authorization: token } },
      );
      if (res.data.status === true) {
        dispatch(setParsedString([]));
      }
    }
  } catch (error) {
    console.log(error);
    dispatch(actionSnackBar.setSnackBar("error", error, 3000));
  }
};

export const {
  setParsedString,
  setBookingTable,
  setStrategyTable,
  setUnderlyingTable,
  setParsedBookingText,
} = derivsPricerSlice.actions;

export default derivsPricerSlice.reducer;
