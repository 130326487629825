import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  DialogContent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  CircularProgress,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import {
  Dialog,
  Grid,
  Typography,
  IconButton,
  Divider,
  FormControl,
} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import {
  StyledTableCell,
  StyledTableRow,
  useStyles,
} from "../../../Styles/fiStyles";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import * as fiActions from "../../../store/fi/fiSlice";
import * as actionSnackBar from "../../../store/snackbar/action";
import { END_POINT } from "../../../utils/index";
import { upperCase } from "lodash";

const BookDialog = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const token = sessionStorage.getItem("token");
  const { open, type, handleClose, tradesToBook } = props;
  const { chosenDeals, fiTrades } = useSelector((state) => state.fiSlice);
  const [bookedTrades, setBookedTrades] = useState([]);
  const [sendingStatus, setSendingStatus] = useState(false);
  const [isGppBtnDisabled, setIsGppBtnDisabled] = useState(false);
  const [brokerRef, setBrokerRef] = useState("gpp");

  useEffect(() => {
    open && setSendingStatus(false);
    if (open === false) {
      setBrokerRef("gpp");
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      const filter = tradesToBook.filter((trade) => trade.gppCode === "");
      if (filter.length > 0) {
        setIsGppBtnDisabled(true);
      }
    }

    const getXml = async () => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}` +
            END_POINT.FI_TRADES +
            "/get_xml_book_view",
          { tradesToBook },
          {
            headers: { Authorization: token },
          },
        );
        setBookedTrades(res.data);
      } catch (error) {}
    };

    const getGppTableBody = async () => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}` +
            END_POINT.FI_TRADES +
            "/get_gpp_table_body",
          { tradesToBook },
          {
            headers: { Authorization: token },
          },
        );
        setBookedTrades(res.data);
      } catch (error) {}
    };
    const getMerrillTableBody = async () => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}` +
            END_POINT.FI_TRADES +
            "/get_merrill_table_body",
          { tradesToBook },
          {
            headers: { Authorization: token },
          },
        );
        setBookedTrades(res.data);
      } catch (error) {}
    };

    if (open && brokerRef === "gpp") {
      getGppTableBody();
    } else if (open && brokerRef === "merrill") {
      getMerrillTableBody();
    }
  }, [tradesToBook, brokerRef]);

  const handleBooking = async () => {
    setSendingStatus(true);
    const isGppCodeMissing = tradesToBook.filter((t) => t.gppCode === null);
    if (brokerRef === "gpp" && isGppCodeMissing.length > 0) {
      dispatch(
        actionSnackBar.setSnackBar(
          "error",
          "All trades must have GPP code",
          5000,
        ),
      );
      setSendingStatus(false);
    } else {
      const token = sessionStorage.getItem("token");
      try {
        const res =
          brokerRef === "gpp"
            ? await axios.post(
                `${process.env.REACT_APP_BASE_URL}` +
                  END_POINT.FI_TRADES +
                  "/book_to_gpp",
                { tradesToBook, bookedTrades },
                { headers: { Authorization: token } },
              )
            : brokerRef === "merrill"
            ? await axios.post(
                `${process.env.REACT_APP_BASE_URL}` +
                  END_POINT.FI_TRADES +
                  "/to_be_booked_merrill",
                { tradesToBook },
                { headers: { Authorization: token } },
              )
            : null;

        if (res.status === 200) {
          dispatch(fiActions.resetDealsArr());
          dispatch(fiActions.resetChosenTrades());

          let copyArr = [...fiTrades];
          copyArr = copyArr.map((t) => {
            return { ...t, checked: false };
          });
          dispatch(fiActions.setFiTrades(copyArr));
          handleClose();
        }
      } catch (error) {
        if (brokerRef === "pershing") {
          dispatch(
            actionSnackBar.setSnackBar(
              "error",
              error.response.data.message,
              5000,
            ),
          );
        } else if (brokerRef === "gpp") {
          dispatch(
            actionSnackBar.setSnackBar(
              "error",
              error.response.data.errors[0].errors,
              5000,
            ),
          );
        }
        setSendingStatus(false);
        handleClose();
      }
    }
  };
  const selectBrokerRef = (e) => {
    setBrokerRef(e.target.value);
    setBookedTrades([]);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      classes={{ paper: classes.dialogPaperBookFiTrade }}
      PaperProps={{
        style: {
          backgroundColor: "#2D2D2D",
        },
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: "30px 84px 32px 75px", position: "absolute" }}
        xs={12}
      >
        {/* <Grid item xs={12} container> */}
        <Grid
          display={"flex"}
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
        >
          <Grid item xs={6} style={{ position: "absolute", left: "25px" }}>
            <Typography style={{ fontSize: 20, color: "#ffffff" }}>
              Book trades
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              className={classes.brokerRefSelect}
              onChange={(e) => {
                selectBrokerRef(e);
              }}
              select
              value={brokerRef}
            >
              <MenuItem value={"gpp"}>GPP</MenuItem>
              <MenuItem value={"merrill"}>Merrill</MenuItem>
            </TextField>
          </Grid>
          <Grid
            item
            xs={1}
            container
            justifyContent="flex-end"
            style={{ position: "absolute", right: "25px" }}
          >
            <IconButton
              size="small"
              onClick={() => {
                handleClose();
              }}
              style={{
                backgroundColor: "#3a3c3f",
                // left: "100px",
              }}
            >
              <CloseIcon style={{ color: "#bababa", fontSize: "14pt" }} />
            </IconButton>
          </Grid>
        </Grid>
        {/* </Grid> */}
        <Grid item md={10} style={{ paddingTop: 10 }}>
          <div
            style={{
              borderRadius: "4px",
              height: "50vh",
              width: "1000px",
              position: "absolute",
              left: "25px",
              overflow: "scroll",
              overflowX: "hidden",
              overflowY: "hidden",
              marginTop: "20px",
            }}
          >
            <Grid
              item
              style={{
                paddingTop: 10,
                // overflowX: "hidden",
              }}
            >
              <TableContainer
                style={{
                  borderRadius: "8px 8px 8px 8px",
                  border: "1px solid #686B76",
                  height: "400px",
                  width: "100%",
                }}
              >
                <Table size="medium" style={{ border: "1px solid #686B76" }}>
                  <TableHead>
                    <StyledTableRow
                      style={{
                        margin: "0",
                        backgroundColor: "#2D2D2D",
                      }}
                    >
                      {bookedTrades.length > 0 &&
                        Object.keys(bookedTrades[0]).map((c, index) => {
                          if (c !== "Column1") {
                            return (
                              <StyledTableCell key={`headCell-${index}`}>
                                {brokerRef === "merrill"
                                  ? c
                                      .replace("bd", "")
                                      .replaceAll("_", " ")
                                      .toUpperCase()
                                  : c.toUpperCase()}
                              </StyledTableCell>
                            );
                          }
                        })}
                    </StyledTableRow>
                  </TableHead>

                  <TableBody>
                    {bookedTrades.length > 0 &&
                      bookedTrades.map((obj, index) => {
                        return (
                          <StyledTableRow
                            key={`row-${index}`}
                            style={{
                              textAlign: "center",
                              margin: "0",
                              height: 30,
                              backgroundColor: "#171717",
                            }}
                            xs={12}
                          >
                            {Object.keys(bookedTrades[0]).map(
                              (category, idx) => {
                                if (category !== "Column1") {
                                  return (
                                    <StyledTableCell
                                      key={`cell-${idx}`}
                                      style={{ border: "none" }}
                                    >
                                      {obj[`${category}`]}
                                    </StyledTableCell>
                                  );
                                }
                              },
                            )}
                          </StyledTableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </div>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          xs={11}
          alignItems="flex-end"
          style={{
            position: "absolute",
            top: "530px",
            left: "50px",
          }}
        >
          <Grid item xs={7}>
            <Button
              variant="contained"
              className={classes.btnCancleBooking}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Grid>

          <Grid item xs={2}>
            <Button
              type="submit"
              variant="contained"
              className={classes.btnSendToPershing}
              onClick={handleBooking}
              disabled={sendingStatus}
            >
              {sendingStatus ? (
                <div className="sendingAnim">Sending</div>
              ) : (
                `Send to ${upperCase(brokerRef)}`
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default BookDialog;
