export const formatSingleCell = (
  jRef,
  cellName,
  textColor,
  backgroundColor,
  fontWeight
) => {
  jRef.current.jspreadsheet.setStyle(
    cellName,
    "background-color",
    backgroundColor
  );
  jRef.current.jspreadsheet.setStyle(cellName, "color", textColor);
  if (!jRef.current.jspreadsheet.getStyle(cellName).includes("font-weight")) {
    jRef.current.jspreadsheet.setStyle(cellName, "font-weight", fontWeight);
  }
};
export const format_key_group = (
  key_group,
  font_color,
  background_color,
  fontWeight
) => {
  // for (let i = 0; i < jRef.current.jspreadsheet.headers.length; i++) {
  //   for (const key of key_group) {
  //     var cellName = jspreadsheet.getColumnNameFromId([i,keyRow(key)]);
  //     formatSingleCell(
  //       cellName,
  //       font_color,
  //       background_color,
  //       fontWeight
  //     );
  //   }
  // }
};
export const format_underline = (key, jRef, cellId, keyRow) => {
  let cols = jRef.current.jspreadsheet.headers.length;
  for (var i = 0; i < cols; i++) {
    let cellName = cellId(i, keyRow(key));
    if (
      !jRef.current.jspreadsheet.getStyle(cellName).includes("border-bottom")
    ) {
      jRef.current.jspreadsheet.setStyle(
        cellName,
        "border-bottom",
        "1px solid #FFC5CD",
        "important"
      );
    }
    if (
      jRef.current.jspreadsheet.getStyle(cellName).includes("border-bottom")
    ) {
      jRef.current.jspreadsheet.setStyle(
        cellName,
        "border-bottom",
        "1px solid #FFC5CD",
        "important"
      );
    }
  }
};
//solid #686b76;
export const remove_format_underline = (key, jRef, cellId, keyRow) => {
  let cols = jRef.current.jspreadsheet.headers.length;
  for (var i = 0; i < cols; i++) {
    let cellName = cellId(i, keyRow(key));
    if (
      jRef.current.jspreadsheet.getStyle(cellName).includes("border-bottom")
    ) {
      jRef.current.jspreadsheet.setStyle(
        cellName,
        "border-bottom",
        "1px solid #686b76",
        "important"
      );
    }
  }
};
export const format_user_date = (date_string, moment) => {
  let currentDate = moment().format("DD-MMM-YY");
  let dateStringArr = [];
if(!date_string.match(/\d+/g)){
  return "invalid date"
}
  if (date_string.match(/[a-zA-Z]+/g)) {
    dateStringArr = [
      date_string.match(/\d+/g)[0],
      date_string.match(/[a-zA-Z]+/g)[0],
    ].join("/");
    
    dateStringArr = moment(dateStringArr, "DD/MMM").toString();
  } else {
    dateStringArr = moment(date_string, "DD/MM").toString();
  }

  let userEnterData = moment(dateStringArr).format("DD-MMM-YY");

  if (
    moment(userEnterData, "DD-MMM-YY").isBefore(
      moment(currentDate, "DD-MMM-YYYY")
    )
  ) {
    userEnterData = moment(dateStringArr).add(1, "years").format("DD-MMM-YY");
  }
  return userEnterData;
};

export const add_dropdown_class_to_deal_type = (
  keyVal_input_col,
  getCell,
  keyRow
) => {
  if (keyVal_input_col("DEAL_TYPE", 1) !== "") {
    let cell = getCell(1, keyRow("DEAL_TYPE"));
    cell.classList.add("dropDownCells");
  }
};
