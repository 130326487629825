import React, { useEffect, useState } from "react";
import { updateTieApi } from "../utils/apiCalls";

export const TieCell = ({ underlineDetails, tieList, setTieList }) => {
  const [editingTie, setEditingTie] = useState(false);
  const [tieValue, setTieValue] = useState(underlineDetails.tie);

  useEffect(() => {
    if (!editingTie) {
      setTieValue(underlineDetails.tie);
    }
  }, [underlineDetails]);

  const handleTieClick = (i) => {
    if (
      underlineDetails.legs[0].futureTickerByLeg !== null &&
      tieList !== null
    ) {
      setTieList((pre) => {
        const arr = [...pre];
        const updatedArr = arr.map((obj, index) => {
          if (index === i) {
            return { ...obj, isEditing: true };
          } else return { ...obj, isEditing: false };
        });
        return updatedArr;
      });
    } else {
      setEditingTie(true);
    }
  };

  const updateTie = async (i = null) => {
    try {
      if (
        underlineDetails.legs[0].futureTickerByLeg !== null &&
        tieList !== null &&
        i !== null
      ) {
        await updateTieApi(
          underlineDetails,
          tieList[i].tieValue,
          tieList[i].legNumArr,
        );
      } else {
        await updateTieApi(underlineDetails, tieValue);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleChange = (event, i = null) => {
    if (
      underlineDetails.legs[0].futureTickerByLeg !== null &&
      tieList !== null
    ) {
      setTieList((pre) => {
        const arr = [...pre];
        const obj = { ...arr[i], tieValue: event.target.value };
        arr.splice(i, 1, obj);
        return arr;
      });
    } else {
      setTieValue(event.target.value);
    }
  };

  const handleBlur = (i = null) => {
    if (
      underlineDetails.legs[0].futureTickerByLeg !== null &&
      tieList !== null
    ) {
      setTieList((pre) => {
        const arr = [...pre];
        const obj = { ...arr[i], isEditing: false };
        arr.splice(i, 1, obj);
        return arr;
      });
      updateTie(i);
    } else {
      setEditingTie(false);
      updateTie();
    }
  };

  const handleKeyDown = (event, i = null) => {
    if (event.key === "Enter") {
      if (
        underlineDetails.legs[0].futureTickerByLeg !== null &&
        tieList !== null
      ) {
        setTieList((pre) => {
          const arr = [...pre];
          const obj = { ...arr[i], isEditing: false };
          arr.splice(i, 1, obj);
          return arr;
        });
        updateTie(i);
      } else {
        setEditingTie(false);
        updateTie();
      }
    }
  };

  if (underlineDetails.legs[0].futureTickerByLeg !== null && tieList !== null) {
    return (
      <>
        {tieList.map((obj, i) => {
          if (obj.isEditing) {
            return (
              <td>
                <input
                  type="number"
                  className="tie-input"
                  value={obj.tieValue}
                  onChange={(e) => handleChange(e, i)}
                  onBlur={() => handleBlur(i)}
                  onKeyDown={(e) => handleKeyDown(e, i)}
                  autoFocus
                  style={{
                    backgroundColor: "transparent",
                    color: "white",
                    borderRadius: "4px",
                    width: "80px",
                    height: "12px",
                  }}
                />
              </td>
            );
          } else
            return (
              <td key={`tie${i}`} onClick={() => handleTieClick(i)}>
                {obj?.tieValue}
              </td>
            );
        })}
      </>
    );
  } else {
    if (editingTie) {
      return (
        <td>
          <input
            type="number"
            className="tie-input"
            value={tieValue}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            autoFocus
            style={{
              backgroundColor: "transparent",
              color: "white",
              borderRadius: "4px",
              width: "80px",
              height: "12px",
            }}
          />
        </td>
      );
    } else {
      return <td onClick={handleTieClick}>{tieValue}</td>;
    }
  }
};
