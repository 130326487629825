import axios from "axios";
import { END_POINT } from "../../../utils";
import * as actionSnackBar from "../../../store/snackbar/action";

export const createUser = async (formDetails, dispatch) => {
  try {
    const token = sessionStorage.getItem("token");
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}${END_POINT.CREATE_NEW_USER}`,
      { formDetails },
      { headers: { Authorization: token } },
    );
    dispatch(actionSnackBar.setSnackBar("success", res.data, 3000));
    return true;
  } catch (error) {
    dispatch(actionSnackBar.setSnackBar("error", error.response.data, 3000));
    return false;
  }
};

export const getUserList = async (setUsersList) => {
  try {
    const token = sessionStorage.getItem("token");
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}${END_POINT.GET_USERS_LIST}`,
      { headers: { Authorization: token } },
    );

    const decryptRes = await decryptData(res.data);
    const parsedRes = JSON.parse(decryptRes);
    setUsersList(
      parsedRes.map((u) => {
        return {
          ...u,
          entitlement: u.entitlement.replace(/;+$/, "").split(";"),
        };
      }),
    );
  } catch (error) {
    setUsersList([]);
    console.log(error);
  }
};

export const decryptData = async (data) => {
  const { encryptedData, iv } = data;
  const secretKey = process.env.REACT_APP_TOKEN_SECRET_KEY;

  // Convert the string key to a Uint8Array
  let encoder = new TextEncoder();
  let keyArray = encoder.encode(secretKey);

  const ivHex = new Uint8Array(
    iv.match(/.{1,2}/g).map((byte) => parseInt(byte, 16)),
  );
  const encryptedDataArray = new Uint8Array(
    encryptedData.match(/.{1,2}/g).map((byte) => parseInt(byte, 16)),
  );

  // Import the secret key for the Web Crypto API
  let keyMaterial;
  try {
    keyMaterial = await window.crypto.subtle.importKey(
      "raw",
      keyArray,
      { name: "AES-CBC" },
      false,
      ["decrypt"],
    );
  } catch (e) {
    console.error("Error importing key:", e);
    return null;
  }

  // Decrypt the data
  try {
    const decryptedData = await window.crypto.subtle.decrypt(
      {
        name: "AES-CBC",
        iv: ivHex,
      },
      keyMaterial,
      encryptedDataArray,
    );

    // Convert the decrypted ArrayBuffer back to a string
    const decoder = new TextDecoder();
    return decoder.decode(decryptedData);
  } catch (e) {
    console.error("Decryption failed:", e);
    return null;
  }
};
