import axios from "axios";
import * as rfqSlice from "../../store/rfq/rfqSlice";

export const apiRefreshQuoteBook = async (
  quoteType,
  quoteBook,
  jtable_dict,
  quote_parameters_dict,
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_PYTON}` + "refresh_quote_book",
      {
        quote_type: quoteType,
        quote_book_dict: quoteBook, //need changing
        jtable_dict: jtable_dict,
        quote_parameters_dict: quote_parameters_dict, //need changing,, //need changing,
      },
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const buildOrderBook = async (quoteBook) => {
  let response = await axios.post(
    `${process.env.REACT_APP_PYTON}` + `build_order_book`,
    quoteBook,
  );
  // dispatch(rfqSlice.updateTableData(response.data));
  return response.data;
};
export const apiGetDealStats = async (
  quoteType,
  rfqTableData,
  quoteParameters,
  dealStats,
  orderBook,
  isUsesPrice
) => {
  const dealStatsCopy = { ...dealStats };
  if(isUsesPrice){
    dealStatsCopy.is_users_price = true;
  }else {
  dealStatsCopy.is_users_price = false;
  }
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_PYTON}` + `get_deal_stats`,
      {
        quote_type: quoteType,
        rfq_table_data: rfqTableData,
        quote_parameters: quoteParameters,
        deal_stats: dealStatsCopy,
        order_book: orderBook,
        type: "agg 2",
      },
    );
    return response.data
    // let obj = {...response.data};
    // obj = {
    //   ...obj,
    //   market_bid: response.data.market_bid
    //     .toString()
    //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"),
    //   market_offer: response.data.market_offer
    //     .toString()
    //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"),
    //   makor_bid: response.data.makor_bid
    //     .toString()
    //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"),
    //   makor_offer: response.data.makor_offer
    //     .toString()
    //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"),
    // };

    // return obj;
  } catch (error) {
    console.log(error);
  }
};
export const apiBuildQuoteBook = async (
  quoteType,
  quoteBook,
  market_maker,
  jtable_dict,
  quote_parameters_dict,
  dispatch,
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_PYTON}` + "build_quote_book",
      {
        quote_type: quoteType,
        quote_book_dict: quoteBook, //need changing
        market_maker: market_maker,
        jtable_dict: jtable_dict,
        quote_parameters_dict: quote_parameters_dict, //need changing,, //need changing,
      },
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
