import axios from "axios";
import { END_POINT } from "../../../utils";
import { useEffect, useLayoutEffect, useState } from "react";
import FiEmailConfoView from "./FiEmailConfo.view";
import { useSelector, useDispatch } from "react-redux";
import * as emailActions from "../../../store/email_confo/emailConfoSlice";
import * as actionSnackBar from "../../../store/snackbar/action";

function FiEmailConfo() {
  const dispatch = useDispatch();
  const [subject, setSubject] = useState();
  const [traderComment, setTraderComment] = useState("");
  const [toEmailAddresses, setToEmailAddresses] = useState();
  const [ccEmailAddresses, setCCEmailAddresses] = useState();
  const emailSent = useSelector((state) => state.emailSlice?.emailSent);
  const [startSendEmail, setStartSendEmail] = useState(emailSent);
  console.log(emailSent)
  const [sendStatus, setSendStatus] = useState(emailSent ? "success" : "");
  const [fullDetailChosenTrade, setFullDetailChosenTrade] = useState();
  const chosenTrade = useSelector((state) => state.fiSlice?.chosenTrades[0]);
  const fiEmail = useSelector((state) => state?.emailSlice?.fiEmail);

  const saveFiConfoToDb = async () => {
    const token = sessionStorage.getItem("token");
    let fiEmailObjToSend = fiEmail;
    fiEmailObjToSend = traderComment
      ? { ...fiEmailObjToSend, traderComment: traderComment }
      : fiEmailObjToSend;
    let fiConfoObj = {
      trade_id: fullDetailChosenTrade?.trade?.trade_id,
      email_body: fiEmailObjToSend,
      to: toEmailAddresses.join(";"),
      cc: ccEmailAddresses?.length > 0 ? ccEmailAddresses.join(";") : "",
      subject: subject === undefined ? fiEmail?.subject : subject,
      trader_comment: traderComment,
    };
    try {
      let res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}` +
          END_POINT.FI_TRADES +
          "/save_fi_confo",
        {
          fiConfoObj,
        },
        {
          headers: { Authorization: token },
        }
      );
      dispatch(actionSnackBar.setSnackBar("success", res.data, 4000));
    } catch (error) {
      console.log("error: ", error);
      dispatch(
        actionSnackBar.setSnackBar(
          "error",
          "Failed Saving Fi Confo to DB",
          4000
        )
      );
    }
  };

  const sendEmail = async () => {
    const token = sessionStorage.getItem("token");
    setStartSendEmail(true);
    setSendStatus("start");
    let separatedToEmailAddresses = [];
    toEmailAddresses.forEach((addresses) => {
      if (addresses.includes(";")) {
        const individualAddresses = addresses.split(";");
        separatedToEmailAddresses.push(...individualAddresses);
      } else {
        separatedToEmailAddresses.push(addresses);
      }
    });
    if (
      separatedToEmailAddresses !== undefined &&
      separatedToEmailAddresses.length !== 0
    ) {
      let toInCC = separatedToEmailAddresses.filter((email) =>
        ccEmailAddresses?.includes(email)
      );

      if (toInCC.length > 0) {
        setSendStatus("fail");
        dispatch(
          actionSnackBar.setSnackBar(
            "error",
            `The "To" email addresses ${toInCC.join(
              ","
            )} found in CC addresses. please remove and try again.`,
            8000
          )
        );
        setTimeout(() => {
          setStartSendEmail(false);
        }, 7000);
      }
      // ! if all is good , will send the email here below
      else {
        let emailBody =
          document.getElementsByClassName("emailToSend")[0].innerHTML;
        try {
          let res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}` +
              END_POINT.EMAIL +
              "/send_fi_email",
            {
              to: separatedToEmailAddresses,
              cc: ccEmailAddresses,
              subject: subject === undefined ? fiEmail?.subject : subject,
              chosenFiTrade: fullDetailChosenTrade,
              html: emailBody,
            },
            {
              headers: { Authorization: token },
            }
          );

          if (res.status === 200) {
            setSendStatus("success");
            saveFiConfoToDb();
          }
        } catch (err) {
          setSendStatus("fail");

          dispatch(
            actionSnackBar.setSnackBar("error", "Error sendig email.", 8000)
          );
          setTimeout(() => {
            setStartSendEmail(false);
          }, 7000);
        }
      }
    }
    if (
      (toEmailAddresses !== undefined && toEmailAddresses[0] === "") ||
      toEmailAddresses === undefined ||
      toEmailAddresses === null
    ) {
      setSendStatus("fail");
      dispatch(
        actionSnackBar.setSnackBar(
          "error",
          `"To" section can not be empty`,
          8000
        )
      );
      setTimeout(() => {
        setStartSendEmail(false);
      }, 4000);
    }
  };

  const editSubject = (event) => {
    setSubject(event.target.value);
  };

  const handleEmailAddressAdd = (type, event) => {
    if (type === "to") {
      let newArr = event.target.value.split(/[\;\,\:]/);
      newArr = newArr.map((address) => {
        return address.replaceAll(" ", "");
      });
      setToEmailAddresses(newArr);
    }
    if (type === "cc") {
      let newArr = event.target.value.split(/[\;\,\:]/);
      newArr = newArr.map((address) => {
        return address.replaceAll(" ", "");
      });
      setCCEmailAddresses(newArr);
    }
  };

  const generateComment = (event) => {
    if (event.nativeEvent.inputType === "insertLineBreak") {
      setTraderComment(traderComment + "\n");
    } else {
      setTraderComment(event.target.value);
    }
  };

  const getFiPdf = async () => {
    const token = sessionStorage.getItem("token");

    let config = {
      headers: { Authorization: token },
      params: {
        fi_data: fullDetailChosenTrade,
      },
    };
    let res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}` + END_POINT.FI_PDF,
      config
    );
    var byteCharacters = atob(res.data);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: "application/pdf;base64" });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  const checkIfConfoSent = async () => {
    const token = sessionStorage.getItem("token");
    let config = {
      headers: { Authorization: token },
      params: {
        id: chosenTrade?.trade_id,
        client_id: chosenTrade.client_id,
      },
    };
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}` +
          END_POINT.FI_TRADES +
          "/check_if_confo_sent",
        config
      );

      if (res.data.sent === false) {
        createFullDetailedChosenFiTrade();
      } else {
        const { sentEmail, currentClient } = res.data;
        setStartSendEmail(true);
        setSendStatus("success");

        setFullDetailChosenTrade({ trade: chosenTrade, client: currentClient });
        setToEmailAddresses(sentEmail.sentTo.split(";"));
        setCCEmailAddresses(sentEmail.sentCC?.split(";"));
        setTraderComment(sentEmail.email_body?.traderComment);
        dispatch(emailActions.setFiEmail(sentEmail.email_body));
      }
    } catch (error) {
      dispatch(
        actionSnackBar.setSnackBar("Error", "Problem Check if Confo Sent", 3000)
      );
    }
  };

  const createFullDetailedChosenFiTrade = async () => {
    const token = sessionStorage.getItem("token");
    const client_id = chosenTrade.client_id;
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}` +
          END_POINT.FI_TRADES +
          `/get_client_details?id=${client_id}`,
        {
          headers: { Authorization: token },
        }
      );
      if (res.data !== undefined && res.data !== null) {
        let fullDetailData = { trade: chosenTrade, client: res.data };
        setFullDetailChosenTrade(fullDetailData);
        setToEmailAddresses(fullDetailData.client.confirmation_email);
        setCCEmailAddresses(["ficonfirms@makor-capital.com"]);
        dispatch(emailActions.getEmailPreview("FI", fullDetailData));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkIfConfoSent();
  }, []);

  return (
    <FiEmailConfoView
      subject={subject}
      getFiPdf={getFiPdf}
      sendEmail={sendEmail}
      sendStatus={sendStatus}
      editSubject={editSubject}
      traderComment={traderComment}
      startSendEmail={startSendEmail}
      generateComment={generateComment}
      chosenTrade={fullDetailChosenTrade}
      toEmailAddresses={toEmailAddresses}
      ccEmailAddresses={ccEmailAddresses}
      handleEmailAddressAdd={handleEmailAddressAdd}
    />
  );
}

export default FiEmailConfo;
