import React, { useState, useEffect } from "react";
import "./RfqBlotterTable.css";
import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Checkbox,
  TableBody,
  Button,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import MuiTableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as DoneIcon } from "../../../../assets/done-icon.svg";
import CircleIcon from "@mui/icons-material/Circle";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import * as rfqSlice from "../../../../store/rfq/rfqSlice";
import * as rfqAggregatorSlice from "../../../../store/rfq/rfqAggregatorSlice";
import { withStyles } from "@material-ui/core/styles";
import { TableSortLabel } from "@mui/material";
import axios from "axios";
import { END_POINT } from "../../../../utils";
import {
  apiGetQuoatParameters,
  apiRestDealStats,
} from "../../../../services/apiCalls/restCalls/restQuoteBookApi";
import { createQuoteBookDict } from "../../../../services/rfqWorkspace/createQuoteBookDict";
import {
  apiGetDealStats,
  apiRefreshQuoteBook,
  buildOrderBook,
} from "../../../../services/apiCalls/quotsBookApi";
import { ws, connectWS, sendEvent } from "../../../../services/websocket";

export const RfqBlotterTable = ({
  setSelectedRfq,
  selectedRfq,
  getRecommendation,
}) => {
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();

  const [orderDirection, setOrderDirection] = useState("desc");
  const [valueToOrderBy, setValueToOrderBy] = useState("");
  const rfq = useSelector((state) => {
    return state.rfqSlice.rfq;
  });
  let recommendationRfqs = useSelector(
    (state) => state.rfqSlice.recommendationRfqs,
  );
  const dispatch = useDispatch();
  const classes = useStyles();
  let copyRfq = JSON.parse(JSON.stringify(rfq));

  const handleOnChange = (e, id) => {
    if (e.detail === 2) {
      getRecommendation(id);

      sessionStorage.setItem("rfqId", id);
      updateRfqBookingModeDetails(id);
      handleRfqModalsDetails(id);
      dispatch(rfqSlice.getCheckedRfq(id));
      const checkboxFilter = copyRfq.map((checkBox) => {
        if (checkBox.rfq_id === id) {
          if (!checkBox.checked === false) {
            setSelectedRfq("");
          }
          return { ...checkBox, checked: !checkBox.checked };
        }
        return checkBox;
      });
      let getRfqStatus = checkboxFilter.filter((rfq) => {
        return rfq.rfq_id === id;
      });

      if (
        getRfqStatus[0].status === "Live" ||
        getRfqStatus[0].status === "Idea" ||
        getRfqStatus[0].status === "Draft"
      ) {
        sessionStorage.setItem("status", "contribute");
        sessionStorage.setItem("rfqMode", "rfq aggregator view");
        // sessionStorage.setItem("status", "db aggregator view");
        dispatch(
          rfqSlice.updateRfqLocationMode({
            rfq_id: id,
            rfqMode: "rfq aggregator view",
          }),
        );
        navigate(`/rfqworkspace/${selectedRfq}`);
      }
      if (
        getRfqStatus[0].status === "Booked" ||
        getRfqStatus[0].status === "Confirmed" ||
        getRfqStatus[0].status === "Confirmed & Booked"
      ) {
        sessionStorage.setItem("status", "open");
        sessionStorage.setItem("rfqMode", "rfq booking view");
        // sessionStorage.setItem("rfqMode", "db booking view");
        dispatch(
          rfqSlice.updateRfqLocationMode({
            rfq_id: sessionStorage.getItem("rfqId"),
            rfqMode: "rfq booking view",
          }),
        );
        navigate(`/rfqworkspace/${selectedRfq}`);
      }
      if (
        getRfqStatus[0].status === "Traded Away" ||
        getRfqStatus[0].status === "Not Traded"
      ) {
        sessionStorage.setItem("status", "open");
        sessionStorage.setItem("rfqMode", "rfq view");
        dispatch(
          rfqSlice.updateRfqLocationMode({
            rfq_id: sessionStorage.getItem("rfqId"),
            rfqMode: "rfq view",
          }),
        );
        navigate(`/rfqworkspace/${selectedRfq}`);
      }
      dispatch(rfqSlice.clearRfqState());
      dispatch(rfqSlice.changeStatusChecked(checkboxFilter));
    } else {
      if (e.target.checked === undefined || e.target.checked) {
        handleRfqModalsDetails(id);
        subToRfqQuote(id);
      }
      setSelectedRfq(id);
      const checkboxFilter = copyRfq.map((checkBox) => {
        if (
          checkBox.checked === true &&
          Number(checkBox.rfq_id) !== Number(id)
        ) {
          return { ...checkBox, checked: !checkBox.checked };
        }
        if (Number(checkBox.rfq_id) === Number(id)) {
          if (!checkBox.checked === false) {
            setSelectedRfq("");
          }
          return { ...checkBox, checked: !checkBox.checked };
        }
        return checkBox;
      });
      dispatch(rfqSlice.clearRfqState());
      dispatch(rfqSlice.changeStatusChecked(checkboxFilter));
    }
  };

  const handleRfqModalsDetails = async (rfqId) => {
    sessionStorage.setItem("rfqId", rfqId);

    try {
      let rfqModalDetails = await axios.get(
        `${process.env.REACT_APP_BASE_URL}` +
          END_POINT.RFQ +
          "/" +
          END_POINT.GET_RFQ_MODALS_DETAILS +
          `/${rfqId}`,
        { headers: { Authorization: token } },
      );
      let { dealStats, orderBook, quoteParameters, rfqQuote, rfqDetails } =
        rfqModalDetails.data;
        
      dealStats = [
        {
          ...dealStats[0],
          fwd_scale:
            dealStats[0].userSpreads.length > 0 &&
            dealStats[0].userSpreads[0].fwd_scale
              ? dealStats[0].userSpreads[0].fwd_scale
              : dealStats[0].fwd_scale,
        },
      ];
      sessionStorage.setItem("rfqStatus", rfqDetails[0].status);
      sessionStorage.setItem("rfqTraderId", rfqDetails[0].trader_id);

      dispatch(
        rfqAggregatorSlice.setRfqDefaultQuoteParams(
          quoteParameters.defaultQuoteParameters[0],
        ),
      );
      //Handler Rfq tgat Is Not Equal To Duble Leg Vol
      if (quoteParameters.userQuoteParameters.length === 0) {
   
        if (
          dealStats[0].userSpreads.length > 0 &&
          dealStats[0].userSpreads[0].userSpreadBid !== "NaN" &&
          dealStats[0].userSpreads[0].userSpreadOffer !== "NaN"
        ) {
          dispatch(
            rfqAggregatorSlice.setUsersSpreadhandler(dealStats[0].userSpreads),
          );
          let copyDealStatsData = { ...dealStats[0] };
          if (quoteParameters.defaultQuoteParameters[0].quote_type === "SWAP") {
            let regex = /[,\s]/g;
            let marketBid = copyDealStatsData.market_bid.replace(regex, "");
            let marketOffer = copyDealStatsData.market_offer.replace(regex, "");
            copyDealStatsData.makor_bid = (
              +marketBid - +dealStats[0].userSpreads[0].userSpreadBid
            ).toFixed(3);
            copyDealStatsData.makor_offer = (
              +marketOffer + +dealStats[0].userSpreads[0].userSpreadOffer
            ).toFixed(3);
          } else {
            copyDealStatsData.makor_bid =
              Number(copyDealStatsData.market_bid) -
              Number(dealStats[0].userSpreads[0].userSpreadBid).toFixed(3);
            copyDealStatsData.makor_offer = (
              Number(copyDealStatsData.market_offer) +
              Number(dealStats[0].userSpreads[0].userSpreadOffer)
            ).toFixed(3);
          }
          let jtableDict = await createQuoteBookDict(rfqId);
          copyDealStatsData = {
            ...copyDealStatsData,
            pb: dealStats[0].userSpreads[0].pb,
          };
          let dealStatsData = await apiGetDealStats(
            quoteParameters.defaultQuoteParameters[0].quote_type,
            jtableDict,
            quoteParameters.defaultQuoteParameters[0],
            copyDealStatsData,
            orderBook,
            true,
          );
          dispatch(rfqSlice.updateTableData(orderBook));
          // dispatch(rfqSlice.updateDealStatsData(dealStats[0]));
          dispatch(
            rfqAggregatorSlice.updateQuoteParameterts(
              quoteParameters.defaultQuoteParameters[0],
            ),
          );
          dealStatsData.pb = dealStats[0].userSpreads[0].pb;

          dispatch(rfqSlice.updateDealStatsData(dealStatsData));
        } else {
          if (quoteParameters.defaultQuoteParameters[0].quote_type === "SWAP") {
            let regex = /[,\s]/g;
            let marketBid = dealStats[0].market_bid.replace(regex, "");
            let makorBid = dealStats[0].makor_bid.replace(regex, "");
            let marketOffer = dealStats[0].market_offer.replace(regex, "");
            let makorOffer = dealStats[0].makor_offer.replace(regex, "");

            let userSpredDetails = [
              {
                userSpreadBid: (+marketBid - +makorBid).toFixed(3),
                isBidUser: false,
                isUserChangeBid: "0",
                isUserChangeOffer: "0",
                isUserChangeSpreadOffer: "0",
                isUserChangeSpreadBid: "0",
                userSpreadOffer: (+makorOffer - +marketOffer).toFixed(3),
              },
            ];
            dispatch(
              rfqAggregatorSlice.setUsersSpreadhandler(userSpredDetails),
            );
            dispatch(rfqSlice.updateTableData(orderBook));
            dispatch(rfqSlice.updateDealStatsData(dealStats[0]));
            dispatch(
              rfqAggregatorSlice.updateQuoteParameterts(
                quoteParameters.defaultQuoteParameters[0],
              ),
            );
          } else {
            let userSpredDetails = [
              {
                userSpreadBid: (
                  Number(dealStats[0].market_bid) -
                  Number(dealStats[0].makor_bid)
                ).toFixed(3),
                isBidUser: false,
                isUserChangeBid: "0",
                isUserChangeOffer: "0",
                isUserChangeSpreadOffer: "0",
                isUserChangeSpreadBid: "0",
                userSpreadOffer: (
                  Number(dealStats[0].makor_offer) -
                  Number(dealStats[0].market_offer)
                ).toFixed(3),
              },
            ];
            dispatch(
              rfqAggregatorSlice.setUsersSpreadhandler(userSpredDetails),
            );
            dispatch(rfqSlice.updateTableData(orderBook));
            dispatch(rfqSlice.updateDealStatsData(dealStats[0]));
            dispatch(
              rfqAggregatorSlice.updateQuoteParameterts(
                quoteParameters.defaultQuoteParameters[0],
              ),
            );
          }
        }
      } else if (quoteParameters.userQuoteParameters.length > 0) {
        let quote = rfqQuote.map((q) => {
          return {
            ...q,
            quoted_vol1: q.quotevol_1,
            quoted_vol2: q.quotevol_2,
          };
        });
        let jtableDict = await createQuoteBookDict(rfqId);
        let refreshQuoteBook = await apiRefreshQuoteBook(
          quoteParameters.userQuoteParameters[0].quote_type,
          quote,
          jtableDict,
          quoteParameters.userQuoteParameters[0],
        );
        let updatedOrderBook = await buildOrderBook(refreshQuoteBook);

        let defaultDealStats = await apiGetDealStats(
          quoteParameters.userQuoteParameters[0].quote_type,
          jtableDict,
          quoteParameters.userQuoteParameters[0],
          dealStats[0],
          updatedOrderBook,
          false,
        );
        if (
          dealStats[0].userSpreads.length > 0 &&
          dealStats[0].userSpreads[0].userSpreadBid !== "NaN" &&
          dealStats[0].userSpreads[0].userSpreadOffer !== "NaN"
        ) {
          dispatch(
            rfqAggregatorSlice.setUsersSpreadhandler(dealStats[0].userSpreads),
          );
          let copyDealStatsData = { ...defaultDealStats };
          if (quoteParameters.defaultQuoteParameters[0].quote_type === "SWAP") {
            let regex = /[,\s]/g;
            let marketBid = copyDealStatsData.market_bid.replace(regex, "");
            let marketOffer = copyDealStatsData.market_offer.replace(regex, "");
            copyDealStatsData.makor_bid = (
              +marketBid - +dealStats[0].userSpreads[0].userSpreadBid
            ).toFixed(3);
            copyDealStatsData.makor_offer = (
              +marketOffer + +dealStats[0].userSpreads[0].userSpreadOffer
            ).toFixed(3);
          } else {
            copyDealStatsData.makor_bid =
              Number(copyDealStatsData.market_bid) -
              Number(dealStats[0].userSpreads[0].userSpreadBid).toFixed(3);
            copyDealStatsData.makor_offer = (
              Number(copyDealStatsData.market_offer) +
              Number(dealStats[0].userSpreads[0].userSpreadOffer)
            ).toFixed(3);
          }
          copyDealStatsData = {
            ...copyDealStatsData,
            pb: dealStats[0].userSpreads[0].pb,
          };
          let userDealStats = await apiGetDealStats(
            quoteParameters.userQuoteParameters[0].quote_type,
            jtableDict,
            quoteParameters.userQuoteParameters[0],
            copyDealStatsData,
            updatedOrderBook,
            true,
          );
          userDealStats.pb = dealStats[0].userSpreads[0].pb;
          dispatch(rfqSlice.updateDealStatsData(userDealStats));
          dispatch(
            rfqAggregatorSlice.updateQuoteParameterts(
              quoteParameters.userQuoteParameters[0],
            ),
          );
          dispatch(rfqSlice.updateTableData(updatedOrderBook));
        } else {
          let userDealStats = await apiGetDealStats(
            quoteParameters.userQuoteParameters[0].quote_type,
            jtableDict,
            quoteParameters.userQuoteParameters[0],
            dealStats[0],
            updatedOrderBook,
            false,
          );
          if (quoteParameters.defaultQuoteParameters[0].quote_type === "SWAP") {
            let regex = /[,\s]/g;
            let marketBid = userDealStats.market_bid.replace(regex, "");
            let makorBid = userDealStats.makor_bid.replace(regex, "");
            let marketOffer = userDealStats.market_offer.replace(regex, "");
            let makorOffer = userDealStats.makor_offer.replace(regex, "");

            let userSpredDetails = [
              {
                userSpreadBid: (+marketBid - +makorBid).toFixed(3),
                isBidUser: false,
                isUserChangeBid: "0",
                isUserChangeOffer: "0",
                isUserChangeSpreadOffer: "0",
                isUserChangeSpreadBid: "0",
                userSpreadOffer: (+makorOffer - +marketOffer).toFixed(3),
              },
            ];
            dispatch(
              rfqAggregatorSlice.setUsersSpreadhandler(userSpredDetails),
            );
            console.log(updatedOrderBook,"updatedOrderBook")
            dispatch(rfqSlice.updateTableData(updatedOrderBook));
            dispatch(rfqSlice.updateDealStatsData(userDealStats));
            dispatch(
              rfqAggregatorSlice.updateQuoteParameterts(
                quoteParameters.userQuoteParameters[0],
              ),
            );
          } else {
            let userSpredDetails = [
              {
                userSpreadBid: (
                  Number(userDealStats.market_bid) -
                  Number(userDealStats.makor_bid)
                ).toFixed(3),
                isBidUser: false,
                isUserChangeBid: "0",
                isUserChangeOffer: "0",
                isUserChangeSpreadOffer: "0",
                isUserChangeSpreadBid: "0",
                userSpreadOffer: (
                  Number(userDealStats.makor_offer) -
                  Number(userDealStats.market_offer)
                ).toFixed(3),
              },
            ];
            dispatch(
              rfqAggregatorSlice.setUsersSpreadhandler(userSpredDetails),
            );
            dispatch(rfqSlice.updateTableData(updatedOrderBook));
            dispatch(rfqSlice.updateDealStatsData(userDealStats));
            dispatch(
              rfqAggregatorSlice.updateQuoteParameterts(
                quoteParameters.userQuoteParameters[0],
              ),
            );
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleRequestSort = (event, property) => {
    const isAscending = valueToOrderBy === property && orderDirection === "asc";
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? "desc" : "asc");
  };
  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };
  const getComprator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };
  const soryedRowInformation = (rowArray, comparator) => {
    const stabilizedRowArray = rowArray.map((el, index) => [el, index]);
    stabilizedRowArray.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedRowArray.map((el) => el[0]);
  };
  let updateRfqBookingModeDetails = async (id) => {
    const token = sessionStorage.getItem("token");
    const status = sessionStorage.getItem("status");
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}` +
          "rfq" +
          `/rfq_booking_details/${id}`,
        {
          headers: { Authorization: token },
        },
      );

      if (response.data.rfqDetails.length > 0) {
        dispatch(
          rfqSlice.updateRfqBookingModeUpdatingRows(response.data.rfqDetails),
        );
      } else {
        dispatch(rfqSlice.updateRfqBookingModeUpdatingRows([]));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const open = (e, id) => {
    if (e.detail === 2) {
      sessionStorage.setItem("rfqId", id);
      updateRfqBookingModeDetails(id);
      const filteredRecommendation = recommendationRfqs.filter(
        (rfq) => Number(rfq.rfqId) === Number(selectedRfq),
      );
      if (filteredRecommendation.length === 0) {
        getRecommendation(id);
      }
      handleRfqModalsDetails(id);
      dispatch(rfqSlice.getCheckedRfq(id));
      const checkboxFilter = copyRfq.map((checkBox) => {
        if (checkBox.rfq_id === id) {
          if (!checkBox.checked === false) {
            setSelectedRfq("");
          }
          return { ...checkBox, checked: !checkBox.checked };
        }
        return checkBox;
      });
      let getRfqStatus = checkboxFilter.filter((rfq) => {
        return rfq.rfq_id === id;
      });

      if (
        getRfqStatus[0].status === "Live" ||
        getRfqStatus[0].status === "Idea" ||
        getRfqStatus[0].status === "Draft"
      ) {
        sessionStorage.setItem("status", "contribute");
        sessionStorage.setItem("rfqMode", "rfq aggregator view");
        // sessionStorage.setItem("status", "db aggregator view");
        dispatch(
          rfqSlice.updateRfqLocationMode({
            rfq_id: id,
            rfqMode: "rfq aggregator view",
          }),
        );
        navigate(`/rfqworkspace/${selectedRfq}`);
      }
      if (
        getRfqStatus[0].status === "Booked" ||
        getRfqStatus[0].status === "Confirmed" ||
        getRfqStatus[0].status === "Confirmed & Booked"
      ) {
        // sessionStorage.setItem("rfqMode", "db booking view");
        sessionStorage.setItem("status", "open");
        sessionStorage.setItem("rfqMode", "rfq booking view");
        dispatch(
          rfqSlice.updateRfqLocationMode({
            rfq_id: sessionStorage.getItem("rfqId"),
            rfqMode: "rfq booking view",
          }),
        );
        navigate(`/rfqworkspace/${selectedRfq}`);
      }
      if (
        getRfqStatus[0].status === "Traded Away" ||
        getRfqStatus[0].status === "Not Traded"
      ) {
        sessionStorage.setItem("status", "open");
        sessionStorage.setItem("rfqMode", "rfq view");
        dispatch(
          rfqSlice.updateRfqLocationMode({
            rfq_id: sessionStorage.getItem("rfqId"),
            rfqMode: "rfq view",
          }),
        );
        navigate(`/rfqworkspace/${selectedRfq}`);
      }

      dispatch(rfqSlice.clearRfqState());
      dispatch(rfqSlice.changeStatusChecked(checkboxFilter));

      // navigate(`/rfqworkspace/${selectedRfq}`);
    }
  };
  useEffect(() => {
    if (rfq.length > 0) {
      let findRfq = rfq.filter((key) => {
        return key.checked === true;
      });

      if (findRfq.length > 0) {
        sessionStorage.setItem("rfqId", findRfq[0].rfq_id);
      }
    }
  }, [rfq]);
  const subToRfqQuote = (newCreatedRfqId) => {
    const token = sessionStorage.getItem("token");
    if (ws !== null) {
      sendEvent({
        type: "subscribe_to_rfq_quote_id",
        data: { rfqId: `${newCreatedRfqId}` },
        metaData: {},
      });
    } else {
      connectWS(token.replace("Bearer ", ""), dispatch, rfqSlice);
      sendEvent({
        type: "subscribe_to_rfq_quote_id",
        data: { rfqId: `${newCreatedRfqId}` },
        metaData: {},
      });
    }
  };
  const unSubFromRfqQuote = (newCreatedRfqId) => {
    const token = sessionStorage.getItem("token");
    if (ws !== null) {
      sendEvent({
        type: "unsubscribe_rfq_quote",
        data: { rfqId: `${newCreatedRfqId}` },
        metaData: {},
      });
    } else {
      connectWS(token.replace("Bearer ", ""), dispatch, rfqSlice);
      sendEvent({
        type: "unsubscribe_rfq_quote",
        data: { rfqId: `${newCreatedRfqId}` },
        metaData: {},
      });
    }
  };
  return (
    <div className={classes.rfqTableContainer} style={{ minHeight: "400px" }}>
      <TableContainer
        className={classes.tableContainer}
        style={{
          width: "99%",
          borderRadius: "8px 8px 0px 0px",
          border: "1px solid var(--unnamed-color-686b76)",
          border: "1px solid #686B76",
          opacity: 1,
        }}
      >
        <Table size="small" stickyHeader className={classes.stickyHeader}>
          <TableHead style={{ pading: "10px" }}>
            <TableRow>
              <TableCell></TableCell>

              <TableCell key={"rfq_description"}>
                {/* <TableCell> */}
                <Typography className="headerText">Rfq</Typography>
                {/* </TableCell> */}
              </TableCell>

              <TableCell key={"status"}>
                <TableSortLabel
                  active={true}
                  direction={
                    valueToOrderBy === "status" ? orderDirection : "asc"
                  }
                  classes={{
                    icon:
                      valueToOrderBy === "status"
                        ? classes.activeSortIcon
                        : classes.inactiveSortIcon,
                  }}
                  onClick={(e) => handleRequestSort(e, "status")}
                >
                  <Typography className="headerText">Status</Typography>
                </TableSortLabel>
              </TableCell>

              <TableCell key={"userName"}>
                {/* <TableCell> */}
                <Typography className="headerText">Owner</Typography>
                {/* </TableCell> */}
              </TableCell>

              <TableCell key={"created_on"}>
                <TableSortLabel
                  active={true}
                  direction={
                    valueToOrderBy === "created_on" ? orderDirection : "asc"
                  }
                  onClick={(e) => handleRequestSort(e, "created_on")}
                >
                  <Typography className="headerText">Created on</Typography>
                </TableSortLabel>
              </TableCell>

              <TableCell key={"client_name"}>
                <TableSortLabel
                  active={true}
                  direction={
                    valueToOrderBy === "client_name" ? orderDirection : "asc"
                  }
                  onClick={(e) => handleRequestSort(e, "client_name")}
                >
                  <Typography className="headerText">Client</Typography>
                </TableSortLabel>
              </TableCell>

              <TableCell key={"market-maker"}>
                <TableSortLabel
                  active={true}
                  direction={
                    valueToOrderBy === "client_name" ? orderDirection : "asc"
                  }
                  onClick={(e) => handleRequestSort(e, "client_name")}
                >
                  <Typography className="headerText">LP</Typography>
                </TableSortLabel>
              </TableCell>

              <TableCell key={"trader_notes"}>
                {/* <TableCell> */}
                <Typography className="headerText">Trader Note</Typography>
                {/* </TableCell> */}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {soryedRowInformation(
              copyRfq
                .sort((a, b) => {
                  return (
                    new Date(b.created_on).getTime() -
                    new Date(a.created_on).getTime()
                  );
                })
                .sort((a, b) => {
                  return a.status - b.status;
                }),
              getComprator(orderDirection, valueToOrderBy),
            ).map((rfq, index) => {
              return (
                <TableRow
                  style={{ padding: "100px" }}
                  key={rfq.rfq_id}
                  onClick={(e) => handleOnChange(e, rfq.rfq_id)}
                  className={clsx(classes.checked, {
                    wsNewRfk: rfq.color === true,
                    [classes.stripedRows]: rfq.checked !== true,
                  })}
                >
                  <TableCell
                    style={{ paddingLeft: "14px" }}
                    className={rfq.color === true ? "transition" : ""}
                  >
                    {rfq.color === true ? (
                      <div style={{ paddingBlock: "14px" }}>
                        <div className="newRfq">New</div>
                      </div>
                    ) : (
                      <CustomColorCheckbox
                        checked={rfq.checked}
                        onChange={(e) => handleOnChange(e, rfq.rfq_id)}
                        id={rfq?.rfq_id?.toString()}
                      />
                    )}
                  </TableCell>
                  <TableCell align="left">
                    <Button onClick={(e) => open(e, rfq.rfq_id)}>
                      <Typography className="basic-style">
                        {rfq.rfq_description}
                      </Typography>
                    </Button>
                  </TableCell>
                  <TableCell align="left">
                    {rfq.status === "Confirmed" ||
                    rfq.status === "Booked" ||
                    rfq.status === "Confirmed & Booked" ? (
                      <DoneIcon />
                    ) : rfq.status === "Live" || rfq.status === "Draft" ? (
                      <CircleIcon
                        style={{
                          color:
                            rfq.status === "Live"
                              ? "#ff4e32"
                              : rfq.status === "Draft"
                              ? "#2692ff"
                              : null,
                          height: " 10px",
                          width: "10px",
                        }}
                      />
                    ) : rfq.status === "Idea" ? (
                      <LightbulbIcon style={{ color: "#2692ff" }} />
                    ) : (
                      <NotInterestedIcon
                        style={{
                          color: "#A8A8A8",
                        }}
                      />
                    )}
                    <Typography
                      style={{
                        display: "inline-flex",
                        marginLeft: "5px",
                        marginTop: "5px",
                        color:
                          rfq.status === "Live"
                            ? "red"
                            : rfq.status === "Draft" || rfq.status === "Idea"
                            ? "#2692ff"
                            : rfq.status === "Confirmed" ||
                              rfq.status === "Booked" ||
                              rfq.status === "Confirmed & Booked"
                            ? "rgb(0, 216, 101)"
                            : rfq.status === "Traded Away" ||
                              rfq.status === "Not Traded"
                            ? "#A8A8A8"
                            : null,
                        position: "relative",
                        top:
                          rfq.status === "Traded Away" ||
                          rfq.status === "Not Traded"
                            ? "-4px"
                            : null,
                      }}
                    >
                      {rfq.status}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography className="basic-style">
                      {rfq.userName}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography className="basic-style">
                      {rfq.created_on}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography className="basic-style">
                      {rfq.post_trade_stats &&
                      Object.keys(rfq.post_trade_stats).length > 0
                        ? rfq.post_trade_stats.client_selection
                        : rfq.client_id}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography className="basic-style">
                      {rfq.post_trade_stats &&
                      Object.keys(rfq.post_trade_stats).length > 0
                        ? rfq.post_trade_stats.market_maker_selection
                        : ""}
                    </Typography>
                  </TableCell>
                  {rfq.trader_notes?.length > 18 ? (
                    <TableCell align="left">
                      <StyledTooltip title={rfq.trader_notes}>
                        <Typography className="basic-style">
                          {rfq.trader_notes?.substring(0, 17) + "..."}
                        </Typography>
                      </StyledTooltip>
                    </TableCell>
                  ) : (
                    <TableCell align="left">
                      <Typography className="basic-style">
                        {rfq.trader_notes}
                      </Typography>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {copyRfq.length === 0 && (
        <div>
          <Typography
            style={{ color: "white", textAlign: "center", marginTop: "10px" }}
          >
            Sorry No rows to show
          </Typography>
        </div>
      )}
    </div>
  );
};

export const useStyles = makeStyles((theme) => ({
  stickyHeader: {
    "& .MuiTableCell-stickyHeader": {
      background: "#171717 0% 0% no-repeat padding-box",
    },
  },
  checked: {
    background: "#3A3C3F 0% 0% no-repeat padding-box",
    opacity: 1,
  },
  activeSortIcon: {
    opacity: 1,
  },
  inactiveSortIcon: {
    opacity: 0.4,
  },
  "@global": {
    "*::-webkit-scrollbar": {
      transform: " matrix(0, 1, -1, 0, 0, 0)",
      width: "8px",
      height: "21px",
    },
    "*::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      outline: "1px solid slategrey",
      borderRadius: 7,
      background: " #3A3C3F 0% 0% no-repeat padding-box",
      // borderRadius: "4px",
      opacity: 1,
    },
  },
  stripedRows: {
    "&:nth-of-type(odd)": {
      background: " #222222 0% 0% no-repeat padding-box",
      borderRadius: "8px",
      opacity: 1,
    },
    "&:nth-of-type(even)": {
      background: "#2D2D2D 0% 0% no-repeat padding-box",
      opacity: 1,
    },
  },
  rfqTableContainer: {
    marginTop: "30px",
    width: "100%",
  },
  tableContainer: {
    overflow: "auto",
    width: "80vw",
    minWidth: "80vw",
    maxHeight: "400px",
  },
  done: {
    marginRight: "10px",
    marginBottom: "10px",
  },
  text: {
    color: "white",
  },
  statusTextGreen: {
    color: "green",
  },
  statusTextOrange: {
    color: "#ffbf00",
  },
  statusTextWhite: {
    color: "white",
  },
  highlightedRows: {
    animationName: "example",
    animationDuration: "5s",
  },
  headerText: {
    color: "var(--unnamed-color-686b76)",
    textalign: "left",
    font: "normal normal normal 16px/20px Inter",
    letterSpacing: "0px",
    color: "#686B76",
    opacity: 1,
  },
}));

const CustomColorCheckbox = withStyles({
  root: {
    width: "24px",
    height: "24px",
    color: "#686B76",
    opacity: 1,
    "&$checked": {
      color: "#13c552",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const StyledTooltip = withStyles({
  tooltip: {
    fontSize: "1em",
    width: "273px",
    height: "95px",
    background: "#3A3C3F 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000066",
    borderRadius: "4px",
    opacity: 1,
    textAlign: "center",
    paddingTop: "18px",
  },
})(Tooltip);
const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(MuiTableCell);
