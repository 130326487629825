import axios from "axios";
import { END_POINT } from "../../../utils";
import { useDispatch } from "react-redux";
import * as actionSnackBar from "../../../store/snackbar/action";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import MMTableRowView from "./MMTableRow.view";

function MMTableRow(props) {
  let { mm, deleteMM, getMarketMakersData, mmIndex } = props;
  const dispatch = useDispatch();
  const [chosenPms, setChosenPms] = useState([]);
  const [chosenAgreement, setChosenAgreement] = useState("");
  const [chosenPmsIds, setChosenPmsIds] = useState([]);
  const [marketMakerName, setMarketMakerName] = useState("");
  const [MMShortName, setMMShortName] = useState("");
  const [mmCompany, setMMCompany] = useState("");
  const [marketMakersDefaultEmail, setMarketMakersDefaultEmail] = useState();
  const [isEdit, setIsEdit] = useState(false);

  const handlePmChange = (e) => {
    let lastValIndex = e.target.value.length - 1;
    let currentClick = e.target.value[lastValIndex];
    let exist = chosenPms.filter((p) => p === currentClick.pm);
    if (exist.length > 0) {
      let newChosenPms = chosenPms.filter((p) => p !== currentClick.pm);
      let newChosenPmsIds = chosenPmsIds.filter((p) => p !== currentClick.id);
      setChosenPms(newChosenPms);
      setChosenPmsIds(newChosenPmsIds);
    } else {
      setChosenPms([...chosenPms, currentClick.pm]);
      setChosenPmsIds([...chosenPmsIds, currentClick.id]);
    }
  };

  const handleEdit = () => {
    let preCheckedPms = mm.pmsList.filter((pm) => pm.checked === true);
    let names = preCheckedPms.map((pm) => pm.pm);
    names = typeof value === "string" ? names.split(",") : names;
    if (chosenPms.length === 0) {
      setChosenPms(names);
    }
    let ids = preCheckedPms.map((pm) => pm.id);
    setChosenPmsIds(ids);
    setMarketMakerName(
      marketMakerName !== "" ? marketMakerName : mm.name
    );
    setMMShortName(MMShortName !== "" ? MMShortName : mm.short_name);
    setChosenAgreement(chosenAgreement !== "" ? chosenAgreement : mm.agreement);
    setMarketMakersDefaultEmail(
      marketMakersDefaultEmail !== null && marketMakersDefaultEmail !== ""
        ? mm.default_email
        : marketMakersDefaultEmail
    );
    setMMCompany(mmCompany !== "" ? mmCompany : mm.company);
    setIsEdit(true);
  };

  const handleAgreementChange = (e) => {
    setChosenAgreement(e.target.value);
  };

  const handelMMCompanyChange = (e) => {
    setMMCompany(e.target.value);
  };

  const changeMMDefaultEmail = (e) => {
    setMarketMakersDefaultEmail(e.target.value.replaceAll(",", ";"));
  };

  useEffect(() => {
    if (mm.id === null) {
      setIsEdit(true);
    }
  }, [mm]);

  const updateMm = async () => {
    let clientToUpdateObj = {
      id: mm.id,
      name: marketMakerName,
      short_name: MMShortName,
      company: mmCompany,
      agreement: chosenAgreement,
      default_email: marketMakersDefaultEmail,
      pm_ids: chosenPmsIds.length > 0 ? chosenPmsIds : null,
    };
    const token = sessionStorage.getItem("token");
    try {
      let res = await axios.put(
        `${process.env.REACT_APP_BASE_URL}${END_POINT.DATA_MGMT}/update_market_maker`,
        clientToUpdateObj,
        { headers: { Authorization: token } }
      );
      dispatch(actionSnackBar.setSnackBar("success", res.data, 3000));
      // setIsEdit(false);
      getMarketMakersData();
    } catch (error) {
      dispatch(actionSnackBar.setSnackBar("error", error.message, 3000));
    }
  };

  const addNewMMToDb = async () => {
    const token = sessionStorage.getItem("token");
    let newClient = {
      name: marketMakerName,
      short_name: MMShortName,
      company: mmCompany,
      agreement: chosenAgreement,
      default_email: marketMakersDefaultEmail,
      pm_ids: chosenPmsIds.length > 0 ? chosenPmsIds : null,
    };
    try {
      let res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${END_POINT.DATA_MGMT}/add_market_maker`,
        newClient,
        { headers: { Authorization: token } }
      );
      dispatch(actionSnackBar.setSnackBar("success", res.data.message, 3000));
      setIsEdit(false);
      getMarketMakersData();
    } catch (error) {
      dispatch(actionSnackBar.setSnackBar("error", error.message, 3000));
    }
  };

  const routerCreateOrUpdateClient = () => {
    if (mm.id !== null && mm.id !== undefined) {
      updateMm();
    } else {
      addNewMMToDb();
    }
  };

  return (
    <MMTableRowView
      isEdit={isEdit}
      mmIndex={mmIndex}
      mm={mm}
      setIsEdit={setIsEdit}
      chosenPms={chosenPms}
      setChosenPms={setChosenPms}
      handleEdit={handleEdit}
      marketMakerName={marketMakerName}
      setMarketMakerName={setMarketMakerName}
      setChosenAgreement={setChosenAgreement}
      setMMShortName={setMMShortName}
      deleteMM={deleteMM}
      mmCompany={mmCompany}
      setMMCompany={setMMCompany}
      handlePmChange={handlePmChange}
      chosenAgreement={chosenAgreement}
      MMShortName={MMShortName}
      handleAgreementChange={handleAgreementChange}
      handelMMCompanyChange={handelMMCompanyChange}
      routerCreateOrUpdateClient={routerCreateOrUpdateClient}
      marketMakersDefaultEmail={marketMakersDefaultEmail}
      changeMMDefaultEmail={changeMMDefaultEmail}
    />
  );
}

export default MMTableRow;
