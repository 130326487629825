import {
  GreenRadio,
  StyledTableCell,
  StyledTableRow,
} from "./RatesTable.styles";
import moment from "moment";
import { Checkbox, Radio } from "@mui/material";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { useStyles } from "./RatesTable.styles";
import { useSelector } from "react-redux";

function RateTableRowView(props) {
  const { rate, numberWithCommas, linkRates } = props;
  const { chosenRates } = useSelector((state) => state?.ratesSlice);
  const classes = useStyles();
  return (
    <StyledTableRow
      className={rate.isNew === true ? "newRates" : null}
      onClick={() => linkRates(rate.TradeId)}
      style={{
        backgroundColor:
          chosenRates.filter((r) => r.TradeId === rate.TradeId)
            .length > 0 && chosenRates.length > 0
            ? "#2b2a2a"
            : "#171717",
      }}
    >
      <StyledTableCell
        style={{
          textAlign: "center",
          position: "sticky",
          width: "2%",
          left: 0,
          backgroundColor:
          chosenRates.filter((r) => r.TradeId === rate.TradeId)
            .length > 0 && chosenRates.length > 0
            ? "#2b2a2a"
            : "#171717",
        }}
      >
        <GreenRadio
          size="small"
          checked={rate.checked}
          className={classes.checkbox}
          name="checkedH"
        />
      </StyledTableCell>
      {Object.entries(rate).map(([key, value], index) => {
        if (key !== "deal_id" && key !== "isNew" && key !== "checked")
          if (
            key === "TradeDate" ||
            key === "StartDate" ||
            key === "EndDate" ||
            key === "AdditionalPaymentDate"
          ) {
            return (
              <StyledTableCell key={index}>
                {value !== "" && value !== null && value !== undefined
                  ? moment(value, "DD/MM/YYYY").format("DD-MMM-YYYY")
                  : ""}
              </StyledTableCell>
            );
          } else if (key === "Notional" || key === "AdditionalPaymentAmount") {
            return (
              <StyledTableCell key={index}>
                {numberWithCommas(value)}
              </StyledTableCell>
            );
          } else {
            return (
              <StyledTableCell key={index}>{value}</StyledTableCell>
            );
          }
      })}
    </StyledTableRow>
  );
}

export default RateTableRowView;
