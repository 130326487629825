import React, { useState, useEffect } from "react";
import { RfqWorkspaceTable } from "../RfqWorkspaceTable/RfqWorkspaceTable";
import { RfqWorkspaceHeader } from "../RfqWorkspaceHeader/RfqWorkspaceHeader";
import * as rfqSlice from "../../../../store/rfq/rfqSlice";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import RfqQuoteBook from "../rfqQuoteBook/RfqQuoteBook";
import * as rfqAggregatorSlice from "../../../../store/rfq/rfqAggregatorSlice";
import workerInstances from "../../../../services/index";
import {
  ws,
  connectWS,
  sendEvent,
  closeWS,
} from "../../../../services/websocket";

import { makeStyles } from "@material-ui/core/styles";
import CircleIcon from "@mui/icons-material/Circle";
import { END_POINT } from "../../../../utils";
import axios from "axios";
import {
  apiGetDealStats,
  apiRefreshQuoteBook,
  buildOrderBook,
} from "../../../../services/apiCalls/quotsBookApi";
import { createQuoteBookDict } from "../../../../services/rfqWorkspace/createQuoteBookDict";

const audio = new Audio();
export const RfqWorkspace = () => {
  const token = sessionStorage.getItem("token");
  const status = useSelector((state) => state.rfqSlice.rfqButtonStatus);
  const rfqInstanceList = useSelector(
    (state) => state.rfqSlice.rfqInstanceList,
  );

  const dispatch = useDispatch();
  const rfqMode = sessionStorage.getItem("rfqMode");
  // const rfqMode = useSelector((state) => state.rfqSlice.rfqMode);
  const rfqObj = useSelector((state) => state.rfqSlice.rfqId);
  const classes = useStyles();
  const socketStatus = useSelector((state) => state.rfqSlice.socketStatus);
  let recommendationRfqs = useSelector(
    (state) => state.rfqSlice.recommendationRfqs,
  );
  const rfq = useSelector((state) => state.rfqSlice.rfq);

  let [rfq_object, setrfq_object] = useState({
    rfq_description: null,
    launch_status: false,
    rfq_table_data: null,
    aggregator_mode: false,
    booking_mode: false,
    quote_type: "",
    rfq_str: "",
    ovml_tail: "",
    order_book: null,
    quote_parameters: null,
    quote_book: null,
    deal_stats: null,
    rfq_table_config: null,
    status: "Live",
  });
  const isLoadingCrossList = useSelector(
    (state) => state.rfqSlice.cross_list_data.isLoading,
  );
  const isLoadingPbAccounts = useSelector(
    (state) => state.rfqSlice.pb_accounts.isLoading,
  );
  const isLoadingdealType = useSelector(
    (state) => state.rfqSlice.dealTypes.isLoading,
  );
  const isLoadingClients = useSelector(
    (state) => state.rfqSlice.clients.isLoading,
  );
  const isLoadingUserMarketMakers = useSelector(
    (state) => state.rfqAggregatorSlice.userMarketMakers.isLoading,
  );
  const isLoadingMarketMakers = useSelector(
    (state) => state.rfqAggregatorSlice.marketMakers.isLoading,
  );
  const isLoadingClientList = useSelector(
    (state) => state.rfqSlice.clientsList.isLoading,
  );
  const [buttonBackgrounds, setButtonBackgrounds] = useState({
    rfqViewBc: "#FF681C",
    aggregatorBc: "#171717",
    rfqBokingBc: "#171717",
  });
  const rfqId = useSelector((state) => state.rfqSlice.items);
  const handleRfqModalsDetails = async (rfqId) => {
    try {
      let rfqModalDetails = await axios.get(
        `${process.env.REACT_APP_BASE_URL}` +
          END_POINT.RFQ +
          "/" +
          END_POINT.GET_RFQ_MODALS_DETAILS +
          `/${rfqId}`,
        { headers: { Authorization: token } },
      );
      let { dealStats, orderBook, quoteParameters, rfqQuote, rfqDetails } =
        rfqModalDetails.data;
      dealStats = [
        {
          ...dealStats[0],
          fwd_scale:
            dealStats[0].userSpreads.length > 0 &&
            dealStats[0].userSpreads[0].fwd_scale
              ? dealStats[0].userSpreads[0].fwd_scale
              : dealStats[0].fwd_scale,
        },
      ];
      sessionStorage.setItem("rfqStatus", rfqDetails[0].status);
      sessionStorage.setItem("rfqTraderId", rfqDetails[0].trader_id);

      dispatch(
        rfqAggregatorSlice.setRfqDefaultQuoteParams(
          quoteParameters.defaultQuoteParameters[0],
        ),
      );
      //Handler Rfq tgat Is Not Equal To Duble Leg Vol
      if (quoteParameters.userQuoteParameters.length === 0) {
        if (
          dealStats[0].userSpreads.length > 0 &&
          dealStats[0].userSpreads[0].userSpreadBid !== "NaN" &&
          dealStats[0].userSpreads[0].userSpreadOffer !== "NaN"
        ) {

          dispatch(
            rfqAggregatorSlice.setUsersSpreadhandler(dealStats[0].userSpreads),
          );
          let copyDealStatsData = { ...dealStats[0] };
        
          if(quoteParameters.defaultQuoteParameters[0].quote_type === "SWAP"){
            let regex = /[,\s]/g;
            let marketBid = copyDealStatsData.market_bid.replace(
              regex,
              ""
            );
            let marketOffer = copyDealStatsData.market_offer.replace(
              regex,
              ""
            );
            copyDealStatsData.makor_bid =
             ( +marketBid - +dealStats[0].userSpreads[0].userSpreadBid).toFixed(3);
            copyDealStatsData.makor_offer =
              (+marketOffer +
              +dealStats[0].userSpreads[0].userSpreadOffer).toFixed(3);
            }else {
              copyDealStatsData.makor_bid =
              Number(copyDealStatsData.market_bid) -
              Number(dealStats[0].userSpreads[0].userSpreadBid).toFixed(
                3,
              );
            copyDealStatsData.makor_offer = (
              Number(copyDealStatsData.market_offer) +
              Number(dealStats[0].userSpreads[0].userSpreadOffer)
            ).toFixed(3);
            }
          // copyDealStatsData.pb = dealStats[0].userSpreads[0].pb
        
          copyDealStatsData = {...copyDealStatsData,pb:dealStats[0].userSpreads[0].pb}
         
          let jtableDict = await createQuoteBookDict(rfqId);
          let dealStatsData = await apiGetDealStats(
            quoteParameters.defaultQuoteParameters[0].quote_type,
            jtableDict,
            quoteParameters.defaultQuoteParameters[0],
            copyDealStatsData,
            orderBook,
            true,
          );
          
          dispatch(rfqSlice.updateTableData(orderBook));
          // dispatch(rfqSlice.updateDealStatsData(dealStats[0]));
          dispatch(
            rfqAggregatorSlice.updateQuoteParameterts(
              quoteParameters.defaultQuoteParameters[0],
            ),
          );
          dealStatsData.pb = dealStats[0].userSpreads[0].pb
          dispatch(rfqSlice.updateDealStatsData(dealStatsData));
        } else {
          if(quoteParameters.defaultQuoteParameters[0].quote_type === "SWAP"){
            let regex = /[,\s]/g;
            let marketBid = dealStats[0].market_bid.replace(regex, "");
            let makorBid = dealStats[0].makor_bid.replace(regex, "");
            let marketOffer = dealStats[0].market_offer.replace(
              regex,
              ""
            );
            let makorOffer = dealStats[0].makor_offer.replace(
              regex,
              ""
            );

            let userSpredDetails = [
              {
                userSpreadBid: (+marketBid - +makorBid).toFixed(3),
                isBidUser: false,
                isUserChangeBid: "0",
                isUserChangeOffer: "0",
                isUserChangeSpreadOffer: "0",
                isUserChangeSpreadBid: "0",
                userSpreadOffer: (+makorOffer - +marketOffer).toFixed(3),
              },
            ];
            dispatch(
              rfqAggregatorSlice.setUsersSpreadhandler(userSpredDetails)
            );
            dispatch(rfqSlice.updateTableData(orderBook));
            dispatch(rfqSlice.updateDealStatsData(dealStats[0]));
            dispatch(
              rfqAggregatorSlice.updateQuoteParameterts(
                quoteParameters.defaultQuoteParameters[0]
              )
            );
          }else {
            let userSpredDetails = [
              {
                userSpreadBid: (
                  Number(dealStats[0].market_bid) -
                  Number(dealStats[0].makor_bid)
                ).toFixed(3),
                isBidUser: false,
                isUserChangeBid: "0",
                isUserChangeOffer: "0",
                isUserChangeSpreadOffer: "0",
                isUserChangeSpreadBid: "0",
                userSpreadOffer: (
                  Number(dealStats[0].makor_offer) -
                  Number(dealStats[0].market_offer)
                ).toFixed(3),
              },
            ];
            dispatch(
              rfqAggregatorSlice.setUsersSpreadhandler(userSpredDetails)
            );
            dispatch(rfqSlice.updateTableData(orderBook));
            dispatch(rfqSlice.updateDealStatsData(dealStats[0]));
            dispatch(
              rfqAggregatorSlice.updateQuoteParameterts(
                quoteParameters.defaultQuoteParameters[0]
              )
            );
          }
        }
      } else if (quoteParameters.userQuoteParameters.length > 0) {
        let quote = rfqQuote.map((q) => {
          return {
            ...q,
            quoted_vol1: q.quotevol_1,
            quoted_vol2: q.quotevol_2,
          };
        });
        let jtableDict = await createQuoteBookDict(rfqId);
        let refreshQuoteBook = await apiRefreshQuoteBook(
          quoteParameters.userQuoteParameters[0].quote_type,
          quote,
          jtableDict,
          quoteParameters.userQuoteParameters[0],
        );
        let updatedOrderBook = await buildOrderBook(refreshQuoteBook);

        let defaultDealStats = await apiGetDealStats(
          quoteParameters.userQuoteParameters[0].quote_type,
          jtableDict,
          quoteParameters.userQuoteParameters[0],
          dealStats[0],
          updatedOrderBook,
          false,
        );
        if (
          dealStats[0].userSpreads.length > 0 &&
          dealStats[0].userSpreads[0].userSpreadBid !== "NaN" &&
          dealStats[0].userSpreads[0].userSpreadOffer !== "NaN"
        ) {
          dispatch(
            rfqAggregatorSlice.setUsersSpreadhandler(dealStats[0].userSpreads),
          );
          let copyDealStatsData = { ...defaultDealStats };
          if(quoteParameters.defaultQuoteParameters[0].quote_type === "SWAP"){
            let regex = /[,\s]/g;
            let marketBid = copyDealStatsData.market_bid.replace(
              regex,
              ""
            );
            let marketOffer = copyDealStatsData.market_offer.replace(
              regex,
              ""
            );
            copyDealStatsData.makor_bid =
              (+marketBid - +dealStats[0].userSpreads[0].userSpreadBid).toFixed(3);
            copyDealStatsData.makor_offer =
              (+marketOffer +
              +dealStats[0].userSpreads[0].userSpreadOffer).toFixed(3);
            }else {
              copyDealStatsData.makor_bid =
              Number(copyDealStatsData.market_bid) -
              Number(dealStats[0].userSpreads[0].userSpreadBid).toFixed(
                3,
              );
            copyDealStatsData.makor_offer = (
              Number(copyDealStatsData.market_offer) +
              Number(dealStats[0].userSpreads[0].userSpreadOffer)
            ).toFixed(3);
            }
          copyDealStatsData = {...copyDealStatsData,pb:dealStats[0].userSpreads[0].pb}
          let userDealStats = await apiGetDealStats(
            quoteParameters.userQuoteParameters[0].quote_type,
            jtableDict,
            quoteParameters.userQuoteParameters[0],
            copyDealStatsData,
            updatedOrderBook,
            true,
          );
          userDealStats.pb = dealStats[0].userSpreads[0].pb
          dispatch(rfqSlice.updateDealStatsData(userDealStats));
          dispatch(
            rfqAggregatorSlice.updateQuoteParameterts(
              quoteParameters.userQuoteParameters[0],
            ),
          );
          dispatch(rfqSlice.updateTableData(updatedOrderBook));
        } else {
          let userDealStats = await apiGetDealStats(
            quoteParameters.userQuoteParameters[0].quote_type,
            jtableDict,
            quoteParameters.userQuoteParameters[0],
            dealStats[0],
            updatedOrderBook,
            false,
          );
          if(quoteParameters.defaultQuoteParameters[0].quote_type === "SWAP"){
            let regex = /[,\s]/g;
            let marketBid = userDealStats.market_bid.replace(regex, "");
            let makorBid = userDealStats.makor_bid.replace(regex, "");
            let marketOffer = userDealStats.market_offer.replace(
              regex,
              ""
            );
            let makorOffer = userDealStats.makor_offer.replace(
              regex,
              ""
            );

            let userSpredDetails = [
              {
                userSpreadBid: (+marketBid - +makorBid).toFixed(3),
                isBidUser: false,
                isUserChangeBid: "0",
                isUserChangeOffer: "0",
                isUserChangeSpreadOffer: "0",
                isUserChangeSpreadBid: "0",
                userSpreadOffer: (+makorOffer - +marketOffer).toFixed(3),
              },
            ];
            dispatch(
              rfqAggregatorSlice.setUsersSpreadhandler(userSpredDetails)
            );
            dispatch(rfqSlice.updateTableData(updatedOrderBook));
            dispatch(rfqSlice.updateDealStatsData(userDealStats));
            dispatch(
              rfqAggregatorSlice.updateQuoteParameterts(
                quoteParameters.userQuoteParameters[0]
              )
            );
          }else {
            let userSpredDetails = [
              {
                userSpreadBid: (
                  Number(userDealStats.market_bid) -
                  Number(userDealStats.makor_bid)
                ).toFixed(3),
                isBidUser: false,
                isUserChangeBid: "0",
                isUserChangeOffer: "0",
                isUserChangeSpreadOffer: "0",
                isUserChangeSpreadBid: "0",
                userSpreadOffer: (
                  Number(userDealStats.makor_offer) -
                  Number(userDealStats.market_offer)
                ).toFixed(3),
              },
            ];
            dispatch(
              rfqAggregatorSlice.setUsersSpreadhandler(userSpredDetails)
            );
            dispatch(rfqSlice.updateTableData(updatedOrderBook));
            dispatch(rfqSlice.updateDealStatsData(userDealStats));
            dispatch(
              rfqAggregatorSlice.updateQuoteParameterts(
                quoteParameters.userQuoteParameters[0]
              )
            );
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const create_quote_book_dict = async (selectedRfq) => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}` +
          "rfq" +
          `/rfq_details/${selectedRfq}`,
        {
          headers: { Authorization: token },
        },
      );

      const fieldsList = [
        "CCY_PAIR",
        "DEAL_TYPE",
        "LEG_TYPE",
        "REQUEST_FOR",
        "OPTION_TYPE",
        "EXPIRY_TEXT",
        "STRIKE",
        "BARRIER",
        "BARRIER2",
        "CALL_PUT",
        "NOTIONAL",
        "BUY_SELL",
        "CCYPAIR_GROUP",
        "PB_ACCOUNT",
        "RFQ_FORMAT",
        "REF_TYPE",
        "INCLUDE_REF",
        "CUT",
        "FIXING_REF",
        "NOTIONAL_CCY",
        "SPOT_REF",
        "FWD_REF",
        "BBG_MID_VOL",
        "EXPIRY",
        "DELIVERY",
        "SPOT_DATE",
        "DELTA",
        "VEGA",
        "HEDGE_AMOUNT",
        "PRICE_PCT_NOTIONAL",
        "PREMIUM_VAL",
        "PREMIUM_TYPE",
        "PREMIUM_CCY",
        "CLIENT_PRICE",
        "MARKET_PRICE",
        "CLIENT_PREMIUM",
        "MARKET_PREMIUM",
        "NET_CLIENT_PRICE",
        "NET_MARKET_PRICE",
        "HEDGE_TYPE",
        "HEDGE_VAL",
        "HEDGE_RATE",
        "HEDGE_DELTA",
      ];

      let array = [];

      fieldsList.map((m) => {
        let obj = {};
        for (let index = 0; index < response.data.rfq.length; index++) {
          let key = "";
          if (m === "CCY_PAIR") {
            key = "ccy_pair";
          } else if (m === "DEAL_TYPE") {
            key = "deal_type";
          } else if (m === "LEG_TYPE") {
            key = "leg_type_text";
          } else if (m === "REQUEST_FOR") {
            key = "";
          } else if (m === "OPTION_TYPE") {
            key = "option_type_text";
          } else if (m === "EXPIRY_TEXT") {
            key = "expiry_date";
          } else if (m === "STRIKE") {
            key = "strike";
          } else if (m === "BARRIER") {
            key = "barrier_1";
          } else if (m === "BARRIER2") {
            key = "barrier_2";
          } else if (m === "CALL_PUT") {
            key = "call_put";
          } else if (m === "NOTIONAL") {
            key = "notional";
          } else if (m === "BUY_SELL") {
            key = "buy_sell";
          } else if (m === "CCYPAIR_GROUP") {
            key = "";
          } else if (m === "PB_ACCOUNT") {
            key = "pb_account";
          } else if (m === "RFQ_FORMAT") {
            key = "rfq_format";
          } else if (m === "REF_TYPE") {
            key = "ref_type";
          } else if (m === "INCLUDE_REF") {
            key = "include_ref";
          } else if (m === "FIXING_REF") {
            key = "fixing_ref";
          } else if (m === "CUT") {
            key = "cut";
          } else if (m === "NOTIONAL_CCY") {
            key = "notional_ccy";
          } else if (m === "SPOT_REF") {
            key = "spot_ref";
          } else if (m === "FWD_REF") {
            key = "forward_ref";
          } else if (m === "BBG_MID_VOL") {
            key = "bbg_mid_vol";
          } else if (m === "EXPIRY") {
            key = "expiry_date";
          } else if (m === "DELIVERY") {
            key = "delivery_date";
          } else if (m === "SPOT_DATE") {
            key = "spot_date";
          } else if (m === "DELTA") {
            key = "delta";
          } else if (m === "VEGA") {
            key = "vega";
          } else if (m === "HEDGE_AMOUNT") {
            key = "hedge_amount";
          } else if (m === "PRICE_PCT_NOTIONAL") {
            key = "price_pct_notional";
          } else if (m === "PREMIUM_VAL") {
            key = "premium_val";
          } else if (m === "PREMIUM_TYPE") {
            key = "premium_type";
          } else if (m === "PREMIUM_CCY") {
            key = "premium_ccy";
          } else if (m === "CLIENT_PRICE") {
            key = "client_price";
          } else if (m === "MARKET_PRICE") {
            key = "market_price";
          } else if (m === "CLIENT_PREMIUM") {
            key = "client_premium";
          } else if (m === "MARKET_PREMIUM") {
            key = "market_premium";
          } else if (m === "NET_CLIENT_PRICE") {
            key = "net_client_price";
          } else if (m === "NET_MARKET_PRICE") {
            key = "net_market_price";
          } else if (m === "HEDGE_TYPE") {
            key = "hedge_type";
          } else if (m === "HEDGE_VAL") {
            key = "hedge_val";
          } else if (m === "HEDGE_RATE") {
            key = "hedge_rate";
          } else if (m === "HEDGE_DELTA") {
            key = "hedge_delta";
          }

          obj = {
            ...obj,
            [0]: m,
            [index + 1]: key !== "" ? [response.data.rfq[index][`${key}`]] : "",
          };
        }
        array.push(obj);
      });

      return array;
    } catch (error) {
      console.log(error);
    }
  };
  const getRecommendation = async (selectedRfq) => {
    let rfqTableData = await create_quote_book_dict(selectedRfq.rfq_id);
    let rfq_object = {};
    try {
      rfq_object = {
        ...rfq_object,
        cross: selectedRfq.ccy_pair,
        created_on: selectedRfq.created_on,
        rfq_id: selectedRfq.rfq_id,
        rfq_table_data: rfqTableData,
      };
      const response = await axios.post(
        "https://fx-letool.letool-engine.makor-group.com/api/get_recommendations",
        rfq_object,
      );

      dispatch(
        rfqSlice.rfqRecommendationHandler({
          recommendationDetails: response.data,
          rfqId: selectedRfq.rfq_id,
        }),
      );
      dispatch(rfqSlice.updateLpPerdiction(response.data));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    dispatch(rfqSlice.getCurrencyPairs());
    dispatch(rfqSlice.getSingleCurrencies());
    dispatch(rfqSlice.getPbAccounts());
    dispatch(rfqSlice.getDealType());
    dispatch(rfqSlice.getClients());
    dispatch(rfqAggregatorSlice.getUserMarketMaker());
    dispatch(rfqAggregatorSlice.getMarketMaker());
    dispatch(rfqSlice.getCompleteClientList());
  }, []);
  useEffect(() => {
    if (ws !== null) {
      sendEvent({
        type: "get_rfq_trade",
        data: {},
        metaData: {},
        token: token.replace("Bearer ", ""),
      });
    } else {
      connectWS(token.replace("Bearer ", ""), dispatch, rfqSlice);
      sendEvent({
        type: "get_rfq_trade",
        data: {},
        metaData: {},
        token: token.replace("Bearer ", ""),
      });
    }
  }, []);

  const getTabs = async () => {
  
    let res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}${
        END_POINT.GET_TABS
      }/${sessionStorage.getItem("id")}`,
      { headers: { Authorization: token } },
    );
    dispatch(rfqSlice.updatRfqInstanceList(res.data));
    const filter = res.data.filter((inst) => inst.tabIsChosen === true);
    

    if (
      filter[0].rfq_description === "NEW RFQ" ||
      sessionStorage.getItem("status") === "newRfq" ||
      sessionStorage.getItem("status") === "copy"
    ) {
       
      // sessionStorage.setItem("rfqDealType","VANNILA");
      dispatch(rfqSlice.selectNewRfqTab());
    } else if (
      filter[0].rfq_description !== "NEW RFQ" &&
      sessionStorage.getItem("status") !== "newRfq" &&
      sessionStorage.getItem("status") !== "copy"
    ) {
      sessionStorage.setItem("status", "open");
      sessionStorage.setItem("rfqMode", "rfq view");
      sessionStorage.setItem("rfqStatus", "Live");
      // sessionStorage.setItem("rfqDealType","VANNILA");
      
      const result = await dispatch(rfqSlice.getRfqById(filter[0].id));
      handleRfqModalsDetails(filter[0].id);
      const rfqObj = result.rfq[0];
      getRecommendation(rfqObj);
    }
    // }
  };

  const loadTabsInfo = async () => {
    const filter = rfqInstanceList.filter((inst) => inst.tabIsChosen === true);
    if (filter[0].rfq_description !== "NEW RFQ") {
      const result = await dispatch(rfqSlice.getRfqById(filter[0].id));
      handleRfqModalsDetails(filter[0].id);
      const rfqObj = result.rfq[0];
      getRecommendation(rfqObj);
    }
  };

  useEffect(() => {
    if (rfqInstanceList.length === 0) {
      getTabs();
    } else {
      if (
        sessionStorage.getItem("status") !== "newRfq" &&
        sessionStorage.getItem("status") === "" &&
        sessionStorage.getItem("status") !== "copy"
      ) {
        loadTabsInfo();
      } else {
        const tabs = rfqInstanceList.map((tab) => {
          if (tab.rfq_description === "NEW RFQ") {
            return { ...tab, tabIsChosen: true };
          } else {
            return { ...tab, tabIsChosen: false };
          }
        });
        dispatch(rfqSlice.updatRfqInstanceList(tabs));
      }
    }

    if (rfqId) {
      if (rfq_object.rfq_id !== undefined) {
        dispatch(rfqSlice.getCheckedRfq(rfq_object.rfq_id));
      }
    }
  }, []);

  return (
    <div style={{ maxWidth: "95vw", maxHeight: "100vh" }}>
      <RfqWorkspaceHeader
        buttonBackgrounds={buttonBackgrounds}
        setButtonBackgrounds={setButtonBackgrounds}
        rfq_object={rfq_object}
        setrfq_object={setrfq_object}
      />
      {isLoadingCrossList === true ||
      isLoadingPbAccounts === true ||
      isLoadingdealType === true ||
      isLoadingClients === true ||
      isLoadingUserMarketMakers === true ||
      isLoadingClientList === true ||
      isLoadingMarketMakers ? (
        <CircularProgress variant={"indeterminate"} size={100} />
      ) : rfqMode !== "rfq aggregator view" ? (
        <RfqWorkspaceTable
          rfq_object={rfq_object}
          setrfq_object={setrfq_object}
          setButtonBackgrounds={setButtonBackgrounds}
        />
      ) : (
        <RfqQuoteBook rfq_object={rfq_object} setrfq_object={setrfq_object} />
      )}
      <Grid className={classes.status}>
        <Grid>
          <CircleIcon color={socketStatus} />
        </Grid>
        <Grid className={classes.statusPosition}>
          <Typography
            className={
              socketStatus === "success"
                ? classes.success
                : socketStatus === "connecting"
                ? classes.connecting
                : socketStatus === "error"
                ? classes.fails
                : null
            }
          >
            {socketStatus === "success"
              ? "Server Is Connected"
              : socketStatus === "connecting"
              ? "Trying to connect to Websocket"
              : socketStatus === "error"
              ? "Connection Lost"
              : null}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};
export const useStyles = makeStyles(() => ({
  status: {
    position: "fixed",
    bottom: "9px",
    width: "100%",
    display: "flex",
  },
  success: {
    color: "green",
  },
  fails: {
    color: "red",
  },
  connecting: {
    color: "#FF8C00",
  },
  statusPosition: {
    marginLeft: "5px",
  },
}));
