import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "ag-grid-enterprise";
import { Grid } from "@material-ui/core";
import { cellEditorSelector } from "../utils/cellEditorSelector";
import { cellEditorParamsSelector } from "../utils/cellEditorParamsSelector";
import { editableSelector } from "../utils/editableSelector";
import { agFormatter } from "../utils/valueFormatter";
import { ParticipantsDialog } from "../components/ParticipantsDialog";
import { onCellEditReq } from "../utils/onEditReq.js";
import "../style/style.css";
import { cellRendererSelector } from "../utils/cellRendererSelector.js";

export const TradesTable = ({
  mappedTableData,
  setMarkitFavData,
  setMappedTableData,
  isSubmitting,
}) => {
  const gridRef = useRef();
  const [isParticipantDialogOpen, setIsParticipantDialogOpen] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [columnDefs, setColumnDefs] = useState([]);
  const gridStyle = useMemo(() => ({ height: "100", width: "1400px" }), []);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };
  const getRowId = useCallback((params) => {
    return params.data.id;
  }, []);
  const closeParticipantDialog = () => {
    setIsParticipantDialogOpen(false);
  };
  const onCellClicked = useCallback((params) => {
    if (
      (params.data.id === "buyer" || params.data.id === "seller") &&
      params.column.colId === "columnHeader"
    ) {
      setIsParticipantDialogOpen(true);
    }
  }, []);
  const onCellEditRequest = useCallback(
    (e) => {
      onCellEditReq(e, setMappedTableData);
    },
    [setMappedTableData],
  );

  useEffect(() => {
    if (mappedTableData !== null) {
      setColumnDefs(
        Object.keys(mappedTableData[0])
          .filter((key) => key !== "id" && key !== "markitFavData")
          .map((key) => {
            if (key === "columnHeader") {
              return {
                field: key,
                headerName: "",
                lockPosition: true,
                suppressMovable: true,
                suppressMenu: true,
              };
            } else {
              return {
                field: key,
                lockPosition: true,
                suppressMovable: true,
                suppressMenu: true,
                cellEditorSelector: cellEditorSelector, // render the select option
                cellEditorParams: cellEditorParamsSelector,
                cellEditorPopup: true,
                editable: (params) => editableSelector(params, mappedTableData),
                valueFormatter: agFormatter,
                cellRendererSelector: cellRendererSelector,
              };
            }
          })
          .sort((a, b) => a.field.localeCompare(b.field)),
      );
    }
  }, [mappedTableData]);

  useEffect(() => {
    if (isSubmitting) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }
  }, [isSubmitting, gridApi]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{ marginTop: "50px" }}
    >
      {mappedTableData !== null ? (
        <Grid item className="ag-theme-balham-dark e_report" style={gridStyle}>
          <AgGridReact
            ref={gridRef}
            rowData={mappedTableData}
            columnDefs={columnDefs}
            getRowId={getRowId}
            domLayout={"autoHeight"}
            onGridReady={onGridReady}
            animateRows={true}
            readOnlyEdit={true}
            singleClickEdit={true}
            onCellClicked={onCellClicked}
            onCellEditRequest={onCellEditRequest}
            suppressHorizontalScroll={true}
            // onCellKeyDown={onCellKeyDown}
            // enableCellEditingOnBackspace={true}
            // getRowClass={getRowClass}
          ></AgGridReact>
        </Grid>
      ) : (
        <Grid item></Grid>
      )}

      <ParticipantsDialog
        open={isParticipantDialogOpen}
        handleClose={closeParticipantDialog}
        setMarkitFavData={setMarkitFavData}
        mappedTableData={mappedTableData}
      />
    </Grid>
  );
};
