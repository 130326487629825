import axios from "axios";
import { END_POINT } from "../utils/index";
import { store } from "../index";
import * as ratesSlice from "../store/rates/ratesSlice";

const getExchangeRates = async () => {
  const token = sessionStorage.getItem("token");
  let ccyPairWithExchange = {};
  try {
    let updateExchange = await axios.get(
      `${process.env.REACT_APP_BASE_URL}` +
        END_POINT.RATES +
        "/ccyExchangeRates",
      { headers: { Authorization: token } }
    );
    console.log("updateExchange: ", updateExchange);
    if (updateExchange.status === 200) {
      if (updateExchange.data) {
        let ratesCCYS = updateExchange.data.ratesCCYs
          .map((ccy) => ccy.Currency + "USD")
          .filter((ccy) => ccy !== "USDUSD");
        let exchangeRatesCCYS = updateExchange.data.exchangeRatesCCYs.map(
          (ccy) => ccy.ccy
        );
        console.log("ratesCCYS: ", ratesCCYS);
        let ccyNotInExRatesCCY = ratesCCYS.filter(
          (ccy) => !exchangeRatesCCYS.includes(ccy)
        );
        if (ccyNotInExRatesCCY.length > 0) {
          try {
            let insertNewCCY = await axios.post(
              `${process.env.REACT_APP_BASE_URL}` +
                END_POINT.RATES +
                "/updateExchangeCCYPairs",
              ccyNotInExRatesCCY,
              { headers: { Authorization: token } }
            );
          } catch (error) {
            // ! ADD ACTION SNACK BAR
          }
        }
        let allCCYExchange = await Promise.all(
          ratesCCYS.map(async (ccy) => {
            let exchange = await axios.get(
              `${process.env.REACT_APP_BLOOMBERG}spot/${ccy}`
            );
            return exchange.data.price;
          })
        );
        ratesCCYS.forEach((ccy, index) => {
          ccyPairWithExchange = {
            ...ccyPairWithExchange,
            [ccy]: +allCCYExchange[index],
          };
        });
        let res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}${END_POINT.RATES}/updateExchangeRates`,
          ccyPairWithExchange,
          { headers: { Authorization: token } }
        );
        store.dispatch(ratesSlice.setExchangeRates(ccyPairWithExchange));
      }
    }
  } catch (error) {
    // dispatch(
    //   actionSnackBar.setSnackBar("error", "Problem update exchange rates", 3000)
    // );
  }
};

export { getExchangeRates };
