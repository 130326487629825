import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  IconButton,
  Typography,
  TextField,
} from "@material-ui/core";
import { ReactComponent as Launch } from "../../../../assets/launch.svg";
import { useStyles } from "../style/style";
import {
  getClientTraderStaticData,
  getConfirmationData,
  getEmailBody,
  sendConformationDerivs,
} from "../utils/apiCalls";
import SuccessFailAnimation from "../../../fi/reusableComponents/SuccessFailAnimation";
import { validateEmailsStructure } from "../utils/validation";
import { useDispatch } from "react-redux";
import * as actionSnackBar from "../../../../store/snackbar/action";

export const ComposeWindow = ({
  tabChosen,
  setMarkdown,
  markdown,
  order,
  isTabConfirmed,
  setIsTabConfirmed,
  setTabsDataByColumn,
  attachmentPath,
  markdownContentTabs,
  tabsDataByColumn,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [toList, setToList] = useState(null);
  const [ccList, setCcList] = useState(null);
  const [sendStatus, setSendStatus] = useState(null);
  const [subject, setSubject] = useState(null);

  const sendConfo = async () => {
    try {
      const { traderId, clientId } = tabChosen;
      const testResult = validateEmailsStructure({ toList, ccList });
      if (testResult.length === 0) {
        setSendStatus("loading");
        const res = await sendConformationDerivs({
          markdown,
          toList: toList
            .split(";")
            .map((email) => email.trim())
            .filter((email) => email !== ""),
          ccList: ccList
            .split(";")
            .map((email) => email.trim())
            .filter((email) => email !== ""),
          traderId,
          clientId,
          orderId: order.id,
          subject,
          companyId: tabChosen.companyId,
          attachmentPath,
        });
        if (res.data.status) {
          setSendStatus("success");
        } else {
          setSendStatus("fail");
        }
      } else {
        dispatch(actionSnackBar.setSnackBar("error", testResult[0], 4000));
      }
    } catch (error) {
      setSendStatus("fail");

      console.log(error);
    }
  };
  const handleEmailListChange = (e, type) => {
    (type === "to" ? setToList : setCcList)(e.target.value);
    setTabsDataByColumn((pre) => {
      const index = pre.findIndex(
        (obj) =>
          Number(obj.clientId) === Number(tabChosen.clientId) &&
          Number(obj.traderId) === Number(tabChosen.traderId),
      );
      pre.splice(index, 1, {
        ...pre[index],
        [`${type}`]: e.target.value,
        [`${type === "to" ? "cc" : "to"}`]: type === "to" ? ccList : toList,
        subject,
      });
      return pre;
    });
  };
  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
    setTabsDataByColumn((pre) => {
      const index = pre.findIndex(
        (obj) =>
          Number(obj.clientId) === Number(tabChosen.clientId) &&
          Number(obj.traderId) === Number(tabChosen.traderId),
      );
      pre.splice(index, 1, {
        ...pre[index],
        cc: ccList,
        to: toList,
        subject: e.target.value,
      });
      return pre;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (tabChosen !== null) {
        try {
          const { traderId, clientId } = tabChosen;
          const res = await getConfirmationData(order.id, clientId, traderId);
          if (res.length !== 0) {
            //confirmed tab
            setIsTabConfirmed(true);
            setSendStatus("success");
            setToList(res[0].toList.map((item) => item).join("; "));
            setCcList(res[0].ccList.map((item) => item).join("; "));
            setMarkdown(res[0].markdown);
            setSubject(res[0].subject);
          } else {
            // load initial tab data
            setIsTabConfirmed(false);
            setSendStatus(null);

            const tabObj = tabsDataByColumn.filter(
              (obj) =>
                Number(obj.traderId) === Number(tabChosen.traderId) &&
                Number(obj.clientId) === Number(tabChosen.clientId),
            );
            if (
              tabChosen.cc === undefined &&
              markdownContentTabs !== null &&
              tabObj[0].cc === undefined
            ) {
              getClientTraderStaticData(
                traderId,
                clientId,
                setToList,
                setCcList,
              );
              const markdownObj = markdownContentTabs.filter(
                (obj) =>
                  Number(obj.traderId) === Number(tabChosen.traderId) &&
                  Number(obj.clientId) === Number(tabChosen.clientId),
              );
              if (
                markdownObj.length > 0 &&
                markdownObj[0].subject !== undefined
              ) {
                setSubject(markdownObj[0].subject);
              } else {
                setSubject(null);
              }
            } else {
              //load edited tab data
              setToList(
                tabChosen.to === undefined ? tabObj[0].to : tabChosen.to,
              );
              setCcList(
                tabChosen.cc === undefined ? tabObj[0].cc : tabChosen.cc,
              );
              setSubject(
                tabChosen.subject === undefined
                  ? tabObj[0].subject
                  : tabChosen.subject,
              );
            }
          }
        } catch (error) {
          console.error("Error fetching confirmation data:", error);
        }
      }
    };

    fetchData();
  }, [tabChosen, markdownContentTabs]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item style={{ marginLeft: "15px", marginTop: "5px" }}>
        <Button
          variant="contained"
          className={classes.sendEmailBtn}
          onClick={sendConfo}
          disabled={
            toList === null ||
            toList === "" ||
            markdown === null ||
            sendStatus === "success" ||
            sendStatus === "loading"
          }
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              style={{ marginTop: sendStatus !== null ? "20px" : null }}
            >
              <IconButton color="primary" aria-label="fav-order">
                {sendStatus !== null ? (
                  <SuccessFailAnimation
                    padding={20}
                    status={sendStatus}
                    loaderClass={"loader"}
                    size={"45px"}
                  />
                ) : (
                  <Launch fontSize="large" style={{ marginBottom: "10px" }} />
                )}
              </IconButton>
            </Grid>
            <Grid item>
              <Typography
                style={{ marginTop: sendStatus !== null ? "15px" : null }}
              >
                {sendStatus === "success"
                  ? "Sent"
                  : sendStatus === "fail"
                  ? "Failed"
                  : sendStatus === "loading"
                  ? "Sending"
                  : "Send"}
              </Typography>
            </Grid>
          </Grid>
        </Button>
      </Grid>
      <Grid
        item
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        md={11}
        spacing={1}
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item>
            <Button variant="contained" className={classes.toCcBtn} disabled>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Typography>TO</Typography>
              </Grid>
            </Button>
          </Grid>
          <Grid item md={11}>
            <TextField
              style={{ margin: 8 }}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.composeInputs}
              value={toList}
              onChange={(e) => handleEmailListChange(e, "to")}
              disabled={isTabConfirmed}
            />
          </Grid>
        </Grid>

        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item>
            <Button variant="contained" className={classes.toCcBtn} disabled>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Typography>CC</Typography>
              </Grid>
            </Button>
          </Grid>
          <Grid item md={11}>
            <TextField
              style={{ margin: 8 }}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.composeInputs}
              value={ccList}
              onChange={(e) => handleEmailListChange(e, "cc")}
              disabled={isTabConfirmed}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Button variant="contained" className={classes.subjectBtn} disabled>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography>Subject</Typography>
            </Grid>
          </Button>
          <Grid item md={11}>
            <TextField
              style={{ margin: 8 }}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.composeInputs}
              value={subject}
              onChange={(e) => handleSubjectChange(e, "cc")}
              disabled={isTabConfirmed}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
