import axios from "axios";
import { END_POINT } from "../../../utils";
import * as actionSnackBar from "../../../store/snackbar/action";

export const rewriteClientString = async (stringInputRef, dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_PYTON}${END_POINT.PARSE_CLIENT_REQUEST_REWRITE}`,
      { client_request_text: stringInputRef.current.value },
    );
    if (res.data.error !== undefined) {
      dispatch(actionSnackBar.setSnackBar("error", res.data.error, 3000));
    } else {
      stringInputRef.current.value = res.data;
      stringInputRef.current.focus();
    }
  } catch (error) {
    stringInputRef.current.focus();
    dispatch(
      actionSnackBar.setSnackBar("error", error.response.statusText, 3000),
    );
  }
};
