import { Checkbox, FormControlLabel } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useStyles } from "../../../Styles/fiStyles";
import { useDispatch, useSelector } from "react-redux";
import * as fiActions from "../../../store/fi/fiSlice";

export const TradeCheckbox = ({ field, type }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState(false);
  const tradeToEdit = useSelector(
    (state) => state.fiSlice?.selectedTradeToEdit,
  );
  const handleClick = (e) => {
    setValue((pre) => {
      return !pre;
    });
    dispatch(
      fiActions.editNewTradeForm({
        value: !value,
        key: field,
      }),
    );
  };

  useEffect(() => {
    if (type !== "new") {
      setValue(tradeToEdit[`${field}`]);
      const obj = {
        value: tradeToEdit[`${field}`] ? true : false,
        key: field,
      };
      dispatch(fiActions.editNewTradeForm(obj));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControlLabel
      value={true} // This is the value for the form submission, not the checked state
      control={
        <Checkbox
          className={classes.checkbox2}
          checked={value} // Set the checkbox state
          onClick={(e) => {
            handleClick();
          }}
        />
      }
      label="Structured"
      labelPlacement="top"
      classes={{ label: classes.FormControlLabel }} // Apply custom label styles
      className={classes.FormControl} // Apply the root class for hover effect
    />
  );
};
