export const buildInitialDataTable = (
  quote,
  refreshQuoteBook,
  initialData,
  rfqParamaters,
) => {
  let regex = /[.\s]/g;

  let completedQuoteBook = [];
  const rfqId = sessionStorage.getItem("rfqId");
  for (let i = 0; i < refreshQuoteBook.length; i++) {
    for (let g = 0; g < quote.length; g++) {
      if (refreshQuoteBook[i].market_maker === quote[g].market_maker) {
        let obj = {
          ...refreshQuoteBook[i],
          quoted_vol1: refreshQuoteBook[i].quoted_vol1,
          quoted_vol2: refreshQuoteBook[i].quoted_vol2,
          trader_id: quote[g].trader_id,
          trader_first_name: quote[g].trader_first_name,
          rfq_id: rfqId,
        };
        completedQuoteBook.push(obj);
      }
    }
  }
  quote = [...completedQuoteBook];

  if (Number(rfqId) === Number(sessionStorage.getItem("rfqId"))) {
    for (let i = 0; i < initialData.length; i++) {
      for (let g = 0; g < quote.length; g++) {
        if (initialData[i][0] === quote[g].market_maker) {
          if (
            rfqParamaters.quote_type === "SINGLE_LEG_VOL" ||
            rfqParamaters.quote_type === "PCT_PAYOUT" ||
            rfqParamaters.quote_type === "PCT_NOTIONAL" ||
            rfqParamaters.quote_type === "STRANGLE_STRADDLE" ||
            rfqParamaters.quote_type === "SWAP" 
          ) {
            initialData[i][1] = quote[g].quote_text
            initialData[i][2] = quote[g].bid
            initialData[i][3] = quote[g].offer
            initialData[i][4] = quote[g].spread;
            initialData[i][5] = quote[g].trader_first_name;
          } else if (rfqParamaters.quote_type === "DOUBLE_LEG_VOL") {
            initialData[i][1] = quote[g].quote_text
            initialData[i][2] = quote[g].quoted_vol1;
            initialData[i][3] = quote[g].quoted_vol2;
            initialData[i][4] = quote[g].bid
            initialData[i][5] = quote[g].offer
            initialData[i][6] = quote[g].spread;
            initialData[i][7] = quote[g].trader_first_name;
          }
        }
      }
    }
  }
  return initialData;
};
export const buildInitialDataInBlotterTable = (quote, quoteBook) => {
  let completedQuoteBook = [];
  const rfqId = sessionStorage.getItem("rfqId");

  for (let i = 0; i < quoteBook.length; i++) {
    for (let g = 0; g < quote.length; g++) {
      if (quoteBook[i].market_maker === quote[g].market_maker) {
        let obj = {
          ...quoteBook[i],
          quotevol_1: quoteBook[i].quoted_vol1,
          quotevol_2: quoteBook[i].quoted_vol2,
          trader_id: quote[g].trader_id,
          trader_first_name: quote[g].trader_first_name,
          rfq_id: rfqId,
        };
        completedQuoteBook.push(obj);
      }
    }
  }
  quote = [...completedQuoteBook];
  return quote;
};
