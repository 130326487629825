import RateTableRowView from "./RatesTableRow.view";

function RatesTableRow(props) {
  const { rate, linkRates } = props;

  const numberWithCommas = (number) => {
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <RateTableRowView
      rate={rate}
      numberWithCommas={numberWithCommas}
      linkRates={linkRates}
    />
  );
}

export default RatesTableRow;
