import axios from "axios";
import { END_POINT } from "../../../../utils";
import { getStaticData } from "../../bookingDialog/BookingDialog";
import { tablesConvertor } from "./functions";
import * as actionSnackBar from "../../../../store/snackbar/action";

export const getBookedOrderData = async (
  id,
  setTabsDataByColumn,
  setMarkdown,
  setMarkdownContentTabs,
  dispatch,
  order,
  setBookedTablesData,
) => {
  try {
    const token = sessionStorage.getItem("token");
    const makorStaticData = await getStaticData();
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}${END_POINT.DERIVS_GET_BOOKED_ORDER_DATA}/${id}`,
      { headers: { Authorization: token } },
    );
    setBookedTablesData(res.data.payload.tablesData);
    const { bookingTable, underlyingTable, strategyTable } =
      res.data.payload.tablesData;

    const { clientObj, clearedByObj, traderObj } = bookingTable.reduce(
      (acc, row) => {
        switch (row.id) {
          case "client":
            acc.clientObj = row;
            break;
          case "cleared_by":
            acc.clearedByObj = row;
            break;
          case "trader":
            acc.traderObj = row;
            break;
          default:
            // Handle other cases if needed
            break;
        }
        return acc;
      },
      { clientObj: null, clearedByObj: null, traderObj: null },
    );

    const execKeys = Object.keys(clientObj).filter((key) =>
      key.startsWith("exec"),
    );

    const bookingTableByExec = tablesConvertor(
      execKeys,
      bookingTable,
      res.data.payload.parsedBookingText,
    );

    const tabsDataByColumn = execKeys.map((e, i) => {
      const client = makorStaticData.data.clients.filter(
        (c) =>
          c.name.toLowerCase() === clientObj[e]?.toLowerCase() &&
          c.company.name.toLowerCase() === clearedByObj[e]?.toLowerCase(),
      );
      const trader =
        client.length > 0
          ? client[0]?.client_traders.filter(
              (c) => c.name.toLowerCase() === traderObj[e]?.toLowerCase(),
            )
          : [];

      bookingTableByExec[i].clientId = client.length > 0 ? client[0].id : null;
      bookingTableByExec[i].traderId = trader.length > 0 ? trader[0].id : null;
      return {
        companyId: client.length > 0 ? client[0].company.id : "",
        companyName: client.length > 0 ? client[0].company.name : "",
        clientId: client.length > 0 ? client[0].id : null,
        clientName: client.length > 0 ? client[0].name : "Client N/A",
        traderId: trader.length > 0 ? trader[0].id : null,
        traderName: trader.length > 0 ? trader[0].name : "Trader N/A",
        bookingData: res.data.payload,
      };
    });
    getEmailBody(
      bookingTableByExec,
      underlyingTable[0],
      strategyTable[0],
      res.data.payload.parsedBookingText,
      setMarkdown,
      setMarkdownContentTabs,
      dispatch,
      order,
    );
    setTabsDataByColumn(tabsDataByColumn);
  } catch (error) {
    console.log(error);
  }
};

export const getClientTraderStaticData = async (
  traderId,
  clientId,
  setToList,
  setCcList,
) => {
  try {
    const token = sessionStorage.getItem("token");
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}${END_POINT.DERIVS_GET_CLIENT_TRADER_STATIC}/${clientId}/${traderId}`,
      { headers: { Authorization: token } },
    );
    if (res.data.status) {
      const { emailList, clearanceAcc } = res.data.payload;
      const toList = emailList.filter(
        (email) => email.type.toLowerCase() === "to",
      );
      const ccList = emailList.filter(
        (email) => email.type.toLowerCase() === "cc",
      );
      toList.length > 0
        ? setToList(toList.map((item) => item.email).join("; "))
        : setToList("");
      ccList.length > 0
        ? setCcList(ccList.map((item) => item.email).join("; "))
        : setCcList("");
    }
  } catch (error) {
    console.log(error);
  }
};

export const sendConformationDerivs = async (body) => {
  try {
    const token = sessionStorage.getItem("token");

    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}${END_POINT.DERIVS_EMAIL}`,
      body,
      { headers: { Authorization: token } },
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getEmailBody = async (
  bookingTableByExec,
  underlyingTable,
  strategyTable,
  parsedBookingText,
  setMarkdown,
  setMarkdownContentTabs,
  dispatch,
  order,
) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_PYTON}` + `create_derivs_email_confo`,
      {
        booking_table: bookingTableByExec,
        underlying: { ...underlyingTable, name: order.name },
        strategy: strategyTable,
        parsed_booking_text: parsedBookingText,
        booking_req_text: parsedBookingText.booking_req_text,
        client_request_text: order.strategy,
      },
    );
    setMarkdownContentTabs(res.data);
    setMarkdown(res.data[0].email_body);
  } catch (error) {
    setMarkdownContentTabs([]);
    setMarkdown("Email body is missing");
    console.log(error);
    dispatch(
      actionSnackBar.setSnackBar(
        "error",
        "Oops something went wrong, couldn't get email body",
        3000,
      ),
    );
  }
};

export const getConfirmationData = async (orderId, clientId, traderId) => {
  try {
    const token = sessionStorage.getItem("token");

    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}${END_POINT.DERIVS_EMAIL}/${orderId}/${clientId}/${traderId}`,

      { headers: { Authorization: token } },
    );
    if (res.data.length > 0) {
      const { emailBody, ccList, toList, subject } = res.data[0];
      return [
        {
          toList: JSON.parse(toList),
          ccList: JSON.parse(ccList),
          markdown: emailBody,
          subject,
        },
      ];
    } else {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const downloadAttachment = async (attachmentPath, setIsDownloading) => {
  try {
    const token = sessionStorage.getItem("token");

    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}${END_POINT.DERIVS_EMAIL}/download_attachment/${attachmentPath}`,

      { headers: { Authorization: token }, responseType: "blob" },
    );
    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", attachmentPath); // Set the file name for the download
    document.body.appendChild(link);
    link.click();

    // Clean up
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);
    setIsDownloading(false);
  } catch (error) {
    console.log(error);
  }
};
