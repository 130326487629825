import {
  Checkbox,
  makeStyles,
  TableCell,
  TableRow,
  withStyles,
} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  freeTextPickerMerrill: {
    background: "red",
    "& .MuiOutlinedInput-input": {
      width: "100px !important",
    },
  },
  dialogPaperPnlSplit: {
    minWidth: "600px",
    height: "550px",
  },
  pnlAttPercentage: {
    "& .MuiSelect-select:not([multiple]) option": {
      backgroundColor: "#2D2D2D",
    },
    "& .MuiOutlinedInput-input": {
      color: "#FFFFFF",
      borderRadius: "4px",
      maxHeight: "20px",
      maxWidth: "100px",
      textAlign: "left",
    },
    "& .MuiInputLabel-root": {
      color: "#868DA2",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#686B76",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#FFFFFF",
    },
    "&:hover .MuiInputLabel-root": {
      color: "#2692FF",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#2692FF",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#FFFFFF",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#2692FF",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#2692FF",
    },
  },
  pnlAttSelectContainer: {
    marginTop: "30px !important",
    marginLeft: "50px !important",
  },
  pnlAttSelect: {
    maxWidth: "130px",
    "& .MuiSvgIcon-root ": {
      fill: "#ffffff !important",
    },

    "& .MuiOutlinedInput-input": {
      color: "#FFFFFF",
      borderRadius: "4px",
    },
    "& .MuiInputLabel-root": {
      color: "#868DA2",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#686B76",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#FFFFFF",
    },
    "&:hover .MuiInputLabel-root": {
      color: "#2692FF",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#2692FF",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#FFFFFF",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#2692FF",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#2692FF",
    },
  },
  pnlArrHeaderTypography: {
    fontSize: "1.5rem !important",
    color: "#ffffff",
    paddingLeft: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  pnlArrHeaderCloseIcon: {
    fontSize: "20px",
    color: "white",
    backgroundColor: "#686B76",
    borderRadius: "12px",
    opacity: "0.5",
  },

  freeTextPicker: {
    marginTop: "25px",
    "& .MuiSelect-select:not([multiple]) option": {
      backgroundColor: "#2D2D2D",
    },
    "& .MuiOutlinedInput-input": {
      color: "#FFFFFF",
      borderRadius: "4px",
      height: "32px",
      width: "220px",
      padding: 0,
      textAlign: "left",
      paddingLeft: "10px",
    },
    "& .MuiInputLabel-root": {
      color: "#868DA2",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#686B76",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#FFFFFF",
    },
    "&:hover .MuiInputLabel-root": {
      color: "#2692FF",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#2692FF",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#FFFFFF",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#2692FF",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#2692FF",
    },
  },
  sort: {
    "& .MuiTableSortLabel-root.Mui-active": {
      color: "white",
    },
    "& .MuiTableSortLabel-root:hover": {},
    "& .MuiTableSortLabel-root:focus": {},
    // MuiButtonBase-root
  },

  dividerBookTrade: {
    backgroundColor: "#868DA2",
    width: "100% ",
    position: "absolute",
    left: "-15px",
  },

  numberPicker: {
    marginTop: "25px",
    "& .MuiSelect-select:not([multiple]) option": {
      backgroundColor: "#2D2D2D",
    },
    "& .MuiOutlinedInput-input": {
      color: "#FFFFFF",
      borderRadius: "4px",
      height: "32px",
      width: "210px",
      padding: 0,
      textAlign: "left",
      marginLeft: "10px",
    },
    "& .MuiInputLabel-root": {
      color: "#868DA2",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#686B76",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#FFFFFF",
    },
    "&:hover .MuiInputLabel-root": {
      color: "#2692FF",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#2692FF",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#FFFFFF",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#2692FF",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#2692FF",
    },
  },
  numberPickerPostTrade: {
    marginTop: "25px",
    "& .MuiSelect-select:not([multiple]) option": {
      backgroundColor: "#2D2D2D",
    },
    "& .MuiOutlinedInput-input": {
      color: "#FFFFFF",
      borderRadius: "4px",
      height: "32px",
      width: "200px",
      padding: 0,
      textAlign: "left",
      marginLeft: "10px",
    },
    "& .MuiInputLabel-root": {
      color: "#868DA2",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#686B76",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#FFFFFF",
    },
    "&:hover .MuiInputLabel-root": {
      color: "#2692FF",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#2692FF",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#FFFFFF",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#2692FF",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline ": {
      borderColor: "#2692FF",
    },
  },

  selectOptionBox: {
    marginTop: "25px",

    "& .makeStyles-selectOptionBox-2993 .MuiOutlinedInput-input ": {
      paddingLeft: "10px",
    },
    "& .MuiSelect-select:not([multiple]) option": {
      backgroundColor: "#2D2D2D",
    },

    "& .MuiSelect-select([multiple]) option": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& .MuiOutlinedInput-input": {
      color: "#FFFFFF",
      borderRadius: "4px",
      height: "32px",
      width: "210px",
      padding: 0,
      textAlign: "left",
      paddingLeft: "10px",
    },
    "& .MuiInputLabel-root": {
      color: "#868DA2",
      // position: "relative",
      // marginBottom: "10px",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#686B76",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#FFFFFF",
    },
    "&:hover .MuiInputLabel-root": {
      color: "#2692FF",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#2692FF",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#FFFFFF",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#2692FF",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#2692FF",
    },
  },
  selectOptionBoxPostTrades: {
    marginTop: "25px",
    "& .makeStyles-selectOptionBox-2993 .MuiOutlinedInput-input": {
      paddingLeft: "10px",
    },
    "& .MuiSelect-select:not([multiple]) option": {
      backgroundColor: "#2D2D2D",
    },

    "& .MuiSelect-select([multiple]) option": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& .MuiOutlinedInput-input": {
      color: "#FFFFFF",
      borderRadius: "4px",
      height: "32px",
      width: "200px",
      padding: 0,
      textAlign: "left",
      paddingLeft: "10px",
    },
    "& .MuiInputLabel-root": {
      color: "#868DA2",
      // position: "relative",
      // marginBottom: "10px",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#686B76",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#FFFFFF",
    },
    "&:hover .MuiInputLabel-root": {
      color: "#2692FF",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#2692FF",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#FFFFFF",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#2692FF",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#2692FF",
    },
  },

  brokerRefSelect: {
    // border: "1px solid #FFFFFF",
    minWidth: "150px",
    borderRadius: "4px",
    "& .MuiInputBase-root": {
      color: "white",
    },

    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#686B76",
    },

    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFFFFF",
    },
    "& .MuiSelect-icon": {
      color: "#FFFFFF",
    },
  },
  disabledButton: {
    "&:disabled": {
      border: "1px solid #bababa",
      color: "#bababa",
    },
  },
  linkBtn: {
    width: "70px",
    borderRadius: 4,
    border: "1px solid #FFB300",
    textTransform: "none",
    color: "#FFB300",
    fontSize: 14,
    "&:hover": {
      backgroundColor: "#FFB300",
      color: "#000000",
    },
  },
  delinkBtn: {
    width: "70px",
    borderRadius: 4,
    border: "1px solid #FF4E32",
    textTransform: "none",
    color: "#FF4E32",
    fontSize: 14,
    "&:hover": {
      backgroundColor: "#FF4E32",
      color: "#000000",
    },
  },
  bookFiBtn: {
    width: "70px",
    borderRadius: 4,
    border: "1px solid #00999E",
    textTransform: "none",
    color: "#00999E",
    fontSize: 14,
    "&:hover": {
      backgroundColor: "#00999E",
      color: "#000000",
    },
  },
  exportFiBtn: {
    borderRadius: 4,
    border: "1px solid #49cb9cf7",
    textTransform: "none",
    color: "#49cb9cf7",
    fontSize: 14,
    "&:hover": {
      backgroundColor: "#49cb9cf7",
      color: "#000000",
    },
  },
  datePickerColor: {
    "& .MuiOutlinedInput": {
      borderColor: "#868DA2",
    },
  },
  editBtn: {
    borderRadius: 4,
    height: 40,
    border: "1px solid #2692FF",
    textTransform: "none",
    color: "#2692FF",
    fontSize: 14,
    "&:hover": {
      backgroundColor: "#2692FF",
      color: "#000000",
    },
  },
  gppCodeBtn: {
    // marginLeft: "3px",
    borderRadius: 4,
    height: "32px",
    width: "95px",
    marginRight: "12px",
    border: "1px solid #686B76",
    textTransform: "none",
    color: "#ffffff",
    fontSize: 14,
    "&:hover": {
      border: "1px solid #2692FF",
      backgroundColor: "#2692FF",
      color: "#000000",
    },
  },
  copyBtn: {
    borderRadius: 4,
    border: "1px solid #1CD3FF",
    textTransform: "none",
    fontSize: 14,
    height: 40,
    color: "#1CD3FF",
    "&:hover": {
      backgroundColor: "#1CD3FF",
      color: "#000000",
    },
  },
  ignoreBtn: {
    borderRadius: 4,
    border: "1px solid #ffe81c",
    textTransform: "none",
    fontSize: 14,
    height: 40,
    color: "#ffe81c",
    "&:hover": {
      backgroundColor: "#ffe81c",
      color: "#000000",
    },
  },
  sendConfoBtn: {
    borderRadius: 4,
    border: "1px solid #FF681C",
    textTransform: "none",
    fontSize: 14,
    width: 122,
    height: 40,
    color: "#FF681C",
    "&:hover": {
      backgroundColor: "#FF681C",
      color: "#000000",
    },
  },
  invalidActionBtn: {
    borderRadius: 4,
    border: "1px solid red",
    textTransform: "none",
    fontSize: 14,
    width: 131,
    height: 32,
    color: "red",
    "&:hover": {
      backgroundColor: "red",
      color: "#000000",
    },
  },

  btn: {
    width: "70px",
    borderRadius: 4,
    backgroundColor: "#2692FF",
    border: "1px solid #2692FF",
    textTransform: "none",
    color: "#ffffff",
    fontSize: 14,
    "&:hover": {
      backgroundColor: "#2692FF",
      color: "#000000",
    },
  },
  btnSendToPershing: {
    minWidth: "180px",
    borderRadius: 4,
    backgroundColor: "#49cb9cf7",
    textTransform: "none",
    height: 40,
    fontSize: 14,
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#49cb9cf7",
      color: "#000000",
    },
    "&.Mui-disabled": {
      backgroundColor: "#49cb9cf7",
      color: "#000000",
      width: "146px",
    },
  },
  btnCancleBooking: {
    borderRadius: 4,
    backgroundColor: "#e74646",
    textTransform: "none",
    height: 40,
    fontSize: 14,
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#e74646",
      color: "#000000",
    },
  },
  datePicker: {
    height: "42px",
    "&.MuiFormControl-root": {
      marginTop: "7px",
    },
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#ffffff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ffffff",
      },
      "&:hover fieldset": {
        borderColor: "#ffffff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#ffffff",
      },
    },
  },
  searchInput: {
    paddingLeft: "10px",

    "& .MuiSvgIcon-root": {
      color: "#2692ff",
    },
    // "& .MuiInputBase-input::placeholder": {

    // },
  },
  dialogPaper: {
    minWidth: "642px",
    height: "667px",
  },
  gppCodeDialogPaper: {
    minWidth: "550px",
    height: "560px",
  },

  dialogPaperBookTrade: {
    minWidth: "850px",
    height: "800px",
  },
  dialogPaperBookFiTrade: {
    minWidth: "1050px",
    height: "600px",
  },
  emailDialogPaper: {
    minWidth: "1250px",
    minHeight: "calc(100% - 0px)",
  },
  btnGroup: {
    border: "1px solid #00999E",
    height: "27px",
    "& .MuiButtonGroup-root": {
      textTransform: "none",
    },
  },
  btnInside: {
    "&:hover": {
      background: "#13b5ba",
    },
    textTransform: "none",
    color: "#ffffff",
    width: "110px",
    height: "inherit",
  },
  chosenBtnInside: {
    backgroundColor: "#00999E",
    height: "inherit",
  },
  checkbox: {
    color: "#686B76",
    "&.MuiIconButton-root": {
      borderRadius: "3px",
      height: "9px",
      width: "10px",
    },
    "&.Mui-checked": {
      color: "#13c552",
    },
  },
  checkbox2: {
    color: "#686B76",
    "&.MuiIconButton-root": {
      borderRadius: "3px",
      height: "9px",
      width: "10px",
    },
    "&.Mui-checked": {
      color: "#2692ff",
    },
  },
  FormControl: {
    marginTop: "20px",
    "&:hover $FormControlLabel": {
      color: "#2692ff", // Change label color on hover
    },
  },
  FormControlLabel: {
    color: "#686B76", // Default label color
  },

  editor: {
    "&.rdw-editor-main": {
      height: "100vh",
      backgroundColor: "pink",
    },
  },
  newFiTrade: {
    backgroundColor: `${Math.floor(Math.random() * 16777215).toString(16)}`,
  },
}));

export const StyledTableCell = withStyles(() => ({
  root: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "noWrap",
    color: "#ffffff",
    textAlign: "center",
    paddingBlock: 1,
    paddingInline: 5,
    // maxWidth: "200px",
  },
}))(TableCell);

export const StyledTableRow = withStyles(() => ({
  root: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "noWrap",
    "& :hover": {
      cursor: "pointer",
    },
  },
}))(TableRow);
