import React, { useState } from 'react';
import './TraderNoteModal.css'
import { makeStyles } from '@material-ui/core/styles'
import { Modal, Fade, Grid, Backdrop, Typography,Button, InputBase } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useSelector, useDispatch } from 'react-redux';
import * as uiSlice from '../../../../store/ui/uiSlice'
import * as rfqSlice from '../../../../store/rfq/rfqSlice'
import axios from 'axios';

export default function ModalCreate(props) {
    const { closeModal,rfqId,rfq } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const modalCreate = useSelector((state) => state.uiSlice.modalCreate);
    const [message,setMessage] = useState()
    const renderTitle = () => {
      return `Create Notes`;
    };
   
    const saveMassage = async () => {
      const token = sessionStorage.getItem("token");
      if(rfqId > 0){
        try {
          let response = await axios.post(`${process.env.REACT_APP_BASE_URL}` + `rfq/rfq_note`,{rfqId:rfqId,message:message},{
            headers: { Authorization: token },
          })
          dispatch(rfqSlice.swapTradeNote({rfqId:rfqId,rfqNote:message}))
          dispatch(uiSlice.ChangeModalStatus(false))
        } catch (error) {
          console.log(error);
        }
      }else {
        dispatch(rfqSlice.updatTraderNote(message))
        dispatch(uiSlice.ChangeModalStatus(false))
      }
       
    }
    const disabledButtonCreate = () => {
        if(message === "" || message === undefined){
            return true
        }
        return false
    }
    return (
      <div className="createModal">
    <Modal
        className={classes.modal}
        open={modalCreate}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalCreate}>
          <Grid
            container
            className={classes.paper}
            style={{ width: "300px", height: "40vh" }}>
            <Grid item xs={12} className={classes.modalTitleContainer}>
              <Typography className={classes.modalTitle}>
                {renderTitle()}
              </Typography>
              <CloseIcon className={classes.closeIcon} onClick={() => closeModal()}/>
            </Grid>
            <InputBase
              id="outlined-multiline-static"
              multiline
              rows={10}
              defaultValue={rfq.length > 0 ? rfq[0].trader_notes : ""}
              onChange = {(e) => {setMessage(e.target.value)}}
              variant="outlined"
              className={classes.root}
              style={{
                  padding:"5px",
                  backgroundColor:"white",
                  borderRadius:"10px",
                  height:"50%",
                  width:"100%"
              }}
            />
               <Button
               onClick={saveMassage}
          disabled={disabledButtonCreate()}
          variant='contained'
          className={classes.buttonCreate}
          style={{
              marginLeft:"auto",
              backgroundColor:"#303030",
              color:"white",
              height:"fit-content",
              width:"fit-content"
          }}>Submit</Button>
          </Grid>
        </Fade>
      </Modal>
      </div>
    );
}
export const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      width: '50%',
      height: 'fit-content',
      maxWidth: '650px',
      minWidth: '400px',
      backgroundColor: "#383838",
      outline: 'none',
      borderRadius: '5px',
    },
    modalTitleContainer: {
      height: '60px',
      borderBottom: '1px solid black',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '5px',
      paddingLeft: '10px',
      paddingRight: '10px',
      backgroundColor: "#383838",
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
    },
    modalTitle: {
      fontSize: '1.3rem',
      fontWeight: '500',
      textTransform: 'capitalize',
      color: "white",
    },
    closeIcon: {
      width: '1.5rem',
      color: "white",
      '&:hover': {
        cursor: 'pointer',
        color: "white",
      },
    },
    buttonCreate: {
      background: '#37b54a',
      color: '#ffffff',
      
      '&:hover': {
        background: '#61bc5c',
      },
    },
    buttonDisabled: {
      background: '#37b54a !important',
      opacity: 0.6,
    },
    buttonCancel: {
      background: "black",
      border: '1px solid black',
      color: "black",
      textTransform: 'none',
      boxShadow: 'none',
      '&:hover': {
        background: "black",
        color: '#000000',
        boxShadow: 'none',
      },
    },
    IconButton: {
      color: "black",
    }
  }))
  