import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  Typography,
  Paper,
  Button,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { DerivsTableByOrder } from "../priceTableByOrder/DerivsTableByOrder";
import workerInstances from "../../../services/index";
import axios from "axios";
import { END_POINT } from "../../../utils";
import { useStyles } from "../../../Styles/pricerStyle";
import SearchIcon from "@material-ui/icons/Search";
import soundEffect from "../../../assets/my-sounds/cash-register-purchase-87313.mp3";
import Pagination from "@material-ui/lab/Pagination";
import { StrategiesMultiSelect } from "./components/StrategiesMultiSelect";
import { getOrdersList } from "./utils/apiCalls";
import { testDateWithinRange, useDebounce } from "./utils/utilsFunctions";
import {
  subscribeOrdersToSocket,
  unsubscribeFromSocket,
  unsubscribeAllFromSocket,
  unsubscribeOnSearch,
} from "./utils/apiCalls";

export const DerivsPriceBlotter = ({
  miniFeedWindowRef,
  historyWindowRef,
  currentTab,
}) => {
  const classes = useStyles();
  const audioRef = useRef(null);
  const [ordersList, setOrdersList] = useState([]);
  const [bbgUpdate, setBbgUpdate] = useState(null);
  const [bbgCurrentTImestamp, setBbgCurrentTimestamp] = useState(
    new Date().getTime(),
  );
  const [strategiesList, setStrategiesList] = useState([]);
  const [isOrderListLoading, setIsOrderListLoading] = useState(true);
  const [searchValue, setSearchValue] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  const [wsPricerConnection, setWsPricerConnection] = useState(false);
  const [isFirstConnection, setIsFirstConnection] = useState(true);
  const [entitlement, setEntitlement] = useState(null);
  const [team, setTeam] = useState(null);

  const parsedString = useSelector(
    (state) => state.derivsPricerSlice.parsedString,
  );

  const handleNewOrder = useCallback(
    async ({ order, ordersList, strategiesList }) => {
      const ordersListCopy = [...ordersList];
      const strategiesListCopy = [...strategiesList];
      const { symbol, equityIndex, futureTicker } = order.order;
      const tickerString = (
        futureTicker !== null
          ? futureTicker + " " + equityIndex
          : symbol.replaceAll("/", "%252f") + " " + equityIndex
      )
        .replaceAll("/", "|")
        .replaceAll(" ", "%20");
      const res = await axios.get(
        `${
          process.env.REACT_APP_BLOOMBERG_DERIVS
        }equity/${tickerString.toUpperCase()}`,
      );
      const {
        div_date,
        name,
        price,
        report_date,
        sector,
        ticker,
        has_adr,
        country,
        security_type,
        isin,
        primary_exchange,
      } = res.data;
      const dividend = await testDateWithinRange(div_date);
      const earnings = await testDateWithinRange(report_date);

      ordersListCopy.unshift({
        ...order.order,
        dividend,
        name,
        lastPrice: price,
        earnings,
        sector,
        ticker,
        has_adr,
        country,
        isNewTimestamp: new Date().getTime(),
        securityType: security_type,
        isin,
        primaryExchange: primary_exchange,
      });

      if (audioRef.current) {
        audioRef.current.play();
      }
      strategiesListCopy.unshift({ ...order.order });
      setOrdersList(ordersListCopy);
      setStrategiesList(strategiesListCopy);
      subscribeOrdersToSocket([order.order]);
      const orderToUnsubscribe = ordersListCopy.find(
        (order, index) => index === pageNum * 10,
      );
      if (orderToUnsubscribe !== undefined) {
        unsubscribeFromSocket(orderToUnsubscribe);
      }
    },
    [ordersList, strategiesList, audioRef, pageNum],
  );
  const handleAddOrderToCustomList = useCallback(
    async ({ id, ordersList, strategiesList }) => {
      const copyOrderList = [...ordersList];
      const order = strategiesList.filter(
        (order) => Number(order.id) === Number(id),
      );
      if (order.length > 0) {
        const { symbol, equityIndex, futureTicker } = order[0];
        const tickerString = (
          futureTicker !== null
            ? futureTicker + " " + equityIndex
            : symbol.replaceAll("/", "%252f") + " " + equityIndex
        )
          .replaceAll("/", "|")
          .replaceAll(" ", "%20");
        const res = await axios.get(
          `${
            process.env.REACT_APP_BLOOMBERG_DERIVS
          }equity/${tickerString.toUpperCase()}`,
        );
        const {
          div_date,
          name,
          price,
          report_date,
          sector,
          ticker,
          has_adr,
          country,
          security_type,
          isin,
          primary_exchange,
        } = res.data;
        const dividend = await testDateWithinRange(div_date);
        const earnings = await testDateWithinRange(report_date);

        subscribeOrdersToSocket([order[0]]);
        copyOrderList.unshift({
          ...order[0],
          dividend,
          name,
          price,
          earnings,
          sector,
          ticker,
          has_adr,
          country,
          securityType: security_type,
          isin,
          primaryExchange: primary_exchange,
        });
        setOrdersList(
          copyOrderList.sort(
            (a, b) => new Date(b.createdOn) - new Date(a.createdOn),
          ),
        );
      }
    },
    [[ordersList, strategiesList, pageNum]],
  );

  const updateOrderFutureTicker = useCallback(
    async ({ order, ordersList, strategiesList }) => {
      const { colId, orderId, value } = order.updateBody;

      const ordersListCopy = [...ordersList];
      const strategiesListCopy = [...strategiesList];
      const ordersListIndex = ordersList.findIndex(
        (order) => Number(order.id) === Number(orderId),
      );
      const strategyIndex = strategiesList.findIndex(
        (order) => Number(order.id) === Number(orderId),
      );

      if (ordersListIndex !== -1) {
        unsubscribeFromSocket(value);
        ordersListCopy.splice(ordersListIndex, 1, value);
        setOrdersList(ordersListCopy);
        subscribeOrdersToSocket([value]);
      }
      if (strategyIndex !== -1) {
        strategiesListCopy.splice(strategyIndex, 1, value);
        setStrategiesList(strategiesListCopy);
      }
    },
    [ordersList, strategiesList, pageNum],
  );

  const handleDeleteOrder = useCallback(
    async ({ orderDetails, ordersList, strategiesList }) => {
      const ordersListCopy = [...ordersList];
      const strategiesListCopy = [...strategiesList];
      const index = ordersList.findIndex(
        (order) => Number(order.id) === Number(orderDetails.orderId),
      );
      const strategyIndex = strategiesList.findIndex(
        (order) => Number(order.id) === Number(orderDetails.orderId),
      );

      if (index !== -1) {
        unsubscribeFromSocket(ordersListCopy[index]);
        ordersListCopy.splice(index, 1);
        setOrdersList(ordersListCopy);
        const orderToSubscribe = ordersList.find(
          (order, index) => index === pageNum * 10,
        );
        if (orderToSubscribe !== undefined) {
          subscribeOrdersToSocket([orderToSubscribe]);
        }

        if (
          ordersListCopy.length % 10 === 0 &&
          ordersListCopy.length / 10 < pageNum
        ) {
          setPageNum(Number(pageNum) !== 1 ? Number(pageNum) - 1 : 1);
        }
      }
      if (strategyIndex !== -1) {
        strategiesListCopy.splice(strategyIndex, 1);
        setStrategiesList(strategiesListCopy);
      }
    },
    [ordersList, strategiesList, pageNum],
  );

  const handleIgnoreOrder = useCallback(
    async ({ id, ordersList }) => {
      const copyOrderList = [...ordersList];
      const index = ordersList.findIndex((o) => Number(o.id) === Number(id));
      if (index !== -1) {
        unsubscribeFromSocket(copyOrderList[index]);
        copyOrderList.splice(index, 1);
        setOrdersList(copyOrderList);
      }
      const orderToSubscribe = ordersList.find(
        (order, index) => index === pageNum * 10,
      );
      if (orderToSubscribe !== undefined) {
        subscribeOrdersToSocket([orderToSubscribe]);
      }

      if (
        copyOrderList.length % 10 === 0 &&
        copyOrderList.length / 10 < pageNum
      ) {
        setPageNum(Number(pageNum) !== 1 ? Number(pageNum) - 1 : 1);
      }
    },
    [ordersList, pageNum],
  );

  const openMiniFeed = () => {
    if (!miniFeedWindowRef.current || miniFeedWindowRef.current.closed) {
      const url = `${process.env.REACT_APP_MINI_FEED_URL}`;
      const windowFeatures =
        "width=510,height=800,menubar=no,toolbar=no,location=no,status=no";

      const newWindow = window.open(url, "_blank", windowFeatures);

      if (newWindow) {
        miniFeedWindowRef.current = newWindow;
      }
    }
  };
  const openHistory = () => {
    if (!historyWindowRef.current || historyWindowRef.current.closed) {
      const url = `${process.env.REACT_APP_HISTORY_URL}`;
      const windowFeatures =
        "width=510,height=800,menubar=no,toolbar=no,location=no,status=no";

      const newWindow = window.open(url, "_blank", windowFeatures);

      if (newWindow) {
        historyWindowRef.current = newWindow;
      }
    }
  };
  const debouncedSearchTerm = useDebounce(searchValue, 500);

  const handleSearchByValue = async (searchValue) => {
    setSearchValue(searchValue);
  };
  const handlePagination = async (e, value) => {
    setIsOrderListLoading(true);
    const updatedOrdersList = await Promise.all(
      ordersList.map(async (order, index) => {
        if (
          (value === 1 && index < 10) ||
          (value > 1 && (value - 1) * 10 <= index && index < value * 10)
        ) {
          subscribeOrdersToSocket([order]);
          const { symbol, equityIndex, futureTicker } = order;
          const tickerString = (
            futureTicker !== null
              ? futureTicker + " " + equityIndex
              : symbol.replaceAll("/", "%252f") + " " + equityIndex
          )
            .replaceAll("/", "|")
            .replaceAll(" ", "%20");
          const res = await axios.get(
            `${
              process.env.REACT_APP_BLOOMBERG_DERIVS
            }equity/${tickerString.toUpperCase()}`,
          );
          const {
            div_date,
            name,
            price,
            report_date,
            sector,
            ticker,
            has_adr,
            country,
            multiplier,
            security_type,
            isin,
            primary_exchange,
          } = res.data;
          const dividend = await testDateWithinRange(div_date);
          const earnings = await testDateWithinRange(report_date);
          return {
            ...order,
            dividend,
            name,
            lastPrice: price,
            earnings,
            sector,
            ticker,
            has_adr,
            country,
            multiplier,
            securityType: security_type,
            isin,
            primaryExchange: primary_exchange,
          };
        } else {
          unsubscribeOnSearch([order]);
          return { ...order };
        }
      }),
    );
    setOrdersList(updatedOrdersList);
    setIsOrderListLoading(false);

    setPageNum(value);
  };

  // first mount && unmount handler
  useEffect(() => {
    getOrdersList(
      setStrategiesList,
      setOrdersList,
      setIsOrderListLoading,
      pageNum,
      setEntitlement,
      setTeam,
      setBbgUpdate,
    );
    return () => {
      unsubscribeAllFromSocket();
    };
  }, []);

  // debounce useEffect
  useEffect(() => {
    searchValue !== null && handleDebouncedSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  const handleDebouncedSearch = async () => {
    try {
      const token = sessionStorage.getItem("token");
      if (searchValue.length > 1) {
        setPageNum(1);
        unsubscribeOnSearch(ordersList);
        setTimeout(async () => {
          setIsOrderListLoading(true);
          const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}${END_POINT.SEARCH_ORDER_BY_VALUE}`,
            { searchValue },
            { headers: { Authorization: token } },
          );
          if (res.data.length > 0) {
            const ordersList = await Promise.all(
              res.data.map(async (order) => {
                const { symbol, equityIndex } = order;
                const tickerString = (
                  symbol.replaceAll("/", "%252f") +
                  " " +
                  equityIndex
                )
                  .replaceAll("/", "|")
                  .replaceAll(" ", "%20");
                const res = await axios.get(
                  `${
                    process.env.REACT_APP_BLOOMBERG_DERIVS
                  }equity/${tickerString.toUpperCase()}`,
                );
                const {
                  div_date,
                  name,
                  price,
                  report_date,
                  sector,
                  ticker,
                  has_adr,
                  country,
                  multiplier,
                  security_type,
                  isin,
                  primary_exchange,
                } = res.data;

                const dividend = await testDateWithinRange(div_date);
                const earnings = await testDateWithinRange(report_date);

                return {
                  ...order,
                  dividend,
                  name,
                  lastPrice: price,
                  earnings,
                  sector,
                  ticker,
                  has_adr,
                  country,
                  multiplier,
                  securityType: security_type,
                  isin,
                  primaryExchange: primary_exchange,
                };
              }),
            );
            subscribeOrdersToSocket(ordersList);

            setBbgUpdate({
              timestamp: new Date().getTime(),
              payload: ordersList,
            });
          } else {
            setOrdersList(res.data);
          }
          setIsOrderListLoading(false);
        }, 500);
      } else if (searchValue.length < 2) {
        setPageNum(1);
        unsubscribeOnSearch(ordersList);
        getOrdersList(
          setStrategiesList,
          setOrdersList,
          setIsOrderListLoading,
          pageNum,
          setEntitlement,
          setTeam,
          setBbgUpdate,
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  // server sockets updates
  useEffect(() => {
    const handleDerivs = async (message) => {
      switch (message.data.type) {
        case "new_derivs_order":
          handleNewOrder({ order: message.data, ordersList, strategiesList });

          break;
        case "update_future_ticker":
          updateOrderFutureTicker({
            order: message.data,
            ordersList,
            strategiesList,
          });

          break;
        case "remove_derivs_order":
          handleDeleteOrder({
            orderDetails: message.data,
            ordersList,
            strategiesList,
          });
          break;
        case "derivs_pricer_ignore":
          handleIgnoreOrder({
            id: message.data.id,
            ordersList,
            pageNum,
          });
          break;
        case "derivs_pricer_add":
          handleAddOrderToCustomList({
            id: message.data.id,
            ordersList,
            strategiesList,
          });
          break;
        case "derivs_pricer_status_update":
          const updateStatus = () => {
            const { id, status } = message.data;
            const copyStrategiesList =
              currentTab !== "favourites" ? [...strategiesList] : [];
            const copyOrdersList = [...ordersList];
            const index = copyStrategiesList.findIndex(
              (s) => Number(s.id) === Number(id),
            );
            const ordersListIndex = copyOrdersList.findIndex(
              (s) => Number(s.id) === Number(id),
            );
            if (index !== -1) {
              copyStrategiesList[index] = {
                ...copyStrategiesList[index],
                status,
              };
              setStrategiesList(copyStrategiesList);
            }
            if (ordersListIndex !== -1) {
              copyOrdersList[ordersListIndex] = {
                ...copyOrdersList[ordersListIndex],
                status,
              };
              setOrdersList(copyOrdersList);
            }
          };
          updateStatus();
          break;
        default:
          break;
      }
    };
    const handleWsConnection = async (message) => {
      switch (message.data.security) {
        case "connection_status":
          if (isFirstConnection && !message.data.status) {
            setIsFirstConnection(false);
          }
          setWsPricerConnection(message.data.status);

          break;
        default:
          break;
      }
    };
    window.addEventListener("message", handleDerivs);
    workerInstances?.WebSocketPricesInstance?.addEventListener(
      "message",
      handleWsConnection,
    );

    return () => {
      window.removeEventListener("message", handleDerivs);

      workerInstances?.WebSocketPricesInstance?.removeEventListener(
        "message",
        handleWsConnection,
      );
    };
  }, [ordersList, strategiesList, pageNum]);

  useEffect(() => {
    if (wsPricerConnection && !isFirstConnection) {
      getOrdersList(
        setStrategiesList,
        setOrdersList,
        setIsOrderListLoading,
        pageNum,
        setEntitlement,
        setTeam,
        setBbgUpdate,
      );
    }
  }, [wsPricerConnection]);

  useEffect(() => {
    if (bbgUpdate !== null) {
      if (bbgUpdate.timestamp > bbgCurrentTImestamp) {
        setOrdersList(bbgUpdate.payload);
        setBbgCurrentTimestamp(bbgUpdate.timestamp);
      }
    }
  }, [bbgUpdate]);

  return (
    <Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{
          height: parsedString.length > 0 ? "65vh" : "75vh",
          // maxHeight: "80vh",
          marginTop: "15px",
        }}
      >
        <audio ref={audioRef} src={soundEffect} />

        <Grid className={classes.teamText}>
          <Typography
            style={{
              font: "normal normal normal 14px/17px Inter",
              opacity: "0.8",
            }}
          >
            {team}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography
            style={{
              fontSize: 22,
              marginBottom: "20px",
              color: "#828282",
              opacity: "0.8",
              height: "40px",
            }}
          >
            Derivatives Blotter
          </Typography>
        </Grid>
        <Grid
          xs={12}
          sm={6}
          md={8}
          spacing={2}
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          {entitlement !== null && entitlement.includes("derivs-history") ? (
            <Grid item>
              <Button className={classes.historyBtn} onClick={openHistory}>
                History
              </Button>
            </Grid>
          ) : null}

          <Grid item>
            <Button className={classes.historyBtn} onClick={openMiniFeed}>
              Mini-Feed
            </Button>
          </Grid>
          <Grid item>
            <StrategiesMultiSelect
              strategiesList={strategiesList}
              ordersList={ordersList}
              setOrdersList={setOrdersList}
            />
          </Grid>
          <Grid item>
            <InputBase
              style={{
                color: "white",
                border: "1px solid #686B76",
                borderRadius: "4px",
                height: "32px",
                width: "256px",
              }}
              className={classes.searchInput}
              variant="outlined"
              onChange={(e) =>
                handleSearchByValue(e.target.value.toLowerCase())
              }
              value={searchValue}
              placeholder="Search"
              endAdornment={
                <InputAdornment position="start">
                  <IconButton
                  // onClick={(e) =>
                  //   handleSearchByValue(e.target.value.toLowerCase())
                  // }
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          alignContent="flex-start"
          wrap="wrap"
          style={{
            height: "90%",
            overflow: "scroll",
            overflowX: "hidden",
          }}
        >
          {isOrderListLoading ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          ) : ordersList.length > 0 ? (
            ordersList.map((order, index) => {
              if (
                (pageNum === 1 && index < 10) ||
                (pageNum > 1 &&
                  (pageNum - 1) * 10 <= index &&
                  index < pageNum * 10)
              ) {
                return (
                  <DerivsTableByOrder
                    key={`${order.id}`}
                    order={order}
                    setOrdersList={setOrdersList}
                    setStrategiesList={setStrategiesList}
                    ordersList={ordersList}
                    strategiesList={strategiesList}
                    tab={"blotter"}
                    team={team}
                  />
                );
              }
            })
          ) : (
            <Typography
              style={{ color: "#828282", opacity: "0.8", fontSize: "15px" }}
            >
              No Active Orders
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid className={classes.pagination}>
        <Pagination
          count={
            ordersList.length % 10 > 0
              ? Math.floor(ordersList.length / 10) + 1
              : ordersList.length / 10
          }
          page={pageNum}
          onChange={handlePagination}
          size="large"
          // disabled={ordersList.length < 6}
        />
      </Grid>
    </Grid>
  );
};
