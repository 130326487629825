import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import {
  createNewMarkitFav,
  getMarkitAddressees,
  getMarkitLegalEntitiesByParticipant,
  getMarkitParticipants,
} from "../utils/apiCalls";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { useStyles } from "../style/style";
import { AddresseesList } from "./AddresseesList";
import CancelIcon from "@material-ui/icons/Cancel";
import CloseIcon from "@material-ui/icons/Close";
import GenericList from "./GenericList";

export const ParticipantsDialog = ({
  open,
  handleClose,
  setMarkitFavData,
  mappedTableData,
}) => {
  const classes = useStyles();
  const [participantsList, setParticipantsList] = useState(null);
  const [addresseesList, setAddresseesList] = useState(null);
  const [legalEntitiesList, setLegalEntitiesList] = useState(null);
  const [selectedIdParticipant, setSelectedIdParticipant] = useState(null);
  const [selectedIdAddressee, setSelectedIdAddressee] = useState(null);
  const [selectedIdLE, setSelectedIdLE] = useState(null);
  const [selectedAddresseesList, setSelectedAddresseesList] = useState([]);
  const [selectedFav, setSelectedFav] = useState(null);
  const [isEditingGroup, setIsEditingGroup] = useState(false);
  const [clickTimeout, setClickTimeout] = useState(null);
  const clickTimeoutRef = useRef(null);

  const handleCancel = () => {
    handleClose();
    setParticipantsList(null);
    setAddresseesList(null);
    setLegalEntitiesList(null);
    setSelectedIdParticipant(null);
    setSelectedIdAddressee(null);
    setSelectedAddresseesList([]);
    setSelectedIdLE(null);
    setSelectedFav(null);
    setIsEditingGroup(false);
  };
  const onParticipantPick = async (participant) => {
    if (participant.id !== selectedIdParticipant) {
      setSelectedIdParticipant(participant.participantId);
      setSelectedFav(null); // Reset to null if "none" is selected
      setSelectedAddresseesList([]);
      setIsEditingGroup(false);
    }
  };
  const onLEPick = (le) => {
    if (le.LEBic !== selectedIdLE) {
      setSelectedIdLE(le.LEBic);
    }
  };

  const onAddresseePick = (addressee) => {
    setSelectedIdAddressee(addressee.id);
  };
  const onAddresseeDoubleClick = (addressee) => {
    setSelectedAddresseesList((prev) => {
      const arr = [...prev];
      const participant = participantsList.filter(
        (p) => p.participantId === selectedIdParticipant,
      );
      const newEntry = {
        label: `${selectedIdParticipant}\\${addressee.userId}`,
        addresseeRowId: addressee.id,
        participantRowId: participant[0].id,
      };
      if (!arr.includes(newEntry)) {
        arr.push(newEntry);
      }
      return arr;
    });
  };
  const handleDeleteChip = (chipToDelete) => {
    setSelectedAddresseesList((pre) =>
      pre.filter((chip) => chip !== chipToDelete),
    );
  };
  const handleSubmit = () => {
    createNewMarkitFav(
      selectedAddresseesList,
      setMarkitFavData,
      isEditingGroup,
      selectedFav,
      selectedIdLE,
    );
    setSelectedFav(null); // Reset to null if "none" is selected
    setSelectedAddresseesList([]);
    setSelectedIdParticipant(null);
    setAddresseesList([]);
    setLegalEntitiesList([]);
    setIsEditingGroup(false);
    // handleCancel();
  };

  const handleChangeFavList = (event) => {
    const selectedOption = event.target.value;
    if (selectedOption === "") {
      setSelectedFav(null); // Reset to null if "none" is selected
      setSelectedAddresseesList([]);
      setSelectedIdParticipant(null);
      setAddresseesList([]);
      setLegalEntitiesList([]);
      setIsEditingGroup(false);
      setSelectedIdAddressee(null);
    } else {
      const participant = participantsList.filter(
        (p) => p.id === selectedOption.chips[0].participantRowId,
      );
      setSelectedIdParticipant(participant[0].participantId);
      setSelectedFav(selectedOption);
      setSelectedAddresseesList(selectedOption.chips);
      setIsEditingGroup(true);
      setSelectedIdAddressee(null);
      setSelectedIdLE(selectedOption.LEBic);
    }
  };
  
  const handleItemClick = (item, onItemPick, onItemDoubleClick) => {
    if (clickTimeoutRef.current) {
      clearTimeout(clickTimeoutRef.current);
      clickTimeoutRef.current = null;
      onItemDoubleClick(item); // Call double-click handler
    } else {
      clickTimeoutRef.current = setTimeout(() => {
        onItemPick(item); // Call single-click handler
        clickTimeoutRef.current = null;
      }, 300); // Adjust the timeout duration as needed
    }
  };
  useEffect(() => {
    if (open) {
      getMarkitParticipants(
        setParticipantsList,
        setAddresseesList,
        setLegalEntitiesList,
      );
    }
  }, [open]);

  useEffect(() => {
    if (selectedIdParticipant !== null) {
      setAddresseesList(null);
      getMarkitAddressees(setAddresseesList, selectedIdParticipant);
      setLegalEntitiesList(null);
      if (!isEditingGroup) {
        setSelectedIdLE(null);
      }
      getMarkitLegalEntitiesByParticipant(
        selectedIdParticipant,
        "participantDialog",
        setLegalEntitiesList,
      );
    }
  }, [selectedIdParticipant]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleCancel();
      }}
      classes={{ paper: classes.participantsDialog }}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        className={classes.mainContainer}
      >
        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <IconButton size="small" onClick={handleCancel}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={4}
          className={classes.sectionContainer}
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item>
            <Typography className={classes.headerTypography}>
              Participants
            </Typography>
          </Grid>

          <Grid
            item
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Collapse in={true} timeout="auto" unmountOnExit>
              <List
                component="div"
                disablePadding
                className={classes.itemsListClients}
              >
                {participantsList !== null ? (
                  participantsList
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((p) => {
                      return (
                        <ListItem
                          button
                          onClick={() => onParticipantPick(p)}
                          selected={selectedIdParticipant === p.participantId}
                          className={classes.listItemSelected}
                          // ref={(el) => itemRefs.current.set(obj.id, el)}
                        >
                          <ListItemText
                            className={classes.listItemText}
                            primary={p.name
                              .toLowerCase()
                              .split(" ")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() +
                                  word.substring(1),
                              )
                              .join(" ")}
                          />
                          {selectedIdParticipant === p.id ? (
                            <PlayArrowIcon className={classes.playIcon} />
                          ) : null}
                        </ListItem>
                      );
                    })
                ) : (
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.columnsContainer}
                  >
                    <Box sx={{ display: "flex" }}>
                      <CircularProgress className={classes.circularProgress} />
                    </Box>
                  </Grid>
                )}
              </List>
            </Collapse>
          </Grid>
        </Grid>
        <Grid
          item
          xs={4}
          className={classes.sectionContainer}
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item>
            <Typography className={classes.headerTypography}>
              Addressees:
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <GenericList
              itemList={addresseesList}
              selectedItem={selectedIdAddressee}
              onItemPick={(item) =>
                handleItemClick(item, onAddresseePick, onAddresseeDoubleClick)
              }
              onItemDoubleClick={() => {}}
              classes={classes}
              itemKeyProp="id"
              itemDisplayProp="userId"
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={4}
          className={classes.sectionContainer}
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item>
            <Typography className={classes.headerTypography}>
              Legal Entities
            </Typography>
          </Grid>

          <Grid
            item
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <GenericList
              itemList={legalEntitiesList}
              selectedItem={selectedIdLE}
              onItemPick={(item) => handleItemClick(item, onLEPick, () => {})}
              onItemDoubleClick={() => {}}
              classes={classes}
              itemKeyProp="LEBic"
              itemDisplayProp="LELongName"
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          item
          xs={12}
          className={classes.chipsContainer}
        >
          <Grid item xs={1}>
            <Typography variant="contained" className={classes.toTypography}>
              To :
            </Typography>
          </Grid>
          <Grid xs={11} item>
            {selectedAddresseesList.map((addressee) => {
              return (
                <Chip
                  variant="outlined"
                  color="primary"
                  onDelete={() => handleDeleteChip(addressee)}
                  label={addressee.label}
                  className={classes.chip}
                  deleteIcon={<CancelIcon className={classes.deleteIcon} />}
                />
              );
            })}
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          item
          xs={12}
          className={classes.favListContainer}
        >
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">
              Favorite List
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="Favorite List"
              className={classes.select}
              MenuProps={{
                classes: { paper: classes.menu },
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                PopoverClasses: { root: classes.menu },
              }}
              value={selectedFav ? selectedFav : ""}
              onChange={handleChangeFavList}
            >
              <MenuItem key="none" value="">
                <em>None</em>
              </MenuItem>
              {open &&
                mappedTableData[0]?.markitFavData.map((option, index) => {
                  return (
                    <MenuItem key={index} value={option}>
                      {option.label}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          xs={12}
          className={classes.buttonsContainer}
        >
          <Grid item>
            <Button
              variant="contained"
              className={classes.btn}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              className={classes.btn}
              onClick={handleSubmit}
            >
              Ok
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};
