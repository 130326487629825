import {
  Grid,
  IconButton,
  Typography,
  Popper,
  Fade,
  Backdrop,
  Modal,
  Button,
} from "@material-ui/core";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "ag-grid-enterprise";
import axios from "axios";
import workerInstances from "../../../services/index";
import { ReactComponent as Trash } from "../../../assets/trash.svg";
import { ReactComponent as Ignore } from "../../../assets/X.svg";
import StarOutlineRoundedIcon from "@material-ui/icons/StarOutlineRounded";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import EmailIcon from "@material-ui/icons/Email";
import { ReactComponent as Launch } from "../../../assets/launch.svg";

import { END_POINT } from "../../../utils";
import "./style.css";
import clsx from "clsx";
import CustomAnimationRenderer from "../custom renderer/CustomAnimationRenderer";
import { ReactComponent as Copy } from "../../../assets/copy.svg";
import EditIcon from "@material-ui/icons/Edit";
import { useStyles } from "../../../Styles/pricerStyle";
import { useDispatch, useSelector } from "react-redux";
import * as actionSnackBar from "../../../store/snackbar/action";
import { ws, connectWS, sendEvent } from "../../../services/websocket";
import { BookingDialog } from "../bookingDialog/BookingDialog";
import { ConfirmationDialog } from "../confirmation/ConfirmationDialog";
import { tieListCreator } from "./utils";
import { TieCell } from "./componenets/TieCell";
import { valueFormatterSelector } from "./utils/valueFormatterSelector";
import { deleteOrder, favouritesHandler, updateOrder } from "./utils/apiCalls";
import { bidAskRefAgg } from "./utils/aggFunctions";
import {
  orderAskRefValueGetter,
  orderAskValueGetter,
  orderBidRefValueGetter,
  orderBidValueGetter,
  orderMidRefValueGetter,
} from "./utils/valueGetter";
import {
  removeOrderFromCustomList,
  startBookingProcess,
  subscribeOrdersToSocket,
  unsubscribeFromSocket,
} from "../derivsPricerBlotter/utils/apiCalls";
import { testDateWithinRange } from "../derivsPricerBlotter/utils/utilsFunctions";
import { MarketMakerPopover } from "./componenets/MarketMakerPopover";
import { EConfoDialog } from "../econfoDialog/EConfoDialog";

export const DerivsTableByOrder = ({
  order,
  setOrdersList,
  setStrategiesList,
  ordersList,
  strategiesList,
  tab,
  team,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const gridRef = useRef();
  const gridStyle = useMemo(
    () => ({
      width: "100%",
      scrollbarWidth: "thin",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    [],
  );
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState(order.legs);
  const [legsValues, setLegValues] = useState(order.legs);
  const [underlineDetails, setUnderlineDetails] = useState(order);
  const [anchorElStrategy, setAnchorElStrategy] = useState(null);
  const [anchorElFutureTicker, setAnchorElFutureTicker] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newFutureTicker, setNewFutureTicker] = useState(null);
  const [openBookingModal, setOpenBookingModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openEConfoModal, setOpenEConfoModal] = useState(false);
  const [tieList, setTieList] = useState(null);
  const [isFlicker, setIsFlicker] = useState(false);

  const isStrategyOpen = Boolean(anchorElStrategy);
  const isFutureTickerOpen = Boolean(anchorElFutureTicker);
  const strategyId = isStrategyOpen ? `popper-${order.id}` : undefined;
  const futureTickerId = isFutureTickerOpen ? `popper-${order.id}` : undefined;
  //internal use function
  const handleClickBookingIcon = async (e, order) => {
    setOpenBookingModal(true);
    startBookingProcess(order.id);
  };

  const handleIgnoreOrder = async (order) => {
    const index = ordersList.findIndex(
      (o) => Number(o.id) === Number(order.id),
    );
    if (index !== -1) {
      removeOrderFromCustomList(order.id);
    }
  };
  const onMouseEnterStrategy = (event) => {
    setAnchorElStrategy(anchorElStrategy ? null : event.currentTarget);
  };
  const onMouseLeaveStrategy = () => {
    setAnchorElStrategy(null);
  };
  const copyToClip = () => {
    navigator.clipboard.writeText(order.strategy);
  };
  const onMouseEnterFutureTicker = (event) => {
    setAnchorElFutureTicker(anchorElFutureTicker ? null : event.currentTarget);
  };
  const onMouseLeaveFutureTicker = () => {
    setAnchorElFutureTicker(null);
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setNewFutureTicker(null);
    setIsModalOpen(false);
  };
  const handleFutureTickerChange = (e) => {
    if (e.target.value === "") {
      setNewFutureTicker(null);
    } else {
      setNewFutureTicker(e.target.value);
    }
  };
  const handleUpdateFutureTicker = async () => {
    try {
      const tickerString = (
        newFutureTicker.replaceAll("/", "%252f") +
        " " +
        order.equityIndex
      )
        .replaceAll("/", "|")
        .replaceAll(" ", "%20");
      const res = await axios.get(
        `${
          process.env.REACT_APP_BLOOMBERG_DERIVS
        }equity/${tickerString.toUpperCase()}`,
      );
      if (res.data.error) {
        dispatch(actionSnackBar.setSnackBar("error", res.data.error, 3000));
      } else {
        const {
          div_date,
          name,
          price,
          report_date,
          sector,
          ticker,
          has_adr,
          country,
          primary_exchange,
        } = res.data;
        const dividend = await testDateWithinRange(div_date);
        const earnings = await testDateWithinRange(report_date);
        const updatedOrder = {
          ...order,
          dividend,
          name,
          price,
          earnings,
          sector,
          ticker,
          has_adr,
          country,
          futureTicker: newFutureTicker.toUpperCase(),
          primaryExchange: primary_exchange,
        };
        updateOrder(order.id, null, null, "futureTicker", updatedOrder);
        handleCloseModal();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const closeBookingModal = () => {
    setOpenBookingModal(false);
  };
  const closeConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };
  const closeEConfoModal = () => {
    setOpenEConfoModal(false);
  };

  const updateTie = useCallback(
    (message) => {
      const { id, tieValue } = message.data.updateBody;

      // Update strategiesList
      setStrategiesList((prevStrategiesList) => {
        const copyStrategiesList =
          tab !== "favourites" ? [...prevStrategiesList] : [];
        const index = copyStrategiesList.findIndex(
          (s) => Number(s.id) === Number(id),
        );
        if (index !== -1) {
          copyStrategiesList[index] = {
            ...copyStrategiesList[index],
            tie: tieValue,
          };
        }
        return copyStrategiesList;
      });

      // Update ordersList
      setOrdersList((prevOrdersList) => {
        const copyOrdersList = [...prevOrdersList];
        const ordersListIndex = copyOrdersList.findIndex(
          (s) => Number(s.id) === Number(id),
        );
        if (ordersListIndex !== -1) {
          copyOrdersList[ordersListIndex] = {
            ...copyOrdersList[ordersListIndex],
            tie: tieValue,
          };
        }
        return copyOrdersList;
      });

      // Update underlineDetails if necessary
      if (Number(order.id) === Number(id)) {
        setUnderlineDetails((prevDetails) => ({
          ...prevDetails,
          tie: tieValue,
        }));
      }
    },
    [setStrategiesList, setOrdersList, setUnderlineDetails],
  );
  const updateTieByLeg = useCallback(
    (message) => {
      const { id, tieValueByLeg, legNumArr } = message.data.updateBody;
      if (Number(id) === Number(order.id)) {
        let copyLegsValues = null;

        setLegValues((pre) => {
          const arr = pre.map((leg) => {
            if (legNumArr.includes(leg.legNum)) {
              return { ...leg, tieByLeg: tieValueByLeg };
            } else return { ...leg };
          });
          copyLegsValues = [...arr];
          return arr;
        });

        setStrategiesList((prevStrategiesList) => {
          const copyStrategiesList =
            tab !== "favourites" ? [...prevStrategiesList] : [];
          const index = copyStrategiesList.findIndex(
            (s) => Number(s.id) === Number(id),
          );
          if (index !== -1) {
            copyStrategiesList[index] = {
              ...copyStrategiesList[index],
              legs: copyLegsValues,
            };
          }
          return copyStrategiesList;
        });

        setOrdersList((prevOrdersList) => {
          const copyOrdersList = [...prevOrdersList];
          const ordersListIndex = copyOrdersList.findIndex(
            (s) => Number(s.id) === Number(id),
          );

          if (ordersListIndex !== -1) {
            copyOrdersList[ordersListIndex] = {
              ...copyOrdersList[ordersListIndex],
              legs: copyLegsValues,
            };
          }
          return copyOrdersList;
        });

        unsubscribeFromSocket(order);

        subscribeOrdersToSocket([{ ...order, legs: copyLegsValues }]);
      }
    },
    [
      legsValues,
      ordersList,
      strategiesList,
      setStrategiesList,
      setOrdersList,
      setUnderlineDetails,
    ],
  );
  //AG GRID functions and configuration
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  //agg functions must be before
  const greeksAgg = (params) => {
    const values = params.values;
    const allValid = values.every((value) => {
      return value !== undefined && value !== "";
    });
    if (allValid) {
      const sum = values.reduce(
        (acc, val, index) =>
          acc + val * gridRef.current?.props?.rowData[index]?.quantity,
        0,
      );
      // return the aggregate value

      if (!isNaN(sum)) {
        if (
          params.colDef.field !== "VEGA_MID_RT" &&
          params.colDef.field !== "DELTA_MID_RT"
        ) {
          return `${(sum * 100).toFixed(2)}%`;
        } else if (params.colDef.field === "DELTA_MID_RT") {
          return `${sum.toFixed(2)}%`;
        } else {
          return `${sum.toFixed(3)}`;
        }
      }
    }
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "legNum",
      headerName: "",
      valueFormatter: valueFormatterSelector,
      colSpan: (params) => {
        if (params.data?.callPut === undefined) {
          return 5;
        } else {
          return 1;
        }
      },
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
      cellStyle: (params) => {
        if (params.data !== undefined) {
          return {
            color: "#686B76",
            opacity: "1",
          };
        }
      },
    },
    {
      field: "quantity",
      headerName: "Qty",
      minWidth: 70,
      editable: true,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "expiryDay",
      headerName: "Day",
      minWidth: 70,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "expiryMonth",
      headerName: "Month",
      minWidth: 80,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "expiryYear",
      headerName: "Year",
      valueFormatter: valueFormatterSelector,
      minWidth: 70,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "callPut",
      headerName: "P/C",
      valueFormatter: valueFormatterSelector,
      minWidth: 60,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "strike",
      minWidth: 90,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "optionTicker",
      headerName: "Option",
      editable: true,
      minWidth: 190,
      maxWidth: 220,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "futureTickerByLeg",
      headerName: "Future Ticker",
      minWidth: 135,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "legLastPrice",
      headerName: "Future Price",
      minWidth: 70,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },

    {
      field: "bidRef",
      minWidth: 70,
      valueGetter: orderBidRefValueGetter,
      valueFormatter: valueFormatterSelector,
      aggFunc: bidAskRefAgg,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,

      cellStyle: (params) => {
        if (params.data === undefined) {
          return {
            border: "0px solid transparent",
            borderTop: "1px solid #FFA800",
            borderBottom: "1px solid #FFA800",
            borderLeft: "1px solid #FFA800",
            borderRadius: "4px 0 0 4px",
            fontSize: "17px",
            color: "#FFA800",
          };
        }
      },
    },
    {
      field: "askRef",
      minWidth: 70,
      valueGetter: orderAskRefValueGetter,
      valueFormatter: valueFormatterSelector,
      aggFunc: bidAskRefAgg,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
      cellStyle: (params) => {
        if (params.data === undefined) {
          return {
            border: "0px solid transparent",
            borderTop: "1px solid #FFA800",
            borderBottom: "1px solid #FFA800",
            fontSize: "17px",
            color: "#FFA800",
          };
        }
      },
    },
    {
      field: "DELTA_MID_RT",
      headerName: "Delta",
      editable: true,

      minWidth: 90,
      valueFormatter: valueFormatterSelector,
      aggFunc: greeksAgg,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
      cellStyle: (params) => {
        if (params.data === undefined) {
          return {
            border: "0px solid transparent",
            borderTop: "1px solid #FFA800",
            borderRight: "1px solid #FFA800",
            borderBottom: "1px solid #FFA800",
            borderRadius: "0px 4px 4px 0px",
            fontSize: "17px",
            color: "#FFA800",
          };
        }
      },
    },
    {
      field: "midRef",
      minWidth: 100,
      valueGetter: orderMidRefValueGetter,
      valueFormatter: valueFormatterSelector,
      aggFunc: bidAskRefAgg,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
      cellRendererFramework: CustomAnimationRenderer,
    },
    {
      field: "BID_SIZE",
      headerName: "Bid Size",
      minWidth: 80,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "ASK_SIZE",
      headerName: "Ask Size",
      lockPosition: true,
      suppressMovable: true,
      minWidth: 85,
      suppressMenu: true,
    },
    {
      field: "orderBid",
      headerName: "Bid",
      minWidth: 70,
      valueGetter: orderBidValueGetter,
      valueFormatter: valueFormatterSelector,
      aggFunc: bidAskRefAgg,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "orderAsk",
      headerName: "Ask",
      minWidth: 70,
      valueGetter: orderAskValueGetter,
      valueFormatter: valueFormatterSelector,
      aggFunc: bidAskRefAgg,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "VEGA_MID_RT",
      headerName: "Vega",
      minWidth: 60,
      valueFormatter: valueFormatterSelector,
      aggFunc: greeksAgg,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "GAMMA_MID_RT",
      headerName: "Gamma",
      minWidth: 70,
      valueFormatter: valueFormatterSelector,
      aggFunc: greeksAgg,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "RT_OPEN_INTEREST",
      headerName: "Open Interest",
      minWidth: 100,
      maxWidth: 100,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "VOLUME",
      headerName: "Volume",
      minWidth: 70,
      maxWidth: 70,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },

    {
      field: "ASK",
      hide: true,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "BID",
      hide: true,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
    };
  }, []);

  const getRowId = useCallback((params) => {
    return params.data.legNum;
  });
  const getRowClass = (params) => {
    // if (params.node.rowIndex % 2 === 0) {
    //   return "my-shaded-effect";
    // }
  };

  const onCellEditRequest = useCallback(
    (e) => {
      unsubscribeFromSocket(order);
      const rowIndex = e.node.rowIndex;
      const colId = e.column.colId;
      const value = e.value.toUpperCase();

      updateOrder(
        order.id,
        legsValues[rowIndex].legNum,
        rowIndex,
        colId,
        value,
      );
    },
    [legsValues, gridApi],
  );

  const onCellClicked = useCallback((e) => {
    console.log("onCellClicked", e);
    // console.log(
    //   e.column.userProvidedColDef.headerName === "Delta" &&
    //     e.data === undefined,
    // );

    const rowIndex = e.node.rowIndex;
    const colId = e.column.colId;
    // e.colDef.editable = true;
  }, []);

  // this useEffect is updating the columnDef with updated async data to keep the agg footer updated
  useEffect(() => {
    const updateColumnDefs = async (key) => {
      const copyColumnDef = [...columnDefs];
      const newColumnDef = copyColumnDef.map((columnDef) => {
        if (columnDef.field !== key) {
          return columnDef; // Return the column as-is if it's not the one we're updating
        }

        // Update the specific column's properties
        else {
          const updatedColumn = { ...columnDef };

          updatedColumn.aggFunc = (params) =>
            `${order.name} ${order.sector !== "" ? `|  ${order.sector}` : ""} ${
              order.has_adr === "Y" ? ` | ${order.country}` : ""
            } ${order.futureTicker !== null ? ` | ${order.futureTicker}` : ""}`;

          updatedColumn.cellRenderer = (params) => {
            if (!params.node.group) {
              return `Leg ${params.value}`;
            } else {
              const wordToColorFutureTicker =
                order.futureTicker !== null ? `${order.futureTicker}` : null;
              const wordToColorHasAdr =
                order.has_adr === "Y" ? `${order.country}` : null;

              let strWithColor =
                wordToColorFutureTicker !== null || wordToColorHasAdr !== null
                  ? params.value.split(" ").map((word, i) => {
                      if (
                        word === wordToColorFutureTicker ||
                        word === wordToColorHasAdr
                      ) {
                        return (
                          <span
                            key={i}
                            style={{ color: "#FFA800" }}
                            onClick={onMouseEnterFutureTicker}
                          >
                            {wordToColorHasAdr !== null ? `ADR ${word}` : word}
                          </span>
                        );
                      } else {
                        return word + " ";
                      }
                    })
                  : params.value;
              return <div>{strWithColor}</div>;
            }
          };

          return updatedColumn;
        }
      });
      setColumnDefs(newColumnDef);
    };

    if (order.name !== undefined) {
      updateColumnDefs("legNum");
      // updateColumnDefs("expiryYear");
    }
  }, [order, legsValues, order.id]); // Make sure to include all dependencies here

  //update order and legs when being changed outside the component (add / remove order)
  useEffect(() => {
    if (order.isNewTimestamp !== undefined) {
      setIsFlicker(true);
      setTimeout(() => {
        setIsFlicker(false);
        order.isNewTimestamp = undefined;
      }, [5000]);
    }
    setUnderlineDetails((pre) => {
      return { ...order };
    });
    setLegValues(order.legs);
    setColumnDefs((prevColumnDefs) => {
      if (order.legs[0].futureTickerByLeg === null) {
        const index = prevColumnDefs.findIndex(
          (obj) => obj.field === "futureTickerByLeg",
        );
        if (index !== -1) {
          // Create a new array without the items you want to remove
          return [
            ...prevColumnDefs.slice(0, index),
            ...prevColumnDefs.slice(index + 2),
          ];
        }
      } else if (order.legs[0].futureTickerByLeg !== null) {
        setTieList(tieListCreator(order.legs));
      }
      return prevColumnDefs;
    });
  }, [order]);

  // socket handling event listener Bloomberg
  useEffect(() => {
    const handleDerivs = async (message) => {
      if (
        message.data.security !== undefined &&
        message.data.order_id === order.id &&
        message.data.leg_id === undefined
      ) {
        setUnderlineDetails({
          ...underlineDetails,
          lastPrice: message.data.LAST_PRICE,
        });
      } else if (
        message.data.security !== undefined &&
        message.data.order_id === order.id &&
        message.data.leg_id !== undefined &&
        message.data.LAST_PRICE === undefined
      ) {
        const obj = {
          ...message.data,
        };
        gridApi.applyTransactionAsync(
          {
            update: [
              {
                ...obj,
                legLastPrice:
                  underlineDetails.legs[message.data.leg_id].legLastPrice !==
                  undefined
                    ? underlineDetails.legs[message.data.leg_id].legLastPrice
                    : null,
                lastPrice: underlineDetails.lastPrice,
                tie: underlineDetails.tie,
              },
            ],
          },
          (result) => {
            //  console.log("Transaction Result:", result);
          },
        );
      } else if (
        message.data.security !== undefined &&
        message.data.order_id === order.id &&
        message.data.leg_id !== undefined &&
        message.data.LAST_PRICE !== undefined
      ) {
        // const { data } = gridApi.getRowNode(message.data.leg_id + 1);

        setUnderlineDetails((pre) => {
          const legs = [...pre.legs];
          legs[message.data.leg_id].legLastPrice = message.data.LAST_PRICE;
          pre.legs = legs;
          return pre;
        });
      }
    };

    workerInstances?.WebSocketPricesInstance?.addEventListener(
      "message",
      handleDerivs,
    );

    return () => {
      workerInstances?.WebSocketPricesInstance?.removeEventListener(
        "message",
        handleDerivs,
      );
    };
  }, [gridApi, underlineDetails, legsValues, order]);

  // socket handling event listener
  useEffect(() => {
    const handleUpdate = async (message) => {
      switch (message.data.type) {
        case "update_leg":
          const { orderId, legNum, rowIndex, colId, value } =
            message.data.updateBody;
          if (Number(orderId) === Number(order.id)) {
            const copyLegsValues = [...legsValues];
            const copyStrategiesList =
              tab !== "favourites" ? [...strategiesList] : [];

            copyLegsValues.splice(rowIndex, 1, {
              ...legsValues[rowIndex],
              [`${colId}`]: Number(value),
            });
            const index = copyStrategiesList.findIndex(
              (s) => Number(s.id) === Number(orderId),
            );
            if (index !== -1) {
              copyStrategiesList[index] = {
                ...copyStrategiesList[index],
                legs: copyLegsValues,
              };
              setStrategiesList(copyStrategiesList);
            }

            setLegValues([...copyLegsValues]);
            unsubscribeFromSocket(order);

            subscribeOrdersToSocket([{ ...order, legs: copyLegsValues }]);
          }
          break;
        case "update_tie_by_leg":
          updateTieByLeg(message);
          break;
        case "updateOptionTicker":
          {
            const { orderId, legNum, rowIndex, colId, value } =
              message.data.updateBody;
            if (Number(orderId) === Number(order.id)) {
              const copyStrategiesList =
                tab !== "favourites" ? [...strategiesList] : [];

              const copyLegsValues = [...legsValues].map((leg) => {
                const optionTickerArr = leg.optionTicker.split(" ");
                optionTickerArr[0] =
                  optionTickerArr[0].toLowerCase() !== "spxw" &&
                  optionTickerArr[0].toLowerCase() !== "rutw"
                    ? value.split(" ")[0]
                    : `${value.split(" ")[0]} ${value.split(" ")[1]}`;
                return { ...leg, optionTicker: optionTickerArr.join(" ") };
              });

              const index = copyStrategiesList.findIndex(
                (s) => Number(s.id) === Number(orderId),
              );
              if (index !== -1) {
                copyStrategiesList[index] = {
                  ...copyStrategiesList[index],
                  legs: copyLegsValues,
                };
                setStrategiesList(copyStrategiesList);
              }

              setLegValues([...copyLegsValues]);
              unsubscribeFromSocket(order);

              subscribeOrdersToSocket([{ ...order, legs: copyLegsValues }]);
            }
          }
          break;
        case "update_tie":
          updateTie(message);
          break;
        case "derivs_pricer_update_fav":
          const updateFav = () => {
            const { orderId, isFavorite } = message.data;

            // Update strategiesList
            setStrategiesList((prevStrategiesList) => {
              let copyStrategiesList;
              if (tab !== "favourites") {
                copyStrategiesList = [...prevStrategiesList];
              } else {
                // If the tab is 'favourites', you might want to handle it differently
                // For instance, you could filter out non-favorites
                copyStrategiesList = prevStrategiesList.filter(
                  (s) => s.isFavorite,
                );
              }
              const index = copyStrategiesList.findIndex(
                (s) => Number(s.id) === Number(orderId),
              );
              if (index !== -1) {
                copyStrategiesList[index] = {
                  ...copyStrategiesList[index],
                  isFavorite,
                };
              }
              return copyStrategiesList;
            });

            // Update ordersList only if the tab is not 'favourites'
            if (tab !== "favourites") {
              setOrdersList((prevOrdersList) => {
                const copyOrdersList = [...prevOrdersList];
                const ordersListIndex = copyOrdersList.findIndex(
                  (s) => Number(s.id) === Number(orderId),
                );

                if (ordersListIndex !== -1) {
                  copyOrdersList[ordersListIndex] = {
                    ...copyOrdersList[ordersListIndex],
                    isFavorite,
                  };
                }
                return copyOrdersList;
              });
            }

            // Update underlineDetails if necessary
            if (Number(order.id) === Number(orderId)) {
              setUnderlineDetails((prevDetails) => ({
                ...prevDetails,
                isFavorite,
              }));
            }
          };

          updateFav();
          break;
        case "derivs_pricer_status_update":
          const updateStatus = () => {
            const { id, status } = message.data;

            if (Number(order.id) === Number(id)) {
              setUnderlineDetails({ ...underlineDetails, status });
            }
          };
          updateStatus();
          break;
        default:
          break;
      }
    };
    window.addEventListener("message", handleUpdate);

    return () => {
      window.removeEventListener("message", handleUpdate);
    };
  }, [
    gridApi,
    underlineDetails,
    setStrategiesList,
    setOrdersList,
    setUnderlineDetails,
  ]);

  useEffect(() => {
    const handleWindowClose = () => {
      // Handle the onClose event here
      gridRef.current.api.flushAsyncTransactions();
    };

    const handleWindowRefresh = (event) => {
      // Handle the onBeforeUnload event here
      //  event.preventDefault();
      //  return (event.returnValue = "Are you sure you want to leave this page?");
      gridRef.current.api.flushAsyncTransactions();
    };

    // Add event listeners
    window.addEventListener("beforeunload", handleWindowRefresh);
    window.addEventListener("unload", handleWindowClose);

    // Clean up the event listeners when the component unmounts
    return () => {
      gridRef?.current?.api?.flushAsyncTransactions();

      window.removeEventListener("beforeunload", handleWindowRefresh);
      window.removeEventListener("unload", handleWindowClose);
    };
  }, []);
  const onAsyncTransactionsFlushed = useCallback((e) => {
    // console.log(
    //   "========== onAsyncTransactionsFlushed: applied " +
    //     e.results.length +
    //     " transactions",
    // );
  }, []);

  return (
    <Grid
      container
      item
      className={clsx({
        ["slide-in-top"]: isFlicker,
      })}
      xs={12}
      style={{
        // height: height,
        borderRadius: "8px",
        marginBottom: "10px",
        border: "1px solid #686B76",
      }}
    >
      <Grid
        container
        item
        xs={12}
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{
          borderRadius: "8px 8px 0px 0px",
          opacity: "1",
          height: "62px",
        }}
        className={clsx({
          flicker: isFlicker,
        })}
      >
        <Grid
          item
          container
          xs={7}
          sm={7}
          md={9}
          lg={10}
          xl={11}
          className={
            tab === "booking"
              ? classes.tableContainerBookingTab
              : classes.tableContainer
          }
        >
          {isFlicker ? (
            <Grid style={{ alignItems: "center" }}>
              <Typography
                className={`grid-transition ${isFlicker ? "" : "hidden"}`}
                style={{
                  width: "50px",
                  color: "#1CD3FF",
                  textAlign: "center",
                }}
              >
                New
              </Typography>
            </Grid>
          ) : (
            <Grid style={{ alignItems: "center" }}>
              <Typography
                className={`grid-transition ${isFlicker ? "" : "hidden"}`}
                style={{ width: "10px", color: "#1CD3FF", textAlign: "center" }}
              ></Typography>
            </Grid>
          )}

          <Grid item container xs={isFlicker ? 9 : 10}>
            <Grid
              container
              item
              style={{ overflowX: "auto" }}
              sx={{ width: "100%" }}
            >
              <table
                style={{ width: "100%", height: "40px" }}
                id="underline-table"
              >
                <thead>
                  <tr
                    style={{
                      textAlign: "left",
                      // fontFamily: "normal normal normal 17px Inter",
                      letterSpacing: "0px",
                      color: "#686B76",
                      opacity: "1",
                      border: "1px solid #686B76",
                    }}
                    className={clsx({
                      flicker: isFlicker,
                    })}
                  >
                    <th style={{ padding: "0px", width: "10%" }}>Ticker</th>
                    <th style={{ padding: "0px", width: "20%" }}>Strategy</th>
                    {underlineDetails.legs[0].futureTickerByLeg !== null &&
                    tieList !== null ? (
                      tieList?.map((obj) => {
                        return (
                          <th style={{ padding: "0px", width: "15%" }}>
                            {obj.tieHeader}
                          </th>
                        );
                      })
                    ) : (
                      <th style={{ padding: "0px", width: "15%" }}>Tie</th>
                    )}
                    {underlineDetails.legs[0].futureTickerByLeg === null ? (
                      <th style={{ padding: "0px" }}>Last Price</th>
                    ) : null}
                    <th style={{ padding: "0px" }}>Created By</th>
                    <th style={{ padding: "0px" }}>Creation Time</th>
                    {underlineDetails.dividend !== "" ? (
                      <th style={{ padding: "0px" }}>Dividend</th>
                    ) : (
                      <th></th>
                    )}
                    {underlineDetails.earnings !== "" ? (
                      <th style={{ padding: "0px" }}>Earnings</th>
                    ) : (
                      <th></th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  <tr
                    className={clsx({
                      flicker: isFlicker,
                    })}
                    style={{
                      textAlign: "left",
                      font: "normal normal normal 14px Inter",
                      letterSpacing: "0px",
                      color: "white",
                      // fontWeight: "bolder",
                      // opacity: "1",
                      border: "1px solid #686B76",
                    }}
                  >
                    <td style={{ width: "10%" }}>
                      {tab !== "favourites" &&
                      (team === "Admin" || team === "Americas") ? (
                        <MarketMakerPopover
                          ticker={underlineDetails.symbol.toUpperCase()}
                        />
                      ) : (
                        underlineDetails.symbol.toUpperCase()
                      )}
                    </td>
                    <td
                      style={{ width: "20%" }}
                      onMouseEnter={onMouseEnterStrategy}
                      onMouseLeave={onMouseLeaveStrategy}
                    >
                      {underlineDetails.strategy.toUpperCase()}
                      <Popper
                        id={strategyId}
                        open={isStrategyOpen}
                        anchorEl={anchorElStrategy}
                        placement={"bottom-start"}
                        transition
                      >
                        {({ TransitionProps }) => (
                          <Fade {...TransitionProps} timeout={100}>
                            <Grid container item style={{ width: "100px" }}>
                              <Grid item className="x-icon">
                                <IconButton
                                  style={{
                                    backgroundColor: "#3A3C3F",
                                  }}
                                  onClick={copyToClip}
                                >
                                  <Copy />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Fade>
                        )}
                      </Popper>
                    </td>
                    <TieCell
                      style={{ width: "15%" }}
                      ordersList={ordersList}
                      underlineDetails={underlineDetails}
                      tieList={tieList}
                      setTieList={setTieList}
                    />
                    {underlineDetails.legs[0].futureTickerByLeg === null ? (
                      <td>{underlineDetails.lastPrice}</td>
                    ) : null}

                    <td>{underlineDetails.traderName}</td>
                    <td>
                      {new Date(underlineDetails.createdOn).toLocaleTimeString(
                        [],
                        { hour: "2-digit", minute: "2-digit", hour12: false },
                      )}
                    </td>
                    {underlineDetails.dividend !== "" ? (
                      <td style={{ color: "#FF6E57" }}>
                        {underlineDetails.dividend}
                      </td>
                    ) : (
                      <td></td>
                    )}
                    {underlineDetails.earnings !== "" ? (
                      <td style={{ color: "#FF6E57" }}>
                        {underlineDetails.earnings}
                      </td>
                    ) : (
                      <td></td>
                    )}
                  </tr>
                </tbody>
              </table>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          xs={5}
          sm={5}
          md={3}
          lg={2}
          xl={1}
          item
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          className={
            tab === "booking"
              ? classes.iconContainerBookingTab
              : classes.iconContainer
          }
        >
          {tab !== "favourites" && (team === "Admin" || team === "Americas") ? (
            <>
              {order.status.toLowerCase() === "booked" ||
              order.status.toLowerCase() === "deleted & booked" ? (
                <Grid item className="trash-icon">
                  <IconButton
                    color="primary"
                    aria-label="fav-order"
                    onClick={(e) => setOpenConfirmationModal(true)}
                    disabled={order.name === undefined}
                  >
                    <MailOutlineIcon
                      fontSize="large"
                      color="secondary"
                      className={`${classes.starIcon} ${classes.emptyBooked}`}
                    />
                  </IconButton>
                </Grid>
              ) : order.status.toLowerCase().includes("booked & confirmed") ? (
                <Grid item className="trash-icon">
                  <IconButton
                    color="primary"
                    aria-label="fav-order"
                    onClick={(e) => setOpenConfirmationModal(true)}
                  >
                    <EmailIcon
                      fontSize="large"
                      color="secondary"
                      className={`${classes.starIcon} ${classes.fullConfirmed}`}
                    />
                  </IconButton>
                </Grid>
              ) : null}
            </>
          ) : null}
          {tab !== "favourites" && (team === "Admin" || team === "Americas") ? (
            <Grid item className="trash-icon">
              {order.status.toLowerCase().includes("booked") ? (
                <IconButton
                  color="primary"
                  aria-label="fav-order"
                  onClick={(e) => handleClickBookingIcon(e, order)}
                >
                  <Launch
                    fontSize="large"
                    color="secondary"
                    className={`${classes.starIcon} ${classes.fullBooked}`}
                  />
                </IconButton>
              ) : (
                <IconButton
                  color="primary"
                  aria-label="fav-order"
                  onClick={(e) => handleClickBookingIcon(e, order)}
                >
                  <Launch
                    fontSize="large"
                    color="secondary"
                    className={`${classes.starIcon} ${classes.emptyBooked}`}
                  />
                </IconButton>
              )}
            </Grid>
          ) : null}
          {tab !== "booking" ? (
            <Grid item className="trash-icon">
              {order.isFavorite ? (
                <IconButton
                  color="primary"
                  aria-label="fav-order"
                  onClick={(e) => favouritesHandler(order, "remove")}
                >
                  <StarRoundedIcon
                    fontSize="large"
                    color="secondary"
                    className={`${classes.starIcon} ${classes.fullStar}`}
                  />
                </IconButton>
              ) : (
                <IconButton
                  color="primary"
                  aria-label="fav-order"
                  onClick={(e) => favouritesHandler(order, "add")}
                >
                  <StarOutlineRoundedIcon
                    fontSize="large"
                    color="secondary"
                    className={`${classes.starIcon} ${classes.fullStar}`}
                  />
                </IconButton>
              )}
            </Grid>
          ) : null}

          {tab !== "favourites" && tab !== "booking" ? (
            <>
              <Grid item className="trash-icon">
                <IconButton
                  color="primary"
                  aria-label="delete-order"
                  onClick={(e) =>
                    deleteOrder({
                      id: order.id,
                      status: order.status,
                    })
                  }
                >
                  <Trash />
                </IconButton>
              </Grid>
              <Grid item className="x-icon">
                <IconButton
                  style={{
                    backgroundColor: "#3A3C3F",
                  }}
                  onClick={(e) => handleIgnoreOrder(order)}
                >
                  <Ignore />
                </IconButton>
              </Grid>
            </>
          ) : null}
        </Grid>
      </Grid>
      {rowData.length > 0 ? (
        <Grid
          className="ag-theme-balham-dark ag-grid-custom"
          style={gridStyle}
          item
        >
          <AgGridReact
            ref={gridRef}
            rowData={legsValues}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            keepDetailRows={true}
            domLayout={"autoHeight"}
            onGridReady={onGridReady}
            animateRows={true}
            // autoGroupColumnDef={autoGroupColumnDef}
            groupIncludeFooter={true}
            groupIncludeTotalFooter={true}
            onRowDataUpdated
            deltaRowDataMode={true}
            getRowClass={getRowClass}
            readOnlyEdit={true}
            singleClickEdit={true}
            onCellEditRequest={onCellEditRequest}
            // masterDetailAutoHeight={true}
            // onFirstDataRendered={onFirstDataRendered}
            // asyncTransactionWaitMillis={4000}
            onCellClicked={onCellClicked} // toggle the row expansion            // onFirstDataRendered={onFirstDataRendered}
            // detailCellRendererParams={detailCellRendererParams}
            onAsyncTransactionsFlushed={onAsyncTransactionsFlushed}
            suppressHorizontalScroll={true} // hides horizontal scroll (side effect : we were getting extra empty row when changing screen width)
          ></AgGridReact>
          <Popper
            id={futureTickerId}
            open={isFutureTickerOpen}
            anchorEl={anchorElFutureTicker}
            placement={"bottom-start"}
            transition
            onMouseLeave={onMouseLeaveFutureTicker}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={100}>
                <Grid container item style={{ width: "100px" }}>
                  <Grid item className="x-icon">
                    <IconButton
                      style={{
                        backgroundColor: "#3A3C3F",
                      }}
                      onClick={() => {
                        setAnchorElFutureTicker(null);
                        handleOpenModal();
                      }}
                    >
                      <EditIcon style={{ color: "#2692ff" }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Fade>
            )}
          </Popper>
        </Grid>
      ) : null}{" "}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isModalOpen}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isModalOpen}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={classes.paperModal}
            spacing={2}
            xl={2}
            lg={3}
            sm={5}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              item
              className="x-icon"
              xs={12}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                item
                xs={11}
              >
                <Typography className={classes.modalTypography}>
                  Future Ticker Update
                </Typography>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                item
                xs={1}
              >
                <IconButton
                  style={{
                    backgroundColor: "#3A3C3F",
                  }}
                  onClick={handleCloseModal}
                >
                  <Ignore />
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              item
              xs={12}
            >
              <input
                type={"text"}
                onChange={handleFutureTickerChange}
                placeholder="Future Ticker"
                autoFocus
                onKeyPress={(e) => {
                  if (e.key === "Enter" && newFutureTicker !== null) {
                    handleUpdateFutureTicker();
                  }
                }}
              />
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              item
              xs={12}
            >
              <Grid item xs={5}>
                <Button
                  disabled={newFutureTicker === null}
                  className={classes.modalBtn}
                  onClick={handleUpdateFutureTicker}
                >
                  Update
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Button className={classes.modalBtn} onClick={handleCloseModal}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Fade>
      </Modal>
      <BookingDialog
        open={openBookingModal}
        handleClose={closeBookingModal}
        order={order}
        gridApi={gridApi}
        setOpenBookingModal={setOpenBookingModal}
        setOpenConfirmationModal={setOpenConfirmationModal}
        setOpenEConfoModal={setOpenEConfoModal}
      />
      <ConfirmationDialog
        open={openConfirmationModal}
        handleClose={closeConfirmationModal}
        order={order}
        setOpenBookingModal={setOpenBookingModal}
        setOpenConfirmationModal={setOpenConfirmationModal}
        setOpenEConfoModal={setOpenEConfoModal}
      />
      <EConfoDialog
        open={openEConfoModal}
        handleClose={closeEConfoModal}
        order={order}
        setOpenBookingModal={setOpenBookingModal}
        setOpenConfirmationModal={setOpenConfirmationModal}
        setOpenEConfoModal={setOpenEConfoModal}
      />
    </Grid>
  );
};
