import React, { useEffect, useState } from "react";
import {
  Dialog,
  Grid,
  Typography,
  IconButton,
  Divider,
  FormControl,
  TextField,
} from "@mui/material";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import { useStyles } from "../../../Styles/fiStyles";
import { useDispatch, useSelector } from "react-redux";
import * as fiActions from "../../../store/fi/fiSlice";

export const SelectComponent = ({
  componentKey,
  label,
  value,
  setValue,
  tradersList = [],
  index,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const tradersSplitPnlArr = useSelector(
    (state) => state.fiSlice.tradersSplitPnlArr,
  );
  const [inputValue, setInputValue] = useState("");
  const [localValue, setLocalValue] = useState(
    (componentKey === "pnlAtt" && value) || (componentKey === "traders" && ""),
  );
  const pnlAttSplitValues = [1, 2, 3, 4, 5];
  const autocompleteOptions =
    (componentKey === "pnlAtt" &&
      pnlAttSplitValues.map((v) => {
        return `${v}`;
      })) ||
    (componentKey === "traders" &&
      tradersList.map((t) => {
        return `${t.value}`;
      }));
  useEffect(() => {
    componentKey === "pnlAtt" && setInputValue(value);
    componentKey === "pnlAtt" && setLocalValue(value);
    componentKey === "traders" && setInputValue("");
    componentKey === "traders" && setLocalValue("");
  }, [value]);

  useEffect(() => {
    if (tradersSplitPnlArr.length > 0) {
      if (componentKey === "traders") {
        const splitIndex = tradersSplitPnlArr.findIndex(
          (split) => Number(split.id) === Number(index),
        );
        if (splitIndex !== -1) {
          const traderDetails = tradersList.filter(
            (t) =>
              Number(t.id) === Number(tradersSplitPnlArr[splitIndex].traderId),
          );
          if (traderDetails.length > 0) {
            setLocalValue(traderDetails[0].value);
            setInputValue(traderDetails[0].value);
          }
        }
      }
    }
  }, [tradersList]);

  return (
    <Grid>
      <Autocomplete
        freeSolo
        clearOnEscape
        autoHighlight
        selectOnFocus
        value={localValue}
        onChange={(event, newValue) => {
          if (componentKey === "pnlAtt") {
            setValue(newValue === null ? "1" : newValue);
            setLocalValue(newValue === null ? "1" : newValue);
          } else if (componentKey === "traders") {
            dispatch(
              fiActions.handleSplitTradersPnl({
                index,
                newValue,
                key: "traderId",
                tradersList,
              }),
            );
            newValue === null ? setLocalValue("") : setLocalValue(newValue);
          }
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          if (componentKey === "pnlAtt") {
            setInputValue(newInputValue === null ? "1" : newInputValue);
          } else if (componentKey === "traders") {
            setInputValue(newInputValue === null ? "" : newInputValue);
          }
        }}
        options={autocompleteOptions}
        renderInput={(params) => (
          <TextField
            className={classes.pnlAttSelect}
            {...params}
            label={label}
            variant="outlined"
            SelectProps={{
              native: true,
              maxwidth: "10px",
              open: true,
            }}
            InputLabelProps={{}}
          />
        )}
      />
    </Grid>
  );
};
