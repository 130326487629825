import { Grid,Typography} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { createQuoteBookDict } from "../../../../../services/rfqWorkspace/createQuoteBookDict";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import * as rfqSlice from "../../../../../store/rfq/rfqSlice";
import { END_POINT } from "../../../../../utils";
import { useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";

const DealStatsPrice = ({dealStatsData}) => {
  const dispatch = useDispatch();
  let tableData = useSelector((state) => state.rfqSlice.tableData);
  const rfqQuoteParameters = useSelector(
    (state) => state.rfqAggregatorSlice.RfqQuoteParameters
  );
  const classes = useStyles();
  const updatesFees = async (pb) => {
   
    const token = sessionStorage.getItem("token");
    const rfqId = sessionStorage.getItem("rfqId");
    let copyDealStatsData = JSON.parse(JSON.stringify(dealStatsData));
    copyDealStatsData.pb = pb;
    let jtableDict = await createQuoteBookDict();
    setchecked(!checked);
    try {
          let response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}` +
              END_POINT.DEAL_STATS +
              "/" +
              END_POINT.PB_BY_USER +
              "/" +
              rfqId,
            { pb },
            { headers: { Authorization: token } }
          );

      let getDealStats = await axios.post(
        `${process.env.REACT_APP_PYTON}` + `get_deal_stats`,
        {
          quote_type: rfqQuoteParameters.quote_type,
          rfq_table_data: jtableDict,
          quote_parameters: rfqQuoteParameters,
          deal_stats: copyDealStatsData,
          order_book: tableData,
        }
      );
     
      dispatch(rfqSlice.updateDealStatsData(getDealStats.data));
    } catch (error) {
      console.log(error)
    }
  };
  const [checked, setchecked] = useState(false);
    return (
        <Grid
          item
          xs={12}
          container
          justifyContent="space-evenly"
          style={{ paddingTop: "15px", paddingLeft: 10 }}
        >
          <Grid item xs={12} container>
            <Grid item container>
              <Grid item>
                <Typography className={classes.dealStateInfo}>
                  PRICING LEG NOTIONAL:
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.dealStateInfoData}>
                  {dealStatsData.pricing_leg_notional}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item>
                <Typography className={classes.dealStateInfo}>
                  BPS VALUE:
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.dealStateInfoData}>
                  {dealStatsData.bps_value}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container>
          <Grid item>
            <Typography className={classes.dealStateInfo}>FEE'S:</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.dealStateInfoData}>
              {dealStatsData.fees}
            </Typography>
          </Grid>
          <Grid item style={{display:"flex",justifyContent:"space-between"}}>
            <Grid item xs={2}>
              <Typography style={{marginLeft:"10px"}} className={classes.dealStateInfo}>RBS</Typography>
            </Grid>
            <Grid item xs={2} style={{marginLeft:"10px",marginTop:"3px"}}>
            
              <FormControlLabel
                onChange={() => updatesFees(checked ? "HSBC" : "RBS")}
                control={<IOSSwitch checked ={ dealStatsData.pb === "RBS" ? false : true} />}
                label=""
              />
            </Grid>
            <Grid item xs={2}style={{marginLeft:"-11px"}}>
              <Typography className={classes.dealStateInfo}>HSBC</Typography>
            </Grid>
          </Grid>
        </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item container>
              <Grid item>
                <Typography className={classes.dealStateInfo}>
                  FEE'S % NOTIONAL:
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.dealStateInfoData}>
                  {dealStatsData.fees_pct_notional}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item>
                <Typography className={classes.dealStateInfo}>
                  RAW BID PNL:
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.dealStateInfoData}>
                  {dealStatsData.raw_bid_pnl}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item>
                <Typography className={classes.dealStateInfo}>
                  RAW OFFER PNL:
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.dealStateInfoData}>
                  {dealStatsData.raw_offer_pnl}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
}
export default DealStatsPrice
export const useStyles = makeStyles(() => ({
    rfqModal: {
      border: "1px solid #686B76",
      borderRadius: "8px 8px 0px 0px",
      // display: "flex",
      flexDirection: "column",
    },
    rfqEmptyModal: {
      border: "1px solid #686B76",
      borderRadius: "8px 8px 0px 0px",
      flexDirection: "column",
    },
    rfqodalHeader: {
      color: "#FFFFFF",
      borderBottom: "1px solid #686B76",
      borderRadius: "8px 8px 0px 0px",
      font: "normal normal normal 20px/24px Inter",
      height: "43px",
      justifyContent: "space-between",
    },
    header: {
      marginTop: "15px",
      marginLeft: "15px",
    },
    copyIcon: {
      marginTop: "15px",
      marginRight: "10px",
    },
    filterIcon: {
      marginTop: "15px",
    },
    input: {
      border: "1px solid #686B76",
      borderRadius: "8px",
      opacity: 1,
      width: "89px !important",
      height: "40px",
      color: "white",
      font: "normal normal normal 14px/20px monospace",
    },
    bid: {
      textAlign: "center",
      fontSize: "1rem",
      marginTop: "2%",
      color: "#86FFF4",
      font: "normal normal normal 14px/20px monospace",
    },
    offer: {
      textAlign: "center",
      fontSize: "1rem",
      marginTop: "2%",
      color: " #FFC5CD",
      font: "normal normal normal 14px/20px monospace",
    },
    text: {
      marginTop: "revert",
      textAlign: "center",
      color: "#686B76",
    },
    dealStateInfo: {
      fontSize: "1.05rem",
      font: "normal normal normal 14px/20px monospace",
      color: "#989EA8",
    },
    dealStateInfoData: {
      fontSize: "1.05rem",
      font: "normal normal normal 14px/20px monospace",
      color: "#ffffff",
    },
    copybuttonDisable: {
      filter: "invert(0.5) sepia(1) hue-rotate(200deg)",
    },
  }));
  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 29,
    height: 13,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.mode === "dark" ? "#86FFF4" : "#86FFF4",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#86FFF4",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 10,
      height: 10,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#FFC5CD" : "#FFC5CD",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));