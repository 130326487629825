import SendIcon from "@material-ui/icons/Send";
import {
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { ReactComponent as AttachIcon } from "../../../assets/attach.svg";
import SuccessFailAnimation from "../reusableComponents/SuccessFailAnimation";
import { StyledTextField, SubjectBox, CommentBox } from "./FiEmailConfo.styles";
import { useSelector } from "react-redux";
function FiEmailConfoView({
  subject,
  getFiPdf,
  sendEmail,
  sendStatus,
  editSubject,
  chosenTrade,
  traderComment,
  startSendEmail,
  generateComment,
  ccEmailAddresses,
  toEmailAddresses,
  handleEmailAddressAdd,
}) {
  const customeView = useMediaQuery("(width:1280px)");
  let fiEmail = useSelector((state) => state?.emailSlice?.fiEmail);
  console.log('fiEmail: ', fiEmail);

  return (
    <Grid item xs={11} style={{ paddingTop: 10 }}>
      <Grid container>
        <Grid item>
          <IconButton
            disabled={startSendEmail}
            onClick={sendEmail}
            style={{
              height: 80,
              width: 68,
              backgroundColor: "#2692ff",
              color: "#ffffff",
              borderRadius: 4,
            }}
          >
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                {startSendEmail ? (
                  <SuccessFailAnimation
                    padding={20}
                    status={sendStatus}
                    loaderClass={"loader"}
                  />
                ) : (
                  <SendIcon />
                )}
              </Grid>
              <Grid item style={{ marginTop: startSendEmail ? 50 : 0 }}>
                {sendStatus !== "start" ? (
                  <Typography style={{ fontSize: "1.3125rem" }}>
                    {!startSendEmail
                      ? "Send"
                      : sendStatus === "success"
                      ? "Sent"
                      : sendStatus === "fail"
                      ? "Failed"
                      : "Send"}
                  </Typography>
                ) : (
                  <div className="sendingAnim">Sending</div>
                  // <HorizontalLoading title="Sending" styling="sendingAnim"/>
                )}
              </Grid>
            </Grid>
          </IconButton>
        </Grid>
        <Grid
          item
          container
          xs={10}
          style={{ marginLeft: 10, alignContent: "space-between" }}
        >
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <Grid
                item
                style={{
                  border: "1px solid #E1E2E7",
                  borderRadius: 4,
                  padding: "4px 10px",
                }}
              >
                <Typography>To: </Typography>
              </Grid>
              <Grid item xs={11}>
                <StyledTextField
                  disabled={startSendEmail}
                  value={toEmailAddresses?.join(";")}
                  onChange={(event) => handleEmailAddressAdd("to", event)}
                  style={{ marginLeft: 10, marginTop: 5 }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: "10px" }}>
            <Grid item container xs={12}>
              <Grid
                item
                style={{
                  border: "1px solid #E1E2E7",
                  borderRadius: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 38,
                }}
              >
                <Typography>CC: </Typography>
              </Grid>
              <Grid item xs={11}>
                <StyledTextField
                  disabled={startSendEmail}
                  value={ccEmailAddresses?.join(";")}
                  style={{ marginLeft: 10, marginTop: 5 }}
                  onChange={(event) => handleEmailAddressAdd("cc", event)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container xs={12} style={{ paddingTop: 20 }}>
            <Grid item container alignItems="center">
              <Grid item style={{ paddingRight: "21px" }}>
                <Typography style={{ color: "#686B76", textAlign: "center" }}>
                  Subject:
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <SubjectBox
                  disabled={startSendEmail}
                  value={subject === undefined ? fiEmail?.subject : subject}
                  onChange={(e) => editSubject(e)}
                  id="commentBox"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              style={{ marginTop: "10px" }}
              alignItems="center"
            >
              <Grid item style={{ paddingRight: "9px" }}>
                <Typography style={{ color: "#686B76", textAlign: "center" }}>
                  Comment:
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <CommentBox
                  disabled={startSendEmail}
                  id="commentBox"
                  maxRows="5"
                  onChange={(e) => generateComment(e)}
                  multiline={true}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              style={{
                // width: "922px",
                marginTop: "10px",
                padding: "9px",
                backgroundColor: "#ECECEF",
                borderRadius: "4px",
              }}
              alignItems="center"
            >
              <Grid item>
                <AttachIcon />
              </Grid>
              <Grid item style={{ marginLeft: "10px" }}>
                <Typography
                  onClick={getFiPdf}
                  style={{
                    textAlign: "left",
                    textDecoration: "underline",
                    font: "normal normal normal 12px/15px Inter",
                    letterSpacing: "0px",
                    color: "#000000",
                    opacity: 1,
                    cursor: "pointer",
                  }}
                >
                  { chosenTrade? 
                  `Makor_Confirmation_
                  ${chosenTrade?.client?.firm_name}_
                  ${chosenTrade?.trade?.trade_date}_
                  ${chosenTrade?.trade?.isin}_
                  ${chosenTrade?.trade?.quantity}.pdf` : `${fiEmail?.subject}.pdf`}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider
                style={{
                  backgroundColor: "#bababa",
                  marginTop: 10,
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              id="emailWrapper"
              style={{
                marginTop: 15,
                minHeight: customeView ? "411px" : "500px",
                maxHeight: customeView ? "411px" : "500px",
                minWidth: "80%",
                position: "relative",
                zIndex: 1,
                overflow: "auto",
                paddingRight: 5,
              }}
              className="emailToSend"
            >
              <div
                className="fiEmailPreview top"
                dangerouslySetInnerHTML={{
                  __html: fiEmail?.topFi,
                }}
              />
              {traderComment && (
                <div className="traderComment">
                  <table className="fiTable">
                    <tr>
                      <td>
                        <pre>{traderComment}</pre>
                      </td>
                    </tr>
                  </table>
                </div>
              )}
              <div
                className="fiEmailPreview"
                style={{ position: "relative" }}
                dangerouslySetInnerHTML={{
                  __html: fiEmail?.bottomFi,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default FiEmailConfoView;
