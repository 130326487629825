import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Grid, IconButton, Popper, Fade } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import workerInstances from "../../../services/index";
import "./style.css";
import clsx from "clsx";
import CustomAnimationRenderer from "./custom renderer/CustomAnimationRenderer";
import axios from "axios";
import { END_POINT } from "../../../utils";
import { ReactComponent as Trash } from "../../../assets/trash.svg";
import { ReactComponent as Ignore } from "../../../assets/X.svg";

export const EquityTableByOrder = ({ order, handleIgnoreOrder }) => {
  const gridRef = useRef();
  const gridStyle = useMemo(
    () => ({
      width: "1270px",
      scrollbarWidth: "thin",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    [],
  );
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [spotCurrencyCode, setSpotCurrencyCode] = useState(null);
  const [spotCurrency, setSpotCurrency] = useState(null);
  const [rowData, setRowData] = useState([
    { ...order, spotCurrencyCode, spotCurrency },
  ]);
  const [hover, setHover] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };
  const getRowId = useCallback((params) => {
    return params.data.id;
  });

  const valueGetterDelta = (params) => {
    let calculation = "";
    const field = "LAST";
    const tickerSide =
      params.data.tickerSide === "false" || params.data.tickerSide === false
        ? false
        : params.data.tickerSide === "true" || params.data.tickerSide === true
        ? true
        : null;
    const spotCurrency =
      params !== undefined &&
      params.data !== undefined &&
      params?.data?.spotCurrencyCode !== undefined &&
      params?.data?.spotCurrencyCode !== null
        ? params.data.spotCurrency
        : null;
    if (
      (field === "LAST" || field === "ASK" || field === "BID") &&
      params.data[`${field}1`] !== undefined &&
      params.data[`${field}2`] !== undefined &&
      params.data[`${field}1`] !== "" &&
      params.data[`${field}2`] !== ""
    ) {
      const price1 =
        (spotCurrency !== undefined &&
        spotCurrency !== null &&
        tickerSide === false
          ? params.data[`${field}1`]
          : spotCurrency !== undefined &&
            spotCurrency !== null &&
            tickerSide === true
          ? params.data[`${field}1`] / spotCurrency
          : params.data[`${field}1`]) /
        (params.data.currency1 === "GBp" ? 100 : 1);

      const price2 =
        (spotCurrency !== undefined &&
        spotCurrency !== null &&
        tickerSide === false
          ? params.data[`${field}2`] / spotCurrency
          : spotCurrency !== undefined &&
            spotCurrency !== null &&
            tickerSide === true
          ? params.data[`${field}2`]
          : params.data[`${field}2`]) /
        (params.data.currency2 === "GBp" ? 100 : 1);

      switch (params.data.priceConvention) {
        case "Spread":
          params.data.flip
            ? (calculation =
                price1 * params.data.weight1 +
                params.data.offset1 -
                (price2 * params.data.weight2 + params.data.offset2))
            : (calculation =
                price2 * params.data.weight2 +
                params.data.offset2 -
                (price1 * params.data.weight1 + params.data.offset1));
          break;
        case "Spread%":
          params.data.flip
            ? (calculation =
                (100 *
                  (price1 * params.data.weight1 +
                    params.data.offset1 -
                    (price2 * params.data.weight2 + params.data.offset2))) /
                price2)
            : (calculation =
                (100 *
                  (price2 * params.data.weight2 +
                    params.data.offset2 -
                    (price1 * params.data.weight1 + params.data.offset1))) /
                price1);
          break;
        case "Ratio":
          params.data.flip
            ? (calculation =
                (price1 * params.data.weight1 + params.data.offset1) /
                (price2 * params.data.weight2 + params.data.offset2))
            : (calculation =
                (price2 * params.data.weight2 + params.data.offset2) /
                (price1 * params.data.weight1 + params.data.offset1));
          break;
        case "Merger":
          calculation =
            price2 * params.data.weight2 + params.data.cash - price1;
          break;
        case "Merger%":
          calculation =
            (100 * (price2 * params.data.weight2 + params.data.cash - price1)) /
            price1;
          break;

        default:
          break;
      }
      if (
        calculation !== undefined &&
        calculation !== "" &&
        params.data.limit_ !== "" &&
        params.data.limit_ !== undefined &&
        params.data.limit_ !== null
      ) {
        const delta =
          ((Number(params.data.limit_) - Number(calculation)) * 100) /
          Number(params.data.limit_);

        return delta.toFixed(4);
      } else {
        return "";
      }
    }
  };

  const strategyValueGetter = (params) => {
    return `${params.data.ticker1
      .replace(" ", "")
      .toUpperCase()}_${params.data.ticker2
      .replace(" ", "")
      .toUpperCase()}_${params.data.priceConvention
      .replace(" ", "")
      .toUpperCase()}`;
  };
  const currencyValueGetter = (params) => {
    return `${
      params.data.tickerSide === "false" || params.data.tickerSide === false
        ? params.data.currency1
        : params.data.tickerSide === "true" || params.data.tickerSide === true
        ? params.data.currency2
        : null
    }`;
  };

  const valueGetterByFormula = useCallback(
    (params) => {
      let calculation = "";
      const field = params.colDef.field;
      const tickerSide =
        params.data.tickerSide === "false" || params.data.tickerSide === false
          ? false
          : params.data.tickerSide === "true" || params.data.tickerSide === true
          ? true
          : null;
      const spotCurrency =
        params !== undefined &&
        params.data !== undefined &&
        params?.data?.spotCurrencyCode !== undefined &&
        params?.data?.spotCurrencyCode !== null
          ? params.data.spotCurrency
          : null;

      const calculationByFormula = (price1, price2) => {
        let calculation;
        switch (params.data.priceConvention) {
          case "Spread":
            params.data.flip
              ? (calculation =
                  price1 * params.data.weight1 +
                  params.data.offset1 -
                  (price2 * params.data.weight2 + params.data.offset2))
              : (calculation =
                  price2 * params.data.weight2 +
                  params.data.offset2 -
                  (price1 * params.data.weight1 + params.data.offset1));
            break;
          case "Spread%":
            params.data.flip
              ? (calculation =
                  (100 *
                    (price1 * params.data.weight1 +
                      params.data.offset1 -
                      (price2 * params.data.weight2 + params.data.offset2))) /
                  price2)
              : (calculation =
                  (100 *
                    (price2 * params.data.weight2 +
                      params.data.offset2 -
                      (price1 * params.data.weight1 + params.data.offset1))) /
                  price1);
            break;
          case "Ratio":
            params.data.flip
              ? (calculation =
                  (price1 * params.data.weight1 + params.data.offset1) /
                  (price2 * params.data.weight2 + params.data.offset2))
              : (calculation =
                  (price2 * params.data.weight2 + params.data.offset2) /
                  (price1 * params.data.weight1 + params.data.offset1));
            break;
          case "Merger":
            calculation =
              price2 * params.data.weight2 + params.data.cash - price1;
            break;
          case "Merger%":
            calculation =
              (100 *
                (price2 * params.data.weight2 + params.data.cash - price1)) /
              price1;
            break;

          default:
            break;
        }
        return calculation;
      };
      if (
        (field === "LAST" || field === "BID" || field === "ASK") &&
        params.data[`${field}1`] !== undefined &&
        params.data[`${field}2`] !== undefined &&
        params.data[`${field}1`] !== "" &&
        params.data[`${field}2`] !== ""
      ) {
        const price1 =
          (spotCurrency !== undefined &&
          spotCurrency !== null &&
          tickerSide === false
            ? params.data[`${field}1`]
            : spotCurrency !== undefined &&
              spotCurrency !== null &&
              tickerSide === true
            ? params.data[`${field}1`] / spotCurrency
            : params.data[`${field}1`]) /
          (params.data.currency1 === "GBp" ? 100 : 1);

        const price2 =
          (spotCurrency !== undefined &&
          spotCurrency !== null &&
          tickerSide === false
            ? params.data[`${field}2`] / spotCurrency
            : spotCurrency !== undefined &&
              spotCurrency !== null &&
              tickerSide === true
            ? params.data[`${field}2`]
            : params.data[`${field}2`]) /
          (params.data.currency2 === "GBp" ? 100 : 1);

        calculation = calculationByFormula(price1, price2);
      } else if (
        field === "askBid" &&
        params.data.ASK1 !== undefined &&
        params.data.BID2 !== undefined &&
        params.data.ASK1 !== "" &&
        params.data.BID2 !== ""
      ) {
        const price1 =
          (spotCurrency !== undefined &&
          spotCurrency !== null &&
          tickerSide === false
            ? params.data.ASK1
            : spotCurrency !== undefined &&
              spotCurrency !== null &&
              tickerSide === true
            ? params.data.ASK1 / spotCurrency
            : params.data.ASK1) / (params.data.currency1 === "GBp" ? 100 : 1);

        const price2 =
          (spotCurrency !== undefined &&
          spotCurrency !== null &&
          tickerSide === false
            ? params.data.BID2 / spotCurrency
            : spotCurrency !== undefined &&
              spotCurrency !== null &&
              tickerSide === true
            ? params.data.BID2
            : params.data.BID2) / (params.data.currency2 === "GBp" ? 100 : 1);

        calculation = calculationByFormula(price1, price2);
      } else if (
        field === "bidAsk" &&
        params.data.BID1 !== undefined &&
        params.data.ASK2 !== undefined &&
        params.data.BID1 !== "" &&
        params.data.ASK2 !== ""
      ) {
        const price1 =
          (spotCurrency !== undefined &&
          spotCurrency !== null &&
          tickerSide === false
            ? params.data.BID1
            : spotCurrency !== undefined &&
              spotCurrency !== null &&
              tickerSide === true
            ? params.data.BID1 / spotCurrency
            : params.data.BID1) / (params.data.currency1 === "GBp" ? 100 : 1);

        const price2 =
          (spotCurrency !== undefined &&
          spotCurrency !== null &&
          tickerSide === false
            ? params.data.ASK2 / spotCurrency
            : spotCurrency !== undefined &&
              spotCurrency !== null &&
              tickerSide === true
            ? params.data.ASK2
            : params.data.ASK2) / (params.data.currency2 === "GBp" ? 100 : 1);

        calculation = calculationByFormula(price1, price2);
      }
      if (calculation !== undefined && calculation !== "") {
        return calculation.toFixed(4);
      } else {
        return;
      }
    },
    [spotCurrencyCode],
  );
  const dateFormatter = (params) => {
    let timestamp = params.value;
    let date = new Date(timestamp);

    let year = date.getFullYear();
    let monthIndex = date.getMonth(); // getMonth() is zero-based
    let day = date.getDate();

    let monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let month = monthNames[monthIndex];

    // Pad day with leading zeros if necessary
    if (day < 10) day = "0" + day;

    let formattedDate = day + "-" + month + "-" + year;

    return formattedDate;
  };
  const timeFormatter = (params) => {
    return new Date(params.value).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  };

  //ag grid column def
  const [columnDefs, setColumnDefs] = useState([
    {
      field: "priceConvention",
      hide: true,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "strategy",
      maxWidth: 180,
      valueGetter: strategyValueGetter,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "weight1",
      headerName: "W1",
      maxWidth: 50,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
      hide: true,
    },
    {
      field: "offset1",
      headerName: "O1",
      maxWidth: 50,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
      hide: true,
    },

    {
      field: "weight2",
      headerName: "W2",
      maxWidth: 50,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
      hide: true,
    },
    {
      field: "offset2",
      headerName: "O2",
      maxWidth: 50,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
      hide: true,
    },
    {
      field: "cash",
      maxWidth: 60,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
      hide: true,
    },
    {
      field: "limit_",
      maxWidth: 60,
      headerName: "Limit",
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "currency",
      valueGetter: currencyValueGetter,
      valueFormatter: (params) => {
        return params.value.toUpperCase();
      },
      maxWidth: 80,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "LAST",
      maxWidth: 110,
      valueGetter: valueGetterByFormula,
      cellRendererFramework: CustomAnimationRenderer,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "LAST1",
      maxWidth: 70,
      hide: true,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "LAST2",
      maxWidth: 60,
      hide: true,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "delta%",
      headerName: "DELTA%",
      maxWidth: 120,
      valueGetter: valueGetterDelta,
      cellRendererFramework: CustomAnimationRenderer,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "askBid",
      headerName: "ASK/BID",

      maxWidth: 110,
      valueGetter: valueGetterByFormula,
      cellRendererFramework: CustomAnimationRenderer,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "BID",
      headerName: "BID/BID",

      maxWidth: 110,
      valueGetter: valueGetterByFormula,
      cellRendererFramework: CustomAnimationRenderer,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },

    {
      field: "BID1",
      maxWidth: 60,
      hide: true,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "BID2",
      maxWidth: 60,
      hide: true,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "ASK",
      headerName: "ASK/ASK",
      maxWidth: 110,
      valueGetter: valueGetterByFormula,
      cellRendererFramework: CustomAnimationRenderer,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "ASK1",
      maxWidth: 60,
      hide: true,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "ASK2",
      maxWidth: 60,
      hide: true,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "bidAsk",
      headerName: "BID/ASK",
      maxWidth: 110,
      valueGetter: valueGetterByFormula,
      cellRendererFramework: CustomAnimationRenderer,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "createdOn",
      headerName: "DATE",
      valueFormatter: dateFormatter,
      maxWidth: 110,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "createdOn",
      headerName: "TIME",
      valueFormatter: timeFormatter,
      maxWidth: 60,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "comment",
      headerName: "COMMENT",
      // valueFormatter: timeFormatter,
      maxWidth: 100,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
  ]);

  useEffect(() => {
    const handleDerivs = async (message) => {
      if (
        message.data.security !== undefined &&
        message.data.order_id === order.id
      ) {
        const obj = {
          ...message.data,
        };

        //       // console.log(gridRef.current);
        if (message.data.side === "a") {
          gridApi?.applyTransaction({
            update: [
              {
                ...gridRef.current.api.getRowNode(order.id).data,
                id: order.id,
                ASK1: obj.ASK,
                BID1: obj.BID,
                LAST1: obj.LAST_PRICE,
              },
            ],
          });
        } else if (message.data.side === "b") {
          gridApi?.applyTransaction({
            update: [
              {
                ...gridRef.current.api.getRowNode(order.id).data,
                id: order.id,
                ASK2: obj.ASK,
                BID2: obj.BID,
                LAST2: obj.LAST_PRICE,
                spotCurrency,
                spotCurrencyCode,
              },
            ],
          });
        }
      }
    };

    workerInstances?.WebSocketPricesInstance?.addEventListener(
      "message",
      handleDerivs,
    );

    return () => {
      workerInstances?.WebSocketPricesInstance?.removeEventListener(
        "message",
        handleDerivs,
      );
    };
  }, [gridApi, order, gridRef, spotCurrency, spotCurrencyCode]);

  useEffect(() => {
    if (order.isNew !== undefined && order.isNew === true) {
      setTimeout(() => {
        order.isNew = false;
      }, [10000]);
    }
    if (order.spotCurrencyCode !== null) {
      setSpotCurrencyCode(order.spotCurrencyCode);
      handleCurrencySpotCode(order.spotCurrencyCode, setSpotCurrency);
    }
    //update order and legs when being changed outside the component (add / remove order)
    setRowData([{ ...order }]);
  }, [order]);
  const open = Boolean(anchorEl);
  const id = open ? `popper-${order.id}` : undefined;
  const handleMouseEnter = (event) => {
    setHover(true);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleMouseLeave = () => {
    setHover(false);
    setAnchorEl(null);
  };
  return (
    <Grid
      container
      item
      // xs={12}
      className={clsx({
        flicker2: order.isNew,
        "slide-in-top": order.isNew,
      })}
      style={{
        width: "1270px",

        height: "66px",
        borderRadius: "8px",
        marginBottom: "10px",
        // marginRight: "25px",
        border: hover ? "1px solid #babecc" : "1px solid #686B76",
      }}
      onMouseLeave={handleMouseLeave}
    >
      {rowData.length > 0 ? (
        <Grid
          className={"custom ag-theme-balham-dark"}
          style={gridStyle}
          item
          onMouseEnter={handleMouseEnter}
        >
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            getRowId={getRowId}
            keepDetailRows={true}
            onGridReady={onGridReady}
            animateRows={true}
            onRowDataUpdated
            deltaRowDataMode={true}
            readOnlyEdit={true}
            singleClickEdit={true}
          ></AgGridReact>
        </Grid>
      ) : null}
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement={"right"}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={100}>
            <Grid container item style={{ width: "100px" }}>
              <Grid
                item
                className="trash-icon"
                style={{
                  marginLeft: "15px",
                  opacity:
                    Number(order.userId) !==
                    Number(sessionStorage.getItem("userId"))
                      ? 0.2
                      : 1,
                }}
              >
                <IconButton
                  color="primary"
                  aria-label="delete-order"
                  onClick={(e) =>
                    deleteOrder({
                      id: order.id,
                      userId: order.userId,
                    })
                  }
                  disabled={
                    Number(order.userId) !==
                    Number(sessionStorage.getItem("userId"))
                  }
                >
                  <Trash />
                </IconButton>
              </Grid>
              <Grid item className="x-icon" style={{ marginLeft: "15px" }}>
                <IconButton
                  style={{
                    backgroundColor: "#3A3C3F",
                  }}
                  onClick={(e) => handleIgnoreOrder(order)}
                >
                  <Ignore />
                </IconButton>
              </Grid>
            </Grid>
          </Fade>
        )}
      </Popper>
    </Grid>
  );
};

const handleCurrencySpotCode = async (spotCurrencyCode, setSpotCurrency) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BLOOMBERG_DERIVS}spot/${spotCurrencyCode}`,
    );
    setSpotCurrency(res.data.price);
  } catch (error) {
    console.log(error);
  }
};

export const deleteOrder = async (orderDetails) => {
  const { userId, id } = orderDetails;
  try {
    const token = sessionStorage.getItem("token");
    await axios.delete(
      `${process.env.REACT_APP_BASE_URL}${END_POINT.GET_ORDERS_LIST_EQUITIES_SPREAD}/${id}/${userId}`,
      { headers: { Authorization: token } },
    );
  } catch (err) {
    console.log(err.response.data);
  }
};
