export const transformString = (input) => {
  // Step 1: Add spaces between letters and numbers, and between numbers and uppercase letters
  let spacedString = input
    .replace(/([a-zA-Z])([0-9])/g, "$1 $2") // Insert space between letters and numbers
    .replace(/([0-9])([A-Z])/g, "$1 $2") // Insert space between numbers and uppercase letters
    .replace(/([a-z])([A-Z])/g, "$1 $2"); // Insert space between lowercase and uppercase letters

  // Step 2: Capitalize the first letter of each word
  let result = spacedString.replace(/\b\w/g, (char) => char.toUpperCase());

  // Step 3: Return the final transformed string
  return result;
};
