import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as fiActions from "../../../store/fi/fiSlice";
import * as actionSnackBar from "../../../store/snackbar/action";

const SplitCalculator = (open, splitOption, splitNumArr) => {
  const dispatch = useDispatch();

  const splitArrValues = useSelector((state) => state.fiSlice.splitArrValues);
  const selectedTrade = useSelector(
    (state) => state.fiSlice.selectedTradeToEdit,
  );
  const FieldsTotalAmount = splitArrValues.reduce((previous, current) => {
    return previous + +current.splitOption;
  }, 0);

  if (open && splitOption === "percentage" && 100 - FieldsTotalAmount < 0) {
    dispatch(
      actionSnackBar.setSnackBar(
        "error",
        `Allocation % cannot be above 100, deviation percentage is: ${
          100 - FieldsTotalAmount
        } %`,
        3000,
      ),
    );
  } else if (
    open &&
    splitOption === "notional" &&
    +selectedTrade?.quantity - FieldsTotalAmount < 0
  ) {
    dispatch(
      actionSnackBar.setSnackBar(
        "error",
        `Notional amount cannot be above trade quantity: ${
          selectedTrade?.quantity
        } , deviation is: ${+selectedTrade?.quantity - FieldsTotalAmount}`,
        3000,
      ),
    );
  }

  if (open && splitOption === "percentage") {
    dispatch(fiActions.setAmountLeftToSplit(100 - FieldsTotalAmount));
  } else if (open && splitOption === "notional") {
    dispatch(
      fiActions.setAmountLeftToSplit(
        +selectedTrade?.quantity - FieldsTotalAmount,
      ),
    );
  }
  // else {
  //   dispatch(
  //     fiActions.setAmountLeftToSplit(
  //       "there are no more",
  //       splitOption,
  //       "left to split",
  //     ),
  //   );
  // }
};
export default SplitCalculator;
