import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import * as actionSnackBar from "../snackbar/action";
import { END_POINT } from "../../utils/index";

export const emailSlice = createSlice({
  name: "emailSlice",
  initialState: {
    loadingEmail: false,
    rfqEmail: null,
    emailSent: false,
    emailAddresses: { to: [], cc: [] },
    fiEmail: null,
    clients: [],
    subject: "",
    fxEmailSubjects: [],
    fxEmailsSavedData: {
      toEmails: [],
      ccEmails: [],
      subjects: [],
      traderComments: [],
      bottomComments: [],
    },
    nodeJSEmailBodyClientSelection : ""
  },
  reducers: {
    setLoadingEmail: (state, action) => {
      state.loadingEmail = action.payload;
    },
    setRfqEmail: (state, action) => {
      state.rfqEmail = action.payload;
    },
    setFiEmail: (state, action) => {
      state.fiEmail = action.payload;
    },
    setClients: (state, action) => {
      state.clients = action.payload;
    },
    setEmailSent: (state, action) => {
      state.emailSent = action.payload;
    },
    setEmailSubject: (state, action) => {
      state.subject = action.payload;
    },
    setFxEmailSavedData: (state, action) => {
      const { index, string } = action.payload;
      if (action.payload.index === null || action.payload.index === undefined) {
        state.fxEmailsSavedData = action.payload;
      } else {
        state.fxEmailsSavedData[action.payload.type][index] = string;
      }
    },
    setNodeJSEmailBodyClientSelection : (state, action) => {
      state.nodeJSEmailBodyClientSelection = action.payload
    }
  },
});

export const getEmailPreview =
  (type, emailGenerateData) => async (dispatch, getState) => {
    const token = sessionStorage.getItem("token");
    let res;
    dispatch(setLoadingEmail(true));
    if (getState().emailSlice?.rfqEmail !== null && type === "FX") {
      dispatch(setLoadingEmail(false));
    } else {
      try {
        if (type === "FX") {
          res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}` +
              END_POINT.RFQ_EMAILS +
              `/${type}`,

            { emailGenerateData },
            {
              headers: { Authorization: token },
            }
          );
          if (res.status === 200) {
            dispatch(setLoadingEmail(false));
            dispatch(setRfqEmail(res.data));
            dispatch(setNodeJSEmailBodyClientSelection(res.data.nodeJsEmailBodyClientSelection));
            dispatch(setEmailSubject(res.data?.subject));
          }
        } else if (type === "FI") {
          res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}` +
              END_POINT.RFQ_EMAILS +
              `/${type}`,
            { emailGenerateData },
            {
              headers: { Authorization: token },
            }
          );
          if (res.status === 200) {
            dispatch(setFiEmail(res.data));
          }
        }
      } catch (error) {
        dispatch(
          actionSnackBar.setSnackBar(
            "error",
            `Problem loading email preview`,
            5000
          )
        );
      }
    }
  };

export const setEmailSavedData =
  (action, type, index, string) => async (dispatch, getState) => {
    let dispatchData;
    if (action === "initialize" && type === "none") {
      let currentClientArrayLength =
        getState().rfqPostTradeSlice?.currentClient?.length;
      let tempSubjectArray = [];
      for (let i = 0; i < currentClientArrayLength; i++) {
        tempSubjectArray.push("");
      }
      let arrayesForEmail;
      for (let i = 0; i < currentClientArrayLength; i++) {
        arrayesForEmail=[]
      }
      dispatchData = {
        toEmails: arrayesForEmail,
        ccEmails: arrayesForEmail,
        subjects: tempSubjectArray,
        traderComments: tempSubjectArray,
        bottomComments: tempSubjectArray,
      };
      dispatch(setFxEmailSavedData(dispatchData));
    } else if (action === "clear") {
      dispatchData = {
        toEmails: [],
        ccEmails: [],
        subjects: [],
        traderComments: [],
        bottomComments: [],
      };
      dispatch(setFxEmailSavedData(dispatchData));
    } else {
      dispatchData = {
        index: index,
        type: type,
        string: string,
      };
      dispatch(setFxEmailSavedData(dispatchData));
    }
  };

export const {
  setLoadingEmail,
  setRfqEmail,
  setFiEmail,
  setClients,
  setEmailSent,
  setEmailSubject,
  setFxEmailSavedData,
  setNodeJSEmailBodyClientSelection
} = emailSlice.actions;

export default emailSlice.reducer;
