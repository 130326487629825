import axios from "axios";
import { store } from "../../index";
import * as rfqSlice from "../../store/rfq/rfqSlice";
import * as rfqPostTradeAction from "../../store/rfq/rfqPostTradeSlice";

export const createQuoteBookDict = async (id) => {
  let rfqId = id === undefined ? sessionStorage.getItem("rfqId") : id;

  let token = sessionStorage.getItem("token");
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}` + "rfq" + `/rfq_details/${rfqId}`,
      {
        headers: { Authorization: token },
      }
    );
    const fieldsList = [
      "CCY_PAIR",
      "DEAL_TYPE",
      "LEG_TYPE",
      "REQUEST_FOR",
      "OPTION_TYPE",
      "EXPIRY_TEXT",
      "STRIKE",
      "BARRIER",
      "BARRIER2",
      "CALL_PUT",
      "NOTIONAL",
      "BUY_SELL",
      "CCYPAIR_GROUP",
      "PB_ACCOUNT",
      "RFQ_FORMAT",
      "REF_TYPE",
      "INCLUDE_REF",
      "CUT",
      "FIXING_REF",
      "NOTIONAL_CCY",
      "SPOT_REF",
      "FWD_REF",
      "BBG_MID_VOL",
      "EXPIRY",
      "DELIVERY",
      "SPOT_DATE",
      "DELTA",
      "VEGA",
      "HEDGE_AMOUNT",
      "PRICE_PCT_NOTIONAL",
      "PREMIUM_VAL",
      "PREMIUM_TYPE",
      "PREMIUM_CCY",
      "CLIENT_PRICE",
      "MARKET_PRICE",
      "CLIENT_PREMIUM",
      "MARKET_PREMIUM",
      "NET_CLIENT_PRICE",
      "NET_MARKET_PRICE",
      "HEDGE_TYPE",
      "HEDGE_VAL",
      "HEDGE_RATE",
      "HEDGE_DELTA",
      "HEDGE_MATCH_TYPE",
      "CLIENT_HEDGE_TYPE",
      "CLIENT_HEDGE_VAL",
      "CLIENT_HEDGE_RATE",
      "CLIENT_HEDGE_DELTA",
      "MM_HEDGE_TYPE",
      "MM_HEDGE_VAL",
      "MM_HEDGE_RATE",
      "MM_HEDGE_DELTA",
      "NEAR_DATE_TEXT",
      "FAR_DATE_TEXT",
      "NEAR_NOTIONAL",
      "FAR_NOTIONAL",
      "SWAP_CCY",
      "NEAR_DATE",
      "FAR_DATE",
      "DATES_IN_RFQ",
      "NEAR_SWAP",
      "FAR_SWAP",
      "SWAP_DIRECTION",
      "CLIENT_START_RATE",
      "MARKET_START_RATE",
      "CLIENT_SWAP_PTS",
      "MARKET_SWAP_PTS",
      "CLIENT_FAR_RATE",
      "MARKET_FAR_RATE",
      "FAR_FIXING_DATE",
      "NEAR_FIXING_DATE",
    ];

    let array = [];

    fieldsList.map((m) => {
      let obj = {};
      for (let index = 0; index < response.data.rfq.length; index++) {
        let key = "";
        if (m === "CCY_PAIR") {
          key = "ccy_pair";
        } else if (m === "DEAL_TYPE") {
          key = "leg_type_text";
        } else if (m === "LEG_TYPE") {
          key = "deal_type_text";
        } else if (m === "REQUEST_FOR") {
          key = "";
        } else if (m === "OPTION_TYPE") {
          key = "option_type_text";
        } else if (m === "EXPIRY_TEXT") {
          key = "expiry_date";
        } else if (m === "STRIKE") {
          key = "strike";
        } else if (m === "BARRIER") {
          key = "barrier_1";
        } else if (m === "BARRIER2") {
          key = "barrier_2";
        } else if (m === "CALL_PUT") {
          key = "call_put";
        } else if (m === "NOTIONAL") {
          key = "notional";
        } else if (m === "BUY_SELL") {
          key = "buy_sell";
        } else if (m === "CCYPAIR_GROUP") {
          key = "";
        } else if (m === "PB_ACCOUNT") {
          key = "pb_account";
        } else if (m === "RFQ_FORMAT") {
          key = "rfq_format";
        } else if (m === "REF_TYPE") {
          key = "ref_type";
        } else if (m === "INCLUDE_REF") {
          key = "include_ref";
        } else if (m === "FIXING_REF") {
          key = "fixing_ref";
        } else if (m === "CUT") {
          key = "cut";
        } else if (m === "NOTIONAL_CCY") {
          key = "notional_ccy";
        } else if (m === "SPOT_REF") {
          key = "spot_ref";
        } else if (m === "FWD_REF") {
          key = "forward_ref";
        } else if (m === "BBG_MID_VOL") {
          key = "bbg_mid_vol";
        } else if (m === "EXPIRY") {
          key = "expiry_date";
        } else if (m === "DELIVERY") {
          key = "delivery_date";
        } else if (m === "SPOT_DATE") {
          key = "spot_date";
        } else if (m === "DELTA") {
          key = "delta";
        } else if (m === "VEGA") {
          key = "vega";
        } else if (m === "HEDGE_AMOUNT") {
          key = "hedge_amount";
        } else if (m === "PRICE_PCT_NOTIONAL") {
          key = "price_pct_notional";
        } else if (m === "PREMIUM_VAL") {
          key = "premium_val";
        } else if (m === "PREMIUM_TYPE") {
          key = "premium_type";
        } else if (m === "PREMIUM_CCY") {
          key = "premium_ccy";
        } else if (m === "CLIENT_PRICE") {
          key = "client_price";
        } else if (m === "MARKET_PRICE") {
          key = "market_price";
        } else if (m === "CLIENT_PREMIUM") {
          key = "client_premium";
        } else if (m === "MARKET_PREMIUM") {
          key = "market_premium";
        } else if (m === "NET_CLIENT_PRICE") {
          key = "net_client_price";
        } else if (m === "NET_MARKET_PRICE") {
          key = "net_market_price";
        } else if (m === "HEDGE_TYPE") {
          key = "hedge_type";
        } else if (m === "HEDGE_VAL") {
          key = "hedge_val";
        } else if (m === "HEDGE_RATE") {
          key = "hedge_rate";
        } else if (m === "HEDGE_DELTA") {
          key = "hedge_delta";
        } else if (m === "HEDGE_MATCH_TYPE") {
          key = "hadge_match_type";
        } else if (m === "CLIENT_HEDGE_TYPE") {
          key = "client_hedge_type";
        } else if (m === "CLIENT_HEDGE_VAL") {
          key = "client_hedge_val";
        } else if (m === "CLIENT_HEDGE_RATE") {
          key = "client_hedge_rate";
        } else if (m === "CLIENT_HEDGE_DELTA") {
          key = "client_hedge_delta";
        } else if (m === "MM_HEDGE_TYPE") {
          key = "mm_hedge_type";
        } else if (m === "NEAR_DATE_TEXT") {
          key = "near_date_text";
        } else if (m === "FAR_DATE_TEXT") {
          key = "far_date_text";
        } else if (m === "NEAR_NOTIONAL") {
          key = "near_notional";
        } else if (m === "FAR_NOTIONAL") {
          key = "far_notional";
        } else if (m === "SWAP_CCY") {
          key = "swap_ccy";
        } else if (m === "NEAR_DATE") {
          key = "near_date";
        } else if (m === "FAR_DATE") {
          key = "far_date";
        } else if (m === "DATES_IN_RFQ") {
          key = "dates_in_rfq";
        } else if (m === "NEAR_SWAP") {
          key = "near_swap";
        } else if (m === "FAR_SWAP") {
          key = "far_swap";
        } else if (m === "SWAP_DIRECTION") {
          key = "swap_direction";
        } else if (m === "CLIENT_START_RATE") {
          key = "client_start_rate";
        } else if (m === "MARKET_START_RATE") {
          key = "market_start_rate";
        } else if (m === "CLIENT_SWAP_PTS") {
          key = "client_swap_pts";
        } else if (m === "MARKET_SWAP_PTS") {
          key = "market_swap_pts";
        } else if (m === "CLIENT_FAR_RATE") {
          key = "client_far_rate";
        } else if (m === "MARKET_FAR_RATE") {
          key = "market_far_rate";
        } else if (m === "FAR_FIXING_DATE") {
          key = "far_fixing_date";
        } else if (m === "NEAR_FIXING_DATE") {
          key = "near_fixing_date";
        }

        obj = {
          ...obj,
          [0]: m,
          [index + 1]: key !== "" ? [response.data.rfq[index][`${key}`]] : "",
        };
      }
      array.push(obj);
    });
    store.dispatch(rfqSlice.updateRfqObject(array));
    let obj = { rfq_table_data: array };
    store.dispatch(rfqPostTradeAction.setRfqObject(obj));

    return array;
  } catch (error) {
    console.log(error);
  }
};
