import {
  makeStyles,
  Tab,
  Tabs,
  TextField,
  withStyles,
} from "@material-ui/core";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

export const useStyles = makeStyles((theme) => ({}));

export const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
    "& .MuiTabScrollButton-root": {
      marginTop: "10px",
    },
  },
  indicator: {
    backgroundColor: "#1890ff",
  },
})(Tabs);

export const AntTab = withStyles((theme) => ({
  root: {
    marginTop: 21,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    minHeight: "15px",
    textTransform: "none",
    maxWidth: 15,
    borderRadius: "8px 8px 0px 0px",
    marginRight: theme.spacing(1),
    backgroundColor: "#E9E9E9",
    color: "#000000",
    "& .MuiTab-wrapper": {
      width: "81px",
      display: "inline-flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
    },
    "&:hover": {
      backgroundColor: "#cacaca",
    },
    "&$selected": {
      color: "#ffffff",
      backgroundColor: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#ffffff",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export const StyledTextField = withStyles(() => ({
  root: {
    width: "114%",
    "& .MuiInputBase-input": {
      color: "#000000",
      fontSize: 14,
      height: 20,
      padding: "0px 8px",
    },
    "& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl":
      {
        paddingLeft: 0,
      },
    "& .MuiInputBase-input": {
      color: "#000000",
      padding: 0,
      paddingBottom: 5,
    },
  },
}))(TextField);

export const CommentBox = withStyles(() => ({
  root: {
    width: "100%",
    "& .MuiInputBase-input": {
      color: "#000000",
      fontSize: 14,
      padding: "0px 8px",
    },
    "& .MuiOutlinedInput-multiline" :{
      padding: "9.5px 14px",
    },
    "& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl":
      {
        paddingLeft: 0,
      },
    "& .MuiInputBase-input": {
      color: "#000000",
      padding: 0,
      paddingBottom: 3,
      maxHeight :10
    },
  },
}))(TextField);
export const SubjectBox = withStyles(() => ({
  root: {
    width: "100%",
    "& .MuiInputBase-input": {
      color: "#000000",
      padding: 10,
    },
  },
}))(TextField);

export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));
