import axios from "axios";
import { END_POINT } from "../../../../utils";
import { ws, connectWS, sendEvent } from "../../../../services/websocket";

export const updateTieApi = async (
  underlineDetails,
  tieValue,
  legNumArr = null,
) => {
  try {
    const token = sessionStorage.getItem("token");
    const updateBody =
      legNumArr === null
        ? { id: underlineDetails.id, tieValue }
        : { id: underlineDetails.id, tieValueByLeg: tieValue, legNumArr };
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}${END_POINT.UPDATE_ORDER}`,
      updateBody,
      { headers: { Authorization: token } },
    );
  } catch (error) {
    console.log(error);
  }
};

export const deleteOrder = async (orderDetails) => {
  const { id, status } = orderDetails;

  try {
    const token = sessionStorage.getItem("token");
    await axios.delete(
      `${process.env.REACT_APP_BASE_URL}${END_POINT.GET_ORDERS_LIST}`,
      {
        params: {
          id,
          status,
        },
        headers: { Authorization: token },
      },
    );
  } catch (err) {
    console.log(err);
  }
};

export const updateOrder = async (orderId, legNum, rowIndex, colId, value) => {
  try {
    const token = sessionStorage.getItem("token");
    const updateBody = { orderId, legNum, rowIndex, colId, value };
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}${END_POINT.UPDATE_ORDER}`,
      updateBody,
      { headers: { Authorization: token } },
    );
  } catch (err) {
    console.log(err);
  }
};

export const favouritesHandler = async (order, action) => {
  try {
    const token = sessionStorage.getItem("token");
    sendEvent({
      type: "derivs_pricer_update_fav",
      data: { action, id: order.id, order },
      metaData: {},
      token: token.replace("Bearer ", ""),
    });
  } catch (err) {
    console.log(err);
  }
};
