import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ResetPasswordView from "./ResetPassword.view";

import * as actionSnackBar from "../../../store/snackbar/action";

function ResetPassword({ open, onClose }) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [userEmail, setUserEmail] = useState();
  const [resetCodeSent, setResetCodeSent] = useState(false);
  const [sixDigits, setSixDigits] = useState("");
  
  const sendResetCode = async () => {
    try {
      let sendCode = await axios.put(
        `${process.env.REACT_APP_BASE_URL}users/reset_password`,
        { userEmail }
      );
      if (sendCode?.data === true) {
        setResetCodeSent(true);
      } else if(sendCode?.data?.error){
        dispatch(actionSnackBar.setSnackBar("error", sendCode?.data?.error, 3000));
      }
    } catch (error) {}
  };

  const handleChangeNumeric = (e) => {
    setSixDigits(e);
  };

  const changePassword = async () => {
    const checkCode = await axios.get(
      `${process.env.REACT_APP_BASE_URL}users/check_code?code=${sixDigits}`
    );
    if (checkCode?.data?.correctCode === true) {
      sessionStorage.setItem("userName", checkCode.data.userName);
      sessionStorage.setItem("id", checkCode.data.traderId);
      navigate("/changePassword");
    } else {
      dispatch(
        actionSnackBar.setSnackBar("error", "Incorrect Restore Code", 8000)
      );
    }
  };

  const handleUserEmailChange = (e) => {
    setUserEmail(e.target.value);
  }

  return (
    <ResetPasswordView
    open={open}
      onClose={onClose}
      handleChangeNumeric={handleChangeNumeric}
      resetCodeSent={resetCodeSent}
      sendResetCode={sendResetCode}
      changePassword={changePassword}
      userEmail={userEmail}
      handleUserEmailChange={handleUserEmailChange}
      sixDigits={sixDigits}
    />
  );
}

export default ResetPassword;
