
import { createSlice } from '@reduxjs/toolkit';


export const uiSlice = createSlice({
  name: 'uiSlice',
  initialState:{
    modalCreate: false,
  },
  reducers:{
    ChangeModalStatus:(state, action) => {
     state.modalCreate = action.payload
    }
  }

})
export const {
    ChangeModalStatus
} = uiSlice.actions

export default uiSlice.reducer