export const validateEmailsStructure = (obj) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const message = [];
  for (const key in obj) {
    const emailString = obj[key];
    const emailArray = emailString
      .split(";")
      .map((email) => email.trim())
      .filter((email) => email !== "");

    // Validate each email in the array
    emailArray.forEach((email) => {
      if (!emailRegex.test(email)) {
        message.push(
          `${email} in ${
            key === "toList" ? "TO field" : "CC field"
          } is not a valid email address`,
        );
      }
    });
  }
  return message;
};
