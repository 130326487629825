export const build_rfq_on_ctrl_enter = async (
  event,
  buildRfq,
  keyVal_input_col,
  col,
  axios,
  jRef,
  setCellValue,
) => {
  if (event.ctrlKey && event.code === "Enter") {
    await buildRfq(event);
  }
};
export const add_new_coluumn_F2 = (
  event,
  keyRow,
  jRef,
  add_custom_leg,
  col,
) => {
  if (event.code === "F2") {
    let row = keyRow("BUY_SELL");
    jRef.current.jspreadsheet.updateSelectionFromCoords(col, row, col, row);
    add_custom_leg();
  }
};
export const handle_arrow_navigation = (event, hidden_keys, row, col, jRef) => {
  if (["ArrowUp", "ArrowDown", "Enter"].includes(event.code) === false) return;
  let clone_hidden_keys = [...hidden_keys];
  let direction = 0;
  if (event.code === "ArrowUp") {
    clone_hidden_keys = clone_hidden_keys.reverse();
    direction = -1;
  }
  if (event.code === "ArrowDown" || event.code === "Enter") {
    direction = 1;
  }
  let key_row = row;
  let key_col = col;
  for (const hidden_key of clone_hidden_keys) {
    let key = jRef.current.jspreadsheet.getValueFromCoords(0, key_row);
    if (key === hidden_key) {
      jRef.current.jspreadsheet.updateSelectionFromCoords(
        key_col,
        key_row + direction,
        key_col,
        key_row + direction,
      );
      key_row += direction;
    }
  }
};
export const initialize_cell_toggles = (event, create_cell_toggle, jRef) => {
  create_cell_toggle("BUY_SELL", ["BUY", "SELL"], false, event);
  create_cell_toggle("CALL_PUT", ["CALL", "PUT"], false, event);
  create_cell_toggle("REF_TYPE", ["SPOT", "FWD"], true, event);
  create_cell_toggle("INCLUDE_REF", ["YES", "NO"], true, event);
  create_cell_toggle("RFQ_FORMAT", ["PCT_NOTIONAL", "VOLS"], true, event);
  create_cell_toggle("HEDGE_MATCH_TYPE", ["MATCHED", "UNMATCHED"], true, event);
  create_cell_toggle("DATES_IN_RFQ", ["YES", "NO"], true, event);
  create_cell_toggle("SWAP_DIRECTION", ["BUY_SELL", "SELL_BUY"], true, event);
  if (!jRef.current !== null) {
    if (jRef.current.jspreadsheet?.getJson()[1][1] !== "") {
      let currencyPairs = [];
      let userCurrencyPair = jRef.current.jspreadsheet.getJson()[1][1][0];
      let base_ccy = userCurrencyPair.split("").splice(0, 3).join("");
      let qoute_ccy = userCurrencyPair.split("").splice(3, 4).join("");
      currencyPairs.push(base_ccy, qoute_ccy);
      create_cell_toggle("NOTIONAL_CCY", currencyPairs, true, event);
    }
  }
};

export const initialize_dropdown_menus_for_hedges = (
  event,
  create_dropdown_menu_on_single_cell,
) => {
  create_dropdown_menu_on_single_cell(
    "HEDGE_TYPE",
    ["SPOT", "FWD", "LIVE"],
    1,
    event,
  );
  create_dropdown_menu_on_single_cell(
    "CLIENT_HEDGE_TYPE",
    ["SPOT", "FWD", "LIVE"],
    1,
    event,
  );
};
export const initialize_dropdown_menus_for_mm_hedges = (
  event,
  create_dropdown_menu_on_single_cell,
) => {
  create_dropdown_menu_on_single_cell(
    "MM_HEDGE_TYPE",
    ["SPOT", "FWD", "LIVE"],
    1,
    event,
  );
};
//
export const initialize_dropdown_menus_for_premium_val = (
  event,
  create_dropdown_menu,
) => {
  create_dropdown_menu(
    "PREMIUM_TYPE",
    ["BASE_PCT", "TERMS_PIPS", "BASE_PIPS", "TERMS_PCT"],
    event,
  );
};
export const initialize_dropdown_menus = (
  event,
  deal_types,
  cross_list_data,
  deal_types_single_leg,
  handleClientList,
  create_dropdown_menu_on_single_cell,
  create_dropdown_menu,
) => {
  create_dropdown_menu("REQUEST_FOR", handleClientList(), event);

  create_dropdown_menu_on_single_cell("CCY_PAIR", cross_list_data, 1, event);

  create_dropdown_menu_on_single_cell("DEAL_TYPE", deal_types(), 1, event);

  create_dropdown_menu("LEG_TYPE", deal_types_single_leg(), event);

  create_dropdown_menu(
    "PB_ACCOUNT",
    ["OG-RBS", "MAKOR-RBS", "MAKOR-HSBC"],
    event,
  );
};

export const initialize_dropdown_menus_for_third_ccy = (
  event,
  singleCurrenciesList,
  create_dropdown_menu_on_single_cell,
  keyVal_input_col,
) => {
  const base_ccy = keyVal_input_col("CCY_PAIR", 1).substring(0, 3);
  const second_ccy = keyVal_input_col("CCY_PAIR", 1).substring(3);
  const filterCurrencies = singleCurrenciesList.filter(
    (c) =>
      c.value.toLowerCase() !== base_ccy.toLowerCase() &&
      c.value.toLowerCase() !== second_ccy.toLowerCase(),
  );
  create_dropdown_menu_on_single_cell("THIRD_CCY", filterCurrencies, 1, event);
};
export const initial_cell_togel_for_premium_val = (
  event,
  create_cell_toggle,
) => {
  create_cell_toggle("PREMIUM_VAL", ["SPOT", "FWD"], true, event);
};

export const initial_cell_togel_for_prem_ccy_type = (
  event,
  create_cell_toggle,
) => {
  create_cell_toggle("PREM_CCY_TYPE", ["DEFAULT", "THIRD_CCY"], true, event);
};
export const create_dropdown_menu_on_single_cell = (
  cell_name,
  item_list,
  col,
  event,
  row,
  set_cell_value,
  dropDownList,
  jRef,
  getCell,
  keyRow,
  eventListeners,
  auto_fill_dropdown_event,
) => {
  if (row === keyRow(cell_name) && event.code === "Space" && col === col) {
    set_cell_value("", cell_name, col);
    event.preventDefault();
    const firstLetter = event.key;
    document.removeEventListener("keydown", eventListeners);
    document.addEventListener("keydown", auto_fill_dropdown_event);
    dropDownList.addDowpDown(
      jRef.current.jspreadsheet,
      getCell(col, row),
      item_list,
      firstLetter,
    );
  }
};
export const toggleToRfqView = (event, dispatch) => {
  // if (event.code === "F6") {
  // }
};
export const toggleToAggregatorView = (event, dispatch) => {
  // if (event.code === "F7") {
  // }
};
export const toggleBookingView = (event, dispatch) => {
  // if (event.code === "F8") {
  // }
};
