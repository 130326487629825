import axios from "axios";
import { END_POINT } from "../../../../utils/index";
import strategiesJson from "./strategiesJson.json";
const moment = require("moment-timezone"); // Import Moment.js with timezone support

export const getMakorXId = async (makorXEmployeesList) => {
  try {
    const token = sessionStorage.getItem("token");
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}${END_POINT.DERIVS_GET_MAKOR_X_ID}`,
      { makorXEmployeesList },
      { headers: { Authorization: token } },
    );
    if (res.data !== null) {
      return res.data;
    } else return null;
  } catch (error) {
    console.log("getMakorXId", error);
  }
};

export const getStrategyId = async (strategy, strategyList) => {
  const strategyObj = strategiesJson.filter(
    (obj) => obj.strategy.toLowerCase() === strategy.toLowerCase(),
  );
  if (strategyObj.length > 0) {
    return Number(strategyObj[0].MakorId);
  } else return null;
};
export const getStrategyName = async (strategy, strategyList) => {
  const strategyObj = strategiesJson.filter(
    (obj) => obj.strategy.toLowerCase() === strategy.toLowerCase(),
  );
  if (strategyObj.length > 0) {
    return strategyObj[0].makorStrategy;
  } else return null;
};
export const getFloorBrokerId = async (floorBroker, floorBrokerList) => {
  const floorBrokerId = floorBrokerList.filter(
    (obj) => obj.name.toLowerCase() === floorBroker.toLowerCase(),
  );
  if (floorBrokerId.length > 0) {
    return floorBrokerId[0].id;
  } else return null;
};
export const getCurrencyId = async (currency, currenciesList) => {
  const currencyId = currenciesList.filter(
    (obj) => obj.code.toLowerCase() === currency.toLowerCase(),
  );

  if (currencyId.length > 0) {
    return currencyId[0].id;
  } else return null;
};
export const getTradeTypeId = async (tradeType, tradesTypeList) => {
  const tradeTypeId = tradesTypeList.filter((obj) =>
    obj.name.toLowerCase().includes(tradeType.toLowerCase()),
  );

  if (tradeTypeId.length > 0) {
    return tradeTypeId[0].id;
  } else return null;
};

export const getTradeExecHedgeTypeId = async (hedgeType, hedgesTypeList) => {
  const hedgeTypeTypeId = hedgesTypeList.filter(
    (obj) => obj.name.toLowerCase() === hedgeType?.toLowerCase(),
  );

  if (hedgeTypeTypeId.length > 0) {
    return hedgeTypeTypeId[0].id;
  } else return null;
};
export const getUnderlyingTypeId = async (
  underlyingType,
  underlyingTypeList,
) => {
  const underlyingTypeId = underlyingTypeList.filter(
    (obj) => obj.name.toLowerCase() === underlyingType.toLowerCase(),
  );

  if (underlyingTypeId.length > 0) {
    return underlyingTypeId[0].id;
  } else return null;
};
export const getUnderlyingStockTypeId = async (
  securityType,
  underlyingStockTypeList,
) => {
  const underlyingStockType =
    securityType.toLowerCase() === "common stock" ? "SS" : "ETF";
  const underlyingStockTypeId = underlyingStockTypeList.filter(
    (obj) => obj.name.toLowerCase() === underlyingStockType.toLowerCase(),
  );

  if (underlyingStockTypeId.length > 0) {
    return underlyingStockTypeId[0].id;
  } else return null;
};
export const getExchangeId = async (exchange, exchangeList) => {
  const exchangeId = exchangeList.filter(
    (obj) => obj.name.toLowerCase() === exchange.toLowerCase(),
  );

  if (exchangeId.length > 0) {
    return exchangeId[0].id;
  } else return null;
};
export const createExecArray = async (array) => {
  const execArray = [];
  let currentExec = null;
  array.forEach((obj, index) => {
    if (obj.id.includes("option_option") || obj.id.includes("hedge_hedge")) {
      currentExec = []; // Create a new array for each group
      execArray.push(currentExec); // Push the array into execArray
    }
    if (currentExec) {
      currentExec.push(obj); // Push the object into the currentExec array
    }
  });

  return execArray;
};
export const getExecComponentType = async (exec, componentTypeList) => {
  const componentType = exec[0].id.toLowerCase().includes("option")
    ? "leg"
    : exec[0].id.toLowerCase().includes("hedge")
    ? "hedge"
    : null;

  const componentTypeId = componentTypeList.filter(
    (c) => c.name.toLowerCase() === componentType.toLowerCase(),
  );

  if (componentTypeId.length > 0) {
    return componentTypeId[0].id;
  } else return null;
};
export const getExecContractType = async (exec, componentTypeList, order) => {
  const componentType =
    exec[0].id.toLowerCase().includes("option") ||
    order.equityIndex.toLowerCase().includes("index")
      ? "option"
      : exec[0].id.toLowerCase().includes("hedge")
      ? "equity"
      : null;

  const componentTypeId = componentTypeList.filter(
    (c) => c.name.toLowerCase() === componentType.toLowerCase(),
  );

  if (componentTypeId.length > 0) {
    return componentTypeId[0].id;
  } else return null;
};

export const getExecPrice = async (exec) => {
  const priceIndex = exec.filter((obj) => obj.id.includes("price"));
  return Number(priceIndex[0]["exec1"].replace(",", ""));
};

export const getExpiry = async (exec, order) => {
  const componentType =
    exec[0].id.toLowerCase().includes("option") ||
    order.equityIndex.toLowerCase().includes("index")
      ? "option"
      : exec[0].id.toLowerCase().includes("hedge")
      ? "equity"
      : null;
  const expiryRow = exec.filter((obj) =>
    obj.id.toLowerCase().includes("expiry"),
  );
  if (componentType === "option" && expiryRow.length > 0) {
    return convertDate(expiryRow[0]["exec1"]);
  } else return null;
};

export const getOptionTypeId = async (exec, optionsTypeList, order) => {
  const componentType =
    exec[0].id.toLowerCase().includes("option") ||
    order.equityIndex.toLowerCase().includes("index")
      ? "option"
      : exec[0].id.toLowerCase().includes("hedge")
      ? "equity"
      : null;

  const callPutRow = exec.filter((obj) =>
    obj.id.toLowerCase().includes("call_put"),
  );
  if (componentType === "option" && callPutRow.length > 0) {
    const value =
      callPutRow[0]["exec1"] === "C"
        ? "call"
        : callPutRow[0]["exec1"] === "P"
        ? "put"
        : null;
    const optionTypeId = optionsTypeList.filter(
      (option) => option.name.toLowerCase() === value,
    );
    if (optionTypeId.length > 0) {
      return optionTypeId[0].id;
    } else return null;
  } else return null;
};
export const getSide = async (exec, side, sideList) => {
  const sideRow = exec.filter((row) => row.id.toLowerCase().includes("side"));
  if (sideRow.length > 0) {
    const currentSide =
      side === "B"
        ? sideRow[0]["exec1"]
        : sideRow[0]["exec1"] === "S"
        ? "B"
        : sideRow[0]["exec1"] === "B"
        ? "S"
        : null;

    const sideById = sideList.filter(
      (obj) =>
        obj.name ===
        (currentSide === "S" ? "sell" : currentSide === "B" ? "buy" : null),
    );
    if (sideById.length > 0) {
      return sideById[0].id;
    } else return null;
  }
};
export const getStrike = async (exec, order) => {
  const componentType =
    exec[0].id.toLowerCase().includes("option") ||
    order.equityIndex.toLowerCase().includes("index")
      ? "option"
      : exec[0].id.toLowerCase().includes("hedge")
      ? "equity"
      : null;
  const strikeRow = exec.filter((obj) =>
    obj.id.toLowerCase().includes("strike"),
  );
  if (componentType === "option" && strikeRow.length > 0) {
    return Number(strikeRow[0]["exec1"]);
  } else return null;
};

export const getTraderSide = async (side, sideList) => {
  const sideById = sideList.filter(
    (obj) =>
      obj.name.toLowerCase() ===
      (side.toLowerCase() === "s"
        ? "sell"
        : side.toLowerCase() === "b"
        ? "buy"
        : null),
  );
  if (sideById.length > 0) {
    return sideById[0].id;
  } else return null;
};
export const getCompanyClearedId = async (company, companies) => {
  const companyById = companies.filter(
    (c) => c.name.toLowerCase() === company.toLowerCase(),
  );
  if (companyById.length > 0) {
    return companyById[0].id;
  } else return null;
};

export const getTraderById = async (company, client, trader, clientsList) => {
  const clientObj =
    company !== null && client !== null && trader !== null
      ? clientsList.filter(
          (c) => c.company.name === company && c.name === client,
        )
      : [];
  const clientTraderObj =
    clientObj.length > 0
      ? clientObj[0]?.client_traders.filter((trd) => trd.name === trader)
      : [];
  if (clientTraderObj.length > 0) {
    return clientTraderObj[0].id;
  } else return null;
};

export const getTotalOptionQuantity = async (optionsArray) => {
  return optionsArray.reduce((total, currentItem) => {
    if (typeof currentItem.quantity === "number") {
      return total + currentItem.quantity;
    }
    return total;
  }, 0);
};

export const getFeeCalculation = async (exec, legFeeRate, hedgeFeeRate) => {
  const totalOptions = await getTotalOptionQuantity(exec.option);
  const totalHedges = await getTotalOptionQuantity(exec.hedge);

  return `${legFeeRate !== 0 ? `${totalOptions} * ${legFeeRate}` : ""}${
    legFeeRate !== 0 && hedgeFeeRate !== 0 ? " + " : ""
  }${hedgeFeeRate !== 0 ? `${totalHedges} * ${hedgeFeeRate}` : ""}`;
};

export const getTraderEmployeesFee = async (
  company,
  client,
  trader,
  clientsList,
) => {
  const clientObj =
    company !== null && client !== null && trader !== null
      ? clientsList.filter(
          (c) => c.company.name === company && c.name === client,
        )
      : [];
  const clientTraderObj =
    clientObj.length > 0
      ? clientObj[0]?.client_traders.filter((trd) => trd.name === trader)
      : [];
  if (
    clientTraderObj.length > 0 &&
    clientTraderObj[0]?.client_trader_employees_brokerage_fee.length > 0
  ) {
    return clientTraderObj[0]?.client_trader_employees_brokerage_fee.map(
      (e) => {
        return { id: e.id, percentage: e.pivot.percentage };
      },
    );
  } else return null;
};

export const getTradeAccountExecs = async (execIndex, execArray) => {
  const mappedAccountExecs = await Promise.all(
    execArray.map((e, i) => {
      const quantityIndex = e.findIndex((row) => row.id.includes("quantity"));
      if (quantityIndex !== -1) {
        return {
          form_drv_trade_execution_id: i + 1,
          quantity: e[quantityIndex][`exec${execIndex}`],
        };
      } else {
        return {
          form_drv_trade_execution_id: i + 1,
          quantity: null,
        };
      }
    }),
  );

  return mappedAccountExecs;
};

export const convertDate = (inputDate) => {
  const parts = inputDate.split("-");

  // Map abbreviated month names to numeric values
  const monthMap = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };

  const day = parts[0].length === 1 ? `0${parts[0]}` : parts[0];
  const month = monthMap[parts[1]];
  const year = `20${parts[2]}`; // Assuming years in the 2000s

  return `${year}-${month}-${day}`;
};

export const convertEstStringToUtc = async (estDateString) => {
  const dateRegex = /^(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})$/;
  if (!dateRegex.test(estDateString)) {
    return null;
  }
  const estDate = moment.tz(estDateString, "America/New_York");
  const utcDate = estDate.utc();

  return utcDate.format("YYYY-MM-DD HH:mm:ss");
};
export const getSymbolName = async (exec, i, options, hedges) => {
  // console.log({ exec, i, options, hedges });
  if (exec[0].columnHeader.toLowerCase().includes("option")) {
    const index = exec[0].columnHeader.toLowerCase().replace("option ", "");
    return options[index - 1].ticker;
  }
  if (exec[0].columnHeader.toLowerCase().includes("hedge")) {
    const index = exec[0].columnHeader.toLowerCase().replace("hedge ", "");
    return hedges[index - 1].ticker;
  }
};
