import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import rfqPostTradeSlice, * as RfqPostTradeSlice from "../../../../store/rfq/rfqPostTradeSlice";
import * as actionSnackBar from "../../../../store/snackbar/action";

const PostTradeSplitCalculator = () => {
  const dispatch = useDispatch();
  const splitArrValues = useSelector(
    (state) => state.rfqPostTradeSlice.postTradeSplitArrValues,
  );
  const splitManner = useSelector(
    (state) => state.rfqPostTradeSlice.splitManner,
  );

  const {
    currentLegsNotional,
    currentDeltasNotional,
    currentNearNotional,
    currentFarNotional,
    postTradeAmountLeftToNotionalSplit,
    postTradeAmountLeftToDeltaNotionalSplit,
  } = useSelector((state) => state.rfqPostTradeSlice);

  // * necessities for Split By Notional
  const rfqTableData = useSelector(
    (state) => state.rfqPostTradeSlice?.rfqObject?.rfq_table_data,
  );

  let totalLegs = [];
  // ! Legs
  rfqTableData !== undefined && rfqTableData !== null
    ? (totalLegs = Object.keys(rfqTableData[0]).slice(1))
    : (totalLegs = []);

  let rfqLegsNotional = rfqTableData?.find((field) => field[0] === "NOTIONAL");
  let DEAL_TYPE = rfqTableData?.find((field) => field[0] === "DEAL_TYPE")[1][0];
  rfqLegsNotional =
    rfqTableData !== undefined && rfqTableData !== null
      ? Object.entries(rfqLegsNotional)
          .slice(1)
          .map(([key, value]) => {
            return +value[0];
          })
      : [];
  // ! Deltas
  let totalDeltas = [];
  let rfqDeltasNotional;
  const hedgeMatchType = rfqTableData?.find(
    (field) => field[0] === "HEDGE_MATCH_TYPE",
  );

  const clientHedgeVal = rfqTableData?.find(
    (field) => field[0] === "CLIENT_HEDGE_VAL",
  );
  const hedgeVal = rfqTableData?.find((field) => field[0] === "HEDGE_VAL");
  const hedgeDelta = rfqTableData?.find((field) => field[0] === "HEDGE_DELTA");
  const buySell = rfqTableData?.find((field) => field[0] === "BUY_SELL");
  const clientHedgeDelta = rfqTableData?.find(
    (field) => field[0] === "CLIENT_HEDGE_DELTA",
  );

  // ! function that sum the total Deltas
  if (splitManner === "notional" && DEAL_TYPE !== "SWAP") {
    (function sumDeltas() {
      if (hedgeMatchType) {
        if (hedgeMatchType[1][0] === "MATCHED") {
          totalDeltas = Object.entries(hedgeVal)
            .map(([key, value]) => {
              if (key !== 0) {
                if (
                  Array.isArray(value) &&
                  value.length > 0 &&
                  value[0] !== "" &&
                  value[0] !== null
                ) {
                  return key;
                }
              }
            })
            .slice(1)
            .filter((d) => d !== undefined);
          rfqDeltasNotional = Object.values(hedgeDelta)
            .filter(Array.isArray)
            .flat()
            .map(Number);
        } else if (hedgeMatchType[1][0] === "UNMATCHED") {
          totalDeltas = Object.entries(clientHedgeVal)
            .map(([key, value]) => {
              if (key !== 0) {
                if (
                  Array.isArray(value) &&
                  value.length > 0 &&
                  value[0] !== "" &&
                  value[0] !== null
                ) {
                  return key;
                }
              }
            })
            .slice(1)
            .filter((delta) => delta !== undefined);
          rfqDeltasNotional = Object.values(clientHedgeDelta)
            .filter(Array.isArray)
            .flat()
            .map(Number);
        }
      }
    })();
  }

  useEffect(() => {
    if (splitManner === "percentage") {
      const FieldsTotalAmount = splitArrValues.reduce((previous, current) => {
        return previous + +current.postTradeSplitOption;
      }, 0);
      if (100 - FieldsTotalAmount < 0) {
        dispatch(
          actionSnackBar.setSnackBar(
            "error",
            `Allocation % cannot be above 100, deviation percentage is: ${
              100 - FieldsTotalAmount
            } %`,
            3000,
          ),
        );
      }
      dispatch(
        RfqPostTradeSlice.setPostTradeAmountLeftToSplit(
          100 - FieldsTotalAmount,
        ),
      );
    } else if (splitManner === "notional") {
      if (DEAL_TYPE !== "SWAP") {
        let traderLegValues;
        let traderDeltaValues;
        if (splitArrValues.length > 0) {
          // ! LEGS arrangement and validation
          traderLegValues = totalLegs?.map((legNumber) => {
            let currentLegValue = splitArrValues.reduce((previous, current) => {
              return previous + +current[`Leg${legNumber}NotionalSplitOption`];
            }, 0);
            return currentLegValue;
          });
          let notionalLeftToSplit = currentLegsNotional.map(
            (notionl, index) => {
              if (
                traderLegValues[index] !== 0 ||
                !isNaN(traderLegValues[index])
              ) {
                return currentLegsNotional[index] - traderLegValues[index];
              } else {
                return currentLegsNotional[index];
              }
            },
          );
          traderLegValues.forEach((legNotional, index) => {
            if (legNotional !== undefined && !isNaN(legNotional)) {
              if (traderLegValues[index] > rfqLegsNotional[index]) {
                dispatch(
                  actionSnackBar.setSnackBar(
                    "error",
                    `Leg ${index + 1} is higher then RFQ Leg ${index + 1} by ${
                      traderLegValues[index] - rfqLegsNotional[index]
                    }`,
                    3000,
                  ),
                );
              }
            }
          });
          dispatch(
            RfqPostTradeSlice.setPostTradeAmountLeftToNotionalSplit(
              notionalLeftToSplit,
            ),
          );
          // ! DELTA arrangement and validation
          let deltaNotionalLeftToSplit;
          traderDeltaValues = totalDeltas?.map((legNumber) => {
            let currentDeltaValue = splitArrValues.reduce(
              (previous, current) => {
                return (
                  previous +
                  Number(current[`Delta${legNumber}NotionalSplitOption`])
                );
              },
              0,
            );
            return currentDeltaValue.toFixed(4);
          });
          deltaNotionalLeftToSplit = currentDeltasNotional.map(
            (deltaNotional, index) => {
              if (
                traderDeltaValues[index] !== 0 &&
                !isNaN(traderDeltaValues[index])
              ) {
                let valueToReturn =
                  currentDeltasNotional[index] - traderDeltaValues[index];
                return Number(valueToReturn.toFixed(4)); // Manage precision and convert back to number
              } else {
                return currentDeltasNotional[index];
              }
            },
          );
          dispatch(
            RfqPostTradeSlice.postTradeAmountLeftToDeltaNotionalSplit(
              deltaNotionalLeftToSplit,
            ),
          );
        }
      } else {
        if (splitArrValues.length > 0) {
          let traderNearNotional;
          let traderFarNotional;
          if (currentFarNotional !== 0 && currentNearNotional !== 0) {
            traderNearNotional = splitArrValues.reduce((previous, current) => {
              return previous + +current.nearNotional;
            }, 0);
            traderFarNotional = splitArrValues.reduce((previous, current) => {
              return previous + +current.farNotional;
            }, 0);
            if (traderNearNotional > currentNearNotional) {
              dispatch(
                actionSnackBar.setSnackBar(
                  "error",
                  "Near notional can not be greater than RFQ NEAR Notional",
                  3000,
                ),
              );
            }
            dispatch(
              RfqPostTradeSlice.postTradeNearNotionalLeftToSplit(
                currentNearNotional - traderNearNotional,
              ),
            );
            if (traderFarNotional > currentFarNotional) {
              dispatch(
                actionSnackBar.setSnackBar(
                  "error",
                  "Far notional can not be greater than RFQ FAR Notional",
                  3000,
                ),
              );
            }
            dispatch(
              RfqPostTradeSlice.postTradeFarNotionalLeftToSplit(
                currentFarNotional - traderFarNotional,
              ),
            );
          }
        }
      }
    }
  }, [splitArrValues]);
};

export default PostTradeSplitCalculator;
