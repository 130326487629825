import React, { useEffect, useRef, useState } from "react";
import { getMakorXData } from "../utils/apiCalls";
import {
  Grid,
  Typography,
  InputBase,
  InputAdornment,
  IconButton,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { useStyles } from "../style/derivsClientsStyle";
import SearchIcon from "@material-ui/icons/Search";
import { CompaniesContainer } from "../companiesContainer/CompaniesContainer";
import "../style/style.css";
import { TradersContainer } from "../tradersContainer/TradersContainer";
import { Contact } from "../contact/Contact";
import { ClearingAccounts } from "../clearingACcounts/ClearingAccounts";

export const ClientStaticMain = () => {
  const [clientsList, setClientsList] = useState(null);
  const [tradersList, setTradersList] = useState(null);
  const [filteredClientList, setFilteredClientList] = useState(null);
  const [pickedTraders, setPickedTraders] = useState(null);
  const [pickedClientByTrader, setPickedClientByTrader] = useState(null);
  const [pickedTraderObj, setPickedTraderObj] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [searchClientValue, setSearchClientValue] = useState(null);
  const [searchTraderValue, setSearchTraderValue] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    getMakorXData(
      setClientsList,
      setCompanies,
      setFilteredClientList,
      setTradersList,
    );
  }, []);

  const handleSearchClientByValue = (value) => {
    setPickedTraders([]);
    setSearchTraderValue("");
    setSearchClientValue(value);
    setFilteredClientList(
      clientsList.filter((client) => client.name.toLowerCase().includes(value)),
    );
  };
  const handleSearchTraderByValue = (value) => {
    setSearchClientValue("");
    setFilteredClientList([...clientsList]);
    setSearchTraderValue(value);
    setPickedTraders(
      tradersList !== null &&
        tradersList.filter((tr) => tr.name.toLowerCase().includes(value)),
    );
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid className={classes.teamText}>
        <Typography className={classes.headerTypography}>
          US Listed Client Static
        </Typography>
      </Grid>{" "}
      <Grid item className={classes.clientsContainer} xs={12}>
        {/* clients header */}
        <Grid
          className={classes.TypographyContainer}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item xs={2}>
            <Typography className={classes.containerTypography}>
              Clients
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.containerTypographyWithBorder}>
              Traders
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography className={classes.containerTypographyWithBorder}>
              Static
            </Typography>
          </Grid>
        </Grid>

        {/*  columns container*/}
        {companies === null ? (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={classes.columnsContainer}
          >
            <Box sx={{ display: "flex" }}>
              <CircularProgress className={classes.circularProgress} />
            </Box>
          </Grid>
        ) : (
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            className={classes.columnsContainer}
          >
            {/* clients column */}
            <Grid item md={2} className={classes.clientsColumn}>
              {/* search input */}
              <Grid
                item
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <InputBase
                  className={classes.searchInput}
                  variant="outlined"
                  onChange={(e) =>
                    handleSearchClientByValue(e.target.value.toLowerCase())
                  }
                  value={searchClientValue}
                  placeholder="Search"
                  endAdornment={
                    <InputAdornment position="start">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
              {/* companies list */}
              <Grid>
                <CompaniesContainer
                  clientsList={filteredClientList}
                  companies={companies}
                  setPickedTraders={setPickedTraders}
                  searchTraderValue={searchTraderValue}
                  pickedClientByTrader={pickedClientByTrader}
                  searchClientValue={searchClientValue}
                />
              </Grid>
            </Grid>
            {/* traders column */}
            <Grid md={2} className={classes.tradersColumn}>
              {/* search input */}
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <InputBase
                  className={classes.searchInput}
                  variant="outlined"
                  onChange={(e) =>
                    handleSearchTraderByValue(e.target.value.toLowerCase())
                  }
                  value={searchTraderValue}
                  placeholder="Search"
                  endAdornment={
                    <InputAdornment position="start">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid>
                <TradersContainer
                  pickedTraders={pickedTraders}
                  setPickedClientByTrader={setPickedClientByTrader}
                  setPickedTraderObj={setPickedTraderObj}
                />
              </Grid>
            </Grid>
            {/* static fields */}
            <Grid md={7} className={classes.staticColumn}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                className={classes.staticContainer}
              >
                <Contact
                  pickedTraderObj={pickedTraderObj}
                  searchClientValue={searchClientValue}
                  searchTraderValue={searchTraderValue}
                />

                <ClearingAccounts
                  pickedTraderObj={pickedTraderObj}
                  searchClientValue={searchClientValue}
                  searchTraderValue={searchTraderValue}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
