import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import {Grid , Typography,} from "@material-ui/core";


const QuoteBookParamsPctNotional = ({rfqParamaters}) => {
  const classes = useStyles();

    return(
        <Grid
        className={classes.rfqPctNotional}
        style={{ marginTop: "10px" }}>
             
            <Grid item xs={12}>
              <Typography style={{ color: "white", fontSize: "1.25" }}>
                QUOTE TYPE: {rfqParamaters?.quote_type_str}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ color: "white", fontSize: "1.25" }}>
                NUMBER OF LEGS: {rfqParamaters?.leg_count}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ color: "white", fontSize: "1.25" }}>
                DIRECTION: {rfqParamaters?.directions}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ color: "white", fontSize: "1.25" }}>
                QUOTE_FORMAT: {rfqParamaters?.quote_format}
              </Typography>
            </Grid>
           {
            rfqParamaters?.is_all_vanilla ?
            <>
                  <Grid item xs={12}>
              <Typography style={{ color: "white", fontSize: "1.25" }}>
                BBG VOLS: {rfqParamaters?.bbg_vols}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ color: "white", fontSize: "1.25" }}>
                VEGA: {rfqParamaters?.vegas}
              </Typography>{" "}
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ color: "white", fontSize: "1.25" }}>
                MID PREMIUM: {rfqParamaters?.bbg_mid_premium}
              </Typography>
            </Grid> </> : ""
           }
            </Grid>
    )
}
export default QuoteBookParamsPctNotional
export const useStyles = makeStyles((theme) => ({
    "& .MuiInputBase-input MuiInput-input": {
      fontSize: "400px",
      color: "white",
    },
    rfqPctNotional: {
      border: "1px solid #686B76",
      padding: "20px",
      marginTop: "5px",
      borderRadius: "8px",
    },
    rfqParameters: {
      fontSize: "1.0625rem",
      borderRadius: "8px",
      display: "flex",
      justifyContent: "space-around",
      border: "1px solid #686B76",
      padding: "20px",
      marginTop: "5px",
      height: "fit-content",
    },
    quoteParamatersHeader: {
      color: " var(--unnamed-color-989ea8)",
      font: "normal normal normal 12px/10px Inter",
      letterSpacing: "0px",
      color: " #989EA8",
    },
    quoteParamatersText: {
      marginTop: "5px",
      color: " var(--unnamed-color-989ea8)",
      font: "normal normal normal 10px/2px Inter",
      letterSpacing: "0px",
      color: "#FFFFFF",
    },
  }));