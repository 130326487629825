import axios from "axios";
import { getStaticData } from "../../bookingDialog/BookingDialog";
import { getUniqueCompanies } from "../../bookingDialog/bookingTables/utils/cellEditorSelector";
import { END_POINT } from "../../../../utils";

export const getMakorXData = async (
  setClientsList,
  setCompanies,
  setFilteredClientList,
  setTradersList,
) => {
  const staticData = await getStaticData();
  setClientsList(staticData.data.clients);
  setFilteredClientList(staticData.data.clients);
  setCompanies(getUniqueCompanies(staticData.data.clients));
  const tradersList = await getMakorXTradersList();
  setTradersList(tradersList.data);
};

export const getMakorXTradersList = async () => {
  return axios.get(
    `https://api.makor-x.com/le_tool/client_traders?api_key=${process.env.REACT_APP_MAKOR_X_KEY}`,
  );
};

export const getClientTraderEmailDetails = async (
  clientId,
  traderId,
  setToEmailList,
  setCcEmailList,
  setOpenStatus,
) => {
  const url = `${process.env.REACT_APP_BASE_URL}${END_POINT.DERIVS_CLIENT_TRADER_EMAIL_DETAILS}`;
  const params = {
    clientId,
    traderId,
  };
  const token = sessionStorage.getItem("token");
  await axios
    .get(url, {
      params,
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      setToEmailList(response.data.toEmailList);
      setCcEmailList(response.data.ccEmailList);
      setOpenStatus({
        recipient: response.data.toEmailList.length > 0 ? true : false,
        cc: response.data.ccEmailList.length > 0 ? true : false,
      });
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
};
export const updateClientTraderEmailDetails = async (emailObj) => {
  const url = `${process.env.REACT_APP_BASE_URL}${END_POINT.DERIVS_CLIENT_TRADER_EMAIL_DETAILS}`;
  const body = {
    id: emailObj.rowId,
    email: emailObj.email,
  };
  const token = sessionStorage.getItem("token");
  try {
    await axios.put(url, body, {
      headers: {
        Authorization: token,
      },
    });
  } catch (error) {
    throw new Error(error.response.data.message || "An error occurred");
  }
};
export const addNewClientTraderEmail = async (emailObj) => {
  const url = `${process.env.REACT_APP_BASE_URL}${END_POINT.DERIVS_CLIENT_TRADER_EMAIL_DETAILS}`;

  const token = sessionStorage.getItem("token");
  try {
    const response = await axios.post(
      url,
      { emailObj },
      {
        headers: {
          Authorization: token,
        },
      },
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message || "An error occurred");
  }
};

export const deleteClientTraderEmailDetails = async (rowId) => {
  const url = `${process.env.REACT_APP_BASE_URL}${END_POINT.DERIVS_CLIENT_TRADER_EMAIL_DETAILS}/${rowId}`;

  const token = sessionStorage.getItem("token");
  try {
    await axios.delete(url, {
      headers: {
        Authorization: token,
      },
    });
  } catch (error) {
    throw new Error(error.response.data.message || "An error occurred");
  }
};
export const switchClientTraderEmail = async (rowId, type) => {
  const url = `${process.env.REACT_APP_BASE_URL}${END_POINT.DERIVS_CLIENT_TRADER_EMAIL_DETAILS}`;

  const token = sessionStorage.getItem("token");
  const body = {
    id: rowId,
    type,
  };
  try {
    await axios.put(url, body, {
      headers: {
        Authorization: token,
      },
    });
  } catch (error) {
    throw new Error(error.response.data.message || "An error occurred");
  }
};
export const getClientTraderClearanceAccounts = async (
  clientId,
  traderId,
  setAccountsList,
) => {
  const url = `${process.env.REACT_APP_BASE_URL}${END_POINT.DERIVS_CLIENT_TRADER_CLEARANCE_ACC}`;
  const params = {
    clientId,
    traderId,
  };
  const token = sessionStorage.getItem("token");
  try {
    await axios
      .get(url, {
        params,
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setAccountsList(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  } catch (error) {
    throw new Error(error.response.data.message || "An error occurred");
  }
};
export const updateClientTraderClearanceAcc = async (accountObj) => {
  const url = `${process.env.REACT_APP_BASE_URL}${END_POINT.DERIVS_CLIENT_TRADER_CLEARANCE_ACC}`;
  const body = {
    id: accountObj.id,
    clearanceAcc: accountObj.clearanceAcc,
  };
  const token = sessionStorage.getItem("token");
  try {
    await axios.put(url, body, {
      headers: {
        Authorization: token,
      },
    });
  } catch (error) {
    throw new Error(error.response.data.message || "An error occurred");
  }
};
export const addNewClientTraderClearanceAcc = async (accountObj) => {
  const url = `${process.env.REACT_APP_BASE_URL}${END_POINT.DERIVS_CLIENT_TRADER_CLEARANCE_ACC}`;

  const token = sessionStorage.getItem("token");
  try {
    const response = await axios.post(
      url,
      { accountObj },
      {
        headers: {
          Authorization: token,
        },
      },
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message || "An error occurred");
  }
};
export const deleteClientTraderClearanceAcc = async (id) => {
  const url = `${process.env.REACT_APP_BASE_URL}${END_POINT.DERIVS_CLIENT_TRADER_CLEARANCE_ACC}/${id}`;

  const token = sessionStorage.getItem("token");
  try {
    await axios.delete(url, {
      headers: {
        Authorization: token,
      },
    });
  } catch (error) {
    throw new Error(error.response.data.message || "An error occurred");
  }
};
export const updateEmailListMakorX = async (pickedTraderObj, arr1, arr2) => {
  try {
    const uniqueEmailList = getUniqueEmails(arr1, arr2);
    const url = `https://api.makor-x.com/le_tool/client_trader_emails/${pickedTraderObj.id}?api_key=${process.env.REACT_APP_MAKOR_X_KEY}`;
    const client_trader_emails = [...uniqueEmailList];
    try {
      if (process.env.REACT_APP_ENVIRONMENT === "prod") {
        await axios.put(url, { client_trader_emails });
      }
    } catch (error) {
      throw new Error(error.response.data.message || "An error occurred");
    }
  } catch (error) {
    console.log(error);
  }
};

export const getUniqueEmails = (array1, array2) => {
  const combinedArray = [...array1, ...array2];
  const uniqueEmails = new Set(combinedArray.map((obj) => obj.email));
  return Array.from(uniqueEmails);
};
