import "./App.css";
import { RfqBlotter } from "./components/fx/rfq/rfqBlotter/RfqBlotter";
import SideBar from "./components/fx/sideBar/SideBar";
import Login from "./components/fx/login/Login";
import { RfqWorkspace } from "./components/fx/rfq/rfqWorkspace/RfqWorkspace";
import FiBlotter from "./components/fi/FiBlotter";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import soundEffect from "./assets/my-sounds/soundEffect.mp3";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import SnackBar from "../src/components/fx/snackbar/Snackbar";
import * as rfqSlice from "./store/rfq/rfqSlice";
import * as userSlice from "./store/users/usersSlice";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import DataMgmtMain from "./components/data_managment/DataMgmtMain";
import ChangePassword from "./components/fx/ChangePassword/ChangePassword";
import ClientBlotter from "./components/fi/fiClientStatic/ClientBlotter";

import { ws, connectWS } from "./services/websocket";
import Rates from "./components/fi/rates/Rates";
import { ClientRequest } from "./components/derivs pricer/clientRequest/ClientRequest";
import { DerivsPricerHome } from "./components/derivs pricer/derivsPricerHome/DerivsPricerHome";
import { EquitiesHome } from "./components/equities spread monitor/home/EquitiesHome";
import { MiniFeed } from "./components/derivs pricer/miniFeed/MiniFeed";
import { useLocation } from "react-router-dom";
import { DataFeed } from "./components/derivs pricer/dataFeed/DataFeed";
import { ClientStaticMain } from "./components/derivs pricer/pricerClientStatic/main/ClientStaticMain";
import UsersManagement from "./components/users/UsersManagement";

const audio = new Audio();
function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isLoggedIn = useSelector((state) => state.userSlice.auth);
  // const id = useSelector((state) => state.userSlice.id);
  const token = sessionStorage.getItem("token");
  const entrances = sessionStorage.getItem("entrances");
  const [data, setRfqData] = useState([]);
  const rfqs = useSelector((state) => state.rfqSlice.rfq);
  const rfq = useSelector((state) => state.rfqSlice.rfqId);
  const rfqButtonStatus = useSelector(
    (state) => state.rfqSlice.rfqButtonStatus,
  );

  const socketStatus = useSelector((state) => state.rfqSlice.socketStatus);

  // const interval = setInterval(() => {
  //   console.log("current ready state is:", ws.readyState);
  //   if (ws.readyState === 3) {
  //     console.log("Connection is to websocket lost");
  //     // console.log(token.replace("Bearer ", ""));
  //     connectWS(token.replace("Bearer ", ""), dispatch, rfqSlice);
  //   }
  // }, 10000);
  const socketMaintainer = () => {
    if (ws !== null) {
      if (ws.readyState === 3) {
        console.log(
          "Connection is to websocket lost, caught by setTimeout => reconnecting...",
        );
        connectWS(token.replace("Bearer ", ""), dispatch, rfqSlice);
      }
    }

    setTimeout(socketMaintainer, 10000);
  };
  socketMaintainer();

  useEffect(() => {
    // const traderId = sessionStorage.getItem("id");

    const getRfqTrade = (message) => {
      let copyRfqs = [...rfqs];
      switch (message.data.type) {
        case "get_new_rfq":
          let newRfq = message.data.rfqTrade;
          dispatch(userSlice.getUserId()).then(async (traderId) => {
            if (
              ((Number(newRfq[0].traderId) === Number(traderId) ||
                Number(newRfq[0].traderId) ===
                  Number(sessionStorage.getItem("id"))) &&
                newRfq[0].status === "Draft") ||
              newRfq[0].status !== "Draft"
            ) {
              if (newRfq[0].rfq_option_request) {
                setRfqData([...newRfq, ...data]);
                newRfq[0].color = true;
                newRfq[0].checked = false;
                audio.src = soundEffect;
                audio.play();
                dispatch(rfqSlice.addNewRfqFromWebSocketToRfqArray(newRfq[0]));
                dispatch(rfqSlice.controleNotificationAcount(newRfq[0]));
                // dispatch(rfqSlice.getRfqByIdSuccess(newRfq));
                setTimeout(() => {
                  dispatch(rfqSlice.idOfRfq(newRfq[0].rfq_id));
                  setRfqData([]);
                }, 30000);
              }
            }
          });

          break;
        case "updated_rfq":
          let updatedRfq = message.data.rfqTrade;
          dispatch(rfqSlice.updateRfqHandler(updatedRfq, navigate));
          if (updatedRfq[0].status !== "Draft") {
            audio.src = soundEffect;
            //  audio.play();
            //  setTimeout(() => {
            //    dispatch(rfqSlice.idOfRfq(newRfq[0].rfq_id));
            //    setRfqData([]);
            //  }, 30000);
          }
          console.log("socket: updated_rfq", updatedRfq);

          break;
        case "update_rfq_status":
          let rfqStatus = message.data.rfqTrade;
          if (rfqStatus !== undefined) {
            dispatch(rfqSlice.updateRfqStatus(rfqStatus));
          }
          break;
        default:
          break;
      }
    };
    window.addEventListener("message", getRfqTrade);

    return () => {
      window.removeEventListener("message", getRfqTrade);
    };
  }, []);
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container>
        <SnackBar />
        <Grid
          item
          style={{
            width:
              !pathname.includes("/pricer_mini_feed") &&
              !pathname.includes("/pricer_history")
                ? "72px"
                : 0,
          }}
        >
          {(isLoggedIn !== "" || token !== null) &&
          entrances > 0 &&
          !pathname.includes("/pricer_mini_feed") &&
          !pathname.includes("/pricer_history") ? (
            <SideBar />
          ) : null}
        </Grid>
        <SnackBar />
        <Grid item style={{ width: "calc(100vw - 106px)" }}>
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path="changePassword" element={<ChangePassword />} />
            {isLoggedIn !== "" || token !== null ? (
              <>
                <Route path="/rfqblotter" element={<RfqBlotter />} />
                <Route path="/fiblotter" element={<FiBlotter />} />
                <Route path="/rfqworkspace" element={<RfqWorkspace />} />
                <Route path="/rfqworkspace/:id" element={<RfqWorkspace />} />
                <Route path="/datamgmt" element={<DataMgmtMain />} />
                <Route path="/clientblotter" element={<ClientBlotter />} />
                <Route path="/rates" element={<Rates />} />
                <Route path="/pricer" element={<DerivsPricerHome />} />
                <Route path="/pricer_mini_feed" element={<MiniFeed />} />
                <Route path="/pricer_history" element={<DataFeed />} />
                <Route path="/equities_spread" element={<EquitiesHome />} />
                <Route path="/users_management" element={<UsersManagement />} />
                <Route
                  path="/derivs_client_static"
                  element={<ClientStaticMain />}
                />
              </>
            ) : (
              <Route path="*" element={<Navigate to={"/login"} />} />
            )}
          </Routes>
        </Grid>
        <Grid></Grid>
        <Grid style={{ marginTop: "10px" }}></Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

export default App;
