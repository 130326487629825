import {
  makeStyles,
  TableCell,
  TableRow,
  TextField,
  withStyles,
} from "@material-ui/core";
const font = "'Mulish', sans-serif";

export const useStyles = makeStyles(() => ({
  select: {
    height: "40px",
    width: "100%",
    "& .MuiOutlinedInput-input": {
      color: "#FFFFFF",
      borderRadius: "4px",
      padding: 0,
      paddingLeft: "10px",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#686B76",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#FFFFFF",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#2692FF",
    },

    "& .MuiInputLabel-root.Mui-focused": {
      color: "#2692FF",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#2692FF",
    },
    "& .MuiSvgIcon-root ": {
      fill: "#ffffff !important",
    },
  },
  deleteDialog: {
    minWidth: "350px",
    height: "130px",
    color: "#FFFFFF",
    backgroundColor: "#2b2b2b",
  },
  search: {
    textAlign: "left",
    letterSpacing: "0px",
    color: "#ffffff",
    opacity: 1,
    "& .MuiInputBase-input": {
      margin: "10px",
    },
  },
}));

export const StyledTableRow = withStyles(() => ({
  root: {
    backgroundColor: "#171717",
    height: "27px",
    "&:hover": {
      backgroundColor: "#8e8585",
    },
    "& td:first-child": {
      borderRadius: "4px 0px 0px 4px",
    },
    "& td:last-child": {
      borderRadius: "0px 4px 4px 0px",
    },
  },
}))(TableRow);

export const StyledTableCell = withStyles(() => ({
  root: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "noWrap",
    textAlign: "center",
    paddingBlock: 1,
    paddingInline: 5,
    color: "#ffffff !important",
    borderBottom: "1px solid #b3b3b3",
    "&.MuiTableCell-stickyHeader": {
      backgroundColor: "#000000",
    },
    fontFamily: font,
    fontSize: "1rem",
  },
}))(TableCell);

export const StyledHeaderTableRow = withStyles(() => ({
  root: {
    "& :first-child": {
      borderRadius: "4px 0px 0px 0px",
    },
    "& :last-child": {
      borderRadius: "0px 4px 0px 0px",
    },
  },
}))(TableRow);

export const StyledTextField = withStyles(() => ({
  root: {
    minWidth: "100%",
    borderRadius: "0px",
    backgroundColor: "#ffffff",
    "& label.Mui-focused": {
      color: "transparent",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "transparent",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "transparent",
      },
      "&:hover fieldset": {
        borderColor: "transparent",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
      },
    },
    "& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl":
      {
        color: "#000000",

        padding: "0px",
      },
  },
}))(TextField);
