import {
  TableCell,
  TableRow,
  TableSortLabel,
  makeStyles,
  withStyles,
  Radio,
} from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  checkbox: {
    color: "#686B76",
    "&.MuiIconButton-root": {
      borderRadius: "3px",
      height: "9px",
      width: "10px",
    },
    "& .Mui-checked": {
      color: "#13c552",
    },
  },
}));

export const StyledTableRow = withStyles(() => ({
  root: {
    height: "27px",
    "&:hover": {
      cursor: "pointer",
    },
    "& td:first-child": {
      borderRadius: "4px 0px 0px 4px",
    },
    "& td:last-child": {
      borderRadius: "0px 4px 4px 0px",
    },
  },
}))(TableRow);

export const StyledTableCell = withStyles(() => ({
  root: {
    fontSize: "1rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "noWrap",
    textAlign: "center",
    paddingBlock: 1,
    paddingInline: 5,
    color: "#ffffff !important",
    borderBottom: (props) =>
      !props.rateIndex && !props.linkedArrLength
        ? "1px solid #b3b3b3"
        : props.rateIndex !== props.linkedArrLength - 1
        ? "none"
        : "1px solid #b3b3b3",
    "&.MuiTableCell-stickyHeader": {
      backgroundColor: "#000000",
    },
  },

  // props.rateIndex !== (props.linkedArrLength-1) ? "none" : "1px solid #b3b3b3"
}))(TableCell);

export const StyledHeaderTableRow = withStyles(() => ({
  root: {
    "& :first-child": {
      borderRadius: "4px 0px 0px 0px",
    },
    "& :last-child": {
      borderRadius: "0px 4px 0px 0px",
    },
  },
}))(TableRow);

export const GreenRadio = withStyles({
  root: {
    color: "#43a047",
    "&$checked": {
      color: "#43a047",
    },
  },
  checked: {},
})(Radio);
