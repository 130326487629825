import React, { useState, useEffect } from "react";
import { RfqBlotterTable } from "../rfqBlotterTable/RfqBlotterTable";
import { useDispatch, useSelector } from "react-redux";
import * as rfqSlice from "../../../../store/rfq/rfqSlice";
import * as actionSnackBar from "../../../../store/snackbar/action";
import {
  Button,
  InputAdornment,
  Grid,
  Box,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as SearchIcon } from "../../../../assets/search.svg";
import IconButton from "@material-ui/core/IconButton";
import { Link, useNavigate } from "react-router-dom";
import "./RfqBlotter.css";
import { InputBase } from "@mui/material";
import { RfqInfoModal } from "../../modals/rfqInfoModal/RfqInfoModal";
import axios from "axios";
import { END_POINT } from "../../../../utils";
import CircleIcon from "@mui/icons-material/Circle";
import * as rfqAggregatorSlice from "../../../../store/rfq/rfqAggregatorSlice";
import * as rfqPostTradeSlice from "../../../../store/rfq/rfqPostTradeSlice";
import {
  apiRefreshQuoteBook,
  buildOrderBook,
  apiGetDealStats,
} from "../../../../services/apiCalls/quotsBookApi";
import { buildInitialDataInBlotterTable } from "../../../../services/rfqQuoteBook/initialDataTable";
import { createQuoteBookDict } from "../../../../services/rfqWorkspace/createQuoteBookDict";
import { ws, connectWS, sendEvent } from "../../../../services/websocket";
import { useDebounce } from "../../../derivs pricer/derivsPricerBlotter/utils/utilsFunctions";

export const RfqBlotter = () => {
  const token = sessionStorage.getItem("token");
  const rfq = useSelector((state) => state.rfqSlice.rfq);
  const dispatch = useDispatch();
  const rfqItems = useSelector((state) => state.rfqSlice.rfq);
  const classes = useStyles();
  const rfqStatus = sessionStorage.getItem("rfqStatus");
  const copeyRfq = [...rfqItems];
  const [search, setSearch] = useState("");
  const [date, setDate] = useState("");
  const [selectedRfq, setSelectedRfq] = useState("");
  const socketStatus = useSelector((state) => state.rfqSlice.socketStatus);
  const rfqInstanceList = useSelector(
    (state) => state.rfqSlice.rfqInstanceList,
  );
  const navigate = useNavigate();
  let { volLegParams } = useSelector((state) => state.rfqAggregatorSlice);
  let rfqParamaters = useSelector(
    (state) => state.rfqAggregatorSlice.RfqQuoteParameters,
  );
  const RfqId = useSelector((state) => state.rfqSlice.rfqId.items);
  const tableData = useSelector((state) => state.rfqSlice.tableData);
  const status = useSelector((state) => state.rfqSlice.rfqButtonStatus);
  let dealStatsData = useSelector((state) => state.rfqSlice.dealStatsData);
  let recommendationRfqs = useSelector(
    (state) => state.rfqSlice.recommendationRfqs,
  );

  let filterVolLegParams = [];
  if (selectedRfq !== undefined) {
    filterVolLegParams = volLegParams.filter(
      (params) => Number(params.rfq_id) === Number(selectedRfq),
    );
  }
  const findCheckedValue = () => {
    const findRfqIndex = copeyRfq.find((element) => element.checked === true);
    return findRfqIndex === undefined ? false : true;
  };
  const findStatusRfq = () => {
    const index = copeyRfq.find((element) => element.rfq_id === selectedRfq);

    if (
      (index && index.status === "Live") ||
      (index && index.status === "Idea") ||
      (index && index.status === "Draft")
    ) {
      return true;
    } else {
      return false;
    }
  };
  const searchFilter = (e) => {
    const searchText = e.target.value;
    setSearch(searchText);
  };
  const debouncedSearchTerm = useDebounce(search, 500);

  const dateFilter = (e) => {
    const dateText = e.target.value;
    setDate(dateText);
  };
  const subToRfqQuote = (selectedRfq) => {
    if (ws !== null) {
      sendEvent({
        type: "subscribe_to_rfq_quote_id",
        data: { rfqId: `${selectedRfq}` },
        metaData: {},
      });
    } else {
      connectWS(token.replace("Bearer ", ""), dispatch, rfqSlice);
      sendEvent({
        type: "subscribe_to_rfq_quote_id",
        data: { rfqId: `${selectedRfq}` },
        metaData: {},
      });
    }
  };
  const open = () => {
    sessionStorage.setItem("rfqId", selectedRfq);

    subToRfqQuote(selectedRfq);
    const findRfq = rfq.find((rfq) => {
      return Number(rfq.rfq_id) === Number(selectedRfq);
    });
    dispatch(rfqSlice.updateOvmlTail(findRfq.ovml_tail));
    dispatch(rfqSlice.updateRfqStr(findRfq.rfq_option_request));
    dispatch(rfqSlice.updatRfqButtonStatus("open"));
    dispatch(rfqSlice.getCheckedRfq(selectedRfq));

    sessionStorage.setItem("rfqId", selectedRfq);
    sessionStorage.setItem("status", "open");
    sessionStorage.setItem("rfqMode", "rfq view");

    updateRfqBookingModeDetails(selectedRfq);
    const filteredRecommendation = recommendationRfqs.filter(
      (rfq) => Number(rfq.rfqId) === Number(selectedRfq),
    );
    if (filteredRecommendation.length === 0) {
      getRecommendation(selectedRfq);
    }
  };
  const copy = () => {
    dispatch(rfqAggregatorSlice.setUserSpread([]));
    sessionStorage.setItem("status", "copy");
    subToRfqQuote(selectedRfq);
    dispatch(rfqSlice.clearRfqState());
    dispatch(rfqSlice.updatRfqButtonStatus("copy"));
    dispatch(rfqSlice.getCheckedRfq(selectedRfq));
    dispatch(rfqSlice.setStatusItem("Live"));
  };
  const contribute = () => {
    sessionStorage.setItem("rfqId", selectedRfq);
    sessionStorage.setItem("status", "contribute");
    sessionStorage.setItem("rfqMode", "rfq aggregator view");

    subToRfqQuote(selectedRfq);
    const findRfq = rfq.find((rfq) => {
      return rfq.rfq_id === selectedRfq;
    });
    sessionStorage.setItem("rfqId", selectedRfq);
    const filteredRecommendation = recommendationRfqs.filter(
      (rfq) => Number(rfq.rfqId) === Number(selectedRfq),
    );
    if (filteredRecommendation.length === 0) {
      getRecommendation(selectedRfq);
    }
    dispatch(rfqSlice.updateOvmlTail(findRfq.ovml_tail));
    dispatch(rfqSlice.updateRfqStr(findRfq.rfq_option_request));
    dispatch(rfqSlice.clearRfqId());
    dispatch(rfqSlice.updatRfqButtonStatus("contribute"));
    dispatch(rfqSlice.getCheckedRfq(selectedRfq));
    updateRfqBookingModeDetails(selectedRfq);
    navigate(`/rfqworkspace/${selectedRfq}`);
  };
  const getRecommendation = async (selectedRfq) => {
    const findRfq = rfq.find((rfq) => {
      return rfq.rfq_id === selectedRfq;
    });

    let rfqTableData = await createQuoteBookDict(selectedRfq);
    let rfq_object = {};
    try {
      rfq_object = {
        ...rfq_object,
        cross: findRfq.ccy_pair,
        created_on: findRfq.created_on,
        rfq_id: findRfq.rfq_id,
        rfq_table_data: rfqTableData,
      };
      const response = await axios.post(
        "https://fx-letool.letool-engine.makor-group.com/api/get_recommendations",
        rfq_object,
      );
      dispatch(
        rfqSlice.rfqRecommendationHandler({
          recommendationDetails: response.data,
          rfqId: selectedRfq,
        }),
      );
      dispatch(rfqSlice.updateLpPerdiction(response.data));
    } catch (error) {
      console.log(error);
    }
  };
  let updateRfqBookingModeDetails = async (id) => {
    const token = sessionStorage.getItem("token");
    const status = sessionStorage.getItem("status");
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}` +
          "rfq" +
          `/rfq_booking_details/${id}`,
        {
          headers: { Authorization: token },
        },
      );

      if (response.data.rfqDetails.length > 0) {
        dispatch(
          rfqSlice.updateRfqBookingModeUpdatingRows(response.data.rfqDetails),
        );
      } else {
        dispatch(rfqSlice.updateRfqBookingModeUpdatingRows([]));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const createNewRfq = () => {
    sessionStorage.setItem("status", "newRfq");
    sessionStorage.setItem("rfqMode", "rfq view");
    sessionStorage.setItem("rfqDealType", "VANILLA");
    dispatch(rfqSlice.clearRfqState());
    dispatch(rfqAggregatorSlice.setUserSpread([]));
    dispatch(rfqAggregatorSlice.updateQuoteParameterts({}));
    dispatch(rfqSlice.setStatusItem("Live"));
  };
  const getTabs = async () => {
    let res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}${
        END_POINT.GET_TABS
      }/${sessionStorage.getItem("id")}`,
      { headers: { Authorization: token } },
    );

    dispatch(rfqSlice.updatRfqInstanceList(res.data));
  };
  const canRfqBeDeleted = () => {
    if (
      Number(sessionStorage.getItem("id")) ===
        Number(sessionStorage.getItem("rfqTraderId")) &&
      (tableData.length === 0 || tableData.length === null) &&
      (rfqStatus === "Live" || rfqStatus === "Draft" || rfqStatus === "Idea")
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    dispatch(rfqSlice.getRfq(debouncedSearchTerm, date));
  }, [debouncedSearchTerm, date]);

  useEffect(() => {
    dispatch(rfqSlice.restartNotificationAcount());
    const obj = {
      value: 2,
      key: "postTradeSplitNumber",
    };
    dispatch(rfqPostTradeSlice.setPostTradeSplitNumber(obj));
    dispatch(rfqPostTradeSlice.resetPostTradeStats());
    dispatch(rfqPostTradeSlice.setSingleConfo(false));
    dispatch(rfqPostTradeSlice.clearCurrentClients());
    dispatch(rfqSlice.updateLpPerdiction([]));
    dispatch(rfqSlice.setStatusCtrlEnter(""));
  }, []);

  useEffect(() => {
    sessionStorage.setItem("status", "");
    const findRfqIndex = rfq.find((element) => element.checked === true);
    if (rfq.length > 0) {
      if (findRfqIndex) {
        if (findRfqIndex.rfq_id > 0) {
          setSelectedRfq(findRfqIndex.rfq_id);
          dispatch(rfqSlice.getRfqById(findRfqIndex.rfq_id));
        }
      }
    }
    sessionStorage.setItem("rfqMode", "");
    sessionStorage.removeItem("rfqId", "blotter_open");
    dispatch(rfqSlice.updateRequestFor(""));
    dispatch(rfqSlice.getRfqByIdSuccess([]));
    if (rfqInstanceList.length === 0) {
      getTabs();
    }
  }, []);
  useEffect(() => {
    if (ws !== null) {
      sendEvent({
        type: "get_rfq_trade",
        data: {},
        metaData: {},
        token: token.replace("Bearer ", ""),
      });
    } else {
      connectWS(token.replace("Bearer ", ""), dispatch, rfqSlice);
      sendEvent({
        type: "get_rfq_trade",
        data: {},
        metaData: {},
        token: token.replace("Bearer ", ""),
      });
    }
  }, []);

  useEffect(() => {
    const getRfqUpdate = (message) => {
      let copyRfqs = [...rfq];
      switch (message.data.type) {
        case "updated_rfq":
          break;
        case "update_rfq_status":
          let rfqStatus = message.data.rfqTrade;
          if (rfqStatus !== undefined) {
            dispatch(rfqSlice.updateRfqStatus(rfqStatus));
          }
          break;
        default:
          break;
      }
    };
    window.addEventListener("message", getRfqUpdate);

    return () => {
      window.removeEventListener("message", getRfqUpdate);
    };
  }, [rfq]);

  useEffect(() => {
    const getRfqTrade = async (message) => {
      switch (message.data.type) {
        case "get_rfq_quote":
          let quote = JSON.parse(message.data.rfqQuote);
          const rfqId = sessionStorage.getItem("rfqId");
          if (Number(rfqId) === Number(sessionStorage.getItem("rfqId"))) {
            if (RfqId.length > 0) {
              try {
                let rfqModalDetails = await axios.get(
                  `${process.env.REACT_APP_BASE_URL}` +
                    END_POINT.RFQ +
                    "/" +
                    END_POINT.GET_RFQ_MODALS_DETAILS +
                    `/${rfqId}`,
                  { headers: { Authorization: token } },
                );
                let {
                  dealStats,
                  orderBook,
                  quoteParameters,
                  rfqQuote,
                  rfqDetails,
                } = rfqModalDetails.data;
                dealStats = [
                  {
                    ...dealStats[0],
                    fwd_scale:
                      dealStats[0].userSpreads.length > 0 &&
                      dealStats[0].userSpreads[0].fwd_scale
                        ? dealStats[0].userSpreads[0].fwd_scale
                        : dealStats[0].fwd_scale,
                  },
                ];
                sessionStorage.setItem("rfqStatus", rfqDetails[0].status);
                dispatch(
                  rfqAggregatorSlice.setRfqDefaultQuoteParams(
                    quoteParameters.defaultQuoteParameters[0],
                  ),
                );
                if (quoteParameters.userQuoteParameters.length === 0) {
                  if (
                    dealStats[0].userSpreads.length > 0 &&
                    dealStats[0].userSpreads[0].userSpreads !== "NaN" &&
                    dealStats[0].userSpreads[0].userSpreadOffer !== "NaN"
                  ) {
                    dispatch(
                      rfqAggregatorSlice.setUsersSpreadhandler(
                        dealStats[0].userSpreads,
                      ),
                    );
                    let copyDealStatsData = { ...dealStats[0] };
                    if (
                      quoteParameters.defaultQuoteParameters[0].quote_type ===
                      "SWAP"
                    ) {
                      let regex = /[,\s]/g;
                      let marketBid = copyDealStatsData.market_bid.replace(
                        regex,
                        "",
                      );
                      let marketOffer = copyDealStatsData.market_offer.replace(
                        regex,
                        "",
                      );
                      copyDealStatsData.makor_bid = (
                        +marketBid - +dealStats[0].userSpreads[0].userSpreadBid
                      ).toFixed(3);
                      copyDealStatsData.makor_offer = (
                        +marketOffer +
                        +dealStats[0].userSpreads[0].userSpreadOffer
                      ).toFixed(3);
                    } else {
                      copyDealStatsData.makor_bid =
                        Number(copyDealStatsData.market_bid) -
                        Number(
                          dealStats[0].userSpreads[0].userSpreadBid,
                        ).toFixed(3);
                      copyDealStatsData.makor_offer = (
                        Number(copyDealStatsData.market_offer) +
                        Number(dealStats[0].userSpreads[0].userSpreadOffer)
                      ).toFixed(3);
                    }
                    let jtableDict = await createQuoteBookDict();
                    let quoteBook = await apiRefreshQuoteBook(
                      quoteParameters.defaultQuoteParameters[0].quote_type,
                      quote,
                      jtableDict,
                      quoteParameters.defaultQuoteParameters[0],
                    );
                    let initialQuote = buildInitialDataInBlotterTable(
                      quote,
                      quoteBook,
                    );
                    let orderBook = await buildOrderBook(initialQuote);
                    let dealStatsData = await apiGetDealStats(
                      quoteParameters.defaultQuoteParameters[0].quote_type,
                      jtableDict,
                      quoteParameters.defaultQuoteParameters[0],
                      copyDealStatsData,
                      orderBook,
                      true,
                    );
                    dispatch(rfqSlice.updateDealStatsData(dealStatsData));
                    dispatch(rfqSlice.updateTableData(orderBook));
                    dispatch(
                      rfqAggregatorSlice.updateQuoteParameterts(
                        quoteParameters.defaultQuoteParameters[0],
                      ),
                    );
                  } else {
                    if (
                      quoteParameters.defaultQuoteParameters[0].quote_type ===
                      "SWAP"
                    ) {
                      let regex = /[,\s]/g;
                      let marketBid = dealStats[0].market_bid.replace(
                        regex,
                        "",
                      );
                      let makorBid = dealStats[0].makor_bid.replace(regex, "");
                      let marketOffer = dealStats[0].market_offer.replace(
                        regex,
                        "",
                      );
                      let makorOffer = dealStats[0].makor_offer.replace(
                        regex,
                        "",
                      );

                      let userSpredDetails = [
                        {
                          userSpreadBid: (+marketBid - +makorBid).toFixed(3),
                          isBidUser: false,
                          isUserChangeBid: "0",
                          isUserChangeOffer: "0",
                          isUserChangeSpreadOffer: "0",
                          isUserChangeSpreadBid: "0",
                          userSpreadOffer: (+makorOffer - +marketOffer).toFixed(
                            3,
                          ),
                        },
                      ];
                      dispatch(
                        rfqAggregatorSlice.setUsersSpreadhandler(
                          userSpredDetails,
                        ),
                      );
                      dispatch(rfqSlice.updateTableData(orderBook));
                      dispatch(rfqSlice.updateDealStatsData(dealStats[0]));
                      dispatch(
                        rfqAggregatorSlice.updateQuoteParameterts(
                          quoteParameters.defaultQuoteParameters[0],
                        ),
                      );
                    } else {
                      let userSpredDetails = [
                        {
                          userSpreadBid: (
                            Number(dealStats[0].market_bid) -
                            Number(dealStats[0].makor_bid)
                          ).toFixed(3),
                          isBidUser: false,
                          isUserChangeBid: "0",
                          isUserChangeOffer: "0",
                          isUserChangeSpreadOffer: "0",
                          isUserChangeSpreadBid: "0",
                          userSpreadOffer: (
                            Number(dealStats[0].makor_offer) -
                            Number(dealStats[0].market_offer)
                          ).toFixed(3),
                        },
                      ];
                      dispatch(
                        rfqAggregatorSlice.setUsersSpreadhandler(
                          userSpredDetails,
                        ),
                      );
                      dispatch(rfqSlice.updateTableData(orderBook));
                      dispatch(rfqSlice.updateDealStatsData(dealStats[0]));
                      dispatch(
                        rfqAggregatorSlice.updateQuoteParameterts(
                          quoteParameters.defaultQuoteParameters[0],
                        ),
                      );
                    }
                  }
                } else if (quoteParameters.userQuoteParameters.length > 0) {
                  let quote = rfqQuote.map((q) => {
                    return {
                      ...q,
                      quoted_vol1: q.quotevol_1,
                      quoted_vol2: q.quotevol_2,
                    };
                  });
                  let jtableDict = await createQuoteBookDict();
                  let refreshQuoteBook = await apiRefreshQuoteBook(
                    quoteParameters.userQuoteParameters[0].quote_type,
                    quote,
                    jtableDict,
                    quoteParameters.userQuoteParameters[0],
                  );
                  let updatedOrderBook = await buildOrderBook(refreshQuoteBook);
                  let defaultDealStats = await apiGetDealStats(
                    quoteParameters.userQuoteParameters[0].quote_type,
                    jtableDict,
                    quoteParameters.userQuoteParameters[0],
                    dealStats[0],
                    updatedOrderBook,
                    false,
                  );

                  if (
                    dealStats[0].userSpreads.length > 0 &&
                    dealStats[0].userSpreads[0].userSpreads !== "NaN" &&
                    dealStats[0].userSpreads[0].userSpreadOffer !== "NaN"
                  ) {
                    dispatch(
                      rfqAggregatorSlice.setUsersSpreadhandler(
                        dealStats[0].userSpreads,
                      ),
                    );
                    let copyDealStatsData = { ...defaultDealStats };
                    if (
                      quoteParameters.defaultQuoteParameters[0].quote_type ===
                      "SWAP"
                    ) {
                      let regex = /[,\s]/g;
                      let marketBid = copyDealStatsData.market_bid.replace(
                        regex,
                        "",
                      );
                      let marketOffer = copyDealStatsData.market_offer.replace(
                        regex,
                        "",
                      );
                      copyDealStatsData.makor_bid = (
                        +marketBid - +dealStats[0].userSpreads[0].userSpreadBid
                      ).toFixed(3);
                      copyDealStatsData.makor_offer = (
                        +marketOffer +
                        +dealStats[0].userSpreads[0].userSpreadOffer
                      ).toFixed(3);
                    } else {
                      copyDealStatsData.makor_bid =
                        Number(copyDealStatsData.market_bid) -
                        Number(
                          dealStats[0].userSpreads[0].userSpreadBid,
                        ).toFixed(3);
                      copyDealStatsData.makor_offer = (
                        Number(copyDealStatsData.market_offer) +
                        Number(dealStats[0].userSpreads[0].userSpreadOffer)
                      ).toFixed(3);
                    }
                    let userDealStats = await apiGetDealStats(
                      quoteParameters.userQuoteParameters[0].quote_type,
                      jtableDict,
                      quoteParameters.userQuoteParameters[0],
                      copyDealStatsData,
                      updatedOrderBook,
                      true,
                    );
                    dispatch(rfqSlice.updateDealStatsData(userDealStats));
                    dispatch(
                      rfqAggregatorSlice.updateQuoteParameterts(
                        quoteParameters.userQuoteParameters[0],
                      ),
                    );
                    dispatch(rfqSlice.updateTableData(updatedOrderBook));
                  } else {
                    let userDealStats = await apiGetDealStats(
                      quoteParameters.userQuoteParameters[0].quote_type,
                      jtableDict,
                      quoteParameters.userQuoteParameters[0],
                      dealStats[0],
                      updatedOrderBook,
                      false,
                    );
                    if (
                      quoteParameters.defaultQuoteParameters[0].quote_type ===
                      "SWAP"
                    ) {
                      let regex = /[,\s]/g;
                      let marketBid = userDealStats.market_bid.replace(
                        regex,
                        "",
                      );
                      let makorBid = userDealStats.makor_bid.replace(regex, "");
                      let marketOffer = userDealStats.market_offer.replace(
                        regex,
                        "",
                      );
                      let makorOffer = userDealStats.makor_offer.replace(
                        regex,
                        "",
                      );

                      let userSpredDetails = [
                        {
                          userSpreadBid: (+marketBid - +makorBid).toFixed(3),
                          isBidUser: false,
                          isUserChangeBid: "0",
                          isUserChangeOffer: "0",
                          isUserChangeSpreadOffer: "0",
                          isUserChangeSpreadBid: "0",
                          userSpreadOffer: (+makorOffer - +marketOffer).toFixed(
                            3,
                          ),
                        },
                      ];
                      dispatch(
                        rfqAggregatorSlice.setUsersSpreadhandler(
                          userSpredDetails,
                        ),
                      );
                      dispatch(rfqSlice.updateTableData(orderBook));
                      dispatch(rfqSlice.updateDealStatsData(userDealStats));
                      dispatch(
                        rfqAggregatorSlice.updateQuoteParameterts(
                          quoteParameters.defaultQuoteParameters[0],
                        ),
                      );
                    } else {
                      let userSpredDetails = [
                        {
                          userSpreadBid: (
                            Number(userDealStats.market_bid) -
                            Number(userDealStats.makor_bid)
                          ).toFixed(3),
                          isBidUser: false,
                          isUserChangeBid: "0",
                          isUserChangeOffer: "0",
                          isUserChangeSpreadOffer: "0",
                          isUserChangeSpreadBid: "0",
                          userSpreadOffer: (
                            Number(userDealStats.makor_offer) -
                            Number(userDealStats.market_offer)
                          ).toFixed(3),
                        },
                      ];
                      dispatch(
                        rfqAggregatorSlice.setUsersSpreadhandler(
                          userSpredDetails,
                        ),
                      );
                      dispatch(rfqSlice.updateTableData(orderBook));
                      dispatch(rfqSlice.updateDealStatsData(userDealStats));
                      dispatch(
                        rfqAggregatorSlice.updateQuoteParameterts(
                          quoteParameters.defaultQuoteParameters[0],
                        ),
                      );
                    }
                  }
                }
              } catch (error) {
                console.log(error);
              }
            }
            if (RfqId.length === 0) {
              try {
                let rfqModalDetails = await axios.get(
                  `${process.env.REACT_APP_BASE_URL}` +
                    END_POINT.RFQ +
                    "/" +
                    END_POINT.GET_RFQ_MODALS_DETAILS +
                    `/${rfqId}`,
                  { headers: { Authorization: token } },
                );
                let {
                  dealStats,
                  orderBook,
                  quoteParameters,
                  rfqQuote,
                  rfqDetails,
                } = rfqModalDetails.data;
                sessionStorage.setItem("rfqStatus", rfqDetails[0].status);
                dealStats = [
                  {
                    ...dealStats[0],
                    fwd_scale:
                      dealStats[0].userSpreads.length > 0 &&
                      dealStats[0].userSpreads[0].fwd_scale
                        ? dealStats[0].userSpreads[0].fwd_scale
                        : dealStats[0].fwd_scale,
                  },
                ];
                dispatch(
                  rfqAggregatorSlice.setRfqDefaultQuoteParams(
                    quoteParameters.defaultQuoteParameters[0],
                  ),
                );
                if (quoteParameters.userQuoteParameters.length === 0) {
                  if (
                    dealStats[0].userSpreads.length > 0 &&
                    dealStats[0].userSpreads[0].userSpreads !== "NaN" &&
                    dealStats[0].userSpreads[0].userSpreadOffer !== "NaN"
                  ) {
                    dispatch(
                      rfqAggregatorSlice.setUsersSpreadhandler(
                        dealStats[0].userSpreads,
                      ),
                    );
                    let copyDealStatsData = { ...dealStats[0] };
                    if (
                      quoteParameters.defaultQuoteParameters[0].quote_type ===
                      "SWAP"
                    ) {
                      let regex = /[,\s]/g;
                      let marketBid = copyDealStatsData.market_bid.replace(
                        regex,
                        "",
                      );
                      let marketOffer = copyDealStatsData.market_offer.replace(
                        regex,
                        "",
                      );
                      copyDealStatsData.makor_bid = (
                        +marketBid - +dealStats[0].userSpreads[0].userSpreadBid
                      ).toFixed(3);
                      copyDealStatsData.makor_offer = (
                        +marketOffer +
                        +dealStats[0].userSpreads[0].userSpreadOffer
                      ).toFixed(3);
                    } else {
                      copyDealStatsData.makor_bid =
                        Number(copyDealStatsData.market_bid) -
                        Number(
                          dealStats[0].userSpreads[0].userSpreadBid,
                        ).toFixed(3);
                      copyDealStatsData.makor_offer = (
                        Number(copyDealStatsData.market_offer) +
                        Number(dealStats[0].userSpreads[0].userSpreadOffer)
                      ).toFixed(3);
                    }
                    let jtableDict = await createQuoteBookDict();
                    let quoteBook = await apiRefreshQuoteBook(
                      quoteParameters.defaultQuoteParameters[0].quote_type,
                      quote,
                      jtableDict,
                      quoteParameters.defaultQuoteParameters[0],
                    );

                    let initialQuote = buildInitialDataInBlotterTable(
                      quote,
                      quoteBook,
                    );

                    let orderBook = await buildOrderBook(initialQuote);
                    let dealStatsData = await apiGetDealStats(
                      quoteParameters.defaultQuoteParameters[0].quote_type,
                      jtableDict,
                      quoteParameters.defaultQuoteParameters[0],
                      copyDealStatsData,
                      orderBook,
                      true,
                    );
                    dispatch(rfqSlice.updateDealStatsData(dealStatsData));
                    dispatch(rfqSlice.updateTableData(orderBook));
                    dispatch(
                      rfqAggregatorSlice.updateQuoteParameterts(
                        quoteParameters.defaultQuoteParameters[0],
                      ),
                    );
                  } else {
                    if (
                      quoteParameters.defaultQuoteParameters[0].quote_type ===
                      "SWAP"
                    ) {
                      let regex = /[,\s]/g;
                      let marketBid = dealStats[0].market_bid.replace(
                        regex,
                        "",
                      );
                      let makorBid = dealStats[0].makor_bid.replace(regex, "");
                      let marketOffer = dealStats[0].market_offer.replace(
                        regex,
                        "",
                      );
                      let makorOffer = dealStats[0].makor_offer.replace(
                        regex,
                        "",
                      );

                      let userSpredDetails = [
                        {
                          userSpreadBid: (+marketBid - +makorBid).toFixed(3),
                          isBidUser: false,
                          isUserChangeBid: "0",
                          isUserChangeOffer: "0",
                          isUserChangeSpreadOffer: "0",
                          isUserChangeSpreadBid: "0",
                          userSpreadOffer: (+makorOffer - +marketOffer).toFixed(
                            3,
                          ),
                        },
                      ];
                      dispatch(
                        rfqAggregatorSlice.setUsersSpreadhandler(
                          userSpredDetails,
                        ),
                      );
                      dispatch(rfqSlice.updateTableData(orderBook));
                      dispatch(rfqSlice.updateDealStatsData(dealStats[0]));
                      dispatch(
                        rfqAggregatorSlice.updateQuoteParameterts(
                          quoteParameters.defaultQuoteParameters[0],
                        ),
                      );
                    } else {
                      let userSpredDetails = [
                        {
                          userSpreadBid: (
                            Number(dealStats[0].market_bid) -
                            Number(dealStats[0].makor_bid)
                          ).toFixed(3),
                          isBidUser: false,
                          isUserChangeBid: "0",
                          isUserChangeOffer: "0",
                          isUserChangeSpreadOffer: "0",
                          isUserChangeSpreadBid: "0",
                          userSpreadOffer: (
                            Number(dealStats[0].makor_offer) -
                            Number(dealStats[0].market_offer)
                          ).toFixed(3),
                        },
                      ];
                      dispatch(
                        rfqAggregatorSlice.setUsersSpreadhandler(
                          userSpredDetails,
                        ),
                      );
                      dispatch(rfqSlice.updateTableData(orderBook));
                      dispatch(rfqSlice.updateDealStatsData(dealStats[0]));
                      dispatch(
                        rfqAggregatorSlice.updateQuoteParameterts(
                          quoteParameters.defaultQuoteParameters[0],
                        ),
                      );
                    }
                  }
                } else if (quoteParameters.userQuoteParameters.length > 0) {
                  let quote = rfqQuote.map((q) => {
                    return {
                      ...q,
                      quoted_vol1: q.quotevol_1,
                      quoted_vol2: q.quotevol_2,
                    };
                  });
                  let jtableDict = await createQuoteBookDict();
                  let refreshQuoteBook = await apiRefreshQuoteBook(
                    quoteParameters.userQuoteParameters[0].quote_type,
                    quote,
                    jtableDict,
                    quoteParameters.userQuoteParameters[0],
                  );
                  let orderBook = await buildOrderBook(refreshQuoteBook);
                  if (
                    dealStats[0].userSpreads.length > 0 &&
                    dealStats[0].userSpreads[0].userSpreads !== "NaN" &&
                    dealStats[0].userSpreads[0].userSpreadOffer !== "NaN"
                  ) {
                    dispatch(
                      rfqAggregatorSlice.setUsersSpreadhandler(
                        dealStats[0].userSpreads,
                      ),
                    );
                    let copyDealStatsData = { ...dealStats[0] };
                    if (
                      quoteParameters.defaultQuoteParameters[0].quote_type ===
                      "SWAP"
                    ) {
                      let regex = /[,\s]/g;
                      let marketBid = copyDealStatsData.market_bid.replace(
                        regex,
                        "",
                      );
                      let marketOffer = copyDealStatsData.market_offer.replace(
                        regex,
                        "",
                      );
                      copyDealStatsData.makor_bid = (
                        +marketBid - +dealStats[0].userSpreads[0].userSpreadBid
                      ).toFixed(3);
                      copyDealStatsData.makor_offer = (
                        +marketOffer +
                        +dealStats[0].userSpreads[0].userSpreadOffer
                      ).toFixed(3);
                    } else {
                      copyDealStatsData.makor_bid =
                        Number(copyDealStatsData.market_bid) -
                        Number(
                          dealStats[0].userSpreads[0].userSpreadBid,
                        ).toFixed(3);
                      copyDealStatsData.makor_offer = (
                        Number(copyDealStatsData.market_offer) +
                        Number(dealStats[0].userSpreads[0].userSpreadOffer)
                      ).toFixed(3);
                    }
                    let dealStats = await apiGetDealStats(
                      quoteParameters.userQuoteParameters[0].quote_type,
                      jtableDict,
                      quoteParameters.userQuoteParameters[0],
                      copyDealStatsData,
                      orderBook,
                      true,
                    );
                    dispatch(rfqSlice.updateDealStatsData(dealStats));
                    dispatch(
                      rfqAggregatorSlice.updateQuoteParameterts(
                        quoteParameters.userQuoteParameters[0],
                      ),
                    );
                    dispatch(rfqSlice.updateTableData(orderBook));
                  } else {
                    let userDealStats = await apiGetDealStats(
                      quoteParameters.userQuoteParameters[0].quote_type,
                      jtableDict,
                      quoteParameters.userQuoteParameters[0],
                      dealStats[0],
                      orderBook,
                      false,
                    );
                    if (
                      quoteParameters.defaultQuoteParameters[0].quote_type ===
                      "SWAP"
                    ) {
                      let regex = /[,\s]/g;
                      let marketBid = userDealStats.market_bid.replace(
                        regex,
                        "",
                      );
                      let makorBid = userDealStats.makor_bid.replace(regex, "");
                      let marketOffer = userDealStats.market_offer.replace(
                        regex,
                        "",
                      );
                      let makorOffer = userDealStats.makor_offer.replace(
                        regex,
                        "",
                      );

                      let userSpredDetails = [
                        {
                          userSpreadBid: (+marketBid - +makorBid).toFixed(3),
                          isBidUser: false,
                          isUserChangeBid: "0",
                          isUserChangeOffer: "0",
                          isUserChangeSpreadOffer: "0",
                          isUserChangeSpreadBid: "0",
                          userSpreadOffer: (
                            +makorOffer - +marketOffer
                          ).toFixed(),
                        },
                      ];
                      dispatch(
                        rfqAggregatorSlice.setUsersSpreadhandler(
                          userSpredDetails,
                        ),
                      );
                      // dispatch(rfqSlice.updateTableData(orderBook));
                      // dispatch(rfqSlice.updateDealStatsData(userDealStats));
                      // dispatch(
                      //   rfqAggregatorSlice.updateQuoteParameterts(
                      //     quoteParameters.userQuoteParameters[0]
                      //   )
                      // );
                    } else {
                      let userSpredDetails = [
                        {
                          userSpreadBid: (
                            Number(userDealStats.market_bid) -
                            Number(userDealStats.makor_bid)
                          ).toFixed(3),
                          isBidUser: false,
                          isUserChangeBid: "0",
                          isUserChangeOffer: "0",
                          isUserChangeSpreadOffer: "0",
                          isUserChangeSpreadBid: "0",
                          userSpreadOffer: (
                            Number(userDealStats.makor_offer) -
                            Number(userDealStats.market_offer)
                          ).toFixed(3),
                        },
                      ];
                      dispatch(
                        rfqAggregatorSlice.setUsersSpreadhandler(
                          userSpredDetails,
                        ),
                      );
                      dispatch(rfqSlice.updateTableData(orderBook));
                      dispatch(rfqSlice.updateDealStatsData(userDealStats));
                      dispatch(
                        rfqAggregatorSlice.updateQuoteParameterts(
                          quoteParameters.userQuoteParameters[0],
                        ),
                      );
                    }
                  }
                }
              } catch (error) {
                console.log(error);
              }
            }
          }
          break;
        default:
          break;
      }
    };
    window.addEventListener("message", getRfqTrade);

    return () => {
      window.removeEventListener("message", getRfqTrade);
    };
  }, []);

  return (
    <Grid container justifyContent="space-between">
      <Grid item container xs={12} spacing={1}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Typography className={classes.rfqHeader}>RFQ Blotter</Typography>
          </Grid>
          <Grid item container xs={12} style={{ marginTop: "30px" }}>
            <Grid item xs={5}>
              {findCheckedValue() ? (
                <>
                  <Link to={`/rfqworkspace/${selectedRfq}`}>
                    <Button
                      className={classes.openButton}
                      variant="outlined"
                      onClick={(e) => open(selectedRfq)}
                    >
                      <Typography className={classes.open}>Open</Typography>
                    </Button>
                  </Link>
                  <Link to={`/rfqworkspace/${selectedRfq}`}>
                    <Button
                      className={classes.copyButton}
                      variant="outlined"
                      onClick={(e) => copy(selectedRfq)}
                    >
                      <Typography className={classes.copy}>Copy</Typography>
                    </Button>
                  </Link>
                  {findStatusRfq() ? (
                    <Button
                      className={classes.conbutton}
                      onClick={(e) => contribute(selectedRfq)}
                    >
                      <Typography className={classes.contribute}>
                        Contribute
                      </Typography>
                    </Button>
                  ) : (
                    ""
                  )}
                  {canRfqBeDeleted() ? (
                    <Button
                      className={classes.deleteButton}
                      onClick={(e) => {
                        dispatch(rfqSlice.handleDeleteRfq(token));
                        dispatch(
                          actionSnackBar.setSnackBar(
                            "error",
                            "The RFQ has been deleted",
                          ),
                        );
                      }}
                    >
                      <Typography className={classes.delete}>Delete</Typography>
                    </Button>
                  ) : null}
                </>
              ) : (
                <Grid></Grid>
              )}
            </Grid>
            <Grid item container justifyContent="flex-end" xs={7}>
              <InputBase
                style={{
                  color: "white",
                  border: "1px solid #686B76",
                  borderRadius: "4px",
                  opacity: 1,
                  width: "231px",
                  height: "40px",
                  marginRight: "10px",
                }}
                name="date"
                variant="outlined"
                type="date"
                className={classes.date}
                onChange={dateFilter}
              />
              {
                <InputBase
                  style={{
                    color: "white",
                    border: "1px solid var(--unnamed-color-686b76)",
                    border: "1px solid #686B76",
                    borderRadius: "4px",
                    opacity: 1,
                    width: "231px",
                    height: "40px",
                  }}
                  className={classes.search}
                  variant="outlined"
                  onChange={(e) => searchFilter(e)}
                  placeholder="Search..."
                  endAdornment={
                    <InputAdornment position="start">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              }
              <Link to={`/rfqworkspace`}>
                <Button
                  className={classes.newRfqButton}
                  variant="outlined"
                  onClick={createNewRfq}
                >
                  <p className="new-rfq">New Rfq</p>
                </Button>
              </Link>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <RfqBlotterTable
              date={date}
              search={search}
              setSelectedRfq={setSelectedRfq}
              selectedRfq={selectedRfq}
              findCheckedValue={findCheckedValue}
              volLegParams2={volLegParams}
              getRecommendation={getRecommendation}
            />
          </Grid>
          <Grid item container xs={12} style={{ marginTop: 15 }}>
            {sessionStorage.getItem("rfqId") !== null ? (
              <Grid item xs={12}>
                <RfqInfoModal direction="row" selectedRfq={selectedRfq} />
              </Grid>
            ) : null}
          </Grid>
          <Grid className={classes.status}>
            <Grid>
              <CircleIcon color={socketStatus} />
            </Grid>
            <Grid className={classes.statusPosition}>
              <Typography
                className={
                  socketStatus === "success"
                    ? classes.success
                    : socketStatus === "connecting"
                    ? classes.connecting
                    : socketStatus === "error"
                    ? classes.fails
                    : null
                }
              >
                {socketStatus === "success"
                  ? "Server Is Connected"
                  : socketStatus === "connecting"
                  ? "Trying to connect to Websocket"
                  : socketStatus === "error"
                  ? "Connection Lost"
                  : null}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export const useStyles = makeStyles(() => ({
  copy: {
    color: "var(--unnamed-color-2692ff)",
    textAlign: "left",
    font: "normal normal medium 16px/20px Inter",
    letterSpacing: " 0px",
    color: "#2692ff",
    opacity: "1",
  },
  open: {
    color: "var(--unnamed-color-2692ff)",
    textAlign: "left",
    font: " normal normal medium 16px/20px Inter",
    letterspacing: "0px",
    color: "#2692ff",
    opacity: " 1",
  },
  contribute: {
    color: "var(--unnamed-color-ff681c)",
    textalign: "left",
    font: "normal normal medium 16px/20px Inter",
    letterspacing: " 0px",
    color: "#ff681c",
    opacity: "1",
  },
  delete: {
    color: "var(--unnamed-color-ff681c)",
    textalign: "left",
    font: "normal normal medium 16px/20px Inter",
    letterspacing: " 0px",
    color: "#FFC5CD",
    opacity: "1",
  },
  buttonGroup: {},
  rfqHeader: {
    marginTop: "15px",
    marginBottom: "10px",
    textAlign: "left",
    font: "normal normal normal 2.125rem/2.1875rem Inter",
    letterSpacing: " 0px",
    color: "#ffffff",
    opacity: "1",
  },
  newRfqButton: {
    marginRight: "20px",
    background: "#2692FF 0% 0% no-repeat padding-box",
    borderRadius: "4px",
    width: "114px",
    height: "40px",
    marginLeft: "10px",
  },
  conbutton: {
    border: "1.5px solid var(--unnamed-color-ff681c)",
    background: " #171717 0% 0% no-repeat padding-box",
    border: " 2px solid #ff681c",
    borderRadius: "4px",
    opacity: 1,
    marginLeft: "10px",
    width: "130px",
    height: "40px",
  },
  deleteButton: {
    background: " #171717 0% 0% no-repeat padding-box",
    border: " 2px solid #FFC5CD",
    borderRadius: "4px",
    opacity: 1,
    marginLeft: "10px",
    width: "130px",
    height: "40px",
  },
  copyButton: {
    border: "1.5px solid var(--unnamed-color-2692ff)",
    background: "#171717 0% 0% no-repeat padding-box",
    border: "2px solid #2692FF",
    borderRadius: "4px",
    opacity: 1,
    width: "90px",
    height: "40px",
  },
  openButton: {
    border: "1.5px solid var(--unnamed-color-2692ff)",
    background: "#171717 0% 0% no-repeat padding-box",
    border: "2px solid #2692FF",
    borderRadius: "4px",
    opacity: 1,
    marginRight: "10px",
    width: "90px",
    height: "40px",
  },
  text: {
    color: "white",
  },
  date: {
    textAlign: "left",
    font: "normal normal normal 16px/20px Inter",
    letterSpacing: "0px",
    color: "#686B76",
    opacity: 1,
  },
  search: {
    textAlign: "left",
    font: "normal normal normal 16px/20px Inter",
    letterSpacing: "0px",
    color: "#686B76",
    opacity: 1,
  },
  status: {
    position: "fixed",
    bottom: "9px",
    width: "100%",
    display: "flex",
  },
  success: {
    color: "green",
  },
  fails: {
    color: "red",
  },
  connecting: {
    color: "#FF8C00",
  },
  statusPosition: {
    marginLeft: "5px",
  },
}));
