import axios from "axios";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { END_POINT } from "../../../../utils";
import PMSTableView from "./PMSTable.view";

function PMSTable() {
  const [loadingData, setLoadingData] = useState(true);
  const [pmsTable, setPmsTable] = useState([{}]);
  const [sortedPms, setSortedPms] = useState([{}]);

  const [order, setOrder] = useState("");
  const [sortField, setSortField] = useState("");

  const getPMTable = async () => {
    const token = sessionStorage.getItem("token");
    setLoadingData(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}` + END_POINT.DATA_MGMT + "/pm",
        { headers: { Authorization: token } }
      );

      let pmsList = res.data.map((pm) => {
        return { ...pm, isEdit: false };
      });
      setPmsTable(pmsList);
      setSortedPms(pmsList);
      setTimeout(() => {
        setLoadingData(false);
      }, [500]);
    } catch (error) {}
  };

  const handleSorting = (field, sortOrder) => {
    if (field) {
      const sorted = [...pmsTable].sort((a, b) => {
        return (
          a[field].toString().localeCompare(b[field].toString(), "en", {
            numeric: true,
          }) * (sortOrder === "asc" ? 1 : -1)
        );
      });
      setSortedPms(sorted);
    }
  };

  const handleSortingChange = (accessor) => {
    const sortOrder =
      accessor === sortField && order === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setOrder(sortOrder);
    handleSorting(accessor, sortOrder);
  };

  const updateTableWhenDelete = (pmIndex) => {
    let newArray = sortedPms.filter((pm, index) => index !== pmIndex);
    setSortedPms(newArray);
  };

  const insertEmptyPm = () => {
    let newPm = {
      id: null,
      pm: "",
      recipients: [],
      isEdit : true,
    };
    setPmsTable([newPm, ...pmsTable]);
    setSortedPms([newPm, ...sortedPms]);
  };

  const editPm = (pmIndex, boolean) => {
    let newPmsList
    if (boolean) {
      let isOneOnEditMode = sortedPms.findIndex((pm) => pm.isEdit === true);
      if (isOneOnEditMode === -1) {
        newPmsList = sortedPms.map((pm, index) => {
          if (index === pmIndex) {
            pm = { ...pm, isEdit: true };
          }
          return pm;
        });
      }else{
        newPmsList = sortedPms.map((pm, index) =>{
          if(index === isOneOnEditMode){
            pm = {...pm , isEdit : false}
          }
          if(index === pmIndex){
            pm = {...pm , isEdit : true}
          }
          return pm
        })
      }
    }
    if(!boolean){
      newPmsList = sortedPms.map((pm, index) => {
        if (index === pmIndex) {
          pm = { ...pm, isEdit: false };
        }
        return pm;
      });
    }
    setSortedPms(newPmsList);
  };

  useEffect(() => {
    getPMTable();
  }, []);

  return (
    <PMSTableView
      pmsTable={pmsTable}
      sortedPms={sortedPms}
      getPMTable={getPMTable}
      loadingData={loadingData}
      insertEmptyPm={insertEmptyPm}
      handleSortingChange={handleSortingChange}
      updateTableWhenDelete={updateTableWhenDelete}
      editPm={editPm}
    />
  );
}

export default PMSTable;
