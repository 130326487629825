export const cellEditorParamsSelector = (params) => {
  if (
    params.data.columnHeader.toLowerCase() === "buyer" ||
    params.data.columnHeader.toLowerCase() === "seller"
  ) {
    return {
      values: params.data.markitFavData.map((fav) => fav.label),
      // cellRenderer: richTextCellRender,
      filterList: true,
      searchType: "match",
      allowTyping: true,
    };
  } else if (
    params.data.columnHeader.toLowerCase() === "buyer le" ||
    params.data.columnHeader.toLowerCase() === "seller le"
  ) {
    const colId = params.column.colId;
    return {
      values:
        params?.data?.leObj[colId].length > 0
          ? params.data.leObj[colId].map((le) => le.LELongName)
          : [],
    };
  } else return undefined;
};
