import { TextField, withStyles } from "@material-ui/core";

export const StyledTextField = withStyles(() => ({
  root: {
    width: "114%",
    "& .MuiInputBase-input": {
      color: "#000000",
      fontSize: 14,
      height: 20,
      padding: "0px 8px",
    },
    "& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl":
      {
        paddingLeft: 0,
      },
    "& .MuiInputBase-input": {
      color: "#000000",
      padding: 0,
      paddingBottom: 5,
    },
  },
}))(TextField);

export const SubjectBox = withStyles(() => ({
  root: {
    width: "100%",
    "& .MuiInputBase-input": {
      color: "#000000",
      padding: 10,
    },
  },
}))(TextField);

export const CommentBox = withStyles(() => ({
  root: {
    width: "100%",
    "& .MuiInputBase-input": {
      color: "#000000",
      fontSize: 14,
      height: 20,
      padding: "0px 8px",
    },
    "& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl":
      {
        paddingLeft: 0,
      },
    "& .MuiInputBase-input": {
      color: "#000000",
      padding: 0,
      width: "120%",
      paddingBottom: 5,
    },
  },
}))(TextField);
