import axios from "axios";
import { END_POINT } from "../../../../utils";

export const getHistoryByTicker = async (
  searchValue,
  setTickerParticipates,
) => {
  try {
    if (searchValue.length > 0) {
      const res = await axios.post(
        `${process.env.REACT_APP_PYTON}${END_POINT.DERIVS_GET_HISTORY}`,
        { ticker: searchValue },
        {
          headers: {
            "x-api": process.env.REACT_APP_DERIVS_HISTORY_X_API,
            "user-id": process.env.REACT_APP_DERIVS_USER_ID,
            "Content-Type": "application/json",
          },
        },
      );
      if (res.data.error === undefined) {
        const participationRes = res.data;
        //   .filter(
        //   (client) => client.participation > 0,
        // );

        setTickerParticipates(participationRes);
      } else {
        setTickerParticipates([]);
      }
    } else {
      setTickerParticipates([]);
    }
  } catch (err) {
    console.log(err);
  }
};
export const getMarketMakers = async (searchValue, setMarketMakers) => {
  try {
    if (searchValue.length > 0) {
      const res = await axios.get(
        `${process.env.REACT_APP_PYTON}${END_POINT.DERIVS_GET_MARKET_MAKER_POD}/${searchValue}`,

        {
          headers: {
            "x-api": process.env.REACT_APP_DERIVS_HISTORY_X_API,
            "user-id": process.env.REACT_APP_DERIVS_USER_ID,
            "Content-Type": "application/json",
          },
        },
      );
      if (res.data.error === undefined) {
        setMarketMakers(res.data);
      } else {
        console.log("here");
        setMarketMakers(null);
      }
    } else {
      setMarketMakers(null);
    }
  } catch (err) {
    console.log(err);
  }
};
