import axios from "axios";
import { END_POINT } from "../../../utils";
import { useDispatch } from "react-redux";
import * as actionSnackBar from "../../../store/snackbar/action";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import ClientTableRowView from "./ClientTableRow.view";

function ClientTableRow(props) {
  let { client, deleteClient, getClientsData, clientIndex, handleSorting } =
    props;
  const dispatch = useDispatch();
  const [chosenPms, setChosenPms] = useState([]);
  const [chosenAgreement, setChosenAgreement] = useState("");
  const [chosenPmsIds, setChosenPmsIds] = useState([]);
  const [clientName, setClientName] = useState("");
  const [clientShortName, setClientShortName] = useState("");
  const [clientCompany, setClientCompany] = useState("");
  const [clientDefaultEmail, setClientDefaultEmail] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const handlePmChange = (e) => {
    let lastValIndex = e.target.value.length - 1;
    let currentClick = e.target.value[lastValIndex];
    let exist = chosenPms.filter((p) => p === currentClick.pm);
    if (exist.length > 0) {
      let newChosenPms = chosenPms.filter((p) => p !== currentClick.pm);
      let newChosenPmsIds = chosenPmsIds.filter((p) => p !== currentClick.id);
      setChosenPms(newChosenPms);
      setChosenPmsIds(newChosenPmsIds);
    } else {
      setChosenPms([...chosenPms, currentClick.pm]);
      setChosenPmsIds([...chosenPmsIds, currentClick.id]);
    }
  };

  const handleEdit = () => {
    let preCheckedPms = client.pmsList.filter((pm) => pm.checked === true);
    let names = preCheckedPms.map((pm) => pm.pm);
    names = typeof value === "string" ? names.split(",") : names;
    if (chosenPms.length === 0) {
      setChosenPms(names);
    }
    let ids = preCheckedPms.map((pm) => pm.id);
    setChosenPmsIds(ids);
    setClientName(clientName !== "" ? clientName : client.client_name);
    setClientShortName(
      clientShortName !== "" ? clientShortName : client.short_name,
    );
    setChosenAgreement(
      chosenAgreement !== "" ? chosenAgreement : client.agreement,
    );
    setClientDefaultEmail(
      clientDefaultEmail !== null && clientDefaultEmail !== ""
        ? client.default_email
        : clientDefaultEmail,
    );
    setClientCompany(clientCompany !== "" ? clientCompany : client.company);
    setIsEdit(true);
  };

  const handleAgreementChange = (e) => {
    setChosenAgreement(e.target.value);
  };

  const handeClientCompanyChange = (e) => {
    setClientCompany(e.target.value);
  };

  const changeClientDefaultEmail = (e) => {
    setClientDefaultEmail(e.target.value.replaceAll(",", ";"));
  };

  useEffect(() => {
    if (client.client_id === null) {
      setIsEdit(true);
    }
  }, [client]);

  const updateClient = async () => {
    let clientToUpdateObj = {
      client_id: client.client_id,
      client_name: clientName,
      short_name: clientShortName,
      company: clientCompany,
      agreement: chosenAgreement,
      default_email: clientDefaultEmail,
      pm_ids: chosenPmsIds.length > 0 ? chosenPmsIds : null,
    };
    const token = sessionStorage.getItem("token");
    try {
      let res = await axios.put(
        `${process.env.REACT_APP_BASE_URL}${END_POINT.DATA_MGMT}/update_client`,
        clientToUpdateObj,
        { headers: { Authorization: token } },
      );
      dispatch(actionSnackBar.setSnackBar("success", res.data, 3000));
      // setIsEdit(false);
      getClientsData();
    } catch (error) {
      console.log(error);
      dispatch(actionSnackBar.setSnackBar("error", error.message, 3000));
    }
  };

  const addNewClientToDb = async () => {
    const token = sessionStorage.getItem("token");
    let newClient = {
      client_name: clientName,
      short_name: clientShortName,
      company: clientCompany,
      agreement: chosenAgreement,
      default_email:
        clientDefaultEmail === undefined || clientDefaultEmail === null
          ? null
          : clientDefaultEmail,
      pm_ids: chosenPmsIds.length > 0 ? chosenPmsIds : null,
    };
    try {
      let res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${END_POINT.DATA_MGMT}/add_client`,
        newClient,
        { headers: { Authorization: token } },
      );
      dispatch(actionSnackBar.setSnackBar("success", res.data.message, 3000));
      setIsEdit(false);
      getClientsData();
    } catch (error) {
      dispatch(actionSnackBar.setSnackBar("error", error.message, 3000));
    }
  };

  const routerCreteOrUpdateClient = () => {
    if (client.client_id !== null && client.client_id !== undefined) {
      updateClient();
    } else {
      addNewClientToDb();
    }
  };

  return (
    <ClientTableRowView
      isEdit={isEdit}
      clientIndex={clientIndex}
      client={client}
      setIsEdit={setIsEdit}
      chosenPms={chosenPms}
      setChosenPms={setChosenPms}
      setChosenAgreement={setChosenAgreement}
      setClientDefaultEmail={setClientDefaultEmail}
      setClientCompany={setClientCompany}
      handleEdit={handleEdit}
      clientName={clientName}
      setClientName={setClientName}
      setClientShortName={setClientShortName}
      deleteClient={deleteClient}
      clientCompany={clientCompany}
      handlePmChange={handlePmChange}
      chosenAgreement={chosenAgreement}
      clientShortName={clientShortName}
      handleAgreementChange={handleAgreementChange}
      handeClientCompanyChange={handeClientCompanyChange}
      routerCreteOrUpdateClient={routerCreteOrUpdateClient}
      clientDefaultEmail={clientDefaultEmail}
      changeClientDefaultEmail={changeClientDefaultEmail}
      handleSorting={handleSorting}
    />
  );
}

export default ClientTableRow;
