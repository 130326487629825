import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { useStyles } from "../style/style";

const CustomToolTip = (props) => {
  const classes = useStyles();
  return (
    <Tooltip
      title={props.value}
      placement="top-start"
      classes={{
        arrow: classes.arrow,
        tooltip: classes.tooltip,
      }}
    >
      <span>{props.value}</span>
    </Tooltip>
  );
};

export default CustomToolTip;
