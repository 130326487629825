import axios from "axios";
import * as actionSnackBar from "../../../../store/snackbar/action";
import { END_POINT } from "../../../../utils";
import LZString from "lz-string";

export const submitBookingToMakorX = async (
  drv_trade,
  dispatch,
  bookingTable,
  strategyTable,
  underlyingTable,
  parsedBookingText,
) => {
  try {
    const res =
      process.env.REACT_APP_ENVIRONMENT === "dev"
        ? {
            data: {
              trade_id: Date.now(),
              status: "booked",
            },
          }
        : process.env.REACT_APP_ENVIRONMENT === "prod"
        ? await axios.post(
            `https://api.makor-x.com/le_tool/drv_store?api_key=${process.env.REACT_APP_MAKOR_X_KEY}`,
            { drv_trade },
          )
        : null;

    if (process.env.REACT_APP_ENVIRONMENT === "dev") {
      console.log({ drv_trade });
    }

    return {
      ...res.data,
      drv_trade,
      bookingTable,
      strategyTable,
      underlyingTable,
      parsedBookingText,
    };
  } catch (error) {
    let errorMessage = "An error occurred.";
    try {
      const parsedError = JSON.parse(error.response.data.error);
      // If parsedError is an array, join it with commas or newlines
      errorMessage = Array.isArray(parsedError)
        ? parsedError.join(", ")
        : parsedError;
    } catch (parseError) {
      // Fallback in case the error message is not valid JSON
      errorMessage =
        error.response?.data?.error || "An unknown error occurred.";
    }

    // Dispatch the error message to Snackbar
    dispatch(actionSnackBar.setSnackBar("error", errorMessage, 3000));
    console.log({ status: false, message: errorMessage });

    return {
      status: false,
      message: errorMessage,
    };
  }
};


export const storeBookedTrade = async (res) => {
  try {
    const {
      bookingTable,
      strategyTable,
      trade_id,
      underlyingTable,
      parsedBookingText,
      order,
    } = res;
    const bookingTableClean = bookingTable.map(
      ({ clientsData, ...row }) => row,
    );
    const token = sessionStorage.getItem("token");
    const bookTradeObj = { tradeId: trade_id, orderId: order.id };

    const stringifyObj = JSON.stringify({
      tablesData: {
        bookingTable: bookingTableClean,
        strategyTable,
        underlyingTable,
      },
      parsedBookingText,
      tradeId: trade_id,
      orderId: order.id,
      order,
    });
    const compressed = LZString.compress(stringifyObj);
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}${END_POINT.DERIVS_BOOK_TRADE}`,
      { bookTradeObj },
      { headers: { Authorization: token } },
    );
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}${END_POINT.DERIVS_STORE_BOOKED_TRADE}`,
      { compressed },
      { headers: { Authorization: token } },
    );
  } catch (error) {
    console.error("Failed to store booked trade:", error);
    throw new Error("Failed to store booked trade: " + error.message);
  }
};
