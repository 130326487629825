import axios from "axios";
import { END_POINT } from "../../../../utils";
import { groupByParticipant } from "./functions";

export const getBookedTradeData = async (id, setBookedTradeData) => {
  try {
    const token = sessionStorage.getItem("token");
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}${END_POINT.DERIVS_GET_BOOKED_ORDER_DATA}/${id}`,
      { headers: { Authorization: token } },
    );
    if (res.data.status) {
      setBookedTradeData(res.data.payload);
    }
  } catch (error) {
    console.log(error);
  }
};
export const saveEConfoData = async (orderId, mappedTableData) => {
  try {
    const token = sessionStorage.getItem("token");
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}${END_POINT.E_CONFO_DATA}`,
      { orderId, mappedTableData },
      { headers: { Authorization: token } },
    );
  } catch (error) {
    console.log(error);
  }
};
export const getMarkitFavData = async (setMarkitFavData) => {
  try {
    const token = sessionStorage.getItem("token");
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}${END_POINT.MARKIT_FAV_DATA}`,
      { headers: { Authorization: token } },
    );

    setMarkitFavData(groupByParticipant(res.data));
  } catch (error) {
    console.log(error);
  }
};
export const getMarkitParticipants = async (
  setParticipantsList,
  setAddresseesList,
  setLegalEntitiesList,
) => {
  try {
    const token = sessionStorage.getItem("token");
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}${END_POINT.MARKIT_PARTICIPANTS}`,
      { headers: { Authorization: token } },
    );

    setParticipantsList(res.data);
    setAddresseesList([]);
    setLegalEntitiesList([]);
  } catch (error) {
    console.log(error);
  }
};
export const getMarkitAddressees = async (setAddresseesList, participantId) => {
  try {
    const token = sessionStorage.getItem("token");
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}${END_POINT.MARKIT_ADDRESSEES}/${participantId}`,
      { headers: { Authorization: token } },
    );

    setAddresseesList(res.data);
  } catch (error) {
    console.log(error);
  }
};

export const createNewMarkitFav = async (
  newFavArray,
  setMarkitFavData,
  isEditingGroup,
  selectedFav,
  selectedIdLE,
) => {
  if (newFavArray.length > 0 || isEditingGroup) {
    try {
      const token = sessionStorage.getItem("token");
      const url = `${process.env.REACT_APP_BASE_URL}${END_POINT.MARKIT_FAV_DATA}`;
      const config = { headers: { Authorization: token } };

      let res;
      if (isEditingGroup) {
        res = await axios.put(
          url,
          { newFavArray, selectedFav, selectedIdLE },
          config,
        );
      } else {
        res = await axios.post(
          url,
          { newFavArray, selectedFav, selectedIdLE },
          config,
        );
      }
      if (res.status) {
        setMarkitFavData(groupByParticipant(res.data.updatedFavList));
      }
    } catch (error) {
      console.log(error);
    }
  }
};

export const getMarkitLegalEntitiesByParticipant = async (
  participantId,
  source = null,
  setLegalEntitiesList = null,
) => {
  try {
    try {
      const token = sessionStorage.getItem("token");
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}${END_POINT.MARKIT_LEGAL_ENTITIES}/${participantId}`,
        { headers: { Authorization: token } },
      );
      if (source === null) {
        return res.data;
      } else if (source === "participantDialog") {
        setLegalEntitiesList(res.data.legalEntitiesList);
      }
    } catch (error) {
      console.log(error);
    }
  } catch (error) {
    console.log(error);
  }
};

export const submitTradesToMarkit = async (
  SWBMLArray,
  setIsSubmittingForm,
  setMappedTableData,
) => {
  try {
    const token = sessionStorage.getItem("token");
    const url = `${process.env.REACT_APP_BASE_URL}${END_POINT.MARKIT_TRADES}`;
    const config = { headers: { Authorization: token } };

    const res = await axios.post(url, { SWBMLArray }, config);

    setMappedTableData((pre) => {
      const arr = [...pre];

      const updatedArr = arr.map((row) => {
        if (
          row.id === "markitStatus" ||
          row.id === "markitDealId" ||
          row.id === "markitErrorMessage"
        ) {
          res.data.forEach((result) => {
            if (row.hasOwnProperty(result.columnId)) {
              if (row.id === "markitStatus") {
                row[result.columnId] = result.status;
              } else if (row.id === "markitDealId") {
                if (result.status === "success") {
                  row[result.columnId] = result.deal_version_handle;
                } else if (result.status === "failure") {
                  row[result.columnId] = null;
                }
              } else if (row.id === "markitErrorMessage") {
                if (result.status === "failure") {
                  row[result.columnId] = result.message;
                } else if (result.status === "success") {
                  row[result.columnId] = null;
                }
              }
            }
          });
        }
        return row;
      });
      return updatedArr;
    });
    setIsSubmittingForm(false);
  } catch (error) {
    console.log(error);
  }
};
