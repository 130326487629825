import workerInstances from "../../../../services/index";
import axios from "axios";
import { END_POINT } from "../../../../utils";
import { testDateWithinRange } from "../../derivsPricerBlotter/utils/utilsFunctions";

export const subscribeOrdersToSocket = async (ordersList) => {
  try {
    const messageArray = [];
    ordersList.forEach((order, orderIndex) => {
      if (order.legs[0].futureTickerByLeg === null) {
        messageArray.push({
          action: "new",
          order_id: order.id,
          security: `${
            order.futureTicker === null ? order.symbol : order.futureTicker
          } ${order.equityIndex}`,
          attributes: ["LAST_PRICE"],
        });
      }

      order.legs.forEach(async (leg, legIndex) => {
        const attributes = ["BID", "ASK"];
        const { DELTA_MID_RT, ...rest } = leg;

        let obj = {
          action: "new",
          mini_feed: true,
          order_id: order.id,
          leg_id: legIndex,
          ...rest,
          security: leg.optionTicker,
          attributes,
        };
        if (leg.DELTA_MID_RT === null) {
          attributes.unshift("DELTA_MID_RT");
        } else {
          obj = { ...obj, DELTA_MID_RT: leg.DELTA_MID_RT };
        }
        messageArray.push(obj);
        if (leg.futureTickerByLeg !== null) {
          messageArray.push({
            action: "new",
            order_id: order.id,
            leg_id: legIndex,
            security: `${leg.futureTickerByLeg} ${order.equityIndex}`,
            attributes: ["LAST_PRICE"],
          });
        }
        messageArray.push(obj);
      });
    });
    workerInstances.WebSocketPricesInstance2.sendEvent(messageArray);
  } catch (err) {
    console.log(err);
  }
};

export const unsubscribeFromSocket = (order) => {
  const messageArray = [];

  messageArray.push({
    action: "remove",
    order_id: order.id,
  });

  workerInstances.WebSocketPricesInstance2.sendEvent(messageArray);
  return;
};

export const unsubscribeAllFromSocket = () => {
  const messageArray = [];

  messageArray.push({
    action: "remove_all",
  });

  workerInstances.WebSocketPricesInstance2.sendEvent(messageArray);
  return;
};

export const unsubscribeOnSearch = async (
  ordersList,
  setStrategiesList,
  setOrdersList,
  setIsOrderListLoading,
  pageNum,
) => {
  ordersList.forEach((order) => {
    const messageArray = [];
    messageArray.push({
      action: "remove",
      order_id: order.id,
    });
    workerInstances.WebSocketPricesInstance2.sendEvent(messageArray);
  });
  const token = sessionStorage.getItem("token");
  const res = await axios.get(
    `${process.env.REACT_APP_BASE_URL}${END_POINT.GET_ORDERS_LIST}/`,
    { headers: { Authorization: token } },
  );
  setStrategiesList(res.data.strategiesListWithLegs);
  setOrdersList(res.data.ordersListWithLegs);
  setIsOrderListLoading(false);
  if (res.data.ordersListWithLegs.length > 0) {
    const ordersList = await Promise.all(
      res.data.ordersListWithLegs.map(async (order, index) => {
        if (
          (pageNum === 1 && index < 10) ||
          (pageNum > 10 && (pageNum - 1) * 10 <= index && index < pageNum * 10)
        ) {
          subscribeOrdersToSocket([order]);
          const { symbol, equityIndex, futureTicker } = order;
          const tickerString = (
            futureTicker !== null
              ? futureTicker + " " + equityIndex
              : symbol.replaceAll("/", "%252f") + " " + equityIndex
          )
            .replaceAll("/", "|")
            .replaceAll(" ", "%20");
          const res = await axios.get(
            `${
              process.env.REACT_APP_BLOOMBERG_DERIVS
            }equity/${tickerString.toUpperCase()}`,
          );
          const {
            div_date,
            name,
            price,
            report_date,
            sector,
            ticker,
            has_adr,
            country,
          } = res.data;
          const dividend = await testDateWithinRange(div_date);
          const earnings = await testDateWithinRange(report_date);
          return {
            ...order,
            dividend,
            name,
            lastPrice: price,
            earnings,
            sector,
            ticker,
            has_adr,
            country,
          };
        } else {
          return {
            ...order,
          };
        }
      }),
    );
    setOrdersList(ordersList);
  }
};
