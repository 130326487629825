import {
  Table,
  Grid,
  TableContainer,
  TableHead,
  Typography,
  TableBody,
  Button,
  IconButton,
  InputBase,
  InputAdornment,
} from "@material-ui/core";
import {
  StyledHeaderTableRow,
  StyledTableCell,
  useStyles,
} from "./MarketMakers.styles";
import { ReactComponent as SearchIcon } from "../../../assets/search.svg";
import AddIcon from "@material-ui/icons/Add";
import CircularProgress from "@material-ui/core/CircularProgress";
import MMTableRow from "./MMTableRow";

function MarketMakersView(props) {
  const {
    sortedMarketMakersData,
    marketMakersData,
    handleSorting,
    loadingData,
    insertEmptyMM,
    deleteMM,
    getMarketMakersData,
    search
  } = props;

  const classes = useStyles();
  const headers = [
    "MM Name",
    "Short Name",
    "PB",
    "Agreement",
    "Default Email",
    "PMS",
    "Actions",
  ];

  return (
    <Grid container justifyContent="space-evenly" alignItems="center">
      {loadingData ? (
        <Grid item style={{ margin: "0 auto", paddingTop: "20%" }}>
          <CircularProgress
            style={{ color: "#2692ff", width: "60px", height: "60px" }}
          />
        </Grid>
      ) : (
        <>
          <Grid
            item
            xs={12}
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ paddingTop: "10px" }}
          >
            <Grid
              item
              xs={6}
              container
              justifyContent="flex-start"
            >
              <Button
                style={{
                  backgroundColor: "#2692ff",
                  textTransform: "none",
                  color: "#ffffff",
                  fontSize: "1.125rem",
                }}
                onClick={insertEmptyMM}
                startIcon={<AddIcon style={{ color: "#ffffff" }} />}
              >
                Add Market Maker
              </Button>
            </Grid>
            <Grid item xs={2}>
              <InputBase
                style={{
                  color: "white",
                  border: "1px solid var(--unnamed-color-686b76)",
                  border: "1px solid #686B76",
                  borderRadius: "4px",
                  opacity: 1,
                  width: "100%",
                  height: "35px",
                }}
                className={classes.search}
                variant="outlined"
                onChange={(e) => search(e)}
                placeholder="Search..."
                endAdornment={
                  <InputAdornment position="start">
                      <SearchIcon />
                  </InputAdornment>
                }
              />
            </Grid>
          </Grid>
          <Grid item container>
            <TableContainer
              style={{
                maxHeight: "calc(100vh - 200px)",
                marginTop: "20px",
              }}
            >
              <Table stickyHeader size="small">
                <TableHead>
                  <StyledHeaderTableRow>
                    {headers.map((header, index) => {
                      return (
                        <StyledTableCell key={index}>
                          {header}
                          {header !== "Actions" &&
                          header !== "PMS" &&
                          header !== "Default Email" ? (
                            <IconButton
                              size="small"
                              style={{
                                color: "#bababa",
                                fontSize: "0.875rem",
                                marginLeft: 10,
                              }}
                              onClick={() => handleSorting(header)}
                            >
                              A-z
                            </IconButton>
                          ) : null}
                        </StyledTableCell>
                      );
                    })}
                  </StyledHeaderTableRow>
                </TableHead>
                <TableBody>
                  {sortedMarketMakersData?.map((mm, index) => {
                    return (
                      <MMTableRow
                        key={index}
                        getMarketMakersData={getMarketMakersData}
                        mm={mm}
                        mmIndex={index}
                        deleteMM={deleteMM}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </>
      )}
    </Grid>
  );
}
export default MarketMakersView;
