import { Box, CircularProgress, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { AntTabs, AntTab } from "../../../../Styles/emailStyles";
import FxEmailContent from "./FxEmailContent";
import { useSelector } from "react-redux";
import { createConfo } from "../../../../services/createConfo";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function FxEmailTabs(props) {
  const { clients, tradeData } = props;
  const [clientIndex, setClientIndex] = useState(0);
  const loadingEmail = useSelector((state) => state.emailSlice?.loadingEmail);
  const { startSendEmailStatus } = useSelector(
    (state) => state.rfqPostTradeSlice
  );

  const handleChange = (event, newValue) => {
    if (startSendEmailStatus === true) {
      return;
    } else {
      setClientIndex(newValue);

      createConfo(newValue);
    }
  };

  useEffect(() => {
    createConfo(clientIndex);
  }, []);

  return (
    <Grid item xs={11}>
      <AntTabs value={clientIndex} variant="scrollable" onChange={handleChange}>
        {clients &&
          clients.map((client, index) => {
            return (
              <AntTab
                style={{ minWidth: "30%" }}
                label={client.name}
                {...a11yProps(index)}
              />
            );
          })}
      </AntTabs>
      {clients &&
        clients.map((client, index) => {
          return (
            <TabPanel value={clientIndex} index={index} key={index}>
              {loadingEmail === true ? (
                <CircularProgress
                  size={60}
                  thickness={2.5}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "45%",
                  }}
                />
              ) : (
                <FxEmailContent
                  key={index}
                  clientIndex={clientIndex}
                  tradeData={tradeData}
                />
              )}
            </TabPanel>
          );
        })}
    </Grid>
  );
}

export default FxEmailTabs;
