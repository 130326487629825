import {
    makeStyles,
  } from "@material-ui/core";
  export const useStyles = makeStyles((theme) => ({
    loginContainer: {
        height: "620px",
        width: "468px",
        background: "#222222 0% 0% no-repeat padding-box",
        borderRadius: "8px",
        opacity: 1,
      },
      loginPage: {
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      header: {
        color: "var(--unnamed-color-2692ff)",
        textAlign: "center",
        font: "normal normal medium 26px/31px Inter",
        letterSpacing: 0,
        color: "#2692FF",
        opacity: 1,
      },
      welcome: {
        textAlign: "center",
        font: "normal normal medium 24px/34px Inter",
        letterSpacing: 0,
        color: "#FFFFFF",
        opacity: 1,
      },
      inputContainer: {
        padding: "0px",
        margin: "0px",
        paddingTop: "5vh",
      },
      forgetpass: {
        textAlign: "right",
        textDecoration: "underline",
        font: "normal normal medium 14px/17px Inter",
        letterSpacing: 0,
        color: "#179AE1",
        cursor: "pointer",
        opacity: 1,
      },
      login: {
        background: "#2692FF 0% 0% no-repeat padding-box",
        borderRadius: "4px",
        opacity: 1,
        width: "313px",
        height: "40px",
      },
      input: {
        border: "1px solid #686B76",
        borderRadius: "4px",
        opacity: 1,
        maxWidth: "311px",
        width: "311px",
        height: "40px",
        maxHeight: "40px",
        marginBottom: "20px",
        "& .MuiInputBase-input": {
          color: "#ffffff",
          fontSize : "1.3125rem",
          padding: "10px 10px 11px",
        },
      },
}));

