import axios from "axios";

export const END_POINT = {
  LOGIN: "users/login",
  COUNTERPARTY: "counterparty",
  ACCOUNT: "account",
  TRADER: "trader",
  RFQ: "rfq",
  RFQ_DETAILS: "rfq_details",
  QUOTE: "quote",
  QUOTE_PARAMETERS: "quote_parameters",
  DEAL_TYPE: "strategy",
  DATA_SET: "dataset",
  ASSETS: "asset",
  PB_ACCOUNT: "pb_account",
  DEAL_TYPE_FIELD: "deal_type_field",
  FIELD: "field",
  TRADE_DETAILS: "trade_details",
  CURRENCY: "currency",
  CLIENTS: "client",
  USER_MARKET_MAKER: `user_market_maker`,
  MARKET_MAKER: "market_maker",
  RFQ_QUOTE: "rfq_quote",
  DEAL_STATS: "deal_stats",
  GET_MARKET_MAKER_QUOTE: "get_market_maker_quote",
  GET_MARKET_MAKERS_AND_CLIENTS: "get_market_makers_and_clients",
  POST_TRADE: "post_trade",
  RFQ_EMAILS: "email",
  FI_TRADES: "fi_trades",
  TRADES: "trades",
  ORDER_BOOK: "order_book",
  AUTH: "users/entitlement",
  DATA_MGMT: "data_mgmt",
  FI_PDF: "fi_Pdf",
  EMAIL: "email",
  FI_CLIENTS: "fi_clients",
  RATES: "rates",
  DEAL_STATS_BY_USER: "deal_stats_by_user",
  PB_BY_USER: "pb_by_user",
  GET_RFQ_MODALS_DETAILS: "get_rfq_modals_details",
  ADD_QUOTE_PARAMETERS_BY_USER: "add_quote_parameters_by_user",
  DELETE_DEAL_STATS_BY_USER: "delete_deal_stats_by_user",
  UPDATE_TABS: "users/update_tabs",
  CLEAN_TABS: "users/clean_tabs",
  GET_TABS: "users/get_tabs",
  DELETE_RFQ_FROM_TAB_LIST: "users/delete_rfq_from_tabs",
  PARSE_CLIENT_REQUEST: "parse_client_request",
  PARSE_CLIENT_REQUEST_REWRITE: "parse_client_request_rewrite",
  GET_FUTURE_TICKER: "get_future_ticker",
  CREATE_DERIVS_ORDER: "derivs_pricer/create_order",
  GET_ORDERS_LIST: "derivs_pricer",
  GET_FAV_LIST: "derivs_pricer/get_fav",
  ADD_ORDER_TO_CUSTOM_LIST: "derivs_pricer/add_order_to_custom_list",
  SEARCH_ORDER_BY_VALUE: "derivs_pricer/search_order",
  UPDATE_ORDER: "derivs_pricer/update_order",
  CREATE_EQUITIES_ORDER: "equities_spread/create_order",
  GET_ORDERS_LIST_EQUITIES_SPREAD: "equities_spread",
  REMOVE_ORDER_EQUITIES_SPREAD: "equities_spread/remove_order_from_custom_list",
  ADD_ORDER_TO_CUSTOM_LIST_EQUITIES_SPREAD:
    "equities_spread/add_order_to_custom_list",
  SEARCH_ORDER_BY_VALUE_EQUITIES_SPREAD: "equities_spread/search_order",
  SUBMIT_FI_PNL_ATT: "fi_trades/submit_pnl_att",
  BBG_CONNECTOR_DOWNLOAD: "downloadBBGConnector",
  DERIVS_GET_HISTORY: "get_history",
  DERIVS_GET_MARKET_MAKER_POD: "get_market_maker_pod",
  DERIVS_GET_MAKOR_X_ID: "derivs_pricer/get_makor_x_id",
  DERIVS_STORE_BOOKED_TRADE: "derivs_pricer/store_booked_trade_data",
  DERIVS_BOOK_TRADE: "derivs_pricer/book_trade",
  DERIVS_GET_BOOKED_ORDER_DATA: "derivs_pricer/get_booked_order_data",
  DERIVS_GET_CLIENT_TRADER_STATIC: "derivs_pricer/get_client_trader_static",
  CREATE_NEW_USER: "users/create_user",
  GET_USERS_LIST: "users/user_list",
  UPDATE_DEAL_STATS_FWD_SCALE: "deal_stats/update_fwd_scale",
  DERIVS_CLIENT_TRADER_EMAIL_DETAILS:
    "derivs_client/client_trader_email_details",
  DERIVS_CLIENT_TRADER_CLEARANCE_ACC: "derivs_client/client_clearance_account",
  DERIVS_EMAIL: "email/derivs_email",
  GET_BOOKED_ORDERS_LIST: "derivs_pricer/booked_orders_list",
  MARKIT_FAV_DATA: "markit/markit_fav_list",
  MARKIT_PARTICIPANTS: "markit/markit_participants",
  MARKIT_ADDRESSEES: "markit/markit_addressees",
  MARKIT_LEGAL_ENTITIES: "markit/markit_legal_entities",
  MARKIT_TRADES: "markit/markit_trades",
  E_CONFO_DATA: "derivs_pricer/econfo_data",
  E_CONFO_FX: "post_trade/e_confo",
};

export function setAuthToken(token) {
  console.log("setAuthToken , CALLED");
  if (token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    console.log("setAuthToken , SET");
  } else {
    console.log("setAuthToken , deleted");
    delete axios.defaults.headers.common["Authorization"];
  }
}

export const saveToLocalStorage = (name, data) => {
  localStorage.setItem(name, JSON.stringify(data));
};

export const saveToSessionStorage = (name, data) => {
  sessionStorage.setItem(name, JSON.stringify(data));
};

export const getFromLocalStorage = (name) => {
  let data = localStorage.getItem(name);
  return JSON.parse(data);
};
