import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useStyles,
  StyledTextField,
} from "../../../../Styles/rfqPostTradeStyles";
import * as rfqSlice from "../../../../store/rfq/rfqSlice";
import { Drafts } from "@material-ui/icons";
import clsx from "clsx";
import axios from "axios";
import { END_POINT } from "../../../../utils";

// sendRfqToDb;
export const RfqStatusModal = ({
  rfq_object,
  setrfq_object,
  setLaunchStatus,
  launchStatus,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const rfq = useSelector((state) => state.rfqSlice.rfqId);
  const rfqs = useSelector((state) => state.rfqSlice.rfq);
  const statusItem = useSelector((state) => state.rfqSlice.statusItem);
  const rfqObjectUpdate = useSelector(
    (state) => state.rfqSlice.rfqObjectUpdate,
  );
  const rfqObject = useSelector((state) => state.rfqPostTradeSlice.rfqObject);
  const rfqButtonStatus = useSelector(
    (state) => state.rfqSlice.rfqButtonStatus,
  );
  const rfqMode = sessionStorage.getItem("rfqMode");
  const rfqId = sessionStorage.getItem("rfqId");
  const status = sessionStorage.getItem("status");
  const token = sessionStorage.getItem("token");
  const [statusValue, setStatusValue] = useState("Live");
  const [disabled, setDisabled] = useState(false);
  const [disabledByStatus, setDisabledByStatus] = useState(false);

  const filter = rfqs.filter((rfq) => Number(rfq?.rfq_id) === Number(rfqId));

  const handleStatusChange = (e) => {
    setrfq_object({
      ...rfq_object,
      status: e.target.value,
      id: rfq_object.rfq_id,
    });
    if (rfqMode !== "rfq aggregator view") {
      setLaunchStatus(false);
    }
    if (e.target.value === "Live" && filter.length > 0) {
      dispatch(rfqSlice.updateIsRfqStatusUpdateLive(true));
    }
    setStatusValue(e.target.value);
    dispatch(rfqSlice.updateRqObjectUpdate(true));
    dispatch(rfqSlice.setStatusCtrlEnter(e.target.value));
  };

  const updateStatusInDb = async () => {
    if (rfqObjectUpdate === true) {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}` + `rfq/update_status`,
        { rfqId, statusValue },
        {
          headers: { Authorization: token },
        },
      );
      if (response.status === 200) {
        dispatch(rfqSlice.updateRqObjectUpdate(false));
        dispatch(rfqSlice.setStatusCtrlEnter("Live"));
      }
    }
  };

  useEffect(() => {
    if (rfqId !== null && status !== "copy" && filter.length > 0) {
      setStatusValue(filter[0].status);
      setrfq_object({
        ...rfq_object,
        status: filter[0].status,
        id: filter[0].status.rfq_id,
      });
    } else if (rfqId !== null && status !== "copy" && filter.length === 0) {
      const getStatus = async () => {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}` +
            END_POINT.RFQ +
            `/rfq_details/${sessionStorage.getItem("rfqId")}`,
          { headers: { Authorization: token } },
        );
        setStatusValue(res.data.rfq[0].status);
        setrfq_object({
          ...rfq_object,
          status: res.data.rfq[0].status,
        });
      };
      getStatus();
    } else {
      setStatusValue("Live");
      setrfq_object({
        ...rfq_object,
        status: "Live",
      });
    }

    // if (
    //   filter.length > 0 &&
    //   ((rfq.items && rfq.items.length > 0 && rfqButtonStatus !== "copy") ||
    //     (rfqButtonStatus !== "" && rfqMode !== ""))
    // ) {
    //   setStatusValue(filter[0].status);
    //   setrfq_object({
    //     ...rfq_object,
    //     status: filter[0].status,
    //     id: filter[0].status.rfq_id,
    //   });
    // } else if (
    //   sessionStorage.getItem("rfqId") !== null &&
    //   sessionStorage.getItem("rfqId") !== undefined
    // ) {
    //   const getStatus = async () => {
    //     const res = await axios.get(
    //       `${process.env.REACT_APP_BASE_URL}` +
    //         END_POINT.RFQ +
    //         `/rfq_details/${sessionStorage.getItem("rfqId")}`,
    //       { headers: { Authorization: token } },
    //     );
    //     setStatusValue(res.data.rfq[0].status);
    //     setrfq_object({
    //       ...rfq_object,
    //       status: res.data.rfq[0].status,
    //     });
    //   };
    //   getStatus();
    // } else {
    //   setStatusValue("Live");
    //   setrfq_object({
    //     ...rfq_object,
    //     status: "Live",
    //   });
    // }
  }, []);

  useEffect(() => {
    if (
      (filter.length > 0 && rfqButtonStatus !== "copy") ||
      (filter.length > 0 && rfqButtonStatus !== "" && rfqMode !== "")
    ) {
      // if (rfq.items.length > 0) {
      setStatusValue(filter[0]?.status);
      setDisabledByStatus(filter[0]?.status);
      dispatch(rfqSlice.setStatusItem(filter[0]?.status));
      setrfq_object({
        ...rfq_object,
        status: filter[0].status,
        id: rfq_object.rfq_id,
      });

      if (
        filter[0].status === "Traded Away" ||
        filter[0].status === "Not Traded"
      ) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
      // }
    }
  }, [rfqObject, rfqId, rfqs]);

  useEffect(() => {
    if (
      statusItem !== "" &&
      rfqButtonStatus !== "copy" &&
      rfq.items.length > 0
    ) {
      setStatusValue(statusItem);
      setrfq_object({
        ...rfq_object,
        status: statusItem,
        id: rfq_object.rfq_id,
      });
    }
  }, [statusItem]);

  return (
    <Grid container className={classes.rfqStatusModalBox}>
      <Grid item xs={2}>
        <Typography
          style={{ color: "white" }}
          className={classes.rfqStatusModalHeader}
        >
          Status
        </Typography>
      </Grid>
      <Grid item xs={5} style={{ marginLeft: "15px" }}>
        <FormControl
          sx={{ m: 1, minWidth: 120 }}
          disabled
          className={clsx(classes.rfqStatusModalSelect, {
            [classes.rfqStatusModalSelectRed]: statusValue === "Live",
            [classes.rfqStatusModalSelectOrange]:
              statusValue === "Idea" || statusValue === "Draft",
            [classes.rfqStatusModalSelectGray]:
              statusValue === "Traded Away" || statusValue === "Not Traded",
            [classes.rfqStatusModalSelectGreen]:
              statusValue === "Confirmed" ||
              statusValue === "Booked" ||
              statusValue === "Confirmed & Booked",
          })}
          // statusValue
          // className={clsx(classes.selectOptionBox, "selectAutoComplete", {
          //   [classes.selectOptionBoxPostTrades]:
          //     values.values.key === "postTradePmSplitId" ||
          //     values.values.key === "postTradeClientSplitId",
          // })}
        >
         
          <Select
            labelId="demo-simple-select-helper-label"
            onChange={(event) => handleStatusChange(event)}
            inputProps={{
              "aria-label": "Without label",
              classes: {
                icon:
                  disabledByStatus === "Not Traded" ||
                  disabledByStatus === "Traded Away" ||
                  disabledByStatus === "Booked" ||
                  disabledByStatus === "Confirmed" ||
                  disabledByStatus === "Confirmed & Booked" ||
                  statusItem === "Not Traded" ||
                  statusItem === "Traded Away" ||
                  statusItem === "Booked" ||
                  statusItem === "Confirmed" ||
                  statusItem === "Confirmed & Booked" ||
                  rfqMode === "rfq booking view"
                    ? classes.iconInvisible
                    : null,
              },
            }}
            disabled={
              disabled === true ||
              (rfqButtonStatus !== "copy" && rfqMode === "rfq booking view") ||
              disabledByStatus === "Booked" ||
              disabledByStatus === "Confirmed" ||
              disabledByStatus === "Confirmed & Booked" ||
              statusItem === "Booked" ||
              statusItem === "Confirmed" ||
              statusItem === "Confirmed & Booked" ||
              (filter.length > 0 &&
                filter[0].status !== "Live" &&
                rfqMode === "rfq aggregator view")
            }
            value={statusValue}
          >
            <MenuItem
              value={"Live"}
              style={{
                display:
                  disabledByStatus === "Live" ||
                  disabledByStatus === "Traded Away" ||
                  disabledByStatus === "Not Traded"
                    ? "none"
                    : null,
              }}
              className={classes.rfqStatusModalMenuItem}
            >
              Live
            </MenuItem>

            <MenuItem
              value={"Draft"}
              style={{
                display:
                  disabledByStatus === "Live" ||
                  disabledByStatus === "Draft" ||
                  disabledByStatus === "Idea"
                    ? "none"
                    : null,
              }}
              className={classes.rfqStatusModalMenuItem}
            >
              Draft
            </MenuItem>
            <MenuItem
              value={"Idea"}
              style={{
                display:
                  disabledByStatus === "Live" || disabledByStatus === "Idea"
                    ? "none"
                    : null,
              }}
              className={classes.rfqStatusModalMenuItem}
            >
              Idea
            </MenuItem>

            <MenuItem
              value={"Traded Away"}
              style={{
                display:
                  disabledByStatus !== "Live" && disabledByStatus !== "Idea"
                    ? "none"
                    : null,
              }}
              className={classes.rfqStatusModalMenuItem}
            >
              Traded Away
            </MenuItem>
            <MenuItem
              value={"Not Traded"}
              style={{
                display:
                  disabledByStatus !== "Live" && disabledByStatus !== "Idea"
                    ? "none"
                    : null,
              }}
              className={classes.rfqStatusModalMenuItem}
            >
              Not Traded
            </MenuItem>
            <MenuItem
              value={"Confirmed"}
              style={{ display: "none" }}
              className={classes.rfqStatusModalMenuItem}
            >
              Confirmed
            </MenuItem>
            <MenuItem
              value={"Booked"}
              style={{ display: "none" }}
              className={classes.rfqStatusModalMenuItem}
            >
              Booked
            </MenuItem>
            <MenuItem
              value={"Confirmed & Booked"}
              style={{ display: "none" }}
              className={classes.rfqStatusModalMenuItem}
            >
              Confirmed & Booked
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <Button
          style={{ display: rfqMode !== "rfq aggregator view" ? "none" : null }}
          className={clsx(
            rfqObjectUpdate === true
              ? classes.launchStatusBtnActive
              : classes.launchStatusBtn,
          )}
          onClick={() => {
            updateStatusInDb();
          }}
        >
          UPDATE
        </Button>
      </Grid>
    </Grid>
  );
};
