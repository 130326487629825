import {
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@material-ui/core";
import { StyledHeaderTableRow, StyledTableCell } from "./PMSTable.style";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";

import PmTableRow from "./PmTableRow";
function PMSTableView(props) {
  const {
    pmsTable,
    sortedPms,
    handleSortingChange,
    updateTableWhenDelete,
    loadingData,
    insertEmptyPm,
    getPMTable,editPm
  } = props;
  return (
    <Grid container>
      {loadingData ? (
        <Grid item style={{ margin: "0 auto", paddingTop: "20%" }}>
          <CircularProgress
            style={{ color: "#2692ff", width: "60px", height: "60px" }}
          />
        </Grid>
      ) : (
        <Grid container>
          <Grid
            item
            container
            justifyContent="flex-start"
            style={{ paddingTop: "10px" }}
          >
            <Button
              style={{
                backgroundColor: "#2692ff",
                textTransform: "none",
                color: "#ffffff",
                fontSize: "1.125rem",
              }}
              onClick={insertEmptyPm}
              startIcon={<AddIcon style={{ color: "#ffffff" }} />}
            >
              Add PM
            </Button>
          </Grid>
          <TableContainer
            style={{
              maxHeight: "calc(100vh - 230px)",
              marginTop: "20px",
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <StyledHeaderTableRow>
                  {pmsTable !== null &&
                    Object.keys(pmsTable[0]).map((key, index) => {
                      if (key === "pm" || key === "recipients") {
                        if (key === "pm") {
                          return (
                            <StyledTableCell
                              key={index}
                              style={{ width: "200px" }}
                            >
                              PM Name
                              <IconButton
                                size="small"
                                style={{
                                  color: "#bababa",
                                  fontSize: "0.875rem",
                                  marginLeft: 10,
                                }}
                                onClick={() => handleSortingChange(key)}
                              >
                                A-z
                              </IconButton>
                            </StyledTableCell>
                          );
                        } else if (key === "recipients") {
                          return (
                            <StyledTableCell key={index}>
                              Recipients
                            </StyledTableCell>
                          );
                        }
                      }
                    })}
                  <StyledTableCell style={{ width: "100px" }}>
                    Actions
                  </StyledTableCell>
                </StyledHeaderTableRow>
              </TableHead>
              <TableBody>
                {sortedPms.map((pm, index) => {
                  return (
                    <PmTableRow
                      getPMTable={getPMTable}
                      pm={pm}
                      pmIndex={index}
                      key={index}
                      updateTableWhenDelete={updateTableWhenDelete}
                      editPm={editPm}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </Grid>
  );
}

export default PMSTableView;
