import {
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import * as derivsPricerSlice from "../../../store/derivsPricer/derivsPricerSlice";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "../../../Styles/pricerStyle";
import strategyList from "./strategyList.json";
import "./styles.css";
import { ReactComponent as Arrows } from "../../../assets/arrows.svg";
import { ReactComponent as Launch } from "../../../assets/launch.svg";
import { rewriteClientString } from "./apiCalls";

export const ClientRequest = () => {
  const dispatch = useDispatch();
  const stringInputRef = useRef(null);
  const selectStrInputRef = useRef(null);
  const classes = useStyles();
  const parsedString = useSelector(
    (state) => state.derivsPricerSlice.parsedString,
  );
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const gridStyle = useMemo(() => ({ height: "100%", width: "1230px" }), []);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  useEffect(() => {
    if (!stringInputRef.current || !gridApi || !gridColumnApi) {
      return;
    }

    stringInputRef.current.addEventListener(
      "keydown",
      function (event) {
        if (event.key !== "Tab") {
          return;
        }
        event.preventDefault();
        gridApi.ensureIndexVisible(0);

        const firstCol = gridColumnApi.getAllDisplayedColumns()[0];

        gridApi.ensureColumnVisible(firstCol);
        gridApi.setFocusedCell(0, firstCol);
        stringInputRef.current.value = "";
      },
      true,
    );
  }, [stringInputRef, gridApi, gridColumnApi]);

  //currently canceled
  const DropdownCellRenderer = (params) => {
    const parsedString = params.data;
    const rowIndex = params.node.rowIndex;

    const [value, setValue] = useState(params.value);
    const handleDropdownChange = (e) => {
      setValue(e.target.value);
      dispatch(
        derivsPricerSlice.handleStrategySelect(e.target.value, strategyList),
      );
    };

    useEffect(() => {
      setValue(parsedString.strategy);
    }, [parsedString]);

    return (
      <>
        {rowIndex === 0 ? (
          <select
            ref={selectStrInputRef}
            value={value}
            onChange={handleDropdownChange}
            className="select-strategy"
          >
            {strategyList.map((s) => {
              return <option value={s.value}>{s.value}</option>;
            })}
          </select>
        ) : null}
      </>
    );
  };
  //toggle component with arrows icon rotating
  const ToggleRenderer = (params) => {
    const isPut = params.value === "Put";
    const isEquity = params.value === "US Equity";
    const rowIndex = params.node.rowIndex;
    const colId = params.column.colId;
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid
          item
          xs={colId === "callPut" ? 8 : colId === "equityIndex" ? 9 : 6}
        >
          {params.value}
        </Grid>
        <Grid
          item
          xs={colId === "callPut" ? 4 : colId === "equityIndex" ? 3 : 6}
        >
          {colId === "callPut" ? (
            <Arrows
              style={{
                transform: `rotate(${isPut ? "180deg" : "0"})`,
              }}
            />
          ) : colId === "equityIndex" && rowIndex === 0 ? (
            <Arrows
              style={{
                transform: `rotate(${isEquity ? "180deg" : "0"})`,
              }}
            />
          ) : null}
        </Grid>
      </Grid>
    );
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "symbol",
      // editable: true,
      minWidth: 90,
      maxWidth: 90,
      editable: (params) => {
        // Conditionally set the 'editable' property based on some logic
        return params.node.rowIndex === 0;
      },
    },
    {
      field: "equityIndex",
      editable: false,
      minWidth: 120,
      maxWidth: 120,
      cellRenderer: "toggleCellRenderer",
    },
    {
      editable: false,
      field: "strategy",
      // cellRenderer: "dropdownCellRenderer",
      // cellRendererParams: { data: parsedString },
      minWidth: 160,
      maxWidth: 160,
    },
    {
      field: "legs",
      // editable: true,
      minWidth: 70,
      maxWidth: 70,
      editable: (params) => {
        // Conditionally set the 'editable' property based on some logic
        return params.node.rowIndex === 0;
      },
    },
    {
      field: "expiryDay",
      editable: true,
      minWidth: 100,
      maxWidth: 100,
    },
    {
      field: "expiryMonth",
      minWidth: 120,
      maxWidth: 120,
    },
    {
      field: "expiryYear",
      minWidth: 100,
      maxWidth: 100,
    },
    {
      field: "strike",
      minWidth: 90,
      maxWidth: 90,
    },
    {
      field: "tie",
      minWidth: 80,
      maxWidth: 80,
      editable: (params) => {
        // Conditionally set the 'editable' property based on some logic
        return params.node.rowIndex === 0;
      },
    },
    {
      field: "lastPrice",
      minWidth: 110,
      maxWidth: 110,
      editable: false,
    },
    {
      field: "callPut",
      editable: false,
      minWidth: 90,
      maxWidth: 90,
      cellRenderer: "toggleCellRenderer",
    },
    {
      field: "quantity",
      minWidth: 90,
      maxWidth: 90,
    },
  ]);
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 110,
      resizable: true,
      editable: true,
    };
  }, []);

  const handleStringSubmit = (e) => {
    if (e !== undefined && e.key === "Enter" && e.target.value) {
      dispatch(derivsPricerSlice.parseClientRequest(e.target.value));

      // e.target.value = "";
      // stringInputRef.current.blur();
    }
  };

  const handleStringSubmitClick = () => {
    if (
      stringInputRef.current.value !== undefined &&
      stringInputRef.current.value !== ""
    ) {
      dispatch(
        derivsPricerSlice.parseClientRequest(stringInputRef.current.value),
      );
    }
  };

  //must have to get the table saving previous data when its being update
  const getRowId = useCallback((params) => {
    return params.data.id;
  });

  //key press handler
  const onCellKeyDown = useCallback((e, parsedString) => {
    if (e.event) {
      var keyPressed = e.event.code;
      const rowIndex = e.node.rowIndex;
      const colId = e.column.colId;
      if (
        (keyPressed === "Space" &&
          e.colDef.field === "equityIndex" &&
          rowIndex === 0) ||
        (keyPressed === "Enter" &&
          e.colDef.field === "equityIndex" &&
          rowIndex === 0)
      ) {
        const newValue =
          e.value === "US Equity"
            ? "Index"
            : e.value === "Index"
            ? "Equity"
            : "US Equity";
        dispatch(derivsPricerSlice.handleTableEdit(rowIndex, colId, newValue));
      } else if (
        (keyPressed === "Space" && e.colDef.field === "callPut") ||
        (keyPressed === "Enter" && e.colDef.field === "callPut")
      ) {
        const newValue = e.value === "Call" ? "Put" : "Call";
        dispatch(derivsPricerSlice.handleTableEdit(rowIndex, colId, newValue));
      }
      // else if (
      //   (keyPressed === "Space" &&
      //     e.colDef.field === "strategy" &&
      //     rowIndex === 0) ||
      //   (keyPressed === "Enter" &&
      //     e.colDef.field === "strategy" &&
      //     rowIndex === 0)
      // ) {
      //   selectStrInputRef.current.focus();
      // }
    }
  }, []);

  //handle the table req to change cell value
  const onCellEditRequest = useCallback(
    (e) => {
      const rowIndex = e.node.rowIndex;
      const colId = e.column.colId;
      const value = e.value.toUpperCase();
      dispatch(derivsPricerSlice.handleTableEdit(rowIndex, colId, value));
    },
    [parsedString],
  );

  const onCellClicked = useCallback((e) => {
    console.log("onCellClicked", e);
    const rowIndex = e.node.rowIndex;
    const colId = e.column.colId;
  }, []);
  const onCellEditingStarted = useCallback(
    (e) => {
      const rowIndex = e.node.rowIndex;
      const colId = e.column.colId;

      // const rowIndex = e.node.rowIndex;
      // const colId = e.column.colId;
      // gridApi.startEditingCell({
      //   rowIndex: rowIndex,
      //   colKey: colId,
      // });
    },
    [gridApi],
  );

  const onCellEditingStopped = useCallback(
    (e) => {
      console.log("cellEditingStopped", e);
      // gridApi.stopEditing();
    },
    [gridApi],
  );
  // const onFirstDataRendered = useCallback((params) => {
  //   gridApi.current.api.sizeColumnsToFit();
  // }, []);

  const handleSubmit = async () => {
    stringInputRef.current.value = "";
    dispatch(derivsPricerSlice.handleSubmitOrder());
  };

  const isAllFieldsFilled = async (parsedString) => {
    const arr = [];
    await Promise.all(
      parsedString.map((leg, idx) => {
        if (idx === 0) {
          if (leg.symbol === "") {
            arr.push({ field: "symbol", message: "empty" });
          }
        }
        if (
          // leg.expiryDay === "" ||
          leg.expiryMonth === "" ||
          leg.expiryYear === ""
        ) {
          arr.push({ field: "date", message: "empty" });
        }
        if (leg.strike === "") {
          arr.push({ field: "strike", message: "empty" });
        }
        if (leg.quantity === "") {
          arr.push({ field: "quantity", message: "empty" });
        }
      }),
    );
    if (arr.length === 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };
  useEffect(() => {
    isAllFieldsFilled(parsedString);
  }, [parsedString]);

  useEffect(() => {
    // setRowData([...parsedString]);
    const index = columnDefs.findIndex((row) => row.field === "tie");
    if (index !== -1) {
      if (
        parsedString.length > 0 &&
        (parsedString[0].futureTickerByLeg !== null ||
          parsedString[0].symbol.toLowerCase() === "vix" ||
          parsedString[0].symbol.toLowerCase() === "cac")
      ) {
        setColumnDefs((pre) => {
          const arr = [...pre];
          const obj = { ...arr[index], editable: true };
          arr.splice(index, 1, obj);
          return arr;
        });
      } else {
        setColumnDefs((pre) => {
          const arr = [...pre];
          const obj = {
            ...arr[index],
            editable: (params) => {
              // Conditionally set the 'editable' property based on some logic
              return params.node.rowIndex === 0;
            },
          };
          arr.splice(index, 1, obj);
          return arr;
        });
      }
    }
  }, [parsedString]);

  return (
    <Grid
      container
      justifyContent="flex-start"
      direction={"column"}
      alignItems="flex-start"
      style={{
        // minHeight: "100vh",
        minWidth: "1255px",
        marginTop: "15px",
      }}
    >
      <Grid item>
        <Typography
          style={{ fontSize: 32, marginBottom: "20px", color: "white" }}
        >
          Listed Derivatives Pricer
        </Typography>
      </Grid>

      <Grid
        item
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        style={{
          display: "flex",
          background: "#1A1C1F",
          border: "1px solid rgb(104,107,118,0.5)",
          borderRadius: "4px",
          padding: "15px",
          maxWidth: "1255px",
        }}
      >
        <Grid item container style={{ marginBottom: "10px" }}>
          <Grid item>
            <Typography
              style={{
                fontSize: 22,
                color: "#686b76",
                font: "normal normal normal 14px/17px Inter",
                opacity: "1",
                marginRight: "10px",
                paddingTop: "8px",
              }}
            >
              Client Request
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              // autoComplete="off"
              inputRef={stringInputRef}
              className={classes.stringInput}
              required
              id="outlined-required"
              variant="outlined"
              InputProps={
                {
                  // endAdornment: (
                  //   <SubdirectoryArrowLeftIcon style={{ color: "white" }} />
                  // ),
                }
              }
              onKeyDown={handleStringSubmit}
            />
          </Grid>
          <Grid>
            <Button
              className={classes.createBtn}
              onClick={() => {
                handleStringSubmitClick();
              }}
            >
              Create
              {/* <Typography>Custom</Typography> */}
            </Button>
          </Grid>
          <Grid>
            <Button
              className={classes.customBtn}
              onClick={() => {
                dispatch(derivsPricerSlice.parseClientRequest("custom"));
                stringInputRef.current.value = "custom";
                stringInputRef.current.focus();
              }}
            >
              Custom
              {/* <Typography>Custom</Typography> */}
            </Button>
          </Grid>
          <Grid>
            <Button
              className={classes.resetBtn}
              onClick={() => {
                dispatch(derivsPricerSlice.parseClientRequest("custom"));
                stringInputRef.current.value = "";
                stringInputRef.current.focus();
                dispatch(derivsPricerSlice.setParsedString([]));
              }}
            >
              Reset
            </Button>
          </Grid>
          <Grid>
            <Button
              className={classes.reformatBtn}
              onClick={() => {
                rewriteClientString(stringInputRef, dispatch);
                dispatch(derivsPricerSlice.parseClientRequest("custom"));
                dispatch(derivsPricerSlice.setParsedString([]));
              }}
            >
              Reformat
            </Button>
          </Grid>
        </Grid>
        {parsedString.length > 0 ? (
          <Grid container>
            <Grid
              item
              className="ag-theme-balham-dark"
              style={gridStyle}
              xs={12}
            >
              <AgGridReact
                rowData={parsedString}
                columnDefs={columnDefs}
                getRowId={getRowId}
                ref={gridApi}
                onCellKeyDown={(e) => onCellKeyDown(e, parsedString)}
                // onCellKeyPress={(e) => onCellKeyPress(e, parsedString)}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                animateRows={true}
                onCellEditingStarted={onCellEditingStarted}
                onCellEditingStopped={onCellEditingStopped}
                frameworkComponents={{
                  dropdownCellRenderer: DropdownCellRenderer,
                  toggleCellRenderer: ToggleRenderer,
                }}
                domLayout={"autoHeight"}
                onCellClicked={onCellClicked}
                readOnlyEdit={true}
                singleClickEdit={true}
                onCellEditRequest={onCellEditRequest}
                // onFirstDataRendered={onFirstDataRendered}
              ></AgGridReact>
            </Grid>
            <Grid
              item
              xs={12}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item>
                <IconButton
                  style={{
                    backgroundColor: disabled ? "#8292a3" : "#2692FF",
                    color: "white",
                    borderRadius: "4px",
                    width: "113px",
                    height: "32px",
                    marginTop: "10px",
                  }}
                  onClick={() => {
                    handleSubmit();
                  }}
                  disabled={disabled}
                >
                  <Launch />
                  &nbsp;Launch
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
};
