import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { RfqAggreatorModal } from "../rfqAggreatorModal/RfqAggreatorModal";
import { useSelector, useDispatch } from "react-redux";
import RfqModalDealStats from "../rfqModalDealStats/RfqModalDealStats";
import OvmlTailModal from "../ovmailTail/OvmlTailModal";
import OrderBook from "../orderBook/OrderBook";
import RfqBookingMode from "../rfqBookingMode/RfqBookingMode";
import RfqOptionRequestModal from "../rfqOptionRequestModal/RfqOptionRequestModal";
import MlModal from "../mlModal/MlModal";
import axios from "axios";
import * as rfqSlice from "../../../../store/rfq/rfqSlice";
import * as RfqPostTradeSlice from "../../../../store/rfq/rfqPostTradeSlice";
import { RfqStatusModal } from "../rfqStatusModal/RfqStatusModal";

export const RfqInfoModal = ({
  direction,
  rfq_object,
  setrfq_object,
  selectedRfq,
  setLaunchStatus,
  launchStatus,
}) => {
  const rfq = useSelector((state) => state.rfqSlice.rfq);
  const dispatch = useDispatch();
  const RfqId = useSelector((state) => state.rfqSlice.rfqId.items);
  let copyRfq = [...RfqId];
  let dealStatsData = useSelector((state) => state.rfqSlice.dealStatsData);
  const [data, setData] = useState({
    makor_bid: dealStatsData?.makor_bid,
    makor_offer: dealStatsData?.makor_offer,
  });
  let rfqParamaters = useSelector(
    (state) => state.rfqAggregatorSlice.RfqQuoteParameters,
  );
  let recommendationRfqs = useSelector(
    (state) => state.rfqSlice.recommendationRfqs,
  );
  const status = useSelector((state) => state.rfqSlice.rfqButtonStatus);
  const rfqMode = sessionStorage.getItem("rfqMode");
  let arrangStringToRows = "";
  let ovmlTail = "";
  let findRfqChecked = rfq.find((rfq) => {
    if (sessionStorage.getItem("rfqId") !== null) {
      return Number(rfq.rfq_id) === Number(sessionStorage.getItem("rfqId"));
    }
  });

  let volLegParams = useSelector(
    (state) => state.rfqAggregatorSlice.volLegParams,
  );

  let filterVolLegParams = [];
  if (selectedRfq !== undefined) {
    filterVolLegParams = volLegParams.filter(
      (params) => Number(params.rfq_id) === Number(selectedRfq),
    );
  }

  const getRecomandation = async () => {
    if (RfqId.length > 0 && rfq_object !== undefined) {
      rfq_object = { ...rfq_object, cross: RfqId[0].ccy_pair };
      rfq_object = { ...rfq_object, created_on: RfqId[0].created_on };
      rfq_object = { ...rfq_object, rfq_id: RfqId[0].rfq_id };
      try {
        const response = await axios.post(
          "https://fx-letool.letool-engine.makor-group.com/api/get_recommendations",
          rfq_object,
        );
        dispatch(rfqSlice.updateLpPerdiction(response.data));
        dispatch(
          rfqSlice.updateRecommendationRfqs({
            recomandationDeatgails: response.data,
            rfqId: sessionStorage.getItem("rfqId"),
          }),
        );
      } catch (error) {
        console.log(error);
      }
    }
  };
  const HandleRfqOptionRequest = () => {
    if (status !== "copy") {
      if (findRfqChecked && findRfqChecked.rfq_option_request) {
        arrangStringToRows = findRfqChecked.rfq_option_request;
      } else if (RfqId.length > 0) {
        arrangStringToRows = RfqId[0].rfq_option_request;
      }
    }
    return arrangStringToRows;
  };
  const HandleOvmlTail = () => {
    if (status !== "copy") {
      if (findRfqChecked && findRfqChecked.ovml_tail) {
        ovmlTail = findRfqChecked.ovml_tail;
      } else if (RfqId.length > 0) {
        ovmlTail = RfqId[0].ovml_tail;
      }
    }
    return ovmlTail;
  };
  useEffect(() => {
    if (status !== "copy") {
      HandleRfqOptionRequest();
      // getRecomandation();
      HandleOvmlTail();
    }
  }, [rfq_object, selectedRfq]);
  useEffect(() => {
    dispatch(RfqPostTradeSlice.getMarketMakersClients());
  }, []);

  return (
    <Grid
      container
      spacing={2}
      style={{
        maxHeight: "85vh",
        overflow: "auto",
        paddingLeft: direction === "column" && 30,
      }}
    >
      {!window.location.pathname.includes("rfqblotter") &&
      rfqMode === "rfq booking view" ? (
        <Grid item lg={direction === "column" ? 12 : 4}>
          <RfqStatusModal
            rfq_object={rfq_object}
            setrfq_object={setrfq_object}
            setLaunchStatus={setLaunchStatus}
            launchStatus={launchStatus}
            item
          />
        </Grid>
      ) : null}

      {rfqMode === "rfq booking view" && (
        <Grid item lg={12}>
          <RfqBookingMode
            header="Post Trade"
            rfq_object={rfq_object}
            setrfq_object={setrfq_object}
          />
        </Grid>
      )}

      {!window.location.pathname.includes("rfqblotter") &&
      !(rfqMode === "rfq booking view") ? (
        <Grid item lg={direction === "column" ? 12 : 4}>
          <RfqStatusModal
            rfq_object={rfq_object}
            setrfq_object={setrfq_object}
            setLaunchStatus={setLaunchStatus}
            launchStatus={launchStatus}
            item
          />
        </Grid>
      ) : null}

      <Grid item lg={direction === "column" ? 12 : 4}>
        <RfqOptionRequestModal
          data={HandleRfqOptionRequest()}
          header="RFQ"
          column={direction === "column"}
        />
      </Grid>
      {window.location.pathname.includes("rfqworkspace") ? (
        <Grid item lg={direction === "column" ? 12 : 4}>
          <OvmlTailModal data={HandleOvmlTail()} header="OVML TAIL" />
        </Grid>
      ) : (
        ""
      )}
      {direction === "column" && (
        <>
          <Grid item lg={12}>
            <MlModal
              header="RECOMENDED MARKET MAKERS"
              column={direction === "column"}
            />
          </Grid>
          <Grid item lg={12}>
            <OrderBook
              header="ORDER BOOK"
              rfq_object={rfq_object}
              setrfq_object={setrfq_object}
            />
          </Grid>
        </>
      )}
      <Grid item lg={direction === "column" ? 12 : 4}>
        <Grid container spacing={1}>
          {direction !== "column" && (
            <Grid item lg={12}>
              <OvmlTailModal data={HandleOvmlTail()} header="OVML TAIL" />
            </Grid>
          )}
          {direction === "column" && (
            <Grid item lg={12}>
              <RfqAggreatorModal
                rfq_object={rfq_object}
                setrfq_object={setrfq_object}
                data={data}
                setData={setData}
                column={direction === "column"}
              />
            </Grid>
          )}
          <Grid item lg={12}>
            <RfqModalDealStats
              header="DEAL STATS"
              rfq_object={rfq_object}
              setrfq_object={setrfq_object}
              data={data}
              setData={setData}
              column={direction === "column"}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={direction === "column" ? 12 : 4}>
        <Grid container spacing={1}>
          {direction !== "column" && (
            <>
              <Grid item lg={12}>
                <OrderBook
                  header="ORDER BOOK"
                  rfq_object={rfq_object}
                  setrfq_object={setrfq_object}
                  column={direction === "column"}
                />
              </Grid>
              <Grid item lg={12}>
                <RfqAggreatorModal
                  rfq_object={rfq_object}
                  setrfq_object={setrfq_object}
                  data={data}
                  setData={setData}
                  column={direction === "column"}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
