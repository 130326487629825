import React, { useRef, useEffect, useState, useCallback } from "react";
import {
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  FormControl,
  Switch,
  MenuItem,
} from "@material-ui/core";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useStyles } from "../../../Styles/pricerStyle";
import { useDispatch, useSelector } from "react-redux";
import * as equitiesSpreadSlice from "../../../store/equitiesSpread/equitiesSpreadSlice";
import { ReactComponent as Launch } from "../../../assets/launch.svg";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

export const EquitySpreadCreator = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { equityDetails, calculation } = useSelector(
    (state) => state.equitiesSpreadSlice,
  );
  const [disabled, setDisabled] = useState(true);
  const handleKeyDown = (event, key) => {
    if (event.key === "Enter" || event.key === "Tab") {
      dispatch(equitiesSpreadSlice.handleTickerSubmit(key));
    }
  };

  useEffect(() => {
    if (equityDetails?.priceConvention !== "") {
      dispatch(
        equitiesSpreadSlice.handleFormula({
          priceConvention: equityDetails.priceConvention,
          flip: equityDetails.flip,
        }),
      );
    }
  }, [equityDetails?.priceConvention, equityDetails?.flip]);

  useEffect(() => {
    if (
      equityDetails.ticker1 !== "" &&
      equityDetails.bloomberg_ticker1 !== undefined &&
      equityDetails.bloomberg_ticker1.replace(" Equity", "").toLowerCase() ===
        equityDetails.ticker1.trim().toLowerCase() &&
      equityDetails.ticker2 !== "" &&
      equityDetails.bloomberg_ticker2 !== undefined &&
      equityDetails.bloomberg_ticker2.replace(" Equity", "").toLowerCase() ===
        equityDetails.ticker2.trim().toLowerCase()
    ) {
      setDisabled(false);
      dispatch(equitiesSpreadSlice.calculateByFormula());
    } else {
      setDisabled(true);
    }
  }, [equityDetails]);

  return (
    <Grid>
      <Grid item>
        <Typography
          style={{ fontSize: 32, marginBottom: "20px", color: "white" }}
        >
          Equities Spread Monitor
        </Typography>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        {equityDetails.priceConvention === "" ? (
          <Grid>
            <TextField
              className={classes.selectRoot}
              label="Strategy"
              variant="outlined"
              select
              value={equityDetails.priceConvention}
              onChange={(e) =>
                dispatch(
                  equitiesSpreadSlice.handleStrategySelect({
                    key: "priceConvention",
                    value: e.target.value,
                  }),
                )
              }
              SelectProps={{
                className: classes.selectStrategy,
                style: { height: "34px" },
                MenuProps: {
                  anchorOrigin: {
                    vertical: "bottom", // Set the menu position to appear below the TextField
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null, // Prevents the menu from appearing below the input field
                },
              }}
            >
              <MenuItem value=""></MenuItem>
              <MenuItem value="Spread">Spread</MenuItem>
              <MenuItem value="Spread%">Spread%</MenuItem>
              <MenuItem value="Ratio">Ratio</MenuItem>
              <MenuItem value="Merger">Merger</MenuItem>
              <MenuItem value="Merger%">Merger%</MenuItem>
            </TextField>
          </Grid>
        ) : null}
      </Grid>
      {equityDetails.priceConvention !== "" ? (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={3}
          style={{
            border: "1px solid #686B76",
            backgroundColor: "#6B6E7729",
            borderRadius: "8px",
            // height: "40vh",
            marginTop: "10px",
            maxWidth: "700px",
          }}
        >
          <Grid container item xs={4} sm={3} spacing={3}>
            {/* strategy Grid */}
            <Grid item xs={9}>
              <TextField
                className={classes.selectRoot}
                label="Strategy"
                variant="outlined"
                select
                value={equityDetails.priceConvention}
                onChange={(e) =>
                  dispatch(
                    equitiesSpreadSlice.handleStrategySelect({
                      key: "priceConvention",
                      value: e.target.value,
                    }),
                  )
                }
                SelectProps={{
                  className: classes.selectStrategy,
                  style: { height: "34px" },
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom", // Set the menu position to appear below the TextField
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    // Prevents the menu from appearing below the input field
                    classes: {
                      list: classes.menuList, // Custom class for the menu list
                      option: classes.menuOption, // Custom class for the menu option
                      paper: classes.menuPaper, // Custom class for the menu paper/container
                    },
                  },
                }}
              >
                <MenuItem value=""></MenuItem>
                <MenuItem value="Spread">Spread</MenuItem>
                <MenuItem value="Spread%">Spread%</MenuItem>
                <MenuItem value="Ratio">Ratio</MenuItem>
                <MenuItem value="Merger">Merger</MenuItem>
                <MenuItem value="Merger%">Merger%</MenuItem>
              </TextField>
            </Grid>
            {/* status Grid */}

            <Grid item xs={9}>
              <TextField
                className={classes.selectRoot}
                label="Status"
                variant="outlined"
                select
                value={equityDetails.statusNote}
                onChange={(e) =>
                  dispatch(
                    equitiesSpreadSlice.handleEquityDetailsInput({
                      key: "comment",
                      value: e.target.value,
                    }),
                  )
                }
                SelectProps={{
                  className: classes.selectStrategy,
                  style: { height: "34px" },
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom", // Set the menu position to appear below the TextField
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    // Prevents the menu from appearing below the input field
                  },
                }}
              >
                <MenuItem value=""></MenuItem>
                <MenuItem value="M&A">M&A</MenuItem>
                <MenuItem value="Right Issue">Right Issue</MenuItem>
                <MenuItem value="Share Class">Share Class</MenuItem>
                <MenuItem value="Long/Short">Long/Short</MenuItem>
                <MenuItem value="Holding/Stub">Holding/Stub</MenuItem>
              </TextField>
            </Grid>

            {/* limit Grid */}
            <Grid item style={{ paddingTop: "3px" }}>
              <TextField
                type="number"
                className={classes.tickerInput}
                label="Limit"
                variant="outlined"
                value={equityDetails.limit}
                onChange={(e) =>
                  dispatch(
                    equitiesSpreadSlice.handleEquityDetailsInput({
                      value: Number(e.target.value),
                      key: "limit",
                    }),
                  )
                }
              />
            </Grid>
          </Grid>
          <Grid container item xs={5} sm={6} md={6} spacing={3}>
            {/* tickers Grid */}
            <Grid
              item
              container
              spacing={3}
              // style={{ minHeight: "80px", maxHeight: "80px" }}
            >
              {/* ticker1 Grid */}
              <Grid item>
                <TextField
                  onChange={(e) =>
                    dispatch(
                      equitiesSpreadSlice.handleEquityDetailsInput({
                        value: e.target.value,
                        key: "ticker1",
                      }),
                    )
                  }
                  value={equityDetails.ticker1}
                  className={classes.tickerInput}
                  label="Ticker 1"
                  variant="outlined"
                  onKeyDown={(e) => handleKeyDown(e, "ticker1")}
                />
                {equityDetails.lastPrice1 !== "" ? (
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    item
                    xs={12}
                    style={{ color: "white", marginTop: "5px" }}
                  >
                    <Grid item xs={10}>
                      <Typography>
                        {`${equityDetails.currency1} ${equityDetails.lastPrice1}`}
                      </Typography>
                    </Grid>
                    {equityDetails.lastPrice1 !== "" &&
                    equityDetails.lastPrice2 !== "" &&
                    equityDetails.originalCurrency1 !==
                      equityDetails.originalCurrency2 ? (
                      <Grid item xs={1}>
                        <FormControlLabel
                          onChange={(e) =>
                            dispatch(
                              equitiesSpreadSlice.handleSelectTickerSide(
                                e.target.checked,
                              ),
                            )
                          }
                          control={
                            <IOSSwitch checked={equityDetails.tickerSide} />
                          }
                          label=""
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                ) : null}
              </Grid>
              {/* ticker2 Grid */}
              <Grid item>
                <TextField
                  onChange={(e) =>
                    dispatch(
                      equitiesSpreadSlice.handleEquityDetailsInput({
                        value: e.target.value,
                        key: "ticker2",
                      }),
                    )
                  }
                  value={equityDetails.ticker2}
                  className={classes.tickerInput}
                  label="Ticker 2"
                  variant="outlined"
                  onKeyDown={(e) => handleKeyDown(e, "ticker2")}
                />
                {equityDetails.lastPrice2 !== "" ? (
                  <Grid
                    item
                    xs={8}
                    style={{
                      color: "white",
                      marginTop: "5px",
                    }}
                  >
                    <span>{`${equityDetails.currency2} ${equityDetails.lastPrice2}`}</span>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>

            {/* weight Grid */}
            {equityDetails.priceConvention === "Spread" ||
            equityDetails.priceConvention === "Spread%" ||
            equityDetails.priceConvention === "Merger" ||
            equityDetails.priceConvention === "Merger%" ||
            equityDetails.priceConvention === "Ratio" ? (
              <Grid item container spacing={3}>
                {equityDetails.priceConvention === "Spread" ||
                equityDetails.priceConvention === "Spread%" ||
                equityDetails.priceConvention === "Ratio" ? (
                  <Grid item>
                    <TextField
                      type="number"
                      className={classes.tickerInput}
                      label="Weight 1"
                      variant="outlined"
                      value={equityDetails.weight1}
                      onChange={(e) =>
                        dispatch(
                          equitiesSpreadSlice.handleEquityDetailsInput({
                            value: Number(e.target.value),
                            key: "weight1",
                          }),
                        )
                      }
                    />
                  </Grid>
                ) : null}

                <Grid item>
                  <TextField
                    type="number"
                    className={classes.tickerInput}
                    label="Weight 2"
                    variant="outlined"
                    value={equityDetails.weight2}
                    onChange={(e) =>
                      dispatch(
                        equitiesSpreadSlice.handleEquityDetailsInput({
                          value: Number(e.target.value),
                          key: "weight2",
                        }),
                      )
                    }
                  />
                </Grid>
                {/* cash Grid */}
                {equityDetails.priceConvention === "Merger" ||
                equityDetails.priceConvention === "Merger%" ? (
                  <Grid item>
                    <TextField
                      type="number"
                      className={classes.tickerInput}
                      label="Cash"
                      variant="outlined"
                      value={equityDetails.cash}
                      onChange={(e) =>
                        dispatch(
                          equitiesSpreadSlice.handleEquityDetailsInput({
                            value: Number(e.target.value),
                            key: "cash",
                          }),
                        )
                      }
                    />
                  </Grid>
                ) : null}
              </Grid>
            ) : null}

            {/* offset Grid */}
            {equityDetails.priceConvention === "Spread" ||
            equityDetails.priceConvention === "Spread%" ||
            equityDetails.priceConvention === "Ratio" ? (
              <Grid item container spacing={3}>
                <Grid item>
                  <TextField
                    type="number"
                    className={classes.tickerInput}
                    value={equityDetails.offset1}
                    label="Offset 1"
                    variant="outlined"
                    onChange={(e) =>
                      dispatch(
                        equitiesSpreadSlice.handleEquityDetailsInput({
                          value: Number(e.target.value),
                          key: "offset1",
                        }),
                      )
                    }
                  />
                </Grid>
                <Grid item>
                  <TextField
                    type="number"
                    className={classes.tickerInput}
                    label="Offset 2"
                    variant="outlined"
                    value={equityDetails.offset2}
                    onChange={(e) =>
                      dispatch(
                        equitiesSpreadSlice.handleEquityDetailsInput({
                          value: Number(e.target.value),
                          key: "offset2",
                        }),
                      )
                    }
                  />
                </Grid>
              </Grid>
            ) : null}
          </Grid>

          <Grid container item xs={1} sm={3} md={1}>
            <Grid item>
              <IconButton
                // className={classes.resetBtn}
                style={{
                  backgroundColor: "#FFA800",
                  color: "white",
                  borderRadius: "4px",
                  minWidth: "100px",
                  maxWidth: "100px",
                  height: "32px",
                  marginTop: "10px",
                }}
                onClick={() => {
                  dispatch(equitiesSpreadSlice.resetEquityDetails());
                }}
              >
                <RestartAltIcon />
                &nbsp;Reset
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                style={{
                  backgroundColor: disabled ? "#8292a3" : "#2692FF",
                  color: "white",
                  borderRadius: "4px",
                  minWidth: "100px",
                  maxWidth: "100px",
                  height: "32px",
                  marginTop: "10px",
                }}
                onClick={() => {
                  dispatch(equitiesSpreadSlice.handleOrderSubmit());
                }}
                disabled={disabled}
              >
                <Launch />
                &nbsp;Launch
              </IconButton>
            </Grid>
          </Grid>
          {/* flip Grid */}
          {equityDetails.priceConvention === "Spread" ||
          equityDetails.priceConvention === "Spread%" ||
          equityDetails.priceConvention === "Ratio" ? (
            <Grid
              container
              item
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
              xs={12}
            >
              <Grid item>
                <Typography style={{ color: "white" }}>Flip</Typography>
              </Grid>
              <Grid item>
                <Checkbox
                  style={{ color: "white" }}
                  // defaultChecked
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  checked={equityDetails.flip}
                  onChange={(e) => {
                    dispatch(
                      equitiesSpreadSlice.handleEquityDetailsInput({
                        value: e.target.checked,
                        key: "flip",
                      }),
                    );
                  }}
                />
              </Grid>
            </Grid>
          ) : null}
          {/* formula Grid */}
          <Grid item>
            <Typography style={{ color: "#FFA800" }}>
              {equityDetails.formula}
            </Typography>
            {calculation !== "" ? (
              <Typography
                style={{
                  color: "#FFC5CD",
                  paddingRight: "10px",
                }}
              >
                {` ${calculation}`}
              </Typography>
            ) : null}
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 29,
  height: 13,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#86FFF4" : "#86FFF4",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#86FFF4",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 10,
    height: 10,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#FFC5CD" : "#FFC5CD",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
