import React, { useCallback, useEffect, useState } from "react";
import {
  testDateWithinRange,
  useDebounce,
} from "../derivsPricerBlotter/utils/utilsFunctions";
import { StrategiesMultiSelect } from "../derivsPricerBlotter/components/StrategiesMultiSelect";
import {
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  Typography,
  Box,
  CircularProgress,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { ReactComponent as Makor } from "../../../assets/makorLogoSmallBlue.svg";
import { useStyles } from "../../../Styles/pricerStyle";
import { MiniFeedTable } from "./miniFeedTable/MiniFeedTable";
import workerInstances from "../../../services/index";
import axios from "axios";
import { ws, connectWS, sendEvent } from "../../../services/websocket";
import * as rfqSlice from "../../../store/rfq/rfqSlice";
import { useDispatch } from "react-redux";
import { END_POINT } from "../../../utils";
import { SocketConnectivity } from "../socketConnectivity/SocketConnectivity";
import {
  subscribeOrdersToSocket,
  unsubscribeAllFromSocket,
  unsubscribeFromSocket,
  unsubscribeOnSearch,
} from "./utils/socketCalls";

export const MiniFeed = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [ordersList, setOrdersList] = useState([]);
  const [strategiesList, setStrategiesList] = useState([]);
  const [isOrderListLoading, setIsOrderListLoading] = useState(true);
  const [pageNum, setPageNum] = useState(1);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [searchValue, setSearchValue] = useState(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const [wsPricerConnection, setWsPricerConnection] = useState(false);

  const token = sessionStorage.getItem("token");

  // functions / handlers
  const handleSearchByValue = async (searchValue) => {
    setSearchValue(searchValue);
  };
  const debouncedSearchTerm = useDebounce(searchValue, 500);

  const handleDebouncedSearch = async () => {
    try {
      const token = sessionStorage.getItem("token");
      if (searchValue.length > 2) {
        unsubscribeOnSearch(ordersList);
        setTimeout(async () => {
          const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}${END_POINT.SEARCH_ORDER_BY_VALUE}`,
            { searchValue },
            { headers: { Authorization: token } },
          );
          if (res.data.length > 0) {
            const ordersList = await Promise.all(
              res.data.map(async (order) => {
                const { symbol, equityIndex } = order;
                const tickerString = (
                  symbol.replaceAll("/", "%252f") +
                  " " +
                  equityIndex
                )
                  .replaceAll("/", "|")
                  .replaceAll(" ", "%20");
                const res = await axios.get(
                  `${
                    process.env.REACT_APP_BLOOMBERG_DERIVS
                  }equity/${tickerString.toUpperCase()}`,
                );
                const { div_date, name, price, report_date, sector, ticker } =
                  res.data;
                const dividend = await testDateWithinRange(div_date);
                const earnings = await testDateWithinRange(report_date);
                return {
                  ...order,
                  dividend,
                  name,
                  lastPrice: price,
                  earnings,
                  sector,
                  ticker,
                };
              }),
            );
            subscribeOrdersToSocket(ordersList);
            setOrdersList(ordersList);
            setIsOrderListLoading(false);
          } else {
            setOrdersList(res.data);
          }
        }, 500);
      } else if (searchValue.length <= 2) {
        unsubscribeOnSearch(
          ordersList,
          setStrategiesList,
          setOrdersList,
          setIsOrderListLoading,
          pageNum,
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleNewOrder = useCallback(
    async ({ order, ordersList, strategiesList }) => {
      const ordersListCopy = [...ordersList];
      const strategiesListCopy = [...strategiesList];
      const { symbol, equityIndex, futureTicker } = order.order;
      const tickerString = (
        futureTicker !== null
          ? futureTicker + " " + equityIndex
          : symbol.replaceAll("/", "%252f") + " " + equityIndex
      )
        .replaceAll("/", "|")
        .replaceAll(" ", "%20");
      const res = await axios.get(
        `${
          process.env.REACT_APP_BLOOMBERG_DERIVS
        }equity/${tickerString.toUpperCase()}`,
      );
      const {
        div_date,
        name,
        price,
        report_date,
        sector,
        ticker,
        has_adr,
        country,
      } = res.data;
      const dividend = await testDateWithinRange(div_date);
      const earnings = await testDateWithinRange(report_date);

      ordersListCopy.unshift({
        ...order.order,
        dividend,
        name,
        lastPrice: price,
        earnings,
        sector,
        ticker,
        has_adr,
        country,
        isNewTimestamp: new Date().getTime(),
      });

      strategiesListCopy.unshift({ ...order.order });
      setOrdersList(ordersListCopy);
      setStrategiesList(strategiesListCopy);
      // if (ordersListCopy.length > 7) {
      //   unsubscribeFromSocket(ordersListCopy.at(7));
      // }
      // subscribeOrdersToSocket([order.order]);
    },
    [ordersList, strategiesList],
  );
  const handleAddOrderToCustomList = useCallback(
    async ({ id, ordersList, strategiesList }) => {
      const copyOrderList = [...ordersList];
      const order = strategiesList.filter(
        (order) => Number(order.id) === Number(id),
      );
      if (order.length > 0) {
        const { symbol, equityIndex, futureTicker } = order[0];
        const tickerString = (
          futureTicker !== null
            ? futureTicker + " " + equityIndex
            : symbol.replaceAll("/", "%252f") + " " + equityIndex
        )
          .replaceAll("/", "|")
          .replaceAll(" ", "%20");
        const res = await axios.get(
          `${
            process.env.REACT_APP_BLOOMBERG_DERIVS
          }equity/${tickerString.toUpperCase()}`,
        );
        const {
          div_date,
          name,
          price,
          report_date,
          sector,
          ticker,
          has_adr,
          country,
        } = res.data;
        const dividend = await testDateWithinRange(div_date);
        const earnings = await testDateWithinRange(report_date);

        subscribeOrdersToSocket([order[0]]);
        copyOrderList.unshift({
          ...order[0],
          dividend,
          name,
          price,
          earnings,
          sector,
          ticker,
          has_adr,
          country,
        });
        setOrdersList(
          copyOrderList.sort(
            (a, b) => new Date(b.createdOn) - new Date(a.createdOn),
          ),
        );
      }
    },
    [[ordersList, strategiesList, pageNum]],
  );
  const handleIgnoreOrder = useCallback(
    async ({ id, ordersList }) => {
      const copyOrderList = [...ordersList];
      const index = ordersList.findIndex((o) => Number(o.id) === Number(id));
      if (index !== -1) {
        unsubscribeFromSocket(copyOrderList[index]);
        copyOrderList.splice(index, 1);
        setOrdersList(copyOrderList);
      }
    },
    [ordersList],
  );
  const updateOrderFutureTicker = useCallback(
    async ({ order, ordersList, strategiesList }) => {
      const { colId, orderId, value } = order.updateBody;

      const ordersListCopy = [...ordersList];
      const strategiesListCopy = [...strategiesList];
      const ordersListIndex = ordersList.findIndex(
        (order) => Number(order.id) === Number(orderId),
      );
      const strategyIndex = strategiesList.findIndex(
        (order) => Number(order.id) === Number(orderId),
      );

      if (ordersListIndex !== -1) {
        unsubscribeFromSocket(value);
        ordersListCopy.splice(ordersListIndex, 1, value);
        setOrdersList(ordersListCopy);
        subscribeOrdersToSocket([value]);
      }
      if (strategyIndex !== -1) {
        strategiesListCopy.splice(strategyIndex, 1, value);
        setStrategiesList(strategiesListCopy);
      }
    },
    [ordersList, strategiesList],
  );
  const handleDeleteOrder = useCallback(
    async ({ orderDetails, ordersList, strategiesList }) => {
      const ordersListCopy = [...ordersList];
      const strategiesListCopy = [...strategiesList];
      const index = ordersList.findIndex(
        (order) => Number(order.id) === Number(orderDetails.orderId),
      );
      const strategyIndex = strategiesList.findIndex(
        (order) => Number(order.id) === Number(orderDetails.orderId),
      );

      if (index !== -1) {
        unsubscribeFromSocket(ordersListCopy[index]);
        ordersListCopy.splice(index, 1);
        setOrdersList(ordersListCopy);
      }
      if (strategyIndex !== -1) {
        strategiesListCopy.splice(strategyIndex, 1);
        setStrategiesList(strategiesListCopy);
      }
    },
    [ordersList, strategiesList],
  );

  //useEffects

  useEffect(() => {
    searchValue !== null && handleDebouncedSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const handleWsConnection = async (message) => {
      switch (message.data.security) {
        case "connection_status":
          // if (isFirstConnection && !message.data.status) {
          //   setIsFirstConnection(false);
          // }
          setWsPricerConnection(message.data.status);
          message.data.status ? setIsScrolling(false) : setIsScrolling(true);

          break;
        default:
          break;
      }
    };

    workerInstances.WebSocketPricesInstance2.connectPricerWS();
    workerInstances?.WebSocketPricesInstance2?.addEventListener(
      "message",
      handleWsConnection,
    );
    return () => {
      workerInstances.WebSocketPricesInstance2.closeWS();
      workerInstances?.WebSocketPricesInstance2?.removeEventListener(
        "message",
        handleWsConnection,
      );
    };
  }, []);

  // get all orders api
  useEffect(() => {
    getOrdersList(
      setStrategiesList,
      setOrdersList,
      setIsOrderListLoading,
      pageNum,
    );
  }, []);

  useEffect(() => {
    const handleDerivs = async (message) => {
      switch (message.data.type) {
        case "new_derivs_order":
          handleNewOrder({ order: message.data, ordersList, strategiesList });

          break;
        case "update_future_ticker":
          updateOrderFutureTicker({
            order: message.data,
            ordersList,
            strategiesList,
          });
          // setTimeout(() => {
          //   dispatch(derivsPricerSlice.removeHighlight(message.data));
          // }, [10000]);
          break;
        case "remove_derivs_order":
          handleDeleteOrder({
            orderDetails: message.data,
            ordersList,
            strategiesList,
          });
          break;
        case "derivs_pricer_ignore":
          handleIgnoreOrder({
            id: message.data.id,
            ordersList,
            pageNum,
          });
          break;
        case "derivs_pricer_add":
          handleAddOrderToCustomList({
            id: message.data.id,
            ordersList,
            strategiesList,
          });
          break;

        default:
          break;
      }
    };

    window.addEventListener("message", handleDerivs);

    return () => {
      window.removeEventListener("message", handleDerivs);
    };
  }, [ordersList, strategiesList]);

  useEffect(() => {
    // if (userName !== "") {
    if (ws !== null) {
      sendEvent({
        type: "derivs_pricer",
        data: {},
        metaData: {},
        token: token.replace("Bearer ", ""),
        isMiniFeed: true,
      });
    } else {
      connectWS(token.replace("Bearer ", ""), dispatch, rfqSlice);

      sendEvent({
        type: "derivs_pricer",
        data: {},
        metaData: {},
        token: token.replace("Bearer ", ""),
        isMiniFeed: true,
      });
    }
  }, []);

 
  //screen resize handler
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // scrolling handler
  useEffect(() => {
    let timeoutId;
    const handleWheelEvent = (event) => {
      if (event.deltaY < 0) {
        setIsScrolling(true);
        console.log("Scrolling started - Up");
      } else {
        console.log("Scrolling started - Down");
        setIsScrolling(true);
      }

      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        console.log("Scrolling ended");
        unsubscribeAllFromSocket();
        setIsScrolling(false);
      }, 1000); // Adjust the timeout duration as needed
    };

    document.addEventListener("wheel", handleWheelEvent);
    return () => {
      document.removeEventListener("wheel", handleWheelEvent);
      clearTimeout(timeoutId); // Clear the timeout when the component is unmounted or updated
    };
  }, []);
  // onClose && onResefresh handler
  useEffect(() => {
    const handleWindowClose = () => {
      // Handle the onClose event here
      // console.log("Window closed");
      unsubscribeAllFromSocket();
    };

    const handleWindowRefresh = (event) => {
      // Handle the onBeforeUnload event here
      //  event.preventDefault();
      //  return (event.returnValue = "Are you sure you want to leave this page?");
      unsubscribeAllFromSocket();
    };

    // Add event listeners
    window.addEventListener("beforeunload", handleWindowRefresh);
    window.addEventListener("unload", handleWindowClose);

    // Clean up the event listeners when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleWindowRefresh);
      window.removeEventListener("unload", handleWindowClose);
    };
  }, []);

  return (
    <Grid
      style={{
        height: windowHeight - 4,
        width: windowWidth - 4,
      }}
    >
      <Grid container className={classes.miniFeedNav}>
        {/* Typography */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          item
          xs={12}
          sm={4}
          md={6}
          lg={8}
          xl={9}
        >
          <Makor />
        </Grid>
        {/* multi select input */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          item
          xs={12}
          sm={4}
          md={3}
          lg={2}
          xl={2}
        >
          <StrategiesMultiSelect
            strategiesList={strategiesList}
            ordersList={ordersList}
            setOrdersList={setOrdersList}
            view={"miniFeed"}
          />
        </Grid>
        {/* search by value */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          item
          xs={12}
          sm={4}
          md={3}
          lg={2}
          xl={1}
        >
          <InputBase
            style={{
              color: "white",
              border: "1px solid #686B76",
              borderRadius: "4px",
              height: "32px",
              width: "180px",
            }}
            className={classes.searchInput}
            variant="outlined"
            onChange={(e) => handleSearchByValue(e.target.value.toLowerCase())}
            value={searchValue}
            placeholder="Search"
            endAdornment={
              <InputAdornment position="start">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        style={{
          marginTop: "100px",
        }}
      >
        {isOrderListLoading ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        ) : ordersList.length > 0 ? (
          ordersList.map((order, index) => {
            return (
              <MiniFeedTable
                order={order}
                key={`${order.id}`}
                ordersList={ordersList}
                setOrdersList={setOrdersList}
                strategiesList={strategiesList}
                setStrategiesList={setStrategiesList}
                isScrolling={isScrolling}
              />
            );
          })
        ) : (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              style={{
                color: "#828282",
                opacity: "0.8",
                fontSize: "15px",
              }}
            >
              No Active Orders
            </Typography>
          </Grid>
        )}
      </Grid>
      <SocketConnectivity platform={"derivs_pricer_mini_feed"} />
    </Grid>
  );
};

export const getOrdersList = async (
  setStrategiesList,
  setOrdersList,
  setIsOrderListLoading,
  pageNum,
) => {
  const token = sessionStorage.getItem("token");
  const res = await axios.get(
    `${process.env.REACT_APP_BASE_URL}${END_POINT.GET_ORDERS_LIST}/`,
    { headers: { Authorization: token } },
  );
  setStrategiesList(res.data.strategiesListWithLegs);
  setOrdersList(res.data.ordersListWithLegs);
  setIsOrderListLoading(false);
};
