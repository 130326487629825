import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "ag-grid-enterprise";
import { Grid } from "@material-ui/core";
import "./style.css";
import { ToggleRenderer } from "../utils/ToggleRenderer";
import { agFormatter, colHeaderFormatter } from "../utils/valueFormatter";
import { cellRendererSelector } from "../utils/cellRendererSelector";
import { editableSelector } from "../utils/editableSelector";
import {
  getUniqueCompanies,
  cellEditorSelector,
  getClientsByCompany,
} from "../utils/cellEditorSelector";
import { cellEditorParamsSelector } from "../utils/cellEditorParamsSelector";
import { onCellEditReq } from "../utils/onCellEditReq";
import { buildRowData } from "../utils/buildRowData";
import { cellKeyDown } from "../utils/onCellKeyDown";
import { cellStyleSelector, getRowClass } from "../utils/styleSelector";
import { useDispatch } from "react-redux";
import * as derivsPricerSlice from "../../../../../store/derivsPricer/derivsPricerSlice";

export const BookingTable = ({
  parsedBookingText,
  order,
  makorStaticData,
  isSubmittingForm,
  bookedTablesData,
}) => {
  // ag grid settings
  const dispatch = useDispatch();
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [columnDefByExec, setColumnDefByExec] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const gridStyle = useMemo(() => ({ height: "100%", width: "800px" }), []);
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  useEffect(() => {

    if (parsedBookingText !== null && columnDefs.length === 0) {
      setColumnDefByExec(
        parsedBookingText.executions.map((exec, i) => {
          return {
            field: `exec${i + 1}`,
            editable: order.status.toLowerCase().includes("booked")
              ? false
              : editableSelector,
            lockPosition: true,
            suppressMovable: true,
            suppressMenu: true,
            valueFormatter: agFormatter, //render to value format not effecting the real value
            cellRendererSelector: cellRendererSelector, //render jsx component
            cellEditorSelector: cellEditorSelector, // render the select option
            cellEditorParams: cellEditorParamsSelector,
            cellEditorPopup: true,
          };
        }),
      );
    }
  }, [parsedBookingText]);

  useEffect(() => {
    setColumnDefs([
      {
        field: "columnHeader",
        headerName: "",
        editable: false,
        lockPosition: true,
        suppressMovable: true,
        suppressMenu: true,
        valueFormatter: colHeaderFormatter,
        cellStyle: cellStyleSelector,
      },
      ...columnDefByExec,
    ]);
  }, [columnDefByExec]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      cellDataType: false,
    };
  }, []);

  const getRowId = useCallback((params) => {
    return params.data.id;
  }, []);
  const onCellKeyDown = useCallback(
    (e) => {
      if (e.event) {
        cellKeyDown(e, rowData, setRowData, order, parsedBookingText);
      }
    },
    [rowData, order],
  );
  const onCellEditRequest = useCallback(
    (e) => {
      onCellEditReq(
        e,
        gridApi,
        rowData,
        setRowData,
        makorStaticData,
        parsedBookingText,
        order,
      );
    },
    [gridApi, rowData, order],
  );

  // useEffect
  useEffect(() => {
    if (!order.status.toLowerCase().includes("booked")) {
      buildRowData(
        parsedBookingText,
        setRowData,
        makorStaticData,
        rowData,
        order,
      );
      setColumnDefs([
        {
          field: "columnHeader",
          headerName: "",
          editable: false,
          lockPosition: true,
          suppressMovable: true,
          suppressMenu: true,
          valueFormatter: colHeaderFormatter,
          cellStyle: cellStyleSelector,
        },
        ...columnDefByExec,
      ]);
    } else {
      if (bookedTablesData !== null) {
        setRowData(bookedTablesData?.bookingTable);
      }
      setColumnDefs(
        columnDefs.map((c) => {
          return { ...c, editable: false };
        }),
      );
    }
  }, [parsedBookingText]);

  useEffect(() => {
    dispatch(derivsPricerSlice.setBookingTable([...rowData]));
  }, [rowData]);

  useEffect(() => {
    if (isSubmittingForm) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }
  }, [isSubmittingForm, gridApi]);

  return (
    <>
      {rowData.length > 0 ? (
        <Grid item className="ag-theme-balham-dark" style={gridStyle} xs={12}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            domLayout={"autoHeight"}
            onGridReady={onGridReady}
            animateRows={true}
            readOnlyEdit={true}
            singleClickEdit={true}
            onCellKeyDown={onCellKeyDown}
            onCellEditRequest={onCellEditRequest}
            enableCellEditingOnBackspace={true}
            getRowClass={getRowClass}
          ></AgGridReact>
        </Grid>
      ) : null}
    </>
  );
};
