import axios from "axios";
import * as actionSnackBar from "../../../../store/snackbar/action";
import { END_POINT } from "../../../../utils";

export const getTablePreview = async (
  rfqObject,
  currentClient,
  currentMarketMaker,
  setTableData,
  dispatch,
  setIsSubmitValid,
  dealStatsData,
  sd,
) => {
  try {
    const token = sessionStorage.getItem("token");
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}${END_POINT.E_CONFO_FX}/preview`,

      {
        rfqObject,
        currentClient,
        currentMarketMaker,
        fwdScale: dealStatsData.fwd_scale,
        sd,
      },
      { headers: { Authorization: token } },
    );
    setTableData(res.data.tablePre);
    setIsSubmitValid(res.data.isSubmitValid);
  } catch (error) {
    console.log(error);
    dispatch(actionSnackBar.setSnackBar("error", error, 3000));
    setIsSubmitValid(false);
  }
};
export const submitEConfo = async (
  rfqObject,
  currentClient,
  currentMarketMaker,
  currentPb,
  trader,
  dispatch,
  setIsSubmitting,
  setIsSubmitValid,
  setLegStatus,
  tableData,
  dealStatsData,
  sd,
) => {
  try {
    setIsSubmitting(true);
    const token = sessionStorage.getItem("token");
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}${END_POINT.E_CONFO_FX}`,

      {
        rfqObject,
        currentClient,
        currentMarketMaker,
        currentPb,
        trader,
        fwdScale: dealStatsData.fwd_scale,
        sd,
        tableData,
      },
      { headers: { Authorization: token } },
    );
    if (res.data.status === "success") {
      await submitTradeToMarkit(
        res.data.xmlArray,
        dispatch,
        setLegStatus,
        rfqObject,
        tableData,
      );
      setIsSubmitValid(false);
    } else {
      throw new Error(res.data.message || "An error occurred");
    }
  } catch (error) {
    console.error("Submission Error:", error);
    dispatch(actionSnackBar.setSnackBar("error", error.message, 3000));
  } finally {
    setIsSubmitting(false);
  }
};

async function submitTradeToMarkit(
  xmlArray,
  dispatch,
  setLegStatus,
  rfqObject,
  tableData,
) {
  const results = [];
  for (const leg of xmlArray) {
    try {
      const response = await axios.post(
        `https://fx-letool.dev.letool-engine.makor-group.com/api/submit_to_fxba`,
        { trade: leg.xml },
      );

      // Check the response status
      if (response.data.status === "success") {
        // Notify the user of success
        dispatch(
          actionSnackBar.setSnackBar(
            "success",
            "XML submitted successfully",
            3000,
          ),
        );
        await saveMessageStatus(
          rfqObject,
          dispatch,
          leg,
          response.data.status === "success" ? "pending" : response.data.status,
          tableData,
        );

        results.push({ ...response.data, key: leg.key });
      } else {
        // Notify the user of failure
        dispatch(
          actionSnackBar.setSnackBar("error", response.data.message, 3000),
        );

        // Add the failed response to results array
        results.push({ ...response.data, key: leg.key, status: "failure" });
      }
    } catch (error) {
      console.error("Submission Error:", error);
      dispatch(actionSnackBar.setSnackBar("error", error.message, 3000));
      // Handle error case by pushing a failure status
      results.push({
        key: leg.key,
        status: `${error.response.data.status} - ${error.response.data.error}`,
      });
    }
  }

  const updates = results.reduce((acc, item) => {
    acc[item.key] = item.status === "success" ? "pending" : item.status;
    return acc;
  }, {});
  setLegStatus((prev) => ({
    ...prev,
    ...updates,
  }));
  return results;
}

async function saveMessageStatus(rfqObject, dispatch, leg, status, tableData) {
  try {
    const { messageId, xml } = leg;
    const token = sessionStorage.getItem("token");
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}${END_POINT.E_CONFO_FX}/message_status`,

      { rfqId: rfqObject.id, messageId, status, xml, tableData },
      { headers: { Authorization: token } },
    );
  } catch (error) {
    console.error("Submission Error:", error);
    dispatch(actionSnackBar.setSnackBar("error", error.message, 3000));
  }
}
