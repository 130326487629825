import axios from "axios";
import { END_POINT } from "../../utils/index";
import * as actionSnackBar from "../snackbar/action";
import {
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";

export let equitiesSpreadSlice = createSlice({
  name: "equitiesSpreadSlice",
  initialState: {
    equityDetails: {
      priceConvention: "",
      ticker1: "",
      ticker2: "",
      weight1: "",
      weight2: "",
      offset1: "",
      offset2: "",
      cash: "",
      flip: false,
      formula: "",
      limit: "",
      tickerSide: false,
      comment:""
    },
    calculation: "",
  },
  reducers: {
    setNewEquityDetails: (state, action) => {
      state.equityDetails = action.payload;
    },
    resetEquityDetails: (state, action) => {
      state.equityDetails = {
        priceConvention: "",
        ticker1: "",
        ticker2: "",
        weight1: "",
        weight2: "",
        offset1: "",
        offset2: "",
        cash: "",
        flip: false,
        formula: "",
        limit: "",
        tickerSide: false,
        comment: "",
      };
      state.calculation = "";
    },
    setFormulaCalculation: (state, action) => {
      state.calculation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(handleTickerSubmit.fulfilled, (state, action) => {
      if (action.payload !== "") {
        state.equityDetails = action.payload;
      }
    });
    builder.addCase(handleCurrencyDifference.fulfilled, (state, action) => {
      if (action.payload !== "") {
        state.equityDetails = action.payload;
      }
    });
    builder.addCase(handleSelectTickerSide.fulfilled, (state, action) => {
      state.equityDetails = action.payload;
    });
  },
});

export const handleStrategySelect =
  ({ key, value }) =>
  async (dispatch, getState) => {
    if (value === "") {
      dispatch(resetEquityDetails());
    } else {
      dispatch(resetEquityDetails());
      let equityDetailsCopy = {
        ...getState().equitiesSpreadSlice.equityDetails,
      };
      if (value === "Spread" || value === "Spread%" || value === "Ratio") {
        equityDetailsCopy = {
          ...equityDetailsCopy,
          [`${key}`]: value,
          weight1: 1,
          weight2: 1,
          offset1: 0,
          offset2: 0,
          cash: null,
          flip: false,
          lastPrice1: "",
          lastPrice2: "",
          limit: null,
          tickerSide: false,
          comment: "",
        };
      } else if (value === "Merger" || value === "Merger%") {
        equityDetailsCopy = {
          ...equityDetailsCopy,
          [`${key}`]: value,
          weight1: null,
          weight2: 1,
          offset1: null,
          offset2: null,
          cash: 0,
          flip: false,
          lastPrice1: "",
          lastPrice2: "",
          limit: null,
          tickerSide: false,
          comment: "",
        };
      }
      dispatch(setNewEquityDetails(equityDetailsCopy));
    }
  };

export const handleEquityDetailsInput =
  ({ key, value }) =>
  async (dispatch, getState) => {
    let equityDetailsCopy = {
      ...getState().equitiesSpreadSlice.equityDetails,
    };

    equityDetailsCopy = {
      ...equityDetailsCopy,
      [`${key}`]: value,
    };
    dispatch(setNewEquityDetails(equityDetailsCopy));
  };
export const handleFormula =
  ({ priceConvention, flip }) =>
  async (dispatch, getState) => {
    let formula;
    switch (priceConvention) {
      case "Spread":
        flip
          ? (formula = `(Price1 * W1 + O1) - (Price2 * W2 + O2)`)
          : (formula = `(Price2 * W2 + O2) - (Price1 * W1 + O1)`);
        break;
      case "Spread%":
        flip
          ? (formula = `100 * ((Price1 * W1 + O1) - (Price2 * W2 + O2)) / L2`)
          : (formula = `100 * ((Price2 * W2 + O2) - (Price1 * W1 + O1)) / L1`);
        break;
      case "Ratio":
        flip
          ? (formula = `(Price1 * W1 + O1) / (Price2 * W2 + O2)`)
          : (formula = `(Price2 * W2 + O2) / (Price1 * W1 + O1)`);
        break;
      case "Merger":
        formula = `Price2 * W2 + Cash - Price1`;
        break;
      case "Merger%":
        formula = `100 * (Price2 * W2 + Cash - Price1) / Price1`;
        break;

      default:
        break;
    }
    dispatch(
      handleEquityDetailsInput({
        key: "formula",
        value: formula,
      }),
    );
  };

export const handleTickerSubmit = createAsyncThunk(
  "orders/handleTickerSubmit",
  async (key, thunkAPI) => {
    try {
      let equityDetailsCopy = {
        ...thunkAPI.getState().equitiesSpreadSlice.equityDetails,
      };
      const value = equityDetailsCopy[`${key}`].trim();
      if (value === "") {
        return "";
      } else {
        const tickerString = `${value} equity`
          .replaceAll("/", "|")
          .replaceAll(" ", "%20");
        const res = await axios.get(
          `${
            process.env.REACT_APP_BLOOMBERG_DERIVS
          }equity/${tickerString.toUpperCase()}`,
        );
        if (res.data.error !== undefined) {
          thunkAPI.dispatch(
            actionSnackBar.setSnackBar("error", res.data.error, 3000),
          );
          return "";
        } else if (res.data.price === "") {
          return "";
        } else {
          let {
            bloomberg_ticker,
            country,
            div_date,
            has_adr,
            name,
            price,
            report_date,
            currency,
          } = res.data;
          const index = key === "ticker1" ? 1 : key === "ticker2" ? 2 : "";
          if (currency === "GBp") {
            currency = "GBP";
            price = price / 100;
          }
          if (
            index === 2 &&
            equityDetailsCopy.currency1 !== undefined &&
            currency !== undefined &&
            equityDetailsCopy.currency1 !== currency
          ) {
            thunkAPI.dispatch(
              handleCurrencyDifference({
                ...equityDetailsCopy,
                [`lastPrice${index}`]: price.toFixed(2),
                [`bloomberg_ticker${index}`]: bloomberg_ticker,
                [`country${index}`]: country,
                [`dividend${index}`]: div_date,
                [`hasAdr${index}`]: has_adr,
                [`name${index}`]: name,
                [`earnings${index}`]: report_date,
                [`currency${index}`]: currency,
                [`originalCurrency${index}`]: res.data.currency,
                [`originalLastPrice${index}`]: price.toFixed(2),
              }),
            );
            return "";
          } else if (price !== undefined) {
            return {
              ...thunkAPI.getState().equitiesSpreadSlice.equityDetails,
              [`lastPrice${index}`]: price.toFixed(2),
              [`bloomberg_ticker${index}`]: bloomberg_ticker,
              [`country${index}`]: country,
              [`dividend${index}`]: div_date,
              [`hasAdr${index}`]: has_adr,
              [`name${index}`]: name,
              [`earnings${index}`]: report_date,
              [`currency${index}`]: currency,
              [`originalCurrency${index}`]: res.data.currency,
              [`originalLastPrice${index}`]: price.toFixed(2),
            };
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  },
);

export const handleCurrencyDifference = createAsyncThunk(
  "orders/handleCurrencyDifference",
  async (data, thunkAPI) => {
    try {
      const res = await axios.get(
        `${
          process.env.REACT_APP_BLOOMBERG_DERIVS
        }spot/${data.currency1.toUpperCase()}${data.currency2.toUpperCase()}`,
      );
      const convertRate = res.data.price;

      return {
        ...data,
        lastPrice2: (data.lastPrice2 / convertRate).toFixed(2),
        currency2: data.currency1,
      };
    } catch (err) {
      thunkAPI.dispatch(actionSnackBar.setSnackBar("error", err, 3000));
    }
  },
);
export const handleOrderSubmit = createAsyncThunk(
  "orders/handleOrderSubmit",
  async (data, thunkAPI) => {
    try {
      let equityDetailsCopy = {
        ...thunkAPI.getState().equitiesSpreadSlice.equityDetails,
      };
      const ticker1 = equityDetailsCopy.ticker1.trim();
      const ticker2 = equityDetailsCopy.ticker2.trim();
      const token = sessionStorage.getItem("token");

      const {
        priceConvention,
        weight1,
        weight2,
        offset1,
        offset2,
        cash,
        flip,
        limit,
        tickerSide,
        originalCurrency1,
        originalCurrency2,
        comment,
      } = equityDetailsCopy;
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${END_POINT.CREATE_EQUITIES_ORDER}`,
        {
          priceConvention,
          ticker1,
          ticker2,
          weight1,
          weight2,
          offset1,
          offset2,
          cash,
          flip,
          limit,
          currency1: originalCurrency1,
          currency2: originalCurrency2,
          spotCurrencyCode:
            originalCurrency1 === originalCurrency2
              ? null
              : tickerSide
              ? `${originalCurrency2.toUpperCase()}${originalCurrency1.toUpperCase()}`
              : `${originalCurrency1.toUpperCase()}${originalCurrency2.toUpperCase()}`,
          tickerSide,
          comment,
        },
        { headers: { Authorization: token } },
      );
      if (res.data.status) {
        thunkAPI.dispatch(resetEquityDetails());
      }
    } catch (err) {
      thunkAPI.dispatch(actionSnackBar.setSnackBar("error", err, 3000));
    }
  },
);

export const calculateByFormula = () => async (dispatch, getState) => {
  let equityDetailsCopy = {
    ...getState().equitiesSpreadSlice.equityDetails,
  };
  let calculation;
  switch (equityDetailsCopy.priceConvention) {
    case "Spread":
      equityDetailsCopy.flip
        ? (calculation =
            equityDetailsCopy.lastPrice1 * equityDetailsCopy.weight1 +
            equityDetailsCopy.offset1 -
            (equityDetailsCopy.lastPrice2 * equityDetailsCopy.weight2 +
              equityDetailsCopy.offset2))
        : (calculation =
            equityDetailsCopy.lastPrice2 * equityDetailsCopy.weight2 +
            equityDetailsCopy.offset2 -
            (equityDetailsCopy.lastPrice1 * equityDetailsCopy.weight1 +
              equityDetailsCopy.offset1));
      break;
    case "Spread%":
      equityDetailsCopy.flip
        ? (calculation =
            (100 *
              (equityDetailsCopy.lastPrice1 * equityDetailsCopy.weight1 +
                equityDetailsCopy.offset1 -
                (equityDetailsCopy.lastPrice2 * equityDetailsCopy.weight2 +
                  equityDetailsCopy.offset2))) /
            equityDetailsCopy.lastPrice2)
        : (calculation =
            (100 *
              (equityDetailsCopy.lastPrice2 * equityDetailsCopy.weight2 +
                equityDetailsCopy.offset2 -
                (equityDetailsCopy.lastPrice1 * equityDetailsCopy.weight1 +
                  equityDetailsCopy.offset1))) /
            equityDetailsCopy.lastPrice1);
      break;
    case "Ratio":
      equityDetailsCopy.flip
        ? (calculation =
            (equityDetailsCopy.lastPrice1 * equityDetailsCopy.weight1 +
              equityDetailsCopy.offset1) /
            (equityDetailsCopy.lastPrice2 * equityDetailsCopy.weight2 +
              equityDetailsCopy.offset2))
        : (calculation =
            (equityDetailsCopy.lastPrice2 * equityDetailsCopy.weight2 +
              equityDetailsCopy.offset2) /
            (equityDetailsCopy.lastPrice1 * equityDetailsCopy.weight1 +
              equityDetailsCopy.offset1));
      break;
    case "Merger":
      calculation =
        equityDetailsCopy.lastPrice2 * equityDetailsCopy.weight2 +
        equityDetailsCopy.cash -
        equityDetailsCopy.lastPrice1;
      break;
    case "Merger%":
      calculation =
        (100 *
          (equityDetailsCopy.lastPrice2 * equityDetailsCopy.weight2 +
            equityDetailsCopy.cash -
            equityDetailsCopy.lastPrice1)) /
        equityDetailsCopy.lastPrice1;
      break;

    default:
      break;
  }

  dispatch(setFormulaCalculation(calculation.toFixed(2)));
};

export const handleSelectTickerSide = createAsyncThunk(
  "orders/handleSelectTickerSide",
  async (data, thunkAPI) => {
    let equityDetailsCopy = {
      ...thunkAPI.getState().equitiesSpreadSlice.equityDetails,
    };

    try {
      if (data) {
        const res = await axios.get(
          `${
            process.env.REACT_APP_BLOOMBERG_DERIVS
          }spot/${equityDetailsCopy.originalCurrency2.toUpperCase()}${equityDetailsCopy.originalCurrency1.toUpperCase()}`,
        );
        const convertRate = res.data.price;

        return {
          ...equityDetailsCopy,
          currency1: equityDetailsCopy.originalCurrency2.toUpperCase(),
          currency2: equityDetailsCopy.originalCurrency2.toUpperCase(),
          lastPrice1: (
            equityDetailsCopy.originalLastPrice1 / convertRate
          ).toFixed(2),
          lastPrice2: equityDetailsCopy.originalLastPrice2,
          tickerSide: data,
        };
      } else {
        const res = await axios.get(
          `${
            process.env.REACT_APP_BLOOMBERG_DERIVS
          }spot/${equityDetailsCopy.originalCurrency1.toUpperCase()}${equityDetailsCopy.originalCurrency2.toUpperCase()}`,
        );
        const convertRate = res.data.price;

        return {
          ...equityDetailsCopy,
          currency1: equityDetailsCopy.originalCurrency1.toUpperCase(),
          currency2: equityDetailsCopy.originalCurrency1.toUpperCase(),
          lastPrice1: equityDetailsCopy.originalLastPrice1,
          lastPrice2: (
            equityDetailsCopy.originalLastPrice2 / convertRate
          ).toFixed(2),
          tickerSide: data,
        };
      }
    } catch (err) {
      console.log(err);
    }
  },
);

export const {
  setNewEquityDetails,
  resetEquityDetails,
  setFormulaCalculation,
} = equitiesSpreadSlice.actions;
export default equitiesSpreadSlice.reducer;
