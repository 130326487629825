import React, { useEffect } from "react";
import "../reusableComponents/SuccessFailAnimation.scss";
function SuccessFailAnimation(props) {
  const { status, padding, loaderClass } = props;


  const returnSvgPath = (status) => {
    if (status === "success") {
      return (
        <path
          className={`${loaderClass}-path`}
          d="M17.5,42.3l23.2-23.2L23.9,35.9l-17-17c3.4-8.1,11.3-13.7,20.6-13.7c12.3,0,22.3,10,22.3,22.3 s-10,22.3-22.3,22.3S5.2,39.8,5.2,27.5c0-3.1,0.6-6,1.7-8.6"
        />
      );
    } else if (status === "fail") {
      return (
        <path
          className={`${loaderClass}-path`}
          d="M15.8,39.2l23.4-23.4v23.4L11.7,11.7c4-4,9.6-6.5,15.8-6.5c12.3,0,22.3,10,22.3,22.3s-10,22.3-22.3,22.3 S5.2,39.8,5.2,27.5c0-6.2,2.5-11.8,6.6-15.8"
        />
      );
    } else {
      return (
        <path
          className={`${loaderClass}-path`}
          d="M17.5,42.3l23.2-23.2L23.9,35.9l-17-17c3.4-8.1,11.3-13.7,20.6-13.7c12.3,0,22.3,10,22.3,22.3 s-10,22.3-22.3,22.3S5.2,39.8,5.2,27.5c0-3.1,0.6-6,1.7-8.6"
        />
      );
    }
  };
  return (
    <div>
      <div
        className={`${loaderClass}-container`}
        style={{ paddingBottom: padding }}
      >
        <svg
          className={`${loaderClass} ${
            status === "success"
              ? "success"
              : status === "fail"
              ? "error"
              : "loading"
          }`}
          viewBox="0 0 55 55"
          xmlSpace="preserve"
        >
          <circle
            className={`${loaderClass}-circle`}
            cx="27.5"
            cy="27.5"
            r="27.5"
          />
          {returnSvgPath(status)}
        </svg>
      </div>
    </div>
  );
}

export default SuccessFailAnimation;
