import React, { useCallback, useEffect, useState } from "react";
import {
  subscribeOrdersToSocket,
  unsubscribeAllFromSocket,
  unsubscribeFromSocket,
  unsubscribeOnSearch,
} from "../derivsPricerBlotter/utils/apiCalls";
import { getBookedOrders } from "./utils/apiCalls";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  Typography,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

import { useSelector } from "react-redux";
import { useStyles } from "../../../Styles/pricerStyle";
import SearchIcon from "@material-ui/icons/Search";
import { DerivsTableByOrder } from "../priceTableByOrder/DerivsTableByOrder";
import axios from "axios";
import {
  testDateWithinRange,
  useDebounce,
} from "../derivsPricerBlotter/utils/utilsFunctions";
import { END_POINT } from "../../../utils";
import workerInstances from "../../../services/index";

export const BookingBlotter = () => {
  const classes = useStyles();
  const [ordersList, setOrdersList] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  const [isOrderListLoading, setIsOrderListLoading] = useState(true);
  const [pageNum, setPageNum] = useState(1);
  const [bbgUpdate, setBbgUpdate] = useState(null);
  const [bbgCurrentTImestamp, setBbgCurrentTimestamp] = useState(
    new Date().getTime(),
  );
  const [entitlement, setEntitlement] = useState(null);
  const [team, setTeam] = useState(null);
  const [wsPricerConnection, setWsPricerConnection] = useState(false);
  const [isFirstConnection, setIsFirstConnection] = useState(true);

  const parsedString = useSelector(
    (state) => state.derivsPricerSlice.parsedString,
  );

  const handleSearchByValue = async (searchValue) => {
    setSearchValue(searchValue);
  };
  const handlePagination = async (e, value) => {
    setIsOrderListLoading(true);
    const updatedOrdersList = await Promise.all(
      ordersList.map(async (order, index) => {
        if (
          (value === 1 && index < 10) ||
          (value > 1 && (value - 1) * 10 <= index && index < value * 10)
        ) {
          subscribeOrdersToSocket([order]);
          const { symbol, equityIndex, futureTicker } = order;
          const tickerString = (
            futureTicker !== null
              ? futureTicker + " " + equityIndex
              : symbol.replaceAll("/", "%252f") + " " + equityIndex
          )
            .replaceAll("/", "|")
            .replaceAll(" ", "%20");
          const res = await axios.get(
            `${
              process.env.REACT_APP_BLOOMBERG_DERIVS
            }equity/${tickerString.toUpperCase()}`,
          );
          const {
            div_date,
            name,
            price,
            report_date,
            sector,
            ticker,
            has_adr,
            country,
            multiplier,
            security_type,
            isin,
            primary_exchange,
          } = res.data;
          const dividend = await testDateWithinRange(div_date);
          const earnings = await testDateWithinRange(report_date);
          return {
            ...order,
            dividend,
            name,
            lastPrice: price,
            earnings,
            sector,
            ticker,
            has_adr,
            country,
            multiplier,
            securityType: security_type,
            isin,
            primaryExchange: primary_exchange,
          };
        } else {
          unsubscribeOnSearch([order]);
          return { ...order };
        }
      }),
    );
    setOrdersList(updatedOrdersList);
    setIsOrderListLoading(false);

    setPageNum(value);
  };

  const updateStatus = (data) => {
    const { id, status } = data;

    const copyOrdersList = [...ordersList];

    const ordersListIndex = copyOrdersList.findIndex(
      (s) => Number(s.id) === Number(id),
    );

    if (ordersListIndex !== -1) {
      copyOrdersList[ordersListIndex] = {
        ...copyOrdersList[ordersListIndex],
        status,
      };
      setOrdersList(copyOrdersList);
    }
  };

  const updateOrderFutureTicker = useCallback(
    async ({ order, ordersList, strategiesList }) => {
      const { colId, orderId, value } = order.updateBody;

      const ordersListCopy = [...ordersList];
      const ordersListIndex = ordersList.findIndex(
        (order) => Number(order.id) === Number(orderId),
      );

      if (ordersListIndex !== -1) {
        unsubscribeFromSocket(value);
        ordersListCopy.splice(ordersListIndex, 1, value);
        setOrdersList(ordersListCopy);
        subscribeOrdersToSocket([value]);
      }
    },
    [ordersList, pageNum],
  );
  const debouncedSearchTerm = useDebounce(searchValue, 500);

  const handleNewOrder = useCallback(
    async ({ order, ordersList }) => {
      const ordersListCopy = [...ordersList];
      const { symbol, equityIndex, futureTicker } = order
      const tickerString = (
        futureTicker !== null
          ? futureTicker + " " + equityIndex
          : symbol.replaceAll("/", "%252f") + " " + equityIndex
      )
        .replaceAll("/", "|")
        .replaceAll(" ", "%20");
      const res = await axios.get(
        `${
          process.env.REACT_APP_BLOOMBERG_DERIVS
        }equity/${tickerString.toUpperCase()}`,
      );
      const {
        div_date,
        name,
        price,
        report_date,
        sector,
        ticker,
        has_adr,
        country,
        security_type,
        isin,
        primary_exchange,
      } = res.data;
      const dividend = await testDateWithinRange(div_date);
      const earnings = await testDateWithinRange(report_date);

      ordersListCopy.unshift({
        ...order,
        dividend,
        name,
        lastPrice: price,
        earnings,
        sector,
        ticker,
        has_adr,
        country,
        isNew: true,
        isNewTimestamp: new Date().getTime(),
        securityType: security_type,
        isin,
        primaryExchange: primary_exchange,
      });

      setOrdersList(ordersListCopy);
      subscribeOrdersToSocket([order]);
      const orderToUnsubscribe = ordersListCopy.find(
        (order, index) => index === pageNum * 10,
      );
      if (orderToUnsubscribe !== undefined) {
        unsubscribeFromSocket(orderToUnsubscribe);
      }
    },
    [ordersList, pageNum],
  );
  useEffect(() => {
    searchValue !== null && handleDebouncedSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  const handleDebouncedSearch = async () => {
    try {
      const token = sessionStorage.getItem("token");
      if (searchValue.length > 1) {
        setPageNum(1);
        unsubscribeOnSearch(ordersList);
        setTimeout(async () => {
          setIsOrderListLoading(true);
          const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}${END_POINT.SEARCH_ORDER_BY_VALUE}`,
            { searchValue },
            { headers: { Authorization: token } },
          );
          if (res.data.length > 0) {
            const ordersList = await Promise.all(
              res.data.map(async (order) => {
                const { symbol, equityIndex } = order;
                const tickerString = (
                  symbol.replaceAll("/", "%252f") +
                  " " +
                  equityIndex
                )
                  .replaceAll("/", "|")
                  .replaceAll(" ", "%20");
                const res = await axios.get(
                  `${
                    process.env.REACT_APP_BLOOMBERG_DERIVS
                  }equity/${tickerString.toUpperCase()}`,
                );
                const {
                  div_date,
                  name,
                  price,
                  report_date,
                  sector,
                  ticker,
                  has_adr,
                  country,
                  multiplier,
                  security_type,
                  isin,
                  primary_exchange,
                } = res.data;

                const dividend = await testDateWithinRange(div_date);
                const earnings = await testDateWithinRange(report_date);

                return {
                  ...order,
                  dividend,
                  name,
                  lastPrice: price,
                  earnings,
                  sector,
                  ticker,
                  has_adr,
                  country,
                  multiplier,
                  security_type,
                  isin,
                  primaryExchange: primary_exchange,
                };
              }),
            );
            subscribeOrdersToSocket(ordersList);

            setBbgUpdate({
              timestamp: new Date().getTime(),
              payload: ordersList,
            });
          } else {
            setOrdersList(res.data);
          }
          setIsOrderListLoading(false);
        }, 500);
      } else if (searchValue.length < 2) {
        setPageNum(1);
        unsubscribeOnSearch(ordersList);
        getBookedOrders(
          setOrdersList,
          setIsOrderListLoading,
          pageNum,

          setBbgUpdate,
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getBookedOrders(
      setOrdersList,
      setIsOrderListLoading,
      pageNum,
      setBbgUpdate,
      setEntitlement,
      setTeam,
    );
    return () => {
      unsubscribeAllFromSocket();
    };
  }, []);

  useEffect(() => {
    if (bbgUpdate !== null) {
      if (bbgUpdate.timestamp > bbgCurrentTImestamp) {
        setOrdersList(bbgUpdate.payload);
        setBbgCurrentTimestamp(bbgUpdate.timestamp);
      }
    }
  }, [bbgUpdate]);

  useEffect(() => {
    const handleDerivs = async (message) => {
      switch (message.data.type) {
        case "update_future_ticker":
          updateOrderFutureTicker({
            order: message.data,
            ordersList,
          });

          break;
        case "remove_derivs_order":
          updateStatus(message.data);
          break;
        case "derivs_pricer_status_update":
          message.data.status.toLowerCase() === "booked"
            ? handleNewOrder({
                order: message.data.orderDetails[0],
                ordersList,
              })
            : updateStatus(message.data);
          break;
        default:
          break;
      }
    };
    const handleWsConnection = async (message) => {
      switch (message.data.security) {
        case "connection_status":
          if (isFirstConnection && !message.data.status) {
            setIsFirstConnection(false);
          }
          setWsPricerConnection(message.data.status);

          break;
        default:
          break;
      }
    };
    window.addEventListener("message", handleDerivs);
    workerInstances?.WebSocketPricesInstance?.addEventListener(
      "message",
      handleWsConnection,
    );

    return () => {
      window.removeEventListener("message", handleDerivs);

      workerInstances?.WebSocketPricesInstance?.removeEventListener(
        "message",
        handleWsConnection,
      );
    };
  }, [ordersList, pageNum]);

  return (
    <Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{
          height: parsedString.length > 0 ? "65vh" : "75vh",
          // maxHeight: "80vh",
          marginTop: "15px",
        }}
      >
        <Grid item xs={12} sm={6} md={4}>
          <Typography
            style={{
              fontSize: 22,
              marginBottom: "20px",
              color: "#828282",
              opacity: "0.8",
              height: "40px",
            }}
          >
            Booking Blotter
          </Typography>
        </Grid>
        <Grid
          xs={12}
          sm={6}
          md={8}
          spacing={2}
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item>
            <InputBase
              style={{
                color: "white",
                border: "1px solid #686B76",
                borderRadius: "4px",
                height: "32px",
                width: "256px",
              }}
              className={classes.searchInput}
              variant="outlined"
              onChange={(e) =>
                handleSearchByValue(e.target.value.toLowerCase())
              }
              value={searchValue}
              placeholder="Search"
              endAdornment={
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          alignContent="flex-start"
          wrap="wrap"
          style={{
            height: "90%",
            overflow: "scroll",
            overflowX: "hidden",
          }}
        >
          {isOrderListLoading ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          ) : ordersList.length > 0 ? (
            ordersList.map((order, index) => {
              if (
                (pageNum === 1 && index < 10) ||
                (pageNum > 1 &&
                  (pageNum - 1) * 10 <= index &&
                  index < pageNum * 10)
              ) {
                return (
                  <DerivsTableByOrder
                    key={`${order.id}`}
                    order={order}
                    setOrdersList={setOrdersList}
                    ordersList={ordersList}
                    tab={"booking"}
                    team={team}
                  />
                );
              }
            })
          ) : (
            <Typography
              style={{ color: "#828282", opacity: "0.8", fontSize: "15px" }}
            >
              No Active Orders
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid className={classes.pagination}>
        <Pagination
          count={
            ordersList?.length % 10 > 0
              ? Math.floor(ordersList.length / 10) + 1
              : ordersList.length / 10
          }
          page={pageNum}
          onChange={handlePagination}
          size="large"
          // disabled={ordersList.length < 6}
        />
      </Grid>
    </Grid>
  );
};
