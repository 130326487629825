import React, { useEffect } from "react";
import { useStyles } from "../../../../Styles/pricerStyle";
import { TextField, Paper } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import {
  removeOrderFromCustomList,
  addOrderToCustomList,
} from "../utils/apiCalls";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const StrategiesMultiSelect = ({
  strategiesList,
  ordersList,
  setOrdersList,
  view = null,
}) => {
  const classes = useStyles();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        backgroundColor: "#2D2D2D",
        color: "white",
      },
    },
  };
  const [ordersChecked, setOrdersChecked] = React.useState([]);
  const [trackingList, setTrackingList] = React.useState([]);

  //SETS CHECKED ORDERS IN THE MULTI SELECT
  useEffect(() => {
    const idList = ordersList.map((order) => {
      return order.id;
    });
    setOrdersChecked([...ordersList]);
    setTrackingList(idList);
  }, [ordersList]);

  const handleChange = async (event, values) => {
    const copyOrderList = [...ordersList];
    const value = values.map((v) => {
      return v.id;
    });
    const orderToRemove = trackingList.filter((id) => !value.includes(id));
    if (orderToRemove.length > 0) {
      const index = ordersList.findIndex(
        (order) => Number(order.id) === Number(orderToRemove[0]),
      );
      if (index !== -1) {
        removeOrderFromCustomList(orderToRemove[0]);
      }
    } else {
      const orderToAdd = value.filter((v) => !trackingList.includes(v));
      if (orderToAdd.length > 0) {
        addOrderToCustomList(orderToAdd[0]);
      }
    }

    setTrackingList(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };
  const handleKeyDown = (event) => {
    if (event.key === "Backspace" && event.target.value === "") {
      setOrdersChecked(ordersChecked);
    }
  };
  return (
    <Autocomplete
      classes={{
        clearIndicator: classes.clearIndicator,
        popupIndicator: classes.popupIndicator,
        endAdornment: classes.endAdornment,
        root: classes.root,
        inputRoot: classes.inputRoot,
        input: classes.input,
        option: classes.option,
        noOptions: classes.noOptions,
      }}
      noOptionsText="No options found"
      // inputProps={{ className: classes.input }}
      PaperComponent={(props) => (
        <Paper {...props} className={classes.paper}>
          {props.children}
        </Paper>
      )}
      // sx={{ width: 200 }}
      multiple
      fullWidth
      disableClearable
      value={ordersChecked}
      options={strategiesList}
      getOptionLabel={(option) => option.strategy}
      getOptionSelected={(option, value) => option.id === value.id}
      disableCloseOnSelect
      onChange={handleChange}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{
              marginRight: 8,
              color: selected ? "#2692FF" : "#989EA8",
            }}
            checked={selected}
          />
          {option.strategy}
        </React.Fragment>
      )}
      // style={{ width: 500 }}
      renderInput={(params) => (
        <TextField
          {...params}
          className={
            view === "miniFeed"
              ? classes.miniFeedMultiSelect
              : classes.multiSelectTextfield
          }
          variant="outlined"
          label={
            ordersChecked.length > 0
              ? `Strategies [${ordersChecked.length}]`
              : "Strategies"
          }
          InputLabelProps={{
            className: classes.label,
            style: {
              color: "white",
            },
          }}
          onKeyDown={handleKeyDown}

          // placeholder={`Strategies ${ordersChecked.length}`}
        />
      )}
      renderTags={(values) =>
        // <span style={{ color: "white" }}>Strategies {values.length}</span>
        null
      }
    ></Autocomplete>
  );
};
