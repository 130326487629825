import React from "react";
import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { makeStyles } from "@material-ui/core/styles";

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f5f5f5",
    padding: theme.spacing(4), // Increase padding for more spacing
  },
  card: {
    maxWidth: 600, // Increased width for a bigger card
    padding: theme.spacing(4), // Increase padding for content inside the card
    textAlign: "center",
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.15)", // Make the shadow a bit stronger
  },
  icon: {
    fontSize: 80, // Increased icon size for more prominence
    marginBottom: theme.spacing(3), // Increase margin below the icon
  },
  errorText: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(3), // More spacing between the error text and other elements
    fontSize: "20px",
  },
  cardActions: {
    justifyContent: "center",
  },
}));

function ErrorFallback({ error, resetErrorBoundary }) {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Card className={classes.card}>
        <CardContent>
          <ErrorOutlineIcon color="error" className={classes.icon} />
          <Typography variant="h4" component="div" gutterBottom>
            Oops! Something went wrong.
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            className={classes.errorText}
          >
            An unexpected error has occurred.
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="pre"
            className={classes.errorText}
          >
            {error.message}
          </Typography>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button
            variant="contained"
            color="primary"
            onClick={resetErrorBoundary}
          >
            Try Again
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}

export default ErrorFallback;
