import React from "react";
import { Bar } from "react-chartjs-2";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import { Typography, Grid, IconButton } from "@material-ui/core";
import { ReactComponent as Copy } from "../../../../assets/copy.svg";
import { makeStyles } from "@material-ui/core/styles";
import * as rfqSlice from "../../../../store/rfq/rfqSlice";
import axios from "axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);
export const options = {
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 0.5,
      barPercentage: 1.0,
      maxBarThickness: 100,
      minBarLength: 20,
    },
  },
  maintainAspectRatio: false,
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      ticks: {
        color: "rgb(255, 255, 255)",
        beginAtZero: true,
        font: {
          size: 10,
        },
      },
      grid: {
        color: "#404040",
      },
    },
    x: {
      ticks: {
        color: "rgb(255, 255, 255)",
        beginAtZero: true,
        font: {
          size: 10,
        },
      },
      grid: {
        color: "#404040",
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};
function MlModal({ rfq_object, header }) {
  const [disableButton, setDisableButton] = useState(false);
  const [scores, setScores] = useState([]);
  const [marketMakers, setMarketMakers] = useState([]);

  const lpPerdiction = useSelector((state) => state.rfqSlice.lpPerdiction);
  const classes = useStyles();
  const rfqId = sessionStorage.getItem("rfqId");
  const recommendationRfqs = useSelector(
    (state) => state.rfqSlice.recommendationRfqs,
  );
  const filteredRecommendation = recommendationRfqs?.filter(
    (rfq) => Number(rfq.rfqId) === Number(rfqId),
  );

  const getMarketMakers = () => {
    const arr = [];
    if (filteredRecommendation.length > 0) {
      filteredRecommendation[0].recommendationDetails.map((x) => {
        return arr.push(x[0]);
      });
    }
    setMarketMakers(arr);
  };

  const getScores = () => {
    const arr = [];
    if (filteredRecommendation.length > 0) {
      filteredRecommendation[0].recommendationDetails.map((x) => {
        return arr.push(x[1]);
      });
    }
    setScores(arr);
  };

  useEffect(() => {
    getMarketMakers();
    getScores();
  }, [recommendationRfqs, rfqId]);

  const labels = marketMakers;
  const data2 = {
    labels,
    datasets: [
      {
        // label: "Dataset 1",
        data: scores.map((m, index) => scores[index]),
        borderColor: "rgba(60, 75,99,1.0)",
        backgroundColor: "rgba(80, 236,168,0.5)",
      },
    ],
  };

  const copy_to_clip = async (string_value) => {
    // setDisableButton(true);
    let stringArray = filteredRecommendation[0].recommendationDetails.map(
      (x) => {
        return x;
      },
    );
    let finalString = stringArray.join("\r\n");
    navigator.clipboard.writeText(finalString);
  };

  return (
    <Grid
      container
      className={
        recommendationRfqs.length > 0 ? classes.rfqModal : classes.rfqModalEmpty
      }
    >
      <Grid
        item
        container
        className={classes.rfqodalHeader}
        justifyContent="space-between"
      >
        <Grid item>
          <Typography className={classes.header}>{header}</Typography>
        </Grid>
        <Grid item>
          <IconButton className={classes.icon}>
            <Copy
              onClick={() => copy_to_clip("data")}
              className={clsx({
                [classes.copybuttonDisable]: disableButton,
              })}
            />
          </IconButton>
        </Grid>
      </Grid>
      <Grid
        style={{
          color: "white",
          backgroundColor: "#222222",
          display: "flex",
          flex: "5",
          width: "100%",
          height: "50vh",
        }}
      >
        {filteredRecommendation.length > 0 ? (
          <Bar style={{ height: "35vh" }} options={options} data={data2} />
        ) : (
          ""
        )}
      </Grid>
    </Grid>
  );

  // <Grid item >
  {
    /* <Typography className={classes.header}>{header}</Typography>
      <Typography className={classes.icon}>
        <Copy
          onClick={() => copy_to_clip("data")}
          className={clsx({
            [classes.copybuttonDisable]: disableButton,
          })}
        />
      </Typography>
      <Grid> */
  }
  {
    /* </Grid> */
  }
  // </Grid>
}

export default MlModal;
export const useStyles = makeStyles(() => ({
  rfqModal: {
    border: "1px solid #686B76",
    borderRadius: "8px 8px 0px 0px",
    display: "flex",
    flexDirection: "column",
    // height: "33.5vh",
    overflowY: "auto",
  },
  rfqModalEmpty: {
    border: "1px solid #686B76",
    borderRadius: "8px 8px 0px 0px",
    display: "flex",
    flexDirection: "column",
    height: "15.5vh",
    overflowY: "auto",
  },
  rfqodalHeader: {
    color: "#FFFFFF",
    borderBottom: "1px solid #686B76",
    height: "43px",
    font: "normal normal normal 20px/24px Inter",
    // justifyContent: "space-between",
    overflowY: "hidden",
  },
  header: {
    marginTop: "15px",
    marginLeft: "15px",
    color: "white",
  },
  icon: {
    marginRight: "2px",
  },
}));
