import React, { useState, useEffect } from "react";
import axios from "axios";
import * as actionSnackBar from "../../../store/snackbar/action";
import workerInstances from "../../../services";
import { END_POINT } from "../../../utils/index";
import { useDispatch } from "react-redux";
import * as userSlice from "../../../store/users/usersSlice";
import * as rfqSlice from "../../../store/rfq/rfqSlice";
import { useNavigate } from "react-router-dom";
import LoginView from "./Login.view";
import { connectWS } from "../../../services/websocket";
import { setAuthToken } from "../../../utils/index";

const Login = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [formState, setFormState] = useState({
    userName: "",
    password: "",
    showPassword: false,
  });
  const { userName, password } = formState;

  const handleChange = (event) => {
    event.preventDefault();
    setFormState((prevState) => {
      return { ...prevState, [event.target.name]: event.target.value };
    });
  };

  const loginUser = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}` + END_POINT.LOGIN,
        { userName, password },
      );
      sessionStorage.setItem("token", "Bearer " + response.data.token);
      setAuthToken(response.data.token);
      dispatch(userSlice.saveLoginDetails(response.data));
      sessionStorage.setItem("userName", response.data.userName);
      sessionStorage.setItem("id", response.data.id);
      sessionStorage.setItem("userId", response.data.userId);
      sessionStorage.setItem("entrances", response.data.entrances);
      sessionStorage.setItem("role", response.data.role);
      const entitlement = response.data.entitlement.split(";");
      if (response.data.entrances === 0) {
        navigate("/changePassword");
      } else {
        if (
          entitlement.filter((entl) => entl === "fx-rfq-blotter").length > 0
        ) {
          navigate("/rfqblotter");
        } else if (
          entitlement.filter((entl) => entl === "fi-blotter").length > 0
        ) {
          navigate("/fiblotter");
        } else if (entitlement.filter((entl) => entl === "pricer").length > 0) {
          navigate("/pricer");
        } else if (
          entitlement.filter((entl) => entl === "equities-spread").length > 0
        ) {
          navigate("/equities_spread");
        }
      }
      connectWS(response.data.token, dispatch, rfqSlice);
    } catch (error) {
      console.log(error);
      dispatch(
        actionSnackBar.setSnackBar("error", error.response.data.error, 3000),
      );
    }
  };

  const handleClickShowPassword = () => {
    setFormState({
      ...formState,
      showPassword: !formState.showPassword,
    });
  };

  return (
    <LoginView
      formState={formState}
      handleClickShowPassword={handleClickShowPassword}
      handleChange={handleChange}
      loginUser={loginUser}
    />
  );
};

export default Login;
