import {
  Box,
  Button,
  Chip,
  Dialog,
  Grid,
  IconButton,
  TextField,
  Typography,
  styled,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableBody,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useStyles } from "../../../Styles/fiStyles";
import * as clientActions from "../../../store/fi/fiClientsSlice";
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";
import axios from "axios";
import { END_POINT } from "../../../utils/index";
import * as actionSnackBar from "../../../store/snackbar/action";

const StripedTableRow = styled(TableRow)(({ theme, index }) => ({
  "&:nth-of-type(odd)": {
    background: "#222222",
    borderRadius: "8px",
    opacity: 1,
  },
  "&:nth-of-type(even)": {
    background: "#2D2D2D",
    opacity: 1,
  },
}));
const WhiteFontTableCell = styled(TableCell)({
  color: "white",
  textAlign: "center",
});
const HeaderFontTableCell1 = styled(TableCell)({
  color: "#828282",
  opacity: "0.8",
  textAlign: "center",
});

const CustomTableContainer = styled(TableContainer)({
  border: "2px solid white",
  borderRadius: "8px",
  maxWidth: "99%",
});

const GppCodeDialog = (props) => {
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");
  const { open, handleClose } = props;
  const classes = useStyles();

  const selectedClient = useSelector(
    (state) => state.fiClientsSlice.selectedClient,
  );
  const [currencies, setCurrencies] = useState({ items: [], isLoading: true });
  const [isCodesListLoading, setIsCodesListLoading] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [gppCode, setGppCode] = useState("");
  const currenciesMap = useRef(new Map());
  const ggpCodesMap = useRef(new Map());
  const top3Currencies = ["EUR", "USD", "GBP"];
  const getCurrencies = async () => {
    try {
      const res =
        open &&
        (await axios.get(
          `${process.env.REACT_APP_BASE_URL}` +
            END_POINT.FI_TRADES +
            "/get_currencies",
          { headers: { Authorization: token } },
        ));
      currenciesMap.current = new Map(
        res.data.map((obj) => {
          return [obj.name, obj.id];
        }),
      );

      setCurrencies({ items: res.data, isLoading: false });
    } catch (error) {
      console.log("getCurrencies", error);
    }
  };
  const getGppCodesList = async () => {
    try {
      const res =
        open &&
        (await axios.get(
          `${process.env.REACT_APP_BASE_URL}` +
            END_POINT.FI_CLIENTS +
            `/get_gpp_codes_list/${selectedClient.id}`,
          { headers: { Authorization: token } },
        ));

      ggpCodesMap.current = new Map(
        res.data.map((obj) => {
          return [obj.currency, obj.code];
        }),
      );
      setIsCodesListLoading(false);
    } catch (error) {
      console.log("getGppCodesList", error);
    }
  };

  const handleDialogOpening = async () => {
    await getCurrencies();
    await getGppCodesList();
  };

  useEffect(() => {
    open && handleDialogOpening();
  }, [open]);

  const handleChange = async (valueSelected) => {
    valueSelected !== null
      ? setInputValue(valueSelected.value)
      : setInputValue("");
    if (valueSelected === null) {
      setGppCode("");
    }
    ggpCodesMap.current.get(valueSelected.value) !== undefined
      ? setGppCode(ggpCodesMap.current.get(valueSelected.value))
      : setGppCode("");
  };

  const handleFreeTextChange = async (e) => {
    if (e.target.value !== "") {
      setGppCode(e.target.value);
      ggpCodesMap.current.set(inputValue, e.target.value);
    } else {
      setGppCode(e.target.value);
      ggpCodesMap.current.delete(inputValue);
    }
  };

  const submitGppCodes = async () => {
    const arr = Array.from(ggpCodesMap.current);
    const gppCodes = arr.map(([key, value]) => {
      return { currency: key, code: value };
    });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}` +
          END_POINT.FI_CLIENTS +
          `/update_gpp_codes`,
        { id: selectedClient.id, gppCodes },
        { headers: { Authorization: token } },
      );
      if (res.status === 200) {
        handleClose();
        setGppCode("");
        dispatch(actionSnackBar.setSnackBar("success", res.data, 3000));
      }
    } catch (error) {
      console.log("getGppCodesList", error);
      dispatch(actionSnackBar.setSnackBar("error", error.message, 3000));
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
          setGppCode("");
          setCurrencies({ items: [], isLoading: true });
          setIsCodesListLoading(true);
        }}
        classes={{ paper: classes.gppCodeDialogPaper }}
        PaperProps={{
          style: {
            backgroundColor: "#2D2D2D",
          },
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={3}
          style={{
            margin: "25px 0px 0px 25px",
            display: "flex",
            flexWrap: "nowrap",
            maxWidth: "90%",
          }}
        >
          <Grid container item xs={12}>
            <Grid item xs={11}>
              <Typography style={{ fontSize: "1.25rem", color: "#ffffff" }}>
                ADD GPP CODE
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                size="small"
                onClick={() => {
                  handleClose();
                  setGppCode("");
                  setCurrencies({ items: [], isLoading: true });
                  setIsCodesListLoading(true);
                }}
                style={{
                  backgroundColor: "#3a3c3f",
                }}
              >
                {" "}
                <CloseIcon
                  style={{ color: "#bababa", fontSize: "1.3125rem" }}
                />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ fontSize: "1.25rem", color: "#ffffff" }}>
              {selectedClient.firmName} - {selectedClient.bbgMakorCode}
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            item
          >
            <Grid item xs={6}>
              <Autocomplete
                clearOnEscape
                autoHighlight
                selectOnFocus
                className={clsx(classes.autocomplete)}
                // value={currencyValue}
                onChange={(event, newValue) => {
                  handleChange(newValue);
                }}
                // inputValue={inputValue}
                getOptionLabel={(option) => option.value}
                options={
                  open &&
                  !currencies.isLoading &&
                  currencies.items?.map((c) => {
                    return { id: c.id, value: c.name };
                  })
                }
                renderInput={(params) => (
                  <TextField
                    className={clsx(
                      classes.selectOptionBox,
                      "selectAutoComplete",
                    )}
                    {...params}
                    label={"Currency"}
                    variant="outlined"
                    SelectProps={{
                      native: true,
                      maxwidth: "10px",
                      open: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                disabled={inputValue === ""}
                className={classes.freeTextPicker}
                id="outlined-select-currency-native"
                type="text"
                label={"GPP Code"}
                value={gppCode}
                onChange={(e) => handleFreeTextChange(e)}
                SelectProps={{
                  native: true,
                }}
                menuprops={{ classes: { paper: classes.dropdownStyle } }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              >
                {" "}
              </TextField>
            </Grid>
          </Grid>
          {open ? (
            <Grid
              item
              container
              style={{
                minHeight: "235px",
                maxHeight: "235px",
                overflow: "auto",
                maxWidth: "480px",
                overflowY: "hidden",
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <CustomTableContainer component={Paper}>
                  <Table className={classes.tableRow}>
                    <TableHead>
                      <TableRow>
                        <HeaderFontTableCell1>
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography variant="h6" fontWeight="bold">
                              Currency
                            </Typography>
                            <hr
                              style={{
                                border:
                                  "none" /* Remove default horizontal border */,
                                borderLeft:
                                  " 2px solid #828282" /* Set a vertical border on the left side */,
                                height:
                                  "20px" /* Set the height of the vertical line */,
                                margin: 0 /* Remove default margins */,
                                padding: 0,
                                marginLeft: "15px",
                              }}
                            />
                          </Grid>
                        </HeaderFontTableCell1>

                        <HeaderFontTableCell1>
                          <Typography variant="h6" fontWeight="bold">
                            Code
                          </Typography>
                        </HeaderFontTableCell1>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!currencies.isLoading && !isCodesListLoading
                        ? top3Currencies.map((currency) => {
                            console.log(ggpCodesMap.current.get(currency));

                            if (
                              ggpCodesMap.current.get(currency) !== undefined
                            ) {
                              return (
                                <StripedTableRow
                                  // key={key}
                                  // index={key}
                                  className={classes.historyTableRow}
                                >
                                  <WhiteFontTableCell
                                    className={classes.historyTableRow}
                                  >
                                    <Typography variant="h6" fontWeight="bold">
                                      {currency}
                                    </Typography>
                                  </WhiteFontTableCell>
                                  <WhiteFontTableCell
                                    className={classes.historyTableRow}
                                  >
                                    <Typography variant="h6" fontWeight="bold">
                                      {ggpCodesMap.current.get(currency)}
                                    </Typography>
                                  </WhiteFontTableCell>
                                </StripedTableRow>
                              );
                            }
                          })
                        : null}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
              </Grid>
            </Grid>
          ) : null}

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            item
            style={{ marginTop: "40px" }}
          >
            <Grid item xs={10}>
              <Button
                item
                variant="contained"
                className={classes.btn}
                onClick={() => {
                  handleClose();
                  setGppCode("");
                  setCurrencies({ items: [], isLoading: true });
                  setIsCodesListLoading(true);
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                item
                variant="contained"
                className={classes.btn}
                onClick={() => {
                  submitGppCodes();
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default GppCodeDialog;

export const getCurrentCodes = (ggpCodesMap) => {
  const arr = Array.from(ggpCodesMap?.current);
  const gppCodes = arr.map(([key, value]) => {
    return { currency: key, code: value };
  });
  return gppCodes;
};
