import moment from "moment";
import { useState, useEffect } from "react";


export const testDateWithinRange = async (dateValue) => {
  if (dateValue !== "") {
    const parsedDate = moment(dateValue, "DD-MMM-YY").toDate();
    const today = new Date();
    const timeDiff = parsedDate.getTime() - today.getTime();
    const dayDiff = Math.abs(timeDiff / (1000 * 60 * 60 * 24));
    if (dayDiff <= 15) {
      // console.log(
      //   "The date value is within 15 days range from today.",
      //   dayDiff,
      // );
      return dateValue;
    } else {
      // console.log(
      //   "The date value is more than 15 days away from today.",
      //   dayDiff,
      // );
      return "";
    }
  } else {
    return "";
  }
};




export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};