import { Box, Button, Typography, Grid } from "@material-ui/core";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Copy } from "../../../../assets/copy.svg";
import clsx from "clsx";

const OvmlTailModal = ({ data = "", styleRfq = {}, header }) => {
  const textAreaRef = useRef(null);
  const classes = useStyles();
  const [disableButton, setDisableButton] = useState(false);

  const copy_to_clip = async () => {
    setDisableButton(true);
    // let textField = document.createElement("textarea");
    // textField.innerText = data;
    // document.body.appendChild(textField);
    // textField.select();
    // document.execCommand("copy");
    // textField.remove();
    await navigator.clipboard.writeText(data);
  };

  return (
    <Grid
      className={classes.rfqModal}
      style={{ display: "flex", flexDirection: "column", height: "10vh" }}
    >
      <Grid item container className={classes.rfqodalHeader}>
        <Typography className={classes.header}>{header}</Typography>
        <Typography className={classes.icon}>
          <Copy
            onClick={copy_to_clip}
            className={clsx({
              [classes.copybuttonDisable]: disableButton,
            })}
          />
        </Typography>
      </Grid>
      <Grid
        item
        style={{
          color: "white",
          backgroundColor: "#222222",
          display: "flex",
          flex: "5",
        }}
      >
        <Typography
          ref={textAreaRef}
          style={{
            font: "normal normal normal 14px/21px monospace",
            fontSize: "1.16rem",
            margin: "18px auto 0 2%",
            height: "100%",
            opacity: "87%",
            color: "#ff9864",
          }}
        >
          <a
            href={"bbg://screens/" + data.replace(/ /g, "%20")}
            target=""
            style={{
              font: "normal normal normal 14px/21px monospace",
              fontSize: "1.16rem",
              height: "100%",
              opacity: "87%",
              color: "#ff9864",
            }}
          >
            {data}
          </a>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default OvmlTailModal;

export const useStyles = makeStyles(() => ({
  rfqModal: {
    border: "1px solid #686B76",
    borderRadius: "8px 8px 0px 0px",
  },
  rfqodalHeader: {
    color: "#FFFFFF",
    borderBottom: "1px solid #686B76",
    borderRadius: "8px 8px 0px 0px",
    height: "43px",
    font: "normal normal normal 20px/24px Inter",
    justifyContent: "space-between",
  },
  header: {
    marginTop: "15px",
    marginLeft: "15px",
  },
  icon: {
    marginTop: "15px",
    marginRight: "15px",
  },
  input: {
    marginLeft: "10px",
    marginRight: "10px",
    border: "1px solid #686B76",
    borderRadius: "8px",
    opacity: 1,
    width: "89px !important",
    height: "40px",
    color: "white",
  },
  bid: {
    marginTop: "2%",
    color: "#00D865",
  },
  offer: {
    marginTop: "2%",
    color: " #FF3400",
  },
  text: {
    marginTop: "revert",
    textAlign: "center",
    color: "#686B76",
  },
  copybuttonDisable: {
    filter: "invert(0.5) sepia(1) hue-rotate(200deg)",
  },
  svgBase: {
    curser: "pointer",
    "& .MuiTypography-root": {
      curser: "pointer",
    },
    "& .MuiTypography-root:hover": {
      curser: "pointer",
    },
  },
}));
