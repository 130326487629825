import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as fiActions from "../../../store/fi/fiSlice";
import * as RfqPostTradeSlice from "../../../store/rfq/rfqPostTradeSlice";
import { useStyles } from "../../../Styles/fiStyles";
import clsx from "clsx";

export default function NumberInput(values) {
  const dispatch = useDispatch();
  const tradeToEdit = useSelector(
    (state) => state.fiSlice?.selectedTradeToEdit
  );
  const newTradeStats = useSelector((state) => state.fiSlice.newTradeStats);
  const { postTradeSplitArrValues } = useSelector(
    (state) => state.rfqPostTradeSlice
  );
  const [color, setColor] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const statusItem = useSelector((state) => state.rfqSlice.statusItem);

  // ! not relevent for notional
  useEffect(() => {
    if (values.values.key === "netCash") {
      if (
        newTradeStats.quantity !== "" &&
        newTradeStats.price !== "" &&
        newTradeStats.accruedInterest !== ""
      ) {
        setSelectedValue(
          Math.abs((newTradeStats.quantity * newTradeStats.price) / 100) +
            Math.abs(newTradeStats.accruedInterest)
        );
        const obj = {
          value:
            Math.abs((newTradeStats.quantity * newTradeStats.price) / 100) +
            Math.abs(newTradeStats.accruedInterest),
          key: values.values.key,
        };
        dispatch(fiActions.editNewTradeForm(obj));
      }
    }
  }, [newTradeStats]);

  useEffect(() => {
    if (
      tradeToEdit &&
      tradeToEdit[`${values.values.key}`] &&
      tradeToEdit !== undefined &&
      values.values.key !== "accruedInterest"
    ) {
      setSelectedValue(tradeToEdit[`${values.values.key}`]);
      const obj = {
        value: tradeToEdit[`${values.values.key}`],
        key: values.values.key,
      };
      dispatch(fiActions.editNewTradeForm(obj));
    } else if (
      tradeToEdit &&
      tradeToEdit !== undefined &&
      values.values.key === "accruedInterest"
    ) {
      setSelectedValue(
        tradeToEdit.accrued_interest !== "" ? tradeToEdit.accrued_interest : "0"
      );
      const obj = {
        value:
          tradeToEdit.accrued_interest !== ""
            ? tradeToEdit.accrued_interest
            : "0",
        key: values.values.key,
      };
      dispatch(fiActions.editNewTradeForm(obj));
    } else if (
      postTradeSplitArrValues.length > 0 &&
      values.values.key === "postTradeSplitOption"
    ) {
      setSelectedValue(
        postTradeSplitArrValues[`${values.values.id}`]?.postTradeSplitOption
      );
    } else if (
      postTradeSplitArrValues.length > 0 &&
      (values.values.key.includes("Delta") || values.values.key.includes("Leg"))
    ) {
      if (postTradeSplitArrValues[`${values.values.id}`] !== undefined) {
        setSelectedValue(
          postTradeSplitArrValues[`${values.values.id}`][values.values.key]
        );
      }
    } else if (
      postTradeSplitArrValues.length > 0 &&
      (values.values.key.includes("near") || values.values.key.includes("far"))
    ) {
      if (postTradeSplitArrValues[`${values.values.id}`] !== undefined) {
        setSelectedValue(
          postTradeSplitArrValues[`${values.values.id}`][values.values.key]
        );
      }
    }
  }, []);

  const classes = useStyles();

  const handleChange = (event) => {
    if (
      values.values.key !== "splitOption" &&
      values.values.key !== "postTradeSplitOption" &&
      !values.values.key.includes("NotionalSplitOption") &&
      !values.values.key.includes("near") &&
      !values.values.key.includes("far")
    ) {
      let obj;
      if (
        values.values.key === "accruedInterest" &&
        event.target.value === ""
      ) {
        obj = {
          value: "0",
          key: values.values.key,
        };
      } else {
        obj = {
          value: event.target.value,
          key: values.values.key,
        };
      }
      setColor(!color);
      setSelectedValue(event.target.value);

      dispatch(fiActions.editNewTradeForm(obj));
    } else if (values.values.key === "splitOption") {
      const obj = {
        value: event.target.value,
        key: values.values.key,
        id: values.values.id,
      };
      setSelectedValue(event.target.value);
      dispatch(fiActions.setSplitArrValues(obj));
    } else if (values.values.key === "postTradeSplitOption") {
      let obj = {
        value: event.target.value,
        key: values.values.key,
        id: values.values.id,
      };
      setSelectedValue(event.target.value);
      dispatch(RfqPostTradeSlice.setPostTradesSplitArrValues(obj));
    } else if (values.values.key.includes("NotionalSplitOption")) {
      let obj = {
        value: event.target.value,
        key: values.values.key,
        id: values.values.id,
      };
      setSelectedValue(event.target.value);
      dispatch(RfqPostTradeSlice.setPostTradesSplitArrValues(obj));
    } else if (
      values.values.key.includes("Leg") ||
      values.values.key.includes("Delta")
    ) {
      let obj = {
        value: event.target.value,
        key: values.values.key,
        id: values.values.id,
      };
      setSelectedValue(event.target.value);
      dispatch(RfqPostTradeSlice.setPostTradesSplitArrValues(obj));
    } else if (
      values.values.key.includes("near") ||
      values.values.key.includes("far")
    ) {
      let obj = {
        value: event.target.value,
        key: values.values.key,
        id: values.values.id,
      };
      setSelectedValue(event.target.value);
      dispatch(RfqPostTradeSlice.setPostTradesSplitArrValues(obj));
    }
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <TextField
          disabled={
            (statusItem === "Confirmed" &&
              values.values.key === "postTradeSplitOption") ||
            (statusItem === "Booked" &&
              values.values.key === "postTradeSplitOption") ||
            (statusItem === "Confirmed & Booked" &&
              values.values.key === "postTradeSplitOption")
          }
          className={clsx(classes.numberPicker, {
            [classes.numberPickerPostTrade]:
              values.values.key === "postTradeSplitOption",
          })}
          id="outlined-select-currency-native"
          type="number"
          label={values.values.label}
          value={selectedValue}
          onChange={handleChange}
          SelectProps={{
            native: true,
          }}
          menuprops={{ classes: { paper: classes.dropdownStyle } }}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          InputProps={{ inputProps: { min: 0 } }}
        >
          {" "}
        </TextField>
      </div>
    </form>
  );
}
