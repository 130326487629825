import { makeStyles, withStyles, TextField } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  launchStatusBtnActive: {
    marginTop: "11px",
    color: "#FF681C",
    border: "1px solid #FF681C",
    height: "28px",
    width: "125px !important",
    font: "normal normal normal 14px/21px monospace",
    borderRadius: "4px 4px 4px 4px",
  },
  launchStatusBtn: {
    marginTop: "11px",
    color: "#686B76",
    border: "1px solid #686B76",
    height: "28px",
    width: "125px !important",
    font: "normal normal normal 14px/21px monospace",
    borderRadius: "4px 4px 4px 4px",
  },
  iconInvisible: {
    display: "none",
  },
  rfqStatusModalMenuItem: {
    fontSize: "18px",
    // fontWeight: "600",
    font: "normal normal normal 14px/21px monospace",
  },
  rfqStatusModalSelect: {
    // paddingBottom: "2px",
    // paddingLeft: "18px",
    marginTop: "12px",
    // border: "1px solid #FF681C",
    // height: "25px",
    // color: "#686B76",
    borderRadius: "4px 4px 4px 4px",

    "& .MuiSelect-icon": {
      fill: "#FFFFFF",
    },

    "& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl":
      {
        color: "#FFFFFF",
        paddingLeft: "15px",
        paddingBottom: "3px",
        height: "25px",
        width: "125px !important",
        font: "normal normal normal 14px/21px monospace",
      },
  },
  rfqStatusModalSelectRed: {
    border: "1px solid #FF4E32",
    // "& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl":
    //   {
    //     color: "#FF4E32",
    //   },
    // "& .MuiSelect-icon": {
    //   fill: "#FF4E32",
    // },
  },
  rfqStatusModalSelectOrange: {
    border: "1px solid #2692ff",
    // "& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl":
    //   {
    //     color: "#ffbf00",
    //   },
    // "& .MuiSelect-icon": {
    //   fill: "#ffbf00",
    // },
  },
  rfqStatusModalSelectGreen: {
    border: "1px solid rgb(0, 216, 101)",
    // "& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl":
    //   {
    //     color: "rgb(0, 216, 101)",
    //   },
    // "& .MuiSelect-icon": {
    //   fill: "rgb(0, 216, 101)",
    // },
  },
  rfqStatusModalSelectGray: {
    border: "1px solid #A8A8A8",
    // "& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl":
    //   {
    //     color: "#A8A8A8",
    //   },
    // "& .MuiSelect-icon": {
    //   fill: "#A8A8A8",
    // },
  },
  rfqStatusModalBox: {
    border: "1px solid #686B76",
    borderRadius: "8px 8px 0px 0px",
  },
  rfqStatusModalBottom: {
    backgroundColor: "#222222",
    flex: "5",
    // minHeight: column ? "fit-content" : "370px",
  },
  rfqStatusModalHeader: {
    color: "#FFFFFF",
    height: "33px",
    justifyContent: "space-between",
    font: "normal normal normal 13px/24px Inter",
    paddingTop: "15px",
    paddingLeft: "18px",
  },

  rfqModal: {
    border: "1px solid #686B76",
    borderRadius: "8px 8px 0px 0px",
  },
  rfqModalHeader: {
    color: "#FFFFFF",
    borderBottom: "1px solid #686B76",
    borderRadius: "8px 8px 0px 0px",
    height: "43px",
    font: " normal normal normal 20px/24px Inter",
    justifyContent: "space-between",
  },
  header: {
    marginTop: "15px",
    marginLeft: "15px",
  },
  checkBoxFormControler: {
    "& .MuiFormControlLabel-label": {
      display: "block",
    },
  },
  checkBox: {
    color: "#868d85",
    "&.MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#13c552",
    },
  },
  arrowIcon: {
    "& .MuiSvgIcon-root": {
      color: "#1C67FF",
      //   backgroundColor: "#FFF",
    },
  },
  autoComp: {
    "& .MuiInputBase-input": {
      color: "#858da2",
      height: "10px",
    },
    "& .css-i4bv87-MuiSvgIcon-root": {
      color: "#1C67FF !important",
      height: "25px !important",
      width: "22px !important",
    },
  },
  postTradeSplitBtn: {
    borderRadius: 4,
    backgroundColor: "#222324",
    height: 40,
    width: 40,
    border: "1px solid #686B76",
    "&.MuiIconButton-root:hover": {
      backgroundColor: "#2D2D2D",
      border: "1px solid  white",
    },
  },
  selectedPostTradeSplitBtn: {
    borderRadius: 4,
    backgroundColor: "#222324",
    height: 40,
    width: 40,
    border: "1px solid #2692FF",
    "&.MuiIconButton-root:hover": {
      // backgroundColor: "#0c2e1a",
      border: "1px solid white",
    },
  },
  dialogPaperRfq: {
    minWidth: "850px",
    height: "667px",
  },
  dividerPostTrades: {
    backgroundColor: "#868DA2",
    width: "675px",
    position: "absolute",
    top: "150px",
    left: "55px",
  },
  removeSplitBtn: {
    borderRadius: 4,
    textTransform: "none",
    fontSize: 14,
    width: 150,
    height: 40,
    position: "absolute",
    left: "-120px",
    bottom: "0px",
  },
  postTradeBtn: {
    borderRadius: 4,
    width: 119,
    backgroundColor: "#2692FF",
    textTransform: "none",
    height: 40,
    fontSize: 14,
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#2692FF",
      color: "#000000",
    },
  },
  btnGroup: {
    border: "1px solid #00999E",
    height: "27px",
    "& .MuiButtonGroup-root": {
      textTransform: "none",
    },
    "& .Mui-disabled": {
      color: "#ffffff",
    }
  },
  btnInside: {
    "&:hover": {
      background: "#13b5ba",
    },
    textTransform: "none",
    color: "#ffffff",
    width: "110px",
    height: "inherit",
  },
  chosenBtnInside: {
    backgroundColor: "#00999E",
    height: "inherit",
  },
}));

export const StyledTextField = withStyles({
  root: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "4px",
      "& fieldset": {
        borderColor: "#686B76",
      },
      "&:hover fieldset": {
        border: "1px solid #EDEFF3",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #EDEFF3",
      },
      "& .MuiSelect-select:focus": {
        backgroundColor: "transparent",
        borderRadius: "8px",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px",
      "&::placeholder": {
        color: "#868DA2",
        opacity: 1,
      },
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(12px, 12px) scale(1)",
    },
    "& .MuiFormLabel-root": {
      color: "#686B76",
    },
    "& .MuiPaper-root .MuiMenu-paper .MuiPopover-paper .MuiPaper-elevation8 .MuiPaper-rounded":
      {
        backgroundColor: "red",
      },
    "& .MuiInputBase-input": {
      color: "#858da2",
    },
  },
})(TextField);
