export const valueFormatterSelector = (params) => {
  if (params?.colDef?.field === "expiryYear") {
    return `${Number(params?.data?.expiryYear) % 100}`;
  } else if (params?.colDef?.field === "legNum") {
    if (params?.data?.legNum !== undefined && params?.data?.legNum !== "") {
      return `Leg ${params?.data?.legNum}`;
    }
  } else if (params?.colDef?.field === "callPut") {
    return `${
      params?.data?.callPut === "Call"
        ? "C"
        : params?.data?.callPut === "Put"
        ? "P"
        : ""
    }`;
  } else if (params?.colDef?.field === "GAMMA_MID_RT") {
    const colId = params?.column?.colId;
    if (
      params?.data !== undefined &&
      params?.data[colId] !== undefined &&
      params?.data[colId] !== ""
    ) {
      return `${(params?.data[colId] * 100).toFixed(2)}%`;
    }
  } else if (params?.colDef?.field === "DELTA_MID_RT") {
    const colId = params?.column?.colId;
    if (
      params?.data !== undefined &&
      params?.data[colId] !== undefined &&
      params?.data[colId] !== ""
    ) {
      return `${Number(params?.data[colId])?.toFixed(2)}%`;
    }
  } else if (params?.colDef?.field === "VEGA_MID_RT") {
    const colId = params?.column?.colId;
    if (
      params?.data !== undefined &&
      params?.data[colId] !== undefined &&
      params?.data[colId] !== ""
    ) {
      return `${params?.data[colId]?.toFixed(3)}`;
    }
  } else if (
    params?.colDef?.field === "bidRef" ||
    params?.colDef?.field === "askRef" ||
    params?.colDef?.field === "orderBid" ||
    params?.colDef?.field === "orderAsk" ||
    params?.colDef?.field === "midRef"
  ) {
    if (params?.data !== undefined && params?.value !== undefined) {
      return `${params?.value.toFixed(2)}`;
    }
  }
};
