import { makeStyles } from "@material-ui/core";
import { store } from "../index";
import * as emailActions from "../store/email_confo/emailConfoSlice";
import * as rfqPostTradeSlice from "../store/rfq/rfqPostTradeSlice";
import { setEmailAddressesAsync } from "../store/rfq/rfqPostTradeSlice";
import * as actionSnackBar from "../store/snackbar/action";
import { END_POINT } from "../utils/index";

import axios from "axios";

const checkIfConfoSent = async (tabIndex) => {
  store.dispatch(emailActions.setRfqEmail(null));
  const token = sessionStorage.getItem("token");
  const { rfqObject, currentClient } = store.getState().rfqPostTradeSlice;

  try {
    let sentedEmail = await axios.get(
      `${process.env.REACT_APP_BASE_URL}` +
        END_POINT.POST_TRADE +
        `/confirmed/${rfqObject?.rfq_id}/${currentClient[tabIndex]}`,
      { headers: { Authorization: token } },
    );
    if (sentedEmail.status === 200) {
      if (sentedEmail?.data[0] !== null && sentedEmail?.data[0] !== undefined) {
        store.dispatch(emailActions.setEmailSent(true));
        store.dispatch(
          rfqPostTradeSlice.setAllValuesIfConfosExist(sentedEmail?.data[0]),
        );
        store.dispatch(
          emailActions.setRfqEmail(sentedEmail?.data[0]?.email_body),
        );
        return true;
      } else {
        store.dispatch(emailActions.setEmailSent(false));
        store.dispatch(rfqPostTradeSlice.setAllValuesIfConfosExist());
        store.dispatch(emailActions.setRfqEmail(null));
        return false;
      }
    }
  } catch (error) {
    store.dispatch(actionSnackBar.setSnackBar("error", "error.message", 3000));
  }
};

const createConfo = async (tabIndex) => {
  let alphabetRegex = new RegExp(/(?!.*\()(?=.*\))[A-Z]/g);
  let {
    rfqObject,
    makorHSBCClient,
    allMakorHSBCClientAndMarketMakers,
    makorMarketMakers,
    makorClients,
    oscarGrussMarketMakers,
    oscarGrussClients,
    currentMarketMaker,
    currentClient,
    currentPb,
    marketMaker,
    trader,
    traderNote,
    dfMids,
    confoInitial,
    marketMakerNeedsConfo,
    postTradeSplitArrValues,
    singleConfo,
    splitManner,
    sd,
    pba,
  } = store.getState().rfqPostTradeSlice;

  let shortNameOfClient;
  let shortNameOfMarketMaker;
  let client;
  let totalNotional = [];

  const DEAL_TYPE = rfqObject.rfq_table_data?.find(
    (field) => field[0] === "DEAL_TYPE",
  )[1][0];

  if (alphabetRegex.test(currentClient[tabIndex])) {
    client = currentClient[tabIndex]
      ?.replace(alphabetRegex, "")
      .replace(" ()", "");
  } else if (currentClient[tabIndex]?.includes("(MM)")) {
    client = currentClient[tabIndex].replace(" (MM)", "");
  } else {
    client = currentClient[tabIndex];
  }

  if (currentMarketMaker === "OG-RBS") {
    shortNameOfClient = "";
    if (!currentClient[tabIndex]?.includes("(MM)")) {
      shortNameOfClient = oscarGrussClients.find(
        (key) => key.ogName === client,
      );
    } else {
      shortNameOfClient = oscarGrussMarketMakers.find(
        (key) => key.ogName === client,
      );
    }
    shortNameOfMarketMaker = oscarGrussMarketMakers.find(
      (key) => key.ogName === marketMaker,
    );
  }
  console.log({
    currentMarketMaker,
    oscarGrussClients,
    makorMarketMakers,
    client,
    ogFind: oscarGrussClients.find((key) => key.ogName === client),
    mkFind: makorMarketMakers.find((key) => key.MmName === client),
  });
  if (currentMarketMaker === "MAKOR-RBS") {
    shortNameOfClient = "";
    if (sd === true) {
      if (!currentClient[tabIndex]?.includes("(MM)")) {
        shortNameOfClient = oscarGrussClients.find(
          (key) => key.ogName === client,
        );
      } else {
        if (
          makorMarketMakers.find((key) => key.MmName === client) !== undefined
        ) {
          shortNameOfClient = makorMarketMakers.find(
            (key) => key.MmName === client,
          );
        } else {
          shortNameOfClient = oscarGrussClients.find(
            (key) => key.ogName === client,
          );
        }
        if (
          makorMarketMakers.find((key) => key.MmName === marketMaker) !==
          undefined
        ) {
          shortNameOfMarketMaker = makorMarketMakers.find(
            (key) => key.MmName === marketMaker,
          );
        } else {
          shortNameOfMarketMaker = oscarGrussClients.find(
            (key) => key.ogName === marketMaker,
          );
        }
      }
    } else {
      if (!currentClient[tabIndex]?.includes("(MM)")) {
        shortNameOfClient = makorClients.find((key) => key.MmName === client);
      } else {
        shortNameOfClient = makorMarketMakers.find(
          (key) => key.MmName === client,
        );
      }

      shortNameOfMarketMaker = makorMarketMakers.find(
        (key) => key.MmName === marketMaker,
      );
    }
  }

  if (currentMarketMaker === "MAKOR-HSBC") {
    shortNameOfClient = "";
    if (!currentClient[tabIndex]?.includes("(MM)")) {
      shortNameOfClient = allMakorHSBCClientAndMarketMakers.find(
        (key) => key.MmName === client,
      );
    } else {
      shortNameOfClient = allMakorHSBCClientAndMarketMakers.find(
        (key) => key.MmName === client,
      );
    }
    shortNameOfMarketMaker = allMakorHSBCClientAndMarketMakers.find(
      (key) => key.MmName === marketMaker,
    );
  }

  let allocation_list;
  let allocationListCopy;
  if (splitManner === "percentage") {
    allocation_list = postTradeSplitArrValues.map((key) => {
      return {
        client_selection: key.postTradeClientSplitId,
        pm_selection: key.postTradePmSplitId,
        percentage_allocation: +key.postTradeSplitOption / 100,
        share_percentage: key.postTradeSplitOption,
      };
    });
  } else if (splitManner === "notional") {
    if (DEAL_TYPE !== "SWAP") {
      allocationListCopy = postTradeSplitArrValues;
      allocation_list = postTradeSplitArrValues.map((split) => {
        let obj = {
          id: split.id,
          client_selection: split.postTradeClientSplitId,
          pm_selection: split.postTradePmSplitId,
          notional_allocation: [{ 0: "OPTION" }, { 0: "HEDGE" }],
        };
        for (const [key, value] of Object.entries(split)) {
          if (key.includes("Leg")) {
            let legNum = key.match(/\d+/g);
            obj.notional_allocation[0] = {
              ...obj.notional_allocation[0],
              [legNum]: value,
            };
          }
          if (key.includes("Delta")) {
            let deltaNum = key.match(/\d+/g);
            obj.notional_allocation[1] = {
              ...obj.notional_allocation[1],
              [deltaNum]: value,
            };
          }
        }
        return obj;
      });
    } else {
      allocationListCopy = postTradeSplitArrValues;
      allocation_list = postTradeSplitArrValues.map((split) => {
        let obj = {
          id: split.id,
          client_selection: split.postTradeClientSplitId,
          pm_selection: split.postTradePmSplitId,
          notional_allocation: [{ 0: "SWAP", 1: 0, 2: 0 }],
        };
        for (const [key, value] of Object.entries(split)) {
          if (key.includes("near")) {
            obj.notional_allocation["1"] = value;
          }
          if (key.includes("far")) {
            obj.notional_allocation["2"] = value;
          }
        }
        return obj;
      });
    }
  }

  let currentPm;
  if (currentClient[tabIndex].includes("(MM") === false) {
    if (allocation_list.length === 0) {
      if (currentMarketMaker === "OG-RBS" || sd === true) {
        currentPm = oscarGrussClients.find(
          (client) => client.ogName === currentClient[tabIndex],
        );
      } else if (currentMarketMaker === "MAKOR-RBS") {
        currentPm = makorClients.find(
          (client) => client.MmName === currentClient[tabIndex],
        );
      } else if (currentMarketMaker === "MAKOR-HSBC") {
        currentPm = allMakorHSBCClientAndMarketMakers.find(
          (client) => client.MmName === currentClient[tabIndex],
        );
      }
    } else {
      if (currentMarketMaker === "OG-RBS" || sd === true) {
        currentPm = oscarGrussClients.find(
          (mm) => mm?.ogName === allocation_list[tabIndex].client_selection,
        );
      } else if (currentMarketMaker === "MAKOR-RBS") {
        currentPm = makorClients.find(
          (mm) => mm?.MmName === allocation_list[tabIndex].client_selection,
        );
      } else if (currentMarketMaker === "MAKOR-HSBC") {
        currentPm = allMakorHSBCClientAndMarketMakers.find(
          (mm) => mm?.MmName === allocation_list[tabIndex].client_selection,
        );
      }
    }
  } else {
    if (currentMarketMaker === "OG-RBS") {
      currentPm = oscarGrussMarketMakers.find(
        (mm) => mm?.ogName === currentClient[tabIndex].replace(" (MM)", ""),
      );
    } else if (currentMarketMaker === "MAKOR-RBS") {
      currentPm = makorMarketMakers.find(
        (mm) => mm?.MmName === currentClient[tabIndex].replace(" (MM)", ""),
      );
    } else if (currentMarketMaker === "MAKOR-HSBC") {
      currentPm = allMakorHSBCClientAndMarketMakers.find(
        (mm) => mm?.MmName === currentClient[tabIndex].replace(" (MM)", ""),
      );
    }
  }

  const setEmailAddresses = (tabIndex) => {
    let emailObj = { to: [], cc: [] };
    store.dispatch(setEmailAddressesAsync());
    let ccToSend = [];
    if (
      currentPm?.default_email !== undefined &&
      currentPm?.default_email !== null
    ) {
      ccToSend = [...ccToSend, currentPm.default_email];
    }
    ccToSend = [
      ...ccToSend,
      "makorfx@makorsecurities.com",
      "fxmakor@makorsecurities.com",
    ];

    if (currentMarketMaker.includes("RBS")) {
      ccToSend = [
        ...ccToSend,
        "fxpb1@natwestmarkets.com",
        "fxpb2@natwestmarkets.com",
      ];
    }
    if (
      currentMarketMaker.includes("RBS") &&
      currentPm?.agreement === "Carveout"
    ) {
      ccToSend = [...ccToSend, "pbnoe@natwestmarkets.com", "gbmpbnoe@rbs.com"];
    }
    if (
      currentMarketMaker === "OG-RBS" ||
      (sd === true && !marketMakerNeedsConfo)
    ) {
      ccToSend = [
        ...ccToSend,
        "ogfx@oscargruss.com",
        "ogfxconfirms@makorsecurities.com",
      ];
    }
    if (currentMarketMaker === "MAKOR-RBS") {
      ccToSend = [...ccToSend, "makorfxconfirms@makorsecurities.com"];
    }
    if (currentMarketMaker === "MAKOR-HSBC") {
      ccToSend = [
        ...ccToSend,
        "fx.pb.cst@hsbcib.com",
        "makorfxconfirms@makorsecurities.com",
        "makorfx@makorsecurities.com",
      ];
    }
    emailObj.cc = ccToSend;

    if (currentPb !== "" || currentPb !== null) {
      let findPb;
      if (currentClient[tabIndex].includes("(MM)")) {
        if (currentMarketMaker === "OG-RBS") {
          findPb = oscarGrussMarketMakers.find((mm) => mm?.ogPm === trader);
          emailObj.to = findPb?.email_addresses;
        } else if (currentMarketMaker === "MAKOR-RBS") {
          findPb = makorMarketMakers.find((mm) => mm?.MmPm === trader);
          emailObj.to = findPb?.email_addresses;
        } else if (currentMarketMaker === "MAKOR-HSBC") {
          findPb = allMakorHSBCClientAndMarketMakers.find(
            (mm) => mm?.MmPm === trader,
          );
          emailObj.to = findPb?.email_addresses;
        }
      } else {
        if (postTradeSplitArrValues.length > 0 && !singleConfo) {
          if (currentMarketMaker === "OG-RBS") {
            findPb = oscarGrussClients.find(
              (client) =>
                client?.ogPm ===
                postTradeSplitArrValues[tabIndex].postTradePmSplitId,
            );
            emailObj.to = findPb?.email_addresses;
          }
          if (currentMarketMaker === "MAKOR-RBS") {
            if (sd === true) {
              findPb = oscarGrussClients.find(
                (client) =>
                  client?.ogPm ===
                  postTradeSplitArrValues[tabIndex].postTradePmSplitId,
              );
              emailObj.to = findPb?.email_addresses;
            } else {
              findPb = makorClients.find(
                (client) =>
                  client?.MmPm ===
                  postTradeSplitArrValues[tabIndex].postTradePmSplitId,
              );
              emailObj.to = findPb?.email_addresses;
            }
          }
          if (currentMarketMaker === "MAKOR-HSBC") {
            findPb = allMakorHSBCClientAndMarketMakers.find(
              (mm) => mm?.MmPm === currentPb,
            );
            emailObj.to = findPb?.email_addresses;
          }
        } else {
          if (currentMarketMaker === "OG-RBS" || sd === true) {
            findPb = oscarGrussClients.find(
              (client) => client?.ogPm === currentPb,
            );
            emailObj.to = findPb?.email_addresses;
          }
          if (currentMarketMaker === "MAKOR-RBS") {
            if (sd === true) {
              findPb = oscarGrussClients.find(
                (client) => client?.ogPm === currentPb,
              );
              emailObj.to = findPb?.email_addresses;
            } else {
              findPb = makorClients.find(
                (client) => client?.MmPm === currentPb,
              );
              emailObj.to = findPb?.email_addresses;
            }
          }
          if (currentMarketMaker === "MAKOR-HSBC") {
            findPb = allMakorHSBCClientAndMarketMakers.find(
              (mm) => mm?.MmPm === currentPb,
            );
            emailObj.to = findPb?.email_addresses;
          }
        }
      }
    } else {
      emailObj.to = [];
    }
    store.dispatch(setEmailAddressesAsync(emailObj));
  };

  setEmailAddresses(tabIndex);

  let confo_details = {};
  const ogFind = oscarGrussClients.find((key) => key.ogName === client);
  const mkFind = makorMarketMakers.find((key) => key.MmName === client);

  if (currentClient[tabIndex]?.includes("(MM)") && marketMakerNeedsConfo) {
    confo_details = {
      client_selection:
        currentMarketMaker === "MAKOR-RBS" && sd === true
          ? shortNameOfMarketMaker?.ogShortName === undefined
            ? shortNameOfMarketMaker?.MmShortName
            : shortNameOfMarketMaker?.ogShortName
          : currentMarketMaker === "MAKOR-RBS" ||
            currentMarketMaker === "MAKOR-HSBC"
          ? shortNameOfClient?.MmShortName
          : shortNameOfClient?.ogShortName,
      market_maker_selection:
        currentMarketMaker === "MAKOR-RBS" && sd === true
          ? shortNameOfMarketMaker?.ogShortName === undefined
            ? shortNameOfMarketMaker?.MmShortName
            : shortNameOfMarketMaker?.ogShortName
          : currentMarketMaker === "MAKOR-RBS" ||
            currentMarketMaker === "MAKOR-HSBC"
          ? shortNameOfMarketMaker?.MmShortName
          : shortNameOfMarketMaker?.ogShortName,
      pm_selection: currentPb !== "No PMs found" ? currentPb : "",
      trader_selection: trader !== "No Traders found" ? trader : "",
      trader_note: traderNote,
      df_mids: dfMids,
      market_maker_needs_confo: true,
      confo_initial: confoInitial,
      pb: currentMarketMaker,
      split_allocation: null,
      pba: pba,
      fullName: shortNameOfMarketMaker.ogName,
      tabFlag: ogFind ? "og" : mkFind ? "makor" : null,
    };
  } else {
    if (allocation_list.length > 0) {
      confo_details = {
        client_selection:
          currentMarketMaker === "MAKOR-RBS" && sd === true
            ? shortNameOfClient?.ogShortName
            : currentMarketMaker === "MAKOR-RBS" ||
              currentMarketMaker === "MAKOR-HSBC"
            ? shortNameOfClient?.MmShortName
            : shortNameOfClient?.ogShortName,
        market_maker_selection:
          currentMarketMaker === "MAKOR-RBS" ||
          currentMarketMaker === "MAKOR-HSBC"
            ? shortNameOfMarketMaker?.MmShortName
            : shortNameOfMarketMaker?.ogShortName,
        pm_selection:
          allocation_list[tabIndex]?.pm_selection !== "no option"
            ? allocation_list[tabIndex]?.pm_selection
            : "",
        trader_selection: trader !== "No Traders found" ? trader : "",
        trader_note: traderNote,
        df_mids: dfMids,
        market_maker_needs_confo: false,
        confo_initial: confoInitial,
        pb: currentMarketMaker,
        split_allocation: allocation_list,
        pba: shortNameOfClient?.pba,
        tabFlag: ogFind ? "og" : mkFind ? "makor" : null,
      };
    } else {
      confo_details = {
        client_selection:
          currentMarketMaker === "MAKOR-RBS" && sd === true
            ? shortNameOfClient?.ogShortName
            : currentMarketMaker === "MAKOR-RBS" ||
              currentMarketMaker === "MAKOR-HSBC"
            ? shortNameOfClient?.MmShortName
            : shortNameOfClient?.ogShortName,
        market_maker_selection:
          currentMarketMaker === "MAKOR-RBS" && sd === true
            ? shortNameOfMarketMaker?.ogShortName
            : currentMarketMaker === "MAKOR-RBS" ||
              currentMarketMaker === "MAKOR-HSBC"
            ? shortNameOfMarketMaker?.MmShortName
            : shortNameOfMarketMaker?.ogShortName,
        pm_selection: currentPb !== "No PMs found" ? currentPb : "",
        trader_selection: trader !== "No Traders found" ? trader : "",
        trader_note: traderNote,
        df_mids: dfMids,
        market_maker_needs_confo: false,
        confo_initial: confoInitial,
        pb: currentMarketMaker,
        split_allocation: null,
        pba: pba,
        tabFlag: ogFind ? "og" : mkFind ? "makor" : null,
      };
    }
  }
  if (currentMarketMaker === "MAKOR-RBS") {
    if (sd === true) {
      confo_details = { ...confo_details, SD: sd };
    }
  }

  try {
    let response;
    let emailGenerateData;

    if (allocation_list.length > 0) {
      const rfqObjectKeys = Object.keys(rfqObject.rfq_table_data[0]);
      let rfq_table_dataCopy = JSON.parse(
        JSON.stringify(rfqObject.rfq_table_data),
      );
      let rfqData = [];
      let rfq_table_data;
      for (let key of rfqObjectKeys) {
        rfq_table_data = rfq_table_dataCopy.reduce((prev, a, index) => {
          if (Array.isArray(a[key])) {
            return { ...prev, [a[0]]: a[key][0] };
          } else {
            return { ...prev, [a[0]]: a[key] };
          }
        }, {});
        rfq_table_data = { ...rfq_table_data };
        rfqData.push(rfq_table_data);
      }
      // ! opts_data manipulation if splited
      if (splitManner === "percentage") {
        rfqData.forEach((rfq, idx) => {
          if (idx !== 0) {
            rfq.NOTIONAL = (
              ((+rfq.NOTIONAL *
                allocation_list[tabIndex]?.percentage_allocation) /
                100) *
              100
            ).toString();
            rfq.CLIENT_PREMIUM = (
              ((Number(rfq.CLIENT_PREMIUM.replace(/,/g, "")) *
                allocation_list[tabIndex]?.percentage_allocation) /
                100) *
              100
            ).toString();
          }
        });
      } else if (
        splitManner === "notional" &&
        !currentClient[tabIndex].includes("(MM)")
      ) {
        const allocationDetails = allocation_list
          .map((obj) =>
            obj.hasOwnProperty("notional_allocation")
              ? obj.notional_allocation
              : null,
          )
          .filter((obj) => obj !== null);
        if (DEAL_TYPE !== "SWAP") {
          let notionals = allocationDetails.map((arr) => {
            return arr[0];
          })[tabIndex];
          delete notionals[0];
          let deltas = allocationDetails.map((arr) => {
            return arr[1];
          })[tabIndex];
          delete deltas[0];
          const HEDGE_MATCH_TYPE = rfqObject?.rfq_table_data.find(
            (field) => field[0] === "HEDGE_MATCH_TYPE",
          )[1][0];
          // ! leg NOTIONAL and DELTA arrangement
          rfqData.forEach((rfq, idx) => {
            if (idx !== 0) {
              totalNotional.push(+rfq.NOTIONAL * 1000000);
              rfq.NOTIONAL = notionals[idx];
              if (HEDGE_MATCH_TYPE === "MATCHED") {
                rfq.HEDGE_DELTA = deltas[idx];
              } else if (HEDGE_MATCH_TYPE === "UNMATCHED") {
                rfq.CLIENT_HEDGE_DELTA = deltas[idx];
              }
            }
          });
        } else {
          let near = allocationDetails.map((arr) => {
            return arr[1];
          })[tabIndex];
          let far = allocationDetails.map((arr) => {
            return arr[2];
          })[tabIndex];
          rfqData.forEach((rfq, idx) => {
            if (idx !== 0) {
              rfq.NEAR_NOTIONAL = near;
              rfq.FAR_NOTIONAL = far;
            }
          });
        }
      }
      let objToSend = [];
      let colObj = {};
      let allKeys = Object.keys(rfqData[0]);
      allKeys.forEach((col) => {
        colObj = {};
        rfqObjectKeys.forEach((key) => {
          if (key === "0") {
            colObj = { [key]: col };
          } else {
            if (splitManner === "notional" && colObj[0] === "CLIENT_PREMIUM") {
              const legTotalNotional = totalNotional[Number(key) - 1] / 1000000;
              const clientPremium =
                Number(Number(rfqData[key].NOTIONAL) / legTotalNotional) *
                Number(rfqData[key].CLIENT_PREMIUM.replaceAll(",", ""));

              colObj = { ...colObj, [key]: [`${clientPremium}`] };
            } else {
              colObj = { ...colObj, [key]: [rfqData[key][col]] };
            }
          }
        });
        objToSend.push(colObj);
      });
      if (singleConfo === false) {
        response = await axios.post(
          `${process.env.REACT_APP_PYTON}create_confo`,
          {
            confo_details: confo_details,
            opts_data: currentClient[tabIndex]?.includes("(MM)")
              ? rfqObject.rfq_table_data
              : objToSend,
          },
        );
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_PYTON}create_confo`,
          {
            confo_details: confo_details,
            opts_data: rfqObject?.rfq_table_data,
          },
        );
      }
      emailGenerateData = {
        confo_details: confo_details,
        opts_data: objToSend, //singleConfo ? rfq_table_data : objToSend,
        email_data: JSON.parse(response.data),
        client_email_addresses: shortNameOfClient?.email_addresses,
        market_maker_email_addresses: shortNameOfMarketMaker?.email_addresses,
        allocations: allocation_list,
        singleConfo: singleConfo,
        clientSplitPercentage: allocation_list[tabIndex]?.percentage_allocation,
      };
      if (
        splitManner === "notional" &&
        !currentClient[tabIndex].includes("(MM)")
      ) {
        delete emailGenerateData.allocations;
        delete emailGenerateData.clientSplitPercentage;
        if (DEAL_TYPE !== "SWAP") {
          emailGenerateData = {
            ...emailGenerateData,
            notionalAllocations: allocation_list,
            clientNotionalSplit:
              allocation_list[tabIndex]?.notional_allocation[0],
            currentClientIndex: tabIndex,
            totalNotional: totalNotional,
          };
        } else {
          emailGenerateData = {
            ...emailGenerateData,
            notionalAllocations: allocation_list,
            currentClientIndex: tabIndex,
          };
        }
      }
      // ! if regular no allocation no splits!
    } else {
      response = await axios.post(
        `${process.env.REACT_APP_PYTON}create_confo`,
        { confo_details: confo_details, opts_data: rfqObject?.rfq_table_data },
      );
      emailGenerateData = {
        confo_details: confo_details,
        opts_data: rfqObject?.rfq_table_data,
        email_data: JSON.parse(response.data),
        client_email_addresses: shortNameOfClient?.email_addresses,
        market_maker_email_addresses: shortNameOfMarketMaker?.email_addresses,
      };
    }
    await checkIfConfoSent(tabIndex);

    store.dispatch(emailActions.getEmailPreview("FX", emailGenerateData));
  } catch (error) {
    console.log(error);
    store.dispatch(actionSnackBar.setSnackBar("error", error.message, 3000));
  }
};

export { createConfo, checkIfConfoSent };
