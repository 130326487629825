import { forEach } from "lodash";
import { cleanEmptyPnlRows, getTraderName } from "./onCellEditReq";
import { getClearanceData } from "./apiCalls";

export const updateFields = async ({
  rowToUpdate,
  newData,
  setRowData,
  colId,
  clientObj,
  order,
  parsedBookingText,
  legFeeRateIndex,
  hedgeFeeRateIndex,
  initiationFeeIndex,
  initiatorIndex,
  isMarketMakerIndex,
  marketMakerFeeIndex,
  floorBrokerFeeIndex,
  executionFeeIndex,
  makorStaticData,
  commissionIndex,
  marketMakerIndex,
}) => {
  if (rowToUpdate === "client") {
    let feeObj;
    let commission = 0;
    let legFeeRate = 0;
    let hedgeFeeRate = newData[hedgeFeeRateIndex][colId];
    let initiationFee = newData[initiationFeeIndex][colId];
    let initiator = newData[initiatorIndex][colId];
    let initiation = 0;
    let marketMakerFee = newData[marketMakerFeeIndex][colId];
    let isMarketMaker = newData[isMarketMakerIndex][colId];
    let marketMaker = 0;
    let floorBrokerFee = newData[floorBrokerFeeIndex][colId];
    let executionFee = newData[executionFeeIndex][colId];
    let initiatorColumnId = null;
    let initiatorInitiationValue = null;
    const updateData = newData.map((row) => {
      if (row.id === "client") {
        return {
          ...row,
          [colId]: clientObj[0].name,
        };
      } else if (row.id === "trader") {
        return {
          ...row,
          [colId]:
            clientObj[0].client_traders?.length > 0
              ? clientObj[0].client_traders[0].name
              : null,
        };
      } else if (row.id === "initiator") {
        const execKeys = Object.keys(row).filter((key) =>
          key.startsWith("exec"),
        );
        execKeys.forEach((exec) => {
          if (row[exec]) {
            initiatorColumnId = exec;
          }
        });

        return row;
      } else if (row.id === "is_market_maker") {
        isMarketMaker = clientObj[0].is_market_maker === 1 ? true : false;
        return {
          ...row,
          [colId]: clientObj[0].is_market_maker === 1 ? true : false,
        };
      } else if (row.id === "leg_fee_rate") {
        if (clientObj[0].client_drv_trade_brokerage_fees.length > 0) {
          const legType = order.equityIndex.includes("Equity")
            ? "SS & ETF"
            : order.equityIndex.includes("Index")
            ? "Index"
            : null;
          if (legType !== null) {
            feeObj = clientObj[0].client_drv_trade_brokerage_fees.filter(
              (fee) => fee.name.toLowerCase() === legType.toLowerCase(),
            );
            if (feeObj.length > 0) {
              legFeeRate = feeObj[0].pivot.fee_rate;
              return {
                ...row,
                [colId]: feeObj[0].pivot.fee_rate,
              };
            } else {
              return {
                ...row,
                [colId]: 0,
              };
            }
          } else
            return {
              ...row,
              [colId]: 0,
            };
        } else {
          return {
            ...row,
            [colId]: 0,
          };
        }
      } else if (row.id === "hedge_fee_rate") {
        if (clientObj[0].client_drv_trade_brokerage_fees.length > 0) {
          const legType = order.equityIndex.includes("Equity")
            ? "SS & ETF"
            : order.equityIndex.includes("Index")
            ? "Index"
            : null;
          if (legType !== null && order.equityIndex.includes("Index")) {
            feeObj = clientObj[0].client_drv_trade_brokerage_fees.filter(
              (fee) => fee.name.toLowerCase() === legType.toLowerCase(),
            );
            if (feeObj.length > 0) {
              hedgeFeeRate = feeObj[0].pivot.fee_rate;
              return {
                ...row,
                [colId]: feeObj[0].pivot.fee_rate,
              };
            } else {
              return {
                ...row,
                [colId]: 0,
              };
            }
          } else
            return {
              ...row,
              [colId]: 0,
            };
        } else {
          return {
            ...row,
            [colId]: 0,
          };
        }
      } else if (row.id === "commission") {
        const columnIndex = Number(colId.replace("exec", "")) - 1;
        let totalOptionsQuantity = 0;
        let totalHedgesQuantity = 0;
        for (const exec of parsedBookingText.executions[columnIndex].option) {
          totalOptionsQuantity += exec.quantity;
        }
        for (const exec of parsedBookingText.executions[columnIndex].hedge) {
          totalHedgesQuantity += exec.quantity;
        }
        commission =
          totalOptionsQuantity * legFeeRate +
          totalHedgesQuantity * hedgeFeeRate;
        return { ...row, [colId]: commission };
      } else if (row.id === "initiation") {
        const execKeys = Object.keys(row).filter(
          (key) => key.startsWith("exec") && key !== colId,
        );
        let initiationSum = 0;
        //other column calculation

        execKeys.forEach((exec) => {
          const isExecInitiator = newData[initiatorIndex][exec];
          const execCommission = newData[commissionIndex][exec];
          const execInitiationFee = newData[initiationFeeIndex][exec];
          const execFloorBrokerFee = newData[floorBrokerFeeIndex][exec];

          if (!isExecInitiator) {
            row = {
              ...row,
              [exec]: Number(
                (
                  (execCommission - execFloorBrokerFee) *
                  execInitiationFee
                ).toFixed(2),
              ),
            };
            initiationSum += Number(
              (
                (execCommission - execFloorBrokerFee) *
                execInitiationFee
              ).toFixed(2),
            );
          } else {
            initiatorColumnId = exec;
          }
        });

        //this handles the column being changed because we don't have the current commission
        if (initiatorColumnId !== colId) {
          row = {
            ...row,
            [initiatorColumnId]:
              initiationSum +
              Number(
                ((commission - floorBrokerFee) * initiationFee).toFixed(2),
              ),
          };
          initiatorInitiationValue =
            initiationSum +
            Number(((commission - floorBrokerFee) * initiationFee).toFixed(2));
          initiation = Number(
            ((commission - floorBrokerFee) * initiationFee).toFixed(2),
          );
          return { ...row, [colId]: initiation };
        } else {
          initiation = initiationSum;
          return { ...row, [colId]: initiationSum };
        }
      } else if (row.id === "market_maker") {
        if (isMarketMaker && !initiator) {
          marketMaker = (commission - floorBrokerFee) * marketMakerFee;
          return {
            ...row,
            [colId]: (commission - floorBrokerFee) * marketMakerFee,
          };
        } else {
          marketMaker = 0;
          return { ...row, [colId]: 0 };
        }
      } else if (row.id === "net") {
        if (initiatorColumnId === colId) {
          return {
            ...row,
            [colId]:
              commission -
              floorBrokerFee -
              executionFee +
              (initiator ? initiation : -initiation) -
              marketMaker,
          };
        } else {
          const colIdNet =
            commission -
            floorBrokerFee -
            executionFee -
            initiation -
            marketMaker;
          const initiatorNet =
            newData[commissionIndex][initiatorColumnId] -
            newData[floorBrokerFeeIndex][initiatorColumnId] -
            newData[executionFeeIndex][initiatorColumnId] +
            initiatorInitiationValue -
            newData[marketMakerIndex][initiatorColumnId];
          return {
            ...row,
            [colId]: colIdNet,
            [initiatorColumnId]: initiatorNet,
          };
        }
      } else {
        return row;
      }
    });

    const clientTraderObj =
      clientObj[0].client_traders[0] !== undefined
        ? [clientObj[0].client_traders[0]]
        : [];

    const updatedArrWithPnl = await pnlRowsGenerator(
      clientObj,
      updateData,
      parsedBookingText,
      colId,
      makorStaticData,
      clientTraderObj,
    );

    const res = await getClearanceData(
      clientTraderObj[0]?.client_id,
      clientTraderObj[0]?.id,
    );

    setRowData(
      cleanEmptyPnlRows(updatedArrWithPnl).map((row) => {
        if (row.id === "clearance_account") {
          const obj =
            row.clearanceDataObj !== undefined
              ? { ...row.clearanceDataObj, [colId]: res }
              : { [colId]: res };
          return {
            ...row,
            [colId]: res.length > 0 ? res[0] : null,
            clearanceDataObj: obj,
          };
        } else return { ...row };
      }),
    );
  }
};

export const resetFields = ({
  columnHeader,
  fieldsToReset,
  setRowData,
  rowIndex,
  colId,
  value,
  newData,
  e,
  initiationIndex,
  initiatorIndex,
}) => {
  const initiator = newData[initiatorIndex][colId];
  let initiation = newData[initiationIndex][colId];
  let currentInitiatorExec = null;

  const arr = newData.map((row) => {
    if (fieldsToReset.includes(row.id) || row.id.includes("pnl")) {
      if (
        row.id === "trader" ||
        row.id === "client" ||
        row.id.includes("pnl")
      ) {
        return { ...row, [colId]: null };
      } else if (row.id === "initiator") {
        const execKeys = Object.keys(row).filter(
          (key) => key.startsWith("exec") && key !== colId,
        );
        execKeys.forEach((exec) => {
          if (row[exec]) {
            currentInitiatorExec = exec;
            console.log(exec);
          }
        });
        return row;
      } else if (row.id === "initiation") {
        if (initiator) {
          initiation = 0;
          return { ...row, [colId]: 0 };
        } else {
          if (currentInitiatorExec !== null) {
            const execKeys = Object.keys(row).filter(
              (key) => key.startsWith("exec") && key !== currentInitiatorExec,
            );
            let initiationSum = 0;
            execKeys.forEach((exec) => {
              if (exec !== colId) {
                const execInitiation = newData[initiationIndex][exec];
                initiationSum += Number(execInitiation);
              }
            });
            return {
              ...row,
              [colId]: 0,
              [currentInitiatorExec]: initiationSum,
            };
          } else {
            initiation = 0;
            return { ...row, [colId]: initiation };
          }
        }
      } else if (row.id === "net") {
        return { ...row, [colId]: 0 };
      } else {
        return { ...row, [colId]: 0 };
      }
    } else {
      return row;
    }
  });
  setRowData(cleanEmptyPnlRows(arr));
};

export const pnlRowsGenerator = async (
  clientObj,
  updateData,
  parsedBookingText,
  colId,
  makorStaticData,
  clientTraderObj,
) => {
  const employeesFeeArr =
    clientTraderObj[0]?.client_trader_employees_brokerage_fee;
  const pnlRowsFromData = updateData.filter((r) =>
    r.columnHeader.includes("pnl"),
  );
  if (clientObj.length > 0 && clientObj[0].client_traders.length > 0) {
    const rowsToAdd = await Promise.all(
      (employeesFeeArr?.length > pnlRowsFromData.length
        ? employeesFeeArr
        : pnlRowsFromData
      ).map((row, pnlIndex) => {
        if (pnlRowsFromData.length > 0) {
          const row = {};
          row.id = `pnl${pnlIndex + 1}`;
          row.columnHeader = `pnl${pnlIndex + 1}`;
          parsedBookingText.executions.forEach((exec, i) => {
            if (`exec${i + 1}` === colId) {
              row[colId] = employeesFeeArr[pnlIndex]
                ? `${getTraderName(
                    employeesFeeArr[pnlIndex]?.id,
                    makorStaticData,
                  )} (${employeesFeeArr[pnlIndex]?.pivot.percentage}%)`
                : null;
            } else {
              if (pnlRowsFromData[pnlIndex]) {
                row[`exec${i + 1}`] = pnlRowsFromData[pnlIndex][
                  [`exec${i + 1}`]
                ]
                  ? pnlRowsFromData[pnlIndex][[`exec${i + 1}`]]
                  : null;
              } else {
                row[`exec${i + 1}`] = null;
              }
            }
          });
          return row;
        } else {
          const row = {};
          row.id = `pnl${pnlIndex + 1}`;
          row.columnHeader = `pnl${pnlIndex + 1}`;
          parsedBookingText.executions.forEach((exec, i) => {
            if (`exec${i + 1}` === colId) {
              row[colId] = `${getTraderName(
                employeesFeeArr[pnlIndex]?.id,
                makorStaticData,
              )} (${employeesFeeArr[pnlIndex]?.pivot.percentage}%)`;
            } else {
              row[`exec${i + 1}`] = null;
            }
          });

          return row;
        }
      }),
    );
    const arrWithoutPnl = updateData.filter(
      (r) => !r.columnHeader.includes("pnl"),
    );
    arrWithoutPnl.splice(8, 0, ...rowsToAdd);
    return arrWithoutPnl;
  } else {
    const rowsToAdd = await Promise.all(
      pnlRowsFromData.map((row, pnlIndex) => {
        if (pnlRowsFromData.length > 0) {
          const row = {};
          row.id = `pnl${pnlIndex + 1}`;
          row.columnHeader = `pnl${pnlIndex + 1}`;
          parsedBookingText.executions.forEach((exec, i) => {
            if (`exec${i + 1}` === colId) {
              row[colId] = null;
            } else {
              if (pnlRowsFromData[pnlIndex]) {
                row[`exec${i + 1}`] = pnlRowsFromData[pnlIndex][
                  [`exec${i + 1}`]
                ]
                  ? pnlRowsFromData[pnlIndex][[`exec${i + 1}`]]
                  : null;
              } else {
                row[`exec${i + 1}`] = null;
              }
            }
          });
          return row;
        } else {
          const row = {};
          row.id = `pnl${pnlIndex + 1}`;
          row.columnHeader = `pnl${pnlIndex + 1}`;
          parsedBookingText.executions.forEach((exec, i) => {
            if (`exec${i + 1}` === colId) {
              row[colId] = null;
            } else {
              row[`exec${i + 1}`] = null;
            }
          });

          return row;
        }
      }),
    );
    const arrWithoutPnl = updateData.filter(
      (r) => !r.columnHeader.includes("pnl"),
    );
    arrWithoutPnl.splice(8, 0, ...rowsToAdd);
    return arrWithoutPnl;
  }
};

export const getFloorBrokerRate = (value, makorStaticData, order) => {
  let crossedFeeRate = null,
    notCrossedFeeRate = null,
    crossedFilteredFee = null,
    notCrossedFilteredFee = null;
  const floorBroker = value;
  const floorBrokerObj = makorStaticData.drv_trade_floor_brokers.filter(
    (fb) => fb.name.toLowerCase() === floorBroker.toLowerCase(),
  );
  if (floorBrokerObj.length > 0) {
    if (
      floorBroker.toLowerCase() === "mando" ||
      floorBroker.toLowerCase() === "tjm"
    ) {
      crossedFilteredFee = floorBrokerObj[0].drv_trade_floor_broker_fees.filter(
        (fee) =>
          fee.name.toLowerCase().includes("crossed") &&
          !fee.name.toLowerCase().includes("not"),
      );
      notCrossedFilteredFee =
        floorBrokerObj[0].drv_trade_floor_broker_fees.filter(
          (fee) =>
            fee.name.toLowerCase().includes("crossed") &&
            fee.name.toLowerCase().includes("not"),
        );
    } else if (floorBroker.toLowerCase() === "xfa") {
      crossedFilteredFee = floorBrokerObj[0].drv_trade_floor_broker_fees.filter(
        (fee) =>
          fee.name.toLowerCase().includes("crossed") &&
          !fee.name.toLowerCase().includes("not") &&
          (order.equityIndex.toLowerCase().includes("index") &&
          order.symbol.toLowerCase() !== "vix"
            ? fee.name.toLowerCase().includes("index")
            : true) &&
          (order.symbol.toLowerCase() === "vix"
            ? fee.name.toLowerCase().includes("vix")
            : true),
      );
      notCrossedFilteredFee =
        floorBrokerObj[0].drv_trade_floor_broker_fees.filter(
          (fee) =>
            fee.name.toLowerCase().includes("crossed") &&
            fee.name.toLowerCase().includes("not") &&
            (order.equityIndex.toLowerCase().includes("index") &&
            order.symbol.toLowerCase() !== "vix"
              ? fee.name.toLowerCase().includes("index")
              : true) &&
            (order.symbol.toLowerCase() === "vix"
              ? fee.name.toLowerCase().includes("vix")
              : true),
        );
    } else {
      console.log("unhandled floor broker", floorBroker);
    }

    if (crossedFilteredFee.length > 0) {
      crossedFeeRate = crossedFilteredFee[0]?.pivot?.fee_rate;
    }
    if (notCrossedFilteredFee.length > 0) {
      notCrossedFeeRate = notCrossedFilteredFee[0]?.pivot?.fee_rate;
    }
    return { crossedFeeRate, notCrossedFeeRate };
  }
};
