
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import {Grid , Typography,} from "@material-ui/core";

const QuoteBookParamsPayout = ({rfqParamaters}) => {
  const classes = useStyles();
    return(
        <Grid container className={classes.rfqParameters}>
        <Grid item xs={2}>
          <Typography className={classes.quoteParamatersText}>
            Quote Params
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.quoteParamatersText}>
            QUOTE TYPE: {rfqParamaters.quote_type_str}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.quoteParamatersText}>
            NUMBER OF LEGS: {rfqParamaters.leg_count}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.quoteParamatersText}>
            Directions: {rfqParamaters.directions}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.quoteParamatersText}>
            SPOT REF: {rfqParamaters.spot_ref}
          </Typography>
         
        </Grid>
        <Grid item xs={2}>
            <Typography className={classes.quoteParamatersText}>
              QUOTE FORMAT: {rfqParamaters.quote_format}
            </Typography>
          </Grid>
      </Grid>
    )
}
export default QuoteBookParamsPayout
export const useStyles = makeStyles((theme) => ({
    "& .MuiInputBase-input MuiInput-input": {
      fontSize: "400px",
      color: "white",
    },
    rfqPctNotional: {
      border: "1px solid #686B76",
      padding: "20px",
      marginTop: "5px",
      borderRadius: "8px",
    },
    rfqParameters: {
      fontSize: "1.0625rem",
      borderRadius: "8px",
      display: "flex",
      flexBasis:"column",
      // justifyContent: "space-around",
      border: "1px solid #686B76",
      padding: "20px",
      marginTop: "5px",
      height: "fit-content",
    },
    quoteParamatersHeader: {
      color: " var(--unnamed-color-989ea8)",
      font: "normal normal normal 12px/10px Inter",
      letterSpacing: "0px",
      color: " #989EA8",
    },
    quoteParamatersText: {
      marginTop: "10px",
      color: " var(--unnamed-color-989ea8)",
      fontSize: "13px Inter",
      letterSpacing: "0px",
      color: "#FFFFFF",
    },
  }));