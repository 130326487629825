import React, { useEffect, useState } from "react";
import {
  Dialog,
  Grid,
  Typography,
  IconButton,
  Divider,
  FormControl,
} from "@mui/material";
import { useStyles } from "../../../Styles/fiStyles";
import CloseIcon from "@material-ui/icons/Close";
import { SelectComponent } from "./SelectComponent";
import axios from "axios";
import { END_POINT } from "../../../utils";
import { NumberComponent } from "./NumberComponent";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as fiActions from "../../../store/fi/fiSlice";
import * as actionSnackBar from "../../../store/snackbar/action";

export const PnlAttDialog = ({ open, handleClose, pnlDealId }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [value, setValue] = useState("1");
  const [clientSplitArr, setClientSplitArr] = useState([]);
  const [tradersList, setTradersList] = useState([]);
  const tradersSplitPnlArr = useSelector(
    (state) => state.fiSlice.tradersSplitPnlArr,
  );
  const fiTrades = useSelector((state) => state.fiSlice.fiTrades);

  // functions
  const handleCancel = () => {
    handleClose();
    setValue("1");
    dispatch(fiActions.resetTradersSplitPnlArr());
  };
  const handleSubmit = async () => {
    if (Number(value) === Number(tradersSplitPnlArr.length)) {
      let sum = 0;

      tradersSplitPnlArr.forEach((split) => {
        if (
          split.percentageSplit === undefined ||
          split.percentageSplit === null ||
          split.traderId === undefined ||
          split.traderId === null
        ) {
          dispatch(
            actionSnackBar.setSnackBar(
              "error",
              "All fields must be filled with agreed split allocated",
              3000,
            ),
          );
        } else {
          sum += split.percentageSplit;
        }
      });
      if (sum !== 100) {
        dispatch(
          actionSnackBar.setSnackBar(
            "error",
            "The allocation split must add up to 100%",
            3000,
          ),
        );
      } else {
        const res = await submitPnlAtt(tradersSplitPnlArr, pnlDealId);
        if (res) {
          handleCancel();
        }
      }
    } else {
      dispatch(
        actionSnackBar.setSnackBar(
          "error",
          "All fields must be filled with agreed split allocated",
          3000,
        ),
      );
    }
  };
  // useEffect's
  useEffect(() => {
    if (open) {
      const pnlAtt = JSON.parse(
        fiTrades.find((t) => t.deal_id === pnlDealId).pnlAtt,
      );
      if (pnlAtt !== null) {
        dispatch(fiActions.updateTradersSplitPnlArr(pnlAtt));
        setValue(`${pnlAtt.length}`);
      }
    }
  }, [open]);

  useEffect(() => {
    if (value !== null) {
      const newArray = Array(Number(value)).fill(null);
      setClientSplitArr(newArray);
      // dispatch(fiActions.resetTradersSplitPnlArr());
    }
  }, [value]);

  useEffect(() => {
    if (open) {
      if (tradersList.length === 0) {
        getTraders(setTradersList);
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      classes={{ paper: classes.dialogPaperPnlSplit }}
      PaperProps={{
        style: {
          backgroundColor: "#2D2D2D",
        },
      }}
    >
      <Grid container>
        {/* Header Grid */}
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item className={classes.pnlArrHeaderTypography} xs={11}>
            <Typography variant="h4">PNL Attribution</Typography>
          </Grid>
          <Grid item>
            <IconButton size="small" onClick={handleCancel}>
              <CloseIcon className={classes.pnlArrHeaderCloseIcon} />
            </IconButton>
          </Grid>
          <Grid xs={12} style={{ opacity: "0.5" }}>
            <hr />
          </Grid>
        </Grid>
        {/* Body Grid */}
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.pnlAttSelectContainer}
        >
          <Grid item style={{ minHeight: "400px" }} xs={3}>
            <SelectComponent
              componentKey={"pnlAtt"}
              label={"Splits"}
              value={value}
              setValue={setValue}
            />
          </Grid>
          <Grid
            xs={8}
            style={{ minHeight: "400px" }}
            item
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
          >
            {clientSplitArr.map((item, index) => {
              return (
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={3}
                >
                  <Grid item xs={5}>
                    <SelectComponent
                      componentKey={"traders"}
                      label={"Trader"}
                      tradersList={tradersList}
                      index={index}
                      value={value}
                    />
                  </Grid>
                  <Grid item>
                    <NumberComponent
                      label={"percentage"}
                      index={index}
                      xs={5}
                      arrValue={value}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid
          xs={12}
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={"150px"}
          // style={{ position: "absolute", bottom: "25px" }}
        >
          <Grid item>
            <Button
              variant="contained"
              className={classes.btn}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              className={classes.btn}
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export const getTraders = async (setTradersList) => {
  try {
    const token = sessionStorage.getItem("token");
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}` +
        END_POINT.FI_TRADES +
        "/get_traders",
      {
        headers: { Authorization: token },
      },
    );
    const tradersList = res.data
      .filter((trader) => trader.trader_id !== 9)
      .map((trader) => {
        return {
          id: trader.trader_id,
          value: `${trader.trader_first_name} ${trader.trader_last_name}`,
        };
      });

    setTradersList(tradersList);
  } catch (error) {
    console.log(error);
  }
};

export const submitPnlAtt = async (tradersSplitPnlArr, pnlDealId) => {
  try {
    const token = sessionStorage.getItem("token");
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}` + END_POINT.SUBMIT_FI_PNL_ATT,
      { tradersSplitPnlArr, pnlDealId },
      {
        headers: { Authorization: token },
      },
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
