import React, { useEffect, useState } from "react";
import { ReactComponent as Add } from "../../../../assets/add new tab.svg";
import { ReactComponent as LeftArrow } from "../../../../assets/left.svg";
import { ReactComponent as RightArrow } from "../../../../assets/right.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/X.svg";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import { Menu, MenuItem, Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import * as rfqSlice from "../../../../store/rfq/rfqSlice";
import * as rfqPostTradeSlice from "../../../../store/rfq/rfqPostTradeSlice";
import * as rfqAggregatorSlice from "../../../../store/rfq/rfqAggregatorSlice";
import { useDispatch, useSelector } from "react-redux";
import "./RfqWorkspaceHeader.css";
import axios from "axios";
import { END_POINT } from "../../../../utils";
import { Link, useNavigate } from "react-router-dom";
import clsx from "clsx";
import * as actionSnackBar from "../../../../store/snackbar/action";
import {
  apiGetQuoatParameters,
  apiRestDealStats,
} from "../../../../services/apiCalls/restCalls/restQuoteBookApi";
import {
  apiGetDealStats,
  apiRefreshQuoteBook,
  buildOrderBook,
} from "../../../../services/apiCalls/quotsBookApi";
import { jtable_keys } from "../rfqWorkspace/helpers/staticDataTable";
import { createQuoteBookDict } from "../../../../services/rfqWorkspace/createQuoteBookDict";
import { KeyboardTab } from "@material-ui/icons";
import MenuIcon from "@mui/icons-material/Menu";
import RfqWorkspaceHeaderTabs from "./rfqWorkspaceHeaderTabs/RfqWorkspaceHeaderTabs";

export const RfqWorkspaceHeader = ({
  rfq_object,
  setrfq_object,
  buttonBackgrounds,
  setButtonBackgrounds,
}) => {
  const activeRfqInstanceList = useSelector(
    (state) => state.rfqSlice.rfqInstanceList,
  );
  let { volLegParams } = useSelector((state) => state.rfqAggregatorSlice);
  let dealStatsData = useSelector((state) => state.rfqSlice.dealStatsData);
  const rfq = useSelector((state) => state.rfqSlice.rfq);
  const requestFor = useSelector((state) => state.rfqSlice.requestFor);
  const rfqid = useSelector((state) => state.rfqSlice.rfqId.items);
  const classes = useStyles();
  const dispatch = useDispatch();
  const rfqDetailsCreated = useSelector(
    (state) => state.rfqSlice.rfqCreatedDetails,
  );
  const { postTradeSplitArrPreValues } = useSelector(
    (state) => state.rfqPostTradeSlice,
  );
  const rfqLocationMode = useSelector(
    (state) => state.rfqSlice.rfqLocationMode,
  );
  let recommendationRfqs = useSelector(
    (state) => state.rfqSlice.recommendationRfqs,
  );

  // const rfqMode = useSelector((state) => state.rfqSlice.rfqMode);
  const rfqMode = sessionStorage.getItem("rfqMode");
  const rfqChecked = useSelector((state) => state.rfqSlice.rfqId.items);
  const status = useSelector((state) => state.rfqSlice.rfqButtonStatus);

  const deleteRfq = async (e, rfqIndex, i) => {
    e.stopPropagation();
    const obj = {
      value: 2,
      key: "postTradeSplitNumber",
    };
    dispatch(rfqPostTradeSlice.setPostTradeSplitNumber(obj));
    dispatch(rfqPostTradeSlice.resetPostTradeStats());
    reset_rfq_instance();
    dispatch(rfqPostTradeSlice.setAllValuesIfConfosExist());
    const indexToRemove = activeRfqInstanceList.findIndex(
      (tab) => Number(tab.id) === Number(i.id),
    );
    const updateActiveRfqInstanceList = [...activeRfqInstanceList];
    if (indexToRemove !== -1) {
      updateActiveRfqInstanceList.splice(indexToRemove, 1);
    }
    dispatch(rfqSlice.removeTabHandler(i.id, e, navigateRfqs));
    const objToRemove = {
      tab: i,
      userId: sessionStorage.getItem("id"),
      action: "remove tab",
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}` + END_POINT.UPDATE_TABS,
        objToRemove,
        { headers: { Authorization: sessionStorage.getItem("token") } },
      );
    } catch (error) {}
  };
  const navigateRfqs = async (e, i, rfqIndex) => {
    const { rfq_description, id } = i;
    dispatch(rfqSlice.setStatusCtrlEnter(""));
    dispatch(rfqPostTradeSlice.resetPostTradeStats());
    e.stopPropagation();
    e.preventDefault();
    if (id === undefined) {
      sessionStorage.setItem("rfqDealType", "");
      sessionStorage.setItem("rfqStatus", "Live");
      sessionStorage.setItem("rfqTraderId", "");
      sessionStorage.setItem("status", "newRfq");
      sessionStorage.setItem("rfqMode", "rfq view");
      dispatch(rfqAggregatorSlice.setRfqDefaultQuoteParams({}));
      dispatch(rfqAggregatorSlice.setUserSpread([]));
      dispatch(rfqPostTradeSlice.setSplitManner("percentage"));
      reset_rfq_instance();
      const obj = {
        value: 2,
        key: "postTradeSplitNumber",
      };
      sessionStorage.removeItem("rfqId");
      dispatch(rfqSlice.updateLpPerdiction([]));
      dispatch(rfqSlice.clearRfqState());
      dispatch(rfqAggregatorSlice.clearQuoteParameterts());
      // dispatch(rfqAggregatorSlice.updateQuoteParameterts({}));
      dispatch(rfqPostTradeSlice.setPostTradeSplitNumber(obj));
      dispatch(rfqPostTradeSlice.resetPostTradeStats());
      dispatch(rfqPostTradeSlice.setAllValuesIfConfosExist());
      setButtonBackgrounds({
        rfqBokingBc: "#171717",
        rfqViewBc: "#FF681C",
        aggregatorBc: "#171717",
      });
      dispatch(rfqSlice.getCurrencyPairs());
      dispatch(rfqSlice.getPbAccounts());
      dispatch(rfqSlice.getDealType());
      dispatch(rfqSlice.getClients());
      dispatch(rfqAggregatorSlice.getUserMarketMaker());
      dispatch(rfqAggregatorSlice.getMarketMaker());
      dispatch(rfqSlice.getCompleteClientList());
      dispatch(rfqSlice.selectNewRfqTab(rfq_description));
      dispatch(rfqSlice.setStatusItem("Live"));
    } else {
      sessionStorage.setItem("status", "open");
      sessionStorage.setItem("rfqMode", "rfq view");
      setButtonBackgrounds({
        rfqBokingBc: "#171717",
        rfqViewBc: "#FF681C",
        aggregatorBc: "#171717",
      });

      sessionStorage.setItem("rfqId", id);
      let getRfqLOcation = rfqLocationMode.find((key) => {
        return Number(key.rfq_id) === Number(id);
      });
      if (dealStatsData.makor_bid !== "---") {
        dispatch(rfqSlice.clearDealStatsData());
      }
      const result = await dispatch(rfqSlice.getRfqById(id));
      let currentRfq = postTradeSplitArrPreValues.filter(
        (rfq) => rfq.rfq_id === result.rfq[0].rfq_id,
      );
      if (result.rfq[0].status === "Live") {
        if (currentRfq.length === 0) {
          dispatch(rfqPostTradeSlice.setSplitManner("percentage"));
        } else {
          dispatch(rfqPostTradeSlice.setSplitManner(currentRfq.split_manner));
        }
      }

      const obj = {
        tab: {
          rfq_description: result.rfq[0].rfq_description,
          id: Number(id),
        },
        userId: sessionStorage.getItem("id"),
        action: "add tab",
        tabIsChosen: true,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}` + END_POINT.UPDATE_TABS,
        obj,
        { headers: { Authorization: sessionStorage.getItem("token") } },
      );
      dispatch(rfqSlice.updatRfqInstanceList(res.data));
      dispatch(rfqSlice.setStatusItem(result.rfq[0].status));
      updateRfqBookingModeDetails(id);
      handleRfqModalsDetails(id);
      sessionStorage.setItem("rfqId", id);
      const filteredRecommendation = recommendationRfqs.filter(
        (rfq) => Number(rfq.rfqId) === Number(id),
      );
      if (filteredRecommendation.length === 0) {
        getRecomandation(id);
      }
    }
  };

  const getRecomandation = async (id) => {
    let findRfq = rfq.find((rfq) => {
      return Number(rfq.rfq_id) === Number(id);
    });
    if (findRfq?.length === 0) {
      findRfq = rfqid;
    }

    rfq_object = { ...rfq_object, cross: findRfq.ccy_pair };
    rfq_object = { ...rfq_object, created_on: findRfq.created_on };
    rfq_object = { ...rfq_object, rfq_id: findRfq.rfq_id };
    try {
      const response = await axios.post(
        "https://fx-letool.letool-engine.makor-group.com/api/get_recommendations",
        rfq_object,
      );
      dispatch(
        rfqSlice.rfqRecommendationHandler({
          recommendationDetails: response.data,
          rfqId: sessionStorage.getItem("rfqId"),
        }),
      );
      dispatch(rfqSlice.updateLpPerdiction(response.data));
    } catch (error) {
      console.log(error);
    }
  };

  const rfqView = (e) => {
    sessionStorage.setItem("status", "open");
    setButtonBackgrounds({
      rfqBokingBc: "#171717",
      rfqViewBc: "#FF681C",
      aggregatorBc: "#171717",
    });
    dispatch(
      rfqSlice.updateRfqLocationMode({
        rfq_id: sessionStorage.getItem("rfqId"),
        rfqMode: "rfq view",
      }),
    );
    sessionStorage.setItem("rfqMode", "rfq view");

    dispatch(rfqSlice.updateRqObjectUpdate(false));
  };
  const rfqAggregatorView = (e) => {
    e.preventDefault();
    sessionStorage.setItem("status", "");
    if (rfq_object.rfq_id === undefined && rfq.length === 0) {
      dispatch(
        actionSnackBar.setSnackBar(
          "error",
          "LAUNCH RFQ BEFORE SETTING AGGREGATOR MODE",
          3000,
        ),
      );
      return;
    }
    setButtonBackgrounds({
      rfqBokingBc: "#171717",
      rfqViewBc: "#171717",
      aggregatorBc: "#FF681C",
    });
    dispatch(
      rfqSlice.updateRfqLocationMode({
        rfq_id: sessionStorage.getItem("rfqId"),
        rfqMode: "rfq aggregator view",
      }),
    );
    sessionStorage.setItem("rfqMode", "rfq aggregator view");

    dispatch(rfqSlice.updateRqObjectUpdate(false));
  };
  const rfqBookingMode = async (e) => {
    const rfqId = sessionStorage.getItem("rfqId");
    await updateRfqBookingModeDetails(rfqId);
    const filteredRfq = rfq.filter((r) => Number(r.rfq_id) === Number(rfqId));

    if (filteredRfq.length === 0) {
      if (
        rfqid.length > 0 &&
        (rfqid[0].status === "Live" ||
          rfqid[0].status === "Confirmed" ||
          rfqid[0].status === "Booked" ||
          rfqid[0].status === "Confirmed & Booked")
      ) {
        sessionStorage.setItem("status", "open");
        sessionStorage.setItem("rfqMode", "rfq booking view");
        if (rfq_object.rfq_id === undefined && rfq.length === 0) {
          dispatch(
            actionSnackBar.setSnackBar(
              "error",
              "LAUNCH RFQ BEFORE SETTING BOOKING MODE",
              3000,
            ),
          );
          return;
        }
        setButtonBackgrounds({
          rfqBokingBc: "#FF681C",
          rfqViewBc: "#171717",
          aggregatorBc: "#171717",
        });
        dispatch(
          rfqSlice.updateRfqLocationMode({
            rfq_id: sessionStorage.getItem("rfqId"),
            rfqMode: "rfq booking view",
          }),
        );
        sessionStorage.setItem("status", "open");
        sessionStorage.setItem("rfqMode", "rfq booking view");
        dispatch(rfqSlice.updateRqObjectUpdate(false));
      }
    } else {
      if (
        filteredRfq.length > 0 &&
        (filteredRfq[0].status === "Live" ||
          filteredRfq[0].status === "Confirmed" ||
          filteredRfq[0].status === "Booked" ||
          filteredRfq[0].status === "Confirmed & Booked")
      ) {
        sessionStorage.setItem("status", "open");
        sessionStorage.setItem("rfqMode", "rfq booking view");

        if (rfq_object.rfq_id === undefined && rfq.length === 0) {
          dispatch(
            actionSnackBar.setSnackBar(
              "error",
              "LAUNCH RFQ BEFORE SETTING BOOKING MODE",
              3000,
            ),
          );
          return;
        }
        setButtonBackgrounds({
          rfqBokingBc: "#FF681C",
          rfqViewBc: "#171717",
          aggregatorBc: "#171717",
        });
        dispatch(
          rfqSlice.updateRfqLocationMode({
            rfq_id: sessionStorage.getItem("rfqId"),
            rfqMode: "rfq booking view",
          }),
        );
        sessionStorage.setItem("rfqMode", "rfq booking view");
        dispatch(rfqSlice.updateRqObjectUpdate(false));
      }
    }
  };
  const reset_rfq_instance = () => {
    let copyRfqObject = { ...rfq_object };
    Object.assign(copyRfqObject, {
      rfq_description: null,
      rfq_table_data: null,
      aggregator_mode: false,
      booking_mode: false,
      rfq_str: "",
      ovml_tail: "",
      order_book: null,
      quote_parameters: null,
      quote_book: null,
      deal_stats: null,
      launch_status: false,
      quote_type: null,
      cross: "",
      currencyPairId: null,
      cut: null,
      notional_ccy: "",
      optionLegId: "",
      pb_account: null,
      strategyId: null,
      traderId: "",
      traderNote: "",
      rfq_id: undefined,
    });
    setrfq_object(copyRfqObject);
  };
  const createdOn = () => {
    if (rfqChecked.length > 0) {
      if (status !== "copy") {
        return rfqChecked[0].created_on;
      }
    }
    return rfqDetailsCreated[0].created_on;
  };
  const createdBy = () => {
    if (rfqChecked.length > 0) {
      if (status !== "copy") {
        return rfqChecked[0].trader_first_name;
      }
    }
    return rfqDetailsCreated[0].traderName;
  };
  const request_for = () => {
    if (requestFor !== "") {
      return requestFor;
    } else {
      return "";
    }
  };
  let updateRfqBookingModeDetails = async (id) => {
    const token = sessionStorage.getItem("token");
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}` +
          "rfq" +
          `/rfq_booking_details/${id}`,
        {
          headers: { Authorization: token },
        },
      );
      if (
        response.data.rfqDetails[0]?.third_ccy !== undefined &&
        response.data.rfqDetails[0]?.third_ccy !== null &&
        response.data.rfqDetails[0]?.third_ccy !== ""
      ) {
        const rates = await axios.get(
          `${process.env.REACT_APP_BLOOMBERG}/spot/${response.data.rfqDetails[0]?.third_ccy}`,
        );
      }

      if (response.data.rfqDetails.length > 0) {
        dispatch(
          rfqSlice.updateRfqBookingModeUpdatingRows(response.data.rfqDetails),
        );
      } else {
        dispatch(rfqSlice.updateRfqBookingModeUpdatingRows([]));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleRfqModalsDetails = async (rfqId) => {
    let token = sessionStorage.getItem("token");
    try {
      let rfqModalDetails = await axios.get(
        `${process.env.REACT_APP_BASE_URL}` +
          END_POINT.RFQ +
          "/" +
          END_POINT.GET_RFQ_MODALS_DETAILS +
          `/${rfqId}`,
        { headers: { Authorization: token } },
      );
      let { dealStats, orderBook, quoteParameters, rfqQuote, rfqDetails } =
        rfqModalDetails.data;
      dealStats = [
        {
          ...dealStats[0],
          fwd_scale:
            dealStats[0].userSpreads.length > 0 &&
            dealStats[0].userSpreads[0].fwd_scale
              ? dealStats[0].userSpreads[0].fwd_scale
              : dealStats[0].fwd_scale,
        },
      ];
      sessionStorage.setItem("rfqStatus", rfqDetails[0].status);
      sessionStorage.setItem("rfqTraderId", rfqDetails[0].trader_id);

      dispatch(
        rfqAggregatorSlice.setRfqDefaultQuoteParams(
          quoteParameters.defaultQuoteParameters[0],
        ),
      );

      //Handler Rfq tgat Is Not Equal To Duble Leg Vol
      if (quoteParameters.userQuoteParameters.length === 0) {
        if (
          dealStats[0].userSpreads.length > 0 &&
          dealStats[0].userSpreads[0].userSpreadBid !== "NaN" &&
          dealStats[0].userSpreads[0].userSpreadOffer !== "NaN"
        ) {
          dispatch(
            rfqAggregatorSlice.setUsersSpreadhandler(dealStats[0].userSpreads),
          );
          let copyDealStatsData = { ...dealStats[0] };
          copyDealStatsData.makor_bid = (
            parse_float_custom(copyDealStatsData.market_bid) -
            parse_float_custom(dealStats[0].userSpreads[0].userSpreadBid)
          ).toFixed(3);
          copyDealStatsData.makor_offer = (
            parse_float_custom(copyDealStatsData.market_offer) +
            parse_float_custom(dealStats[0].userSpreads[0].userSpreadOffer)
          ).toFixed(3);
          // copyDealStatsData.makor_bid =
          //   Number(copyDealStatsData.market_bid) -
          //   Number(dealStats[0].userSpreads[0].userSpreadBid).toFixed(3);
          // copyDealStatsData.makor_offer = (
          //   Number(copyDealStatsData.market_offer) +
          //   Number(dealStats[0].userSpreads[0].userSpreadOffer)
          // ).toFixed(3);
          let jtableDict = await createQuoteBookDict(rfqId);
          copyDealStatsData = {
            ...copyDealStatsData,
            pb: dealStats[0].userSpreads[0].pb,
          };
          let dealStatsData = await apiGetDealStats(
            quoteParameters.defaultQuoteParameters[0].quote_type,
            jtableDict,
            quoteParameters.defaultQuoteParameters[0],
            copyDealStatsData,
            orderBook,
            true,
          );
          dispatch(rfqSlice.updateTableData(orderBook));
          // dispatch(rfqSlice.updateDealStatsData(dealStats[0]));
          dispatch(
            rfqAggregatorSlice.updateQuoteParameterts(
              quoteParameters.defaultQuoteParameters[0],
            ),
          );
          dealStatsData.pb = dealStats[0].userSpreads[0].pb;
          dispatch(rfqSlice.updateDealStatsData(dealStatsData));
        } else {
          let userSpredDetails = [
            {
              userSpreadBid: (
                Number(dealStats[0].market_bid) - Number(dealStats[0].makor_bid)
              ).toFixed(3),
              isBidUser: false,
              isUserChangeBid: "0",
              isUserChangeOffer: "0",
              isUserChangeSpreadOffer: "0",
              isUserChangeSpreadBid: "0",
              userSpreadOffer: (
                Number(dealStats[0].makor_offer) -
                Number(dealStats[0].market_offer)
              ).toFixed(3),
            },
          ];
          dispatch(rfqAggregatorSlice.setUsersSpreadhandler(userSpredDetails));
          dispatch(rfqSlice.updateTableData(orderBook));
          dispatch(rfqSlice.updateDealStatsData(dealStats[0]));
          dispatch(
            rfqAggregatorSlice.updateQuoteParameterts(
              quoteParameters.defaultQuoteParameters[0],
            ),
          );
        }
      } else if (quoteParameters.userQuoteParameters.length > 0) {
        let quote = rfqQuote.map((q) => {
          return {
            ...q,
            quoted_vol1: q.quotevol_1,
            quoted_vol2: q.quotevol_2,
          };
        });
        let jtableDict = await createQuoteBookDict(rfqId);
        let refreshQuoteBook = await apiRefreshQuoteBook(
          quoteParameters.userQuoteParameters[0].quote_type,
          quote,
          jtableDict,
          quoteParameters.userQuoteParameters[0],
        );
        let updatedOrderBook = await buildOrderBook(refreshQuoteBook);

        let defaultDealStats = await apiGetDealStats(
          quoteParameters.userQuoteParameters[0].quote_type,
          jtableDict,
          quoteParameters.userQuoteParameters[0],
          dealStats[0],
          updatedOrderBook,
          false,
        );
        if (
          dealStats[0].userSpreads.length > 0 &&
          dealStats[0].userSpreads[0].userSpreadBid !== "NaN" &&
          dealStats[0].userSpreads[0].userSpreadOffer !== "NaN"
        ) {
          dispatch(
            rfqAggregatorSlice.setUsersSpreadhandler(dealStats[0].userSpreads),
          );
          let copyDealStatsData = { ...defaultDealStats };
          copyDealStatsData.makor_bid = (
            parse_float_custom(copyDealStatsData.market_bid) -
            parse_float_custom(dealStats[0].userSpreads[0].userSpreadBid)
          ).toFixed(3);
          copyDealStatsData.makor_offer = (
            parse_float_custom(copyDealStatsData.market_offer) +
            parse_float_custom(dealStats[0].userSpreads[0].userSpreadOffer)
          ).toFixed(3);
          // copyDealStatsData.makor_bid =
          //   Number(copyDealStatsData.market_bid) -
          //   Number(dealStats[0].userSpreads[0].userSpreadBid).toFixed(3);
          // copyDealStatsData.makor_offer = (
          //   Number(copyDealStatsData.market_offer) +
          //   Number(dealStats[0].userSpreads[0].userSpreadOffer)
          // ).toFixed(3);
          copyDealStatsData = {
            ...copyDealStatsData,
            pb: dealStats[0].userSpreads[0].pb,
          };
          let userDealStats = await apiGetDealStats(
            quoteParameters.userQuoteParameters[0].quote_type,
            jtableDict,
            quoteParameters.userQuoteParameters[0],
            copyDealStatsData,
            updatedOrderBook,
            true,
          );
          userDealStats.pb = dealStats[0].userSpreads[0].pb;

          dispatch(rfqSlice.updateDealStatsData(userDealStats));
          dispatch(
            rfqAggregatorSlice.updateQuoteParameterts(
              quoteParameters.userQuoteParameters[0],
            ),
          );
          dispatch(rfqSlice.updateTableData(updatedOrderBook));
        } else {
          let userDealStats = await apiGetDealStats(
            quoteParameters.userQuoteParameters[0].quote_type,
            jtableDict,
            quoteParameters.userQuoteParameters[0],
            dealStats[0],
            updatedOrderBook,
            false,
          );
          let userSpredDetails = [
            {
              userSpreadBid: (
                Number(userDealStats.market_bid) -
                Number(userDealStats.makor_bid)
              ).toFixed(3),
              isBidUser: false,
              isUserChangeBid: "0",
              isUserChangeOffer: "0",
              isUserChangeSpreadOffer: "0",
              isUserChangeSpreadBid: "0",
              userSpreadOffer: (
                Number(userDealStats.makor_offer) -
                Number(userDealStats.market_offer)
              ).toFixed(3),
            },
          ];
          dispatch(rfqAggregatorSlice.setUsersSpreadhandler(userSpredDetails));
          dispatch(rfqSlice.updateDealStatsData(userDealStats));
          dispatch(rfqSlice.updateTableData(updatedOrderBook));
          dispatch(
            rfqAggregatorSlice.updateQuoteParameterts(
              quoteParameters.userQuoteParameters[0],
            ),
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const parse_float_custom = (number_string) => {
    if (isString(number_string)) {
      return parseFloat(number_string.replace(/,/g, ""));
    } else {
      return number_string;
    }
  };
  const isString = (x) => {
    return Object.prototype.toString.call(x) === "[object String]";
  };
  useEffect(() => {
    if (rfqMode === "rfq aggregator view") {
      setButtonBackgrounds({
        rfqBokingBc: "#171717",
        rfqViewBc: "#171717",
        aggregatorBc: "#FF681C",
      });
    } else if (rfqMode === "rfq booking view") {
      setButtonBackgrounds({
        rfqBokingBc: "#FF681C",
        rfqViewBc: "#171717",
        aggregatorBc: "#171717",
      });
    } else if (rfqMode === "rfq view") {
      setButtonBackgrounds({
        rfqBokingBc: "#171717",
        rfqViewBc: "#FF681C",
        aggregatorBc: "#171717",
      });
    }
  }, [rfqMode]);

  return (
    <Grid mt={2} container>
      <Grid mb={2} item>
        <Typography className={classes.rfqHeader}>RFQ Workspace</Typography>
      </Grid>
      <RfqWorkspaceHeaderTabs
        navigateRfqs={navigateRfqs}
        deleteRfq={deleteRfq}
      />

      <Grid
        container
        className={classes.buttons}
        justifyContent="space-between"
      >
        <Grid item xs={2}>
          <Typography className={classes.createdRow}>
            Created By:
            {` ${createdBy() !== undefined ? createdBy() : ""} ${
              createdBy() !== undefined ? "on" : ""
            } ${createdOn() !== undefined ? createdOn() : ""}`}{" "}
          </Typography>
          <Typography className={classes.request_for_row}>
            Request For:
            {` ${request_for() !== undefined ? request_for() : ""}`}{" "}
          </Typography>
        </Grid>
        <Grid item xs={9} container>
          <Grid item xs={2} style={{ paddingRight: "10px" }}>
            <Button
              onClick={(e) => rfqView(e)}
              style={{
                width: "100%",
                height: "40px",
                color: "#FFFFFF",
                background: buttonBackgrounds.rfqViewBc,
                borderRadius: "4px",
                font: "normal normal normal 1.125rem/1.3125rem Inter",
                border: "1px solid #FF681C",
                borderRadius: "4px",
                opacity: 1,
                marginRight: "10px",
              }}
            >
              RFQ View
            </Button>
          </Grid>
          <Grid item xs={2} style={{ paddingRight: "10px" }}>
            <Button
              onClick={(e) => rfqAggregatorView(e)}
              style={{
                width: "100%",
                opacity: 1,
                backgroundColor: buttonBackgrounds.aggregatorBc,
                border: "1px solid #FF681C",
                height: "40px",
                color: "#FFFFFF",
                borderRadius: "4px",
                font: "normal normal normal 1.125rem/1.3125rem Inter",
              }}
            >
              Aggregator view
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              onClick={(e) => rfqBookingMode(e)}
              style={{
                width: "100%",
                height: "40px",
                color: "#FFFFFF",
                background: buttonBackgrounds.rfqBokingBc,
                borderRadius: "4px",
                font: "normal normal normal 1.125rem/1.3125rem Inter",
                border: "1px solid #FF681C",
                borderRadius: "4px",
                opacity: 1,
              }}
            >
              BOOKING View
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const useStyles = makeStyles(() => ({
  buttons: {
    display: "flex",
    padding: "20px 20px 0 ",
    alignItems: "center",
  },
  createdRow: {
    width: "max-content",
    fontSize: "1.125rem",
    color: "var(--unnamed-color-686b76)",
    font: "normal normal normal 16px/20px Inter",
    letterSpacing: "0px",
    color: "#686B76",
    opacity: 1,
    marginLeft: "-15px",
  },
  request_for_row: {
    width: "max-content",
    fontSize: "1.125rem",
    color: "var(--unnamed-color-686b76)",
    font: "normal normal normal 16px/20px Inter",
    letterSpacing: "0px",
    color: "#686B76",
    opacity: 1,
    marginLeft: "-15px",
  },
  rfqHeader: {
    textAlign: "left",
    font: "normal normal normal 2.125rem/2.1875rem Inter",
    letterSpacing: "0px",
    color: "#ffffff",
    opacity: 1,
  },
  rfqtabs: {
    width: "100%",
    borderBottom: "1px solid #686B76",
  },
  // rfqtab: {
  //   display: "flex",
  //   flexDirection: "row",
  // },
  rfqDescription: {
    font: "normal normal normal 16px/20px Inter",
    letterSpacing: "0px",
    color: "#FFFFFF",
    opacity: 1,
    fontSize: "16px",
    marginRight: "10px",
    background: "#2D2D2D 0% 0% no-repeat padding-box",
    borderradius: "4px 4px 0px 0px",
    opacity: 1,
    width: "195px",
    height: "40px",
    backgroundColor: "##686B76",
    color: "#fff",
  },
  rfqDescription2: {
    width: "200px",
  },
  rfqDescription3: {
    width: "200px",
  },
  HighlightedRfqTab: {
    font: "normal normal normal 16px/20px Inter",
    letterSpacing: "0px",
    color: "#FFFFFF",
    opacity: 1,
    fontSize: "16px",
    marginRight: "10px",
    background: "#2D2D2D 0% 0% no-repeat padding-box",
    borderRadius: "4px 4px 0px 0px",
    opacity: 1,
    width: "195px",
    height: "40px",
    backgroundColor: "#2692ff",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#72B8FF",
      color: "#fff",
    },
  },
  rfqTabsDescription: {
    fontSize: "1.125rem",
  },
  rfqTabsCloseIcon: {},
  // rfqTabsAddIcon: {
  //   marginTop: "10px",
  //   marginRight: "10px",
  //   marginLeft: "10px",
  // },
  bottomContainer: {},
  icon: {
    marginRight: "20px",
  },
  trash: {
    marginRight: "20px",
    marginTop: "11px",
    marginLeft: "13px",
    background: "#3A3C3F 0% 0% no-repeat padding-box",
  },
  launch: {
    textAlign: "left",
    font: "normal normal normal 1.125rem/1.3125rem Inter",
    letterSpacing: "0px",
    color: "#F2F2F2",
    opacity: 1,
  },
}));
