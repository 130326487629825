import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  IconButton,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@material-ui/core";
import { useStyles } from "../style/derivsClientsStyle";
import AddBoxRoundedIcon from "@material-ui/icons/AddBoxRounded";
import IndeterminateCheckBoxRoundedIcon from "@material-ui/icons/IndeterminateCheckBoxRounded";
import LoopIcon from "@material-ui/icons/Loop";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { v1 as uuidv1 } from "uuid";
import {
  addNewClientTraderEmail,
  deleteClientTraderEmailDetails,
  getClientTraderEmailDetails,
  switchClientTraderEmail,
  updateClientTraderEmailDetails,
  updateEmailListMakorX,
} from "../utils/apiCalls";
import * as actionSnackBar from "../../../../store/snackbar/action";
import { useDispatch } from "react-redux";
import { ReactComponent as Copy } from "../../../../assets/copy.svg";
import { findIndex } from "lodash";

export const Contact = ({
  pickedTraderObj,
  searchTraderValue,
  searchClientValue,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [openStatus, setOpenStatus] = useState({});
  const [traderEmailsList, setTraderEmailsList] = useState([]);
  const [toEmailList, setToEmailList] = useState([]);
  const [ccEmailList, setCcEmailList] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [hoveredId, setHoveredId] = useState(null);
  const [isEditing, setIsEditing] = useState(null);
  const [editedEmailValue, setEditedEmailValue] = useState("");
  const inputRef = useRef(null);

  const toggleOpen = async (fieldName) => {
    try {
      setOpenStatus((prev) => ({
        ...prev,
        [fieldName]: !prev[fieldName],
      }));
      setIsEditing(null);
      setSelectedId(null);
      setEditedEmailValue("");
      const emailIndexToRemove = (
        fieldName === "recipient" ? toEmailList : ccEmailList
      ).findIndex((i) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        const emailRegexResult = emailRegex.test(i.email);
        const notEmptyStringRegexResult = i.email.trim() !== "";
        return !emailRegexResult || !notEmptyStringRegexResult;
      });
      if (emailIndexToRemove !== -1) {
        const array = [
          ...(fieldName === "recipient" ? toEmailList : ccEmailList),
        ];
        array.splice(emailIndexToRemove, 1);
        (fieldName === "recipient" ? setToEmailList : setCcEmailList)(array);
        await deleteClientTraderEmailDetails(array[emailIndexToRemove].rowId);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onEmailPick = (email) => {
    setSelectedId(email.id);
  };

  const setEditedEmail = (e, i) => {
    setEditedEmailValue(e.target.value);
  };
  const handleEditClick = (item) => {
    setIsEditing(item.id);
    setEditedEmailValue(item.email);
  };

  const updateEmailChange = useCallback(
    async (id, type) => {
      try {
        let emailList, setEmailList;
        if (type === "to") {
          emailList = toEmailList;
          setEmailList = setToEmailList;
        } else if (type === "cc") {
          emailList = ccEmailList;
          setEmailList = setCcEmailList;
        } else {
          emailList = [];
          setEmailList = () => {};
        }
        const index = emailList.findIndex((item) => item.id === id);

        if (index !== -1) {
          const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
          const emailRegexResult = emailRegex.test(editedEmailValue);
          const notEmptyString = editedEmailValue.trim() !== "";

          // Update the email API
          if (emailRegexResult && notEmptyString) {
            await updateClientTraderEmailDetails({
              ...emailList[index],
              email: editedEmailValue,
            });
            await updateEmailListMakorX(
              pickedTraderObj,
              emailList.map((item, idx) =>
                idx === index ? { ...item, email: editedEmailValue } : item,
              ),
              type === "to" ? ccEmailList : toEmailList,
            );
            setIsEditing(null);
            setEditedEmailValue("");
          } else if (
            !emailRegexResult ||
            (!emailRegexResult && !notEmptyString)
          ) {
            setEditedEmailValue(emailList[index].email);
            setTimeout(() => {
              inputRef.current.focus();
              inputRef.current.select();
            }, [100]);

            dispatch(
              actionSnackBar.setSnackBar(
                "error",
                "Please enter a valid email address",
                3000,
              ),
            );
          }
          // Update the email list
          setEmailList((prev) => {
            const updatedList = [...prev];
            updatedList[index].email = emailRegexResult
              ? editedEmailValue
              : updatedList[index].email;

            return updatedList;
          });
        }
      } catch (error) {
        dispatch(actionSnackBar.setSnackBar("error", error.message, 3000));
      }
    },
    [inputRef, ccEmailList, toEmailList, editedEmailValue],
  );
  const handleKeyPress = (e, id, type) => {
    if (e.key === "Enter") {
      updateEmailChange(id, type);
    }
  };
  const addNewEmail = async (type) => {
    try {
      const res = await addNewClientTraderEmail({
        clientId: pickedTraderObj.client_id,
        traderId: pickedTraderObj.id,
        email: "Fill in new email ...",
        id: uuidv1(),
        type: type === "to" ? "to" : type === "cc" ? "cc" : null,
      });

      (type === "to"
        ? setToEmailList
        : type === "cc"
        ? setCcEmailList
        : () => {})((prev) => {
        const updatedList = [...prev];
        updatedList.unshift(res.emailObj);
        setSelectedId(updatedList[0].id);
        setIsEditing(updatedList[0].id);
        setEditedEmailValue(updatedList[0].email);
        return updatedList;
      });
    } catch (error) {
      dispatch(actionSnackBar.setSnackBar("error", error.message, 3000));
    }
  };

  const handleDelete = async (e, id, type) => {
    try {
      // Determine the relevant list and setter based on the type
      let emailList, setEmailList;
      if (type === "to") {
        emailList = toEmailList;
        setEmailList = setToEmailList;
      } else if (type === "cc") {
        emailList = ccEmailList;
        setEmailList = setCcEmailList;
      } else {
        emailList = [];
        setEmailList = () => {};
      }

      // Find the index of the item with the given id
      const index = emailList.findIndex((item) => item.id === id);

      if (index !== -1) {
        // Delete the email detail
        await deleteClientTraderEmailDetails(emailList[index].rowId);

        // Update the email list
        const updatedList = [...emailList];
        updatedList.splice(index, 1);
        setEmailList(updatedList);
        await updateEmailListMakorX(
          pickedTraderObj,
          updatedList,
          type === "to" ? ccEmailList : toEmailList,
        );
        // Reset the editing state
        setIsEditing(null);
        setSelectedId(null);
        setEditedEmailValue("");
      }
    } catch (error) {
      dispatch(actionSnackBar.setSnackBar("error", error.message, 3000));
    }
  };
  const handleSwitch = async (e, id, type) => {
    try {
      let emailListToRemove,
        emailListToAdd,
        removeFromEmailList,
        addToEmailList;

      if (type === "cc") {
        emailListToRemove = toEmailList;
        emailListToAdd = ccEmailList;
        removeFromEmailList = setToEmailList;
        addToEmailList = setCcEmailList;
      } else if (type === "to") {
        emailListToRemove = ccEmailList;
        emailListToAdd = toEmailList;
        removeFromEmailList = setCcEmailList;
        addToEmailList = setToEmailList;
      }

      const index = emailListToRemove.findIndex((item) => item.id === id);

      if (index !== -1) {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        const emailRegexResult = emailRegex.test(
          emailListToRemove[index].email,
        );
        const notEmptyStringRegexResult =
          emailListToRemove[index].email.trim() !== "";
        if (emailRegexResult && notEmptyStringRegexResult) {
          await switchClientTraderEmail(emailListToRemove[index].rowId, type);
          emailListToAdd.unshift(emailListToRemove[index]);
          addToEmailList(emailListToAdd);
          emailListToRemove.splice(index, 1);
          removeFromEmailList(emailListToRemove);

          setOpenStatus({
            recipient:
              (type === "cc" ? emailListToRemove : emailListToAdd).length > 0
                ? true
                : false,
            cc:
              (type === "to" ? emailListToRemove : emailListToAdd).length > 0
                ? true
                : false,
          });
          setIsEditing(null);
          setHoveredId(null);
          setSelectedId(null);
          setEditedEmailValue("");
        }
      }
    } catch (error) {
      dispatch(actionSnackBar.setSnackBar("error", error.message, 3000));
    }
  };
  const handleCopyEmail = (e, email) => {
    navigator.clipboard.writeText(email);
  };

  useEffect(() => {
    if (pickedTraderObj !== null) {
      const { client_id, id } = pickedTraderObj;
      getClientTraderEmailDetails(
        client_id,
        id,
        setToEmailList,
        setCcEmailList,
        setOpenStatus,
      );
    }
  }, [pickedTraderObj]);

  useEffect(() => {
    // Focus the input when isEditing matches selectedId
    if (isEditing === selectedId && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isEditing, selectedId]);

  useEffect(() => {
    setOpenStatus([]);
    setToEmailList([]);
    setCcEmailList([]);
  }, [searchTraderValue, searchClientValue]);

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      className={classes.staticColumn1}
      xs={6}
    >
      <Grid item className={classes.grayHeaderContainer} xs={12}>
        <Typography className={classes.grayHeader}>Contact</Typography>
      </Grid>
      {/* recipient */}
      <Grid item className={classes.itemListContainer} xs={12}>
        <Typography
          className={classes.companyTypography}
          onClick={() => toggleOpen("recipient")}
        >
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              toggleOpen("recipient");
            }}
          >
            {openStatus["recipient"] ? (
              <IndeterminateCheckBoxRoundedIcon
                className={classes.companiesIsOpenIcon}
              />
            ) : (
              <AddBoxRoundedIcon className={classes.companiesIsOpenIcon} />
            )}
          </IconButton>
          To
        </Typography>
        <Collapse in={openStatus["recipient"]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Grid className={classes.itemsListEmails}>
              {toEmailList.map((item, i) => {
                return (
                  <Grid className={classes.itemListContainer} key={i}>
                    <ListItem
                      button
                      onClick={() => onEmailPick(item)}
                      selected={selectedId === item.id}
                      className={classes.listItemSelected}
                      onMouseEnter={() => setHoveredId(item.id)}
                      onMouseLeave={() => setHoveredId(null)}
                    >
                      {isEditing === item.id && selectedId === item.id ? (
                        <input
                          type="text"
                          value={editedEmailValue}
                          onChange={(e) => setEditedEmail(e, i)}
                          onBlur={() => updateEmailChange(item.id, "to")}
                          onKeyPress={(e) => handleKeyPress(e, item.id, "to")}
                          className={classes.emailInput}
                          ref={inputRef}
                        />
                      ) : (
                        <ListItemText
                          className={classes.listItemText}
                          primary={item.email}
                        />
                      )}
                      {selectedId === item.id || hoveredId === item.id ? (
                        <>
                          <CreateIcon
                            className={classes.createIcon}
                            fontSize={"large"}
                            onClick={() => handleEditClick(item)}
                          />
                          <DeleteIcon
                            className={classes.deleteIcon}
                            fontSize={"large"}
                            onClick={(e) => handleDelete(e, item.id, "to")}
                          />
                          <LoopIcon
                            className={classes.switchIcon}
                            fontSize={"large"}
                            onClick={(e) => handleSwitch(e, item.id, "cc")}
                          />
                          <Copy
                            className={classes.deleteIcon}
                            fontSize={"large"}
                            onClick={(e) => handleCopyEmail(e, item.email)}
                          />
                        </>
                      ) : null}
                    </ListItem>
                  </Grid>
                );
              })}
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={11}>
                <Button
                  disableElevation
                  variant="contained"
                  color="primary"
                  className={classes.addContactBtn}
                  onClick={() => addNewEmail("to")}
                >
                  + Add Contact
                </Button>
              </Grid>
            </Grid>
          </List>
        </Collapse>
      </Grid>

      {/* CC */}
      <Grid item className={classes.itemListContainer} xs={12}>
        <Typography
          className={classes.companyTypography}
          onClick={() => toggleOpen("cc")}
        >
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              toggleOpen("cc");
            }}
          >
            {openStatus["cc"] ? (
              <IndeterminateCheckBoxRoundedIcon
                className={classes.companiesIsOpenIcon}
              />
            ) : (
              <AddBoxRoundedIcon className={classes.companiesIsOpenIcon} />
            )}
          </IconButton>
          CC
        </Typography>
        <Collapse in={openStatus["cc"]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Grid className={classes.itemsListEmails}>
              {ccEmailList.map((item, i) => {
                return (
                  <Grid className={classes.itemListContainer} key={i}>
                    <ListItem
                      button
                      onClick={() => onEmailPick(item)}
                      selected={selectedId === item.id}
                      className={classes.listItemSelected}
                      onMouseEnter={() => setHoveredId(item.rowId)}
                      onMouseLeave={() => setHoveredId(null)}
                    >
                      {isEditing === item.id && selectedId === item.id ? (
                        <input
                          type="text"
                          value={editedEmailValue}
                          onChange={(e) => setEditedEmail(e, i)}
                          onBlur={() => updateEmailChange(item.id, "cc")}
                          onKeyPress={(e) => handleKeyPress(e, item.id, "cc")}
                          className={classes.emailInput}
                          ref={inputRef}
                        />
                      ) : (
                        <ListItemText
                          className={classes.listItemText}
                          primary={item.email}
                        />
                      )}
                      {selectedId === item.id || hoveredId === item.rowId ? (
                        <>
                          <CreateIcon
                            className={classes.createIcon}
                            fontSize={"large"}
                            onClick={() => handleEditClick(item)}
                          />
                          <DeleteIcon
                            className={classes.deleteIcon}
                            fontSize={"large"}
                            onClick={(e) => handleDelete(e, item.id, "cc")}
                          />
                          <LoopIcon
                            className={classes.switchIcon}
                            fontSize={"large"}
                            onClick={(e) => handleSwitch(e, item.id, "to")}
                          />
                          <Copy
                            className={classes.deleteIcon}
                            fontSize={"large"}
                            onClick={(e) => handleCopyEmail(e, item.email)}
                          />
                        </>
                      ) : null}
                    </ListItem>
                  </Grid>
                );
              })}
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={11}>
                <Button
                  disableElevation
                  variant="contained"
                  color="primary"
                  className={classes.addContactBtn}
                  onClick={() => addNewEmail("cc")}
                >
                  + Add Contact
                </Button>
              </Grid>
            </Grid>
          </List>
        </Collapse>
      </Grid>
    </Grid>
  );
};
