import { useStyles } from "../../../../Styles/pricerStyle";
import React, { useState } from "react";
import { IconButton, Typography } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { getParticipationValue } from "../utils/utilsFunc";
import { StripedTableRow, WhiteFontTableCell } from "../style/styledComponents";

export const Row = ({ row, key, tabValue }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const clients =
    tabValue === "history"
      ? row.client_traders
          .replace(/[\[\]']/g, "")
          .toLowerCase() // Convert the whole string to lowercase
          .split(" ") // Split the string into an array of words
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
          .join(" ")
      : null;

  return (
    <>
      <StripedTableRow
        key={key}
        index={key}
        className={classes.historyTableRow}
      >
        <WhiteFontTableCell className={classes.historyTableRow}>
          {tabValue === "history" ? (
            <Typography variant="h6" fontWeight="bold">
              {row.client !== null
                ? row.client
                    .toLowerCase() // Convert the whole string to lowercase
                    .split(" ") // Split the string into an array of words
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
                    .join(" ")
                : ""}
            </Typography>
          ) : tabValue === "marketMaker" ? (
            <Typography>{row.market_maker}</Typography>
          ) : null}
        </WhiteFontTableCell>
        <WhiteFontTableCell className={classes.historyTableRow}>
          {tabValue === "history" ? (
            <Typography variant="h6" fontWeight="bold">
              {clients.length > 40 ? (
                <div>
                  <span>
                    {open ? clients : clients.substring(0, 40) + " ... "}
                  </span>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                    style={{ color: "white" }}
                  >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </div>
              ) : (
                clients
              )}
            </Typography>
          ) : tabValue === "marketMaker" ? (
            <Typography>{row.pod}</Typography>
          ) : null}
        </WhiteFontTableCell>
        <WhiteFontTableCell className={classes.historyTableRow}>
          {tabValue === "history" ? (
            <Typography variant="h6" fontWeight="bold">
              {typeof row.participation === "number"
                ? getParticipationValue(row.participation)
                : ""}
            </Typography>
          ) : tabValue === "marketMaker" ? (
            <Typography>{row.contact}</Typography>
          ) : null}
        </WhiteFontTableCell>
      </StripedTableRow>
    </>
  );
};
