import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  sendEmailBtn: {
    width: "85px",
    height: "100px",
    backgroundColor: "#2692FF",
    color: "white",
    "&:disabled": {
      backgroundColor: "#66b0fa",
      color: "white",
    },
  },
  toCcBtn: {
    "&:disabled": {
      height: "40px",
      backgroundColor: "#2692FF",
      color: "white",
      width: "50px",
    },
  },
  subjectBtn: {
    "&:disabled": {
      height: "40px",
      backgroundColor: "none",
      background: "none",
      color: "#686b76",
      width: "50px",
    },
  },
  rootTabs: {
    flexGrow: 1,
  },
  tabs: {
    backgroundColor: "transparent",
    color: "white",
    borderBottom: "1px solid white",
    borderTop: "none",
  },
  tabPanel: {
    backgroundColor: "transparent",
    color: "white",
  },
  appBar: {
    width: "100%",
    backgroundColor: "transparent",
    boxShadow:
      "-2px 2px 4px -1px rgba(0,0,0,0.2), -2px 4px 5px 0px rgba(0,0,0,0.14), -2px 1px 10px 0px rgba(0,0,0,0.12)",
  },
  notSelectedTab: {
    // borderRight: "1px solid #686B76",
    "&::before": {
      content: '""',
      position: "absolute",
      right: 0,
      top: "10%", // Adjust the top value to position the border
      bottom: "10%", // Adjust the bottom value to control the height
      borderLeft: "1px solid #686B76",
      width: 0,
    },
  },
  selectedTab: {
    backgroundColor: "#2692FF",
  },
  indicator: {
    backgroundColor: "white",
  },

  composeInputs: {
    "& .MuiInputBase-input": {
      fontSize: "1.3rem",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white", // Change the underline color to white when focused
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#686B76", // Change the underline color to white when focused
    },
    "&:hover .MuiInput-underline:before": {
      borderBottomColor: "#A0A4B0", // Change the underline color to white on hover
    },
    "& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl":
      {
        paddingLeft: "10px",
      },
  },
  bodyContainer: {
    marginTop: "25px",
    width: "95%",
    backgroundColor: "white",
    height: "600px",
    overflow: "auto",
  },

  table: {
    backgroundColor: "white !important", // White background for the table
    color: "black !important", // Black font color
    border: "none",
    borderCollapse: "collapse",
    marginTop: "20px",
    marginBottom: "20px",
  },
  cell: {
    width: "auto", // Adjust this as needed
    backgroundColor: "white !important", // White background for the table
    color: "black !important", // Black font color
    border: "1px solid #ddd",
    textAlign: "center !important",
  },
  firstCellOg: {
    width: "auto", // Adjust this as needed
    backgroundColor: "white !important", // White background for the table
    color: "black !important", // Black font color
    border: "1px solid #ddd",
    borderTop: "1px solid #00a73f !important",
    textAlign: "center !important",
  },
  firstCellMakor: {
    width: "auto", // Adjust this as needed
    backgroundColor: "white !important", // White background for the table
    color: "black !important", // Black font color
    border: "1px solid #ddd",
    borderTop: "1px solid #430c8c !important",
    textAlign: "center !important",
  },
  headerCellOg: {
    width: "auto", // Adjust this as needed
    backgroundColor: "white !important", // White background for the table
    color: "black !important", // Black font color
    border: "1px solid #00a73f !important",
    whiteSpace: "nowrap",
    textAlign: "center !important",
  },
  headerCellMakor: {
    width: "auto", // Adjust this as needed
    backgroundColor: "white !important", // White background for the table
    color: "black !important", // Black font color
    border: "1px solid #430c8c !important",
    whiteSpace: "nowrap",
    textAlign: "center !important",
  },
  progressContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%", // This height will be relative to the Grid container
    width: "100%", // Ensures the container takes full width
  },
}));
