import {
  Grid,
  IconButton,
  Typography,
  Divider,
  useMediaQuery,
} from "@material-ui/core";
import {
  StyledTextField,
  LightTooltip,
  CommentBox,
  SubjectBox,
} from "../../../../Styles/emailStyles";
import SendIcon from "@material-ui/icons/Send";
import { useStyles } from "./FxEmailContent.styles";
import SuccessFailAnimation from "../../../fi/reusableComponents/SuccessFailAnimation";
function FxEmailContentView(props) {
  const {
    subject,
    tooltip,
    rfqEmail,
    sendEmail,
    setTooltip,
    sendStatus,
    editSubject,
    singleConfo,
    clientIndex,
    thereIsConfo,
    traderComment,
    currentClient,
    bottomComment,
    startSendEmail,
    generateComment,
    ccEmailAddresses,
    allocationsArray,
    toEmailAddresses,
    handleEmailAddressAdd,
  } = props;
  const customeView = useMediaQuery("(width:1280px)");
  const classes = useStyles();
  
  return (
    <Grid item xs={12} style={{ paddingTop: 10 }}>
      <Grid container>
        <Grid item>
          <IconButton
            disabled={startSendEmail}
            onClick={() => sendEmail()}
            className={classes.sendButton}
          >
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                {startSendEmail ? (
                  <SuccessFailAnimation
                    padding={20}
                    status={sendStatus}
                    loaderClass={"loader"}
                  />
                ) : (
                  <SendIcon />
                )}
              </Grid>
              <Grid item style={{ marginTop: startSendEmail ? 50 : 0 }}>
                {sendStatus !== "start" ? (
                  <Typography style={{ fontSize: "1.3125rem" }}>
                    {!startSendEmail
                      ? "Send"
                      : sendStatus === "success"
                      ? "Sent"
                      : sendStatus === "fail"
                      ? "Failed"
                      : "Send"}
                  </Typography>
                ) : (
                  <div className="sendingAnim">Sending</div>
                  // <HorizontalLoading title="Sending" styling="sendingAnim"/>
                )}
              </Grid>
            </Grid>
          </IconButton>
        </Grid>
        <Grid
          item
          container
          xs={10}
          style={{ marginLeft: 10, alignContent: "space-between" }}
        >
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <Grid
                item
                style={{
                  border: "1px solid #E1E2E7",
                  borderRadius: 4,
                  padding: "4px 10px",
                }}
              >
                <Typography>To: </Typography>
              </Grid>
              <Grid item xs={11}>
                <StyledTextField
                  type="email"
                  pattern=".+@globex\.com"
                  value={toEmailAddresses?.join(";")}
                  onChange={(event) => handleEmailAddressAdd("to", event)}
                  style={{ marginLeft: 10, marginTop: 5 }}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={8} style={{paddingLeft : "46px"}}>
                  {
                    toEmailAddresses !== null && toEmailAddresses !== undefined ? 
                    <span style={{color : validationSpan(toEmailAddresses).validation ? "green" :"red",height: "13px"}}>{validationSpan(toEmailAddresses).text}</span> 
                    :null
                  }
                </Grid> */}
          <Grid item xs={12} style={{ paddingTop: "10px" }}>
            <Grid item container xs={12}>
              <Grid
                item
                style={{
                  border: "1px solid #E1E2E7",
                  borderRadius: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 38,
                }}
              >
                <Typography>CC: </Typography>
              </Grid>
              <Grid item xs={11}>
                <StyledTextField
                  type="email"
                  pattern=".+@globex\.com"
                  value={ccEmailAddresses?.join(";")}
                  style={{ marginLeft: 10, marginTop: 5 }}
                  onChange={(event) => handleEmailAddressAdd("cc", event)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item xs={8} style={{paddingLeft : "126px",minHeight: "13px"}}>
                  {
                    ccEmailAddresses !== null && ccEmailAddresses !== undefined? 
                    <span style={{color : validationSpan(ccEmailAddresses).validation ? "green" :"red"}}>{validationSpan(ccEmailAddresses).text}</span> 
                    :null
                  }
                </Grid> */}
        <Grid item xs={12}>
          <Grid container xs={12} style={{ paddingTop: 20 }}>
            <Grid item container alignItems="center">
              <Grid item style={{ paddingRight: "21px" }}>
                <Typography style={{ color: "#686B76", textAlign: "center" }}>
                  Subject:
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <SubjectBox
                  value={
                    subject === undefined || subject === ""
                      ? rfqEmail?.subject
                      : subject
                  }
                  onChange={(e) => editSubject(e)}
                  disabled={thereIsConfo}
                  id="commentBox"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              style={{ marginTop: "10px" }}
              alignItems="center"
            >
              <Grid item style={{ paddingRight: "9px" }}>
                <Typography style={{ color: "#686B76", textAlign: "center" }}>
                  Comment:
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <CommentBox
                  disabled={thereIsConfo}
                  id="commentBox"
                  value={traderComment}
                  maxRows="5"
                  onChange={(e) => generateComment(e)}
                  multiline={true}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              style={{ marginTop: "10px" }}
              alignItems="center"
            >
              <Grid item style={{ paddingRight: "9px" }}>
                <Typography style={{ color: "#686B76", textAlign: "center" }}>
                  Bottom Comment:
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <CommentBox
                  disabled={thereIsConfo}
                  id="commentBox"
                  value={bottomComment}
                  maxRows="5"
                  onChange={(e) => generateComment(e, "bottom")}
                  multiline={true}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider
                style={{
                  backgroundColor: "#bababa",
                  marginTop: 10,
                }}
              />
            </Grid>
            <LightTooltip
              open={tooltip.open}
              title={tooltip.title}
              followCursor
              placement="right-start"
            >
              <Grid
                item
                xs={12}
                id="emailWrapper"
                style={{
                  marginTop: 15,
                  minHeight: customeView ? "411px" : "567px",
                  maxHeight: customeView ? "411px" : "567px",
                  minWidth: "80%",
                  position: "relative",
                  zIndex: 1,
                  overflow: "auto",
                  paddingRight: 5,
                }}
                className="emailToSend"
                onClick={() => setTooltip({ ...tooltip, open: !tooltip.open })}
              >
                <div className="emailPreview">
                  {allocationsArray.length > 0 &&
                    singleConfo &&
                    !currentClient[clientIndex]?.includes("(MM)") && (
                      <div style={{ width: "650px" }}>
                        <h4 style={{ textAlign: "center" }}>
                          ***THIS TRADE HAS SPLIT ALLOCATIONS***
                        </h4>
                      </div>
                    )}
                  {traderComment && (
                    <div style={{ whiteSpace: "pre-line" }}>
                      <table>
                        <tr
                          style={{
                            backgroundColor: "#ffffff",
                            color: "#000000",
                          }}
                        >
                          <td>
                            <pre>{traderComment}</pre>
                          </td>
                        </tr>
                      </table>
                    </div>
                  )}
                  {traderComment !== "" ||
                    (rfqEmail?.trader_comment !== "" && (
                      <div style={{ whiteSpace: "pre-line" }}>
                        {rfqEmail?.trader_comment
                          ? rfqEmail.trader_comment
                          : traderComment}
                      </div>
                    ))}
                  {singleConfo &&
                    currentClient[clientIndex]?.includes("(MM)") === false && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: rfqEmail?.allocationTable,
                        }}
                      />
                    )}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: rfqEmail?.companyHeader,
                    }}
                  />

                  <div dangerouslySetInnerHTML={{ __html: rfqEmail?.header }} />

                  {rfqEmail?.rfq_legs?.map((leg, index) => {
                    return (
                      <div
                        key={index}
                        dangerouslySetInnerHTML={{ __html: leg }}
                      />
                    );
                  })}
                  {rfqEmail?.hedgesTables?.map((hedge, index) => {
                    return (
                      <div
                        key={index}
                        dangerouslySetInnerHTML={{ __html: hedge }}
                      />
                    );
                  })}
                  {bottomComment && (
                    <div style={{ whiteSpace: "pre-line" }}>
                      <table>
                        <tr
                          style={{
                            backgroundColor: "#ffffff",
                            color: "#000000",
                          }}
                        >
                          <td>
                            <pre>{bottomComment}</pre>
                          </td>
                        </tr>
                      </table>
                    </div>
                  )}
                  {bottomComment !== "" ||
                    (rfqEmail?.bottom_comment !== "" && (
                      <div style={{ whiteSpace: "pre-line" }}>
                        {rfqEmail?.bottom_comment
                          ? rfqEmail.bottom_comment
                          : bottomComment}
                      </div>
                    ))}
                  <div
                    dangerouslySetInnerHTML={{ __html: rfqEmail?.regards }}
                  />
                  <br />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: rfqEmail?.disclaimer,
                    }}
                  />
                </div>
              </Grid>
            </LightTooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default FxEmailContentView;
