import axios from "axios";
import { useEffect, useState } from "react";
import { END_POINT } from "../../../utils";
import ClientsView from "./Clients.view";
import * as actionSnackBar from "../../../store/snackbar/action";
import { useDispatch } from "react-redux";
import { isSameDay } from "date-fns/esm";

function Clients() {
  const dispatch = useDispatch();
  const [clientsData, setClientsData] = useState();
  const [sortedClientsData, setSortedClientsData] = useState();
  const [isASD, setIsASD] = useState(true);
  const [loadingData, setLoadingData] = useState(false);

  const getClientsData = async () => {
    const token = sessionStorage.getItem("token");
    try {
      setLoadingData(true);
      let res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}${END_POINT.DATA_MGMT}/clients`,
        { headers: { Authorization: token } }
      );
      setClientsData(res.data);
      setSortedClientsData(res.data);
      setTimeout(() => {
        setLoadingData(false);
      }, [500]);
    } catch (error) {
      console.log(error);
    }
  };

  const insertEmptyClient = () => {
    let newClient = {
      client_id: null,
      client_name: "",
      short_name: "",
      company: "",
      agreement: "",
      default_email: null,
      pm_ids: null,
      pm_names: null,
      pmsList: clientsData[0].pmsList.map((pm) => {
        return { ...pm, checked: false };
      }),
    };
    setClientsData([newClient, ...clientsData]);
    setSortedClientsData([newClient, ...sortedClientsData]);
  };

  const updateTableWhenDelete = (clientIndex) => {
    let newArray = clientsData.filter((client, index) => index !== clientIndex);
    setClientsData(newArray);
    setSortedClientsData(newArray);
  };

  const search = (e) => {
    if (e.target.value === "") {
      setSortedClientsData(clientsData);
    } else {
      let filterd = sortedClientsData.filter(
        (mm) =>
          mm.client_name.toLowerCase().includes(e.target.value) ||
          mm.short_name.toLowerCase().includes(e.target.value)
      );
      setSortedClientsData(filterd);
    }
  };

  const deleteClient = async (clientId, clientIndex) => {
    let newClientData = clientsData.filter(
      (client, index) => index !== clientIndex || client.client_id !== clientId
    );
    setClientsData(newClientData);
    setSortedClientsData(newClientData);
    const token = sessionStorage.getItem("token");
    try {
      let res = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}${END_POINT.DATA_MGMT}/delete_client`,
        {
          headers: {
            Authorization: token,
          },
          data: {
            clientId: clientId,
          },
        }
      );
      dispatch(actionSnackBar.setSnackBar("success", res.data, 3000));
      let newClientData = clientsData.filter(
        (client) => client.client_id !== clientId
      );
      setClientsData(newClientData);
    } catch (error) {
      dispatch(actionSnackBar.setSnackBar("error", error, 3000));
    }

    return "deleted";
  };

  const handleSorting = (field) => {
    if (field === "PB") {
      field = "company";
    } else if (field === "Short Name") {
      field = "short_name";
    } else if (field === "Client Name") {
      field = "client_name";
    } else if (field === "Agreement") {
      field = "agreement";
    }
    if (field) {
      const sorted = [...clientsData].sort((a, b) => {
        return (
          a[field]?.toString().localeCompare(b[field]?.toString(), "en", {
            numeric: true,
          }) * (isASD ? 1 : -1)
        );
      });
      setIsASD(!isASD);
      setSortedClientsData(sorted);
    }
  };

  useEffect(() => {
    getClientsData();
  }, []);

  return (
    <ClientsView
      search={search}
      getClientsData={getClientsData}
      sortedClientsData={sortedClientsData}
      clientsData={clientsData}
      loadingData={loadingData}
      insertEmptyClient={insertEmptyClient}
      updateTableWhenDelete={updateTableWhenDelete}
      deleteClient={deleteClient}
      handleSorting={handleSorting}
    />
  );
}

export default Clients;
