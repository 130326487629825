export const bidAskRefAgg = (params) => {
  // gridRef.current.api.forEachNode((node) => {
  //   console.log(node);
  // })
  // console.log({ gridApi, gridRef });
  const values = params.values;
  const allValid = values.every((value) => {
    return value !== undefined && value !== "";
  });

  if (allValid) {
    const sum = values.reduce((acc, val, index) => acc + val, 0);
    // return the aggregate value
    if (!isNaN(sum)) {

      return sum.toFixed(2);
    }
  }
};
