import {
  getUniqueCompanies,
  cellEditorSelector,
  getClientsByCompany,
} from "./cellEditorSelector.js";

export const buildRowData = async (
  parsedBookingText,
  setRowData,
  makorStaticData,
  rowData,
  order,
) => {
  const headers = [
    "received_at",
    "completed_at",
    "executed_at",
    "trade_type",
    "side",
    "cleared_by",
    "client",
    "trader",
    "clearance_account",
    "send_conformation",
    "initiator",
    "initiation_fee",
    "is_market_maker",
    "market_maker_fee",
    "options",
    "hedges",
    "floor_broker",
    "fee_currency",
    "crossed_fee_rate",
    "not_crossed_fee_rate",
    "leg_fee_rate",
    "hedge_fee_rate",
    "commission",
    "floor_broker_fee",
    "execution_fee",
    "initiation",
    "market_maker",
    "net",
  ];
  let crossedFeeRate = 0,
    notCrossedFeeRate = 0;
  const mappedRowData = await Promise.all(
    headers.map((header, idx) => {
      if (header !== "options" && header !== "hedges") {
        const rowObj = {
          id: header,
          [`columnHeader`]: header
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
        };
        if (
          header === "received_at" ||
          header === "completed_at" ||
          header === "executed_at" ||
          header === "trade_type"
        ) {
          parsedBookingText.executions.forEach((exec, i) => {
            rowObj[`exec${i + 1}`] = parsedBookingText[`${header}`];
          });
        } else if (header === "side") {
          parsedBookingText.executions.forEach((exec, i) => {
            rowObj[`exec${i + 1}`] = parsedBookingText.executions[i].side;
          });
        } else if (header === "cleared_by") {
          parsedBookingText.executions.forEach((exec, i) => {
            rowObj[`exec${i + 1}`] = "oscar gruss";
          });
          rowObj.clearedByData = getUniqueCompanies(makorStaticData.clients);
        } else if (header === "client") {
          parsedBookingText.executions.forEach((exec, i) => {
            rowObj[`exec${i + 1}`] = null;
          });
          rowObj.clientsData = getClientsByCompany(makorStaticData.clients);
        } else if (header === "trader") {
          parsedBookingText.executions.forEach((exec, i) => {
            rowObj[`exec${i + 1}`] = null;
          });
          rowObj.clientsData = makorStaticData;
        } else if (header === "clearance_account") {
          parsedBookingText.executions.forEach((exec, i) => {
            rowObj[`exec${i + 1}`] = null;
          });
        } else if (header === "send_conformation") {
          parsedBookingText.executions.forEach((exec, i) => {
            rowObj[`exec${i + 1}`] = false;
          });
          rowObj.clientsData = makorStaticData;
        } else if (header === "initiator") {
          parsedBookingText.executions.forEach((exec, i) => {
            if (i === 0) {
              rowObj[`exec${i + 1}`] = true;
            } else {
              rowObj[`exec${i + 1}`] = false;
            }
          });
        } else if (header === "is_market_maker") {
          parsedBookingText.executions.forEach((exec, i) => {
            rowObj[`exec${i + 1}`] = false;
          });
        } else if (
          header === "initiation_fee" ||
          header === "market_maker_fee"
        ) {
          parsedBookingText.executions.forEach((exec, i) => {
            rowObj[`exec${i + 1}`] =
              header === "initiation_fee"
                ? 0.34
                : header === "market_maker_fee"
                ? 0.33
                : null;
          });
        } else if (header === "floor_broker") {
          parsedBookingText.executions.forEach((exec, i) => {
            rowObj[`exec${i + 1}`] = order.equityIndex.includes("Equity")
              ? "Mando"
              : order.equityIndex.includes("Index")
              ? parsedBookingText.floor_broker
              : null;
          });
        } else if (header === "fee_currency") {
          parsedBookingText.executions.forEach((exec, i) => {
            rowObj[`exec${i + 1}`] = "USD";
          });
        } else if (header === "crossed_fee_rate") {
          parsedBookingText.executions.forEach((exec, i) => {
            const floorBroker = order.equityIndex
              .toLowerCase()
              .includes("index")
              ? parsedBookingText.floor_broker
              : "Mando";
            const floorBrokerObj =
              makorStaticData.drv_trade_floor_brokers.filter(
                (fb) => fb.name.toLowerCase() === floorBroker.toLowerCase(),
              );
            if (floorBrokerObj.length > 0) {
              const filteredFee =
                floorBrokerObj[0].drv_trade_floor_broker_fees.filter(
                  (fee) =>
                    fee.name.toLowerCase().includes("crossed") &&
                    !fee.name.toLowerCase().includes("not") &&
                    (order.equityIndex.toLowerCase().includes("index") &&
                    order.symbol.toLowerCase() !== "vix"
                      ? fee.name.toLowerCase().includes("index")
                      : true) &&
                    (order.symbol.toLowerCase() === "vix"
                      ? fee.name.toLowerCase().includes("vix")
                      : true),
                );

              if (filteredFee.length > 0) {
                rowObj[`exec${i + 1}`] = filteredFee[0]?.pivot?.fee_rate;
                crossedFeeRate = filteredFee[0]?.pivot?.fee_rate;
              }
            }
          });
        } else if (header === "not_crossed_fee_rate") {
          parsedBookingText.executions.forEach((exec, i) => {
            const floorBroker = order.equityIndex
              .toLowerCase()
              .includes("index")
              ? parsedBookingText.floor_broker
              : "Mando";
            const floorBrokerObj =
              makorStaticData.drv_trade_floor_brokers.filter(
                (fb) => fb.name.toLowerCase() === floorBroker.toLowerCase(),
              );
            if (floorBrokerObj.length > 0) {
              const filteredFee =
                floorBrokerObj[0].drv_trade_floor_broker_fees.filter(
                  (fee) =>
                    fee.name.toLowerCase().includes("crossed") &&
                    fee.name.toLowerCase().includes("not") &&
                    (order.equityIndex.toLowerCase().includes("index") &&
                    order.symbol.toLowerCase() !== "vix"
                      ? fee.name.toLowerCase().includes("index")
                      : true) &&
                    (order.symbol.toLowerCase() === "vix"
                      ? fee.name.toLowerCase().includes("vix")
                      : true),
                );
              if (filteredFee.length > 0) {
                rowObj[`exec${i + 1}`] = filteredFee[0]?.pivot?.fee_rate;
                notCrossedFeeRate = filteredFee[0]?.pivot?.fee_rate;
              }
            }
          });
        } else if (header === "leg_fee_rate") {
          parsedBookingText.executions.forEach((exec, i) => {
            rowObj[`exec${i + 1}`] = 0;
          });
        } else if (header === "hedge_fee_rate") {
          parsedBookingText.executions.forEach((exec, i) => {
            rowObj[`exec${i + 1}`] = 0;
          });
        } else if (header === "commission") {
          parsedBookingText.executions.forEach((exec, i) => {
            rowObj[`exec${i + 1}`] = 0;
          });
        } else if (header === "floor_broker_fee") {
          let totalQuantityBuy = 0;
          let totalQuantitySell = 0;
          // Aggregate total quantities for options and hedges across all clients

          parsedBookingText.executions.forEach((exec) => {
            exec.option.forEach((option) => {
              if (exec?.side?.toLowerCase() === "b") {
                totalQuantityBuy += option.quantity;
              } else if (exec?.side?.toLowerCase() === "s") {
                totalQuantitySell += option.quantity;
              }
            });
            exec.hedge.forEach((hedge) => {
              if (exec?.side?.toLowerCase() === "b") {
                totalQuantityBuy += hedge.quantity;
              } else if (exec?.side?.toLowerCase() === "s") {
                totalQuantitySell += hedge.quantity;
              }
            });
          });

          const totalCrossed = Math.min(totalQuantityBuy, totalQuantitySell);
          let availableCrossed = totalCrossed;
          const totalNotCrossed = Math.abs(
            totalQuantityBuy - totalQuantitySell,
          );

          let sideNotCrossed = null,
            sideCrossed = null;

          if (totalQuantityBuy >= totalQuantitySell) {
            sideNotCrossed = "b";
            sideCrossed = "s";
          } else {
            sideNotCrossed = "s";
            sideCrossed = "b";
          }

          parsedBookingText.executions.forEach((exec, i) => {
            let crossedFee, notCrossedFee;
            let clientQuantity = 0;
            exec.option.forEach((option) => {
              clientQuantity += option.quantity;
            });
            exec.hedge.forEach((hedge) => {
              clientQuantity += hedge.quantity;
            });

            if (exec?.side?.toLowerCase() === sideNotCrossed.toLowerCase()) {
              const crossedQuantity = Math.min(
                clientQuantity,
                availableCrossed,
              );
              availableCrossed -= crossedQuantity;
              const notCrossedQuantity = Math.abs(
                clientQuantity - crossedQuantity,
              );
              crossedFee = crossedFeeRate * crossedQuantity;
              notCrossedFee = notCrossedFeeRate * notCrossedQuantity;
            } else if (exec?.side?.toLowerCase() === sideCrossed.toLowerCase()) {
              crossedFee = crossedFeeRate * clientQuantity;
              notCrossedFee = 0;
            }
            rowObj[`exec${i + 1}`] = Number(
              (crossedFee + notCrossedFee).toFixed(2),
            );

            const floorBrokerFeeRate =
              (crossedFee + notCrossedFee) / clientQuantity;

            rowObj["floorBrokerFeeRate"] =
              rowObj["floorBrokerFeeRate"] === undefined
                ? { [i]: floorBrokerFeeRate.toFixed(2) }
                : {
                    ...rowObj["floorBrokerFeeRate"],
                    [i]: floorBrokerFeeRate.toFixed(2),
                  };
          });
        } else if (header === "execution_fee") {
          parsedBookingText.executions.forEach((exec, i) => {
            rowObj[`exec${i + 1}`] = 0;
          });
        } else if (header === "initiation") {
          parsedBookingText.executions.forEach((exec, i) => {
            rowObj[`exec${i + 1}`] = 0;
          });
        } else if (header === "market_maker") {
          parsedBookingText.executions.forEach((exec, i) => {
            rowObj[`exec${i + 1}`] = 0;
          });
        } else if (header === "net") {
          parsedBookingText.executions.forEach((exec, i) => {
            rowObj[`exec${i + 1}`] = 0;
          });
        }

        return rowObj;
      } else if (header === "options") {
        const optionsHeaders = [
          "option",
          "call_put",
          "strike",
          "expiry",
          "side_option",
          "quantity",
          "price",
        ];
        const optionsArray = parsedBookingText.executions.map((exec) => {
          return exec.option;
        });
        const optionsBlocksArr = [];

        const maxLengthArr = findMaxLengthArr(optionsArray);
        maxLengthArr.forEach((obj, blockIndex) => {
          //option block
          optionsHeaders.forEach((header, headerIndex) => {
            const rowObj = {
              id: header,
              [`columnHeader`]:
                header !== "call_put" && header !== "option"
                  ? header
                      .split("_")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1),
                      )
                      .join(" ")
                  : header === "option"
                  ? `Option ${blockIndex + 1}`
                  : header === "call_put"
                  ? "Call/Put"
                  : null,
            };

            parsedBookingText.executions.forEach((exec, execIndex) => {
              if (parsedBookingText.executions[execIndex]?.option[blockIndex]) {
                if (header === "call_put") {
                  // console.log(
                  //   parsedBookingText.executions[execIndex]?.option[blockIndex],
                  // );
                  rowObj[`exec${execIndex + 1}`] =
                    parsedBookingText.executions[execIndex]?.option[
                      blockIndex
                    ].call_put;
                } else if (
                  header === "strike" ||
                  header === "quantity" ||
                  header === "price"
                ) {
                  rowObj[`exec${execIndex + 1}`] =
                    parsedBookingText.executions[execIndex]?.option[blockIndex][
                      header
                    ];
                } else if (header === "side_option") {
                  rowObj[`exec${execIndex + 1}`] =
                    parsedBookingText.executions[execIndex]?.option[
                      blockIndex
                    ].side;
                } else if (header === "expiry") {
                  // if (order.legs[blockIndex] !== undefined) {
                  const { expiry_day, expiry_month, expiry_year } =
                    parsedBookingText.executions[0]?.option[blockIndex];
                  rowObj[`exec${execIndex + 1}`] = formatDate(
                    expiry_day,
                    expiry_month,
                    expiry_year,
                  );
                  // } else {
                  //   rowObj[`exec${execIndex + 1}`] = null;
                  // }
                } else {
                  rowObj[`exec${execIndex + 1}`] = null;
                }
              }
            });
            optionsBlocksArr.push(rowObj);
          });
        });

        return optionsBlocksArr.map((r, i) => {
          return {
            ...r,
            id: `option_${r.id}_${i}`,
          };
        }); // Return optionsBlocksArr directly
      } else if (header === "hedges") {
        let hedgesHeaders = ["hedge", "side_hedge", "quantity", "price"];

        if (order.equityIndex.toLowerCase().includes("index")) {
          hedgesHeaders = [
            "hedge",
            "call_put",
            "strike",
            "expiry",
            "side_hedge",
            "quantity",
            "price",
          ];
        }
        const hedgesArray = parsedBookingText.executions.map((exec) => {
          return exec.hedge;
        });
        const hedgesBlocksArr = [];

        const maxLengthArr = findMaxLengthArr(hedgesArray);
        maxLengthArr.forEach((obj, blockIndex) => {
          //option block
          hedgesHeaders.forEach((header, headerIndex) => {
            const rowObj = {
              id: header,
              [`columnHeader`]:
                header !== "call_put" && header !== "hedge"
                  ? header
                      .split("_")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1),
                      )
                      .join(" ")
                  : header === "hedge"
                  ? `Hedge ${blockIndex + 1}`
                  : header === "call_put"
                  ? "Call/Put"
                  : null,
            };

            parsedBookingText.executions.forEach((exec, execIndex) => {
              if (parsedBookingText.executions[execIndex]?.hedge[blockIndex]) {
                if (header === "call_put") {
                  rowObj[`exec${execIndex + 1}`] =
                    parsedBookingText.executions[execIndex]?.hedge[
                      blockIndex
                    ].call_put;
                } else if (header === "side_hedge") {
                  rowObj[`exec${execIndex + 1}`] =
                    parsedBookingText.executions[execIndex]?.hedge[
                      blockIndex
                    ].side;
                } else if (header === "expiry") {
                  // if (order.legs[blockIndex] !== undefined) {
                  const { expiry_day, expiry_month, expiry_year } =
                    parsedBookingText.executions[0]?.hedge[blockIndex];
                  rowObj[`exec${execIndex + 1}`] = formatDate(
                    expiry_day,
                    expiry_month,
                    expiry_year,
                  );
                  // } else {
                  //   rowObj[`exec${execIndex + 1}`] = null;
                  // }
                } else if (header !== "hedge" && header !== "side_hedge") {
                  rowObj[`exec${execIndex + 1}`] =
                    parsedBookingText.executions[execIndex]?.hedge[blockIndex][
                      header
                    ];
                } else {
                  rowObj[`exec${execIndex + 1}`] = null;
                }
              }
            });
            hedgesBlocksArr.push(rowObj);
          });
        });

        return hedgesBlocksArr.map((r, i) => {
          return {
            ...r,
            id: `hedge_${r.id}_${i}`,
          };
        });
      }
    }),
  );
  const finalRowData = [].concat(...mappedRowData);
  setRowData(finalRowData);
};

export const findMaxLengthArr = (arrays) => {
  let maxLength = -1;
  let maxArray = null;

  for (const array of arrays) {
    if (Array.isArray(array) && array.length > maxLength) {
      maxLength = array.length;
      maxArray = array;
    }
  }
  return maxArray;
};

export const formatDate = (expiryDay, expiryMonth, expiryYear) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Assuming expiryMonth is in the range 1-12
  const formattedMonth = months[expiryMonth - 1];
  const formattedYear = expiryYear.toString().slice(-2); // Take the last two digits of the year

  return `${expiryDay}-${formattedMonth}-${formattedYear}`;
};
