export const tablesConvertor = (execKeys, bookingTable, parsedBookingText) => {
  if (execKeys !== undefined && bookingTable !== undefined) {
    const bookingTableByExec = execKeys.map((exec, i) => {
      const obj = {};
      bookingTable
        .filter(
          (row) =>
            row.id === "hedge_fee_rate" ||
            (!row.id.includes("option") && !row.id.includes("hedge")),
        )
        .forEach((row) => {
          obj[`${row.columnHeader}`.toLowerCase().replaceAll(" ", "_")] =
            row[`${exec}`];
        });

      const options = createExecArray(
        bookingTable.filter((row) => row.id.includes("option")),
        exec,
        "option_option",
        parsedBookingText.executions[i].option,
      );

      const hedges = createExecArray(
        bookingTable.filter(
          (row) => row.id.includes("hedge") && row.id !== "hedge_fee_rate",
        ),
        exec,
        "hedge_hedge",
        parsedBookingText.executions[i].hedge,
      );

      obj.options = options;
      obj.hedges = hedges;

      return obj;
    });

    return bookingTableByExec;
  }
};

export const createExecArray = (array, exec, type, execParsedOption) => {
  const execArray = [];
  let currentExec = null;
  array.forEach((obj, index) => {
    if (obj.id.includes(type)) {
      if (currentExec !== null) {
        execArray.push(currentExec); 
      }
      currentExec = {}; 
    } else {
      if (currentExec === null) {
        currentExec = {};
      }
      currentExec[
        obj.id
          .replace(/option_?/, "")
          .replace(/hedge_?/, "")
          .replace(/_\d+$/, "")
      ] = obj[exec]; 
    }
  });

  if (currentExec !== null) {
    execArray.push(currentExec); 
  }

  return execArray.map((e, i) => {
    return {
      ...e,
      ticker: execParsedOption[i].ticker,
    };
  });
};
