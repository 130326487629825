import { Button, ButtonGroup, Grid, makeStyles } from "@material-ui/core";
import { Typography } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";
import Clients from "./Clients/Clients";
import MarketMakers from "./Market_makers/MarketMakers";
import PmManagment from "./PMS/PmManagment";

function DataMgmtMain() {
  const classes = useStyles();
  const [chosenSettings, setChosenSettings] = useState("pms");
  return (
    <Grid container spacing={1} style={{ paddingTop: "20px" }}>
      <Grid item xs={12}>
        <Typography className={classes.rfqHeader}>FX Client Static</Typography>
      </Grid>
      <Grid item xs={12} container>
        <ButtonGroup size="large">
          <Button
            onClick={() => setChosenSettings("pms")}
            className={clsx(
              chosenSettings === "pms" ? classes.selectedBtn : classes.btns
            )}
          >
            Pms
          </Button>
          <Button
            onClick={() => setChosenSettings("clients")}
            className={clsx(
              chosenSettings === "clients" ? classes.selectedBtn : classes.btns
            )}
          >
            Clients
          </Button>
          <Button
            onClick={() => setChosenSettings("marketMakers")}
            className={clsx(
              chosenSettings === "marketMakers"
                ? classes.selectedBtn
                : classes.btns
            )}
          >
            Market Makers
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid item container>
        {chosenSettings === "pms" ? (
          <Grid container justifyContent="space-between">
            <Grid item xs={12}>
              <PmManagment />
            </Grid>
          </Grid>
        ) : chosenSettings === "clients" ? (
          <Clients />
        ) : (
          <MarketMakers />
        )}
      </Grid>
    </Grid>
  );
}

export default DataMgmtMain;

export const useStyles = makeStyles((theme) => ({
  rfqHeader: {
    fontSize: "20px",
    marginTop: "15px",
    marginBottom: "10px",
    textAlign: "left",
    font: "normal normal normal 2.125rem/2.1875rem Inter !important",
    letterSpacing: " 0px",
    color: "#ffffff",
    opacity: "1",
  },
  selectedBtn: {
    backgroundColor: "#12AD64",
    color: "#ffffff",
    fontSize: "1.125rem",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#12AD64",
      color: "#ffffff",
      border: "1px solid #12AD64",
    },
  },
  btns: {
    color: "#12AD64",
    fontSize: "1.125rem",
    textTransform: "none",
    backgroundColor: "transparent",
    border: "1px solid #12AD64",
    "&:hover": {
      backgroundColor: "#12AD64",
      color: "#ffffff",
    },
  },
}));
