import {
  Box,
  Button,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import * as rfqSlice from "../../../../../store/rfq/rfqSlice";
import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent as CloseIcon } from "../../../../../assets/X.svg";
import { useDispatch, useSelector } from "react-redux";
import { tabsClasses } from "@mui/material";

const RfqWorkspaceHeaderTabs = ({ navigateRfqs, deleteRfq }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const rfqInstanceList = useSelector(
    (state) => state.rfqSlice.rfqInstanceList,
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (
      sessionStorage.getItem("status") === "newRfq" ||
      sessionStorage.getItem("status") === "copy"
    ) {
      setValue(rfqInstanceList.length - 1);
    } else {
      const index = rfqInstanceList.findIndex(
        (tab) => tab.tabIsChosen === true,
      );
      if (index !== -1) {
        setValue(index);
      }
    }
  }, []);

  useEffect(() => {
    const index = rfqInstanceList.findIndex((tab) => tab.tabIsChosen === true);
    if (index !== -1) {
      setValue(index);
    }
  }, [rfqInstanceList]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (key, e) => {
    setAnchorEl(null);
    if (key !== "close menu") {
      dispatch(rfqSlice.handleCloseTabs(key, navigateRfqs, e));
    }
    setValue(0);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid
      className={classes.rfqtabs}
      container
      item
      display={"flex"}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item xs={11} style={{ maxWidth: "97%", flexBasis: "97%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          aria-label="visible arrows tabs example"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": { opacity: 0.3 },
            },
          }}
          className={classes.rfqTab}
        >
          {rfqInstanceList.length > 0
            ? rfqInstanceList.map((i, rfqIndex) => {
                return (
                  <Tab
                    label={i.rfq_description}
                    icon={
                      <CloseIcon onClick={(e) => deleteRfq(e, rfqIndex, i)} />
                    }
                    onClick={(e) => navigateRfqs(e, i, rfqIndex)}
                  ></Tab>
                );
              })
            : null}
        </Tabs>
      </Grid>
      <Grid xs={1} style={{ maxWidth: "3%", flexBasis: "3%" }}>
        <Button onClick={handleClick}>
          <MenuIcon
            style={{ color: "#686B76", width: "30px", height: "30px" }}
          />
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={(e) => handleClose("close menu", e)}
        >
          <MenuItem
            className={classes.tabsMenu}
            onClick={(e) => handleClose("close all", e)}
          >
            Close all
          </MenuItem>
          <MenuItem
            className={classes.tabsMenu}
            onClick={(e) => handleClose("close other tabs", e)}
          >
            Close other tabs
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
};

export default RfqWorkspaceHeaderTabs;

export const useStyles = makeStyles(() => ({
  tabsMenu: {
    fontSize: "1.5rem",
  },
  rfqtabs: {
    width: "100%",
    borderBottom: "1px solid #686B76",
  },
  rfqTab: {
    "& .MuiTab-root": {
      borderRadius: "8px",
      marginRight: "10px",
      backgroundColor: "##686B76",
      background: "#2D2D2D 0% 0% no-repeat padding-box",
    },
    "& .MuiTab-root:hover": {
      backgroundColor: "#89b8e8",
    },

    "& .MuiTab-labelIcon": {
      minHeight: "44px",
      minWidth: "220px",
    },
    "& .MuiTab-wrapper": {
      color: "#fff",
      font: "normal normal normal 14px/18px Inter",
      marginRight: "25px",
    },
    "& 	.Mui-selected": {
      backgroundColor: "#2692ff",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "white",
    },
    "& .MuiTabs-scrollButtons.Mui-disabled": {
      opacity: "0.3",
    },

    "& .MuiTab-labelIcon .MuiTab-wrapper > *:first-child": {
      position: "absolute",
      right: "15px",
      top: "17px",
      transform: "scale(1.4)",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
      width: "40px",
      height: "40px",
    },
  },
}));
