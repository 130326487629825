import { Grid, IconButton, Typography } from "@material-ui/core";
import { ReactComponent as Trash } from "../../../../assets/trash.svg";
import { ReactComponent as Ignore } from "../../../../assets/X.svg";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useStyles } from "../../../../Styles/pricerStyle";
import workerInstances from "../../../../services/index";
import CustomAnimationRenderer from "../../custom renderer/CustomAnimationRenderer";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "ag-grid-enterprise";
import { InView } from "react-intersection-observer";
import {
  subscribeOrdersToSocket,
  unsubscribeFromSocket,
} from "../utils/socketCalls";
import { testDateWithinRange } from "../../derivsPricerBlotter/utils/utilsFunctions";
import { removeOrderFromCustomList } from "../../derivsPricerBlotter/utils/apiCalls";
import LinearProgress from "@material-ui/core/LinearProgress";
import clsx from "clsx";
import "./style.css";
import axios from "axios";
import StarOutlineRoundedIcon from "@material-ui/icons/StarOutlineRounded";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import { valueFormatterSelector } from "../../priceTableByOrder/utils/valueFormatterSelector";
import {
  deleteOrder,
  favouritesHandler,
} from "../../priceTableByOrder/utils/apiCalls";
import {
  orderAskRefValueGetter,
  orderBidRefValueGetter,
  // orderBidRefValueGetter,
  orderMidRefValueGetter,
} from "../../priceTableByOrder/utils/valueGetter";
import { tieListCreator } from "../../priceTableByOrder/utils";
import { bidAskRefAgg } from "../../priceTableByOrder/utils/aggFunctions";

export const MiniFeedTable = ({
  order,
  ordersList,
  setOrdersList,
  strategiesList,
  setStrategiesList,
  isScrolling,
}) => {
  const classes = useStyles();
  const gridRef = useRef();
  const containerRef = useRef(null);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState(order.legs);
  const [legsValues, setLegValues] = useState(order.legs);
  const [underlineDetails, setUnderlineDetails] = useState(order);
  const [summaryRow, setSummaryRow] = useState(null);
  const [firstLoad, setFirstLoad] = useState(0);
  const [tieList, setTieList] = useState(null);
  const [isFlicker, setIsFlicker] = useState(false);

  const handleIgnoreOrder = async (order) => {
    const copyOrderList = [...ordersList];
    const index = ordersList.findIndex(
      (o) => Number(o.id) === Number(order.id),
    );
    if (index !== -1) {
      removeOrderFromCustomList(order.id);
      unsubscribeFromSocket(order);
      copyOrderList.splice(index, 1);
      setOrdersList(copyOrderList);
    }
  };

  const handleLegUpdate = useCallback(
    async (orderId, legNum, rowIndex, colId, value) => {
      if (Number(orderId) === Number(order.id)) {
        const copyLegsValues = [...legsValues];
        const copyStrategiesList = [...strategiesList];
        copyLegsValues.splice(rowIndex, 1, {
          ...legsValues[rowIndex],
          [`${colId}`]: Number(value),
        });
        const index = copyStrategiesList.findIndex(
          (s) => Number(s.id) === Number(orderId),
        );
        if (index !== -1) {
          copyStrategiesList[index] = {
            ...copyStrategiesList[index],
            legs: copyLegsValues,
          };
          setStrategiesList(copyStrategiesList);
        }

        setLegValues([...copyLegsValues]);
        unsubscribeFromSocket(order);

        subscribeOrdersToSocket([{ ...order, legs: copyLegsValues }]);
      }
    },
    [ordersList, strategiesList, underlineDetails, legsValues],
  );

  const updateTie = useCallback(
    (message) => {
      const { id, tieValue } = message.data.updateBody;

      // Update strategiesList
      setStrategiesList((prevStrategiesList) => {
        const copyStrategiesList = [...prevStrategiesList];
        const index = copyStrategiesList.findIndex(
          (s) => Number(s.id) === Number(id),
        );
        if (index !== -1) {
          copyStrategiesList[index] = {
            ...copyStrategiesList[index],
            tie: tieValue,
          };
        }
        return copyStrategiesList;
      });

      // Update ordersList
      setOrdersList((prevOrdersList) => {
        const copyOrdersList = [...prevOrdersList];
        const ordersListIndex = copyOrdersList.findIndex(
          (s) => Number(s.id) === Number(id),
        );
        if (ordersListIndex !== -1) {
          copyOrdersList[ordersListIndex] = {
            ...copyOrdersList[ordersListIndex],
            tie: tieValue,
          };
        }
        return copyOrdersList;
      });

      // Update underlineDetails if necessary
      if (Number(order.id) === Number(id)) {
        setUnderlineDetails((prevDetails) => ({
          ...prevDetails,
          tie: tieValue,
        }));
      }
    },
    [setStrategiesList, setOrdersList, setUnderlineDetails],
  );

  const updateTieByLeg = useCallback(
    (message) => {
      const { id, tieValueByLeg, legNumArr } = message.data.updateBody;
      if (Number(id) === Number(order.id)) {
        let copyLegsValues = null;

        setLegValues((pre) => {
          const arr = pre.map((leg) => {
            if (legNumArr.includes(leg.legNum)) {
              return { ...leg, tieByLeg: tieValueByLeg };
            } else return { ...leg };
          });
          copyLegsValues = [...arr];
          return arr;
        });

        setStrategiesList((prevStrategiesList) => {
          const copyStrategiesList = [...prevStrategiesList];
          const index = copyStrategiesList.findIndex(
            (s) => Number(s.id) === Number(id),
          );
          if (index !== -1) {
            copyStrategiesList[index] = {
              ...copyStrategiesList[index],
              legs: copyLegsValues,
            };
          }
          return copyStrategiesList;
        });

        setOrdersList((prevOrdersList) => {
          const copyOrdersList = [...prevOrdersList];
          const ordersListIndex = copyOrdersList.findIndex(
            (s) => Number(s.id) === Number(id),
          );

          if (ordersListIndex !== -1) {
            copyOrdersList[ordersListIndex] = {
              ...copyOrdersList[ordersListIndex],
              legs: copyLegsValues,
            };
          }
          return copyOrdersList;
        });

        unsubscribeFromSocket(order);

        subscribeOrdersToSocket([{ ...order, legs: copyLegsValues }]);
      }
    },
    [
      legsValues,
      ordersList,
      strategiesList,
      setStrategiesList,
      setOrdersList,
      setUnderlineDetails,
    ],
  );

  const handleOptionTickerUpdate = useCallback(
    async (orderId, legNum, rowIndex, colId, value) => {
      if (Number(orderId) === Number(order.id)) {
        const copyLegsValues = [...legsValues].map((leg) => {
          const optionTickerArr = leg.optionTicker.split(" ");
          optionTickerArr[0] = value.split(" ")[0];
          return { ...leg, optionTicker: optionTickerArr.join(" ") };
        });
        const copyStrategiesList = [...strategiesList];

        const index = copyStrategiesList.findIndex(
          (s) => Number(s.id) === Number(orderId),
        );
        if (index !== -1) {
          copyStrategiesList[index] = {
            ...copyStrategiesList[index],
            legs: copyLegsValues,
          };
          setStrategiesList(copyStrategiesList);
        }

        setLegValues([...copyLegsValues]);
        unsubscribeFromSocket(order);

        subscribeOrdersToSocket([{ ...order, legs: copyLegsValues }]);
      }
    },
    [ordersList, strategiesList, underlineDetails, legsValues],
  );

  const handleInView = async (inView, order, entry) => {
    setUnderlineDetails((pre) => ({ ...pre, inView }));

    if (inView && underlineDetails.lastPrice === undefined) {
      if (firstLoad < 7) {
        setFirstLoad(firstLoad + 1);
      }
      const { symbol, equityIndex, futureTicker } = order;
      const tickerString = (
        futureTicker !== null
          ? futureTicker + " " + equityIndex
          : symbol.replaceAll("/", "%252f") + " " + equityIndex
      )
        .replaceAll("/", "|")
        .replaceAll(" ", "%20");
      const res = await axios.get(
        `${
          process.env.REACT_APP_BLOOMBERG_DERIVS
        }equity/${tickerString.toUpperCase()}`,
      );
      const {
        div_date,
        name,
        price,
        report_date,
        sector,
        ticker,
        has_adr,
        country,
      } = res.data;
      const dividend = await testDateWithinRange(div_date);
      const earnings = await testDateWithinRange(report_date);
      const updatedOrder = {
        ...order,
        dividend,
        name,
        lastPrice: price,
        earnings,
        sector,
        ticker,
        has_adr,
        country,
        inView,
      };
      setUnderlineDetails(updatedOrder);
    } else if (inView && order.isNewTimestamp !== undefined) {
      subscribeOrdersToSocket([order]);
    }
  };

  // agGrid functions
  const getSummaryRowData = (order) => {
    if (order.name === undefined) {
      return;
    } else {
      let summaryString = `${order.name}  ${
        order.sector !== "" ? `|  ${order.sector}` : ""
      } ${order.has_adr === "Y" ? ` | ${order.country}` : ""} ${
        order.futureTicker !== null ? ` | ${order.futureTicker}` : ""
      }`;
      const wordToColorFutureTicker =
        order.futureTicker !== null ? `${order.futureTicker}` : null;
      const wordToColorHasAdr =
        order.has_adr === "Y" ? `${order.country}` : null;

      let strWithColor =
        wordToColorFutureTicker !== null || wordToColorHasAdr !== null
          ? summaryString.split(" ").map((word, i) => {
              if (
                (word === wordToColorFutureTicker &&
                  wordToColorFutureTicker !== null) ||
                (word === wordToColorHasAdr && wordToColorHasAdr !== null)
              ) {
                return (
                  <>
                    <span key={i} style={{ color: "#FFA800" }}>
                      {wordToColorHasAdr !== null ? `ADR ${word}` : `${word}`}
                    </span>
                  </>
                );
              } else return word + " ";
            })
          : summaryString;
      if (strWithColor.includes("undefined")) {
        return;
      }
      setSummaryRow(strWithColor);
    }
  };

  const gridStyle = useMemo(
    () => ({
      width: "400px",
      maxWidth: "600px",
      minWidth: "600px",
      height: "60px",
      scrollbarWidth: "thin",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    [],
  );
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };
  const greeksAgg = (params) => {
    const values = params.values;
    const allValid = values.every((value) => {
      return value !== undefined && value !== "";
    });

    if (allValid) {
      const sum = values.reduce(
        (acc, val, index) =>
          acc + val * gridRef.current.props.rowData[index]?.quantity,
        0,
      );
      // return the aggregate value

      if (!isNaN(sum)) {
        if (
          params.colDef.field !== "VEGA_MID_RT" &&
          params.colDef.field !== "DELTA_MID_RT"
        ) {
          return `${(sum * 100).toFixed(2)}%`;
        } else if (params.colDef.field === "DELTA_MID_RT") {
          return `${sum.toFixed(2)}%`;
        } else {
          return `${sum.toFixed(3)}`;
        }
      }
    }
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      hide: true,
      field: "quantity",
      headerName: "Qty",
      minWidth: 70,
      editable: true,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },

    {
      hide: true,
      field: "callPut",
      headerName: "P/C",
      valueFormatter: valueFormatterSelector,
      minWidth: 60,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      hide: true,
      field: "strike",
      minWidth: 90,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },

    {
      field: "bidRef",
      minWidth: 60,
      maxWidth: 150,
      valueGetter: orderBidRefValueGetter,
      valueFormatter: valueFormatterSelector,
      aggFunc: bidAskRefAgg,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,

      cellStyle: (params) => {
        if (params.data === undefined) {
          return {
            border: "none",
            fontSize: "17px",
            color: "#FFA800",
          };
        } else {
          return {
            display: "none",
          };
        }
      },
    },

    {
      field: "askRef",
      minWidth: 60,
      maxWidth: 150,
      valueGetter: orderAskRefValueGetter,
      valueFormatter: valueFormatterSelector,
      aggFunc: bidAskRefAgg,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
      cellStyle: (params) => {
        if (params.data === undefined) {
          return {
            border: "none",
            fontSize: "17px",
            color: "#FFA800",
          };
        } else {
          return {
            display: "none",
          };
        }
      },
    },
    {
      field: "DELTA_MID_RT",
      headerName: "Delta",
      editable: true,

      minWidth: 60,
      maxWidth: 150,
      valueFormatter: valueFormatterSelector,
      aggFunc: greeksAgg,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
      cellStyle: (params) => {
        if (params.data === undefined) {
          return {
            border: "none",
            fontSize: "17px",
            color: "#FFA800",
          };
        } else {
          return {
            display: "none",
          };
        }
      },
    },
    {
      field: "midRef",
      minWidth: 60,
      maxWidth: 150,
      valueGetter: orderMidRefValueGetter,
      valueFormatter: valueFormatterSelector,
      aggFunc: bidAskRefAgg,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
      cellRendererFramework: CustomAnimationRenderer,
      cellStyle: (params) => {
        if (params.data === undefined) {
          return {
            border: "none",
            fontSize: "17px",
            color: "#FFA800",
          };
        } else {
          return {
            display: "none",
          };
        }
      },
    },

    {
      field: "ASK",
      hide: true,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "BID",
      hide: true,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
  ]);

  const getRowId = useCallback((params) => {
    return params.data.legNum;
  });
  const getRowHeight = (params) => (params.data === undefined ? 25 : 0);

  //useEffects

  //update order and legs when being changed outside the component (add / remove order)
  useEffect(() => {
    if (order.isNewTimestamp !== undefined) {
      setIsFlicker(true);
      setTimeout(() => {
        setIsFlicker(false);
        order.isNewTimestamp = undefined;
      }, [5000]);
    }
    setUnderlineDetails((pre) => {
      console.log("new order", { order, pre });
      return { ...pre, ...order };
    });
    setLegValues(order.legs);
    if (order.legs[0].futureTickerByLeg !== null) {
      setTieList(tieListCreator(order.legs));
    }
  }, [order]);
  // socket handling event listener Bloomberg
  useEffect(() => {
    const handleDerivs = async (message) => {
      if (
        message.data.security !== undefined &&
        message.data.mini_feed !== undefined &&
        message.data.order_id === order.id &&
        message.data.leg_id === undefined
      ) {
        setUnderlineDetails({
          ...underlineDetails,
          lastPrice: message.data.LAST_PRICE,
        });
      } else if (
        message.data.security !== undefined &&
        message.data.order_id === order.id &&
        message.data.mini_feed !== undefined &&
        message.data.leg_id !== undefined &&
        message.data.LAST_PRICE === undefined
      ) {
        const obj = {
          ...message.data,
        };
        gridApi.applyTransactionAsync(
          {
            update: [
              {
                ...obj,
                legLastPrice:
                  underlineDetails.legs[message.data.leg_id].legLastPrice !==
                  undefined
                    ? underlineDetails.legs[message.data.leg_id].legLastPrice
                    : null,
                lastPrice: underlineDetails.lastPrice,
                tie: underlineDetails.tie,
              },
            ],
          },
          (result) => {
            //  console.log("Transaction Result:", result);
          },
        );
      } else if (
        message.data.security !== undefined &&
        message.data.order_id === order.id &&
        message.data.leg_id !== undefined &&
        message.data.LAST_PRICE !== undefined
      ) {
        // const { data } = gridApi.getRowNode(message.data.leg_id + 1);

        setUnderlineDetails((pre) => {
          const legs = [...pre.legs];
          legs[message.data.leg_id].legLastPrice = message.data.LAST_PRICE;
          pre.legs = legs;
          return pre;
        });
      }
    };

    workerInstances?.WebSocketPricesInstance2?.addEventListener(
      "message",
      handleDerivs,
    );

    return () => {
      workerInstances?.WebSocketPricesInstance2?.removeEventListener(
        "message",
        handleDerivs,
      );
    };
  }, [gridApi, underlineDetails, legsValues, order]);
  // socket handling event listener
  useEffect(() => {
    const handleUpdate = async (message) => {
      switch (message.data.type) {
        case "update_leg":
          const { orderId, legNum, rowIndex, colId, value } =
            message.data.updateBody;

          handleLegUpdate(orderId, legNum, rowIndex, colId, value);
          break;
        case "update_tie_by_leg":
          updateTieByLeg(message);
          break;
        case "updateOptionTicker":
          {
            const { orderId, legNum, rowIndex, colId, value } =
              message.data.updateBody;

            handleOptionTickerUpdate(orderId, legNum, rowIndex, colId, value);
          }
          break;
        case "update_tie":
          updateTie(message);
          break;
        case "derivs_pricer_update_fav":
          const updateFav = () => {
            const { orderId, isFavorite } = message.data;

            // Update strategiesList
            setStrategiesList((prevStrategiesList) => {
              const index = prevStrategiesList.findIndex(
                (s) => Number(s.id) === Number(orderId),
              );
              if (index !== -1) {
                // If the item exists, update it
                const updatedStrategiesList = [...prevStrategiesList];
                updatedStrategiesList[index] = {
                  ...updatedStrategiesList[index],
                  isFavorite,
                };
                return updatedStrategiesList;
              }
              // If the item doesn't exist, return the previous list without changes
              return prevStrategiesList;
            });

            // Update ordersList
            setOrdersList((prevOrdersList) => {
              const ordersListIndex = prevOrdersList.findIndex(
                (s) => Number(s.id) === Number(orderId),
              );
              if (ordersListIndex !== -1) {
                // If the item exists, update it
                const updatedOrdersList = [...prevOrdersList];
                updatedOrdersList[ordersListIndex] = {
                  ...updatedOrdersList[ordersListIndex],
                  isFavorite,
                };
                return updatedOrdersList;
              }
              // If the item doesn't exist, return the previous list without changes
              return prevOrdersList;
            });

            // Update underlineDetails if necessary
            if (Number(order.id) === Number(orderId)) {
              setUnderlineDetails((prevDetails) => ({
                ...prevDetails,
                isFavorite,
              }));
            }
          };

          updateFav();
          break;
        default:
          break;
      }
    };
    window.addEventListener("message", handleUpdate);

    return () => {
      window.removeEventListener("message", handleUpdate);
    };
  }, [gridApi, underlineDetails]);

  useEffect(() => {
    // if (summaryRow === null) {
    getSummaryRowData(underlineDetails);
    // }
  }, [underlineDetails]);

  useEffect(() => {
    if (!isScrolling) {
      underlineDetails.inView && subscribeOrdersToSocket([order]);
    }
  }, [isScrolling]);

  useEffect(() => {
    underlineDetails.inView && subscribeOrdersToSocket([order]);
  }, [firstLoad]);

  // onclose && onRefresh Flush all pending socket updates
  useEffect(() => {
    const handleWindowClose = () => {
      // Handle the onClose event here
      gridRef.current.api.flushAsyncTransactions();
    };

    const handleWindowRefresh = (event) => {
      // Handle the onBeforeUnload event here
      //  event.preventDefault();
      //  return (event.returnValue = "Are you sure you want to leave this page?");
      gridRef.current.api.flushAsyncTransactions();
    };

    // Add event listeners
    window.addEventListener("beforeunload", handleWindowRefresh);
    window.addEventListener("unload", handleWindowClose);

    // Clean up the event listeners when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleWindowRefresh);
      window.removeEventListener("unload", handleWindowClose);
    };
  }, []);

  // show how many pending updates been flushed
  const onAsyncTransactionsFlushed = useCallback((e) => {
    // console.log(
    //   "========== onAsyncTransactionsFlushed: applied " +
    //     e.results.length +
    //     " transactions",
    // );
  }, []);

  return (
    <Grid
      ref={containerRef}
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      item
      className={clsx(classes.miniFeedMainContainer, {
        ["slide-flicker"]: isFlicker,
      })}
    >
      <InView
        key={order.id}
        as="div"
        onChange={(inView, entry) => handleInView(inView, order, entry)}
      >
        {/* symbol & buttons Grid */}
        <Grid container item xs={12}>
          {/* symbol Grid */}
          <Grid item xs={8} sm={8} container alignItems="center">
            <Typography className={classes.miniFeedUnderline}>
              {underlineDetails.strategy.toUpperCase()}
            </Typography>
          </Grid>
          {/* buttons Grid */}
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            xs={4}
            sm={4}
            spacing={4}
          >
            <Grid item className="trash-icon" style={{ marginRight: "10px" }}>
              {order.isFavorite ? (
                <IconButton
                  color="primary"
                  aria-label="fav-order"
                  onClick={(e) => favouritesHandler(order, "remove")}
                >
                  <StarRoundedIcon
                    fontSize="large"
                    color="secondary"
                    className={`${classes.starIcon} ${classes.fullStar}`}
                  />
                </IconButton>
              ) : (
                <IconButton
                  color="primary"
                  aria-label="fav-order"
                  onClick={(e) => favouritesHandler(order, "add")}
                >
                  <StarOutlineRoundedIcon
                    fontSize="large"
                    color="secondary"
                    className={`${classes.starIcon} ${classes.fullStar}`}
                  />
                </IconButton>
              )}
            </Grid>
            <Grid item className="trash-icon" style={{ marginRight: "10px" }}>
              <IconButton
                color="primary"
                aria-label="delete-order"
                onClick={(e) =>
                  deleteOrder({
                    id: order.id,
                    status: order.status,
                  })
                }
              >
                <Trash />
              </IconButton>
            </Grid>
            <Grid item className="trash-icon">
              <IconButton
                style={{
                  backgroundColor: "#3A3C3F",
                }}
                onClick={(e) => handleIgnoreOrder(order)}
              >
                <Ignore />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        {/* tie, last, dividend & earnings Grid */}
        <Grid container item xs={12}>
          {/* tie Grid */}

          {underlineDetails.legs[0].futureTickerByLeg !== null &&
          tieList !== null ? (
            <>
              {tieList?.map((obj) => {
                return (
                  <Grid
                    item
                    xs={2}
                    container
                    justifyContent="flex-start"
                    direction="row"
                    alignItems="flex-start"
                  >
                    <Grid>
                      <Typography className={classes.miniFeedUnderlineHeader}>
                        {obj.tieHeader}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography className={classes.miniFeedUnderline}>
                        {obj?.tieValue}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </>
          ) : (
            <Grid
              item
              xs={2}
              container
              justifyContent="flex-start"
              direction="column"
              alignItems="flex-start"
            >
              {" "}
              <Grid>
                <Typography className={classes.miniFeedUnderlineHeader}>
                  Tie:
                </Typography>
              </Grid>
              <Grid>
                <Typography className={classes.miniFeedUnderline}>
                  {underlineDetails.tie}
                </Typography>
              </Grid>{" "}
            </Grid>
          )}

          {/* last price Grid */}
          {underlineDetails.legs[0].futureTickerByLeg === null ? (
            <Grid
              item
              xs={2}
              container
              justifyContent="flex-start"
              direction="column"
              alignItems="flex-start"
            >
              <Grid item>
                <Typography className={classes.miniFeedUnderlineHeader}>
                  Last Price:
                </Typography>
              </Grid>
              <Grid>
                <Typography className={classes.miniFeedUnderline}>
                  {underlineDetails.lastPrice}
                </Typography>
              </Grid>
            </Grid>
          ) : null}

          {/* dividend & earnings Grid */}
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            xs={8}
          >
            {/* dividend Grid */}
            {underlineDetails.dividend === "" ||
            underlineDetails.dividend === undefined ? null : (
              <Grid
                item
                container
                justifyContent="flex-start"
                direction="column"
                alignItems="flex-start"
                xs={8}
                sm={4}
              >
                <Grid item>
                  <Typography className={classes.miniFeedUnderlineHeader}>
                    Dividend:
                  </Typography>
                </Grid>
                <Grid>
                  <Typography className={classes.miniFeedUnderline2}>
                    {underlineDetails.dividend}
                  </Typography>
                </Grid>
              </Grid>
            )}

            {/* earnings Grid */}
            {underlineDetails.earnings === "" ||
            underlineDetails.earnings === undefined ? null : (
              <Grid
                item
                container
                justifyContent="flex-start"
                direction="column"
                alignItems="flex-start"
                xs={8}
                sm={4}
              >
                <Grid>
                  <Typography className={classes.miniFeedUnderlineHeader}>
                    Earnings:
                  </Typography>
                </Grid>
                <Grid>
                  <Typography className={classes.miniFeedUnderline2}>
                    {underlineDetails.earnings}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        {/* agGrid */}
        {rowData.length > 0 ? (
          <Grid
            className="ag-theme-balham-dark ag-grid-custom"
            style={gridStyle}
            item
          >
            <AgGridReact
              getRowHeight={getRowHeight}
              ref={gridRef}
              rowData={legsValues}
              columnDefs={columnDefs}
              getRowId={getRowId}
              keepDetailRows={true}
              domLayout={"autoHeight"}
              onGridReady={onGridReady}
              animateRows={true}
              groupIncludeFooter={true}
              groupIncludeTotalFooter={true}
              onRowDataUpdated
              deltaRowDataMode={true}
              readOnlyEdit={true}
              singleClickEdit={true}
              onAsyncTransactionsFlushed={onAsyncTransactionsFlushed}
            ></AgGridReact>
          </Grid>
        ) : null}
        <Grid item>
          {summaryRow === null ? (
            <Grid className={classes.linearLoading}>
              <LinearProgress />
            </Grid>
          ) : (
            <Typography color="primary" className={classes.miniFeedUnderline}>
              {summaryRow}
            </Typography>
          )}
        </Grid>
      </InView>
    </Grid>
    //{" "}
  );
};
