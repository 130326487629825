import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  IconButton,
  TextareaAutosize,
  Paper,
} from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";
import UndoIcon from "@mui/icons-material/Undo";
import { useDispatch } from "react-redux";
import * as actionSnackBar from "../../../../../store/snackbar/action";
import { updateBookedOrderData } from "../utils/apiCalls";
import { ReactComponent as Launch } from "../../../../../assets/launch.svg";
import { useStyles } from "../../../../../Styles/pricerStyle";
import CircularProgress from "@material-ui/core/CircularProgress";

export const BookingTextEditor = ({
  label,
  bookedPayloadData,
  field,
  order,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [text, setText] = useState("");
  const [originalText, setOriginalText] = useState("");
  const [isSymphonySent, setIsSymphonySent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text).then(
      () => {
        dispatch(
          actionSnackBar.setSnackBar(
            "success",
            "Text copied to clipboard!",
            3000,
          ),
        );
      },
      (err) => {
        dispatch(
          actionSnackBar.setSnackBar("error", "Failed to copy text", 3000),
        );
      },
    );
  };

  const handleSaveText = async () => {
    setIsLoading(true);

    try {
      if (text.trim() === "") {
        dispatch(
          actionSnackBar.setSnackBar(
            "error",
            `Cannot save empty ${label} text.`,
            3000,
          ),
        );
        return;
      }
      const response = await updateBookedOrderData({
        field,
        value: text,
        orderId: order.id,
      });

      if (response && response.status) {
        setOriginalText(text);
        setIsSymphonySent(response.isSymphonySent);
        dispatch(
          actionSnackBar.setSnackBar(
            field === "symphonyText" && !response.isSymphonySent
              ? "error"
              : "success",
            field === "symphonyText"
              ? response.message
              : `${label} text updated successfully!`,
            3000,
          ),
        );
      } else {
        dispatch(
          actionSnackBar.setSnackBar(
            "error",
            field === "symphonyText"
              ? response.message
              : `Failed to update ${label} text.`,
            3000,
          ),
        );
      }
    } catch (error) {
      dispatch(
        actionSnackBar.setSnackBar(
          "error",
          `Failed to save ${label} text: ${error.message}`,
          3000,
        ),
      );
    }
    setIsLoading(false);
  };

  const handleRevertText = () => {
    setText(originalText);
  };

  useEffect(() => {
    if (bookedPayloadData) {
      const initialText = bookedPayloadData[field];
      setText(initialText);
      setOriginalText(initialText);
      setIsSymphonySent(bookedPayloadData.isSymphonySent);
    }
  }, [bookedPayloadData, field]);

  return (
    <Grid
      container
      spacing={2}
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item xs={12} style={{ marginTop: "20px" }}>
        <Paper
          elevation={3}
          style={{
            padding: 16,
            backgroundColor: "transparent",
            borderRadius: 8,
            width: "75%",
          }}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5" style={{ color: "#E0E0E0" }}>
              {label}
            </Typography>
            <Grid item>
              <IconButton
                onClick={handleSaveText}
                style={{
                  color:
                    field !== "symphonyText" && originalText === text
                      ? "#27AE60"
                      : field !== "symphonyText" && originalText !== text
                      ? "#C0C0C0"
                      : undefined,
                }}
                disabled={originalText === text && isSymphonySent}
              >
                {isLoading ? (
                  <CircularProgress size={24} style={{ color: "#ffffff" }} />
                ) : field === "symphonyText" ? (
                  <Launch
                    className={
                      originalText !== text || !isSymphonySent
                        ? `${classes.starIcon} ${classes.emptyBooked}`
                        : `${classes.starIcon} ${classes.fullBooked}`
                    }
                  />
                ) : (
                  <CheckIcon />
                )}
              </IconButton>
              <IconButton
                onClick={handleRevertText}
                style={{ color: "#E74C3C" }}
              >
                <UndoIcon />
              </IconButton>
              <IconButton onClick={handleCopy} style={{ color: "#5DADE2" }}>
                <ContentCopyIcon />
              </IconButton>
            </Grid>
          </Grid>
          <TextareaAutosize
            aria-label={`${label} Text Area`}
            minRows={6}
            maxRows={6}
            placeholder={`Enter ${label} Text`}
            style={{}}
            disabled={isLoading}
            value={text}
            onChange={(e) => setText(e.target.value)}
            className={classes.TextareaAutosize}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};
