export const get_vanilla_opt_dets = async (
  is_user_vol,
  col,
  rfqMode,
  jRef,
  keyVal_input_col,
  axios,
  set_cell_value,
) => {
  if (rfqMode !== "rfq booking view") {
    let table_data = jRef.current.jspreadsheet.getJson();
    try {
      for (
        let index = 0;
        index < jRef.current.jspreadsheet.headers.length - 1;
        index++
      ) {
        let strike = keyVal_input_col("STRIKE", index + 1);
        let expiry = keyVal_input_col("EXPIRY", index + 1);
        let ccyPair = keyVal_input_col("CCY_PAIR", index + 1);
        let getStrikePeiceFormBloomberg = await axios.get(
          `${process.env.REACT_APP_BLOOMBERG}` +
            `strikevol/${ccyPair}/${expiry}/${strike}`,
        );
        let strikePrice = Number(getStrikePeiceFormBloomberg.data.vol);
        set_cell_value(strikePrice.toFixed(4), "BBG_MID_VOL", index + 1);
        // set_cell_value(8.345, "BBG_MID_VOL", index + 1);
      }
      let response = await axios.post(
        `${process.env.REACT_APP_PYTON}` + `get_vanilla_opt_dets`,
        {
          is_user_vol: is_user_vol,
          opts_data: jRef.current.jspreadsheet.getJson(),
        },
      );
      let results = response.data;

      for (
        let index = 0;
        index < jRef.current.jspreadsheet.headers.length - 1;
        index++
      ) {
        set_cell_value(
          results.ui_dets[index].delta.toFixed(2),
          "DELTA",
          index + 1,
        );
        set_cell_value(
          results.ui_dets[index].hedge_amount.toFixed(2),
          "HEDGE_AMOUNT",
          index + 1,
        );
        set_cell_value(
          results.ui_dets[index].price_pct_notional.toFixed(4),
          "PRICE_PCT_NOTIONAL",
          index + 1,
        );
        set_cell_value(
          results.ui_dets[index].vega.toFixed(0),
          "VEGA",
          index + 1,
        );
        set_cell_value(
          results.ui_dets[index].client_price.toFixed(6),
          "CLIENT_PRICE",
          index + 1,
        );
        set_cell_value(
          results.ui_dets[index].market_price.toFixed(6),
          "MARKET_PRICE",
          index + 1,
        );
        set_cell_value(
          results.ui_dets[index].client_premium.toLocaleString(),
          "CLIENT_PREMIUM",
          index + 1,
        );
        set_cell_value(
          results.ui_dets[index].market_premium.toLocaleString(),
          "MARKET_PREMIUM",
          index + 1,
        );
        set_cell_value("", "NET_CLIENT_PRICE", index + 1);
        set_cell_value("", "NET_MARKET_PRICE", index + 1);
      }

      set_cell_value(
        results.net_prices.net_client_price.toFixed(6),
        "NET_CLIENT_PRICE",
        1,
      );
      set_cell_value(
        results.net_prices.net_market_price.toFixed(6),
        "NET_MARKET_PRICE",
        1,
      );
      for (
        let index = 1;
        index < jRef.current.jspreadsheet.headers.length;
        index++
      ) {
        if (index !== 1) {
          set_cell_value("", "HEDGE_TYPE", index);
        }
        set_cell_value("", "HEDGE_DELTA", index);
        set_cell_value("", "HEDGE_RATE", index);
        set_cell_value("", "HEDGE_VAL", index);
      }

      for (let idx = 0; idx < results.hedges.hedge_delta.length; idx++) {
        set_cell_value(results.hedges.hedge_delta[idx], "HEDGE_DELTA", idx + 1);
        set_cell_value(results.hedges.hedge_rate[idx], "HEDGE_RATE", idx + 1);
        set_cell_value(results.hedges.hedge_val[idx], "HEDGE_VAL", idx + 1);
      }
    } catch (error) {
      console.log(error);
    }
  }
};
export const get_exotic_opt_dets = async (
  col,
  jRef,
  set_cell_value,
  axios,
) => {
  let table_data = jRef.current.jspreadsheet.getJson();

  try {
    let response = await axios.post(
      `${process.env.REACT_APP_PYTON}` + `get_exotic_opt_dets`,
      { opts_data: table_data },
    );
    let results = response.data;

    for (
      let index = 0;
      index < jRef.current.jspreadsheet.headers.length - 1;
      index++
    ) {
      set_cell_value(0, "BBG_MID_VOL", index + 1);
      set_cell_value(0, "DELTA", index + 1);
      set_cell_value(0, "HEDGE_AMOUNT", index + 1);
      set_cell_value(0, "PRICE_PCT_NOTIONAL", index + 1);
      set_cell_value(0, "VEGA", index + 1);
      set_cell_value(0, "CLIENT_PRICE", index + 1);
      set_cell_value(0, "MARKET_PRICE", index + 1);
      set_cell_value(0, "CLIENT_PREMIUM", index + 1);
      set_cell_value(0, "MARKET_PREMIUM", index + 1);
      set_cell_value("", "NET_CLIENT_PRICE", index + 1);
      set_cell_value("", "NET_MARKET_PRICE", index + 1);
    }

    set_cell_value(0, "NET_CLIENT_PRICE", 1);
    set_cell_value(0, "NET_MARKET_PRICE", 1);

    for (let idx = 0; idx < results.hedges.hedge_delta.length; idx++) {
      if (results.hedges.hedge_match_type === "") {
        set_cell_value("MATCHED", "HEDGE_MATCH_TYPE", idx + 1);
      }
      if (results.hedges.hedge_match_type === "MATCHED") {
        set_cell_value("MATCHED", "HEDGE_MATCH_TYPE", idx + 1);
      }
      if (results.hedges.hedge_match_type === "UNMATCHED") {
        set_cell_value("UNMATCHED", "HEDGE_MATCH_TYPE", idx + 1);
      }
      set_cell_value(results.hedges.hedge_delta[idx], "HEDGE_DELTA", idx + 1);
      set_cell_value(results.hedges.hedge_rate[idx], "HEDGE_RATE", idx + 1);
      set_cell_value(results.hedges.hedge_val[idx], "HEDGE_VAL", idx + 1);

      set_cell_value(
        results.hedges.mm_hedge_delta[idx],
        "MM_HEDGE_DELTA",
        idx + 1,
      );
      set_cell_value(
        results.hedges.mm_hedge_rate[idx],
        "MM_HEDGE_RATE",
        idx + 1,
      );
      set_cell_value(results.hedges.mm_hedge_val[idx], "MM_HEDGE_VAL", idx + 1);
      set_cell_value(
        results.hedges.client_hedge_delta[idx],
        "CLIENT_HEDGE_DELTA",
        idx + 1,
      );
      set_cell_value(
        results.hedges.client_hedge_rate[idx],
        "CLIENT_HEDGE_RATE",
        idx + 1,
      );
      set_cell_value(
        results.hedges.client_hedge_val[idx],
        "CLIENT_HEDGE_VAL",
        idx + 1,
      );
    }
  } catch (error) {
    console.log(error);
  }
};
export const refresh_vanilla_dets = async (
  is_user_vol,
  col,
  rfqMode,
  jRef,
  keyVal_input_col,
  axios,
  set_cell_value,
  assign_jexcel_data,
) => {
  let table_data = jRef.current.jspreadsheet.getJson();

  try {
    let response = await axios.post(
      `${process.env.REACT_APP_PYTON}` + `get_vanilla_opt_dets`,
      { is_user_vol: is_user_vol, opts_data: table_data },
    );
    let results = response.data;
    for (
      let index = 0;
      index < jRef.current.jspreadsheet.headers.length - 1;
      index++
    ) {
      set_cell_value(
        results.ui_dets[index].delta.toFixed(2),
        "DELTA",
        index + 1,
      );
      set_cell_value(
        results.ui_dets[index].hedge_amount.toFixed(2),
        "HEDGE_AMOUNT",
        index + 1,
      );
      set_cell_value(
        results.ui_dets[index].price_pct_notional.toFixed(4),
        "PRICE_PCT_NOTIONAL",
        index + 1,
      );
      set_cell_value(results.ui_dets[index].vega.toFixed(0), "VEGA", index + 1);
    }
    set_cell_value(
      results.net_prices.net_client_price.toFixed(6),
      "NET_CLIENT_PRICE",
      1,
    );
    set_cell_value(
      results.net_prices.net_market_price.toFixed(6),
      "NET_MARKET_PRICE",
      1,
    );

    for (let idx = 0; idx < results.hedges.hedge_delta.length; idx++) {
      set_cell_value(results.hedges.hedge_delta[idx], "HEDGE_DELTA", idx + 1);
    }
  } catch (error) {
    console.log(error);
  }
};
export const refresh_exotic_dets = async (
  col,
  jRef,
  axios,
  set_cell_value,
  assign_jexcel_data,
) => {
  let table_data = jRef.current.jspreadsheet.getJson();
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_PYTON}` + `get_exotic_opt_dets`,
      { opts_data: table_data },
    );
    let results = response.data;
    for (let idx = 0; idx < results.hedges.hedge_delta.length; idx++) {
      set_cell_value(results.hedges.hedge_delta[idx], "HEDGE_DELTA", idx + 1);
    }
    // assign_jexcel_data()
  } catch (error) {
    console.log(error);
  }
};
export const get_rfq_strings = async (
  jRef,
  rfq_object,
  newRfqCreatedDetails,
  dispatch,
  updateOvmlTail,
  updateRfqStr,
  axios,
) => {
  try {
    let jtable = jRef.current.jspreadsheet.getJson();
    let response = await axios.post(
      `${process.env.REACT_APP_PYTON}` + `get_rfq_strings`,
      { jtable: jtable },
    );
    Object.assign(rfq_object, {
      rfq_str: response.data.rfq,
      ovml_tail: response.data.ovml_tail,
    });
    dispatch(updateOvmlTail(response.data.ovml_tail));
    dispatch(updateRfqStr(response.data.rfq));
    newRfqCreatedDetails.push({
      rfq_str: response.data.rfq,
      ovml_tail: response.data.ovml_tail,
    });
    return {
      rfq_str: response.data.rfq,
      ovml_tail: response.data.ovml_tail,
    };
  } catch (error) {
    console.log(error);
  }
};
export const initialize_rfq = async (
  cross,
  expiry_text,
  col,
  axios,
  keyVal_input_col,
  cross_list_data,
  set_cell_value,
  add_cut_toggle_to_g10_ccy,
  jRef,
  updateRfqBookingModeUpdatingRows,
  rfqMode,
  rfqId,
  dispatch,
  assign_jexcel_data,
) => {
  if (cross === "" || expiry_text === "") {
    return;
  }
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_PYTON}` + `initialize_rfq`,
      { cross: cross, expiry_text: expiry_text },
    );

    let spotRef = await axios.get(
      `${process.env.REACT_APP_BLOOMBERG}` + `spot/${cross}`,
    );
    let spotRefNumber = Number(spotRef.data.price);
    let fwdRef = await axios.get(
      `${process.env.REACT_APP_BLOOMBERG}` +
        `forwards/${cross}/${response.data.expiry}`,
    );
    let fwdRefNumber = Number(fwdRef.data.price);
    let ccyPair = keyVal_input_col("CCY_PAIR", col);
    let currencyPairDetails = cross_list_data.find((x) => {
      return x.value == ccyPair;
    });
    let results = response.data;
    if (rfqMode === "rfq booking view") {
      let updatingData = {
        rfqId: rfqId,
        cut: results.cut,
        fixing_ref: results.fixing_ref,
        delivery: results.delivery,
        expiry: results.expiry,
        expiry_text: results.expiry_text,
        spot_date: results.spot_date,
      };
    }
    if (keyVal_input_col("CCYPAIR_GROUP", col) === "G10") {
      document.addEventListener("keydown", add_cut_toggle_to_g10_ccy);
    }
    let expiry = keyVal_input_col("EXPIRY_TEXT", col);
    for (
      let index = 1;
      index < jRef.current.jspreadsheet.headers.length;
      index++
    ) {
      set_cell_value(results.cut, "CUT", index);
      set_cell_value(results.fixing_ref, "FIXING_REF", index);
      set_cell_value(spotRefNumber.toFixed(4), "SPOT_REF", index);
      set_cell_value(results.spot_date, "SPOT_DATE", index);

      if (keyVal_input_col("EXPIRY_TEXT", index) === expiry) {
        set_cell_value(results.expiry_text, "EXPIRY_TEXT", index);
        set_cell_value(fwdRefNumber.toFixed(4), "FWD_REF", index);
        set_cell_value(results.expiry, "EXPIRY", index);
        set_cell_value(results.delivery, "DELIVERY", index);
      }
    }
    assign_jexcel_data();
  } catch (error) {
    console.log(error);
  }
};
export const validate_expiry_text = async (expiry_text, axios) => {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_PYTON}` + `validate_expiry_text`,
      { expiry_text },
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const set_quote_type_api = async (jRef, axios, rfq_object) => {
  try {
    let table_data = jRef.current.jspreadsheet.getJson();
    let response = await axios.post(
      `${process.env.REACT_APP_PYTON}` + `set_quote_type`,
      table_data,
    );

    Object.assign(rfq_object, {
      quote_type: response.data,
    });
  } catch (error) {
    console.log(error);
  }
};
export const refresh_hedge_amounts = async (
  is_vanilla_only,
  jRef,
  set_cell_value,
  keyVal_input_col,
  assign_jexcel_data,
  axios,
) => {
  const is_vanillas_only = is_vanilla_only();
  for (
    let index = 1;
    index < jRef.current.jspreadsheet.headers.length;
    index++
  ) {
    if (index !== 1) {
      set_cell_value("", "HEDGE_TYPE", index);
    }
    set_cell_value("", "HEDGE_DELTA", index);
    set_cell_value("", "HEDGE_RATE", index);
    set_cell_value("", "HEDGE_VAL", index);
  }

  if (keyVal_input_col("HEDGE_TYPE", 1) === "LIVE") {
    assign_jexcel_data();
    // rfq_object = {...rfq_object,rfq_table_data: jRef.current.jspreadsheet.getJson()};

    return;
  }
  let table_data = jRef.current.jspreadsheet.getJson();
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_PYTON}` + `refresh_hedge_amounts`,
      {
        opts_data: table_data,
        is_vanillas_only: is_vanillas_only,
      },
    );
    let results = response.data;
    for (let idx = 0; idx < results.hedges.hedge_delta.length; idx++) {
      set_cell_value(results.hedges.hedge_delta[idx], "HEDGE_DELTA", idx + 1);
      set_cell_value(results.hedges.hedge_rate[idx], "HEDGE_RATE", idx + 1);
      set_cell_value(results.hedges.hedge_val[idx], "HEDGE_VAL", idx + 1);
    }
    assign_jexcel_data();
  } catch (error) {
    console.log(error);
  }
};
