import RatesView from "./Rates.view";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import * as rfqSlice from "../../../store/rfq/rfqSlice";
import * as ratesSlice from "../../../store/rates/ratesSlice";
import * as actionSnackBar from "../../../store/snackbar/action";
import { ws, connectWS, sendEvent } from "../../../services/websocket";
import moment from "moment";
import axios from "axios";
import { END_POINT } from "../../../utils";
import PapaParse from "papaparse";

function Rates() {
  const dispatch = useDispatch();
  const [metaData, setMetaData] = useState({
    dateFormat: "TradeDate",
    date: moment(new Date()).format("DD/MM/YYYY"),
  });
  const [searchValue, setSearchValue] = useState("");
  const [dateSelected, setDateSelected] = useState("");

  const { rates, exchangeRates, chosenRates, chosenDeals } = useSelector(
    (state) => state.ratesSlice
  );
  const [PnL, setPnL] = useState();

  const handleDatePick = (e) => {
    const token = sessionStorage.getItem("token");
    setSearchValue("");
    setMetaData({
      ...metaData,
      date:
        e.target.value !== ""
          ? moment(e.target.value, "YYYY-MM-DD").format("DD/MM/YYYY")
          : moment(new Date()).format("DD/MM/YYYY"),
    });
    setDateSelected(e.target.value);
    let objForSendEvent;

    objForSendEvent = {
      type: "get_rates",
      data: {},
      token: token.replace("Bearer ", ""),
    };
    objForSendEvent = {
      ...objForSendEvent,
      metaData: {
        date:
          e.target.value !== ""
            ? moment(e.target.value, "YYYY-MM-DD").format("DD/MM/YYYY")
            : moment(new Date()).format("DD/MM/YYYY"),
        dateFormat: metaData.dateFormat,
      },
    };

    if (ws !== null) {
      sendEvent(objForSendEvent);
    } else {
      connectWS(token.replace("Bearer ", ""), dispatch, rfqSlice);
      sendEvent(objForSendEvent);
    }
  };

  const handleDateFormatPick = (e) => {
    const token = sessionStorage.getItem("token");
    setMetaData({ ...metaData, dateFormat: e.target.value });
    if (metaData.date !== undefined) {
      if (ws !== null) {
        sendEvent({
          type: "get_rates",
          data: {},
          metaData: { date: metaData.date, dateFormat: e.target.value },
          token: token.replace("Bearer ", ""),
        });
      } else {
        connectWS(token.replace("Bearer ", ""), dispatch, rfqSlice);
        sendEvent({
          type: "get_rates",
          data: {},
          metaData: { date: metaData.date, dateFormat: e.target.value },
          token: token.replace("Bearer ", ""),
        });
      }
    }
  };

  const handleSearchByValue = async (search_value) => {
    const token = sessionStorage.getItem("token");
    if (search_value !== "") {
      setSearchValue(search_value);
      if (ws !== null) {
        sendEvent({
          type: "get_rates",
          data: {},
          metaData: { searchValue: search_value },
          token: token.replace("Bearer ", ""),
        });
      } else {
        connectWS(token.replace("Bearer ", ""), dispatch, rfqSlice);
        sendEvent({
          type: "get_rates",
          data: {},
          metaData: { searchValue: search_value },
          token: token.replace("Bearer ", ""),
        });
      }
    } else {
      setSearchValue(search_value);
      sendEvent({
        type: "get_rates",
        data: {},
        metaData: {
          date: "TradeDate",
          dateFormat: moment(new Date()).format("DD/MM/YYYY"),
        },
        token: token.replace("Bearer ", ""),
      });
    }
  };

  const updatePnl = async () => {
    let specificRatesDataToCalculate = rates.map((rate) => {
      let startDate = moment(rate.StartDate, "DD/MM/YYYY");
      let endDate = moment(rate.EndDate, "DD/MM/YYYY");
      let picked = (({
        Notional,
        Rate,
        AdditionalPaymentAmount,
        Direction,
        AdditionalPaymentDirection,
      }) => ({
        Notional,
        Rate,
        AdditionalPaymentAmount,
        Direction,
        AdditionalPaymentDirection,
      }))(rate);
      picked = {
        ...picked,
        Notional: +picked.Notional,
        Rate: +rate.Rate,
        exRate:
          rate.Currency === "USD" ? 1 : exchangeRates[`${rate.Currency}USD`],
        DateDiff: endDate.diff(startDate, "days"),
        AdditionalPaymentAmount: +rate.AdditionalPaymentAmount,
        AdditionalPaymentDirection: rate.AdditionalPaymentDirection,
      };
      return picked;
    });
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_PYTON}` + `calculate_rates_pnl`,
        {
          RatesDataToCalculate: specificRatesDataToCalculate,
        }
      );
      if (response.data) {
        setPnL(response.data);
      }
    } catch (error) {}
  };

  const getExchangeRatesForTraders = async () => {
    const token = sessionStorage.getItem("token");
    try {
      let updateExchange = await axios.get(
        `${process.env.REACT_APP_BASE_URL}` +
          END_POINT.RATES +
          "/ccyExchangeRates",
        {
          params: {
            traders: true,
          },
          headers: { Authorization: token },
        }
      );
      dispatch(ratesSlice.setExchangeRates(updateExchange.data));
    } catch (error) {}
  };

  const exportToCsv = () => {
    let csv;
    if (rates.length > 0) {
      csv = PapaParse.unparse(rates);
    }
    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(
        blob,
        `RATES_FILTERD_BY_${metaData.dateFormat}_OF_${metaData.date}`
      );
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute(
          "download",
          `RATES_FILTERD_BY_${metaData.dateFormat}_OF_${metaData.date}`
        );
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const verifyRatesBeforeLink = async () => {
    let uiError = "";
    let payDirectionRate = chosenRates.filter(
      (rate) => rate.Direction === "Pay"
    );
    let recievieDirectionRate = chosenRates.filter(
      (rate) => rate.Direction === "Receive"
    );
    let sumNotinalOfPay;
    let sumNotinalOfRecive;
    if (payDirectionRate.length === 0 || recievieDirectionRate.length === 0) {
      uiError = "Direction must be the opposite of one to the other";
      return uiError;
    } else {
      sumNotinalOfPay = payDirectionRate
        .map((rate) => +rate.Notional)
        .reduce((partialSum, a) => partialSum + a);
      sumNotinalOfRecive = recievieDirectionRate
        .map((rate) => +rate.Notional)
        .reduce((partialSum, a) => partialSum + a);
    }
    if (sumNotinalOfPay !== sumNotinalOfRecive) {
      uiError =
        "Trades cannot be linked, sum of pay does not equal sum of receive";
    }
    const checkFields = () => {
      let referenceObject = chosenRates[0];
      let checkArray = chosenRates.slice(1);
      let notEqualFields = checkArray.map((element, index) => {
        if (
          element.StartDate !== referenceObject.StartDate
        ) {
          uiError = "Trades cannot be linked, different Start/End Dates";
          return uiError;
        } else if (element.Currency !== referenceObject.Currency) {
          uiError = "Trades cannot be linked, difference currency ";
          return uiError;
        } else if (element.ProductType !== referenceObject.ProductType) {
          uiError = "Trades cannot be linked, different Product Types";
          return uiError;
        } else if (element.FRateIndex !== referenceObject.FRateIndex) {
          uiError = "Trades cannot be linked, different Floating Rate Index";
          return uiError;
        } else {
          return true;
        }
      });
      return notEqualFields[0];
    };

    if (uiError !== "") {
      return uiError;
    } else if (checkFields() !== true) {
      return uiError;
    } else {
      return true;
    }
  };

  const linkRates = async () => {
    let verifyRatesBeforeLinkResult = await verifyRatesBeforeLink();
    const token = sessionStorage.getItem("token");
    if (verifyRatesBeforeLinkResult === true) {
      try {
        let linkRates = await axios.post(
          `${process.env.REACT_APP_BASE_URL}${END_POINT.RATES}/link_rates`,
          {
            chosenRates,
          },
          { headers: { Authorization: token } }
        );
        dispatch(ratesSlice.setChosenRates([]));
      } catch (error) {
        actionSnackBar.setSnackBar("error", "Error Link Rates", 3000);
      }
    } else {
      dispatch(
        actionSnackBar.setSnackBar("error", verifyRatesBeforeLinkResult, 3000)
      );
    }
  };

  const deLinkTrades = async () => {
    let token = sessionStorage.getItem("token");
    try {
      let deLinkRates = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${END_POINT.RATES}/deLink_rates`,
        {
          chosenRates,
        },
        { headers: { Authorization: token } }
      );
      dispatch(ratesSlice.setChosenRates([]));
      dispatch(ratesSlice.setChosenDeals([]));
    } catch (error) {
      dispatch(
        actionSnackBar.setSnackBar("error", "Error De-Link Rates", 3000)
      );
    }
  };
  // ! handle all newRates and first load rates
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    console.log("first")
    getExchangeRatesForTraders();
    console.log("Second")
    if (rates.length > 0) {
      updatePnl();
    }
    dispatch(ratesSlice.setDataLoading(true));
    const getRates = (message) => {
      switch (message.data.type) {
        case "get_rates":
          if (message.data.rates) {
            dispatch(
              ratesSlice.getRatesDataAsync(message.data.rates, "get_rates")
            );
          }
          break;
          case "new_rates":
          console.log('message.data: ', message.data)
          dispatch(
            ratesSlice.getRatesDataAsync(message.data.newRates, "new_rates")
          );
        case "handle_rates_deal":
          dispatch(
            ratesSlice.getRatesDataAsync(message.data.rate, "handle_rates_deal")
          );
        default:
          break;
      }
    };
    window.addEventListener("message", getRates);

    return () => {
      dispatch(ratesSlice.setRates([]));
      dispatch(ratesSlice.setChosenRates([]));
      dispatch(ratesSlice.setChosenDeals([]));
      window.removeEventListener("message", getRates);
    };
  }, []);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (ws !== null) {
      sendEvent({
        type: "get_rates",
        data: {},
        metaData: {
          dateFormat: "TradeDate",
          date: moment(new Date()).format("DD/MM/YYYY"),
        },
        token: token.replace("Bearer ", ""),
      });
    } else {
      connectWS(token.replace("Bearer ", ""), dispatch, rfqSlice);
      sendEvent({
        type: "get_rates",
        data: {},
        metaData: {
          dateFormat: "TradeDate",
          date: moment(new Date()).format("DD/MM/YYYY"),
        },
        token: token.replace("Bearer ", ""),
      });
    }
    return async () => {
      sendEvent({
        type: "unsubscribe_rates",
      });
    };
  }, []);

  useEffect(() => {
    if (Object.keys(exchangeRates).length === 0) {
      getExchangeRatesForTraders();
    }
    if (rates.length > 0) {
      updatePnl();
    } else {
      setPnL();
    }
  }, [rates, rates.length]);

  return (
    <RatesView
      setDateSelected={setDateSelected}
      dateSelected={dateSelected}
      handleDatePick={handleDatePick}
      handleSearchByValue={handleSearchByValue}
      searchValue={searchValue}
      handleDateFormatPick={handleDateFormatPick}
      metaData={metaData}
      PnL={PnL}
      exportToCsv={exportToCsv}
      linkRates={linkRates}
      deLinkTrades={deLinkTrades}
    />
  );
}

export default Rates;
