function bookToETG(etgBookingResponse) {

  // Check for failure conditions
  const options = etgBookingResponse?.data?.options || [];
  const etgMessages = options.toString();

  if (etgMessages.includes("DOCTYPE") || etgMessages.includes("Failed")) {
    console.error("Booking failed with message: ", etgMessages);
    return "Failed";
  }

  // Mark as booked for all other cases
  console.log("Booking successful");
  return "Booked";
}

export { bookToETG };
