import CustomToolTip from "../components/CustomToolTip";
import MarkitStatusRenderer from "../components/MarkitStatusRenderer";

export const cellRendererSelector = (params) => {
  if (params.data.columnHeader.toLowerCase() === "markit status") {
    return {
      component: MarkitStatusRenderer,
    };
  } else if (
    params.data.columnHeader.toLowerCase() === "markit error message" ||
    params.data.columnHeader.toLowerCase() === "markit deal id"
  ) {
    return {
      component: CustomToolTip,
    };
  }

  return undefined;
};
