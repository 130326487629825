const convertDate = (value, key) => {
  const day = value.toLocaleString("default", {
    day: "2-digit",
  });
  const month = value.toLocaleString("en-US", {
    month: "short",
  });
  const year = value.toLocaleString("default", {
    year: "numeric",
  });
  const res = day + "-" + month + "-" + year;
  let obj = {
    value: res,
    key,
  };

  return obj;
};

module.exports = { convertDate };
