import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { ReactComponent as DownloadCsv } from "../../../../assets/csv-download.svg";
import { ReactComponent as DownloadPdf } from "../../../../assets/pdf17.svg";
import { ReactComponent as DownloadExcel } from "../../../../assets/excel.svg";
import { useStyles } from "./style";
import { downloadAttachment } from "../utils/apiCalls";

export const Attachment = ({
  tabChosen,
  order,
  markdownContentTabs,
  attachmentPath,
  setAttachmentPath,
}) => {
  const classes = useStyles();
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = async () => {
    if (!isDownloading) {
      setIsDownloading(true);
      await downloadAttachment(attachmentPath, setIsDownloading);
    }
  };

  useEffect(() => {
    if (markdownContentTabs !== null) {
      const markdownObj = markdownContentTabs.filter(
        (obj) =>
          Number(obj?.traderId) === Number(tabChosen?.traderId) &&
          Number(obj?.clientId) === Number(tabChosen?.clientId),
      );
      if (markdownObj.length > 0 && markdownObj[0].attachment !== undefined) {
        setAttachmentPath(markdownObj[0].attachment);
      } else {
        setAttachmentPath(null);
      }
    }
  }, [tabChosen, markdownContentTabs]);

  return (
    <>
      {tabChosen !== null && attachmentPath !== null ? (
        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.componentContainer}
        >
          {tabChosen !== null && markdownContentTabs !== null ? (
            <Grid
              item
              className={
                attachmentPath.toLowerCase().includes(".csv") ||
                attachmentPath.toLowerCase().includes(".xlsx")
                  ? classes.attachmentContainerCsv
                  : attachmentPath.toLowerCase().includes(".pdf")
                  ? classes.attachmentContainerPdf
                  : null
              }
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              onClick={handleDownload}
            >
              <Grid item>
                <IconButton disabled>
                  {attachmentPath.toLowerCase().includes(".csv") ? (
                    <DownloadCsv className={classes.csvIcon} />
                  ) : attachmentPath.toLowerCase().includes(".pdf") ? (
                    <DownloadPdf className={classes.pdfIcon} />
                  ) : attachmentPath.toLowerCase().includes(".xlsx") ? (
                    <DownloadExcel className={classes.csvIcon} />
                  ) : null}
                </IconButton>
              </Grid>
              <Typography className={classes.attachmentTypography}>
                {isDownloading ? "Downloading file" : attachmentPath}
              </Typography>
            </Grid>
          ) : (
            <Grid
              item
              className={classes.attachmentContainer}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <CircularProgress className={classes.circularProgress} />
              </Grid>
              <Typography className={classes.attachmentTypography}>
                Loading File
              </Typography>
            </Grid>
          )}
        </Grid>
      ) : null}
    </>
  );
};
