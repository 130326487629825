import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as fiActions from "../../../store/fi/fiSlice";
import moment from "moment";
import "./style.css";

export default function TimePickerComponent(values) {
  const tradeToEdit =
    useSelector((state) => state.fiSlice?.selectedTradeToEdit) || null;
  const [color, setColor] = useState(false);
  const [dynamicClass, setDynamicClass] = useState("time-picker-default");
  const [value, setValue] = useState("08:00");
  const dispatch = useDispatch();
  let obj = {
    value: value,
    key: values.values.key,
  };
  const update = () => {
    dispatch(fiActions.editNewTradeForm(obj));
  };
  useEffect(() => {

    update();
    if (tradeToEdit !== null) {
      setDynamicClass("time-picker");
      const time = tradeToEdit[`${values.values.key}`];
      setValue(time);
      obj = {
        value: time,
        key: values.values.key,
      };
      dispatch(fiActions.editNewTradeForm(obj));
    }
  }, []);
  const handleChange = (event) => {
    if (!color) {
      setDynamicClass("time-picker");
    }
    obj = {
      value: event.target.value,
      key: values.values.key,
    };
    setValue(event.target.value);
    dispatch(fiActions.editNewTradeForm(obj));
  };

  return (
    <div className="time-wrapper">
      <label className="time-picker-label">{values.values.label}</label>
      <input
        type="time"
        step="0.001"
        className={dynamicClass}
        valuedefault={value}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
}
