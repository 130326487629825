export const jtable_keys = [
  "REQUEST_FOR",
  "CCY_PAIR",
  "DEAL_TYPE",
  "LEG_TYPE",
  "OPTION_TYPE",
  "EXPIRY_TEXT",
  "STRIKE",
  "BARRIER",
  "BARRIER2",
  "BARRIER_START_DATE",
  "BARRIER_END_DATE",
  "CALL_PUT",
  "NOTIONAL",
  "NEAR_DATE_TEXT",
  "FAR_DATE_TEXT",
  "NEAR_NOTIONAL",
  "FAR_NOTIONAL",
  "SWAP_CCY",
  "NEAR_DATE",
  "FAR_DATE",
  "DATES_IN_RFQ",
  "BUY_SELL",
  "CCYPAIR_GROUP",
  "PB_ACCOUNT",
  "RFQ_FORMAT",
  "REF_TYPE",
  "INCLUDE_REF",
  "CUT",
  "FIXING_REF",
  "NEAR_FIXING_DATE",
  "FAR_FIXING_DATE",
  "NOTIONAL_CCY",
  "SPOT_REF",
  "FWD_REF",
  "BBG_MID_VOL",
  "NEAR_SWAP",
  "FAR_SWAP",
  "EXPIRY",
  "DELIVERY",
  "SPOT_DATE",
  "DELTA",
  "VEGA",
  "HEDGE_AMOUNT",
  "PRICE_PCT_NOTIONAL",
  "PREMIUM_VAL",
  "PREMIUM_TYPE",
  "PREMIUM_CCY",
  "PREM_CCY_TYPE",
  "THIRD_CCY",
  "FX_RATE",
  "CLIENT_PRICE",
  "MARKET_PRICE",
  "CLIENT_PREMIUM",
  "MARKET_PREMIUM",
  "NET_CLIENT_PRICE",
  "NET_MARKET_PRICE",
  "SWAP_DIRECTION",
  "CLIENT_START_RATE",
  "MARKET_START_RATE",
  "CLIENT_SWAP_PTS",
  "MARKET_SWAP_PTS",
  "CLIENT_FAR_RATE",
  "MARKET_FAR_RATE",
  "HEDGE_MATCH_TYPE",
  "HEDGE_TYPE",
  "HEDGE_VAL",
  "HEDGE_RATE",
  "HEDGE_DELTA",
  "CLIENT_HEDGE_TYPE",
  "CLIENT_HEDGE_VAL",
  "CLIENT_HEDGE_RATE",
  "CLIENT_HEDGE_DELTA",
  "MM_HEDGE_TYPE",
  "MM_HEDGE_VAL",
  "MM_HEDGE_RATE",
  "MM_HEDGE_DELTA",
];
export const rfq_view_not_swp_hidden_rows = [
  "NEAR_DATE_TEXT",
  "FAR_DATE_TEXT",
  "NEAR_NOTIONAL",
  "FAR_NOTIONAL",
  "SWAP_CCY",
  "NEAR_DATE",
  "FAR_DATE",
  "DATES_IN_RFQ",
  "NEAR_FIXING_DATE",
  "FAR_FIXING_DATE",
  "NEAR_SWAP",
  "FAR_SWAP",
  "SWAP_DIRECTION",
  "CLIENT_START_RATE",
  "MARKET_START_RATE",
  "CLIENT_SWAP_PTS",
  "MARKET_SWAP_PTS",
  "CLIENT_FAR_RATE",
  "MARKET_FAR_RATE",
  "THIRD_CCY",
  "FX_RATE",
];
export const swap_keys = [
  "LEG_TYPE",
  "OPTION_TYPE",
  "EXPIRY_TEXT",
  "STRIKE",
  "BARRIER",
  "BARRIER2",
  "BARRIER_START_DATE",
  "BARRIER_END_DATE",
  "CALL_PUT",
  "NOTIONAL",
  "BUY_SELL",
  "CCYPAIR_GROUP",
  "PB_ACCOUNT",
  "RFQ_FORMAT",
  "REF_TYPE",
  "INCLUDE_REF",
  "CUT",
  "FIXING_REF",
  "NOTIONAL_CCY",
  "SPOT_REF",
  "FWD_REF",
  "BBG_MID_VOL",
  "EXPIRY",
  "DELIVERY",
  "SPOT_DATE",
  "DELTA",
  "VEGA",
  "HEDGE_AMOUNT",
  "PRICE_PCT_NOTIONAL",
  "PREMIUM_VAL",
  "PREMIUM_TYPE",
  "PREMIUM_CCY",
  "CLIENT_PRICE",
  "MARKET_PRICE",
  "CLIENT_PREMIUM",
  "MARKET_PREMIUM",
  "NET_CLIENT_PRICE",
  "NET_MARKET_PRICE",
  "HEDGE_MATCH_TYPE",
  "HEDGE_TYPE",
  "HEDGE_VAL",
  "HEDGE_RATE",
  "HEDGE_DELTA",
  "CLIENT_HEDGE_TYPE",
  "CLIENT_HEDGE_VAL",
  "CLIENT_HEDGE_RATE",
  "CLIENT_HEDGE_DELTA",
  "MM_HEDGE_TYPE",
  "MM_HEDGE_VAL",
  "MM_HEDGE_RATE",
  "MM_HEDGE_DELTA",
  "NEAR_FIXING_DATE",
  "FAR_FIXING_DATE",
  "THIRD_CCY",
  "FX_RATE",
];
export const post_trade_rows = [
  "NOTIONAL_CCY",
  "PREMIUM_VAL",
  "PREMIUM_TYPE",
  "PREMIUM_CCY",
  "PREM_CCY_TYPE",
  "CLIENT_PRICE",
  "MARKET_PRICE",
  "CLIENT_PREMIUM",
  "MARKET_PREMIUM",
  "NET_CLIENT_PRICE",
  "NET_MARKET_PRICE",
  "THIRD_CCY",
  "FX_RATE",
];
export const post_trade_rows_swap = [
  "SWAP_DIRECTION",
  "CLIENT_START_RATE",
  "MARKET_START_RATE",
  "CLIENT_SWAP_PTS",
  "MARKET_SWAP_PTS",
  "CLIENT_FAR_RATE",
  "MARKET_FAR_RATE",
];
export const booking_mode_hidden_rows = [
  "PB_ACCOUNT",
  "CUT",
  "FIXING_REF",
  "NOTIONAL_CCY",
  "SPOT_REF",
  "FWD_REF",
  "BBG_MID_VOL",
  "RFQ_FORMAT",
  "REF_TYPE",
  "INCLUDE_REF",
  "DELTA",
  "HEDGE_AMOUNT",
  "PRICE_PCT_NOTIONAL",
  "VEGA",
  "OPTION_TYPE",
  "CCYPAIR_GROUP",
  "REQUEST_FOR",
  "THIRD_CCY",
  "FX_RATE",
];
export const booking_mode_swp_hidden_rows = [
  "SPOT_REF",
  "INCLUDE_REF",
  "NEAR_SWAP",
  "FAR_SWAP",
  "FIXING_REF",
  "REQUEST_FOR",
  "DATES_IN_RFQ",
  "THIRD_CCY",
  "FX_RATE",
  "PREM_CCY_TYPE",
  // "CCYPAIR_GROUP"
];
export const rfq_view_swp_hidden_rows = [
  "SWAP_DIRECTION",
  "CLIENT_START_RATE",
  "MARKET_START_RATE",
  "CLIENT_SWAP_PTS",
  "MARKET_SWAP_PTS",
  "CLIENT_FAR_RATE",
  "MARKET_FAR_RATE",
  "THIRD_CCY",
  "FX_RATE",
];
export const booking_rows_attention = [
  "BUY_SELL",
  "CALL_PUT",
  "CLIENT_PRICE",
  "MARKET_PRICE",
  "HEDGE_TYPE",
  "HEDGE_VAL",
  "HEDGE_RATE",
  "HEDGE_DELTA",
];
export const tableHeaders = ["Field", "Leg 1"];
export const cross_list_data = [
  "XAUXAG",
  "XAUXPT",
  "XAUXPD",
  "XAUEUR",
  "XAUGBP",
  "XAUAUD",
  "XAUNZD",
  "XAUUSD",
  "XAUCAD",
  "XAUCHF",
  "XAUKWD",
  "XAUTRY",
  "XAUBRL",
  "XAUBGN",
  "EURUSD",
  "AEDCLP",
];
export const NDF_CCY = [
  "ARS",
  "COP",
  "CLP",
  "PEN",
  "BRL",
  "KZT",
  "UAH",
  "CNY",
  "KRW",
  "TWD",
  "INR",
  "IDR",
  "MYR",
  "PHP",
];
export const G10_CCY = [
  "EUR",
  "GBP",
  "AUD",
  "NZD",
  "USD",
  "CAD",
  "CHF",
  "NOK",
  "SEK",
  "JPY",
];
export const hidden_keys = [];
export const hedge_keys = [
  "HEDGE_TYPE",
  "HEDGE_VAL",
  "HEDGE_RATE",
  "HEDGE_DELTA",
];
export const user_editable_cells = [
  "CCY_PAIR",
  "EXPIRY_TEXT",
  "STRIKE",
  "NOTIONAL",
  "SPOT_REF",
  "FWD_REF",
  "BBG_MID_VOL",
  "CLIENT_PRICE",
  "MARKET_PRICE",
  "HEDGE_RATE",
  "HEDGE_DELTA",
  "DELIVERY",
  "SPOT_DATE",
  "BARRIER",
  "BARRIER2",
  "THIRD_CCY",
];
export const market_data_keys = [
  "SPOT_REF",
  "FWD_REF",
  "BBG_MID_VOL",
  "NEAR_SWAP",
  "FAR_SWAP",
];
export const spacebar_keys = [
  "CALL_PUT",
  "BUY_SELL",
  "CUT",
  "PB_ACCOUNT",
  "RFQ_FORMAT",
  "REF_TYPE",
  "INCLUDE_REF",
  "PREMIUM_VAL",
  "PREMIUM_TYPE",
  "REQUEST_FOR",
  "DATES_IN_RFQ",
  "SWAP_CCY",
];
// export const swap_keys = [
//   "NEAR_SWAP",
//   "FAR_SWAP",
//   "NEAR_DATE_TEXT",
//   "FAR_DATE_TEXT",
//   "NEAR_NOTIONAL",
//   "FAR_NOTIONAL",
//   "SWAP_CCY",
//   "NEAR_DATE",
//   "FAR_DATE",
//   "DATES_IN_RFQ",
//   "SWAP_DIRECTION",
//   "CLIENT_START_RATE",
//   "MARKET_START_RATE",
//   "CLIENT_SWAP_PTS",
//   "MARKET_SWAP_PTS",
//   "CLIENT_FAR_RATE",
//   "MARKET_FAR_RATE",
//   "NEAR_FIXING_DATE",
//   "FAR_FIXING_DATE",
// ];
export const tableHeaders_vol_spread = [
  "MARKET MAKER",
  "QUOTE TEXT",
  "QUOTED VOL1",
  "QUOTED VOL2",
  "BID",
  "OFFER",
  "SPREAD",
  "TRADER",
];
export const tableHeaders_single_price = [
  "MARKET MAKER",
  "QUOTE TEXT",
  "BID",
  "OFFER",
  "SPREAD",
  "TRADER",
];
export const user_editable_columns = ["QUOTE TEXT"];
export const user_clickable_columns = ["MARKET MAKER"];

export const market_makers = [
  "BARX",
  "BMO",
  "BNP",
  "BOA",
  "CIBC",
  "CITI",
  "COMMERZ",
  "CS",
  "DB",
  "DRW",
  "GS",
  "HSBC",
  "MS",
  "MUFG",
  "NATWEST",
  "NOMURA",
  "RBC",
  "SANTANDER",
  "SEB",
  "SOCGEN",
  "STANCHART",
  "STANDARDBANK",
  "SUSQ",
  "TDFX",
  "UBS",
  "ALPHADYNE",
  "BREVAN",
  "LMR",
  "CAPSTONE",
];
