import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import * as actionSnackBar from "../../../../store/snackbar/action";
import { ReactComponent as Edit } from "../../../../assets/edit.svg";
import { ReactComponent as Delete } from "../../../../assets/delete.svg";
import {
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@material-ui/core";
import {
  StyledTableCell,
  StyledTableRow,
  StyledTextField,
  useStyles,
} from "./PMSTable.style";
import { useEffect, useState } from "react";
import axios from "axios";
import { END_POINT } from "../../../../utils";
import { useDispatch } from "react-redux";
import { Button, Typography } from "@mui/material";

function PmTableRow(props) {
  const { pm, pmIndex, updateTableWhenDelete, getPMTable, editPm } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  // const [isEdit, setIsEdit] = useState(false);
  const [emailAddresses, setEmailAddresses] = useState("");
  const [pmName, setPmName] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  const changeEmailAddresses = (e) => {
    let newArr = e.target.value.split(/[\;\,\:]/);
    newArr = newArr.map((address) => {
      return address.replaceAll(" ", "");
    });
    setEmailAddresses(newArr);
  };

  const changePmName = (e) => {
    setPmName(e.target.value);
  };

  const updatePm = async () => {

    let pmToUpdate = {
      id: pm.id,
      pm: pmName !== "" ? pmName : pm.pm,
      recipients: emailAddresses.length !== 0 ? emailAddresses : pm.recipients,
    };
    if (emailAddresses[0] === "") {
      pmToUpdate = { ...pmToUpdate, recipients: null };
    }
    try {
      let token = sessionStorage.getItem("token");
      let res = await axios.put(
        `${process.env.REACT_APP_BASE_URL}${END_POINT.DATA_MGMT}/update_pm`,
        pmToUpdate,
        { headers: { Authorization: token } }
      );
      dispatch(actionSnackBar.setSnackBar("success", res.data, 4000));
      editPm(pmIndex, false);
    } catch (error) {
      dispatch(actionSnackBar.setSnackBar("error", error, 4000));
      setEmailAddresses("");
      setPmName("");
      editPm(pmIndex, false);
    }
  };

  const deletePm = async (pmIndex) => {
    let token = sessionStorage.getItem("token");
    if (pm.id !== null) {
      try {
        let res = await axios.delete(
          `${process.env.REACT_APP_BASE_URL}${END_POINT.DATA_MGMT}/delete_pm`,
          {
            headers: {
              Authorization: token,
            },
            data: {
              id: pm.id,
            },
          }
        );
        dispatch(actionSnackBar.setSnackBar(`success`, res.data, 4000));
        updateTableWhenDelete(pmIndex);
        setDialogOpen(false);
      } catch (error) {
        dispatch(
          actionSnackBar.setSnackBar(`error`, "Something went wrong.", 4000)
        );
      }
    }
  };
  const cancelEdit = () => {
    setEmailAddresses("");
    setPmName("");
    editPm(pmIndex, false);
  };

  const savePmToDb = async () => {
    let token = sessionStorage.getItem("token");
    let pmObj = {
      pm: pmName,
      recipients: emailAddresses,
    };
    try {
      let res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${END_POINT.DATA_MGMT}/add_pm`,
        pmObj,
        { headers: { Authorization: token } }
      );

      dispatch(
        actionSnackBar.setSnackBar(
          res.data.includes("already") ? "warning" : "success",
          res.data,
          4000
        )
      );
      setPmName("");
      setEmailAddresses([]);
    
      getPMTable();
    } catch (error) {
      console.log(error);
      dispatch(
        actionSnackBar.setSnackBar("Error", "somthing went wrong", 4000)
      );
    }
  };

  return (
    <>
      <StyledTableRow>
        {Object.entries(pm).map(([key, value], index) => {
          if (
            key !== "id" &&
            key !== "entity" &&
            key !== "recipients" &&
            key !== "agreement" &&
            key !== "isEdit"
          ) {
            return (
              <StyledTableCell key={index}>
                {pm?.isEdit === false ? (
                  pmName !== "" ? (
                    pmName
                  ) : (
                    value
                  )
                ) : (
                  <StyledTextField
                    value={pmName !== "" ? pmName : value}
                    onChange={(e) => changePmName(e)}
                  />
                )}
              </StyledTableCell>
            );
          } else if (key === "recipients") {
            return (
              <StyledTableCell
                key={index}
                style={{
                  maxWidth: "200px",
                }}
              >
                {pm?.isEdit === false ? (
                  <Typography
                    style={{ color: pm[key] === "null" ? "#15ba77" : null }}
                  >
                    {emailAddresses !== ""
                      ? emailAddresses.join(";")
                      : pm[key] === "null"
                      ? "No recipients"
                      : pm[key]}
                  </Typography>
                ) : (
                  <StyledTextField
                    value={
                      emailAddresses !== "" ? emailAddresses.join(";") : pm[key]
                    }
                    onChange={(e) => changeEmailAddresses(e)}
                  />
                )}
              </StyledTableCell>
            );
          }
        })}
        <StyledTableCell style={{ borderLeft: "1px solid #b3b3b3" }}>
          {pm?.isEdit === false ? (
            <Grid item container justifyContent="space-evenly">
              <Grid item>
                <IconButton size="small" onClick={() => editPm(pmIndex, true)}>
                  <Edit />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton size="small" onClick={() => setDialogOpen(true)}>
                  <Delete />
                </IconButton>
              </Grid>
            </Grid>
          ) : (
            <Grid item container justifyContent="space-evenly">
              <Grid item>
                <IconButton
                  size="small"
                  onClick={() => (pm.id === null ? savePmToDb() : updatePm())}
                >
                  <DoneIcon style={{ color: "#15ba77" }} />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton size="small" onClick={cancelEdit}>
                  <CloseIcon style={{ color: "#e30606" }} />
                </IconButton>
              </Grid>
            </Grid>
          )}
        </StyledTableCell>
      </StyledTableRow>
      <Dialog open={dialogOpen} classes={{ paper: classes.deleteDialog }}>
        <DialogTitle
          style={{ textAlign: "center", fontFamily: "'Mulish', sans-serif" }}
        >
          Confirm Delete
        </DialogTitle>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={11} style={{ textAlign: "center", paddingBottom: 20 }}>
            <Typography
              style={{
                fontSize: "1.125rem",
                fontFamily: "'Mulish', sans-serif",
              }}
            >
              {`Are you sure you want to delete ${pm.pm} ?`}
            </Typography>
          </Grid>
          <Grid item container xs={12} justifyContent="space-evenly">
            <Grid item>
              <Button
                style={{ backgroundColor: "#56df95", color: "#ffffff" }}
                onClick={() => deletePm(pmIndex)}
              >
                Yes
              </Button>
            </Grid>
            <Grid item>
              <Button
                style={{ backgroundColor: "#ff6464", color: "#ffffff" }}
                onClick={() => setDialogOpen(false)}
              >
                No
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}

export default PmTableRow;
