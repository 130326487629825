import React, { useEffect } from "react";
import {
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { EquitySpreadCreator } from "../equitySpreadCreator/EquitySpreadCreator";
import { ws, connectWS, sendEvent } from "../../../services/websocket";
import workerInstances from "../../../services/index";
import { useDispatch, useSelector } from "react-redux";

import * as rfqSlice from "../../../store/rfq/rfqSlice";
import { EquitySpreadList } from "../equitySpreadList/EquitySpreadList";

export const EquitiesHome = () => {
  const token = sessionStorage.getItem("token");
  const dispatch = useDispatch();
  useEffect(() => {
    // if (userName !== "") {
    if (ws !== null) {
      sendEvent({
        type: "equities_spread",
        data: {},
        metaData: {},
        token: token.replace("Bearer ", ""),
      });
    } else {
      connectWS(token.replace("Bearer ", ""), dispatch, rfqSlice);

      sendEvent({
        type: "equities_spread",
        data: {},
        metaData: {},
        token: token.replace("Bearer ", ""),
      });
    }
    workerInstances.WebSocketPricesInstance.connectPricerWS();
    return () => {
      workerInstances.WebSocketPricesInstance.closeWS();
    };
  }, []);
  return (
    <Grid container>
      <EquitySpreadCreator />
      <EquitySpreadList />
    </Grid>
  );
};
