import react, { useState, useEffect, useCallback } from "react";
import {
  Button,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  Checkbox,
  TableBody,
  CircularProgress,
  InputAdornment,
  useTheme,
  Modal,
  Box,
  Popper,
  Fade,
} from "@material-ui/core";

import {
  Grid,
  IconButton,
  InputBase,
  TableSortLabel,
  Typography,
} from "@mui/material";
import SearchIcon from "@material-ui/icons/Search";
import { useDispatch, useSelector } from "react-redux";
import {
  StyledTableCell,
  StyledTableRow,
  useStyles,
} from "../../Styles/fiStyles";
import TradeDialog from "./tradesDialog/TradeDialog";
import SplitDialog from "./splitDialog/SplitDialog";
import BookDialog from "./bookDialog/BookDialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import * as fiActions from "../../store/fi/fiSlice";
import * as actionSnackBar from "../../store/snackbar/action";
import * as rfqSlice from "../../store/rfq/rfqSlice";

import EmailModal from "../fx/modals/emailModal/EmailModal";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { ReactComponent as Split } from "../../assets/split.svg";
import { ReactComponent as DownloadCsv } from "../../assets/csv-download.svg";
import { ReactComponent as EditIcon } from "../../assets/Icon material-edit.svg";
import clsx from "clsx";
import axios from "axios";
import NumberFormat from "react-number-format";
import { END_POINT } from "../../utils";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import * as rfqPostTradeSlice from "../../store/rfq/rfqPostTradeSlice";
import moment from "moment";
import "./fIBlotter.css";
import PapaParse from "papaparse";
import { ws, connectWS, sendEvent } from "../../services/websocket";
import { createAndUpdateMifids } from "../../services/createMifids.js";
import { getExchangeRates } from "../../services/getExchangeRates.js";
import { PnlAttDialog, getTraders } from "./pnlAttDialog/PnlAttDialog";
import { useDebounce } from "./hooks/useDebounce.js"; // Make sure to import the debounce hook

function FiBlotter() {
  const dispatch = useDispatch();
  const fiTradesArr = useSelector((state) => state.fiSlice?.fiTrades);
  const linkedTrades = fiTradesArr
    ?.filter((t) => t.deal_id !== null && t.deal_id !== "")
    ?.reduce(function (rv, x) {
      (rv[x.deal_id] = rv[x.deal_id] || []).push(x);
      return rv;
    }, {});
  const linkedTradesArr = linkedTrades ? Object.entries(linkedTrades) : [];
  const tradesWithoutLinkArr = fiTradesArr?.filter(
    (t) => t.deal_id === null || t.deal_id === "",
  );
  const { chosenDeals, chosenTrades } = useSelector((state) => state.fiSlice);
  const { isNavigateToRfqBlotter } = useSelector((state) => state.rfqSlice);

  const userRole = sessionStorage.getItem("role");
  const classes = useStyles();
  const customeView = useMediaQuery("(width:1280px)");
  const token = sessionStorage.getItem("token");
  const [modalType, setModalType] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [confoModalType, setConfModalType] = useState("");
  const [openConfoDialog, setOpenConfoDialog] = useState(false);
  const [openSplitDialog, setOpenSplitDialog] = useState(false);
  const [openBookDialog, setOpenBookDialog] = useState(false);
  const [filterArr, setFilterArr] = useState(fiTradesArr);
  const [tradesToBook, setTradesToBook] = useState("");
  const [data, setData] = useState({});
  const [metaData, setMetaData] = useState({});
  const [dateFormat, setDateFormat] = useState("created_on");
  const [orderDirection, setOrderDirection] = useState("desc");
  const [valueToOrderBy, setValueToOrderBy] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [dateSelected, setDateSelected] = useState("");
  const [tradingAmount, setTradingAmount] = useState("");
  const [tradingAmountSource, setTradingAmountSource] = useState("");
  const [editTradingAmountOn, setEditTradingAmountOn] = useState(false);
  const [role, setRole] = useState("");
  const [anchorElPnlAtt, setAnchorElPnlAtt] = useState(null);
  const [openPnlDialog, setOpenPnlAttDialog] = useState(false);
  const [isSplitDialogClientListLoading, setIsSplitDialogClientListLoading] =
    useState(true);
  const [isTradeDialogLoading, setIsTradeDialogLoading] = useState(true);
  const [pnlDealId, setPnlDealId] = useState(null);
  const [tradersList, setTradersList] = useState([]);
  const debouncedDate = useDebounce(dateSelected, 1000); // 500ms delay

  const isPnlAttOpen = Boolean(anchorElPnlAtt);

  const categories = [
    "trade_id",
    "status",
    "trader",
    "trader_rsp",
    "client",
    "trade_date",
    "settlement_date",
    "security_name",
    "isin",
    "side",
    "quantity",
    "price",
    "accrued_interest",
    "currency",
    "net_cash",
    "net_cash_currency",
    "time",
    "gppCode",
    "created_by",
    "created_on",
    "version",
    "last_updated_by",
    "last_updated_at",
    "uuid",
    "deal_id",
    "unresolved_client",
    "unresolved_trader",
    "comment",
    "client_id",
    "checked",
    "company",
    "merrill_account_number",
    "client_type",
    "is_structured",
  ];

  const openDialogAction = (type) => {
    dispatch(
      fiActions.setSelectedTradeToEdit(
        fiTradesArr.filter((t) => t.checked === true)[0],
      ),
    );
    setModalType(type);
    setOpenDialog(!openDialog);
  };
  const openSplitDialogAction = () => {
    dispatch(
      fiActions.setSelectedTradeToEdit(
        fiTradesArr.filter((t) => t.checked === true)[0],
      ),
    );
    setOpenSplitDialog(!openSplitDialog);
  };

  const handleDialogClose = () => {
    setModalType("");
    setOpenDialog(!openDialog);
  };
  const handleSplitDialogClose = () => {
    setOpenSplitDialog(!openSplitDialog);
    setIsSplitDialogClientListLoading(true);
    setIsTradeDialogLoading(true);
  };

  const openBookDialogAction = () => {
    setOpenBookDialog(!openBookDialog);
  };
  const handleBookDialogClose = () => {
    setOpenBookDialog(!openBookDialog);
  };

  const openConfoDialogAction = (type) => {
    setConfModalType(type);
    setOpenConfoDialog(!openConfoDialog);
  };

  const handleConfoDialogClose = () => {
    setConfModalType("");
    setOpenConfoDialog(!openConfoDialog);
  };

  const handlePnlAttModalOpen = () => {
    setOpenPnlAttDialog(true);
    setAnchorElPnlAtt(null);
  };
  const handlePnlAttModalClose = () => {
    setOpenPnlAttDialog(false);
  };

  const searchFi = (searchValue) => {
    dispatch(fiActions.filterTrades(searchValue));
  };

  const handleChecked = (id) => {
    dispatch(fiActions.setCheckedTrade(id));
    setFilterArr(fiTradesArr);
  };
  const exportToCsv = () => {
    // linkedTradesArr, tradesWithoutLinkArr;
    const linkedTrades = [];
    if (linkedTradesArr.length > 0) {
      linkedTradesArr.forEach((deal) => {
        deal[1].forEach((element) => {
          linkedTrades.push(element);
        });
      });
    }

    const csv = PapaParse.unparse(
      chosenTrades.length > 0
        ? chosenTrades
        : fiTradesArr.length > 0
        ? [...linkedTrades, ...tradesWithoutLinkArr]
        : (setModalMessage("There are no FI trades to export"),
          setOpenModal(true)),
    );
    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(
        blob,
        `Fi Trades ${moment(new Date()).format("YYYY-MM-DD")}`,
      );
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute(
          "download",
          `Fi Trades ${moment(new Date()).format("YYYY-MM-DD")}`,
        );
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setModalMessage("");
  };

  const linkValidation = (fiTrades) => {
    const messageArr = [];
    let sumQuantityBuy = 0,
      sumQuantitySell = 0,
      sumAccruedInterestBuy = 0,
      sumAccruedInterestSell = 0;
    let checkedTrades = fiTrades.filter((t) => t.checked === true);
    let onlyIsin = checkedTrades.map((t) => t.isin);
    let onlySettlementDate = checkedTrades.map((t) => t.settlement_date);
    let onlyCompany = checkedTrades.map((t) => t.company);
    if (
      checkedTrades.filter((trade) => trade.status === "unresolved").length > 0
    ) {
      messageArr.push({
        status: false,
        message: "Unresolved trades cannot be linked together ",
      });
      return messageArr;
    }
    if (checkedTrades.filter((trade) => trade.status === "ignore").length > 0) {
      messageArr.push({
        status: false,
        message: "Ignored trades cannot be linked together ",
      });
      return messageArr;
    }
    if (onlyCompany.every((company) => company === checkedTrades[0].company)) {
      if (onlyIsin.every((isin) => isin === checkedTrades[0].isin)) {
        if (
          onlySettlementDate.every(
            (settlementDate) =>
              settlementDate === checkedTrades[0].settlement_date,
          )
        ) {
          checkedTrades.map((t) => {
            if (t.side === "buy") {
              sumQuantityBuy += +t.quantity;
              sumAccruedInterestBuy += +t.accrued_interest;
            } else if (t.side === "sell") {
              sumQuantitySell += +t.quantity;
              sumAccruedInterestSell += +t.accrued_interest;
            }
          });
          if (sumQuantitySell === sumQuantityBuy) {
            if (Math.abs(sumAccruedInterestBuy - sumAccruedInterestSell) <= 1) {
              return true;
            } else {
              messageArr.push({
                status: false,
                message:
                  "The sum of accrued interest of all the buys should be equal to the sum of accrued interest of all the sells",
              });
              return messageArr;
            }
          } else {
            messageArr.push({
              status: false,
              message:
                "The sum of quantity of all the buys should be equal to the sum of quantity of all the sells",
            });

            return messageArr;
          }
        } else {
          messageArr.push({
            status: false,
            message: "All the selected trades should have same settlement date",
          });
          return messageArr;
        }
      } else {
        messageArr.push({
          status: false,
          message: "All the selected trades should have the same isin",
        });
        return messageArr;
      }
    } else {
      messageArr.push({
        status: false,
        message: "All the selected trades should have the same company name",
      });
      return messageArr;
    }
  };

  const handleLink = async () => {
    const linkValidationResult = linkValidation(fiTradesArr);
    if (linkValidationResult !== true) {
      // dispatch(fiActions.resetChosenTrades());
      setModalMessage(linkValidationResult[0].message);
      setOpenModal(true);
    } else if (linkValidationResult === true) {
      const token = sessionStorage.getItem("token");
      let checkedTrades = fiTradesArr.filter((t) => t.checked === true);
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}` +
          END_POINT.FI_TRADES +
          "/link_trades_to_deal",
        checkedTrades,
        { headers: { Authorization: token } },
      );
      dispatch(fiActions.resetChosenTrades());
    }
  };
  const handleDeLink = async () => {
    const linkValidationResult = linkValidation(fiTradesArr);
    if (linkValidationResult !== true) {
      setModalMessage(linkValidationResult[0].message);
      setOpenModal(true);
    } else if (linkValidationResult === true) {
      const token = sessionStorage.getItem("token");
      let checkedTrades = fiTradesArr.filter((t) => t.checked === true);
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}` +
          END_POINT.FI_TRADES +
          "/dlink_trades",
        checkedTrades,
        { headers: { Authorization: token } },
      );
      dispatch(fiActions.resetChosenTrades());
      dispatch(fiActions.resetDealsArr());
    }
  };

  const handleChangeDealCheckBox = (e, dealId) => {
    const chosenTradesArr = [...chosenTrades];
    const tradesToCheck = fiTradesArr.filter((t) => t.deal_id === dealId);
    const chosenDealIndex = chosenDeals.findIndex(
      (deal_id) => deal_id === dealId,
    );
    if (e.target.checked) {
      if (chosenDealIndex === -1) {
        dispatch(fiActions.addToDealsArr(dealId));
        tradesToCheck.map((t) => {
          t = { ...t, checked: true };
          const indexToUpdate = fiTradesArr.findIndex(
            (trade) => trade.uuid === t.uuid,
          );

          const index = chosenTradesArr.findIndex(
            (trade) => t.trade_id === trade.trade_id,
          );
          if (index === -1) {
            chosenTradesArr.push(t);
          }
          dispatch(
            fiActions.setCheckboxInsideDeal({ indexToUpdate, trade: t }),
          );
        });
        dispatch(fiActions.setChosenTrades(chosenTradesArr));
      }
    } else {
      tradesToCheck.map((t) => {
        t = { ...t, checked: false };
        const indexToUpdate = fiTradesArr.findIndex(
          (trade) => trade.uuid === t.uuid,
        );
        dispatch(fiActions.setCheckboxInsideDeal({ indexToUpdate, trade: t }));
        const tempIndex = chosenTradesArr.findIndex(
          (tr) => tr.trade_id === t.trade_id,
        );
        chosenTradesArr.splice(tempIndex, 1);
      });
      dispatch(fiActions.setChosenTrades(chosenTradesArr));
      const index = chosenDeals.findIndex((d) => d === dealId);
      dispatch(fiActions.removeFromDealsArr(index));
    }
  };

  const checkBloombergConectionForMiFid = async () => {
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_BLOOMBERG}heartbeat`,
        // "http://localhost:8000/api/bloomberg_market_data/heartbeat"
      );
      if (res?.data?.can_connect === false) {
        dispatch(
          actionSnackBar.setSnackBar(
            "error",
            "Missing Bloomberg Connection",
            3000,
          ),
        );
      } else {
        try {
          let missingMiFidTrades = await axios.get(
            `${process.env.REACT_APP_BASE_URL}` +
              END_POINT.FI_TRADES +
              "/get_missing_mifid",
            { headers: { Authorization: token } },
          );
          if (missingMiFidTrades.status === 200) {
            if (missingMiFidTrades.data === "No trades missing MiFid") {
              dispatch(
                actionSnackBar.setSnackBar(
                  "success",
                  missingMiFidTrades.data,
                  4000,
                ),
              );
            } else {
              let res = await createAndUpdateMifids(missingMiFidTrades?.data);
              if (res === "All trades updated") {
                dispatch(actionSnackBar.setSnackBar("success", res, 4000));
              }
            }
          }
        } catch (error) {
          console.log(error);
          dispatch(
            actionSnackBar.setSnackBar(
              "Error",
              error.response.data.message,
              4000,
            ),
          );
        }
      }
    } catch (error) {
      console.log(error);
      dispatch(
        actionSnackBar.setSnackBar("Error", error.response.data.message, 3000),
      );
    }

    // ! update Exchange rates
    getExchangeRates();
  };

  const handleBookDeal = async (e) => {
    let arr = [];
    chosenDeals.map((deal) => {
      const filter = fiTradesArr.filter((t) => t.deal_id === deal);
      filter.map((t) => {
        arr.push(t);
      });
    });
    openBookDialogAction();
    setTradesToBook(arr);
  };
  const handleDatePick = (e) => {
    setDateSelected(e.target.value);
  };

  useEffect(() => {
    if (debouncedDate) {
      setSearchValue("");
      setMetaData({ date: debouncedDate });
      dispatch(fiActions.resetChosenTrades());
      dispatch(fiActions.resetDealsArr());

      if (ws !== null) {
        sendEvent({
          type: "get_fi_trades",
          data: {},
          metaData: { date: debouncedDate, dateFormat: dateFormat },
          token: token.replace("Bearer ", ""),
        });
      } else {
        connectWS(token.replace("Bearer ", ""), dispatch, rfqSlice);
        sendEvent({
          type: "get_fi_trades",
          data: {},
          metaData: { date: debouncedDate, dateFormat: dateFormat },
          token: token.replace("Bearer ", ""),
        });
      }
    }
  }, [debouncedDate]);
  const handleDateFormatPick = (e) => {
    setDateFormat(e.target.value);
    if (metaData.date !== undefined) {
      if (ws !== null) {
        sendEvent({
          type: "get_fi_trades",
          data: {},
          metaData: { date: metaData.date, dateFormat: e.target.value },
          token: token.replace("Bearer ", ""),
        });
      } else {
        connectWS(token.replace("Bearer ", ""), dispatch, rfqSlice);
        sendEvent({
          type: "get_fi_trades",
          data: {},
          metaData: { date: metaData.date, dateFormat: e.target.value },
          token: token.replace("Bearer ", ""),
        });
      }
    }
  };
  const handleSearchByValue = async (search_value) => {
    setData({ ...data, searchValue: search_value });
    setSearchValue(search_value);
    setDateSelected("");
    dispatch(fiActions.resetChosenTrades());
    dispatch(fiActions.resetDealsArr());
    if (ws !== null) {
      sendEvent({
        type: "get_fi_trades",
        data: { ...data, searchValue: search_value },
        metaData: {},
        token: token.replace("Bearer ", ""),
      });
    } else {
      connectWS(token.replace("Bearer ", ""), dispatch, rfqSlice);
      sendEvent({
        type: "get_fi_trades",
        data: { ...data, searchValue: search_value },
        metaData: {},
        token: token.replace("Bearer ", ""),
      });
    }
  };
  const handleRequestSort = (category) => {
    const isAscending = valueToOrderBy === category && orderDirection === "asc";
    setValueToOrderBy(category);
    setOrderDirection(isAscending ? "desc" : "asc");
    let copy = [...fiTradesArr];
    const order = isAscending ? "desc" : "asc";

    if (
      category === "price" ||
      category === "accrued_interest" ||
      category === "net_cash" ||
      category === "quantity"
    ) {
      if (order === "asc") {
        let temp = copy.sort((a, b) => a[`${category}`] - b[`${category}`]);
        dispatch(fiActions.setFiTrades(temp));
      } else {
        let temp = copy.sort((a, b) => b[`${category}`] - a[`${category}`]);

        dispatch(fiActions.setFiTrades(temp));
      }
    } else {
      if (order === "asc") {
        let temp = copy.sort((a, b) => {
          let x = a[`${category}`].toLowerCase();
          let y = b[`${category}`].toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });
        dispatch(fiActions.setFiTrades(temp));
      } else {
        let temp = copy.sort((a, b) => {
          let x = a[`${category}`].toLowerCase();
          let y = b[`${category}`].toLowerCase();
          if (y < x) {
            return -1;
          }
          if (y > x) {
            return 1;
          }
          return 0;
        });
        dispatch(fiActions.setFiTrades(temp));
      }
    }
  };
  const handleIgnore = () => {
    dispatch(fiActions.handleIgnoreTrade());
  };
  const calculatePnl = (trades) => {
    let sellNetCashSum = 0,
      buyNetCashSum = 0;
    trades.forEach((trade) => {
      if (trade.side === "sell") {
        sellNetCashSum += Number(trade.net_cash);
      } else if (trade.side === "buy") {
        buyNetCashSum += Number(trade.net_cash);
      }
    });
    const pnl = sellNetCashSum - buyNetCashSum;
    return pnl;
  };

  const handleTradingAmount = async (e) => {
    if (e.target.value >= 0 && e.target.value <= 250000000) {
      setTradingAmount(e.target.value);
    } else if (e.target.value < 0) {
      setTradingAmount(0);
    } else if (e.target.value > 0) {
      setTradingAmount(250000000);
    }
  };
  const closeTradingAmount = () => {
    setEditTradingAmountOn(false);
    setTradingAmount(tradingAmountSource);
  };
  const saveTradingAmount = async () => {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}` +
        END_POINT.FI_TRADES +
        "/trading_amount",
      { tradingAmount },
      { headers: { Authorization: token } },
    );
    setTradingAmountSource(tradingAmount);
    setEditTradingAmountOn(false);
  };

  const onMouseEnterPnlAtt = (event, obj) => {
    setAnchorElPnlAtt(anchorElPnlAtt ? null : event.currentTarget);
    setPnlDealId(obj[0]);
  };

  const onMouseLeavePnlAtt = () => {
    setAnchorElPnlAtt(null);
    setPnlDealId(null);
  };

  useEffect(() => {
    const obj = {
      value: 2,
      key: "postTradeSplitNumber",
    };
    dispatch(rfqPostTradeSlice.setPostTradeSplitNumber(obj));
    dispatch(rfqPostTradeSlice.resetPostTradeStats());
    const getTradingAmount = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}` +
          END_POINT.FI_TRADES +
          "/trading_amount",
        { headers: { Authorization: token } },
      );
      setTradingAmountSource(res.data.response[0].trading_amount);
      setTradingAmount(res.data.response[0].trading_amount);
      setRole(res.data.role);
    };
    getTradingAmount();
    getTraders(setTradersList);
    return () => {
      dispatch(fiActions.resetDealsArr());
    };
  }, []);

  // message handler for worker + socket
  useEffect(() => {
    const getFiTrades = (message) => {
      switch (message.data.type) {
        case "get_fi_trades":
          dispatch(fiActions.getFiDataAsync(message.data.trades, "first_load"));
          break;
        case "get_new_fi_trade":
          dispatch(
            fiActions.getFiDataAsync(message.data.trade, "new_fi_trade"),
          );
          break;
        case "handle_deal":
          dispatch(fiActions.getFiDataAsync(message.data.trade, "handle_deal"));
          break;
        case "update_trading_amount":
          setTradingAmountSource(message.data.tradeAmount);
          setTradingAmount(message.data.tradeAmount);
          break;
        case "fi_pnl_att_update":
          const { pnlDealId, tradersSplitPnlArr } = message.data;
          dispatch(fiActions.updatePnlAtt(pnlDealId, tradersSplitPnlArr));
          break;
        default:
          break;
      }
    };
    window.addEventListener("message", getFiTrades);

    return () => {
      window.removeEventListener("message", getFiTrades);
    };
  }, []);

  useEffect(() => {
    // if (userName !== "") {
    if (ws !== null) {
      sendEvent({
        type: "get_fi_trades",
        data: {},
        metaData: {},
        token: token.replace("Bearer ", ""),
      });
    } else {
      connectWS(token.replace("Bearer ", ""), dispatch, rfqSlice);

      sendEvent({
        type: "get_fi_trades",
        data: {},
        metaData: {},
        token: token.replace("Bearer ", ""),
      });
    }
    // }
    return async () => {
      sendEvent({
        type: "unsubscribe_fi_trades",
      });
      if (isNavigateToRfqBlotter) {
        dispatch(rfqSlice.getRfq());
        dispatch(rfqSlice.setIsNavigateToRfqBlotter(false));
      }
    };
  }, []);
  return (
    <Grid container style={{ paddingTop: 32 }}>
      {fiTradesArr ? (
        <>
          <Grid container>
            <Grid
              xs={4}
              item
              // lg={customeView ? 11 : 12}
              // md={10}
              style={{ height: "0px" }}
            >
              <Typography style={{ color: "white", fontSize: 32 }}>
                Fixed Income Blotter
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Grid
                container
                xs={10}
                style={{
                  border: "1px solid #686B76",
                  height: "50px",
                  borderRadius: "4px",
                }}
              >
                <Grid item xs={1}></Grid>
                <Grid
                  item
                  xs={5}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography style={{ color: "#686B76", fontSize: "14px" }}>
                    Open Trading Amount :
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {editTradingAmountOn === false ? (
                    <Typography style={{ color: "white", fontSize: "14px" }}>
                      {tradingAmount}
                    </Typography>
                  ) : (
                    <InputBase
                      style={{
                        color: "white",
                        border: "1px solid #686B76",
                        borderRadius: "4px",
                        height: "20px",
                      }}
                      className={classes.searchInput}
                      variant="outlined"
                      onChange={(e) => setTradingAmount(e.target.value)}
                      value={tradingAmount}
                      placeholder="Set Trading Amount"
                      // inputProps={{
                      //   type: "number",
                      // }}
                    />
                  )}
                </Grid>
                {role === "operations" ? (
                  <Grid
                    item
                    xs={2}
                    style={{
                      marginLeft: "0px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      style={{ fill: "#49cb9cf7" }}
                      // onClick={(e) =>
                      //   handleSearchByValue(e.target.value.toLowerCase())
                      // }
                      size={"small"}
                    >
                      {editTradingAmountOn === false ? (
                        <EditIcon
                          style={{
                            transform: "scale(1)",
                            // width: "50px",
                            // border: "1px solid red",
                          }}
                          onClick={(e) => setEditTradingAmountOn(true)}
                        />
                      ) : editTradingAmountOn === true ? (
                        <>
                          <CheckIcon
                            style={{
                              fill: "#49cb9cf7",
                              transform: "scale(1.4)",
                              marginRight: "20px",
                            }}
                            onClick={(e) => saveTradingAmount()}
                          />
                          <CloseIcon
                            style={{
                              fill: "red",
                              transform: "scale(1.4)",
                            }}
                            onClick={(e) => closeTradingAmount()}
                          />
                        </>
                      ) : null}
                    </IconButton>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={12} md={10} style={{ paddingBlock: 70 }}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ height: 0 }}
            >
              {chosenTrades?.length === 1 &&
              chosenTrades[0]?.status !== "unresolved" &&
              chosenTrades[0]?.deal_id !== null &&
              chosenTrades[0]?.deal_id !== "" &&
              chosenDeals.length === 0 ? (
                <Grid item xs={2} lg={customeView ? 1 : 2}>
                  <Button
                    onClick={() => openConfoDialogAction("fi")}
                    className={clsx(classes.sendConfoBtn, {
                      [classes.disabledButton]: true,
                    })}
                  >
                    Send Confo
                  </Button>
                </Grid>
              ) : // <span></span>
              null}
              {chosenTrades.filter(
                (t) =>
                  t.checked === true &&
                  (t.deal_id === null || t.deal_id === ""),
              ).length === 1 && chosenTrades.length === 1 ? (
                <Grid item lg={customeView ? 5 : 4} md={5}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                      <Button
                        onClick={() => openDialogAction("edit")}
                        className={clsx(classes.editBtn, {
                          [classes.disabledButton]: true,
                        })}
                      >
                        Edit
                      </Button>
                    </Grid>
                    {chosenTrades[0]?.status !== "unresolved" && (
                      <Grid item>
                        <Button
                          onClick={() => openConfoDialogAction("fi")}
                          className={clsx(classes.sendConfoBtn, {
                            [classes.disabledButton]: true,
                          })}
                        >
                          Send Confo
                        </Button>
                      </Grid>
                    )}
                    <Grid item>
                      <Button
                        onClick={() => openDialogAction("copy")}
                        className={clsx(classes.copyBtn, {
                          [classes.disabledButton]: true,
                        })}
                      >
                        Copy
                      </Button>
                    </Grid>{" "}
                    <Grid item>
                      <Button
                        onClick={() => handleIgnore()}
                        className={clsx(classes.ignoreBtn, {
                          [classes.disabledButton]: true,
                        })}
                      >
                        Ignore
                      </Button>
                    </Grid>
                    <Grid item>
                      <IconButton
                        onClick={() => openSplitDialogAction()}
                        style={{
                          backgroundColor: "#3A3C3F",
                          color: "#ffffff",
                          borderRadius: 4,
                          height: 41,
                          width: 40,
                        }}
                      >
                        <Split />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              ) : chosenTrades.filter(
                  (trade) => trade.deal_id !== "" && trade.deal_id !== null,
                ).length === 0 && chosenTrades.length > 1 ? (
                <Grid item lg={customeView ? 5 : 3} md={5}>
                  <Grid container>
                    <Grid item xs={2}>
                      <Button
                        onClick={() => handleLink()}
                        className={clsx(classes.linkBtn, {
                          [classes.disabledButton]: true,
                        })}
                      >
                        Link
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              ) : chosenDeals.length > 0 ? (
                <Grid item>
                  <Grid
                    container
                    alignItems="center"
                    spacing={8}
                    display={"flex"}
                  >
                    {chosenDeals.length > 0 && chosenTrades.length === 1 && (
                      <Grid
                        item
                        xs={5}
                        lg={customeView ? 3 : 3}
                        style={{ marginRight: "10px" }}
                      >
                        <Button
                          onClick={() => openConfoDialogAction("fi")}
                          className={clsx(classes.sendConfoBtn, {
                            [classes.disabledButton]: true,
                          })}
                        >
                          Send Confo
                        </Button>
                      </Grid>
                    )}
                    <Grid item xs={2}>
                      <Button
                        className={clsx(classes.delinkBtn, {
                          [classes.disabledButton]: true,
                        })}
                        onClick={handleDeLink}
                      >
                        De-Link
                      </Button>
                    </Grid>

                    {chosenDeals.length > 0 &&
                    chosenDeals
                      .map((deal) => {
                        let list = linkedTradesArr.filter((t) => deal === t[0]);
                        if (list.length > 0) {
                          list = list[0][1];
                        }

                        if (
                          list.filter((t) => t.status === "booked").length === 0
                        )
                          return "notBooked";
                        else return "booked";
                      })
                      .filter((a) => a === "booked").length === 0 ? (
                      <Grid item xs={2}>
                        {sessionStorage.getItem("role") === "operations" ? (
                          <Button
                            className={clsx(classes.bookFiBtn, {
                              [classes.disabledButton]: true,
                            })}
                            onClick={handleBookDeal}
                          >
                            Book
                          </Button>
                        ) : null}
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              ) : (
                <Grid item>
                  <Grid container alignItems="center" spacing={2}></Grid>
                </Grid>
              )}
              {/* Right Section*/}
              <Grid
                item
                md={9}
                lg={customeView ? 11 : 7}
                style={{ height: 45 }}
              >
                <Grid
                  spacing={1}
                  justifyContent="flex-end"
                  container
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={4}
                    container
                    style={{
                      alignItems: "center",
                      // width: "250px",
                    }}
                  ></Grid>
                  <Grid
                    container
                    style={{ width: "250px" }}
                    alignSelf={"center"}
                  >
                    <Grid
                      item
                      order={1}
                      style={{
                        width: "250px",
                        zIndex: "3",
                        marginTop: "9px",
                      }}
                    >
                      <InputBase
                        style={{
                          color: "#ffffff",
                          border: "1px solid #686B76",
                          borderRadius: "4px",
                          width: "250px",
                          height: "40px",
                        }}
                        onChange={(e) => handleDatePick(e)}
                        name="date"
                        variant="outlined"
                        type="date"
                        className={"select-date-picker"}
                        value={dateSelected}
                      />
                    </Grid>
                    <Grid
                      item
                      order={2}
                      style={{
                        width: "130px",
                        position: "absolute",
                        zIndex: "5",
                        marginTop: "20px",
                      }}
                    >
                      <select
                        className="select-date-format"
                        onChange={(e) => {
                          handleDateFormatPick(e);
                        }}
                      >
                        <option value={"created_on"}>Created on</option>
                        <option value={"trade_date"}>Trade date</option>
                        <option value={"settlement_date"}>
                          Settlement date
                        </option>
                      </select>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <InputBase
                      style={{
                        color: "white",
                        border: "1px solid #686B76",
                        borderRadius: "4px",
                        height: "40px",
                      }}
                      className={classes.searchInput}
                      variant="outlined"
                      onChange={(e) =>
                        handleSearchByValue(e.target.value.toLowerCase())
                      }
                      value={searchValue}
                      placeholder="Search"
                      endAdornment={
                        <InputAdornment position="start">
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid item>
                    <IconButton
                      size="small"
                      style={{
                        fill: "#49cb9cf7",
                      }}
                      onClick={() => exportToCsv()}
                    >
                      <DownloadCsv style={{ width: "35px", height: "35px" }} />
                    </IconButton>
                  </Grid>
                  {userRole === "operations" && (
                    <Grid item>
                      <Button
                        className={clsx(classes.bookFiBtn, {
                          [classes.disabledButton]: true,
                        })}
                        onClick={checkBloombergConectionForMiFid}
                      >
                        MiFid
                      </Button>
                    </Grid>
                  )}

                  <Grid item>
                    <Button
                      onClick={() => openDialogAction("new")}
                      className={classes.btn}
                      disabled={
                        !(
                          fiTradesArr.filter((t) => t.checked === true)
                            .length === 0
                        )
                      }
                    >
                      Create
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <TableContainer
              style={{
                borderRadius: "8px 8px 8px 8px",
                border: "1px solid #686B76",
                height: "70vh",
                width: "100%",
              }}
            >
              <Table size="medium">
                <TableHead>
                  <StyledTableRow style={{ margin: "0", width: "10%" }}>
                    <TableCell
                      style={{
                        position: "sticky",
                        left: 0,
                        background: "inherit",
                        width: "1%",
                        borderBottom: "1px solid #686B76",
                      }}
                    ></TableCell>
                    {categories.map((category, index) => {
                      if (category !== "uuid") {
                        return category !== "trade_id" &&
                          category !== "checked" &&
                          category !== "deal_id" &&
                          category !== "unresolved_client" &&
                          category !== "unresolved_trader" &&
                          category !== "bookedTo" &&
                          category !== "client_id" &&
                          category !== "trader_rsp" ? (
                          <StyledTableCell
                            key={index}
                            style={{
                              fontSize: "12px",
                              color: "#686B76",
                              borderBottom: "1px solid #686B76",
                              textTransform:
                                category === "isin"
                                  ? "uppercase"
                                  : "capitalize",
                            }}
                          >
                            <TableSortLabel
                              direction={orderDirection}
                              onClick={() => handleRequestSort(category)}
                              id={"sort-header"}
                            >
                              {category.replaceAll("_", " ")}
                            </TableSortLabel>
                          </StyledTableCell>
                        ) : null;
                      }
                    })}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {linkedTradesArr.length > 0 &&
                    linkedTradesArr[0][0] !== undefined &&
                    linkedTradesArr[0] !== null &&
                    linkedTradesArr?.map((obj, idx) => {
                      return (
                        <>
                          <StyledTableRow
                            onClick={() => {
                              dispatch(fiActions.handleDealRowSelect(obj));
                            }}
                            style={{
                              backgroundColor:
                                chosenDeals.filter((deal) => deal === obj[0])
                                  .length > 0 && chosenDeals.length > 0
                                  ? "#363434"
                                  : "#171717",
                            }}
                          >
                            <StyledTableCell style={{ borderBottom: "none" }}>
                              <Checkbox
                                name="checkedH"
                                className={classes.checkbox}
                                onChange={(e) => {
                                  handleChangeDealCheckBox(e, obj[0]);
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                checked={
                                  chosenDeals.filter((deal) => deal === obj[0])
                                    .length > 0 && chosenDeals.length > 0
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell
                              colSpan={"5%"}
                              style={{
                                borderBottom: "none",
                                width: "150px",
                              }}
                            >
                              <Typography
                                variant="body1"
                                style={{
                                  fontSize: "14px",
                                  color: "white",
                                  textAlign: "left",
                                }}
                              >
                                Deal: {obj[0]}{" "}
                                {obj[1][0].bookedTo !== "" &&
                                obj[1][0].bookedTo !== null &&
                                obj[1][0].bookedTo !== undefined
                                  ? `(${obj[1][0].bookedTo})`
                                  : ""}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell
                              colSpan={"5%"}
                              style={{
                                borderBottom: "none",
                                width: "150px",
                              }}
                            >
                              <Typography
                                variant="body1"
                                style={{
                                  fontSize: "14px",
                                  color: "white",
                                  textAlign: "left",
                                }}
                              >
                                PNL :
                                <span
                                  style={{
                                    color:
                                      Number(calculatePnl(obj[1])) >= 0
                                        ? "#22D865"
                                        : "#FF4E32",
                                    marginLeft: "5px",
                                  }}
                                >
                                  {calculatePnl(obj[1]).toFixed(2)}
                                  {obj[1][0].currency}
                                </span>
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell
                              colSpan={"5%"}
                              style={{
                                borderBottom: "none",
                                width: "150px",
                              }}
                              onMouseEnter={(e) => onMouseEnterPnlAtt(e, obj)}
                              key={idx}
                            >
                              <Typography
                                variant="body1"
                                style={{
                                  fontSize: "14px",
                                  color: "white",
                                  textAlign: "left",
                                }}
                              >
                                PNL Attribution :
                                {obj[1][0]?.pnlAtt !== null &&
                                  obj[1][0]?.pnlAtt !== "" &&
                                  JSON.parse(obj[1][0].pnlAtt).map(
                                    (split, index, array) => {
                                      const isLastObject =
                                        index === array.length - 1;
                                      const trader = tradersList.filter(
                                        (t) => t.id === split.traderId,
                                      );

                                      return (
                                        <>
                                          {trader.length > 0 ? (
                                            <span>
                                              {trader[0].value} :{" "}
                                              {split.percentageSplit}%
                                              {!isLastObject && (
                                                <span> | </span>
                                              )}
                                            </span>
                                          ) : null}
                                        </>
                                      );
                                    },
                                  )}
                              </Typography>
                            </StyledTableCell>
                          </StyledTableRow>

                          {obj[1].map((obj, idx) => {
                            return (
                              <>
                                <StyledTableRow
                                  key={idx}
                                  style={{
                                    textAlign: "center",
                                    margin: "0",
                                    height: 30,
                                    backgroundColor:
                                      chosenTrades.filter(
                                        (t) =>
                                          Number(t.trade_id) ===
                                          Number(obj.trade_id),
                                      ).length > 0 && chosenTrades.length > 0
                                        ? "#2b2a2a"
                                        : "#171717",
                                  }}
                                  onClick={() => handleChecked(obj.trade_id)}
                                  xs={12}
                                  onMouseLeave={onMouseLeavePnlAtt}
                                >
                                  <StyledTableCell
                                    style={{
                                      border: "none",
                                      textAlign: "center",
                                      position: "sticky",
                                      width: "5%",
                                      left: 0,
                                      background: "inherit",
                                    }}
                                  >
                                    <Checkbox
                                      size="small"
                                      checked={obj.checked}
                                      className={classes.checkbox}
                                      icon={<RadioButtonUncheckedIcon />}
                                      checkedIcon={<RadioButtonCheckedIcon />}
                                      name="checkedH"
                                    />
                                  </StyledTableCell>

                                  {fiTradesArr !== undefined &&
                                    fiTradesArr !== null &&
                                    Object.keys(fiTradesArr[0]).map(
                                      (category, idx) => {
                                        console.log({
                                          category,
                                          value: obj[`${category}`],
                                        });
                                        const value = obj[`${category}`];
                                        return category !== "trade_id" &&
                                          category !== "checked" &&
                                          category !== "uuid" &&
                                          category !== "deal_id" &&
                                          category !== "unresolvedClient" &&
                                          category !== "unresolvedTrader" &&
                                          category !== "bookedTo" &&
                                          category !== "client_id" &&
                                          category !== "pnlAtt" &&
                                          category !== "importRef" &&
                                          category !== "trader_rsp" ? (
                                          <StyledTableCell
                                            key={idx}
                                            style={{ border: "none" }}
                                          >
                                            <Typography
                                              style={{
                                                fontSize: "12px",
                                                fontWeight: 500,
                                                color:
                                                  obj[`${category}`] ===
                                                    "live" ||
                                                  obj[`${category}`] === "sell"
                                                    ? "#FF4E32"
                                                    : obj[`${category}`] ===
                                                        "confirmed" ||
                                                      obj[`${category}`] ===
                                                        "booked" ||
                                                      obj[`${category}`] ===
                                                        "buy"
                                                    ? "#22D865"
                                                    : obj[`${category}`] ===
                                                      "draft"
                                                    ? "#FFB300"
                                                    : obj[`${category}`] ===
                                                      "GPP pending"
                                                    ? "#FFB300"
                                                    : obj[`${category}`] ===
                                                      "to be booked"
                                                    ? "#FFB300"
                                                    : obj[`${category}`] ===
                                                      "unresolved"
                                                    ? "#FFB300"
                                                    : obj[`${category}`] ===
                                                      "new"
                                                    ? "#22D865"
                                                    : obj.client === "VCON" &&
                                                      category === "client"
                                                    ? "#FFB300"
                                                    : obj.trader === "VCON" &&
                                                      category === "trader"
                                                    ? "#FFB300"
                                                    : null,
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              {category === "trader" &&
                                              obj.trader === "VCON" ? (
                                                `!${obj.unresolvedTrader}`
                                              ) : category === "client" &&
                                                obj.client === "VCON" ? (
                                                `!${obj.unresolvedClient}`
                                              ) : category === "quantity" ? (
                                                <NumberFormat
                                                  thousandsGroupStyle="thousand"
                                                  value={value}
                                                  decimalSeparator="."
                                                  displayType="text"
                                                  type="text"
                                                  thousandSeparator={true}
                                                  allowNegative={true}
                                                  decimalScale={0}
                                                />
                                              ) : category ===
                                                "accrued_interest" ? (
                                                <NumberFormat
                                                  thousandsGroupStyle="thousand"
                                                  value={value}
                                                  decimalSeparator="."
                                                  displayType="text"
                                                  type="text"
                                                  thousandSeparator={true}
                                                  allowNegative={true}
                                                  decimalScale={2}
                                                />
                                              ) : category === "net_cash" ? (
                                                <NumberFormat
                                                  thousandsGroupStyle="thousand"
                                                  value={value}
                                                  decimalSeparator="."
                                                  displayType="text"
                                                  type="text"
                                                  thousandSeparator={true}
                                                  allowNegative={true}
                                                  decimalScale={2}
                                                />
                                              ) : category === "price" ? (
                                                <NumberFormat
                                                  thousandsGroupStyle="thousand"
                                                  value={value}
                                                  decimalSeparator="."
                                                  displayType="text"
                                                  type="text"
                                                  thousandSeparator={true}
                                                  allowNegative={true}
                                                  decimalScale={7}
                                                />
                                              ) : category ===
                                                "is_structured" ? (
                                                obj[`${category}`] ? (
                                                  "true"
                                                ) : (
                                                  "false"
                                                )
                                              ) : (
                                                obj[`${category}`]
                                              )}
                                            </Typography>
                                          </StyledTableCell>
                                        ) : null;
                                      },
                                    )}
                                </StyledTableRow>
                              </>
                            );
                          })}
                        </>
                      );
                    })}
                  {tradesWithoutLinkArr !== undefined &&
                    tradesWithoutLinkArr !== null &&
                    tradesWithoutLinkArr.map((obj, idx) => {
                      return (
                        <>
                          <StyledTableRow
                            key={idx}
                            style={{
                              textAlign: "center",
                              margin: "0",
                              height: 30,
                              backgroundColor:
                                chosenTrades.filter(
                                  (t) =>
                                    Number(t.trade_id) === Number(obj.trade_id),
                                ).length > 0 && chosenTrades.length > 0
                                  ? "#2b2a2a"
                                  : "#171717",
                            }}
                            className={clsx({
                              newFiTrade: obj.highlight === true,
                              updateFiTrade: obj.highlightUpdate === true,
                            })}
                            onClick={() => handleChecked(obj.trade_id)}
                          >
                            <StyledTableCell
                              style={{
                                border: "none",
                                textAlign: "center",
                                position: "sticky",
                                width: "5%",
                                left: 0,
                                background: "inherit",
                              }}
                            >
                              <Checkbox
                                size="small"
                                checked={obj.checked}
                                // onClick={() => handleChecked(obj.trade_id)}
                                className={classes.checkbox}
                              />
                            </StyledTableCell>
                            {fiTradesArr !== undefined &&
                              fiTradesArr !== null &&
                              Object.keys(fiTradesArr[0]).map(
                                (category, idx) => {
                                  const value = obj[`${category}`];

                                  return category !== "trade_id" &&
                                    category !== "checked" &&
                                    category !== "uuid" &&
                                    category !== "deal_id" &&
                                    category !== "unresolvedClient" &&
                                    category !== "unresolvedTrader" &&
                                    category !== "bookedTo" &&
                                    category !== "client_id" &&
                                    category !== "pnlAtt" &&
                                    category !== "importRef" &&
                                    category !== "trader_rsp" ? (
                                    <StyledTableCell
                                      key={idx}
                                      style={{
                                        border: "none",
                                        width: "fit",
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: 500,
                                          color:
                                            obj.status === "ignore"
                                              ? "#5c5b5b"
                                              : obj[`${category}`] === "live" ||
                                                obj[`${category}`] === "sell"
                                              ? "#FF4E32"
                                              : obj[`${category}`] ===
                                                  "confirmed" ||
                                                obj[`${category}`] === "buy"
                                              ? "#22D865"
                                              : obj[`${category}`] === "draft"
                                              ? "#FFB300"
                                              : obj[`${category}`] ===
                                                "unresolved"
                                              ? "#FFB300"
                                              : obj.client === "VCON" &&
                                                category === "client"
                                              ? "#FFB300"
                                              : obj.trader === "VCON" &&
                                                category === "trader"
                                              ? "#FFB300"
                                              : null,
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {category === "trader" &&
                                        obj.trader === "VCON" ? (
                                          `!${obj.unresolvedTrader}`
                                        ) : category === "client" &&
                                          obj.client === "VCON" ? (
                                          `!${obj.unresolvedClient}`
                                        ) : category === "quantity" ? (
                                          <NumberFormat
                                            thousandsGroupStyle="thousand"
                                            value={value}
                                            decimalSeparator="."
                                            displayType="text"
                                            type="text"
                                            thousandSeparator={true}
                                            allowNegative={true}
                                            decimalScale={0}
                                          />
                                        ) : category === "accrued_interest" ? (
                                          <NumberFormat
                                            thousandsGroupStyle="thousand"
                                            value={value}
                                            decimalSeparator="."
                                            displayType="text"
                                            type="text"
                                            thousandSeparator={true}
                                            allowNegative={true}
                                            decimalScale={2}
                                          />
                                        ) : category === "net_cash" ? (
                                          <NumberFormat
                                            thousandsGroupStyle="thousand"
                                            value={value}
                                            decimalSeparator="."
                                            displayType="text"
                                            type="text"
                                            thousandSeparator={true}
                                            allowNegative={true}
                                            decimalScale={2}
                                          />
                                        ) : category === "price" ? (
                                          <NumberFormat
                                            thousandsGroupStyle="thousand"
                                            value={value}
                                            decimalSeparator="."
                                            displayType="text"
                                            type="text"
                                            thousandSeparator={true}
                                            allowNegative={true}
                                            decimalScale={7}
                                          />
                                        ) : category === "is_structured" ? (
                                          obj[`${category}`] ? (
                                            "true"
                                          ) : (
                                            "false"
                                          )
                                        ) : (
                                          obj[`${category}`]
                                        )}
                                      </Typography>
                                    </StyledTableCell>
                                  ) : null;
                                },
                              )}
                          </StyledTableRow>
                        </>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <TradeDialog
            open={openDialog}
            type={modalType}
            handleClose={handleDialogClose}
            isTradeDialogLoading={isTradeDialogLoading}
            setIsTradeDialogLoading={setIsTradeDialogLoading}
          />
          <SplitDialog
            open={openSplitDialog}
            type={"split"}
            handleClose={handleSplitDialogClose}
            isSplitDialogClientListLoading={isSplitDialogClientListLoading}
            setIsSplitDialogClientListLoading={
              setIsSplitDialogClientListLoading
            }
          />
          <BookDialog
            tradesToBook={tradesToBook}
            open={openBookDialog}
            type={"book"}
            handleClose={handleBookDialogClose}
          />
          <EmailModal
            open={openConfoDialog}
            type={confoModalType}
            handleClose={handleConfoDialogClose}
          />
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                // width: 400,
                bgcolor: "background.paper",
                boxShadow: 24,
                pt: 2,
                px: 4,
                pb: 3,
                width: "396px",
                height: "150px",
                /* UI Properties */
                background: "#2D2D2D 0% 0% no-repeat padding-box",
                boxShadow: "0px 3px 18px #00000040",
                borderRadius: "8px",
                opacity: "1",
                zoom: "110%",
              }}

              // sx={{ ...style, width: 400 }}
            >
              <Grid
                container
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12}>
                  <h2
                    id="parent-modal-title"
                    style={{ color: "red", textAlign: "center" }}
                  >
                    Invalid Action
                  </h2>
                </Grid>
                <Grid item xs={12}>
                  <p
                    id="parent-modal-description"
                    style={{ color: "white", textAlign: "center" }}
                  >
                    {modalMessage}
                  </p>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    color="error"
                    className={classes.invalidActionBtn}
                    onClick={handleCloseModal}
                  >
                    OK
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
          <PnlAttDialog
            open={openPnlDialog}
            type={"split"}
            handleClose={handlePnlAttModalClose}
            pnlDealId={pnlDealId}
          />
          <Popper
            open={isPnlAttOpen}
            anchorEl={anchorElPnlAtt}
            placement={"bottom-start"}
            transition

            // onMouseLeave={onMouseLeaveFutureTicker}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={100}>
                <Grid container item style={{ width: "100px" }}>
                  <Grid item className="x-icon">
                    <IconButton
                      style={{
                        backgroundColor: "#3A3C3F",
                      }}
                      onClick={() => {
                        setAnchorElPnlAtt(null);
                        handlePnlAttModalOpen();
                      }}
                    >
                      <EditIcon style={{ color: "#2692ff" }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Fade>
            )}
          </Popper>
        </>
      ) : (
        <CircularProgress />
      )}
    </Grid>
  );
}

export default FiBlotter;
