import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, DialogContent } from "@material-ui/core";
import {
  Dialog,
  Grid,
  Typography,
  IconButton,
  Divider,
  FormControl,
} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import { useStyles } from "../../../Styles/fiStyles";
import clsx from "clsx";
import SelectOption from "../../fi/Select/Select";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import * as fiActions from "../../../store/fi/fiSlice";
import NumberInput from "../../fi/numberInput/NumberInput";
import { v1 as uuidv1 } from "uuid";
import SplitCalculator from "./SplitValidator";
import tradeSplitter from "./TradeSplitter";
import * as actionSnackBar from "../../../store/snackbar/action";
import { END_POINT } from "../../../utils/index";

const SplitDialog = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const splitTradeData = useSelector((state) => state.fiSlice?.splitTradeForm);
  const splitArrValues = useSelector((state) => state.fiSlice?.splitArrValues);
  const amountLeftToSplit = useSelector(
    (state) => state.fiSlice?.amountLeftToSplit,
  );
  const selectedTradeToEdit = useSelector(
    (state) => state.fiSlice?.selectedTradeToEdit,
  );
  const fiTradesList = useSelector((state) => state.fiSlice?.fiTrades);
  const {
    open,
    type,
    handleClose,
    isSplitDialogClientListLoading,
    setIsSplitDialogClientListLoading,
  } = props;
  const [clientsListObj, setTClientsListObj] = useState({});
  const [splitOption, setSplitOption] = useState("notional");

  const splitNumArr = [];
  for (let index = 1; index <= splitTradeData.splitNumber; index++) {
    splitNumArr.push(`obj ${index}`);
  }
  SplitCalculator(open,splitOption, splitNumArr);

  const splitValues = [];
  for (let index = 2; index < 11; index++) {
    splitValues.push({ id: index, value: index });
  }

  const splitNumObj = {
    label: "Number of splits",
    values: splitValues,
    key: "splitNumber",
    type,
  };
  const splitOptionObj = {
    label: splitOption === "notional" ? "Notional" : "Allocation %",
    key: "splitOption",
  };

  const getClients = async () => {
    const token = sessionStorage.getItem("token");

    const clientsList = [];
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}` +
        END_POINT.FI_TRADES +
        "/get_clients",
      {
        headers: { Authorization: token },
      },
    );
    res.data.map((client) => {
      return clientsList.push({
        id: client.id,
        value: client.value,
      });
    });
    const obj = {
      label: "Client",
      values: clientsList,
      key: "clientSplitId",
    };
    setTClientsListObj(obj);
    setIsSplitDialogClientListLoading(false);
  };

  useEffect(() => {
    if (open) {
      getClients();
    }
  }, [open]);

  const handleSubmit = async () => {
    try {
      const fieldsValidatorArr = [];
      splitNumArr.map((obj, index) => {
        if (
          !splitArrValues ||
          !splitArrValues[index]?.clientSplitId ||
          splitArrValues[index]?.clientSplitId === "" ||
          !splitArrValues[index]?.splitOption ||
          splitArrValues[index]?.splitOption === ""
        ) {
          fieldsValidatorArr.push({
            status: false,
            message: `All the fields in row ${index + 1} are mandatory`,
          });
        }
      });
      if (amountLeftToSplit !== 0) {
        if (splitOptionObj.label === "Notional") {
          fieldsValidatorArr.push({
            status: false,
            message: `Allocations are not adding up to the total quantity ,remaining amount is: ${amountLeftToSplit}`,
          });
        } else if (splitOptionObj.label === "Allocation %") {
          fieldsValidatorArr.push({
            status: false,
            message: `Allocations are not adding up to 100% ,remaining percentage is: ${amountLeftToSplit}`,
          });
        }
      }
      if (fieldsValidatorArr.length !== 0) {
        dispatch(
          actionSnackBar.setSnackBar(
            "error",
            fieldsValidatorArr[0].message,
            3000,
          ),
        );
      } else if (fieldsValidatorArr.length === 0) {
        const obj = {
          splitNumArr,
          splitArrValues,
          selectedTradeToEdit,
          splitOption,
        };
        const tradesList = await tradeSplitter(obj);
        const token = sessionStorage.getItem("token");
        tradesList.map((trade) => {
          return axios.post(
            `${process.env.REACT_APP_BASE_URL}` +
              END_POINT.FI_TRADES +
              "/create_trade",
            trade,
            { headers: { Authorization: token } },
          );
        });
        const newTradesList = fiTradesList.filter(
          (trade) => trade.uuid !== selectedTradeToEdit.uuid,
        );
        dispatch(fiActions.setFiTrades(newTradesList));
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}` +
            END_POINT.FI_TRADES +
            "/update_trade_to_allocated",
          {
            uuid: `${selectedTradeToEdit.uuid}`,
          },
          { headers: { Authorization: token } },
        );
        const obj3 = {
          value: 2,
          key: "splitNumber",
        };
        const obj2 = {
          value: "notional",
          key: "splitOption",
        };
        dispatch(fiActions.setSplitTradeForm(obj3));
        dispatch(fiActions.setSplitTradeForm(obj2));
        dispatch(fiActions.resetSelectedTradeToEdit());
        dispatch(fiActions.resetSplitArrValues());
        dispatch(fiActions.resetChosenTrades());
        setSplitOption("notional");
        handleClose();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        const obj = {
          value: 2,
          key: "splitNumber",
        };
        const obj2 = {
          value: "notional",
          key: "splitOption",
        };
        setSplitOption("notional");
        dispatch(fiActions.setSplitTradeForm(obj));
        dispatch(fiActions.setSplitTradeForm(obj2));
        dispatch(fiActions.resetSplitArrValues());
        handleClose();
      }}
      classes={{ paper: classes.dialogPaper }}
      PaperProps={{
        style: {
          backgroundColor: "#2D2D2D",
        },
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: "30px 84px 32px 75px", position: "absolute" }}
      >
        <Grid item xs={11}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={6}>
              <Typography style={{ fontSize: "1.25rem", color: "#ffffff" }}>
                Split trade
              </Typography>
            </Grid>
            <Grid item xs={6} container justifyContent="flex-end">
              <IconButton
                size="small"
                onClick={() => {
                  const obj = {
                    value: 2,
                    key: "splitNumber",
                  };
                  const obj2 = {
                    value: "notional",
                    key: "splitOption",
                  };
                  dispatch(fiActions.setSplitTradeForm(obj));
                  dispatch(fiActions.setSplitTradeForm(obj2));
                  setSplitOption("notional");
                  dispatch(fiActions.resetSplitArrValues());
                  handleClose();
                }}
                style={{
                  backgroundColor: "#3a3c3f",
                  left: "110px",
                  top: "-20px",
                }}
              >
                <CloseIcon
                  style={{ color: "#bababa", fontSize: "1.3125rem" }}
                />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container xs={12} justifyContent="space-between">
          <Grid item xs={6}>
            <SelectOption values={splitNumObj} />
          </Grid>
          <Grid item xs={6}>
            <ButtonGroup
              className={classes.btnGroup}
              style={{ position: "absolute", top: "79px", height: "31px" }}
            >
              <Button
                className={clsx(classes.btnInside, {
                  [classes.chosenBtnInside]: splitOption === "percentage",
                })}
                onClick={() => {
                  setSplitOption("percentage");
                  const obj = {
                    value: "percentage",
                    key: "splitOption",
                  };

                  dispatch(fiActions.setSplitTradeForm(obj));
                }}
              >
                %
              </Button>
              <Button
                className={clsx(classes.btnInside, {
                  [classes.chosenBtnInside]: splitOption === "notional",
                })}
                onClick={() => {
                  setSplitOption("notional");
                  const obj = {
                    value: "notional",
                    key: "splitOption",
                  };

                  dispatch(fiActions.setSplitTradeForm(obj));
                }}
              >
                Notional
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
        <Grid item container>
          <Divider
            variant="middle"
            style={{
              backgroundColor: "#868DA2",
              width: "470px",
              position: "absolute",
              top: "150px",
              left: "55px",
            }}
          />
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          xs={12}
          style={{
            paddingTop: "0px",
            marginTop: "40px",
            maxHeight: "380px",
            overflow: "auto",
            // border: "1px solid red",
          }}
        >
          {!isSplitDialogClientListLoading &&
            splitNumArr.map((obj, index) => {
              const id = index;
              clientsListObj.id = id;
              splitOptionObj.id = id;
              const newClientObj = { ...clientsListObj, type };
              const newSplitOptionObj = { ...splitOptionObj };

              return (
                <Grid
                  key={id}
                  item={true}
                  container
                  xs={12}
                  style={{
                    marginTop: "40px",
                  }}
                >
                  <Grid item xs={6}>
                    <SelectOption values={newClientObj} key={id} />
                  </Grid>

                  <Grid item xs={6}>
                    <NumberInput
                      values={newSplitOptionObj}
                      key={id}
                      className={"numberSplit"}
                    />
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
      </Grid>
      <Grid
        item
        container
        justifyContent="space-between"
        xs={12}
        alignItems="flex-end"
        style={{
          // border: "1px solid red",
          position: "absolute",
          bottom: "50px",
          backgroundColor: "transparent",
          width: "470px",
          marginLeft: "70px",
        }}
      >
        <Grid item>
          <Button
            variant="contained"
            className={classes.btn}
            onClick={() => {
              const obj = {
                value: 2,
                key: "splitNumber",
              };
              const obj2 = {
                value: "notional",
                key: "splitOption",
              };
              dispatch(fiActions.setSplitTradeForm(obj));
              dispatch(fiActions.setSplitTradeForm(obj2));
              dispatch(fiActions.resetSplitArrValues());
              setSplitOption("notional");
              handleClose();
            }}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            className={classes.btn}
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default SplitDialog;
