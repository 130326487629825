import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Dialog,
  Divider,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { useStyles } from "../../../../Styles/fiStyles";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import FxEmailTabs from "./FxEmailTabs";
import FiEmailConfo from "../../../fi/EmailConfo/FiEmailConfo";

const returnClientForTabTitle = (client, trader, pm, clientInSplit, index) => {
  if (client?.includes("(MM)")) {
    if (
      trader === "" ||
      trader === "No PMs found" ||
      trader === "No Traders found"
    ) {
      
      return client;
    } else {
      return client?.replace("MM", trader);
    }
  } else if (clientInSplit.length > 0) {
    if (
      clientInSplit[index]?.postTradePmSplitId !== null ||
      clientInSplit[index]?.postTradePmSplitId !== ""
    ) {
      if (clientInSplit[index]?.postTradePmSplitId === "no options") {
        return `${client?.replace("no options", "")}`;
      } else if (clientInSplit[index]?.postTradePmSplitId === "No PMs found") {
        return `${client?.replace("No PMs found", "")}`;
      }
      if (clientInSplit[index]?.postTradePmSplitId === "No Traders Found") {
        return `${client?.replace("No Traders Found", "")}`;
      } else {
        let bracetIndex = client?.indexOf("(");
        let clientEdit = client?.slice(0, bracetIndex);
        return `${
          clientEdit + `(${clientInSplit[index]?.postTradePmSplitId})`
        }`;
      }
    } else {
      return client;
    }
  } else if (pm !== "" && pm !== null) {
    if (pm === "no options") {
      return `${client}`;
    }
    if (pm === "No PMs found") {
      return `${client}`;
    }
    if (pm === "No Traders Found") {
      return `${client}`;
    } else {
      return `${client + `(${pm})`}`;
    }
  } else {
    return client;
  }
};

function EmailModal(props) {
  const { open, type, handleClose, data } = props;
  const pm = useSelector((state) => state.rfqPostTradeSlice?.currentPb);
  const trader = useSelector((state) => state.rfqPostTradeSlice?.trader);
  const postTradeSplitArrValues = useSelector(
    (state) => state.rfqPostTradeSlice.postTradeSplitArrValues
  );
  let clients = useSelector((state) =>
    state.rfqPostTradeSlice.currentClient?.map((client, index) => {
      let obj = {
        name: returnClientForTabTitle(
          client,
          trader,
          pm,
          postTradeSplitArrValues,
          index
        ),
      };
      return obj;
    })
  );
  const classes = useStyles();
  const fiEmail = useSelector((state) => state.emailSlice?.fiEmail);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.emailDialogPaper }}
    >
      <Grid container justifyContent="center" style={{ paddingTop: 9 }}>
        <Grid item xs={11} style={{ paddingBottom: 10 }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography style={{ fontSize: "1.125rem", fontWeight: "540" }}>
                Confirmation
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                onClick={handleClose}
                size="small"
                style={{ backgroundColor: "#bababa" }}
              >
                <CloseIcon
                  style={{ fontSize: "1.3125rem", color: "#ffffff" }}
                />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        {type === "FX" ? (
          <FxEmailTabs title={type} clients={clients} tradeData={data} />
        ) : <FiEmailConfo/>}
      </Grid>
    </Dialog>
  );
}

export default EmailModal;
