import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as clientActions from "../../../store/fi/fiClientsSlice";
import * as rfqSlice from "../../../store/rfq/rfqSlice";

import {
  StyledTableCell,
  StyledTableRow,
  useStyles,
} from "../../../Styles/fiStyles";
import SearchIcon from "@material-ui/icons/Search";
import { DebounceInput } from "react-debounce-input";
import ClientsDialog from "../clientsDialog/ClientsDialog";
import { getElement } from "jspreadsheet-ce";

const ClientBlotter = () => {
  const dispatch = useDispatch();
  const { isNavigateToRfqBlotter } = useSelector((state) => state.rfqSlice);

  const customeView = useMediaQuery("(width:1280px)");
  const classes = useStyles();
  useEffect(() => {
    dispatch(clientActions.getClientsListAsync());

    return () => {
      dispatch(clientActions.setScrollPageParams(30));
      dispatch(clientActions.resetClientsList());
      if (isNavigateToRfqBlotter) {
        dispatch(rfqSlice.getRfq());
        dispatch(rfqSlice.setIsNavigateToRfqBlotter(false));
      }
    };
  }, []);

  const { clientsList, tradersList, selectedClient, searchValue } = useSelector(
    (state) => state.fiClientsSlice,
  );

  let categories = [];
  if (!clientsList.isLoading && clientsList?.items.length > 0) {
    categories = Object.keys(clientsList?.items[0]);
  } else {
    categories = [
      "id",
      "bbgMakorCode",
      "firmName",
      "subAccountsCode",
      "ssi",
      "account",
      "lei",
      "agentName",
      "agentBic",
      "localCode",
      "clearanceAc",
      "confirmationEmail1",
      "confirmationEmail2",
      "confirmationEmail3",
      "confirmationEmail4",
    ];
  }

  const [openClientDialog, setOpenClientDialog] = useState(false);
  const [modalType, setModalType] = useState("");

  const openClientDialogAction = (type) => {
    setModalType(type);
    setOpenClientDialog(!openClientDialog);
  };

  const handleClientDialogClose = () => {
    setModalType("");
    setOpenClientDialog(!openClientDialog);
  };

  const handleScroll = (e) => {
    const threshold = 10; // Adjust this value as needed
    const isNearBottom =
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <
      threshold;

    if (isNearBottom && searchValue === "") {
      dispatch(clientActions.getClientsListAsync());
    }
  };
  // const handleScroll = (e) => {
  //   console.log(
  //     searchValue === "" &&
  //       Math.floor(e.target.scrollHeight) - Math.floor(e.target.scrollTop) ===
  //         Math.floor(e.target.clientHeight),
  //   );
  //   if (
  //     searchValue === "" &&
  //     Math.floor(e.target.scrollHeight) - Math.floor(e.target.scrollTop) ===
  //       Math.floor(e.target.clientHeight)
  //   ) {
  //     dispatch(clientActions.getClientsListAsync());
  //   }
  // };
  const handleSearchByValue = (e) => {
    if (e === "") {
      dispatch(clientActions.setSearchValue(""));
      dispatch(clientActions.setScrollPageParams(30));
      dispatch(clientActions.getClientsListAsync());
    } else {
      dispatch(clientActions.searchClientAsync(e));
    }
  };
  const handleCheckboxChange = (e, id) => {
    dispatch(clientActions.handleCheckboxChange(e, id));
  };
  const handleRowSelect = (client) => {
    dispatch(clientActions.handleRowSelect(client));
  };

  return (
    <Grid container style={{ paddingTop: 32 }}>
      <Grid item lg={customeView ? 11 : 12} md={10} style={{ height: "0px" }}>
        <Typography style={{ color: "white", fontSize: 32 }}>
          Fixed Income Client Static Blotter
        </Typography>
      </Grid>
      <Grid item lg={12} md={10} style={{ paddingBlock: 70 }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ height: 0 }}
        >
          <Grid item lg={customeView ? 5 : 3} md={5}>
            {clientsList.items?.filter((client) => client.checked).length >
            0 ? (
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={2}>
                  <Button
                    onClick={() => openClientDialogAction("edit")}
                    className={clsx(classes.editBtn, {
                      [classes.disabledButton]: true,
                    })}
                  >
                    Edit
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    onClick={() => openClientDialogAction("copy")}
                    className={clsx(classes.copyBtn, {
                      [classes.disabledButton]: true,
                    })}
                  >
                    Copy
                  </Button>
                </Grid>

                <Grid item xs={2}>
                  <Button
                    onClick={() => dispatch(clientActions.deleteCLientThunk())}
                    className={clsx(classes.delinkBtn, {
                      [classes.disabledButton]: true,
                    })}
                  >
                    Delete
                  </Button>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
          <Grid item md={7} lg={customeView ? 7 : 4} style={{ height: 45 }}>
            <Grid
              spacing={1}
              justifyContent="flex-end"
              container
              alignItems="center"
            >
              <Grid item>
                <DebounceInput
                  id="searchBox"
                  style={{
                    color: "white",
                    border: "1px solid #686B76",
                    borderRadius: "4px",
                    height: "35px",
                    background: "none",
                  }}
                  minLength={2}
                  debounceTimeout={500}
                  className={classes.searchInput}
                  variant="outlined"
                  onChange={(e) =>
                    handleSearchByValue(e.target.value.toLowerCase())
                  }
                  placeholder="Search"
                  endAdornment={
                    <InputAdornment position="start">
                      <IconButton
                      // onClick={searchFi}
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item>
                <Button
                  onClick={() => openClientDialogAction("new")}
                  className={classes.btn}
                  // disabled={
                  //   !(
                  //     fiTradesArr.filter((t) => t.checked === true).length === 0
                  //   )
                  // }
                >
                  Create
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TableContainer
          // ref={tableRef}
          onScroll={handleScroll}
          style={{
            borderRadius: "8px 8px 8px 8px",
            border: "1px solid #686B76",
            height: "70vh",
            width: "100%",
          }}
        >
          {clientsList.isLoading ? null : (
            <Table size="medium" stickyHeader>
              <TableHead>
                <StyledTableRow style={{ margin: "0", width: "10%" }}>
                  <TableCell
                    style={{
                      position: "sticky",
                      left: 0,
                      background: "inherit",
                      width: "1%",
                      borderBottom: "1px solid #686B76",
                    }}
                  ></TableCell>
                  {categories.map((category, index) => {
                    return category !== "value" &&
                      category !== "id" &&
                      category !== "gppCode" &&
                      category !== "checked" ? (
                      <StyledTableCell
                        key={index}
                        style={{
                          fontSize: "12px",
                          color: "#686B76",
                          borderBottom: "1px solid #686B76",
                          textTransform: "capitalize",
                          backgroundColor: "inherit",
                        }}
                      >
                        {category === "bbgMakorCode"
                          ? "BBG/Makor Code"
                          : category === "agentBic"
                          ? "Agent BIC"
                          : category === "agentName"
                          ? "Agent Name"
                          : category === "localCode"
                          ? "Local Code"
                          : category === "brokerRef"
                          ? "Broker Ref"
                          : category === "firmName"
                          ? "Firm Name"
                          : category === "subAccountsCode"
                          ? "Sub Account/Code"
                          : category === "ssi"
                          ? "SSI"
                          : category === "clearanceAc"
                          ? "Clearance A/C"
                          : category.replaceAll("_", " ")}
                      </StyledTableCell>
                    ) : null;
                  })}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {clientsList.items.map((client, index) => {
                  return (
                    <>
                      <StyledTableRow
                        key={index}
                        style={{
                          textAlign: "center",
                          margin: "0",
                          height: 30,
                        }}
                        onClick={() => handleRowSelect(client)}
                      >
                        <StyledTableCell
                          style={{
                            border: "none",
                            textAlign: "center",
                            position: "sticky",
                            left: 0,
                            background: "inherit",
                          }}
                        >
                          <Checkbox
                            size="small"
                            checked={
                              client.checked !== undefined
                                ? client.checked
                                : false
                            }
                            // onClick={() => handleChecked(obj.trade_id)}
                            className={classes.checkbox}
                            onChange={(e) => handleCheckboxChange(e, client.id)}
                          />
                        </StyledTableCell>
                        {categories.map((category, index) => {
                          const value = client[`${category}`];
                          return category !== "value" &&
                            category !== "id" &&
                            category !== "gppCode" &&
                            category !== "checked" ? (
                            <StyledTableCell
                              key={index}
                              style={{
                                border: "none",
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: "12px",
                                  fontWeight: 500,
                                  textTransform: "capitalize",
                                }}
                              >
                                {value}
                              </Typography>
                            </StyledTableCell>
                          ) : null;
                        })}
                      </StyledTableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Grid>
      <ClientsDialog
        open={openClientDialog}
        type={modalType}
        handleClose={handleClientDialogClose}
      />
    </Grid>
  );
};

export default ClientBlotter;
