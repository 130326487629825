import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  participantsDialog: {
    backgroundColor: "#2D2D2D",
    minWidth: "1400px",
    height: "800px",
    borderRadius: "12px",
    overflowX: "hidden",
  },
  mainContainer: {
    height: "100%",
    width: "100%",
  },
  sectionContainer: {
    height: "48%",
    width: "70%",
  },
  chipsContainer: {
    height: "15%",
    overflow: "auto",
  },
  favListContainer: {
    height: "7%",
  },
  buttonsContainer: {
    height: "60px",
  },
  itemsListClients: {
    minWidth: "450px",
    minHeight: "250px",
    maxHeight: "250px",
    backgroundColor: "#2D2D2D",
    border: "1px solid rgba(104, 107, 118, 0.3)",
    borderRadius: "8px",
    overflow: "auto",
  },
  listItemSelected: {
    "&.MuiListItem-root:hover": {
      backgroundColor: "#34495c",
    },
    "&.MuiListItem-root.Mui-selected": {
      backgroundColor: "#2B3C4C", // The color you want for the selected item
      "&:hover": {
        backgroundColor: "#405970", // Optional: if you want to maintain the color on hover
      },
    },
  },
  listItemText: {
    color: "white",
    paddingTop: "10px",
    marginLeft: "25px",
    "& .MuiListItemText-primary": {
      font: "normal normal normal 16px/20px Inter",
    },
  },
  headerTypography: {
    color: "white",
    font: "normal normal normal 16px/20px Inter",
    marginTop: "25px",
    marginBottom: "50px",
  },
  toTypography: {
    color: "white",
    font: "normal normal normal 16px/20px Inter",
    marginLeft: "25px",
  },
  circularProgress: {
    "& .MuiCircularProgress-svg": {
      color: "white",
    },
  },
  btn: {
    marginTop: "10px",
    marginBottom: "10px",
    width: "100px",
    color: "white",
    backgroundColor: "#2692FF",
    borderRadius: "8px",
    font: "normal normal normal 16px/20px Inter",
    "&:hover": {
      backgroundColor: "#405970",
      color: "white",
    },
  },
  chip: {
    color: "white",
    borderColor: "white",
    borderWidth: 1,
    borderStyle: "solid",
    font: "normal normal normal 16px/20px Inter",
    marginRight: "5px",
    marginLeft: "5px",
    marginBottom: "5px",
  },
  deleteIcon: {
    color: "white",
  },
  closeIcon: {
    marginTop: "10px",
    marginRight: "15px",
    color: "white",
    backgroundColor: "#686B76",
    borderRadius: "14px",
    opacity: "0.5",
    "& .MuiSvgIcon-root": {
      fontSize: "1.6rem",
    },
  },

  formControl: {
    marginLeft: "25px",
    width: 400,
    "& .MuiOutlinedInput-root": {
      height: "35px", // Set the height of the Select component
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
    "& .MuiInputBase-input": {
      color: "white",
      height: "100%", // Ensure the input field takes the full height
      padding: "10px 14px", // Adjust padding to center the text vertically
      display: "flex",
      alignItems: "center",
    },
    "& .MuiInputLabel-outlined": {
      color: "white",
      transform: "translate(14px, 10px) scale(1)", // Adjust label position
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)", // Adjust label position when shrunk
    },
    "& .MuiSelect-icon": {
      color: "white",
      top: "calc(50% - 12px)", // Center the dropdown icon vertically
    },
  },
  select: {
    backgroundColor: "#2D2D2D",
    color: "white",
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  menu: {
    "& .MuiPaper-root": {
      backgroundColor: "#2D2D2D",
    },
    "& .MuiList-root": {
      backgroundColor: "#2D2D2D",
    },
    "& .MuiMenuItem-root": {
      color: "white",
      "&:hover": {
        backgroundColor: "#1F1F1F",
      },
    },
  },
  confoDialog: {
    backgroundColor: "#2D2D2D",
    minWidth: "1800px",
    height: "1100px",
    borderRadius: "12px",
    overflowX: "hidden",
  },
  btnMain: {
    width: "150px",
    borderRadius: 4,
    backgroundColor: "#2692FF",
    border: "1px solid #2692FF",
    textTransform: "none",
    color: "#ffffff",
    fontSize: 14,
    "&:hover": {
      backgroundColor: "#2692FF",
      border: "1px solid #ffffff",
    },
    "&:disabled": {
      backgroundColor: "rgba(92, 87, 90, 0.26)",
      border: "1px solid rgba(192, 179, 186, 0.26)",
      color: "#ffffff",
    },
  },
  tooltip: {
    fontSize: 16, // Adjust the font size as needed
    backgroundColor: "#f5f5f5", // Optional: Customize tooltip background color
    color: "#333", // Optional: Customize tooltip text color
  },
  arrow: {
    color: "#f5f5f5", // Optional: Customize arrow color
  },
}));
