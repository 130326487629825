import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  Box,
  CircularProgress,
  IconButton,
  Typography,
  Grid,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { StyledTableCell, useStyles } from "./style";
import { getTablePreview } from "./apiCalls";
import { transformString } from "./utils";
import RefreshIcon from "@material-ui/icons/Refresh";

const NearFarTable = ({
  rfqObject,
  setIsSubmitValid,
  legsStatus,
  tableData,
  setTableData,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { currentClient, currentMarketMaker, sd } = useSelector(
    (state) => state.rfqPostTradeSlice,
  );
  const { dealStatsData } = useSelector((state) => state.rfqSlice);

  const handleRefresh = async () => {
    getTablePreview(
      rfqObject,
      currentClient,
      currentMarketMaker,
      setTableData,
      dispatch,
      setIsSubmitValid,
      dealStatsData,
      sd,
    );
  };

  useEffect(() => {
    getTablePreview(
      rfqObject,
      currentClient,
      currentMarketMaker,
      setTableData,
      dispatch,
      setIsSubmitValid,
      dealStatsData,
      sd,
    );
  }, []);

  useEffect(() => {
    if (tableData !== null && legsStatus !== null) {
      setTableData((pre) => {
        const newData = [...pre]; // Create a shallow copy of the previous tableData array
        const index = newData.findIndex((row) => row.label === "reportStatus");

        if (index !== -1) {
          // Replace the item at the found index with a new object that merges legsStatus
          newData[index] = { ...newData[index], ...legsStatus };
        }

        return newData;
      });
    }
  }, [legsStatus]);
  return (
    <>
      {tableData === null ? (
        <Box className={classes.spinnerContainer}>
          <CircularProgress style={{ color: "#fff" }} />
        </Box>
      ) : (
        <TableContainer component={Paper} className={classes.container}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell colSpan={3}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h5" style={{ color: "#FFFFFF" }}>
                      E-Confo Table
                    </Typography>
                    <IconButton
                      onClick={handleRefresh}
                      className={classes.refreshButton}
                      aria-label="refresh"
                    >
                      <RefreshIcon fontSize="large" />
                      <Typography
                        variant="body1"
                        style={{ marginLeft: 8, color: "#FFFFFF" }}
                      >
                        Refresh
                      </Typography>
                    </IconButton>
                  </Grid>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell>Near Leg</StyledTableCell>
                <StyledTableCell>Far Leg</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.length > 0
                ? tableData.map((row, index) => (
                    <TableRow key={index}>
                      <StyledTableCell>
                        {transformString(row.label)}
                      </StyledTableCell>
                      {row.label === "reportStatus" ? (
                        <>
                          <TableCell
                            className={classes.transparentCell}
                            style={{
                              color: row.nearLeg?.includes("success")
                                ? "#2ECC71"
                                : row.nearLeg?.includes("failure")
                                ? "#ff6347"
                                : row.nearLeg?.includes("pending")
                                ? "#FFA500"
                                : "#dadfe1",
                            }}
                          >
                            {row.nearLeg === null
                              ? "Not Submitted"
                              : row.nearLeg}
                          </TableCell>

                          <TableCell
                            className={classes.transparentCell}
                            style={{
                              color: row.farLeg?.includes("success")
                                ? "#2ECC71"
                                : row.farLeg?.includes("failure")
                                ? "#ff6347"
                                : row.farLeg?.includes("pending")
                                ? "#FFA500"
                                : "#dadfe1",
                            }}
                          >
                            {row.farLeg === null ? "Not Submitted" : row.farLeg}
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell className={classes.transparentCell}>
                            {row.nearLeg}
                          </TableCell>
                          <TableCell className={classes.transparentCell}>
                            {row.farLeg}
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default NearFarTable;
