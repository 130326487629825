export const update_ccy_pair = async (
  col,
  keyVal_input_col,
  checkCurrencyPair,
  set_cell_value,
  clear_existing_grid,
  set_keyval_for_all_columns,
  jRef,
  setSnackBar,
  dispatch,
) => {
  let userInput = keyVal_input_col("CCY_PAIR", col);
  if (userInput === "") {
    return;
  }
  if (userInput === undefined) {
    return;
  }
  userInput = userInput.toUpperCase();

  if (!checkCurrencyPair(userInput)) {
    dispatch(
      setSnackBar("error", `${userInput} is not a valid Ccy Pair`, 3000),
    );
    set_cell_value("", "CCY_PAIR", col);
    return;
  }

  clear_existing_grid(["REQUEST_FOR", "CCY_PAIR"]);
  set_cell_value(userInput, "CCY_PAIR", col);
  set_keyval_for_all_columns("CCY_PAIR", col);
  set_cell_value("VANILLA", "DEAL_TYPE", col);

  jRef.current.jspreadsheet.updateSelectionFromCoords(col, 2, col, 2);
};
export const update_deal_type = (
  col,
  keyVal_input_col,
  clear_existing_grid,
  initialize_deal_type,
  handle_visible_rows,
  set_is_custom_deal_bool,
  set_cell_value,
) => {
  if (keyVal_input_col("DEAL_TYPE", col) === "") {
    return;
  }
  if (keyVal_input_col("DEAL_TYPE", col) === undefined) {
    return;
  }
  if (
    keyVal_input_col("DEAL_TYPE", col) === "CUSTOM" ||
    keyVal_input_col("DEAL_TYPE", col) === "custom"
  ) {
    set_cell_value("CUSTOM", "DEAL_TYPE", col);
    return;
  }

  clear_existing_grid(["REQUEST_FOR", "CCY_PAIR", "DEAL_TYPE"]);
  initialize_deal_type(col);
  handle_visible_rows();
  set_is_custom_deal_bool();
};
export const update_leg_type = (
  col,
  keyVal_input_col,
  get_deal_specs_by_leg_type,
  set_cell_value,
  jRef,
  set_custom_deal_type,
  is_vanilla_only,
  set_keyval_for_all_columns,
  handle_visible_rows,
  setSnackBar,
  dispatch,
  set_swap_leg,
  format_table,
) => {
  if (keyVal_input_col("LEG_TYPE", col) === "") {
    return;
  }
  if (keyVal_input_col("LEG_TYPE", col) === undefined) {
    return;
  }

  set_cell_value(
    keyVal_input_col("LEG_TYPE", col).toUpperCase(),
    "LEG_TYPE",
    col,
  );
  let specs = get_deal_specs_by_leg_type(col);
  set_cell_value(specs.option_type[0], "OPTION_TYPE", col);
  set_cell_value("", "BARRIER", col);
  set_cell_value("", "BARRIER2", col);
  if (jRef.current.jspreadsheet.headers.length === 2) {
    if (specs.option_type[0] === "VANILLA") {
      set_cell_value("SINGLE_VANILLA", "DEAL_TYPE", col);
      sessionStorage.setItem("rfqDealType", "VANILLA");
    } else if (specs.option_type[0] === "SWAP") {
      sessionStorage.setItem("rfqDealType", "SWAP");
      set_cell_value("SWAP", "DEAL_TYPE", col);
    } else {
      sessionStorage.setItem("rfqDealType", "EXOTIC");
      set_cell_value("SINGLE_EXOTIC", "DEAL_TYPE", col);
    }
  } else {
    set_custom_deal_type();
  }

  if (
    (specs.option_type[0] === "VANILLA" &&
      jRef.current.jspreadsheet.headers.length > 3) ||
    !is_vanilla_only()
  ) {
    let ccypair_group = keyVal_input_col("CCYPAIR_GROUP", col);
    let option_type = keyVal_input_col("OPTION_TYPE", col);
    if (ccypair_group === "NDF") {
      set_cell_value("FWD", "REF_TYPE", col);
    } else {
      set_cell_value("SPOT", "REF_TYPE", col);
    }
    set_cell_value("PCT_NOTIONAL", "RFQ_FORMAT", col);
    set_cell_value("YES", "INCLUDE_REF", col);
  } else {
    set_cell_value(specs.rfq_format, "RFQ_FORMAT", col);
    set_cell_value(specs.ref_type, "REF_TYPE", col);
    set_cell_value(specs.include_ref, "INCLUDE_REF", col);
  }

  set_keyval_for_all_columns("RFQ_FORMAT", col);
  set_keyval_for_all_columns("REF_TYPE", col);
  set_keyval_for_all_columns("INCLUDE_REF", col);
  set_swap_leg(specs.leg_type[0]);
  handle_visible_rows();
  format_table();
};
export const update_strike_price = async (
  col,
  keyVal_input_col,
  set_cell_value,
  assign_jexcel_data,
  value,
) => {
  if (keyVal_input_col("CCY_PAIR", col) === "") {
    set_cell_value("", "STRIKE", col);
    return;
  }
  if (keyVal_input_col("CCY_PAIR", col) === undefined) {
    set_cell_value("", "STRIKE", col);
    return;
  }
  // if (keyVal_input_col("EXPIRY", col) === "") {
  //   set_cell_value("", "STRIKE", col);
  //   return;
  // }
  // if (keyVal_input_col("EXPIRY", col) === undefined) {
  //   set_cell_value("", "STRIKE", col);
  //   return;
  // }
  if (keyVal_input_col("STRIKE", col) === "") {
    set_cell_value("", "STRIKE", col);
    return;
  }
  if (keyVal_input_col("STRIKE", col) === undefined) {
    set_cell_value("", "STRIKE", col);
    return;
  }

  if (value[0] === ".") {
    set_cell_value(`${0}${value}`, "STRIKE", col);
  }
  // assign_jexcel_data()
};
export const set_direction = (col, keyVal_input_col, jRef, table_keys) => {
  if (keyVal_input_col("DEAL_TYPE", col) === "CUSTOM") {
    return;
  }
  try {
    jRef.current.jspreadsheet.ignoreEvents = true;
    for (let c = 1; c < jRef.current.jspreadsheet.headers.length; c++) {
      let direction =
        keyVal_input_col("BUY_SELL", c) === "BUY" ? "SELL" : "BUY";
      if (c !== col) {
        jRef.current.jspreadsheet.setValueFromCoords(
          c,
          table_keys().indexOf("BUY_SELL"),
          direction,
          true,
        );
      }
    }
  } catch (err) {
    console.log(err);
  } finally {
    jRef.current.jspreadsheet.ignoreEvents = false;
  }
};
export const calc_net_price = (
  is_client_premium,
  keyVal_input_col,
  jRef,
  set_cell_value,
  setNetClientPrice,
  setNetMarketPrice,
  assign_jexcel_data,
) => {
  let notional = keyVal_input_col("NOTIONAL", 1);
  let strike = keyVal_input_col("STRIKE", 1);
  let premium_key = is_client_premium ? "CLIENT_PREMIUM" : "MARKET_PREMIUM";
  let net_premium = 0;

  for (
    let index = 1;
    index < jRef.current.jspreadsheet.headers.length;
    index++
  ) {
    let opt_premium = keyVal_input_col(premium_key, index);
    let set_direction = keyVal_input_col("BUY_SELL", index) === "BUY" ? 1 : -1;
    net_premium += parseFloat(opt_premium.replace(/,/g, "")) * set_direction;
  }

  let premium_type = keyVal_input_col("PREMIUM_TYPE", 1);

  let price = 0;

  let premium = net_premium;

  // if (premium_type === "BASE_PCT") {
  //   price = premium / (notional * 10000);
  // }
  // if (premium_type === "TERMS_PIPS") {
  //   price = premium / (notional * 1000000);
  // }
  // if (premium_type === "TERMS_PCT") {
  //   price = premium / (notional * strike * 10000);
  // }
  // if (premium_type === "BASE_PIPS") {
  //   price = premium / (notional * strike * 1000000);
  // }
  let base_ccy = keyVal_input_col("CCY_PAIR", 1).substring(0, 3);
  let notional_ccy = keyVal_input_col("NOTIONAL_CCY", 1);
  if (premium_type === "BASE_PCT") {
    if (base_ccy === notional_ccy) {
      premium = price * notional * 10000;
    } else {
      premium = price * notional * strike * 10000;
    }
  }

  if (premium_type === "TERMS_PIPS") {
    if (base_ccy === notional_ccy) {
      premium = price * notional * 1000000;
    } else {
      premium = price * (notional * strike * 1000000);
    }
  }

  if (premium_type === "TERMS_PCT") {
    if (base_ccy === notional_ccy) {
      premium = price * notional * strike * 10000;
    } else {
      premium = price * notional * 10000;
    }
  }

  if (premium_type === "BASE_PIPS") {
    if (base_ccy === notional_ccy) {
      premium = price * (notional * strike * 1000000);
    } else {
      premium = price * (notional * 1000000);
    }
  }

  let price_key = is_client_premium ? "NET_CLIENT_PRICE" : "NET_MARKET_PRICE";
  if (price_key === "NET_CLIENT_PRICE") {
    setNetClientPrice(price);
    set_cell_value(price, "NET_CLIENT_PRICE", 1);
  } else if (price_key === "NET_MARKET_PRICE") {
    setNetMarketPrice(price);
    set_cell_value(price, "NET_MARKET_PRICE", 1);
  }
  assign_jexcel_data();
};
export const update_call_put = (
  col,
  keyVal_input_col,
  are_option_types_the_same,
  set_cell_value,
  get_deal_specs,
  set_custom_deal_type,
) => {
  if (keyVal_input_col("DEAL_TYPE", col).includes("SINGLE")) {
    return;
  }
  if (!are_option_types_the_same()) {
    return;
  }
  if (keyVal_input_col("DEAL_TYPE", 1) === "RR") {
    if (keyVal_input_col("CALL_PUT", 1) === "CALL") {
      set_cell_value("PUT", "CALL_PUT", 2);
    } else {
      set_cell_value("CALL", "CALL_PUT", 2);
    }
    return;
  }
  console.log(keyVal_input_col("DEAL_TYPE", col) === "SPREAD");
  if (
    keyVal_input_col("DEAL_TYPE", col) === "SPREAD" ||
    keyVal_input_col("DEAL_TYPE", col) === "DIGITAL_SPREAD"
  ) {
    return;
  }
  let deal_specs = get_deal_specs(col);
  if (deal_specs.legs > 1) {
    if (keyVal_input_col("CALL_PUT", col) === "CALL") {
      set_cell_value("PUT", "CALL_PUT", col);
    } else {
      set_cell_value("CALL", "CALL_PUT", col);
    }
    return;
  }

  set_custom_deal_type();
};
export const set_fwd_ref_same_expiry = (
  col,
  jRef,
  keyVal_input_col,
  table_keys,
) => {
  try {
    jRef.current.jspreadsheet.ignoreEvents = true;
    let fwd = keyVal_input_col("FWD_REF", col);
    let expiry = keyVal_input_col("EXPIRY", col);

    for (let c = 1; c < jRef.current.jspreadsheet.headers; c++) {
      if (keyVal_input_col("EXPIRY", c) === expiry) {
        jRef.current.jspreadsheet.setValueFromCoords(
          c,
          table_keys().indexOf("FWD_REF"),
          fwd,
          true,
        );
      }
    }
  } catch (err) {
    console.log(err);
  } finally {
    jRef.current.jspreadsheet.ignoreEvents = false;
  }
};
export const update_premium_type = (
  col,
  keyVal_input_col,
  set_cell_value,
  set_keyval_for_all_columns,
  assign_jexcel_data,
) => {
  let premium_type = keyVal_input_col("PREMIUM_TYPE", col);
  let premium_ccy = "";
  if (premium_type === "BASE_PCT" || premium_type === "BASE_PIPS") {
    premium_ccy = keyVal_input_col("CCY_PAIR", col).substring(0, 3);
  }
  if (premium_type === "TERMS_PCT" || premium_type === "TERMS_PIPS") {
    premium_ccy = keyVal_input_col("CCY_PAIR", col).substring(3, 6);
  }
  set_cell_value(premium_ccy, "PREMIUM_CCY", col);
  set_keyval_for_all_columns("PREMIUM_CCY", col);
  set_keyval_for_all_columns("PREMIUM_TYPE", col);
  // assign_jexcel_data();
};
export const update_price = (
  col,
  is_client_price,
  keyVal_input_col,
  setClientPrice,
  setClientPremium,
  setMarketPrice,
  setMarketPremium,
  set_cell_value,
  assign_jexcel_data,
) => {
  let premium_type = keyVal_input_col("PREMIUM_TYPE", col);
  let price_key = is_client_price ? "CLIENT_PRICE" : "MARKET_PRICE";
  let premium_key = is_client_price ? "CLIENT_PREMIUM" : "MARKET_PREMIUM";
  let premium = 0;
  let notional = keyVal_input_col("NOTIONAL", col);
  let price = keyVal_input_col(price_key, col);
  let strike = keyVal_input_col("STRIKE", col);

  // if (premium_type === "BASE_PCT") {
  //   premium = price * notional * 10000;
  // }
  // if (premium_type === "TERMS_PIPS") {
  //   premium = price * notional * 1000000;
  // }
  // if (premium_type === "TERMS_PCT") {
  //   premium = price * notional * strike * 10000;
  // }
  // if (premium_type === "BASE_PIPS") {
  //   premium = price * notional * strike * 1000000;
  // }
  let base_ccy = keyVal_input_col("CCY_PAIR", 1).substring(0, 3);
  let notional_ccy = keyVal_input_col("NOTIONAL_CCY", 1);
  if (premium_type === "BASE_PCT") {
    if (base_ccy === notional_ccy) {
      premium = price * notional * 10000;
    } else {
      premium = price * notional * strike * 10000;
    }
  }

  if (premium_type === "TERMS_PIPS") {
    if (base_ccy === notional_ccy) {
      premium = price * notional * 1000000;
    } else {
      premium = price * (notional * strike * 1000000);
    }
  }

  if (premium_type === "TERMS_PCT") {
    if (base_ccy === notional_ccy) {
      premium = price * notional * strike * 10000;
    } else {
      premium = price * notional * 10000;
    }
  }

  if (premium_type === "BASE_PIPS") {
    if (base_ccy === notional_ccy) {
      premium = price * (notional * strike * 1000000);
    } else {
      premium = price * (notional * 1000000);
    }
  }

  if (is_client_price) {
    setClientPrice(price);
    setClientPremium(premium.toLocaleString());
  } else if (is_client_price === false) {
    setMarketPrice(price);
    setMarketPremium(premium.toLocaleString());
  }
  set_cell_value(premium.toLocaleString(), premium_key, col);
  // assign_jexcel_data();

  // rfq_object.rfq_table_data = jRef.current.jspreadsheet.getJson()
  // rfq_object = {...rfq_object,rfq_table_data: jRef.current.jspreadsheet.getJson()};
};
export const update_premium = (
  col,
  is_client_premium,
  keyVal_input_col,
  set_cell_value,
  rfq_object,
  jRef,
) => {
  let premium_type = keyVal_input_col("PREMIUM_TYPE", col);
  let price_key = is_client_premium ? "CLIENT_PRICE" : "MARKET_PRICE";
  let premium_key = is_client_premium ? "CLIENT_PREMIUM" : "MARKET_PREMIUM";
  let price = 0;
  let notional = keyVal_input_col("NOTIONAL", col);
  let premium = keyVal_input_col(premium_key, col);
  let strike = keyVal_input_col("STRIKE", col);

  // if (premium_type === "BASE_PCT") {
  //   price = premium / (notional * 10000);
  // }
  // if (premium_type === "TERMS_PIPS") {
  //   price = premium / (notional * 1000000);
  // }
  // if (premium_type === "TERMS_PCT") {
  //   price = premium / (notional * strike * 10000);
  // }
  // if (premium_type === "BASE_PIPS") {
  //   price = premium / (notional * strike * 1000000);
  // }
  let base_ccy = keyVal_input_col("CCY_PAIR", 1).substring(0, 3);
  let notional_ccy = keyVal_input_col("NOTIONAL_CCY", 1);
  if (premium_type === "BASE_PCT") {
    if (base_ccy === notional_ccy) {
      premium = price * notional * 10000;
    } else {
      premium = price * notional * strike * 10000;
    }
  }

  if (premium_type === "TERMS_PIPS") {
    if (base_ccy === notional_ccy) {
      premium = price * notional * 1000000;
    } else {
      premium = price * (notional * strike * 1000000);
    }
  }

  if (premium_type === "TERMS_PCT") {
    if (base_ccy === notional_ccy) {
      premium = price * notional * strike * 10000;
    } else {
      premium = price * notional * 10000;
    }
  }

  if (premium_type === "BASE_PIPS") {
    if (base_ccy === notional_ccy) {
      premium = price * (notional * strike * 1000000);
    } else {
      premium = price * (notional * 1000000);
    }
  }

  price = price.toFixed(6);
  set_cell_value(price, price_key, col);
  rfq_object = {
    ...rfq_object,
    rfq_table_data: jRef.current.jspreadsheet.getJson(),
  };
};
