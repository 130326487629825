import { ToggleRenderer } from "./ToggleRenderer";

export const cellRendererSelector = (params) => {
  if (params.data.columnHeader === "Side") {
    return { component: ToggleRenderer };
  } else if (params.data.columnHeader === "Send Conformation") {
    return { component: ToggleRenderer };
  } else if (params.data.columnHeader === "Initiator") {
    return { component: ToggleRenderer };
  } else if (params.data.columnHeader === "Is Market Maker") {
    return { component: ToggleRenderer };
  }

  return undefined;
};
