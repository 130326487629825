const getValueOrDefault = (value, defaultValue = 0) => value || defaultValue;

export const isDataValid = (data) => {
  return (
    data !== undefined &&
    data.DELTA_MID_RT !== undefined &&
    data.DELTA_MID_RT !== "" &&
    ((data.BID !== undefined && data.BID !== "") ||
      (data.ASK !== undefined && data.ASK !== ""))
  );
};

export const getAdjustedPrice = (data, isFuture) => {
  return getValueOrDefault(isFuture ? data.legLastPrice : data.lastPrice);
};

export const getAdjustedTie = (data, isFuture) => {
  return getValueOrDefault(
    isFuture ? data.tieByLeg : data.tie,
    getAdjustedPrice(data, isFuture),
  );
};

export const calculateRef = (tie, lastPrice, deltaMidRt, price, quantity) => {
  const priceDifference = Number(tie) - Number(lastPrice);
  const deltaAdjustment = priceDifference * (Number(deltaMidRt) / 100);
  const refValue = deltaAdjustment + Number(price);
  return refValue * quantity;
};
