import "../tables/style.css";

export const cellStyleSelector = (params) => {
  // style per cell
  if (params.colDef.field === "columnHeader") {
    return {
      color: "#f5f5f5a3",
      fontWeight: "600",
    };
  } else return undefined;
};

export const getRowClass = (params) => {
  // style per row
  if (
    params.data.columnHeader === "Executed At" ||
    params.data.columnHeader === "Market Maker Fee" ||
    params.data.columnHeader === "Price"
  ) {
    return "red-border";
  }
};
