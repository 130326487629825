import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import * as rfqAggregatorSlice from "../../../../../store/rfq/rfqAggregatorSlice";
import * as rfqSlice from "../../../../../store/rfq/rfqSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  apiGetDealStats,
  apiRefreshQuoteBook,
  buildOrderBook,
} from "../../../../../services/apiCalls/quotsBookApi";
import axios from "axios";
import { END_POINT } from "../../../../../utils";
import { createQuoteBookDict } from "../../../../../services/rfqWorkspace/createQuoteBookDict";

const QuoteBookParamsDoubleVol = ({
  rfqParamaters,
  rfq_object,
  create_quote_book_dict,
  set_jtable,
}) => {
//ADD_QUOTE_PARAMETERS_BY_USER
const token = sessionStorage.getItem("token");
const rfqId = sessionStorage.getItem("rfqId");
  let rfqParamatersCopy = { ...rfqParamaters };
  const [choiceLeg1Validation, setChoiceLeg1Validation] = useState("")
  const [choiceLeg2Validation, setChoiceLeg2Validation] = useState("")
  const userSpread = useSelector((state) => state.rfqAggregatorSlice.userSpread)
  
  const classes = useStyles();
  const dispatch = useDispatch();
  const max1280 = useMediaQuery("(max-width:1280px)");

  const [volLeg1, setVolLeg1] = useState(
    Number(rfqParamaters.choice_leg) === "1"
      ? rfqParamaters.choice_vol
      : rfqParamaters.bbg_vol1,
  );

  const [volLeg2, setVolLeg2] = useState(
    Number(rfqParamaters.choice_leg) === "2"
      ? rfqParamaters.choice_vol
      : rfqParamaters.bbg_vol2,
  );

  let dealStatsData = useSelector((state) => state.rfqSlice.dealStatsData);
  let { volLegParams, rfqDefaultQuoteParams } = useSelector(
    (state) => state.rfqAggregatorSlice,
  );
  let filterVolLegParams = volLegParams.filter(
    (params) => Number(params.rfq_id) === Number(rfqId),
  );
  let copyDealStatsData =
    filterVolLegParams.length > 0
      ? { ...filterVolLegParams[0].dealStatsData }
      : JSON.parse(JSON.stringify(dealStatsData));

  const choiceLegString = () => {
    return Number(rfqParamaters.choice_leg) === 1 ? true : false;
  };
  const choiceSelection = () => {
    return choiceLegString() ? "LEG 1 IS CHOICE" : "LEG 2 IS CHOICE";
  };
  const updateQuoteParameterVolsOfChoiceLeg2 = async (e, val, leg) => {
    if (e.key === "Enter") {
      setChoiceLeg2Validation("")
      if (Number(rfqParamatersCopy.choice_leg) !== Number(leg)) return;
      // e.target.value = Number.parseFloat(e.target.value).toFixed(3);
      setVolLeg2(Number.parseFloat(e.target.value));
      rfqParamatersCopy.choice_leg = leg;
      rfqParamatersCopy.choice_vol = Number.parseFloat(e.target.value)
      
      rfqParamatersCopy.bbg_vol2 = Number.parseFloat(e.target.value);
      dispatch(rfqAggregatorSlice.updateQuoteParameterts(rfqParamatersCopy));
      updateChiceLeg(rfqParamatersCopy);
    }
  };
  const updateQuoteParameterVolsOfChoiceLeg1 = async (e, val, leg) => {
    if (e.key === "Enter") {
      setChoiceLeg1Validation("")

      if (Number(rfqParamatersCopy.choice_leg) !== Number(leg)) return;
      // e.target.value = Number.parseFloat(e.target.value).toFixed(3);
     
      setVolLeg1(Number.parseFloat(e.target.value));
      rfqParamatersCopy.choice_leg = leg;
      // rfqParamatersCopy.choice_vol = Number.parseFloat(e.target.value).toFixed(
      //   2,
      // );
      // rfqParamatersCopy.bbg_vol1 = Number.parseFloat(e.target.value).toFixed(3);
      rfqParamatersCopy.choice_vol = Number.parseFloat(e.target.value)
      rfqParamatersCopy.bbg_vol1 = Number.parseFloat(e.target.value);
      dispatch(rfqAggregatorSlice.updateQuoteParameterts(rfqParamatersCopy));

      updateChiceLeg(rfqParamatersCopy);
    }
  };
  const updateChiceLegByRfqQuoteParameters = () => {
    setChoiceLeg1Validation("")
    setChoiceLeg2Validation("")

    if (Number(rfqParamatersCopy.choice_leg) === 1) {
      rfqParamatersCopy.choice_leg = 2;
      rfqParamatersCopy.choice_vol =
        volLeg2 === undefined ? rfqParamaters.bbg_vol2 : volLeg2;

      dispatch(rfqAggregatorSlice.updateQuoteParameterts(rfqParamatersCopy));
    } else if (Number(rfqParamatersCopy.choice_leg) === 2) {
      rfqParamatersCopy.choice_leg = 1;
      rfqParamatersCopy.choice_vol =
        volLeg1 === undefined ? rfqParamaters.bbg_vol1 : volLeg1;
      dispatch(rfqAggregatorSlice.updateQuoteParameterts(rfqParamatersCopy));
    }
    copyDealStatsData.is_users_price = false;
    addQuoteParametersByUser(rfqParamatersCopy)
    delete copyDealStatsData.bps_value;
    updateChiceLeg(rfqParamatersCopy);
  };
  const addQuoteParametersByUser = async (quoteParameters) => {
    quoteParameters={ ...quoteParameters, rfq_id:sessionStorage.getItem("rfqId")}
  try {
    let response = axios.post( `${process.env.REACT_APP_BASE_URL}` + END_POINT.QUOTE_PARAMETERS + "/" +
    END_POINT.ADD_QUOTE_PARAMETERS_BY_USER +
    `/${rfqId}`,
    { quoteParameters },
  { headers: { Authorization: token } },)
  } catch (error) {
    console.log(error)
  }
  }
  const updateChiceLeg = async (rfqParamatersCopy) => {
    addQuoteParametersByUser(rfqParamatersCopy)
   
    dispatch(rfqAggregatorSlice.setIsQuoteParamsChanged(true));

    let tradersList = create_quote_book_dict().map((key) => {
      return key.trader;
    });

    try {
      let jtableDict = await createQuoteBookDict();
      let quoteBook = await apiRefreshQuoteBook(
        rfqParamaters.quote_type,
        create_quote_book_dict(),
        jtableDict,
        rfqParamatersCopy,
      );
      let orderBook = await buildOrderBook(quoteBook);
      let defaultDealStats = await apiGetDealStats(
        rfqParamaters.quote_type,
        jtableDict,
        rfqParamatersCopy,
        copyDealStatsData,
        orderBook,
        false
      );
      if(userSpread.length > 0){
        let dealStatsData = {...defaultDealStats}
        dealStatsData.makor_bid =
        (Number(dealStatsData.market_bid) - Number(userSpread[0].userSpreadBid).toFixed(3))
        dealStatsData.makor_offer = 
        (Number(dealStatsData.market_offer) + Number(userSpread[0].userSpreadOffer)).toFixed(3);
       
        let dealStats = await apiGetDealStats(
          rfqParamaters.quote_type,
          jtableDict,
          rfqParamatersCopy,
          dealStatsData,
          orderBook,
          true
        )
        dispatch(rfqSlice.updateDealStatsData(dealStats));
        let buildQuoteBookData = quoteBook.map((x, i) => {
          let arr = Object.values(x);
          arr.push(tradersList[i]);
          return arr;
        });
        set_jtable(buildQuoteBookData);

        dispatch(rfqSlice.updateTableData(orderBook));
        dispatch(rfqAggregatorSlice.updateQuoteParameterts(rfqParamatersCopy));
      }else {
      let dealStats = await apiGetDealStats(
        rfqParamaters.quote_type,
        jtableDict,
        rfqParamatersCopy,
        copyDealStatsData,
        orderBook,
          false
      );
       dispatch(rfqSlice.updateDealStatsData(dealStats));
      let buildQuoteBookData = quoteBook.map((x, i) => {
        let arr = Object.values(x);
        arr.push(tradersList[i]);
        return arr;
      });
      set_jtable(buildQuoteBookData);

      dispatch(rfqSlice.updateTableData(orderBook));
      dispatch(rfqAggregatorSlice.updateQuoteParameterts(rfqParamatersCopy));
      }
      
      let buildQuoteBookData = quoteBook.map((x, i) => {
        let arr = Object.values(x);
        arr.push(tradersList[i]);
        return arr;
      });
      set_jtable(buildQuoteBookData);

      dispatch(rfqSlice.updateTableData(orderBook));
      dispatch(rfqAggregatorSlice.updateQuoteParameterts(rfqParamatersCopy));
    
    } catch (error) {
      console.log(error);
    }
  };
  const validateLeg1ChoiceLegMatch = (e,leg) => {
   if(e.target.value !== ""){
    if (Number(rfqParamatersCopy.choice_leg) !== Number(leg)){
      setChoiceLeg1Validation("You are not in choice leg")
    }else if(Number(rfqParamatersCopy.choice_leg) === Number(leg)){
      setChoiceLeg1Validation("Press Enter for the changes to take effect")
    }
  }
  }
  const validateLeg2ChoiceLegMatch = (e,leg) => {
    if(e.target.value !== ""){
      if (Number(rfqParamatersCopy.choice_leg) !== Number(leg)){
        setChoiceLeg2Validation("You are not in choice leg")
      }else if(Number(rfqParamatersCopy.choice_leg) === Number(leg)){
        setChoiceLeg2Validation("Press Enter for the changes to take effect")
      }
    }
  }
  return (
    <Grid
      item
      container
      style={{
        border: "1px solid #686B76",
        borderRadius: "8px",
        padding: "20px 0px 20px 15px",
        marginTop: "10px",
      }}
    >
      <Grid item container xs={4}>
        <Grid item xs={12}>
          <Typography className={classes.quoteParamatersText}>
            {rfqDefaultQuoteParams?.quote_type_str}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            className={classes.quoteParamatersText}
            style={{ font: "normal normal normal 11px/20px monospace" }}
          >
            MID PREMIUM:{rfqDefaultQuoteParams?.bbg_mid_premium}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "15px" }}>
          <Button
            onClick={updateChiceLegByRfqQuoteParameters}
            className={classes.toggleButton}
          >
            Toggle Choice
          </Button>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "10px" }}>
          <Typography
            className={classes.legChoiceText}
            style={{
              color: "#2692ff",
              font: "normal normal normal 14px/16px Inter",
            }}
          >
            {choiceSelection()}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container lg={8} xs={5}>
        <Grid item container xs={5} lg={max1280 ? 5.5 : 5} alignItems="center">
          <Grid item lg={max1280 ? 10 : 7} style={{ height: "fit-content" }}>
            <TextField
              required
              id="outlined-required"
              label="LEG1"
              className={classes.inputLeg}
              onKeyPress={(e) =>
                updateQuoteParameterVolsOfChoiceLeg1(e, volLeg1, 1)
              }
              onChange={(e) =>
                validateLeg1ChoiceLegMatch(e,1)
              }
              onClick={(e) => {setChoiceLeg2Validation("")}}
              InputProps={{
                disableUnderline: true,
                style: {
                  color: "white",
                  textAlign: "center",
                  marginLeft: "50px",
                },
              }}
              defaultValue={volLeg1}
              InputLabelProps={{
                style: {
                  color: "#ffffff",
                  marginRight: 10,
                },
              }}
            />
            <Grid>
              <Typography style={{color:"red"}}>{
               choiceLeg1Validation
              }
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            lg={2}
            style={{ height: "fit-content", paddingLeft: "5px" }}
          >
            {choiceLegString() ? (
              <Typography style={{ color: "#2692ff", fontSize: "1.3125rem" }}>
                CH
              </Typography>
            ) : null}
          </Grid>
          <Grid item container>
            <Grid item xs={12}>
              <Typography className={classes.quoteParamatersText}>
                DIRECTION: {rfqDefaultQuoteParams?.leg1_direction}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.quoteParamatersText}>
                VEGA: {rfqDefaultQuoteParams?.vega1}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.quoteParamatersText}>
                BBG VOL: {rfqDefaultQuoteParams?.bbg_vol1}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={2}
          alignItems="flex-start"
          justifyContent="flex-start"
          style={{
            position: "relative",
            bottom: "10px",
            right: max1280 ? "-15px" : "15px",
          }}
        >
          <Typography style={{ fontSize: "1.5rem", color: "#FF3400" }}>
            VS
          </Typography>
        </Grid>
        <Grid item container xs={5} lg={5.5} alignItems="center">
          <Grid item lg={max1280 ? 10 : 7} style={{ height: "fit-content" }}>
            <TextField
              required
              id="outlined-required"
              label="LEG2"
           
              className={classes.inputLeg}
              onKeyPress={(e) =>
                updateQuoteParameterVolsOfChoiceLeg2(e, volLeg2, 2)
              }
              onChange={(e) =>
                validateLeg2ChoiceLegMatch(e,2)
              }
              onClick={(e) => {setChoiceLeg1Validation("")}}

              InputProps={{
                disableUnderline: true,
                style: {
                  color: "white",
                  marginLeft: "50px",
                },
              }}
              defaultValue={volLeg2}
              InputLabelProps={{
                style: {
                  color: "#ffffff",
                  marginRight: 10,
                },
              }}
            />
            <Grid>
              <Typography style={{color:"red"}}>{choiceLeg2Validation}</Typography>
              {/* <Typography style={{color:"red"}}>{rfqParamatersCopy.choice_leg !== 2 ? "You are not in choice leg" : "Press Enter for the changes to take effect"}</Typography> */}
            </Grid>
          </Grid>
          <Grid
            item
            lg={2}
            style={{ height: "fit-content", paddingLeft: "5px" }}
          >
            {!choiceLegString() ? (
              <Typography style={{ color: "#2692ff", fontSize: "1.3125rem" }}>
                CH
              </Typography>
            ) : null}
          </Grid>
          <Grid item container>
            <Grid item xs={12}>
              <Typography className={classes.quoteParamatersText}>
                DIRECTION: {rfqDefaultQuoteParams?.leg2_direction}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.quoteParamatersText}>
                VEGA: {rfqDefaultQuoteParams?.vega2}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.quoteParamatersText}>
                BBG VOL: {rfqDefaultQuoteParams?.bbg_vol2}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default QuoteBookParamsDoubleVol;
export const useStyles = makeStyles((theme) => ({
  "& .MuiInputBase-input MuiInput-input": {
    fontSize: "400px",
    color: "white",
  },
  rfqPctNotional: {
    border: "1px solid #686B76",
    padding: "20px",
    marginTop: "5px",
    borderRadius: "8px",
  },
  rfqParameters: {
    fontSize: "1.0625rem",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-around",
    border: "1px solid #686B76",
    padding: "20px",
    marginTop: "5px",
    height: "fit-content",
  },
  rfqCoiceLeg: {
    marginTop: "1%",
    border: "1px solid #686B76",
    borderRadius: " 8px",
    height: "20vh",
    width: "100%",
  },
  quoteParamatersHeader: {
    color: " var(--unnamed-color-989ea8)",
    font: "normal normal normal 12px/10px Inter",
    letterSpacing: "0px",
    color: " #989EA8",
  },
  quoteParamatersText: {
    marginTop: "5px",
    color: " var(--unnamed-color-989ea8)",
    font: "normal normal normal 12px/10px Inter",
    letterSpacing: "0px",
    color: "#FFFFFF",
  },
  legChoiceText: {
    textAlign: "center",
    letterSpacing: "0px",
    color: "#FFFFFF",
    width: "90%",
  },

  quoteParamsLeg1Ch: {
    [theme.breakpoints.up("lg")]: {
      marginLeft: "-8em !important",
    },

    [theme.breakpoints.up("xl")]: {
      marginLeft: "-3em !important",
    },
    [theme.breakpoints.between("lg", "xl")]: {
      marginLeft: "-3em !important",
    },
  },
  menuItem: {
    color: "white",
  },
  input: {
    border: "1px solid var(--unnamed-color-686b76) !important",
  },
  inputLeg: {
    color: "white",
    border: "1px solid #686B76",
    borderRadius: "4px",
    opacity: 1,
    width: "100%",
    // height: "40px",
  },
  toggleButton: {
    backgroundColor: "#FF681C",
    border: "1px solid #FF681C",
    height: "25px",
    color: "#FFFFFF",
    borderRadius: "4px",
    width: "90%",
    font: "bold normal normal 14px/16px Inter",
  },
  checkbox: {
    color: "white",
    "&.MuiIconButton-root": {
      borderRadius: "3px",
      height: "5px",
      width: "5px",
    },
    "&.Mui-checked": {
      color: "#13c552",
    },
  },
}));
